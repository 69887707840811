import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { getValidationMessages } from "../../../../../utils";
import {
  StepTypeForm,
  stepTypeFormDefaultValues,
  stepTypeFormValidationSchema,
} from "../_compose";

export const useAddStepType = () => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.createDiligenceStepType,
  );

  const navigate = useNavigate();

  const form = useForm<StepTypeForm>({
    defaultValues: stepTypeFormDefaultValues,
    resolver: yupResolver(stepTypeFormValidationSchema),
  });

  const { handleSubmit } = form;

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await callService({
          description: values.description,
          name: values.name,
          label: values.label,
        });

        if (success) {
          OToastManager.success("Tipo de step salvo");
          navigate(
            corporateRouter.routes.energia.product.diligenceConfig.stepTypes
              .path,
          );
        } else {
          OToastManager.danger(
            getValidationMessages(error)?.[0].ErrorMessage ??
              "Erro ao salvar tipo de step",
          );
        }
      }),
    [callService, handleSubmit, navigate],
  );

  return {
    form,
    loading,
    submit,
  };
};
