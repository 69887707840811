export const identificatorTypes: HubPartners.Admin.IdentificationType[] = [
  "CPF",
  "CNPJ",
  "GUID",
  "DESK_ID",
  "DIGITAL_ID",
];

export const generateUuidV4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
