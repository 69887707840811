import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { PostAdminReceivablesSummaryResponse } from "services/quickfin/models";

export const NoteDetailsGenrator = (
  summary: PostAdminReceivablesSummaryResponse,
) => {
  if (!summary) return {} as DetailsFields;

  return {
    summary: {
      items: [
        {
          label: "Nº total de recebíveis",
          value: summary.totalRecebiveis,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Total vencimento",
          value: `${masks.currency(
            summary.totalValorVencimento,
            "R$",
          )} | ${masks.currency(
            summary.totalValorVencimentoUSD,
            "US$",
          )} | ${masks.currency(summary.totalValorVencimentoEUR, "€")}`,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Total desembolso",
          value: `${masks.currency(
            summary.totalValorDesembolso,
            "R$",
          )} | ${masks.currency(
            summary.totalValorDesembolsoUSD,
            "US$",
          )} | ${masks.currency(summary.totalValorDesembolsoEUR, "€")}`,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Total rebate",
          value: `${masks.currency(
            summary.totalRebate,
            "R$",
          )} | ${masks.currency(
            summary.totalRebateUSD,
            "US$",
          )} | ${masks.currency(summary.totalRebateEUR, "€")}`,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  } satisfies DetailsFields;
};
