import { OToastManager } from "@maestro/core";
import { OTooltip } from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services/service";
import { PostGuarantorsGetAllResponse } from "services/onboarding-relationships/models";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";

type GuarantorsResponse = NonNullable<
  PostGuarantorsGetAllResponse["guarantorsResponse"]
>[number];

const buildDetailsLink = (uuid: string) =>
  corporateRouter.routes.cadastro.customer.guarantors.guarantorsDetails.path({
    guarantorUuid: uuid,
  });

export const buildEditLink = (uuid: string) =>
  corporateRouter.routes.cadastro.customer.guarantors.guarantorsEmbedded.path({
    guarantorUuid: uuid,
  });

export const buildContingenciesLink = (uuid: string) =>
  corporateRouter.routes.cadastro.customer.guarantors.contingencies.path({
    guarantorUuid: uuid,
  });

const resentInvite = async (uuid: string) => {
  const payload = {
    uuid,
  };
  try {
    await service.onboardingRelationships.resendGuarantorsInvite(payload);
  } catch {
    OToastManager.danger("Falha ao reenviar convite");
  }
};

export const buildGrid: ODataGridGeneratorConfig<GuarantorsResponse> = {
  datagrid: {
    noDataText: "Nenhum avalista",
    pager: {
      allowedPageSizes: [10, 20, 30],
      showPageSizeSelector: true,
      infoText: "Página {0} de {1} ({2} avalistas)",
    },
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      role: roles.cadastro.customer.guarantorsGuarantorsDetailsPage.role,
      cellRender: ({ data }) =>
        data.uuid ? (
          <DetailsLink href={buildDetailsLink(data.uuid)} />
        ) : undefined,
      allowExporting: false,
    },
    {
      dataField: "id",
      caption: "ID",
      allowHeaderFiltering: false,
      allowExporting: false,
    },
    { dataField: "name", caption: "Nome", allowHeaderFiltering: false },
    { dataField: "email", caption: "E-mail", allowHeaderFiltering: false },
    {
      dataField: "phoneNumber",
      caption: "Telefone",
      customizeText: ({ valueText }) =>
        valueText ? masks.phone(valueText) : "Não informado",
      allowHeaderFiltering: false,
    },
    {
      dataField: "taxId",
      caption: "CPF",
      customizeText: ({ valueText }) => masks.cpf(valueText),
      allowHeaderFiltering: false,
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
    {
      dataField: "portalStatus",
      caption: "Dados Gerais",
    },
    {
      dataField: "detailedStatus",
      caption: "Status Detalhado",
      role: roles.cadastro.customer.guarantorsGuarantorsDetailedStatusColumn
        .role,
      allowExporting: false,
    },
    {
      dataField: "type",
      caption: "Tipo",
    },
    {
      dataField: "biometryStatusType",
      caption: "Biometria",
    },
    {
      dataField: "errors",
      caption: "Ocorrências",
      alignment: "center",
      width: 110,
      cellRender: ({ data }) => (
        <OTooltip floating position="top-left">
          <span slot="tooltip-content">Clique em detalhes para ver mais</span>
          {data.errors?.length}
        </OTooltip>
      ),
      allowHeaderFiltering: false,
      allowExporting: false,
    },
    {
      dataField: "documents",
      caption: "Documentos",
      alignment: "center",
      width: 110,
      cellRender: ({ data }) => (
        <OTooltip floating position="top-left">
          <span slot="tooltip-content">Clique em detalhes para ver mais</span>
          {data.documents?.length}
        </OTooltip>
      ),
      allowHeaderFiltering: false,
      allowExporting: false,
    },
    {
      caption: "Ações",
      allowExporting: false,
      role: roles.cadastro.customer.guarantorsGuarantorsEmbeddedPage.role,
      cellRender: ({ data }) => (
        <DataGridAction
          actions={[
            {
              icon: { category: "orq", icon: "orq-it" },
              label: "Contingências",
              route: data.uuid ? buildContingenciesLink(data.uuid) : "",
              role: roles.cadastro.customer.guarantorsContingenciesPage.role,
              visible: !!data.uuid,
              disabled: !data.uuid,
            },
            {
              label: "Editar",
              icon: { category: "orq", icon: "orq-edit-pencil" },
              route: data.uuid ? buildEditLink(data.uuid) : "",
              visible: !!data.uuid,
              disabled: !data.uuid,
            },
            {
              label: "Reenviar onboarding",
              icon: { category: "orq", icon: "orq-email" },
              onClick: () => resentInvite(data.uuid ?? ""),
              visible: data.portalStatus === "REPROVADO",
            },
          ]}
        />
      ),
    },
  ],
  paging: {
    defaultPageSize: 10,
  },
};
