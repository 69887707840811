import {
  OCheckbox,
  OCol,
  OLabel,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORow,
} from "@maestro/react";
import {
  checkboxValue,
  consumerUnitFormFields,
} from "../../../../edit-consumer-unit.form";

interface BasicDataFieldsProps {
  disabled?: boolean;
  disabledTaxId?: boolean;
}

export const BasicDataFields = ({
  disabled,
  disabledTaxId,
}: BasicDataFieldsProps) => {
  return (
    <ORow gap={3}>
      <OCol xs={12} md={6}>
        <ORFieldInput
          {...consumerUnitFormFields.taxId}
          disabled={disabled || disabledTaxId}
        />
      </OCol>
      <OCol xs={12} md={6}>
        <ORFieldInput
          {...consumerUnitFormFields.entityOfficialName}
          disabled={disabled}
        />
      </OCol>
      <OCol xs={12} md={6}>
        <ORFieldInput
          {...consumerUnitFormFields.entityName}
          disabled={disabled}
        />
      </OCol>
      <OCol xs={12} md={6}>
        <ORFieldInput {...consumerUnitFormFields.alias} disabled={disabled} />
      </OCol>
      <OCol xs={12} md={6}>
        <ORFieldInput
          {...consumerUnitFormFields.externalId}
          disabled={disabled}
        />
      </OCol>
      <OCol xs={12} md={6}>
        <ORFieldCheckboxGroup
          {...consumerUnitFormFields.isMainUnit}
          disabled={disabled}
        >
          <div className="d-flex align-items-center gap-2">
            <OCheckbox
              id="isMainUnit-checkbox"
              value={checkboxValue}
              size="sm"
            />
            <OLabel htmlFor="isMainUnit-checkbox">Unidade principal</OLabel>
          </div>
        </ORFieldCheckboxGroup>
      </OCol>
    </ORow>
  );
};
