import { OToastManager, modalManager } from "@maestro/core";
import { OConfirmationModal, OTypography } from "@maestro/react";
import { IconButton } from "components/icon-button";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { getValidationMessages } from "../../../../../../utils";

const modalId = "reprocess-import-confirmation-modal";

interface ReprocessButtonProps {
  importData: HubEnergy.LegacyImport.ConsumerUnitsImportDataResponse;
}

export const ReprocessButton = ({ importData }: ReprocessButtonProps) => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.reprocessConsumerUnitsImportData,
  );

  const navigate = useNavigate();

  const reprocessImportData = useCallback(async () => {
    const { success, error } = await callService(importData.id);

    if (success) {
      OToastManager.success("A importação foi reprocessada com sucesso");
      navigate(corporateRouter.routes.energia.product.consumerUnitsImport.path);
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0].ErrorMessage ??
          "Erro ao reprocessar a importação",
      );
    }
  }, [callService, importData.id, navigate]);

  return (
    <>
      <IconButton
        icon={{ category: "orq", icon: "orq-swap-circle" }}
        loading={loading}
        onClick={() => modalManager.show(modalId)}
        disabled={
          importData.status === "IMPORTADO" ||
          importData.status === "PROCESSANDO"
        }
      >
        Reprocessar importação
      </IconButton>
      <OConfirmationModal
        modalId={modalId}
        title="Reprocessar importação"
        divider={false}
        onConfirm={() => {
          reprocessImportData();
          modalManager.hide(modalId);
        }}
        className="position-absolute"
      >
        <OTypography>
          Tem certeza que deseja reprocessar a importação?
        </OTypography>
      </OConfirmationModal>
    </>
  );
};
