import { OToastManager } from "@maestro/core";
import { SelectSearchOption } from "components/select-search/select-search.types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FieldError, useFormContext } from "react-hook-form";
import { service } from "services";
import { logger } from "utils/logger";

export const useIndicationFields = (isEditing: boolean) => {
  const [operators, setOperators] = useState<HubPartners.Admin.Operator[]>();
  const [originators, setOriginators] =
    useState<HubPartners.Admin.Originator[]>();
  const [loadingOperators, setLoadingOperators] = useState(false);
  const [loadingOriginators, setLoadingOriginators] = useState(false);
  const [wasIndicated, setWasIndicated] = useState(false);

  const form = useFormContext();
  const {
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = form;
  const watchOriginator = watch("indicatorOriginatorUUID");

  const originatorValue = useMemo(() => {
    const foundOriginator = originators?.find(
      (originator) => originator.uuid === watchOriginator,
    );
    return foundOriginator?.name ?? "";
  }, [originators, watchOriginator]);

  const operatorValue = useMemo(() => {
    const foundOperator = operators?.find(
      (operator) => operator.uuid === getValues("indicatorOperatorUUID"),
    );
    return foundOperator?.officialName ?? "";
  }, [getValues, operators]);

  const originatorsError = (errors.indicatorOriginatorUUID ??
    null) as FieldError | null;
  const operatorsError = (errors.indicatorOperatorUUID ??
    null) as FieldError | null;

  const indicationOriginatorOptions = useMemo(
    () =>
      originators?.map<SelectSearchOption<string>>((originator) => ({
        label: originator.name,
        value: originator.uuid,
        onSelect: (value) => setValue("indicatorOriginatorUUID", value),
      })) ?? [],
    [originators, setValue],
  );
  const indicationOperatorOptions = useMemo(
    () =>
      operators?.map<SelectSearchOption<string>>((operator) => ({
        label: operator.officialName,
        value: operator.uuid,
        onSelect: (value) => setValue("indicatorOperatorUUID", value),
      })) ?? [],
    [operators, setValue],
  );

  const toggleHasIndicatorCheckbox = useCallback(
    (hasIndication: boolean) => {
      if (!hasIndication) {
        setValue("indicatorOriginatorUUID", undefined);
        setValue("indicatorOperatorUUID", undefined);
      }
      setWasIndicated(hasIndication);
    },
    [setValue],
  );

  const fetchDataOriginator = useCallback(async () => {
    try {
      setLoadingOriginators(true);

      const { data } = await service.hubPartners.getOriginators();

      setOriginators(data.data);
    } catch (e) {
      logger.error(e);
      OToastManager.danger("Não foi possível obter os possíveis originadores");
    } finally {
      setLoadingOriginators(false);
    }
  }, []);

  const fetchDataOperator = useCallback(async () => {
    if (!watchOriginator) {
      return;
    }

    const chosenOriginator = originators?.find(
      (originator) => originator.uuid === watchOriginator,
    );

    if (!chosenOriginator) {
      return;
    }

    try {
      setLoadingOperators(true);

      const params = {
        uuid: chosenOriginator.uuid,
      };

      const { data } = await service.hubPartners.getOperatorsByOriginatorUuid(
        params,
      );

      setOperators(data.data);
    } catch (e) {
      logger.error(e);
      OToastManager.danger("Não foi possível obter os possíveis operadores");
    } finally {
      setLoadingOperators(false);
    }
  }, [originators, watchOriginator]);

  useEffect(() => {
    fetchDataOriginator();
  }, [fetchDataOriginator]);

  useEffect(() => {
    fetchDataOperator();
  }, [fetchDataOperator]);

  useEffect(() => {
    if (isEditing) {
      setWasIndicated(getValues("hasIndicator").includes("check"));
    }
  }, [getValues, isEditing]);

  return {
    indicationOperatorOptions,
    indicationOriginatorOptions,
    loadingOperators,
    loadingOriginators,
    operatorsError,
    operatorValue,
    originatorsError,
    originatorValue,
    toggleHasIndicatorCheckbox,
    wasIndicated,
  };
};
