import {
  OCheckbox,
  OCol,
  OField,
  OLabel,
  ORFieldCheckboxGroup,
  ORow,
} from "@maestro/react";
import { SelectSearch } from "components/select-search";
import { useIndicationFields } from "./indication-fields.hook";

interface IndicationFieldsProps {
  isEditing?: boolean;
}

export const IndicationFields = ({
  isEditing = false,
}: IndicationFieldsProps) => {
  const {
    indicationOperatorOptions,
    indicationOriginatorOptions,
    loadingOperators,
    loadingOriginators,
    operatorsError,
    operatorValue,
    originatorsError,
    originatorValue,
    toggleHasIndicatorCheckbox,
    wasIndicated,
  } = useIndicationFields(isEditing);

  return (
    <>
      <ORFieldCheckboxGroup id="hasIndicator" name="hasIndicator" required>
        <div className="d-flex gap-2 align-items-center">
          <OCheckbox
            id="hasIndicatorCheck"
            size="sm"
            value="check"
            onClick={(checked) =>
              toggleHasIndicatorCheckbox(!checked.currentTarget.checked)
            }
          />
          <OLabel htmlFor="hasIndicatorCheck">
            Este originador foi indicado.
          </OLabel>
        </div>
      </ORFieldCheckboxGroup>
      <ORow>
        <OCol>
          <OField
            htmlFor="indicatorOperatorUUID"
            label="Originador"
            error={!!originatorsError}
            message={originatorsError?.message ?? ""}
            disabled={loadingOriginators || !wasIndicated}
          >
            <SelectSearch
              className="w-100"
              id="indicatorOriginatorUUID"
              name="indicatorOriginatorUUID"
              placeholder="Selecione"
              value={originatorValue}
              disabled={loadingOriginators || !wasIndicated}
              options={indicationOriginatorOptions}
            />
          </OField>
        </OCol>
        <OCol>
          <OField
            htmlFor="indicatorOperatorUUID"
            label="Operador"
            error={!!operatorsError}
            message={operatorsError?.message ?? ""}
            disabled={
              loadingOperators ||
              !wasIndicated ||
              !indicationOperatorOptions.length
            }
          >
            <SelectSearch
              className="w-100"
              id="indicatorOperatorUUID"
              name="indicatorOperatorUUID"
              placeholder="Selecione"
              value={operatorValue}
              disabled={
                loadingOperators ||
                !wasIndicated ||
                !indicationOperatorOptions.length
              }
              options={indicationOperatorOptions}
            />
          </OField>
        </OCol>
      </ORow>
    </>
  );
};
