import { OToastManager } from "@maestro/core";
import { useCustomer } from "contexts/customer";
import {
  FinancialInstitution,
  useFinancialInstitution,
} from "hooks/financial-institution";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { service } from "services";
import { logger } from "utils/logger";
import { DataGridUtils, Filters } from "./_compose";
import {
  DataSourceItem,
  INITIAL_PAGE_NUMBER,
  INITIAL_PAGE_SIZE,
  MetaData,
  UpdateDataSourceParams,
} from "./payments-and-transfers.types";
import {
  assembleDataSourceFromPayments,
  assembleGetPaymentFilters,
} from "./payments-and-transfers.utils";

interface PaymentsAndTransfersContextProps {
  dataSource: DataSourceItem[];
  isLoadingDataSource: boolean;
  filtersSubmitted: Filters | undefined;
  pageNumber: number;
  setPageNumber: (value: React.SetStateAction<number>) => void;
  pageSize: number;
  setPageSize: (value: React.SetStateAction<number>) => void;
  pageCursors: [string, string];
  pageCursorOrigin: string;
  selectedItems: MetaData[];
  clearSelectedItems: () => void;
  updateSelectedAllItems: (toAllSelected: boolean, items: MetaData[]) => void;
  updateSelectedItems: (toItemSelected: boolean, item: MetaData) => void;
  setSelectedItems: (value: React.SetStateAction<MetaData[]>) => void;
  clickedRow: MetaData;
  setClickedRow: (value: React.SetStateAction<MetaData>) => void;
  updateDataSource: (params: UpdateDataSourceParams) => void;
  reloadDataSource: () => void;
  financialInstitutions: FinancialInstitution[];
}

const PaymentsAndTransfersContextDefaultValue: PaymentsAndTransfersContextProps =
  {
    dataSource: [],
    isLoadingDataSource: false,
    filtersSubmitted: undefined,
    pageNumber: INITIAL_PAGE_NUMBER,
    setPageNumber: () => {},
    pageSize: INITIAL_PAGE_SIZE,
    setPageSize: () => {},
    pageCursors: ["", ""],
    pageCursorOrigin: "",
    selectedItems: [],
    clearSelectedItems: () => {},
    updateSelectedAllItems: () => {},
    updateSelectedItems: () => {},
    setSelectedItems: () => {},
    clickedRow: {},
    setClickedRow: () => {},
    updateDataSource: () => {},
    reloadDataSource: () => {},
    financialInstitutions: [],
  };

const PaymentsAndTransfersContext = createContext(
  PaymentsAndTransfersContextDefaultValue,
);

interface PaymentsAndTransfersProviderProps {
  children: React.ReactNode;
}

export const PaymentsAndTransfersProvider = ({
  children,
}: PaymentsAndTransfersProviderProps) => {
  const {
    customer: { identification },
  } = useCustomer();

  const [dataSource, setDataSource] = useState<DataSourceItem[]>([]);
  const [isLoadingDataSource, setIsLoadingDataSource] = useState(false);

  const [filtersSubmitted, setFiltersSubmitted] = useState<
    Filters | undefined
  >();

  const [pageNumber, setPageNumber] = useState(INITIAL_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(INITIAL_PAGE_SIZE);

  const [pageCursors, setPageCursors] = useState<[string, string]>(["", ""]);
  const [pageCursorOrigin, setPageCursorOrigin] = useState<string>("");

  const [selectedItems, setSelectedItems] = useState<MetaData[]>([]);

  const [clickedRow, setClickedRow] = useState<MetaData>({});

  const financialInstitutions = useFinancialInstitution();

  const clearSelectedItems = useCallback(() => setSelectedItems([]), []);

  const updateSelectedAllItems = useCallback(
    (allSelected: boolean, items: MetaData[]): void => {
      if (allSelected) {
        setSelectedItems(() => [...items]);
      } else clearSelectedItems();
    },
    [clearSelectedItems],
  );

  const updateSelectedItems = useCallback(
    (isSelected: boolean, item: MetaData) => {
      const newSelectedItems = isSelected
        ? [...selectedItems, item]
        : selectedItems.filter(
            (prevSelectedItem) => prevSelectedItem.id !== item.id,
          );

      setSelectedItems(newSelectedItems);
    },
    [selectedItems],
  );

  const updateDataSource = useCallback(
    async ({
      filters,
      pagination,
      cursor,
      cursorOrigin,
    }: UpdateDataSourceParams) => {
      const params = {
        ...(filters && assembleGetPaymentFilters(filters)),
        ...(!!pagination && {
          pageSize: pagination.pageSize,
          pageNumber: pagination.pageNumber,
        }),
        cursor: cursor && cursor.split("cursor=")[1],
      };

      try {
        setIsLoadingDataSource(true);
        setSelectedItems([]);

        if (!cursor) setFiltersSubmitted(filters);

        const { data } = await service.api.getCompanyPayments(
          identification,
          params,
        );

        if (!data) return;

        const { data: payments } = data;

        setDataSource(assembleDataSourceFromPayments(payments));

        const nextPageLink = data._links?.next?.href ?? "";
        const previousPageLink = data._links?.previous?.href ?? "";

        setPageCursors([previousPageLink, nextPageLink]);
        if (cursor) setPageCursorOrigin(cursor);
      } catch (error) {
        setDataSource([]);
        setPageCursors((prev) =>
          DataGridUtils.loadPageCursors(prev, cursor, cursorOrigin),
        );
        logger.warn(error);
        OToastManager.danger(
          "Não foi possivel carregar a listagem de pagamentos.",
        );
      } finally {
        setIsLoadingDataSource(false);
      }
    },
    [identification],
  );

  const reloadDataSource = useCallback(async () => {
    await updateDataSource({
      filters: filtersSubmitted,
      pagination: {
        pageNumber,
        pageSize,
      },
    });
  }, [filtersSubmitted, pageNumber, pageSize, updateDataSource]);

  const value = useMemo(
    () => ({
      dataSource,
      isLoadingDataSource,
      filtersSubmitted,
      pageNumber,
      setPageNumber,
      pageSize,
      setPageSize,
      pageCursors,
      pageCursorOrigin,
      selectedItems,
      clearSelectedItems,
      updateSelectedAllItems,
      updateSelectedItems,
      setSelectedItems,
      clickedRow,
      setClickedRow,
      updateDataSource,
      reloadDataSource,
      financialInstitutions,
    }),
    [
      dataSource,
      isLoadingDataSource,
      filtersSubmitted,
      pageNumber,
      setPageNumber,
      pageSize,
      setPageSize,
      pageCursors,
      pageCursorOrigin,
      selectedItems,
      clearSelectedItems,
      updateSelectedAllItems,
      updateSelectedItems,
      setSelectedItems,
      clickedRow,
      setClickedRow,
      updateDataSource,
      reloadDataSource,
      financialInstitutions,
    ],
  );

  return (
    <PaymentsAndTransfersContext.Provider value={value}>
      {children}
    </PaymentsAndTransfersContext.Provider>
  );
};

export const usePaymentsAndTransfersContext = () =>
  useContext(PaymentsAndTransfersContext);
