import { OLoader } from "@maestro/react";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { SideFormModal } from "./_compose";
import { useSideFilterBar } from "./side-filter-bar.hook";
import { SideFilterBarForm, SideFilterBarProps } from "./side-filter-bar.types";
import {
  assembleDefaultValuesFromSections,
  removeNullableFormFields,
} from "./side-filter-bar.utils";

export const SideFilterBar = ({
  className,
  sections,
  submitFilters,
  defaultValuesSubmitted = false,
}: SideFilterBarProps) => {
  const {
    setFormFieldIdsFiltered,
    isSubmitting,
    onFormSubmit,
    formFieldIdsFiltered,
    onFormError,
  } = useSideFilterBar(sections, submitFilters);

  const form = useForm<SideFilterBarForm>({
    reValidateMode: "onBlur",
  });

  const { handleSubmit, setValue } = form;

  useEffect(() => {
    const defaultValues = assembleDefaultValuesFromSections(sections);
    Object.entries(defaultValues).forEach(([key, value]) => {
      setValue(key, value);
    });
    if (defaultValuesSubmitted)
      setFormFieldIdsFiltered(
        Object.keys(removeNullableFormFields(defaultValues, sections)),
      );
  }, [sections, setValue, defaultValuesSubmitted, setFormFieldIdsFiltered]);

  return (
    <div className={className}>
      {isSubmitting && <OLoader absolute backdrop />}
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(onFormSubmit, onFormError)}
          autoComplete="off"
          id="side-filter-bar"
        >
          <SideFormModal
            sections={sections}
            formFieldIdsFiltered={formFieldIdsFiltered}
            setFormFieldIdsFiltered={setFormFieldIdsFiltered}
            onFormSubmit={onFormSubmit}
          />
        </form>
      </FormProvider>
    </div>
  );
};
