import {
  OCol,
  ODivider,
  OField,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { SelectSearch } from "components/select-search";
import { identificatorTypes } from "../../../pages/produto/originadores/novo-originador/new-originator.utils";
import { useOriginatorInfoFields } from "./originator-info-fields.hook";

interface OriginatorInfoFieldsProps {
  isCNPJ?: boolean;
  isCorban?: boolean;
  isEditing?: boolean;
  isTechnology?: boolean;
}

export const OriginatorInfoFields = ({
  isCNPJ = false,
  isCorban = false,
  isEditing = false,
  isTechnology = true,
}: OriginatorInfoFieldsProps) => {
  const {
    economicGroupOptions,
    economicGroupsError,
    economicGroupValue,
    foundEntityData,
    isGuid,
    loadingEconomicGroups,
    loadingMainOrignators,
    mainOriginatorOptions,
    aminOriginatorsError,
    mainOriginatorValue,
  } = useOriginatorInfoFields(isEditing, isTechnology);
  return (
    <div className="d-flex flex-column gab-5">
      <ORow>
        <OCol lg={6} xs={12}>
          <ORFieldSelect
            id="identificationType"
            name="identificationType"
            label="Tipo do Identificador"
            disabled={isEditing}
            required
          >
            {identificatorTypes.map((identificatorType) => (
              <OOption key={identificatorType} value={identificatorType}>
                {identificatorType}
              </OOption>
            ))}
          </ORFieldSelect>
        </OCol>
        <OCol lg={6} xs={12}>
          <ORFieldInput
            tag="text"
            id="identification"
            name="identification"
            label="Identificador"
            disabled={isEditing || isGuid}
            required
          />
        </OCol>
        <OCol lg={6} xs={12}>
          <ORFieldInput
            tag="text"
            id="officialName"
            name="officialName"
            label="Razão Social"
            required
            disabled={(isEditing && isCNPJ) || foundEntityData}
          />
        </OCol>
        <OCol lg={6} xs={12}>
          <ORFieldInput
            tag="text"
            id="tradingName"
            name="tradingName"
            label="Nome Fantasia"
            disabled={(isEditing && isCNPJ) || foundEntityData}
          />
        </OCol>
        {(isTechnology || isEditing) && (
          <OCol lg={6} xs={12}>
            <OField
              htmlFor="economicGroupUUID"
              label="Grupo Econômico"
              error={!!economicGroupsError}
              message={economicGroupsError?.message ?? ""}
              disabled={
                (isEditing && isCorban && !isTechnology) ||
                loadingEconomicGroups
              }
            >
              <SelectSearch
                className="w-100"
                id="economicGroupUUID"
                name="economicGroupUUID"
                placeholder="Selecione"
                value={economicGroupValue}
                disabled={
                  (isEditing && isCorban && !isTechnology) ||
                  loadingEconomicGroups
                }
                options={economicGroupOptions}
              />
            </OField>
          </OCol>
        )}
        {(isTechnology || isEditing) && (
          <OCol lg={6} xs={12}>
            <ORFieldInput
              tag="text"
              id="identifier"
              name="identifier"
              label="Identificador externo"
              disabled={isEditing && !isTechnology}
            />
          </OCol>
        )}
        {isEditing && (
          <OCol lg={6} xs={12}>
            <ORFieldInput
              tag="text"
              id="tagCode"
              name="tagCode"
              label="tagCode"
            />
          </OCol>
        )}
      </ORow>

      <ODivider className="my-3" />

      <ORow>
        {(isTechnology || isEditing) && (
          <OCol lg={6} xs={12}>
            <OField
              htmlFor="mainOriginatorUUID"
              label="Instituição Responsável"
              error={!!aminOriginatorsError}
              message={aminOriginatorsError?.message ?? ""}
              disabled={
                (isEditing && isCorban && !isTechnology) ||
                loadingMainOrignators
              }
            >
              <SelectSearch
                className="w-100"
                id="mainOriginatorUUID"
                name="mainOriginatorUUID"
                placeholder="Selecione"
                value={mainOriginatorValue}
                disabled={
                  (isEditing && isCorban && !isTechnology) ||
                  loadingMainOrignators
                }
                options={mainOriginatorOptions}
              />
            </OField>
          </OCol>
        )}
        <OCol lg={6} xs={12}>
          <ORFieldInput
            tag="text"
            id="areaCode"
            name="areaCode"
            label="Área"
            required
          />
        </OCol>
      </ORow>
    </div>
  );
};
