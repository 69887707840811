import {
  dataSourceCustomStoreGenerator,
  ExtractODataGridGeneratorConfigType,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { loanAgreementsGrid } from "../../../../../components/loan-agreement/loan-agreements.grid";
import {
  EmprestimosProdutoParceiroAssociarConvenioRouteQueryParams,
  EmprestimosProdutoParceirosByPartnerNameByPartnerId,
} from "../../../../../routes/emprestimos.route-params";

export const PartnerDetailsPage = () => {
  const [partnerAgreements, setPartnerAgreements] =
    useState<EmprestimosProdutoParceiroAssociarConvenioRouteQueryParams>();
  const { partnerName, partnerId } =
    useParams<EmprestimosProdutoParceirosByPartnerNameByPartnerId>();

  if (!partnerName || !partnerId)
    throw new Error("No partnerName or partnerId");

  const gridRef = useGridRef();

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<
        ExtractODataGridGeneratorConfigType<typeof loanAgreementsGrid>
      >(() => {
        return service.hubLoan
          .getArrangementDetails(partnerName)
          .then(({ data }) => {
            setPartnerAgreements(
              data.map((details) => ({ agreementId: details.agreement.id })),
            );
            return data.map((details) => details.agreement);
          })
          .catch(() => {
            throw new Error("Erro ao buscar convênios associados.");
          });
      }),
    [partnerName],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title={`${partnerName} - Convênios associados`} />}
      actions={
        <>
          <RouterButton
            type="tertiary"
            dataAction="emprestimos_detalhes_parceiro:botao:associar_convenio"
            dataLabel="associar_convenio"
            href={`${corporateRouter.routes.emprestimos.product.partners.partner.details.associateAgreement.path(
              { partnerId, partnerName },
            )}?data=${encodeURIComponent(JSON.stringify(partnerAgreements))}`}
            outline
            role={
              roles.emprestimos.product
                .partnersPartnerDetailsAssociateAgreementPage.role
            }
          >
            Associar a um convênio
          </RouterButton>
          <RouterButton
            type="tertiary"
            dataAction="emprestimos_detalhes_parceiro:botao:cadastrar_novo_convenio"
            dataLabel="cadastrar_novo_convenio"
            href={
              corporateRouter.routes.emprestimos.product.agreements.add.path
            }
            role={roles.emprestimos.product.agreementsAddPage.role}
            outline
          >
            Cadastrar novo convênio
          </RouterButton>
        </>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={loanAgreementsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
