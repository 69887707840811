import * as yup from "yup";

export type UpdateOriginatorInfoFormValues = Pick<
  HubPartners.Admin.NewOriginator,
  | "identification"
  | "identificationType"
  | "officialName"
  | "tradingName"
  | "economicGroupUUID"
  | "identifier"
  | "mainOriginatorUUID"
  | "areaCode"
> & {
  tagCode: string;
};

export const updateOriginatorInfoValidationSchema: yup.ObjectSchema<UpdateOriginatorInfoFormValues> =
  yup.object({
    identification: yup.string().required("Este campo é obrigatório"),
    identificationType: yup
      .mixed<HubPartners.Admin.IdentificationType>()
      .required("Este campo é obrigatório"),
    officialName: yup.string().required("Este campo é obrigatório"),
    tradingName: yup.string().required("Este campo é obrigatório"),
    areaCode: yup
      .number()
      .required("Este campo é obrigatório")
      .transform((value) => (Number.isNaN(value) ? null : value)),
    economicGroupUUID: yup.string(),
    identifier: yup.string().required("Este campo é obrigatório"),
    mainOriginatorUUID: yup.string(),
    tagCode: yup.string().required("Este campo é obrigatório"),
  });

export const updateOriginatorInfoDefaultValues: UpdateOriginatorInfoFormValues =
  {
    identification: "",
    identificationType: "CPF",
    officialName: "",
    tradingName: "",
    economicGroupUUID: "",
    identifier: undefined,
    mainOriginatorUUID: "",
    areaCode: 0,
    tagCode: "",
  };
