import { DetailsFields } from "components/details-card";
import { GetApiAdminDashboardResponse } from "services/hubloan/models/responses";
import { masks } from "@maestro/utils";
import { currencySymbol } from "utils/currency";

export const loanProductDashboardDetails = ({
  countByStatus,
}: GetApiAdminDashboardResponse): DetailsFields => {
  if (!countByStatus) return {} as DetailsFields;

  return {
    Dashboard: {
      title: "Total por Status",
      items: [
        {
          label: "Renegociado:",
          value: `${countByStatus.Renegociado}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!countByStatus.Renegociado,
        },
        {
          label: "Expirado:",
          value: `${countByStatus.Expirado}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!countByStatus.Expirado,
        },
        {
          label: "Rejeitado:",
          value: `${countByStatus.Rejeitado}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!countByStatus.Rejeitado,
        },
        {
          label: "Inválido:",
          value: `${countByStatus.Invalido}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!countByStatus.Invalido,
        },
        {
          label: "Cancelado:",
          value: `${countByStatus.Cancelado}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!countByStatus.Cancelado,
        },
        {
          label: "Simulado:",
          value: `${countByStatus.Simulado}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!countByStatus.Simulado,
        },
        {
          label: "Pendente Onboarding:",
          value: `${countByStatus.PendenteOnboardingTerceiros}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!countByStatus.PendenteOnboardingTerceiros,
        },
        {
          label: "Geracão de Contrato:",
          value: `${countByStatus.GeracaoDeContrato}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!countByStatus.GeracaoDeContrato,
        },
        {
          label: "Pendente Assinatura:",
          value: `${countByStatus.PendenteAssinatura}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!countByStatus.PendenteAssinatura,
        },
        {
          label: "Pendente Validacão:",
          value: `${countByStatus.PendenteValidacao}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!countByStatus.PendenteValidacao,
        },
        {
          label: "Aguardando Registro:",
          value: `${countByStatus.AguardandoRegistro}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!countByStatus.AguardandoRegistro,
        },
        {
          label: "Aguardando Nota Fiscal:",
          value: `${countByStatus.AguardandoNotaFiscal}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!countByStatus.AguardandoNotaFiscal,
        },
        {
          label: "Pendente Ativação Conta:",
          value: `${countByStatus.PendenteAtivacaoConta}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!countByStatus.PendenteAtivacaoConta,
        },
        {
          label: "Aprovação Envio Trade:",
          value: `${countByStatus.AprovacaoEnvioTrade}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!countByStatus.AprovacaoEnvioTrade,
        },
        {
          label: "Envio Trade:",
          value: `${countByStatus.EnvioTrade}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!countByStatus.EnvioTrade,
        },
        {
          label: "Trade Enviado:",
          value: `${countByStatus.TradeEnviado}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!countByStatus.TradeEnviado,
        },
        {
          label: "Aguardando Aprovação:",
          value: `${countByStatus.AguardandoAprovacao}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!countByStatus.AguardandoAprovacao,
        },
        {
          label: "Aguardando Desembolso:",
          value: `${countByStatus.AguardandoDesembolso}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!countByStatus.AguardandoDesembolso,
        },
        {
          label: "Desembolsado:",
          value: `${countByStatus.Desembolsado}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!countByStatus.Desembolsado,
        },
        {
          label: "Liquidado:",
          value: `${countByStatus.Liquidado}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!countByStatus.Liquidado,
        },
      ],
    },
  };
};

export const loanProductDashboardAmountDetails = ({
  amountByStatus,
}: GetApiAdminDashboardResponse): DetailsFields => {
  if (!amountByStatus) return {} as DetailsFields;

  return {
    Dashboard: {
      title: "Valor por Status",
      items: [
        {
          label: "Renegociado:",
          value: `${masks.currency(
            amountByStatus.Renegociado,
            currencySymbol.BRL,
          )}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!amountByStatus.Renegociado,
        },
        {
          label: "Expirado:",
          value: `${masks.currency(
            amountByStatus.Expirado,
            currencySymbol.BRL,
          )}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!amountByStatus.Expirado,
        },
        {
          label: "Rejeitado:",
          value: `${masks.currency(
            amountByStatus.Rejeitado,
            currencySymbol.BRL,
          )}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!amountByStatus.Rejeitado,
        },
        {
          label: "Inválido:",
          value: `${masks.currency(
            amountByStatus.Invalido,
            currencySymbol.BRL,
          )}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!amountByStatus.Invalido,
        },
        {
          label: "Cancelado:",
          value: `${masks.currency(
            amountByStatus.Cancelado,
            currencySymbol.BRL,
          )}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!amountByStatus.Cancelado,
        },
        {
          label: "Simulado:",
          value: `${masks.currency(
            amountByStatus.Simulado,
            currencySymbol.BRL,
          )}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!amountByStatus.Simulado,
        },
        {
          label: "Pendente Onboarding:",
          value: `${masks.currency(
            amountByStatus.PendenteOnboardingTerceiros,
            currencySymbol.BRL,
          )}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!amountByStatus.PendenteOnboardingTerceiros,
        },
        {
          label: "Geração de Contrato:",
          value: `${masks.currency(
            amountByStatus.GeracaoDeContrato,
            currencySymbol.BRL,
          )}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!amountByStatus.GeracaoDeContrato,
        },
        {
          label: "Pendente Assinatura:",
          value: `${masks.currency(
            amountByStatus.PendenteAssinatura,
            currencySymbol.BRL,
          )}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!amountByStatus.PendenteAssinatura,
        },
        {
          label: "Pendente Validação:",
          value: `${masks.currency(
            amountByStatus.PendenteValidacao,
            currencySymbol.BRL,
          )}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!amountByStatus.PendenteValidacao,
        },
        {
          label: "Aguardando Registro:",
          value: `${masks.currency(
            amountByStatus.AguardandoRegistro,
            currencySymbol.BRL,
          )}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!amountByStatus.AguardandoRegistro,
        },
        {
          label: "Aguardando Nota Fiscal:",
          value: `${masks.currency(
            amountByStatus.AguardandoNotaFiscal,
            currencySymbol.BRL,
          )}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!amountByStatus.AguardandoNotaFiscal,
        },
        {
          label: "Pendente Ativação Conta:",
          value: `${masks.currency(
            amountByStatus.PendenteAtivacaoConta,
            currencySymbol.BRL,
          )}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!amountByStatus.PendenteAtivacaoConta,
        },
        {
          label: "Aprovação Envio Trade:",
          value: `${masks.currency(
            amountByStatus.AprovacaoEnvioTrade,
            currencySymbol.BRL,
          )}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!amountByStatus.AprovacaoEnvioTrade,
        },
        {
          label: "Envio Trade:",
          value: `${masks.currency(
            amountByStatus.EnvioTrade,
            currencySymbol.BRL,
          )}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!amountByStatus.EnvioTrade,
        },
        {
          label: "Trade Enviado:",
          value: `${masks.currency(
            amountByStatus.TradeEnviado,
            currencySymbol.BRL,
          )}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!amountByStatus.TradeEnviado,
        },
        {
          label: "Aguardando Aprovação:",
          value: `${masks.currency(
            amountByStatus.AguardandoAprovacao,
            currencySymbol.BRL,
          )}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!amountByStatus.AguardandoAprovacao,
        },
        {
          label: "Aguardando Desembolso:",
          value: `${masks.currency(
            amountByStatus.AguardandoDesembolso,
            currencySymbol.BRL,
          )}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!amountByStatus.AguardandoDesembolso,
        },
        {
          label: "Desembolsado:",
          value: `${masks.currency(
            amountByStatus.Desembolsado,
            currencySymbol.BRL,
          )}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!amountByStatus.Desembolsado,
        },
        {
          label: "Liquidado:",
          value: `${masks.currency(
            amountByStatus.Liquidado,
            currencySymbol.BRL,
          )}`,
          grid: { sm: 4, md: 4, lg: 6 },
          visible: !!amountByStatus.Liquidado,
        },
      ],
    },
  };
};
