import { CollectionFilterFields } from "./filters-modal.type";

const formatDate = (date: string) => {
  const day = date.slice(0, 2);
  const month = date.slice(3, 5);
  const year = date.slice(6, 10);
  return `${year}-${month}-${day}`;
};

export const assembleFilterParams = (params: CollectionFilterFields) => {
  const result: CollectionFilterFields = {};

  const dateKeys = [
    "startCreatedAt",
    "endCreatedAt",
    "startDueDate",
    "endDueDate",
    "startSettledAt",
    "endSettledAt",
  ];

  Object.keys(params).forEach((key) => {
    const paramKey = key as keyof CollectionFilterFields;
    if (params[paramKey]) {
      result[paramKey] = dateKeys.includes(paramKey)
        ? formatDate(params[paramKey] ?? "")
        : params[paramKey];
    }
  });

  return result;
};
