import { yupResolver } from "@hookform/resolvers/yup";
import { modalManager, OToastManager } from "@maestro/core";
import {
  OButton,
  OCol,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORow,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import type { DataGrid } from "devextreme-react/data-grid";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { ModalWithSelectOverflow } from "../../../../../../components/modal-with-select-overflow";
import { SelectSearchFieldV2 } from "../../../../../../components/select-search-v2";
import { getValidationMessages } from "../../../../../../utils";
import {
  BatchAssignModalForm,
  batchAssignModalFormDefaultValues,
  batchAssignModalFormValidationSchema,
} from "./batch-assign-modal.form";

export const batchAssignModalId = "batch-assign-modal";

interface BatchAssignModalProps {
  agents: HubEnergy.AgentResponse[];
  selectedCuts: HubEnergy.ConsumerUnitWithAgentsResponse[];
  gridRef: React.RefObject<DataGrid>;
  reload: () => void;
}

export const BatchAssignModal = ({
  agents,
  selectedCuts,
  reload,
  gridRef,
}: BatchAssignModalProps) => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.assignAgentBatch,
  );

  const form = useForm<BatchAssignModalForm>({
    defaultValues: batchAssignModalFormDefaultValues,
    resolver: yupResolver(batchAssignModalFormValidationSchema),
  });

  const { handleSubmit, reset } = form;

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await callService({
          assignments: selectedCuts.map((cut) => ({
            agentId: values.agent,
            consumerUnitId: cut.consumerUnitId,
            tradeId: cut.tradeId,
          })),
        });

        if (success) {
          OToastManager.success("Unidades atribuídas com sucesso");
          modalManager.hide(batchAssignModalId);
          reload();
          gridRef?.current?.instance.clearSelection();
        } else {
          OToastManager.danger(
            getValidationMessages(error)?.[0]?.ErrorMessage ??
              "Erro ao atribuir unidades",
          );
        }
      }),
    [callService, gridRef, handleSubmit, reload, selectedCuts],
  );

  useEffect(() => {
    const cleanup = modalManager.on(batchAssignModalId, "modalClose", () => {
      reset(batchAssignModalFormDefaultValues);
    });
    return cleanup;
  }, [reset]);

  return (
    <ModalWithSelectOverflow
      id={batchAssignModalId}
      style={{ position: "absolute" }}
      position="center"
      backdrop={!loading}
    >
      <OModalHeader closeButton={!loading}>
        <OTypography size="lg">Atribuir em lote</OTypography>
      </OModalHeader>
      <OModalBody>
        <OTypography className="mb-4">
          {selectedCuts.length} unidades selecinadas. Atribuir a:
        </OTypography>
        <FormProvider {...form}>
          <ORow>
            <OCol xs={12} md={6}>
              <SelectSearchFieldV2
                options={agents
                  .map(({ name, id, level }) => ({
                    content: `${level} - ${name}`,
                    value: id,
                  }))
                  .sort((a, b) => a.content.localeCompare(b.content))}
                id="agent"
                name="agent"
                placeholder="Selecionar"
              />
            </OCol>
          </ORow>
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-end gap-3">
          <OButton
            type="dark"
            outline
            onClick={() => modalManager.hide(batchAssignModalId)}
            disabled={loading}
          >
            Cancelar
          </OButton>
          <LoadingButton loading={loading} onClick={submit}>
            Enviar
          </LoadingButton>
        </div>
      </OModalFooter>
    </ModalWithSelectOverflow>
  );
};
