import { Roles } from "roles/roles.types";

export const energiaRoles = {
  customer: {
    tab: {
      role: "admin:MLETabCustomer.View",
      description: "Visualização da tab 'MLE' na visão cliente",
    },
    tradesGuarantorsEdit: {
      role: "admin:MLETradesGuarantorsEditActionCustomer.View",
      description:
        "Visualização da ação 'Trades - Avalistas - Editar' na visão cliente",
    },
    billingReport: {
      role: "admin:MLEBillingReportPageCustomer.View",
      description:
        "Visualização do menu 'Relatórios de Faturamento' na visão cliente",
    },
    billingReportDetails: {
      role: "admin:MLEBillingReportDetailsPageCustomer.View",
      description:
        "Visualização do menu 'Relatórios de Faturamento - Detalhes' na visão cliente",
    },
    billingReportInvoices: {
      role: "admin:MLEBillingReportInvoicesPageCustomer.View",
      description:
        "Visualização do menu 'Relatórios de Faturamento - Invoices' na visão cliente",
    },
    billingReportSync: {
      role: "admin:MLEBillingReportSyncActionCustomer.View",
      description:
        "Visualização do menu 'Relatório de Faturamento - Sincronizar com o NES' na visão cliente",
    },
    consumerUnitsCreate: {
      role: "admin:MLEConsumerUnitsCreatePageCustomer.View",
      description:
        "Visualização do menu 'Unidades consumidoras - Adicionar' na visão cliente",
    },
    consumerUnitsDelete: {
      role: "admin:MLEConsumerUnitsDeleteActionCustomer.View",
      description:
        "Visualização da ação 'Unidades consumidoras - Remover' na visão cliente",
    },
    consumerUnitDetails: {
      role: "admin:MLEConsumerUnitDetailsPageCustomer.View",
      description:
        "Visualização do menu 'Consumidoras - Detalhes' na visão cliente",
    },
    consumerUnitDetailsEdit: {
      role: "admin:MLEConsumerUnitDetailsEditPageCustomer.View",
      description:
        "Visualização do menu 'Consumidoras - Editar' na visão cliente",
    },
    consumerUnitMigration: {
      role: "admin:MLEConsumerUnitMigrationPageCustomer.View",
      description:
        "Visualização do menu 'Consumidoras - Migração' na visão cliente",
    },
    consumerUnitMigrationStepType: {
      role: "admin:MLEConsumerUnitMigrationStepTypePageCustomer.View",
      description:
        "Visualização do menu 'Consumidoras - Migração - Step' na visão cliente",
    },
    consumerUnitTradesDocuments: {
      role: "admin:MLEConsumerUnitTradesDocumentsPageCustomer.View",
      description:
        "Visualização do menu 'Consumidoras - Trades - Documentos' na visão cliente",
    },
    contactsEdit: {
      role: "admin:MLEContactsEditActionsCustomer.View",
      description:
        "Visualização das ações para adicionar/editar/remover 'Contatos' na visão cliente",
    },
    diligenceStepsAdd: {
      role: "admin:MLEDiligenceStepsAddCustomer.View",
      description:
        "Visualização do botão para adicionar diligências na visão cliente",
    },
    diligenceStepsApprove: {
      role: "admin:MLEDiligenceStepsApproveCustomer.View",
      description:
        "Visualização do botão para aprovar diligências na visão cliente",
    },
    migrationStepsUpdateInfo: {
      role: "admin:MLEMigrationStepsUpdateInfoCustomer.View",
      description:
        "Visualização do botão para atualizar informações de uma etapa de migração na visão cliente",
    },
    migrationStepsEdit: {
      role: "admin:MLEMigrationStepsEditCustomer.View",
      description:
        "Visualização do botão para aprovar/concluir/pendenciar uma etapa de migração na visão cliente",
    },
    migrationStepsAddProperty: {
      role: "admin:MLEMigrationStepsAddPropertyCustomer.View",
      description:
        "Visualização do botão para adicionar propriedade a uma etapa de migração na visão cliente",
    },
    migrationStepPropertyActions: {
      role: "admin:MLEMigrationStepPropertyActionsCustomer.View",
      description:
        "Visualização das ações envolvendo uma propriedade de uma etapa de migração na visão cliente",
    },
    migrationStepsAddPendency: {
      role: "admin:MLEMigrationStepsAddPendencyCustomer.View",
      description:
        "Visualização do botão para adicionar pendência a uma etapa de migração na visão cliente",
    },
    migrationStepsAddPendencyNotes: {
      role: "admin:MLEMigrationStepsAddPendencyNotesCustomer.View",
      description:
        "Visualização do botão para adicionar anotações a uma pendência de uma etapa de migração na visão cliente",
    },
    migrationStepsEditPendency: {
      role: "admin:MLEMigrationStepsEditPendencyCustomer.View",
      description:
        "Visualização do botão para editar/deletar uma pendência de uma etapa de migração na visão cliente",
    },
    recreateMigration: {
      role: "admin:MLERecreateMigrationCustomer.View",
      description:
        "Visualização do botão para recriar uma migração na visão cliente",
    },
    migrationDateUpdate: {
      role: "admin:MLEMigrationDateUpdate.View",
      description:
        "Visualização do botão para atualiza a data de migração na visão cliente",
    },
  },
  product: {
    tab: {
      role: "admin:MLETabProduct.View",
      description: "Visualização da tab 'MLE' na visão produto",
    },
    billingReport: {
      role: "admin:MLEBillingReportPageProduct.View",
      description:
        "Visualização do menu 'Relatórios de Faturamento' na visão produto",
    },
    customer: {
      role: "admin:MLECustomerPageProduct.View",
      description: "Visualização do menu 'Clientes' na visão produto",
    },
    customerDetails: {
      role: "admin:MLECustomerDetailsPageProduct.View",
      description:
        "Visualização do menu 'Clientes - Detalhes' na visão produto",
    },
    customerConsumerUnitsCreate: {
      role: "admin:MLECustomerConsumerUnitsCreatePageProduct.View",
      description:
        "Visualização do menu 'Clientes - Unidades consumidoras - Adicionar' na visão produto",
    },
    consumerUnitsDelete: {
      role: "admin:MLECustomerConsumerUnitsDeleteActionProduct.View",
      description:
        "Visualização da ação 'Unidades consumidoras - Remover' na visão produto",
    },
    consumerUnit: {
      role: "admin:MLEConsumerUnitPageProduct.View",
      description:
        "Visualização do menu 'Unidades consumidoras' na visão produto",
    },
    consumerUnitCreate: {
      role: "admin:MLEConsumerUnitCreatePageProduct.View",
      description:
        "Visualização do menu 'Unidades consumidoras - Adicionar' na visão produto",
    },
    consumerUnitDetails: {
      role: "admin:MLEConsumerUnitDetailsPageProduct.View",
      description:
        "Visualização do menu 'Unidades consumidoras - Detalhes' na visão produto",
    },
    consumerUnitTrades: {
      role: "admin:MLEConsumerUnitTradesPageProduct.View",
      description:
        "Visualização do menu 'Unidades consumidoras - Trades' na visão produto",
    },
    consumerUnitTradesDocuments: {
      role: "admin:MLEConsumerUnitTradesDocumentsPageProduct.View",
      description:
        "Visualização do menu 'Unidades consumidoras - Trades - Documentos' na visão produto",
    },
    distributor: {
      role: "admin:MLEDistributorPageProduct.View",
      description: "Visualização do menu 'Distribuidoras' na visão produto",
    },
    distributorDetails: {
      role: "admin:MLEDistributorDetailsPageProduct.View",
      description:
        "Visualização do menu 'Distribuidoras - Detalhes' na visão produto",
    },
    distributorConfig: {
      role: "admin:MLEDistributorConfigPageProduct.View",
      description:
        "Visualização do menu 'Distribuidoras - Configuração' na visão produto",
    },
    distributorDiligence: {
      role: "admin:MLEDistributorDiligencePageProduct.View",
      description:
        "Visualização do menu 'Distribuidoras - Diligência' na visão produto",
    },
    distributorAdequator: {
      role: "admin:MLEDistributorAdequatorPageProduct.View",
      description:
        "Visualização do menu 'Distribuidoras - Adequadora' na visão produto",
    },
    distributorCreate: {
      role: "admin:MLEDistributorCreatePageProduct.View",
      description:
        "Visualização do menu 'Distribuidoras - Adicionar' na visão produto",
    },
    tasks: {
      role: "admin:MLETasksProduct.View",
      description: "Visualização do menu 'Tarefas' na visão produto",
    },
    diligenceAnalysis: {
      role: "admin:MLEDiligenceAnalysisPageProduct.View",
      description:
        "Visualização do menu 'Análise de migração' na visão produto",
    },
    diligenceAnalysisStep: {
      role: "admin:MLEDiligenceAnalysisStepPageProduct.View",
      description:
        "Visualização do menu 'Análise de migração - Análise da etapa' na visão produto",
    },
    trades: {
      role: "admin:MLETradesPageProduct.View",
      description: "Visualização do menu 'Trades' na visão produto",
    },
    tradesGridActions: {
      role: "admin:MLETradesGridActionsProduct.View",
      description:
        "Visualização da coluna de ações na grid de propostas na visão produto",
    },
    tradesDetails: {
      role: "admin:MLETradesDetailsPageProduct.View",
      description: "Visualização do menu 'Trades - Detalhes' na visão produto",
    },
    tradesDetailsGenerateDocuments: {
      role: "admin:MLETradesDetailsGenerateDocumentsPageProduct.View",
      description:
        "Visualização do menu 'Trades - Documentos' na visão produto",
    },
    tradesByIdDocumentsContingencyColumn: {
      role: "admin:MLETradesByIdDocumentsContingencyColumnProduct.View",
      description:
        "Visualização da coluna 'Contingência' na grid de documentos do trade na visão produto",
    },
    tradesConsumerUnit: {
      role: "admin:MLETradesConsumerUnitPageProduct.View",
      description:
        "Visualização do menu 'Trades - Unidades consumidoras' na visão produto",
    },
    tradesGuarantors: {
      role: "admin:MLETradesGuarantorsPageProduct.View",
      description: "Visualização do menu 'Trades - Avalistas' na visão produto",
    },
    tradesGuarantorsEdit: {
      role: "admin:MLETradesGuarantorsEditActionProduct.View",
      description:
        "Visualização da ação 'Trades - Avalistas - Editar' na visão produto",
    },
    tradesContracts: {
      role: "admin:MLETradesContractsPageProduct.View",
      description: "Visualização do menu 'Trades - Contratos' na visão produto",
    },
    generateMigrationDocuments: {
      role: "admin:MLEGenerateMigrationDocumentsButtonProduct.View",
      description:
        "Visualização do botão para gerar documentos de migração na visão produto",
    },
    generateContract: {
      role: "admin:MLEGenerateContractButtonProduct.View",
      description: "Visualização do botão para gerar contrato na visão produto",
    },
    managementAgents: {
      role: "admin:MLEManagementAgentsPageProduct.View",
      description:
        "Visualização do menu 'Gerenciamento - Agentes' na visão produto",
    },
    managementAgentAssignments: {
      role: "admin:MLEManagementAgentAssignmentsPageProduct.View",
      description:
        "Visualização do menu 'Gerenciamento - Atribuição de unidades consumidoras' na visão produto",
    },
    managementAnalysisLogs: {
      role: "admin:MLEManagementAnalysisLogsPageProduct.View",
      description:
        "Visualização do menu 'Gerenciamento - Logs de análise' na visão produto",
    },
    commonDomain: {
      role: "admin:MLECommonDomainPageProduct.View",
      description:
        "Visualização do menu 'Variáveis de configuração' na visão produto",
    },
    adequators: {
      role: "admin:MLEAdequatorsProduct.View",
      description:
        "Visualização da página 'Configuração de Diligência - Adequadoras' na visão produto",
    },
    createAdequator: {
      role: "admin:MLECreateAdequatorProduct.View",
      description:
        "Visualização da página 'Configuração de Diligência - Adequadoras - Adicionar' na visão produto",
    },
    updateAdequator: {
      role: "admin:MLEUpdateAdequatorProduct.View",
      description:
        "Visualização da página 'Configuração de Diligência - Adequadoras - Atualizar' na visão produto",
    },
    adequatorDistributor: {
      role: "admin:MLEAdequatorDistributorProduct.View",
      description:
        "Visualização da página 'Configuração de Diligência - Adequadoras - Distribuidoras' na visão produto",
    },
    diligenceConfigPropertiesType: {
      role: "admin:MLEDiligenceConfigPropertiesTypeProduct.View",
      description:
        "Visualização da página 'Configuração de Diligência - Tipos de Propriedades' na visão produto",
    },
    diligenceConfigCreatePropertyType: {
      role: "admin:MLEDiligenceConfigCreatePropertyTypeProduct.View",
      description:
        "Visualização da página 'Configuração de Diligência - Tipos de Propriedades - Adicionar' na visão produto",
    },
    diligenceConfigUpdatePropertyType: {
      role: "admin:MLEDiligenceConfigUpdatePropertyTypeProduct.View",
      description:
        "Visualização da página 'Configuração de Diligência - Tipos de Propriedades - Atualizar' na visão produto",
    },
    diligenceConfigStepsType: {
      role: "admin:MLEDiligenceConfigStepsTypeProduct.View",
      description:
        "Visualização da página 'Configuração de Diligência - Tipos de Steps' na visão produto",
    },
    diligenceConfigCreateStepType: {
      role: "admin:MLEDiligenceConfigCreateStepTypeProduct.View",
      description:
        "Visualização da página 'Configuração de Diligência - Tipos de Steps - Adicionar' na visão produto",
    },
    diligenceConfigUpdateStepType: {
      role: "admin:MLEDiligenceConfigUpdateStepTypeProduct.View",
      description:
        "Visualização da página 'Configuração de Diligência - Tipos de Steps - Atualizar' na visão produto",
    },
    diligenceConfigPendenciesType: {
      role: "admin:MLEDiligenceConfigPendenciesTypeProduct.View",
      description:
        "Visualização da página 'Configuração de Diligência - Tipos de Pendência' na visão produto",
    },
    diligenceConfigCreatePendencyType: {
      role: "admin:MLEDiligenceConfigCreatePendencyTypeProduct.View",
      description:
        "Visualização da página 'Configuração de Diligência - Tipos de Pendência - Adicionar' na visão produto",
    },
    diligenceConfigUpdatePendencyType: {
      role: "admin:MLEDiligenceConfigUpdatePendencyTypeProduct.View",
      description:
        "Visualização da página 'Configuração de Diligência - Tipos de Pendência - Atualizar' na visão produto",
    },
    diligenceConfigStepsConfig: {
      role: "admin:MLEDiligenceConfigStepsConfigProduct.View",
      description:
        "Visualização da página 'Configuração de Diligência - Configuração do Step' na visão produto",
    },
    diligenceConfigCreateStepConfig: {
      role: "admin:MLEDiligenceConfigCreateStepConfigProduct.View",
      description:
        "Visualização da página 'Configuração de Diligência - Configuração do Step - Adicionar' na visão produto",
    },
    diligenceConfigUpdateStepConfig: {
      role: "admin:MLEDiligenceConfigUpdateStepConfigProduct.View",
      description:
        "Visualização da página 'Configuração de Diligência - Configuração do Step - Atualizar' na visão produto",
    },
    diligenceConfigAdequators: {
      role: "admin:MLEDiligenceConfigAdequatorProduct.View",
      description:
        "Visualização da página 'Configuração de Diligência - Adequadoras' na visão produto",
    },
    diligenceConfigCreateAdequator: {
      role: "admin:MLEDiligenceConfigCreateAdequatorProduct.View",
      description:
        "Visualização da página 'Configuração de Diligência - Adequadoras - Adicionar' na visão produto",
    },
    diligenceConfigUpdateAdequator: {
      role: "admin:MLEDiligenceConfigUpdateAdequatorProduct.View",
      description:
        "Visualização da página 'Configuração de Diligência - Adequadoras - Atualizar' na visão produto",
    },
    diligenceConfigDocuments: {
      role: "admin:MLEDiligenceConfigDocumentProduct.View",
      description:
        "Visualização da página 'Configuração de Diligência - Documentos' na visão produto",
    },
    diligenceConfigCreateDocument: {
      role: "admin:MLEDiligenceConfigCreateDocumentProduct.View",
      description:
        "Visualização da página 'Configuração de Diligência - Documentos - Adicionar' na visão produto",
    },
    diligenceConfigUpdateDocument: {
      role: "admin:MLEDiligenceConfigUpdateDocumentProduct.View",
      description:
        "Visualização da página 'Configuração de Diligência - Documentos - Atualizar' na visão produto",
    },
    diligenceConfigDiligenceEvents: {
      role: "admin:MLEDiligenceConfigDiligenceEventsProduct.View",
      description:
        "Visualização da página 'Configuração de Diligência - Regras de Eventos' na visão produto",
    },
    diligenceConfigDiligenceEventsCreate: {
      role: "admin:MLEDiligenceConfigDiligenceEventsCreateProduct.View",
      description:
        "Visualização da página 'Configuração de Diligência - Regras de Eventos - Adicionar' na visão produto",
    },
    diligenceConfigDiligenceEventsDetails: {
      role: "admin:MLEDiligenceConfigDiligenceEventsDetailsProduct.View",
      description:
        "Visualização da página 'Configuração de Diligência - Regras de Eventos - Detalhes' na visão produto",
    },
    consumerUnitsImport: {
      role: "admin:MLEConsumerUnitsImportPageProduct.View",
      description:
        "Visualização da página 'Importação de unidades consumidoras' na visão produto",
    },
    consumerUnitsImportSpreadsheet: {
      role: "admin:MLEConsumerUnitsImportSpreadsheetPageProduct.View",
      description:
        "Visualização da página 'Importação de unidades consumidoras - Enviar planilha' na visão produto",
    },
    consumerUnitsImportByConsumerUnitsImportDataId: {
      role: "admin:MLEConsumerUnitsImportByConsumerUnitsImportDataIdPageProduct.View",
      description:
        "Visualização da página 'Importação de unidades consumidoras - Detalhes' na visão produto",
    },
    diligenceStepsMonitor: {
      role: "admin:MLEDiligenceStepsMonitorPageProduct.View",
      description:
        "Visualização da página 'Steps de migração' na visão produto",
    },
    diligenceConfigDeleteDocument: {
      role: "admin:MLEDiligenceConfigDeleteDocumentProduct.View",
      description:
        "Visualização da ação 'Configuração de Diligência - Documentos - Excluir' na visão produto",
    },
    updateAdequacyDistributors: {
      role: "admin:MLEUpdateAdequacyDistributorsProduct.View",
      description:
        "Visualização da ação de atualizar as distribuidoras de uma adequadora na visão produto",
    },
    updateDiligenceConfig: {
      role: "admin:MLEUpdateDiligenceConfigProduct.View",
      description:
        "Visualização da ação de atualizar configuração de diligência na visão produto",
    },
    diligenceContactsEdiActionst: {
      role: "admin:MLEDiligenceContactsEditActionsProduct.View",
      description:
        "Visualização das ações para adicionar/editar/remover 'Distribuidoras - Configuração da distribuidora - Contatos' na visão cliente",
    },
    updateDistributor: {
      role: "admin:MLEUpdateDistributorConfigProduct.View",
      description:
        "Visualização da ação de atualizar configuração da distribuidora na visão produto",
    },
    updateDistributoRegistration: {
      role: "admin:MLEUpdateDistributorRegistrationProduct.View",
      description:
        "Visualização da ação de atualizar dados cadastrais de distribuidora na visão produto",
    },
    taskActions: {
      role: "admin:MLETaskActionsProduct.View",
      description: "Visualização das ações em 'Tarefas' na visão produto",
    },
    addCommonDomain: {
      role: "admin:MLEAddCommonDomainProduct.View",
      description:
        "Visualização da ação de adicionar variável de configuração na visão produto",
    },
    editGridCommonDomain: {
      role: "admin:MLEEditGridCommonDomainProduct.View",
      description:
        "Visualização da ação de edtar/excluir uma variável de configuração na visão produto",
    },
    updateAdequatorDistributors: {
      role: "admin:MLEUpdateAdequatorDistributorProduct.View",
      description:
        "Visualização da ação de atualizar as distribuidoras de uma adequadora na visão produto",
    },
  },
} satisfies Roles;
