import {
  OFilterItem,
  OInputText,
  OOption,
  OSelect,
  OTypography,
} from "@maestro/react";
import { useState } from "react";
import { BankslipIdentifier } from "./identifier-filter-item.types";

export const IdentifierFilterItem = () => {
  const [identifierType, setIdentifierType] = useState<BankslipIdentifier>(
    BankslipIdentifier.DocumentNumber,
  );

  return (
    <OFilterItem>
      <OTypography slot="title">Identificador do boleto</OTypography>
      <div className="d-flex align-items-center justify-content-between py-2 px-2">
        <OSelect
          className="w-30 bg-transparent"
          aspect="flushed"
          iconSize="xl"
          placeholder="Nº Documento"
          defaultValue={BankslipIdentifier.DocumentNumber}
          dataAction=""
          dataLabel=""
        >
          <OOption
            className="bg-light"
            onClick={() => setIdentifierType(BankslipIdentifier.DocumentNumber)}
            value={BankslipIdentifier.DocumentNumber}
          >
            Nº Documento
          </OOption>
          <OOption
            className="bg-light"
            onClick={() => setIdentifierType(BankslipIdentifier.OurNumber)}
            value={BankslipIdentifier.OurNumber}
          >
            Nosso número
          </OOption>
          <OOption
            className="bg-light"
            onClick={() => setIdentifierType(BankslipIdentifier.DigitableLine)}
            value={BankslipIdentifier.DigitableLine}
          >
            Linha digitável
          </OOption>
          <OOption
            className="bg-light"
            onClick={() => setIdentifierType(BankslipIdentifier.BarCode)}
            value={BankslipIdentifier.BarCode}
          >
            Código de barras
          </OOption>
        </OSelect>

        <OInputText
          className="w-60"
          dataAction=""
          dataLabel=""
          id={identifierType}
          key={identifierType}
          name={identifierType}
          placeholder="Buscar..."
          aspect="flushed"
          data-filterparam={identifierType}
        />
      </div>
    </OFilterItem>
  );
};
