import { OToastManager } from "@maestro/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services/service";

export const useRemunerationFields = (editingTaxId?: string) => {
  const [bankAccounts, setBankAccounts] =
    useState<HubPartners.Admin.Account[]>();
  const [selectedOption, setSelectedOption] = useState("custom");
  const [loading, setLoading] = useState(false);

  const form = useFormContext();
  const { getValues, setValue, watch } = form;
  const watchIdentification = watch("identification");
  const watchIdentificationType = watch("identificationType");

  const isOtherAccount = useMemo(
    () => selectedOption === "custom",
    [selectedOption],
  );

  const getBankAccountsWhenEditing = useCallback(async () => {
    if (editingTaxId) {
      const params = {
        taxId: editingTaxId.replace(/[^\d]/g, ""),
      };

      setLoading(true);
      try {
        const { data } = await service.adminBankinghub.getAccounts(params);

        const bankData = data.map((bank) => ({
          bank: "208",
          branch: bank.agency.toString(),
          number: bank.number,
        }));

        const foundBankAccount = bankData.find(
          (bank) =>
            bank.branch === getValues("account").branch &&
            bank.number === getValues("account").number,
        );

        if (foundBankAccount) {
          setSelectedOption(
            `${foundBankAccount.bank}_${foundBankAccount.branch}_${foundBankAccount.number}`,
          );
        }

        setBankAccounts(bankData);
      } catch {
        OToastManager.danger(
          "Não foi possível obter as contas bancárias do PJ",
        );
      } finally {
        setLoading(false);
      }
    }
  }, [editingTaxId, getValues]);

  const getBankAccounts = useCallback(async () => {
    if (!watchIdentification || !watchIdentificationType || editingTaxId) {
      return;
    }

    const params = {
      taxId: watchIdentification.replace(/[^\d]/g, ""),
    };

    if (
      (watchIdentificationType === "CNPJ" &&
        watchIdentification.length === 14) ||
      (watchIdentificationType === "CPF" && watchIdentification.length === 11)
    ) {
      setLoading(true);
      try {
        const { data } = await service.adminBankinghub.getAccounts(params);

        const bankData = data.map((bank) => ({
          bank: "208",
          branch: bank.agency.toString(),
          number: bank.number,
        }));

        setBankAccounts(bankData);
      } catch {
        OToastManager.danger(
          "Não foi possível obter as contas bancárias do PJ",
        );
      } finally {
        setLoading(false);
      }
    }
  }, [editingTaxId, watchIdentification, watchIdentificationType]);

  useEffect(() => {
    getBankAccountsWhenEditing();
  }, [getBankAccountsWhenEditing]);

  useEffect(() => {
    if (!isOtherAccount) {
      const bankData = selectedOption.split("_");

      if (bankData.length === 3) {
        setValue("account.bank", bankData[0]);
        setValue("account.branch", bankData[1]);
        setValue("account.number", bankData[2]);
      }
    }
  }, [isOtherAccount, selectedOption, setValue]);

  useEffect(() => {
    getBankAccounts();
  }, [getBankAccounts]);

  return {
    bankAccounts,
    loading,
    isOtherAccount,
    selectedOption,
    setSelectedOption,
  };
};
