import { client } from "../client";
import { hubPartnersEndpoints } from "./hubpartners.endpoints";
import {
  GetBooks,
  GetCatalogs,
  GetOriginatorSubtypeResponse,
  GetPartnershipTypeResponse,
  GetOriginatorsByTaxIdDetailsResponse,
  GetOriginatorsByUuidOperatorsResponse,
  GetOriginatorsMainResponse,
  GetOriginatorsSearchResponse,
  GetProductLinks,
  GetProductsResponse,
  GetEconomicGroupsResponse,
  GetEconomicGroupOriginatorsResponse,
} from "./models";
import {
  GetOriginatorsByTaxIdDetailsParams,
  GetOriginatorsByUuidOperatorsParams,
  PostBooksRequest,
  PostCatalogsRequest,
  PostOperatorsBody,
  PostOriginatorsBody,
  PostPartnershipTypeRequest,
  PostProductLinkRequest,
  PostProductsRequest,
  PutBooksRequest,
  PutOriginatorByUuidBody,
  PutOriginatorByuuidIndicatorBody,
  PutOriginatorByuuidPermissionBody,
  PutPartnershipTypeRequest,
  PutProductLinkRequest,
  PutProductsRequest,
  PutProgonatorByUuidRemunerationBody,
  PostEconomicGroupRequest,
  PutEconomicGroupRequest,
  PostOriginatorsSubtypeRequest,
} from "./models/requests";
import { PutCatalogsRequest } from "./models/requests/put-catalogs.request";
import { PutOriginatorsSubtypeRequest } from "./models/requests/put-originators-subtype.request";
import { odataService } from "./odata/hubpartners-odata.service";
import { portalService } from "./portal/hubpartners-portal.service";

export const hubPartnersService = {
  odata: odataService,
  portal: portalService,
  getOriginatorSubtypes: () =>
    client.get<GetOriginatorSubtypeResponse>(
      hubPartnersEndpoints.admin.originatorSubtype.endpoint,
    ),
  getPartnershipTypes: () =>
    client.get<GetPartnershipTypeResponse>(
      hubPartnersEndpoints.admin.partnershipType.endpoint,
    ),
  getEconomicGroupOriginators: (originatorIdentification: string) =>
    client.post<GetEconomicGroupOriginatorsResponse>(
      hubPartnersEndpoints.admin.economicGroup.originators
        .byOriginatorIdentification.endpoint,
      {
        originatorIdentification,
      },
    ),
  getProducts: () =>
    client.get<GetProductsResponse>(
      hubPartnersEndpoints.admin.products.endpoint,
    ),
  getProductLinks: () =>
    client.get<GetProductLinks>(
      hubPartnersEndpoints.admin.productLink.endpoint,
    ),
  getBooks: () =>
    client.get<GetBooks>(hubPartnersEndpoints.admin.books.endpoint),
  getCatalogs: () =>
    client.get<GetCatalogs>(hubPartnersEndpoints.admin.catalogs.endpoint),

  getOriginators: () =>
    client.get<GetOriginatorsSearchResponse>(
      hubPartnersEndpoints.admin.originators.search,
    ),
  postProducts(body: PostProductsRequest) {
    return client.post(hubPartnersEndpoints.admin.products.endpoint, body);
  },

  postBooks(body: PostBooksRequest) {
    return client.post(hubPartnersEndpoints.admin.books.endpoint, body);
  },
  postCatalogs(body: PostCatalogsRequest) {
    return client.post(hubPartnersEndpoints.admin.catalogs.endpoint, body);
  },
  postLinks(body: PostProductLinkRequest) {
    return client.post(hubPartnersEndpoints.admin.productLink.endpoint, body);
  },
  postPartnershipType(body: PostPartnershipTypeRequest) {
    return client.post(
      hubPartnersEndpoints.admin.partnershipType.endpoint,
      body,
    );
  },
  postOriginatorsSubtype(body: PostOriginatorsSubtypeRequest) {
    return client.post(
      hubPartnersEndpoints.admin.originatorSubtype.endpoint,
      body,
    );
  },
  registerOriginator(body: PostOriginatorsBody) {
    return client.post(hubPartnersEndpoints.admin.originators.endpoint, body);
  },
  putPartnershipType(data: PutPartnershipTypeRequest) {
    const { uuid, ...body } = data;
    return client.put(
      `${hubPartnersEndpoints.admin.partnershipType.endpoint}/${uuid}`,
      body,
    );
  },
  putOriginatorsSubtype(data: PutOriginatorsSubtypeRequest) {
    const { uuid, ...body } = data;
    return client.put(
      `${hubPartnersEndpoints.admin.originatorSubtype.endpoint}/${uuid}`,
      body,
    );
  },
  putProducsts(data: PutProductsRequest) {
    const { uuid, ...body } = data;
    return client.put(
      `${hubPartnersEndpoints.admin.products.endpoint}/${uuid}`,
      body,
    );
  },
  putBooks(data: PutBooksRequest) {
    const { uuid, ...body } = data;
    return client.put(
      `${hubPartnersEndpoints.admin.books.endpoint}/${uuid}`,
      body,
    );
  },
  putCatalogs(data: PutCatalogsRequest) {
    const { uuid, ...body } = data;
    return client.put(
      `${hubPartnersEndpoints.admin.catalogs.endpoint}/${uuid}`,
      body,
    );
  },
  putLink(data: PutProductLinkRequest) {
    const { uuid, ...body } = data;
    return client.put(
      `${hubPartnersEndpoints.admin.productLink.endpoint}/${uuid}`,
      body,
    );
  },
  getOperatorsByOriginatorUuid(params: GetOriginatorsByUuidOperatorsParams) {
    return client.get<GetOriginatorsByUuidOperatorsResponse>(
      hubPartnersEndpoints.admin.originators.byUuid.operators(params.uuid),
    );
  },
  getMainOriginators() {
    return client.get<GetOriginatorsMainResponse>(
      hubPartnersEndpoints.admin.originators.main,
    );
  },
  originatorDetails(taxId: GetOriginatorsByTaxIdDetailsParams) {
    return client.get<GetOriginatorsByTaxIdDetailsResponse>(
      hubPartnersEndpoints.admin.originators.byTaxId.detail(taxId),
    );
  },
  updateOriginatorDetails(uuid: string, body: PutOriginatorByUuidBody) {
    return client.put(
      hubPartnersEndpoints.admin.originators.byUuid.endpoint(uuid),
      body,
    );
  },
  updateOriginatorPermission(
    uuid: string,
    body: PutOriginatorByuuidPermissionBody,
  ) {
    return client.put(
      hubPartnersEndpoints.admin.originators.byUuid.permission(uuid),
      body,
    );
  },
  updateOriginatorIndicator(
    uuid: string,
    body: PutOriginatorByuuidIndicatorBody,
  ) {
    return client.put(
      hubPartnersEndpoints.admin.originators.byUuid.indicator(uuid),
      body,
    );
  },
  updateOriginatorRemuneration(
    uuid: string,
    body: PutProgonatorByUuidRemunerationBody,
  ) {
    return client.put(
      hubPartnersEndpoints.admin.originators.byUuid.remuneration(uuid),
      body,
    );
  },
  createOperator(body: PostOperatorsBody) {
    return client.post(hubPartnersEndpoints.operatorProfiles.endpoint, body);
  },
  fetchAllEconomicGroups() {
    return client.get<GetEconomicGroupsResponse>(
      hubPartnersEndpoints.admin.economicGroup.endpoint,
    );
  },
  createEconomicGroup(body: PostEconomicGroupRequest) {
    return client.post(hubPartnersEndpoints.admin.economicGroup.endpoint, body);
  },
  updateEconomicGroup(body: PutEconomicGroupRequest) {
    return client.put(hubPartnersEndpoints.admin.economicGroup.endpoint, body);
  },
};
