import * as yup from "yup";

export type EditPartnershipTypeFormFields = HubPartners.Admin.PartnershipType;

export const editPartnershipTypeSchema: yup.ObjectSchema<EditPartnershipTypeFormFields> =
  yup.object({
    name: yup.string().required("Este campo é obrigatório"),
    code: yup.string().required("Este campo é obrigatório"),
    masterGatekeeperProfiles: yup.array().default([]),
    restrictedGatekeeperProfiles: yup.array().default([]),
    uuid: yup.string().required("Este campo é obrigatório"),
    segmentFilterCode: yup.string().required("Este campo é obrigatório"),
  });
