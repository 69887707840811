import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { customer } from "contexts/customer";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services/service";
import {
  updateOriginatorRemunerationDefaultValues,
  updateOriginatorRemunerationValidationSchema,
} from "./update-originator-remuneration.form";

export const useUpdateOriginatorRemuneration = () => {
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [uuid, setUuid] = useState("");

  const form = useForm({
    resolver: yupResolver(updateOriginatorRemunerationValidationSchema),
    defaultValues: updateOriginatorRemunerationDefaultValues,
  });
  const { handleSubmit, setValue } = form;

  const onSubmit = handleSubmit(async (values) => {
    setLoadingSubmit(true);
    try {
      await service.hubPartners.updateOriginatorRemuneration(uuid, values);
      OToastManager.success("Originador atualizado com sucesso!");
    } catch {
      OToastManager.danger("Erro ao atualizar remuneração do originador");
    } finally {
      setLoadingSubmit(false);
    }
  });

  const getInfo = useCallback(async () => {
    const customerUuid = customer.value?.identification;

    if (!customerUuid) {
      return;
    }

    setLoading(true);
    try {
      const { data } = await service.hubPartners.originatorDetails(
        customerUuid,
      );
      const details = data.data;

      if (details.account) {
        setValue("account.bank", details.account?.bank);
        setValue("account.branch", details.account?.agency);
        setValue("account.number", details.account?.account);
      }
      setValue("mirror.mirrorBook", details.mirror?.mirrorBook);
      setValue("mirror.mirrorStrategy", details.mirror?.mirrorStrategy);

      setUuid(details.uuid);
    } catch {
      OToastManager.danger("Falha ao obter detalhes do originador");
    } finally {
      setLoading(false);
    }
  }, [setValue]);

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  return {
    form,
    loading,
    loadingSubmit,
    onSubmit,
  };
};
