import { OToastManager } from "@maestro/core";
import { useCallback, useState } from "react";
import { service } from "services";

export const useGuarantorDetails = () => {
  const [guarantor, setGuarantor] =
    useState<HubOnboardingRelationships.Guarantor.Guarantor>();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const getGuarantor = useCallback(async (uuid: string) => {
    try {
      setLoading(true);
      const { data } = await service.onboardingRelationships.getGuarantor({
        getOnboardingByUuidQueryBody: { onboardingUuid: uuid },
      });
      setGuarantor(data);
      setHasError(false);
    } catch (err) {
      OToastManager.danger("Não foi possível buscar o avalista");
      setHasError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    guarantor,
    loading,
    hasError,
    getGuarantor,
  };
};
