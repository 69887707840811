import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { grid } from "./cnab-agreement.grid";
import { useCNABAgreement } from "./cnab-agreement.hook";

export const CNABAgreement = () => {
  const { agreementsList = [], loading } = useCNABAgreement();
  const gridRef = useGridRef();

  return (
    <div>
      <GridTemplate
        pageTitle={<PageTitle title="Convênio - Arquivos e transmissões" />}
        gridRef={gridRef}
      >
        <ODataGridGenerator
          gridRef={gridRef}
          grid={grid}
          dataSource={agreementsList}
          loading={loading}
        />
      </GridTemplate>
    </div>
  );
};
