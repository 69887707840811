import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { getValidationMessages } from "../../../../../../utils";

export const useGridActions = (
  stepType: HubEnergy.DiligenceStepTypeResponse,
  reload: () => void,
) => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.deleteDiligenceStepTypeById,
  );

  const deleteStepType = useCallback(async () => {
    const { success, error } = await callService(stepType.id);

    if (success) {
      OToastManager.success("Tipo de step excluído com sucesso");
      reload();
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0]?.ErrorMessage ??
          "Erro ao excluir o tipo de step",
      );
    }
  }, [callService, stepType, reload]);

  return { deleteStepType, loading };
};
