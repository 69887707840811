import { OIcon, OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import { CopyButton } from "components/copy-button";
import { DetailsFields } from "components/details-card";
import dayjs from "dayjs";
import { currencySymbol } from "utils/currency";
import { tradeFields } from "../../../../../components/trade";

export const consumerUnitBasicDataFields = (
  consumerUnit: HubEnergy.ConsumerUnitDetailsResponse,
): DetailsFields => ({
  basicData: {
    title: "Unidade consumidora",
    items: [
      {
        label: "Nome",
        value: consumerUnit?.name,
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Razão social",
        value: consumerUnit?.entity.officialName,
        grid: { md: 6, lg: 4 },
      },
      {
        label: "CNPJ",
        value: (
          <div className="d-flex align-items-center gap-2">
            {masks.cpfCnpj(consumerUnit?.entity.taxId)}{" "}
            <CopyButton
              action="detalhes_do_cliente:botao:copiar-identificacao"
              label="copiar-identificacao"
              target={consumerUnit?.entity.taxId}
              toasterMessage="Identificação copiada."
            />
          </div>
        ),
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Unidade principal?",
        value: consumerUnit?.isMainUnit ? (
          <OIcon
            category="fa"
            icon="fa-check-square"
            type="info"
            className="d-inline-block"
            size="lg"
          />
        ) : (
          <OIcon
            category="fa"
            icon="fa-times-square"
            type="danger"
            className="d-inline-block"
            size="lg"
          />
        ),
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Endereço",
        value: (
          <>
            <OTypography>
              {[
                consumerUnit?.address?.street,
                consumerUnit?.address?.number,
                consumerUnit?.address?.complement,
                consumerUnit?.address?.neighborhood,
              ]
                .filter((value) => !!value)
                .join(", ")}
            </OTypography>
            <OTypography>
              {[
                consumerUnit?.address?.city,
                consumerUnit?.address?.state,
                masks.cep(consumerUnit?.address?.zipCode),
              ]
                .filter((value) => !!value)
                .join(" - ")}
            </OTypography>
          </>
        ),

        grid: { md: 6, lg: 4 },
      },
    ],
  },
});

export const consumerUnitCustomerFields = (
  consumerUnit: HubEnergy.ConsumerUnitDetailsResponse,
): DetailsFields => ({
  customer: {
    title: "Matriz",
    items: [
      {
        label: "Razão social",
        value: consumerUnit?.customer.officialName,
        grid: { md: 6, lg: 4 },
      },
      {
        label: "CNPJ",
        value: (
          <div className="d-flex align-items-center gap-2">
            {masks.cpfCnpj(consumerUnit?.customer.taxId)}{" "}
            <CopyButton
              action="detalhes_do_cliente:botao:copiar-identificacao"
              label="copiar-identificacao"
              target={consumerUnit?.customer.taxId}
              toasterMessage="Identificação copiada."
            />
          </div>
        ),
        grid: { md: 6, lg: 4 },
      },
    ],
  },
});

export const consumerUnitTradeFields = (
  consumerUnit: HubEnergy.ConsumerUnitDetailsResponse,
): DetailsFields => ({
  trade: {
    title: "Proposta",
    items: [
      {
        label: "Economia mensal estimada",
        value: masks.currency(
          consumerUnit?.currentTradeEstimatedMonthlyEconomy,
          currencySymbol.BRL,
        ),
      },
      ...tradeFields(consumerUnit.currentTrade).trade.items,
    ],
  },
});

export const consumerUnitEnergyFields = (
  consumerUnit: HubEnergy.ConsumerUnitDetailsResponse,
): DetailsFields => ({
  consumerUnitDistributor: {
    title: "Informações de consumo",
    items: [
      {
        label: "Distribuidora Vinculada",
        value: `${
          consumerUnit?.currentDistributor?.name ??
          consumerUnit?.currentDistributor?.officialName
        } - ${masks.cpfCnpj(consumerUnit?.currentDistributor?.taxId)}`,
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Número de instalação",
        value: consumerUnit?.installationNumber,
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Modalidade tarifária",
        value: `${consumerUnit?.fareGroup ?? ""} ${
          consumerUnit?.modality ?? ""
        }`,
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Consumo medio da unidade (MWh)",
        value: consumerUnit?.volume,
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Volume (MWm)",
        value: consumerUnit?.volumePerHour,
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Demanda contratada ponta (kW)",
        value: consumerUnit?.peakDemand,
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Demanda contratada fora-ponta (kW)",
        value: consumerUnit?.offPeakDemand,
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Percentual de alocação do volume mínimo na ponta",
        value: masks.percentage(consumerUnit.minimumPeakAllocation),
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Data da migração",
        value:
          consumerUnit?.migrationDate &&
          dayjs(consumerUnit?.migrationDate).format("DD/MM/YYYY"),
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Data final de suprimento",
        value:
          consumerUnit?.supplyEndDate &&
          dayjs(consumerUnit?.supplyEndDate).format("DD/MM/YYYY"),
        grid: { md: 6, lg: 4 },
      },
    ],
  },
});
