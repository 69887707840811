import { Roles } from "roles/roles.types";

export const cambioRoles = {
  customer: {
    tab: {
      role: "admin:FXTabCustomer.View",
      description: "Visualização da tab 'Câmbio' na visão cliente",
    },
    quotesPage: {
      role: "admin:FXQuotesPageCustomer.View",
      description: "Visualização da página 'Operações' na visão cliente",
    },
    quotesDetailsPage: {
      role: "admin:FXQuotesDetailsPageCustomer.View",
      description:
        "Visualização da página 'Operações - Detalhes' na visão cliente",
    },
    ordersPage: {
      role: "admin:FXOrdersPageCustomer.View",
      description: "Visualização da página 'Ordens' na visão cliente",
    },
    ordersDetailsPage: {
      role: "admin:FXOrdersDetailsPageCustomer.View",
      description:
        "Visualização da página 'Ordens - Detalhes' na visão cliente",
    },
    preTicketsPage: {
      role: "admin:FXPreTicketsPageCustomer.View",
      description: "Visualização da página 'Pré-Tickets' na visão cliente",
    },
    preTicketsDetailsPage: {
      role: "admin:FXPreTicketsDetailsPageCustomer.View",
      description:
        "Visualização da página 'Pré-Tickets - Detalhes' na visão cliente",
    },
    limitsPage: {
      role: "admin:FXLimitsPageCustomer.View",
      description: "Visualização da página 'Limites' na visão cliente",
    },
    limitsDetailsPage: {
      role: "admin:FXLimitsDetailsPageCustomer.View",
      description:
        "Visualização da página 'Limites - Detalhes' na visão cliente",
    },
    incorrectOrdersPage: {
      role: "admin:FXIncorrectOrdersPageCustomer.View",
      description:
        "Visualização da página 'Ordens incorretas' na visão cliente",
    },
  },
  product: {
    tab: {
      role: "admin:FXTabProduct.View",
      description: "Visualização da tab 'Câmbio' na visão produto",
    },
    dashboardPage: {
      role: "admin:FXDashboardPageProduct.View",
      description: "Visualização da página 'Dashboard' na visão produto",
    },
    quotesPage: {
      role: "admin:FXQuotesPageProduct.View",
      description: "Visualização da página 'Operações' na visão produto",
    },
    quotesDetailsPage: {
      role: "admin:FXQuotesDetailsPageProduct.View",
      description:
        "Visualização da página 'Operações - Detalhes' na visão produto",
    },
    ordersPage: {
      role: "admin:FXOrdersPageProduct.View",
      description: "Visualização da página 'Ordens' na visão produto",
    },
    ordersDetailsPage: {
      role: "admin:FXOrdersDetailsPageProduct.View",
      description:
        "Visualização da página 'Ordens - Detalhes' na visão produto",
    },
    preTicketsPage: {
      role: "admin:FXPreTicketsPageProduct.View",
      description: "Visualização da página 'Pré-Tickets' na visão produto",
    },
    preTicketsDetailsPage: {
      role: "admin:FXPreTicketsDetailsPageProduct.View",
      description:
        "Visualização da página 'Pré-Tickets - Detalhes' na visão produto",
    },
    limitsPage: {
      role: "admin:FXLimitsPageProduct.View",
      description: "Visualização da página 'Limites' na visão produto",
    },
    limitsDetailsPage: {
      role: "admin:FXLimitsDetailsPageProduct.View",
      description:
        "Visualização da página 'Limites - Detalhes' na visão produto",
    },
    counterpartiesPage: {
      role: "admin:FXCounterpartiesPageProduct.View",
      description: "Visualização da página 'Contrapartes' na visão produto",
    },
    counterpartiesAddPage: {
      role: "admin:FXCounterpartiesAddPageProduct.View",
      description:
        "Visualização da página 'Contrapartes - Adicionar' na visão produto",
    },
    counterpartiesCounterpartyDetailsPage: {
      role: "admin:FXCounterpartiesCounterpartyDetailsPageProduct.View",
      description:
        "Visualização da página 'Contrapartes - Contraparte - Detalhes' na visão produto",
    },
    contingencyCommonDomainPage: {
      role: "admin:FXContingencyCommonDomainPageProduct.View",
      description:
        "Visualização da página 'Contingência - Variáveis de Configuração' na visão produto",
    },
    contingencyNaturesAllPage: {
      role: "admin:FXContingencyNaturesAllPageProduct.View",
      description:
        "Visualização da página 'Contingência - Cadastro de Naturezas - Naturezas' na visão produto",
    },
    contingencyNaturesAllCreatePage: {
      role: "admin:FXContingencyNaturesAllCreatePageProduct.View",
      description:
        "Visualização da página 'Contingência - Cadastro de Naturezas - Naturezas - Adicionar' na visão produto",
    },
    contingencyNaturesAllUpdatePage: {
      role: "admin:FXContingencyNaturesAllUpdatePageProduct.View",
      description:
        "Visualização da página 'Contingência - Cadastro de Naturezas - Naturezas - Atualizar' na visão produto",
    },
    contingencyNaturesDocumentGroupsPage: {
      role: "admin:FXContingencyNaturesDocumentGroupsPageProduct.View",
      description:
        "Visualização da página 'Contingência - Cadastro de Naturezas - Grupo de Documentos' na visão produto",
    },
    contingencyNaturesDocumentGroupsCreatePage: {
      role: "admin:FXContingencyNaturesDocumentGroupsCreatePageProduct.View",
      description:
        "Visualização da página 'Contingência - Cadastro de Naturezas - Grupo de Documentos - Adicionar' na visão produto",
    },
    contingencyNaturesDocumentGroupsUpdatePage: {
      role: "admin:FXContingencyNaturesDocumentGroupsUpdatePageProduct.View",
      description:
        "Visualização da página 'Contingência - Cadastro de Naturezas - Grupo de Documentos - Atualizar' na visão produto",
    },
    contingencyNaturesDocumentConfigsPage: {
      role: "admin:FXContingencyNaturesDocumentConfigsPageProduct.View",
      description:
        "Visualização da página 'Contingência - Cadastro de Naturezas - Configuração de Documentos' na visão produto",
    },
    contingencyNaturesDocumentConfigsCreatePage: {
      role: "admin:FXContingencyNaturesDocumentConfigsCreatePageProduct.View",
      description:
        "Visualização da página 'Contingência - Cadastro de Naturezas - Configuração de Documentos - Adicionar' na visão produto",
    },
    contingencyNaturesDocumentConfigsUpdatePage: {
      role: "admin:FXContingencyNaturesDocumentConfigsUpdatePageProduct.View",
      description:
        "Visualização da página 'Contingência - Cadastro de Naturezas - Configuração de Documentos - Atualizar' na visão produto",
    },
    contingencyNaturesGenericFieldsPage: {
      role: "admin:FXContingencyNaturesGenericFieldsPageProduct.View",
      description:
        "Visualização da página 'Contingência - Cadastro de Naturezas - Campos Genéricos' na visão produto",
    },
    contingencyNaturesGenericFieldsCreatePage: {
      role: "admin:FXContingencyNaturesGenericFieldsCreatePageProduct.View",
      description:
        "Visualização da página 'Contingência - Cadastro de Naturezas - Campos Genéricos - Adicionar' na visão produto",
    },
    contingencyNaturesGenericFieldsUpdatePage: {
      role: "admin:FXContingencyNaturesGenericFieldsUpdatePageProduct.View",
      description:
        "Visualização da página 'Contingência - Cadastro de Naturezas - Campos Genéricos - Atualizar' na visão produto",
    },
    contingencyBeneficiaryBanksPage: {
      role: "admin:FXContingencyBeneficiaryBanksPageProduct.View",
      description:
        "Visualização da página 'Contingência - Bancos de Beneficiário' na visão produto",
    },
    contingencyBeneficiaryBanksFormPage: {
      role: "admin:FXContingencyBeneficiaryBanksFormPageProduct.View",
      description:
        "Visualização da página 'Contingência - Bancos de Beneficiário - Formulário' na visão produto",
    },
    contingencyCurrenciesPage: {
      role: "admin:FXContingencyCurrenciesPageProduct.View",
      description:
        "Visualização da página 'Contingência - Moedas' na visão produto",
    },
    contingencyCurrenciesFormPage: {
      role: "admin:FXContingencyCurrenciesFormPageProduct.View",
      description:
        "Visualização da página 'Contingência - Moedas - Formulário' na visão produto",
    },
    contingencyCurrenciesDetailsPage: {
      role: "admin:FXContingencyCurrenciesDetailsPageProduct.View",
      description:
        "Visualização da página 'Contingência - Moedas - Detalhes' na visão produto",
    },
    contingencyCountriesPage: {
      role: "admin:FXContingencyCountriesPageProduct.View",
      description:
        "Visualização da página 'Contingência - Países' na visão produto",
    },
    contingencyCountriesFormPage: {
      role: "admin:FXContingencyCountriesFormPageProduct.View",
      description:
        "Visualização da página 'Contingência - Países - Formulário' na visão produto",
    },
    incorrectOrdersPage: {
      role: "admin:FXIncorrectOrdersPageProduct.View",
      description:
        "Visualização da página 'Ordens incorretas' na visão de produto",
    },
  },
} satisfies Roles;
