import { client } from "../client";
import { accEndpoints } from "./acc.endpoints";
import { accAdminCustomerService } from "./customer/acc-admin-customer.service";
import { PostAdminAccGenerateContract } from "./models/requests/post-admin-acc-generate-contract.request";
import { PostAdminCommonBody } from "./models/requests/post-admin-common.request";
import { PostAdminCounterpartyBody } from "./models/requests/post-admin-counterparty.request";
import { GetAdminCommonResponse } from "./models/responses/get-admin-common.response";
import { PostAdminCounterpartyResponse } from "./models/responses/post-admin-counterparty.response";
import { accODataService } from "./odata/acc-odata.service";

export const accService = {
  generateAccContract(body: PostAdminAccGenerateContract) {
    return client.post(accEndpoints.admin.acc.generateContract, body);
  },
  adminCustomer: accAdminCustomerService,
  odata: accODataService,
  getCommonDomain() {
    return client.get<GetAdminCommonResponse>(accEndpoints.admin.common);
  },
  postCommonDomain(body: PostAdminCommonBody) {
    return client.post(accEndpoints.admin.common, body);
  },
  postCounterparty(body: PostAdminCounterpartyBody) {
    return client.post<PostAdminCounterpartyResponse>(
      accEndpoints.admin.counterparty,
      body,
    );
  },
};
