import {
  OButton,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OOption,
  ORFieldInputDate,
  ORFieldSelect,
  OTypography,
} from "@maestro/react";
import dayjs from "dayjs";
import { FormProvider } from "react-hook-form";
import { useSearchModal } from "./search-modal.hook";
import { CompanySearch } from "../../../_compose/company-search";

const brands = ["Visa", "MasterCard", "AmericanExpress", "Elo", "HiperCard"];

export const searchModalId = "search-modal";

export const SearchModal = () => {
  const { form, submit, clear } = useSearchModal();

  return (
    <OModal id={searchModalId}>
      <OModalHeader>
        <OTypography size="lg">Parâmetros de busca</OTypography>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <div className="d-flex flex-column gap-4">
            <CompanySearch
              mode="Sacado"
              label="Nome ou identificação do sacado"
              name="sacados"
              placeholder="Selecione o sacado"
              multiple={false}
            />
            <CompanySearch
              mode="Cedente"
              label="Nome ou identificação do cedente"
              name="cedentes"
              placeholder="Selecione o cedente"
              multiple={false}
            />
            <ORFieldSelect
              dataAction="entuba_sugestao:select:bandeiras"
              dataLabel="bandeira"
              id="bandeira"
              name="bandeira"
              label="Bandeira de cartão"
            >
              {brands.map((brand) => (
                <OOption key={brand} value={String(brand)}>
                  {brand}
                </OOption>
              ))}
            </ORFieldSelect>
            <ORFieldInputDate
              id="dataVencimentoInicial"
              name="dataVencimentoInicial"
              label="Data de vencimento inicial"
              disabledDate={(date) =>
                dayjs(date.toDate()).isBefore(dayjs().startOf("day"))
              }
            />
          </div>
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-between">
          <OButton
            dataAction="entuba_sugestao:botao:limpar"
            dataLabel="limpar"
            type="dark"
            outline
            onClick={() => clear()}
          >
            Limpar
          </OButton>
          <OButton
            dataAction="entuba_sugestao:botao:buscar"
            dataLabel="buscar"
            onClick={() => submit()}
          >
            Buscar
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
