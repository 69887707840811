import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { SharkSimulateResponse } from "services/shark/models/responses";
import { TotalAmountCardComponent } from "../_compose/total-amount-card.component";

export const AnticipationDetailsGenerator = (
  simulation?: SharkSimulateResponse,
) => {
  const { contractNumber } = useParams<{ contractNumber: string }>();
  const [searchParams] = useSearchParams();

  const selectedInstallmentsText = useMemo(() => {
    const selectedInstallments =
      searchParams.get("installments")?.split(",") ?? [];
    if (selectedInstallments.length === 1) return selectedInstallments[0];
    if (selectedInstallments.length > 1) {
      const lastInstallment = selectedInstallments.pop();
      return `${selectedInstallments.join(", ")} e ${lastInstallment}`;
    }
    return "-";
  }, [searchParams]);

  return {
    details: {
      items: [
        {
          label: "Contrato",
          value: contractNumber,
          grid: { sm: 3 },
        },
        {
          label: "Parcelas a antecipar",
          value: selectedInstallmentsText,
          grid: { sm: 3 },
        },
        {
          label: "Valor total das parcelas",
          value: simulation
            ? masks.currency(simulation.amount_at_maturity, "R$", ".")
            : "-",
          grid: { sm: 3 },
        },
        {
          label: "Desconto",
          value: simulation
            ? masks.currency(
                simulation.amount_at_maturity - simulation.curve_value,
                "R$",
                ".",
              )
            : "-",
          grid: { sm: 3 },
        },
      ],
    },
    totalAmount: {
      items: [
        {
          value: <TotalAmountCardComponent simulation={simulation} />,
        },
      ],
    },
  } satisfies DetailsFields;
};
