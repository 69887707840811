import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import {
  LoanPartnerForm,
  LoanPartnersFormValues,
} from "../../../../components/loan-partner/loan-partner-form.component";
import { loanPartnerValidationFormSchema } from "../../../../components/loan-partner/loan-partner-form.schemas";
import { EmprestimosProdutoParceiroEditarRouteQueryParams } from "../../../../routes/emprestimos.route-params";

type LoanEditPartnerFormValues = LoanPartnersFormValues & { id?: number };

export const EditPartnerPage = () => {
  const [loading, setLoading] = useState(false);

  const { value, hasError } =
    useParseFromSearchParams<EmprestimosProdutoParceiroEditarRouteQueryParams>(
      "data",
    );

  const form = useForm<LoanEditPartnerFormValues>({
    resolver: yupResolver(loanPartnerValidationFormSchema),
  });

  const { handleSubmit, reset } = form;

  const editPartner = useCallback(async (values: LoanEditPartnerFormValues) => {
    try {
      if (!values.id) return;
      setLoading(true);

      await service.hubLoan.updatePartner({ ...values, id: values.id });

      OToastManager.success("Convênio editado com sucesso");
    } catch {
      OToastManager.danger("Ocorreu algum erro. Tente novamente mais tarde");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    value && reset(value as LoanEditPartnerFormValues);
  }, [reset, value]);

  return (
    <ContentTemplate
      hasError={hasError}
      loading={!value && !hasError}
      noValue
      render={() => (
        <FormTemplate
          pageTitle={<PageTitle title="Editar convênio" />}
          actions={
            <LoadingButton
              loading={loading}
              dataAction="emprestimos_cadastrar_perfis:botao:salvar"
              dataLabel="salvar"
              onClick={handleSubmit(editPartner)}
            >
              Salvar
            </LoadingButton>
          }
        >
          <CardTemplate>
            <FormProvider {...form}>
              <LoanPartnerForm />
            </FormProvider>
          </CardTemplate>
        </FormTemplate>
      )}
    />
  );
};
