import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { DetailsTemplate } from "templates/details-template";
import { GridTemplate } from "templates/grid-template";
import { configurableColumnsGrid } from "./configurable-columns.grid";

export const ConfigurableColumnsPage = () => {
  return (
    <DetailsTemplate pageTitle={<PageTitle title="Colunas configuráveis" />}>
      <GridTemplate
        actions={
          <RefreshGridButton
            onClick={() => {
              !configurableColumnsGrid.datagrid.dataSource?.isLoading() &&
                configurableColumnsGrid.datagrid.dataSource?.reload();
            }}
          />
        }
      >
        <ODataGridGenerator grid={configurableColumnsGrid} />
      </GridTemplate>
    </DetailsTemplate>
  );
};
