import { modalManager } from "@maestro/core";
import {
  OButton,
  OModal,
  OModalFooter,
  OModalHeader,
  OTypography,
} from "@maestro/react";
import {
  EstrategiaForm,
  EstrategiaFormLabels,
  EstrategiaFormLabelsEnum,
} from "pages/recebiveis/components/estrategia-form";
import { UseFormReturn } from "react-hook-form";

interface UpdateEstrategiaConfirmationModalProps {
  submit: () => void;
  form: UseFormReturn<EstrategiaForm, any>;
}

export const updateEstrategiaConfirmationModalId =
  "update-estrategia-confirmation-modal";

export const UpdateEstrategiaConfirmationModal = ({
  submit,
  form,
}: UpdateEstrategiaConfirmationModalProps) => {
  return (
    <>
      {form.formState?.dirtyFields ? (
        <OModal
          id={updateEstrategiaConfirmationModalId}
          position="center"
          backdrop
        >
          <OModalHeader>
            <OTypography size="lg">
              Por favor, verifique as informações atualizadas antes de enviar.
            </OTypography>
            <OTypography className="mt-3">
              {Object.keys(form.formState.dirtyFields).map((key) => (
                <OTypography size="sm" key={key} className="mt-1">
                  {EstrategiaFormLabels[key as EstrategiaFormLabelsEnum]}:{" "}
                  {form.getValues(key as keyof EstrategiaForm)}
                </OTypography>
              ))}
            </OTypography>
          </OModalHeader>
          <OModalFooter>
            <div className="d-flex justify-content-end gap-3">
              <OButton
                type="dark"
                outline
                onClick={() =>
                  modalManager.hide(updateEstrategiaConfirmationModalId)
                }
                className="me-auto"
              >
                Cancelar
              </OButton>
              <OButton
                onClick={() => {
                  submit();
                  modalManager.hide(updateEstrategiaConfirmationModalId);
                }}
              >
                Enviar
              </OButton>
            </div>
          </OModalFooter>
        </OModal>
      ) : (
        <>
          {submit()}
          {modalManager.hide(updateEstrategiaConfirmationModalId)}
        </>
      )}
    </>
  );
};
