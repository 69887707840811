import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useQuoteList } from "../../quote-list.context";
import {
  searchModalDefaulValues,
  searchModalValidationSchema,
} from "./search-modal.form";
import { CompanySearchForm } from "./search-modal.types";

export const useSearchModal = () => {
  const { setFilterPayload } = useQuoteList();

  const form = useForm<CompanySearchForm>({
    defaultValues: searchModalDefaulValues,
    resolver: yupResolver(searchModalValidationSchema),
  });

  const { handleSubmit, reset } = form;

  const clear = useCallback(() => reset(searchModalDefaulValues), [reset]);

  const submit = useMemo(
    () =>
      handleSubmit((values)=> {
        setFilterPayload({
          cedente: values.cedentes.at(0)!,
          bandeira: values.bandeira,
          dataVencimentoInicial: values.dataVencimentoInicial ? values.dataVencimentoInicial?.toDate() : null,
          sacado: values.sacados ? values.sacados.at(0)! : null,
        });
      }),
    [handleSubmit, setFilterPayload],
  );

  return { form, submit, clear };
};
