import { OLoader } from "@maestro/react";
import { SelectSearchField } from "components/select-search";
import { SelectSearchFieldOption } from "components/select-search/field/select-search-field.types";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { getPropertyLabel } from "../../../../../../../utils";
import { AddPendencyForm } from "../../add-pendency-modal.form";

interface DiligenceStepPropertySelectorProps {
  diligenceHasError: boolean;
  diligenceLoading: boolean;
  diligence: HubEnergy.DiligenceResponse | undefined;
}

export const DiligenceStepPropertySelector = ({
  diligence,
  diligenceHasError,
  diligenceLoading,
}: DiligenceStepPropertySelectorProps) => {
  const { watch } = useFormContext<AddPendencyForm>();
  const stepWatch = watch("stepId");

  const properties = useMemo(
    () =>
      diligence?.steps
        .find((s) => s.id === stepWatch)
        ?.properties.map<SelectSearchFieldOption<number>>((property) => ({
          label: getPropertyLabel(property),
          value: property.id,
        })) ?? [],
    [diligence?.steps, stepWatch],
  );

  return (
    <div className="d-flex align-items-center flex-fill">
      <SelectSearchField
        options={properties}
        id="propertyId"
        name="propertyId"
        label="Propriedade"
        disabled={diligenceHasError || diligenceLoading || !properties?.length}
        placeholder="Selecionar"
      />
      {diligenceLoading && <OLoader size="sm" />}
    </div>
  );
};
