import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { AdminAccTradeResponse } from "services/acc/models/types/acc-trades/acc-trades.model";
import { currencySymbol } from "utils/currency";
import { format } from "utils/date";

export const adminCustomerAccTradesDetailsGenerator = (
  value: AdminAccTradeResponse | null,
) => {
  if (!value) return {} as DetailsFields;

  const quoteDetails = {
    quote: {
      title: "Operação",
      items: [
        {
          label: "Id",
          value: value.id,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Dt. Criação",
          value: format.dateTime(value?.quoteSimulation?.createdDate),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Dt. Liquidação",
          value: format.dateTime(value?.quoteSimulation?.disbursementDate),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Status",
          value: value?.quoteSimulation?.status || "UNDEFINED",
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    currency: {
      title: "Moeda da operação",
      items: [
        {
          label: "Moeda",
          value: value.quoteSimulation?.sellCurrencyType,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Cotação",
          value: value?.quoteSimulation?.exchangeRatio
            ?.toString()
            .replace(".", ","),
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    actualValue: {
      title: "Valor real",
      items: [
        {
          label: "Vr. Moeda",
          value: masks.currency(
            value?.quoteSimulation?.amount?.toFixed(2).replace(".", ","),
            value.quoteSimulation?.buyCurrencyType,
            ".",
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Vr. VET",
          value: masks.currency(
            value?.quoteSimulation?.vet,
            currencySymbol.BRL,
            ".",
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Vr. Reais",
          value: masks.currency(
            value?.quoteSimulation?.nationalCurrencyTotal,
            currencySymbol.BRL,
            ".",
          ),
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    quoteTaxes: {
      visible: !!value?.quoteSimulation?.taxes,
      title: "Taxas",
      items: [
        {
          label: "IOF",
          value: `${masks.currency(
            value?.quoteSimulation?.taxes?.iofAmount,
            currencySymbol.BRL,
            ".",
          )} (${
            masks.percentage(value?.quoteSimulation?.taxes?.iofRate) || "0,00%"
          })`,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "ISS",
          value: `${masks.currency(
            value?.quoteSimulation?.taxes?.issAmount,
            currencySymbol.BRL,
            ".",
          )} (${
            masks.percentage(value?.quoteSimulation?.taxes?.issRate) || "0,00%"
          })`,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "PIS",
          value: `${masks.currency(
            value?.quoteSimulation?.taxes?.pisAmount,
            currencySymbol.BRL,
            ".",
          )} (${
            masks.percentage(value?.quoteSimulation?.taxes?.pisRate) || "0,00%"
          })`,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "CIDE",
          value: `${masks.currency(
            value?.quoteSimulation?.taxes?.cideAmount,
            currencySymbol.BRL,
            ".",
          )} (${
            masks.percentage(value?.quoteSimulation?.taxes?.cideRate) || "0,00%"
          })`,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "IRRF",
          value: `${masks.currency(
            value?.quoteSimulation?.taxes?.irrfAmount,
            currencySymbol.BRL,
            ".",
          )} (${
            masks.percentage(value?.quoteSimulation?.taxes?.irrfRate) || "0,00%"
          })`,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "COFINS",
          value: `${masks.currency(
            value?.quoteSimulation?.taxes?.cofinsAmount,
            currencySymbol.BRL,
            ".",
          )} (${
            masks.percentage(value?.quoteSimulation?.taxes?.cofinsRate) ||
            "0,00%"
          })`,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    accProposal: {
      title: "Dados da proposta",
      items: [
        {
          label: "Moeda",
          value: value.accProposal.currency,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Total",
          value: masks.currency(
            value?.accProposal.amount,
            value.accProposal.currency,
            ".",
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Data de expiração",
          value: format.dateTime(value?.accProposal.expirationDate),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Data de vencimento",
          value: format.dateTime(value?.accProposal.settlementDate),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Taxa de juros",
          value: masks.percentage(value?.accProposal.interestRate) || "0,00%",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Risco",
          value: value?.accProposal.ratingType,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  } satisfies DetailsFields;

  return quoteDetails;
};
