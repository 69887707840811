import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { GetProposalDocumentItem } from "services/b2b/agro/models";
import { GridTemplate } from "templates/grid-template";
import { ParceirosAgroProdutoByProposalIdRouteParams } from "../../../../../../routes/parceiros.route-params";
import { proposalDocumentsGrid } from "./proposal-document.grid";

export const ProposalDocumentsPage = () => {
  const gridRef = useGridRef();
  const { proposalId } =
    useParams<ParceirosAgroProdutoByProposalIdRouteParams>();

  if (!proposalId) throw new Error("No proposalId");

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<GetProposalDocumentItem>(() =>
        service.b2bAgro
          .getProposalDocuments(proposalId)
          .then(({ data }) => {
            return data;
          })
          .catch(() => {
            throw new Error("Erro ao buscar documentos da proposta");
          }),
      ),
    [proposalId],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Documentos da proposta" />}
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={proposalDocumentsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
