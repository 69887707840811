import { modalManager, OToastManager } from "@maestro/core";
import { OIcon } from "@maestro/react";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { UnstyledButton } from "components/unstyled-button";
import { service } from "services";
import { gridStorage } from "utils/storage";
import {
  DocumentTemplateModal,
  documentTemplateModalId,
} from "../../../../../../../components/document-template-modal";
import { getValidationMessages } from "../../../../../../../utils";
import { GetPropertyConfigsReturn } from "./property-configs-grid.types";

export const buildDataSource = (
  diligenceStepConfigId: number,
  fetch: GetPropertyConfigsReturn["callService"],
) =>
  dataSourceCustomStoreGenerator<HubEnergy.DiligenceStepPropertyConfigResponse>(
    () =>
      fetch(diligenceStepConfigId).then(({ success, error, response }) => {
        if (success) return response.data.response;

        const errorMessage =
          getValidationMessages(error)?.[0].ErrorMessage ??
          "Erro ao buscas as propriedades";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
    {
      customStoreOptions: {
        insert: async (values) => {
          try {
            const { data } =
              await service.hubEnergy.upsertDiligenceStepPropertyConfig({
                diligenceStepConfigId,
                isPublic: values.isPublic,
                minimumAmount: values.minimumAmount,
                typeId: values.typeId,
                documentTypeId: values.documentTypeId,
              });
            OToastManager.success("Propriedade adicionada");
            return data.response;
          } catch (err) {
            const errorMessage =
              getValidationMessages(err)?.[0].ErrorMessage ??
              "Erro ao adicionar propriedade";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }
        },
        update: async (oldValues, newValues) => {
          try {
            const newData = { ...oldValues, ...newValues };

            const { data } =
              await service.hubEnergy.upsertDiligenceStepPropertyConfig({
                diligenceStepConfigId,
                diligenceStepPropertyConfigId: oldValues.id,
                isPublic: newData.isPublic,
                minimumAmount: newData.minimumAmount,
                typeId: newData.typeId,
                documentTypeId: newData.documentTypeId,
              });
            OToastManager.success("Propriedade atualizada");
            return data.response;
          } catch (err) {
            const errorMessage =
              getValidationMessages(err)?.[0].ErrorMessage ??
              "Erro ao atualizar propriedade";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }
        },
        remove: async (values) => {
          try {
            await service.hubEnergy.deleteDiligenceStepPropertyConfigById(
              values.id,
            );
            OToastManager.success("Propriedade excluída");
          } catch (err) {
            const errorMessage =
              getValidationMessages(err)?.[0].ErrorMessage ??
              "Erro ao excluir propriedade";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }
        },
      },
    },
  );

const datagridSettings: ODataGridGeneratorConfig<HubEnergy.DiligenceStepPropertyConfigResponse>["datagrid"] =
  {
    noDataText: "Nenhuma propriedade",
    stateStoring: gridStorage("propertyConfigsGrid"),
    editing: {
      confirmDelete: true,
      allowDeleting: true,
      allowUpdating: true,
    },
    onInitNewRow: (e) => {
      e.data.isPublic = false;
    },
    onEditorPreparing: (e) => {
      e.editorOptions = {
        ...e.editorOptions,
        dropDownOptions: {
          ...e.editorOptions?.dropDownOptions,
          minWidth: 400,
        },
        itemTemplate: (itemData, _, itemElement) => {
          (itemElement as HTMLElement).setAttribute("title", itemData.label);
          return itemData.label;
        },
      };
    },
    columnResizingMode: "nextColumn",
  };

export const propertyConfigsGrid = (
  propertyTypes: HubEnergy.DiligenceStepPropertyTypeResponse[],
  documentTypes: HubEnergy.DocumentTypeResponse[],
): ODataGridGeneratorConfig<HubEnergy.DiligenceStepPropertyConfigResponse> => {
  return {
    datagrid: datagridSettings,
    columns: [
      {
        caption: "Público",
        dataField: "isPublic",
        dataType: "boolean",
        width: 100,
        validationRules: [
          {
            type: "custom",
            validationCallback: ({ value }) => typeof value === "boolean",
          },
        ],
      },
      {
        caption: "Tipo",
        dataField: "typeId",
        dataType: "string",
        validationRules: [{ type: "required" }],
        lookup: {
          dataSource: propertyTypes.map(({ name, id, label }) => ({
            value: id,
            label: `${label} - [${name}]`,
          })),
          displayExpr: "label",
          valueExpr: "value",
        },
      },
      {
        caption: "Tipo do documento",
        dataField: "documentTypeId",
        dataType: "string",
        lookup: {
          dataSource: documentTypes.map(({ name, id, label }) => ({
            value: id,
            label: `${label} - [${name}]`,
          })),
          allowClearing: true,
          displayExpr: "label",
          valueExpr: "value",
        },
      },
      {
        type: "buttons",
        buttons: ["edit", "delete", { template: "documentTemplateButton" }],
      },
    ],
    templates: [
      {
        name: "documentTemplateButton",
        render: ({ data }) => {
          if (!data.id || data.type.mask !== "DOCUMENT") return null;
          return (
            <>
              <UnstyledButton
                style={{ verticalAlign: "bottom" }}
                className="d-inline"
                title="Template do documento"
                onClick={() =>
                  modalManager.show(documentTemplateModalId(data.id))
                }
              >
                <OIcon category="orq" icon="orq-file" className="d-inline" />
              </UnstyledButton>
              <DocumentTemplateModal
                propertyConfigId={data.id}
                documentTemplate={data.documentTemplate}
              />
            </>
          );
        },
      },
    ],
  };
};
