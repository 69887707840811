import { Roles } from "roles/roles.types";

export const accRoles = {
  customer: {
    tab: {
      role: "admin:AccTabCustomer.View",
      description: "Visualização da tab 'ACC' na visão cliente",
    },
    tradesPage: {
      role: "admin:AccTradesPageCustomer.View",
      description: "Visualização da página 'Operações' na visão cliente",
    },
    tradesDetailsPage: {
      role: "admin:AccTradesDetailsPageCustomer.View",
      description:
        "Visualização da página 'Operações - Detalhes' na visão cliente",
    },
    contractsPage: {
      role: "admin:AccContractsPageCustomer.View",
      description: "Visualização da página 'Contratos' na visão cliente",
    },
    counterpartyDetailsPage: {
      role: "admin:AccCounterpartiesDetailsPageProduct.View",
      description:
        "Visualização da página 'Contraparte - Detalhes' na visão cliente",
    },
    proposalsPage: {
      role: "admin:AccProposalsPageCustomer.View",
      description: "Visualização da página 'Propostas' na visão cliente",
    },
    proposalsDetailsPage: {
      role: "admin:AccProposalsDetailsPageCustomer.View",
      description:
        "Visualização da página 'Propostas - Detalhes' na visão cliente",
    },
  },
  product: {
    tab: {
      role: "admin:AccTabProduct.View",
      description: "Visualização da tab 'ACC' na visão produto",
    },
    proposalsPage: {
      role: "admin:AccProposalsPageProduct.View",
      description: "Visualização da página 'Propostas' na visão produto",
    },
    tradesPage: {
      role: "admin:AccTradesPageProduct.View",
      description: "Visualização da página 'Operações' na visão produto",
    },
    counterpartiesPage: {
      role: "admin:AccCounterpartiesPageProduct.View",
      description: "Visualização da página 'Contrapartes' na visão produto",
    },
    counterpartiesAddPage: {
      role: "admin:AccCounterpartiesAddPageProduct.View",
      description:
        "Visualização da página 'Contrapartes - Adicionar' na visão produto",
    },
    contingencyCommonDomainPage: {
      role: "admin:AccContingencyCommonDomainPageProduct.View",
      description:
        "Visualização da página 'Contingência - Variáveis de Configuração' na visão produto",
    },
  },
} satisfies Roles;
