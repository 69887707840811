import { masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { service } from "services";
import { endpoints } from "services/endpoints";
import { buildCustomerLink } from "utils/build-customer-link";
import { currencySymbol } from "utils/currency";
import { corporateRouter } from "routes/corporate-router.context";

export const exchangeOdataCounterpartiesGrid = {
  datagrid: {
    noDataText: "Nenhuma contraparte encontrada",
    headerFilter: { visible: true, allowSearch: true },
    onDataErrorOccurred: (e) => {
      if (e.error) e.error.message = "Erro ao buscar contrapartes.";
    },
    dataSource: dataSourceODataStoreGenerator(
      endpoints.hubFx.odata.adminCounterpartiesOData,
    ),
  },
  columns: [
    {
      type: "buttons",
      allowExporting: false,
      role: roles.cambio.product.counterpartiesCounterpartyDetailsPage.role,
      cellRender: ({ data }) => {
        if (!!data.Identification) {
          return (
            <DetailsLink
              href={buildCustomerLink(
                data.Identification,
                corporateRouter.routes.cambio.customer.quotes.path
              )}
            />
          );
        }
        return null;
      },
    },
    {
      dataField: "Id",
      caption: "Id",
      dataType: "number",
    },
    {
      caption: "Nome",
      dataField: "Name",
    },
    {
      caption: "Identificação",
      dataField: "Identification",
      format: (value) => masks.cpfCnpj(value),
    },
    {
      caption: "Cge",
      dataField: "Cge",
    },
    {
      caption: "Limite em aberto (Remessa)",
      dataField: "OpenOutgoingOperationsLimitAmount",
      customizeText: ({ value }) =>
        masks.currency(value, currencySymbol.USD, ".") || "Taxa padrão",
    },
    {
      caption: "Limite em aberto (Internação)",
      dataField: "OpenIncomingOperationsLimitAmount",
      customizeText: ({ value }) =>
        masks.currency(value, currencySymbol.USD, ".") || "Taxa padrão",
    },
    {
      caption: "Pré-Ticket habilitado",
      dataField: "HasPreTicketEnabled",
    },
  ],
} satisfies ODataGridGeneratorConfig<
  NonNullable<
    Awaited<
      ReturnType<typeof service.hubFx.odata.getCounterparties>
    >["data"]["value"]
  >[number]
>;
