import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { GridTemplate } from "templates/grid-template";
import { useContactsFavored } from "./contacts.hook";

export const ContactsFavored = () => {
  const { grid, dataSource, getClerkContacts } = useContactsFavored();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Contatos" />}
      actions={
        <RefreshGridButton
          onClick={() => {
            getClerkContacts();
            dataSource.reload();
          }}
        />
      }
    >
      <ODataGridGenerator grid={grid} dataSource={dataSource} />
    </GridTemplate>
  );
};
