import * as yup from "yup";

export type UpdateOriginatorIndicationFormFields = Pick<
  HubPartners.Admin.NewOriginator,
  "indicatorOriginatorUUID" | "indicatorOperatorUUID"
> & {
  hasIndicator: string[];
};

export const updateOriginatorIndcationValidationSchema: yup.ObjectSchema<UpdateOriginatorIndicationFormFields> =
  yup.object({
    hasIndicator: yup
      .array()
      .of(yup.string().required("Este campo é obrigatório"))
      .default([]),
    indicatorOriginatorUUID: yup
      .string()
      .test(
        "requiredWhenIndicated",
        "Selecionar originador que indicou",
        (value, context) =>
          (!!value && context.parent.hasIndicator.includes("check")) ||
          (!value && !context.parent.hasIndicator.includes("check")),
      ),
    indicatorOperatorUUID: yup
      .string()
      .test(
        "requiredWhenIndicated",
        "Selecionar operador que indicou",
        (value, context) =>
          (!!value && context.parent.hasIndicator.includes("check")) ||
          (!value && !context.parent.hasIndicator.includes("check")),
      ),
  });

export const updateOriginatorIndcationDefaultValues: UpdateOriginatorIndicationFormFields =
  {
    hasIndicator: [],
    indicatorOriginatorUUID: undefined,
    indicatorOperatorUUID: undefined,
  };
