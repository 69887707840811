import { useServiceCall } from "hooks/service-call";
import debounce from "lodash/debounce";
import uniqBy from "lodash/uniqBy";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { service } from "services";
import { SelectedEntity } from "../../../canceling.types";
import { buildCompanyOptions } from "./company-search-canceling.utils";

export const useCompanySearchCanceling = (
  mode: "Sacado" | "Cedente",
  name: string,
) => {
  const [selected, setSelected] = useState<SelectedEntity[]>([]);

  const {
    callService: getAllCompanies,
    hasError,
    loading,
    value,
  } = useServiceCall(service.quickfin.getAllCompanies);

  const outerForm = useFormContext();

  const {
    setValue: outerSetValue,
    formState: {
      errors: { [name]: outerError },
    },
    watch: outerWatch,
  } = outerForm;

  const inputOuterWatch = outerWatch(name);

  const localForm = useForm<{ input: string | undefined }>();

  const {
    watch: localWatch,
    setError: localSetError,
    setValue: localSetValue,
    clearErrors: localClearErrors,
    formState: {
      errors: { input: localError },
    },
  } = localForm;

  const inputWatcher = localWatch("input");

  const debouncedGetCompanies = useMemo(
    () => debounce(getAllCompanies, 500),
    [getAllCompanies],
  );

  const selectCompany = useCallback((id: string, label: string) => {
    setSelected((oldSelected) =>
      uniqBy(
        [
          ...oldSelected,
          {
            id,
            label,
          },
        ],
        "id",
      ),
    );
  }, []);

  const removeCompany = useCallback((id: string) => {
    setSelected((oldSelected) =>
      oldSelected.filter((company) => company.id !== id),
    );
  }, []);

  useEffect(() => {
    if (inputWatcher && inputWatcher.length >= 3) {
      debouncedGetCompanies({
        CompanyType: mode,
        CompanyFilter: inputWatcher,
      });
    }
  }, [debouncedGetCompanies, inputWatcher, mode]);

  useEffect(() => {
    if (hasError)
      localSetError("input", {
        message: "Erro ao buscar as empresas",
      });
  }, [hasError, localSetError]);

  useEffect(() => {
    localSetValue("input", undefined);
    outerSetValue(
      name,
      selected.map((company) => company.id),
    );
  }, [selected, localSetValue, outerSetValue, name]);

  useEffect(() => {
    if (outerError?.message) {
      localSetError("input", {
        message: outerError.message as string,
      });
    } else {
      localClearErrors("input");
    }
  }, [outerError, localSetError, localClearErrors]);

  useEffect(() => {
    if (inputOuterWatch.length === 0) {
      setSelected([]);
    }
  }, [inputOuterWatch.length]);

  const options = useMemo(() => {
    return buildCompanyOptions(value?.companies, selectCompany);
  }, [selectCompany, value]);

  return {
    inputWatcher,
    loading,
    localError,
    localForm,
    options,
    removeCompany,
    selected,
  };
};
