import dayjs from "dayjs";
import moment from "moment";
import { NotesFilterForm } from "pages/recebiveis/pages/produto/titulos/nota-a-nota/_compose/note-filter/note-filter.types";
import { PostAdminReceivablesSummaryBody } from "services/quickfin/models";

export const statesOptions = [
  "Inicial",
  "AprovadoCedente",
  "PendenteAprovacaoBanco",
  "AprovadoBanco",
  "DisponivelParaCedente",
  "PendenteAprovacaoCedente",
  "PendenteOnboarding",
  "AprovadoOnboarding",
  "PendenteSolicitacaoTrade",
  "PendenteReleaseTrade",
  "TradeSolicitado",
  "TradeDesembolsado",
  "TradeLiquidado",
  "TradeEmAtraso",
  "Recusado",
  "Removido",
  "EmCancelamento",
  "FundingProprioRecusado",
  "PendenteContratacao",
  "PendenteValidacaoTaxas",
  "AguardandoRegistro",
  "PendenteEligibilidade",
];

export const bodyInitialValue: PostAdminReceivablesSummaryBody = {
  receivableIdentification: [],
  externalIds: [],
  contractNumbers: [],
  strategyGuids: [],
  receivableId: [],
  tradeId: [],
  operationId: [],
  borderoId: [],
  tipoProdutoList: ["CCC"],
  currentStateList: [],
  fromTradeDate: "",
  toTradeDate: "",
  fromDisbursementDate: "",
  toDisbursementDate: "",
  fromMaturityDate: "",
  toMaturityDate: "",
  fromCreatedAt: "",
  toCreatedAt: "",
  fromUpdatedAt: dayjs().format("YYYY-MM-DD"),
  toUpdatedAt: dayjs().format("YYYY-MM-DD"),
  sacadoIdList: [],
};

export const newDuplicatasFilterDefaultValues: NotesFilterForm = {
  sacadoIdList: [],
  cedenteIdList: [],
  tipoProdutoList: ["CCC"],
  currentStateList: [],
  receivableIdentification: [],
  externalIds: [],
  contractNumbers: [],
  strategyGuids: [],
  receivableId: [],
  tradeId: [],
  borderoId: [],
  operationId: [],
  creditorIds: [],
  marketplaceIds: [],
  portfolioIds: [],
  tradeDate: null,
  disbursementDate: null,
  maturityDate: null,
  createdAt: null,
  updatedAt: [moment(), moment()],
};

export const duplicatasNoteFilterModalId = "note-filter-modal";

export const buildPayload = (
  values: NotesFilterForm,
  cedenteId: string,
): PostAdminReceivablesSummaryBody => ({
  borderoId: values.borderoId,
  currentStateList: values.currentStateList,
  fromCreatedAt: values.createdAt
    ? values.createdAt[0].format("YYYY/MM/DD")
    : "",
  fromDisbursementDate: values.disbursementDate
    ? values.disbursementDate[0].format("YYYY/MM/DD")
    : "",
  fromMaturityDate: values.maturityDate
    ? values.maturityDate[0].format("YYYY/MM/DD")
    : "",
  fromTradeDate: values.tradeDate
    ? values.tradeDate[0].format("YYYY/MM/DD")
    : "",
  fromUpdatedAt: values.updatedAt
    ? values.updatedAt[0].format("YYYY/MM/DD")
    : "",
  operationId: values.operationId,
  receivableId: values.receivableId,
  receivableIdentification: values.receivableIdentification,
  externalIds: values.externalIds,
  contractNumbers: values.contractNumbers,
  strategyGuids: values.strategyGuids,
  sacadoIdList: values.sacadoIdList,
  tipoProdutoList: values.tipoProdutoList,
  toCreatedAt: values.createdAt ? values.createdAt[1].format("YYYY/MM/DD") : "",
  toDisbursementDate: values.disbursementDate
    ? values.disbursementDate[1].format("YYYY/MM/DD")
    : "",
  toMaturityDate: values.maturityDate
    ? values.maturityDate[1].format("YYYY/MM/DD")
    : "",
  toTradeDate: values.tradeDate ? values.tradeDate[1].format("YYYY/MM/DD") : "",
  toUpdatedAt: values.updatedAt ? values.updatedAt[1].format("YYYY/MM/DD") : "",
  tradeId: values.tradeId,
  cedenteIdList: [cedenteId],
});
