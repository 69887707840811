import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { TooltipIcon } from "components/tooltip-icon";
import dayjs from "dayjs";
import { ReceivablesReprocess } from "services/quickfin/models";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";

export const reprocessGrid = (
  setSelectedReceivables: React.Dispatch<
    React.SetStateAction<ReceivablesReprocess[] | undefined>
  >,
): ODataGridGeneratorConfig<ReceivablesReprocess> => ({
  datagrid: {
    noDataText: "Nenhum título disponível.",
    filterRow: {
      visible: false,
    },
    headerFilter: {
      visible: true,
      allowSearch: true,
    },
    pager: {
      allowedPageSizes: [10, 20, 30],
      showPageSizeSelector: true,
      showInfo: true,
      infoText: "Página {0} de {1} ({2} títulos)",
    },
    remoteOperations: true,
    onSelectionChanged: (evt) => setSelectedReceivables(evt.selectedRowsData),
  },
  columns: [
    {
      dataField: "ReceivableId",
      caption: "Id Recebível",
    },
    {
      dataField: "TradeId",
      caption: "Id da trade",
    },
    {
      dataField: "TipoProduto",
      caption: "Tipo de recebível",
    },
    {
      dataField: "State",
      caption: "Status",
      allowHeaderFiltering: false,
      allowSorting: false,
      cellRender: ({ data }) => (
        <span>
          {data.State}{" "}
          
          <TooltipIcon
            maxWidth="600px"
            content={
              <ul>
                {data.ErrorValidacao.map((error, idx) => (
                  <li key={idx}>{error}</li>
                ))}
              </ul>
            }
          />
        </span>
      ),
    },
    {
      dataField: "Sacado",
      caption: "Sacado",
    },
    {
      dataField: "SacadoCnpj",
      caption: "CNPJ do sacado",
      cellRender: ({ data }) => masks.cnpj(data.SacadoCnpj),
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
    {
      dataField: "Cedente",
      caption: "Cedente",
    },
    {
      dataField: "CedenteCnpj",
      caption: "CNPJ do cedente",
      cellRender: ({ data }) => masks.cnpj(data.CedenteCnpj),
      calculateFilterExpression: calculateTaxIdFilterExpression,
    },
    {
      dataField: "ValorVencimento",
      caption: "Valor vencimento",
      allowHeaderFiltering: false,
      cellRender: ({ data }) => masks.currency(data.ValorVencimento),
    },
    {
      dataField: "ValorDesembolso",
      caption: "Valor desembolso",
      allowHeaderFiltering: false,
      cellRender: ({ data }) => masks.currency(data.ValorDesembolso),
    },
    {
      dataField: "ValorDesembolsoCalculado",
      caption: "Valor desembolso calculado",
      allowHeaderFiltering: false,
      cellRender: ({ data }) => masks.currency(data.ValorDesembolsoCalculado),
    },
    {
      dataField: "TaxaTotal",
      caption: "Taxa total BTG",
      format: { type: "percent", precision: 15 },
      allowHeaderFiltering: false
    },
    {
      dataField: "Rebate",
      caption: "Rebate",
      allowHeaderFiltering: false,
      cellRender: ({ data }) => masks.currency(data.Rebate),
    },
    {
      dataField: "DataVencimento",
      caption: "Data de vencimento",
      allowHeaderFiltering: false,
      cellRender: ({ data }) =>
        data.DataVencimento
          ? dayjs(data.DataVencimento).format("DD/MM/YYYY")
          : dayjs().format("DD/MM/YYYY"),
    },
    {
      dataField: "CreatedAt",
      caption: "Criado em",
      allowHeaderFiltering: false,
      cellRender: ({ data }) => dayjs(data.CreatedAt).format("DD/MM/YYYY"),
    },
    {
      dataField: "UpdatedAt",
      caption: "Atualizado em",
      sortOrder: "desc",
      allowHeaderFiltering: false,
      cellRender: ({ data }) => dayjs(data.UpdatedAt).format("DD/MM/YYYY"),
    },
    {
      dataField: "BorderoId",
      caption: "Id de importação",
    },
  ],
  selection: {
    mode: "multiple",
    selectAllMode: "allPages",
    showCheckBoxesMode: "always",
  },
});
