import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import { ODataGridGenerator } from "components/data-grid";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { IconButton } from "components/icon-button";
import { PageTitle } from "components/page-title";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GetSnakeCompanyByIdResponse } from "services/fidc/models/responses";
import { service } from "services/service";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { GridTemplate } from "templates/grid-template";
import { getSearchParams } from "utils/search-params/get-search-params";
import { interactionFinancialsGrid, interactionPartnersGrid } from "./grids";
import { InteractionDetailsSearchParams } from "./interaction-details.type";

export const InteractionDetails = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [interaction, setInteraction] = useState<GetSnakeCompanyByIdResponse>();

  const fetchInteraction = useCallback(async () => {
    const { interactionId } = getSearchParams<InteractionDetailsSearchParams>();

    if (!interactionId) {
      return;
    }

    setLoading(true);
    try {
      const { data } = await service.fidc.getInteractionDetails(interactionId);
      setInteraction(data);
      setHasError(false);
    } catch {
      OToastManager.danger("Erro ao buscar detalhes da interação");
      setHasError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  const buildAddFinancialContactLink = () => {
    const { routes } = corporateRouter;

    const baseUrl = routes.fidc.product.interactions.details.add.path;

    const stringifyedData = JSON.stringify(interaction);
    const searchParams = new URLSearchParams();
    searchParams.set("contact", stringifyedData);

    return baseUrl + "?" + searchParams.toString();
  };

  useEffect(() => {
    fetchInteraction();
  }, [fetchInteraction]);

  return (
    <ContentTemplate
      loading={loading}
      hasError={hasError}
      value={interaction}
      errorComponent={
        <ErrorComponent
          messageTitle="Ocorreu um erro ao buscar os detalhes da interação."
          messageParagraph="Clique no botão para tentar novamente"
        >
          <TryAgainButton onClick={() => fetchInteraction()} />
        </ErrorComponent>
      }
      render={() => (
        <DetailsTemplate
          pageTitle={
            <PageTitle
              title={`${interaction?.name} - ${masks.cnpj(interaction?.taxId)}`}
            />
          }
        >
          <div className="d-flex flex-column gap-5">
            <GridTemplate pageTitle={<PageTitle title="Sócios" />}>
              <ODataGridGenerator
                grid={interactionPartnersGrid}
                dataSource={interaction?.partners ?? []}
              />
            </GridTemplate>
            <GridTemplate
              pageTitle={<PageTitle title="Contatos financeiros" />}
              actions={
                <IconButton
                  role={roles.fidc.product.interactionsDetailsAddPage.role}
                  icon={{ category: "orq", icon: "orq-add-plus", size: "lg" }}
                  dataAction=""
                  dataLabel=""
                  onClick={() => navigate(buildAddFinancialContactLink())}
                >
                  Adicionar
                </IconButton>
              }
            >
              <ODataGridGenerator
                grid={interactionFinancialsGrid}
                dataSource={interaction?.financials ?? []}
              />
            </GridTemplate>
          </div>
        </DetailsTemplate>
      )}
    />
  );
};
