import { ISizes } from "@maestro/core";
import { masks } from "@maestro/utils";
import {
  CategoryScale,
  Chart as ChartJS,
  ChartType,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  TooltipItem,
} from "chart.js";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
import { DashboardByChannelResponses } from "services/hubfx/models/responses";
import { currencySymbol } from "utils/currency";
import { TableStyle } from "./exchange-product-dashboard.styles";
import { generateDecreasingHex } from "./exchange-product-dashboard.utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

type DataSource<T> = {
  key: string;
  header: string;
  footer: string;
  colLg?: ISizes;
  cellRender: (props: { data: T }) => JSX.Element;
}[];

export const exchangeProductDashboardMap: DataSource<DashboardByChannelResponses> =
  [
    {
      key: "counterparties-beneficiaries",
      header: "Clientes/Beneficiários",
      footer: "Qtd. total de contrapartes cadastradas",
      cellRender: ({ data }) => (
        <TableStyle>
          <tr>
            <td>Clientes:</td>
            <td>{data.counterparties.count}</td>
          </tr>
          <tr>
            <td>Clientes ativos:</td>
            <td>{data.counterparties.activeCount}</td>
          </tr>
          <tr>
            <td>Contas beneficiárias:</td>
            <td>{data.beneficiaries.count}</td>
          </tr>
          <tr>
            <td>Contas beneficiárias ativas:</td>
            <td>{data.beneficiaries.activeCount}</td>
          </tr>
        </TableStyle>
      ),
      colLg: 4,
    },
    {
      key: "operations-vet",
      header: "Dados sobre as operações:",
      footer:
        "VET: cotação, taxas e spread; Total e total em trânsito: valor e spread.",
      cellRender: ({ data }) => {
        return (
          <TableStyle>
            <tr>
              <td className="w-80">Qtd.:</td>
              <td>
                {data.operationsByCurrency.reduce(
                  (sum, operations) => operations.count + sum,
                  0,
                )}
              </td>
            </tr>
            <tr>
              <td className="w-80">Vet:</td>
              <td>
                {masks.currency(
                  data.operationsByCurrency.reduce(
                    (sum, operations) =>
                      (operations.byStatus.find((a) => a.status === "Disbursed")
                        ?.vet || 0) + sum,
                    0,
                  ),
                  currencySymbol.BRL,
                )}
              </td>
            </tr>
            <tr>
              <td className="w-80">Total:</td>
              <td>
                {masks.currency(
                  data.operationsByCurrency.reduce(
                    (sum, operations) =>
                      (operations.byStatus.find((a) => a.status === "Disbursed")
                        ?.nationalCurrencyAmount || 0) + sum,
                    0,
                  ),
                  currencySymbol.BRL,
                )}
              </td>
            </tr>
            <tr>
              <td className="w-80">Total em trânsito:</td>
              <td>
                {masks.currency(
                  data.operationsByCurrency.reduce(
                    (sum, operations) =>
                      (operations.byStatus.find((a) => a.status === "InTransit")
                        ?.nationalCurrencyAmount || 0) + sum,
                    0,
                  ),
                  currencySymbol.BRL,
                )}
              </td>
            </tr>
          </TableStyle>
        );
      },
      colLg: 4,
    },
    {
      key: "cancellation-fees",
      header: "Taxas de cancelamento",
      footer: "Total de ganhos em Fee e Cotação",
      cellRender: ({ data }) => {
        return (
          <TableStyle>
            <tr>
              <td className="w-80">Fee:</td>
              <td>
                {masks.currency(
                  data.cancellationsByCurrency?.reduce(
                    (count, c) => count + c.feeAmount,
                    0,
                  ),
                  currencySymbol.BRL,
                )}
              </td>
            </tr>
            <tr>
              <td className="w-80">
                Lucro na diferença de cotação (não devolvido ao cliente):
              </td>
              <td>
                {masks.currency(
                  data.cancellationsByCurrency?.reduce(
                    (count, c) => count + c.earningsAmount,
                    0,
                  ),
                  currencySymbol.BRL,
                )}
              </td>
            </tr>
            <tr>
              <td className="w-80">
                Prejuízo na diferença de cotação (cobrado do cliente):
              </td>
              <td>
                {masks.currency(
                  data.cancellationsByCurrency?.reduce(
                    (count, c) => count + c.lossesAmount,
                    0,
                  ),
                  currencySymbol.BRL,
                )}
              </td>
            </tr>
            <tr>
              <td className="w-80">Total cobrado do cliente:</td>
              <td>
                {masks.currency(
                  data.cancellationsByCurrency?.reduce(
                    (count, c) => count + c.totalChargedAmount,
                    0,
                  ),
                  currencySymbol.BRL,
                )}
              </td>
            </tr>
          </TableStyle>
        );
      },
      colLg: 4,
    },
    {
      key: "dollar-operations",
      header: "Operações liquidadas:",
      footer: "Valor das operações no período",
      cellRender: ({ data }) => {
        return (
          <TableStyle>
            {(data.operationsByCurrency || []).map((operation) => (
              <tr key={operation.currencyCode}>
                <td>{operation.currencyCode}: </td>
                <td>
                  {" "}
                  {masks.currency(
                    operation.byStatus.find(
                      (status) => status.status === "Disbursed",
                    )?.foreignCurrencyAmount,
                    data.currencies.find(
                      (curr) => curr.code === operation.currencyCode,
                    )?.symbol,
                  )}
                </td>
                <td>
                  {" "}
                  {masks.currency(
                    operation.byStatus.find(
                      (status) => status.status === "Disbursed",
                    )?.nationalCurrencyAmount,
                    currencySymbol.BRL,
                  )}
                </td>
              </tr>
            ))}
          </TableStyle>
        );
      },
      colLg: 4,
    },
    {
      key: "intransit-operations",
      header: "Operações em trânsito:",
      footer: "Valor das operações no período",
      cellRender: ({ data }) => {
        return (
          <TableStyle>
            {(data.operationsByCurrency || []).map((operation) => (
              <tr key={operation.currencyCode}>
                <td>{operation.currencyCode}: </td>
                <td>
                  {" "}
                  {masks.currency(
                    operation.byStatus.find(
                      (status) => status.status === "InTransit",
                    )?.foreignCurrencyAmount,
                    data.currencies.find(
                      (curr) => curr.code === operation.currencyCode,
                    )?.symbol,
                  )}
                </td>
                <td>
                  {" "}
                  {masks.currency(
                    operation.byStatus.find(
                      (status) => status.status === "InTransit",
                    )?.nationalCurrencyAmount,
                    currencySymbol.BRL,
                  )}
                </td>
              </tr>
            ))}
          </TableStyle>
        );
      },
      colLg: 4,
    },
    {
      key: "cancelled-operations",
      header: "Operações canceladas:",
      footer: "Valor das operações no período",
      cellRender: ({ data }) => {
        return (
          <TableStyle>
            {(data.cancellationsByCurrency || []).map((cancellation) => (
              <tr key={cancellation.currencyCode}>
                <td>{cancellation.currencyCode}: </td>

                <td>
                  {" "}
                  {masks.currency(
                    cancellation.foreignCurrencyAmount,
                    data.currencies.find(
                      (curr) => curr.code === cancellation.currencyCode,
                    )?.symbol,
                  )}
                </td>

                <td>
                  {" "}
                  {masks.currency(
                    cancellation?.nationalCurrencyAmount,
                    currencySymbol.BRL,
                  )}
                </td>
              </tr>
            ))}
          </TableStyle>
        );
      },
      colLg: 4,
    },
    {
      key: "available-amount",
      header: "Valores disponíveis",
      footer: "Valor total disponível para internação",
      cellRender: ({ data }) => (
        <TableStyle>
          {(data.ordersByCurrency || []).map((order) => (
            <tr key={order.currencyCode}>
              <td className="w-75">{order.currencyCode}:</td>
              <td>
                {masks.currency(
                  order.availableAmount,
                  data.currencies.find(
                    (curr) => curr.code === order.currencyCode,
                  )?.symbol,
                )}
              </td>
            </tr>
          ))}
        </TableStyle>
      ),
      colLg: 6,
    },
    {
      key: "received-values",
      header: "Valores dos recebimentos",
      footer: "Recebimentos no período",
      cellRender: ({ data }) => (
        <TableStyle>
          {(data.ordersByCurrency || []).map((order) => (
            <tr key={order.currencyCode}>
              <td>{order.currencyCode}- Recebido:</td>
              <td>
                {masks.currency(
                  order.totalAmount,
                  data.currencies.find(
                    (curr) => curr.code === order.currencyCode,
                  )?.symbol,
                )}
              </td>
              <td>| Internalizado:</td>
              <td>
                {masks.currency(
                  order.availableAmount,
                  data.currencies.find(
                    (curr) => curr.code === order.currencyCode,
                  )?.symbol,
                )}
              </td>
            </tr>
          ))}
        </TableStyle>
      ),
      colLg: 6,
    },
    {
      key: "pre-ticket-per-status",
      header: "Pré-tickets por status",
      footer: "Qtd. de pré-tickets por status",
      cellRender: ({ data }) => {
        const labels = data.preTicketsByCurrency[1].byStatus?.map(
          (s) => s.status,
        );

        const datasets = data.preTicketsByCurrency.reduce(
          (acc: number[], value) =>
            value.byStatus.map((s) => acc.push(s.count)),
          [],
        );

        const colours = generateDecreasingHex(labels.length);

        return (
          <Pie
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "right" as const,
                },
              },
            }}
            data={{
              labels,
              datasets: [
                {
                  label: "Total de operações",
                  data: datasets,
                  backgroundColor: colours,
                  borderColor: colours,
                  borderWidth: 1,
                },
              ],
            }}
          />
        );
      },
      colLg: 3,
    },
    {
      key: "outgoing-per-currency",
      header: "Operações de remessa por moeda",
      footer: "Operações por tipo e moeda no período",
      cellRender: ({ data }) => {
        const dataArray = [] as number[];
        const labelsArray = [] as string[];

        (data.operationsByCurrency || []).map((a) => {
          const label = a.currencyCode;
          labelsArray.push(label);
          const amount =
            a.byFlowType.find((flow) => flow.flowType === "REMESSA")
              ?.nationalCurrencyAmount || 0;

          return dataArray.push(amount);
        });

        const colours = generateDecreasingHex(labelsArray.length);

        return (
          <Doughnut
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top" as const,
                },
                tooltip: {
                  callbacks: {
                    label: (context) =>
                      `Total de operações: ${masks.currency(
                        Number(context.raw),
                        data?.currencies?.find(
                          (curr) => curr.code === context.label,
                        )?.symbol,
                      )}`,
                  },
                },
              },
            }}
            data={{
              labels: labelsArray,
              datasets: [
                {
                  label: "Total de operações",
                  data: dataArray,
                  backgroundColor: colours,
                  borderColor: colours,
                  borderWidth: 1,
                },
              ],
            }}
          />
        );
      },
      colLg: 3,
    },
    {
      key: "incoming-per-currency",
      header: "Operações de internação por moeda",
      footer: "Operações por tipo e moeda no período",
      cellRender: ({ data }) => {
        const dataArray = [] as number[];
        const labelsArray = [] as string[];

        (data.operationsByCurrency || []).map((a) => {
          const label = a.currencyCode;
          labelsArray.push(label);
          const amount =
            a.byFlowType.find((flow) => flow.flowType === "INTERNACAO")
              ?.nationalCurrencyAmount || 0;
          return dataArray.push(amount);
        });

        const colours = generateDecreasingHex(labelsArray.length);
        return (
          <Doughnut
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top" as const,
                },
                tooltip: {
                  callbacks: {
                    label: (context) =>
                      `Total de operações: ${masks.currency(
                        Number(context.raw),
                        data?.currencies?.find(
                          (curr) => curr.code === context.label,
                        )?.symbol,
                      )}`,
                  },
                },
              },
            }}
            data={{
              labels: labelsArray,
              datasets: [
                {
                  label: "Total de operações",
                  data: dataArray,
                  backgroundColor: colours,
                  borderColor: colours,
                  borderWidth: 1,
                },
              ],
            }}
          />
        );
      },
      colLg: 3,
    },
    {
      key: "cancelled-paid-other-operations",
      header: "Operações Desembolsadas x Canceladas x Em trânsito",
      footer: "Qtd. total de operações Desembolsadas x canceladas no período",
      cellRender: ({ data }) => {
        return (
          <Pie
            data={{
              labels: ["Desembolsadas", "Canceladas", "Em trânsito"],
              datasets: [
                {
                  label: "Total de operações",
                  data: [
                    Object.values(data.operationsByCurrency || []).reduce(
                      (sum, operations) =>
                        (operations.byStatus.find(
                          (a) => a.status === "Disbursed",
                        )?.count || 0) + sum,
                      0,
                    ),
                    Object.values(data.operationsByCurrency || []).reduce(
                      (sum, operations) =>
                        (operations.byStatus.find(
                          (a) => a.status === "Cancelled",
                        )?.count || 0) + sum,
                      0,
                    ),
                    Object.values(data.operationsByCurrency || []).reduce(
                      (sum, operations) =>
                        (operations.byStatus.find(
                          (a) => a.status === "InTransit",
                        )?.count || 0) + sum,
                      0,
                    ),
                  ],
                  backgroundColor: ["#28D1A0", "#FF5A73", "#FFA94D"],
                  borderColor: ["#28D1A0", "#FF5A73", "#FFA94D"],
                  borderWidth: 1,
                },
              ],
            }}
          />
        );
      },
      colLg: 3,
    },
    {
      key: "total-orders-pretickets-cancelings-last-12-months",
      header: "Performance / Mês (últimos 12 meses)",
      footer: "Total de ordens, pré-tickets e cancelamentos por mês",
      colLg: 6,
      cellRender: ({ data }) => {
        const months = data.consolidatedPerMonth.months.map((month) => {
          const date = new Date(month.date);
          return { ...month, date: `${date.getMonth()}/${date.getFullYear()}` };
        });

        return (
          <Bar
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top" as const,
                },
              },
            }}
            data={{
              labels: months.map((d) => d.date),
              datasets: [
                {
                  label: "Ordens",
                  data: months.map((d) => d.orders),
                  backgroundColor: "#5081c6",
                },
                {
                  label: "Pré-tickets",
                  backgroundColor: "#62C395",
                  data: months.map((d) => d.preTickets),
                },
                {
                  label: "Cancelamentos",
                  backgroundColor: "#8b2328",
                  data: months.map((d) => d.cancellations),
                },
                {
                  label: "Operações",
                  backgroundColor: "#134489",
                  data: months.map((d) => d.operations),
                },
              ],
            }}
          />
        );
      },
    },
    {
      key: "total-incoming-outcoming-operations-last-12-months",
      header: "Performance / Mês (últimos 12 meses)",
      footer: "Total de operações de envio e recebimento por mês",
      colLg: 6,
      cellRender: ({ data }) => {
        const months = data.consolidatedOperationsByFlowTypePerMonth.months.map(
          (month) => {
            return {
              ...month,
              date: `${month.date.slice(5, 7)}/${month.date.slice(2, 4)}`,
            };
          },
        );

        const footer = (tooltipItems: TooltipItem<ChartType>[]) => {
          let date = "";
          let type = "";
          tooltipItems.forEach((item) => {
            date = item.label;
            type = item.dataset.label === "Envios" ? "REMESSA" : "INTERNACAO";
          });
          const total = months
            .find((flow) => flow.date === date)
            ?.flowTypes.find((flow) => flow.flowType === type)?.count;

          return "Quantidade: " + total;
        };

        return (
          <Bar
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top" as const,
                },
                tooltip: {
                  callbacks: {
                    footer,
                    label: (context) =>
                      `Total: ${masks.currency(Number(context.raw), "R$")}`,
                  },
                },
              },
            }}
            data={{
              labels: months.map((d) => d.date),
              datasets: [
                {
                  label: "Recebimentos",
                  backgroundColor: "#5081c6",
                  data: months.map(
                    (d) =>
                      d.flowTypes.find((a) => a.flowType === "INTERNACAO")
                        ?.nationalCurrencyAmount,
                  ),
                },
                {
                  label: "Envios",
                  backgroundColor: "#134489",
                  data: months.map(
                    (d) =>
                      d.flowTypes.find((a) => a.flowType === "REMESSA")
                        ?.nationalCurrencyAmount,
                  ),
                },
              ],
            }}
          />
        );
      },
    },
  ];
