import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { MleByConsumerUnitsImportDataIdRouteParams } from "../../../../routes/energia.route-params";
import { getValidationMessages } from "../../../../utils";

export const useImportDataDetails = () => {
  const { consumerUnitsImportDataId } =
    useParams<MleByConsumerUnitsImportDataIdRouteParams>();
  if (!consumerUnitsImportDataId)
    throw new Error("No consumerUnitsImportDataId");

  const { callService, error, hasError, loading, value } = useServiceCall(
    service.hubEnergy.getConsumerUnitsImportDataDetails,
  );

  const getImportDataDetails = useCallback(async () => {
    const { success, error: err } = await callService(
      consumerUnitsImportDataId,
    );

    if (!success)
      OToastManager.danger(
        getValidationMessages(err)?.[0]?.ErrorMessage ??
          "Erro ao buscar a importação",
      );
  }, [callService, consumerUnitsImportDataId]);

  useEffect(() => {
    getImportDataDetails();
  }, [getImportDataDetails]);

  return {
    error,
    getImportDataDetails,
    hasError,
    loading,
    value,
  };
};
