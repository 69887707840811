import { OButton, OIcon, OInputText } from "@maestro/react";
import { useFormContext } from "react-hook-form";
import { EditListProps } from "./edit-list.type";

export const EditList = ({ field, sectionName }: EditListProps) => {
  const { register, setValue, watch } = useFormContext();
  const fieldWatch: string[] = watch(field);

  return (
    <div
      id={field}
      className="d-flex flex-column gap-2 mb-3"
      {...register(field)}
    >
      {fieldWatch &&
        fieldWatch.length > 0 &&
        fieldWatch.map((item: string, index) => (
          <div
            className="d-flex flex-columns gap-2"
            key={`${field}${0 - index}`}
          >
            <OInputText
              id={`${field}${0 - index}`}
              name={`${field}${0 - index}`}
              dataAction={`${sectionName}:texto:${field}${0 - index}`}
              dataLabel={`${field}${0 - index}`}
              value={item}
              onInput={(e: any) => {
                const newItems = [...fieldWatch];
                newItems[index] = e.target.value;
                setValue(field, newItems);
              }}
            />
            <OButton
              dataAction={`${sectionName}:botao:exluir_${field}${0 - index}`}
              dataLabel={`excluir_${field}${0 - index}`}
              onClick={() => {
                const newItems = [...fieldWatch].filter(
                  (_, idx) => idx !== index,
                );
                setValue(field, newItems);
              }}
            >
              <OIcon category="orq" icon="orq-close" />
            </OButton>
          </div>
        ))}
      <OButton
        dataAction={`${sectionName}:botao:adicionar`}
        dataLabel="adicionar"
        onClick={() => setValue(field, [...fieldWatch, ""])}
      >
        Adicionar
      </OButton>
    </div>
  );
};
