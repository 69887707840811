import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { service } from "services";

type ConfigurableGridColumns = Awaited<
  ReturnType<typeof service.hubCreditManager.getConfigurableGridColumns>
>["data"][number];

export const configurableColumnsGrid = {
  datagrid: {
    noDataText: "Nenhuma coluna configurável encontrada",
    dataSource: dataSourceCustomStoreGenerator(() =>
      service.hubCreditManager.getConfigurableGridColumns().catch(() => {
        throw new Error("Não foi possível buscar as colunas configuráveis.");
      }),
    ),
    paging: { pageSize: 10 },
  },
  columns: [
    {
      dataField: "id",
      dataType: "number",
      caption: "Id",
      sortOrder: "asc",
    },
    {
      dataField: "caption",
      caption: "Legenda",
    },
    {
      dataField: "dataField",
    },
    {
      dataField: "dataType",
    },
    {
      dataField: "cellRenderType",
      caption: "Tipo de renderização",
    },
    {
      dataField: "filterable",
      caption: "Filtrável?",
      cellRender: ({ data }) => (data.filterable ? "Sim" : "Não"),
    },
  ],
} satisfies ODataGridGeneratorConfig<ConfigurableGridColumns>;
