import { OToastManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { IconButton } from "components/icon-button";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { getValidationMessages } from "../../../../../../../../../../../../utils";

interface DownloadFileButtonProps {
  fileKey: string;
  fileName: string;
  disabled?: boolean;
}

export const DownloadFileButton = ({
  fileName,
  fileKey,
  disabled,
}: DownloadFileButtonProps) => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.getChannelFile,
  );

  const downloadFile = useCallback(async () => {
    const { success, response, error } = await callService({ key: fileKey });
    if (success && response.data.response) {
      helpers.downloadBase64(response.data.response, fileName);
    } else {
      const validationMessage = getValidationMessages(error)?.[0].ErrorMessage;
      OToastManager.danger(
        validationMessage
          ? {
              title: `Erro ao fazer o download de ${fileName}`,
              message: validationMessage,
            }
          : `Erro ao fazer o download de ${fileName}`,
      );
    }
  }, [callService, fileKey, fileName]);

  return (
    <IconButton
      icon={{ category: "orq", icon: "orq-download" }}
      loading={loading}
      type="dark"
      outline
      disabled={disabled}
      onClick={downloadFile}
      title={fileName}
    >
      <span
        style={{
          display: "inline-block",
          maxWidth: "200px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {fileName}
      </span>
    </IconButton>
  );
};
