import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { GridTemplate } from "templates/grid-template";
import { FundSelect } from "../../../components";
import { interactionsGrid } from "./interactions.grid";
import { useInteractions } from "./interactions.hook";

export const Interactions = () => {
  const { dataSource, form, funds, loading, resend, selectedFundWatch } =
    useInteractions();

  const gridRef = useGridRef();

  const grid = useMemo(() => {
    return interactionsGrid(resend);
  }, [resend]);

  return (
    <FormProvider {...form}>
      <div className="d-flex flex-column gap-5">
        <PageTitle title="Interações" />
        <FundSelect />
        {selectedFundWatch && (
          <GridTemplate
            pageTitle={
              <PageTitle
                title={`Interações do fundo ${
                  funds?.find((fund) => fund.taxId === selectedFundWatch)
                    ?.name ?? ""
                }`}
              />
            }
            gridRef={gridRef}
            showRefreshButton
            showClearFiltersButton
          >
            <ODataGridGenerator
              gridRef={gridRef}
              loading={loading}
              grid={grid}
              dataSource={dataSource}
            />
          </GridTemplate>
        )}
      </div>
    </FormProvider>
  );
};
