import {
  modalManager,
  OButton,
  OCard,
  OCardBody,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { DetailsTemplate } from "templates/details-template";
import { getValidationMessages } from "../../../../../../../../utils";
import { actionTextBuilder } from "../../../../_utils";
import {
  RemoveActionConfirmationModal,
  removeActionConfirmationModalId,
} from "../../../confirmation-modals";

interface ActionDisplayProps {
  action: HubEnergy.DiligenceEventActionSimpleResponse;
  setMode: React.Dispatch<React.SetStateAction<"display" | "editing">>;
  setActions: React.Dispatch<
    React.SetStateAction<HubEnergy.DiligenceEventActionSimpleResponse[]>
  >;
}

export const ActionDisplay = ({
  action,
  setMode,
  setActions,
}: ActionDisplayProps) => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.deleteDiligenceEventAction,
  );

  const deleteAction = useCallback(async () => {
    const { success, error } = await callService(action.id);

    if (success) {
      OToastManager.success("Ação excluída com sucesso");
      setActions((old) => old.filter(({ id }) => id !== action.id));
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0]?.ErrorMessage ??
          "Erro ao excluir ação",
      );
    }
  }, [action.id, callService, setActions]);

  return (
    <>
      <DetailsTemplate
        actions={
          <>
            <LoadingButton
              loading={loading}
              onClick={() =>
                modalManager.show(removeActionConfirmationModalId(action.id))
              }
              type="danger"
            >
              Excluir
            </LoadingButton>
            <OButton onClick={() => setMode("editing")}>Editar</OButton>
          </>
        }
      >
        <OCard>
          <OCardBody>
            <OTypography>{actionTextBuilder(action)}</OTypography>
          </OCardBody>
        </OCard>
      </DetailsTemplate>
      <RemoveActionConfirmationModal action={action} remove={deleteAction} />
    </>
  );
};
