import * as yup from "yup";

export type UpdateOriginatorCategorizationsFormFields = Pick<
  HubPartners.Admin.NewOriginator,
  "partnershipType" | "originatorSubTypes" | "products"
>;

export const updateOriginatorCategorizationsSchema: yup.ObjectSchema<UpdateOriginatorCategorizationsFormFields> =
  yup.object({
    partnershipType: yup.string().required("Este campo é obrigatório"),
    originatorSubTypes: yup
      .array()
      .of(yup.string().required("Este campo é obrigatório"))
      .default([]),
    products: yup
      .array()
      .of(yup.string().required("Este campo é obrigatório"))
      .default([]),
  });

export const updateOriginatorCategorizationsDefaultValues: UpdateOriginatorCategorizationsFormFields =
  {
    partnershipType: "",
    originatorSubTypes: [],
    products: [],
  };
