import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { service } from "services";
import { getValidationMessages } from "../../utils";
import { DocumentTemplateModalProps } from "./document-template-modal.types";

export const useDocumentTemplateModal = ({
  documentTemplate: templateFromProps,
  propertyId,
  propertyConfigId,
}: DocumentTemplateModalProps) => {
  const [documentTemplate, setDocumentTemplate] = useState<
    HubEnergy.DocumentResponse | undefined
  >();

  const { callService: _createTemplate, loading: createTemplateLoading } =
    useServiceCall(service.hubEnergy.addPropertyDocumentTemplate);

  const { callService: _removeTemplate, loading: removeTemplateLoading } =
    useServiceCall(service.hubEnergy.removePropertyDocumentTemplate);

  const {
    callService: _createConfigTemplate,
    loading: createConfigTemplateLoading,
  } = useServiceCall(service.hubEnergy.addPropertyConfigDocumentTemplate);

  const {
    callService: _removeConfigTemplate,
    loading: removeConfigTemplateLoading,
  } = useServiceCall(service.hubEnergy.removePropertyConfigDocumentTemplate);

  const createDocumentTemplate = useCallback(async () => {
    const request =
      propertyId !== undefined
        ? _createTemplate({
            diligenceStepPropertyId: propertyId,
          })
        : _createConfigTemplate({
            diligenceStepPropertyConfigId: propertyConfigId,
          });

    const { success, error, response } = await request;

    if (success) {
      OToastManager.success("Template do documento criado com sucesso");
      setDocumentTemplate(response.data.response?.documentTemplate);
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0]?.ErrorMessage ??
          "Erro ao criar template do documento",
      );
    }
  }, [_createConfigTemplate, _createTemplate, propertyConfigId, propertyId]);

  const removeDocumentTemplate = useCallback(async () => {
    const request =
      propertyId !== undefined
        ? _removeTemplate(propertyId)
        : _removeConfigTemplate(propertyConfigId);

    const { success, error } = await request;

    if (success) {
      OToastManager.success("Template do documento removido com sucesso");
      setDocumentTemplate(undefined);
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0]?.ErrorMessage ??
          "Erro ao remover template do documento",
      );
    }
  }, [_removeConfigTemplate, _removeTemplate, propertyConfigId, propertyId]);

  useEffect(() => {
    setDocumentTemplate(templateFromProps);
  }, [templateFromProps]);

  return {
    createDocumentTemplate,
    createTemplateLoading: createTemplateLoading || createConfigTemplateLoading,
    documentTemplate,
    removeDocumentTemplate,
    removeTemplateLoading: removeTemplateLoading || removeConfigTemplateLoading,
  };
};
