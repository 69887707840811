import {
  OCol,
  OPillTab,
  OPillTabHeader,
  OPillTabs,
  ORow,
} from "@maestro/react";
import { useState } from "react";
import { FluctuationsChart } from "./_compose";
import { periodTabMap } from "./dashboard-fluctuations.utils";

export const DashboardFluctuations = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <ORow gap={2}>
      <OPillTabs>
        <div className="d-flex justify-content-end mb-3">
          <OPillTabHeader>
            {periodTabMap.map((period, index) => (
              <OPillTab
                key={period.label}
                dataAction={`cotacao:botao:${period.label}`}
                dataLabel={period.label}
                handleClick={() => setActiveTab(index)}
              >
                {period.title}
              </OPillTab>
            ))}
          </OPillTabHeader>
        </div>
        <ORow gap={2}>
          <OCol sm={6}>
            <FluctuationsChart flowType="REMESSA" activeTab={activeTab} />
          </OCol>
          <OCol sm={6}>
            <FluctuationsChart flowType="INTERNACAO" activeTab={activeTab} />
          </OCol>
        </ORow>
      </OPillTabs>
    </ORow>
  );
};
