import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { RouterButton } from "components/router-button";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { endpoints } from "services/endpoints";
import { PostByIdAdminCurrency } from "services/hubfx/models/requests/post-by-id-admin-currencies.request";

export const exchangeProductCurrenciesGrid = {
  datagrid: {
    noDataText: "Nenhuma moeda encontrada.",
    dataSource: dataSourceODataStoreGenerator(
      endpoints.hubFx.odata.adminCurrenciesOData,
    ),
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      type: "buttons",
      cellRender: ({ data }) =>
        !!data.id && (
          <DetailsLink
            href={`${
              corporateRouter.routes.cambio.product.contingency.currencies
                .details.path
            }?data=${encodeURIComponent(
              JSON.stringify(data as PostByIdAdminCurrency),
            )}`}
          />
        ),
    },
    {
      dataField: "id",
      caption: "Id",
    },
    {
      dataField: "description",
      caption: "Nome",
    },
    {
      dataField: "code",
      caption: "Código",
    },
    {
      dataField: "isApproved",
      caption: "Aprovada",
    },
    {
      dataField: "symbol",
      caption: "Símbolo",
    },
    {
      dataField: "allowsNonStopTrading",
      caption: "allowsNonStopTrading (24/7)",
    },
    {
      dataField: "allowsSameDayDisbursement",
      caption: "allowsSameDayDisbursement (D+0)",
    },
    {
      dataField: "createdDate",
      caption: "Data de criação",
    },
    {
      dataField: "updatedDate",
      caption: "Última atualização",
    },
    {
      role: roles.cambio.product.contingencyCurrenciesFormPage.role,
      cellRender: ({ data }) => (
        <RouterButton
          dataAction="cambio_moedas:botao:editar"
          dataLabel="editar"
          href={`${
            corporateRouter.routes.cambio.product.contingency.currencies.form
              .path
          }?data=${encodeURIComponent(
            JSON.stringify(data as PostByIdAdminCurrency),
          )}`}
          outline
        >
          Editar
        </RouterButton>
      ),
    },
  ],
} satisfies ODataGridGeneratorConfig<
  Awaited<
    ReturnType<typeof service.hubFx.odata.getCurrencies>
  >["data"]["value"][number]
>;
