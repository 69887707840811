import { OLoader } from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { SelectSearchField } from "components/select-search";
import { SelectSearchFieldOption } from "components/select-search/field/select-search-field.types";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { AddPendencyForm } from "../../add-pendency-modal.form";

interface DiligenceStepSelectorProps {
  diligenceHasError: boolean;
  diligenceLoading: boolean;
  diligence: HubEnergy.DiligenceResponse | undefined;
  getDiligence: () => void;
}

export const DiligenceStepSelector = ({
  diligence,
  diligenceHasError,
  diligenceLoading,
  getDiligence,
}: DiligenceStepSelectorProps) => {
  const { clearErrors, setError, watch } = useFormContext<AddPendencyForm>();

  const consumerUnitWatch = watch("_consumerUnitId");

  useEffect(() => {
    if (!diligenceHasError) clearErrors("stepId");
    else
      setError("stepId", {
        message:
          "Erro ao buscar os steps. Clique no botão ao lado para tentar novamente.",
      });
  }, [clearErrors, diligenceHasError, setError]);

  const steps = useMemo(
    () =>
      diligence?.steps.map<SelectSearchFieldOption<number>>(({ id, name }) => ({
        label: name,
        value: id,
      })) ?? [],
    [diligence?.steps],
  );

  return (
    <div className="d-flex align-items-center flex-fill">
      <SelectSearchField
        options={steps}
        id="stepId"
        name="stepId"
        label="Step"
        disabled={
          !consumerUnitWatch ||
          diligenceHasError ||
          diligenceLoading ||
          !steps?.length
        }
        placeholder="Selecionar"
        required
      />

      {diligenceLoading && <OLoader size="sm" />}
      {diligenceHasError && !diligenceLoading && (
        <RefreshGridButton onClick={getDiligence} />
      )}
    </div>
  );
};
