import { FormValuesType } from "./_compose";

export const accountType = {
  CC: "Conta Corrente",
  PP: "Conta Poupança",
};

export const handleValues = (values: FormValuesType, uuid?: string) => {
  const isDisbursementAccount = values.is_disbursement_account === "S";

  return {
    ...values,
    id: uuid || "",
    is_disbursement_account: isDisbursementAccount,
  };
};
