import { ODivider, ORow, OTypography } from "@maestro/react";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { addEstrategiaFormDefaultValues } from "../estrategia-form.form";
import { EstrategiaForm } from "../estrategia-form.types";
import {
  ApprovalGroupsField,
  AprovacaoAposAntecipacaoSacadoField,
  BiometryRequiredField,
  CancelaNaoAntecipadasD0Field,
  CollateralIdentificationField,
  CollateralRequiredField,
  ComandoOperacaoField,
  DiasCampanhaNotasNaoAntecipadasCedenteField,
  DiasParaVencimentoSacadoField,
  EmailsSacadoNotificationField,
  ExigeAprovacaoBancoField,
  ExigeAprovacaoSacadoField,
  ExigeIndicacaoHedgeField,
  FccIdSacadoField,
  HabilitaPagForField,
  HorarioLimiteOperacaoField,
  InstrumentCodeIdField,
  MarketplaceIdField,
  ModeloDeTaxaField,
  NomeField,
  NotaDisponivelCedenteField,
  NotificacaoOnboardingSacadoField,
  NotificacaoRecebiveisAntecipadosField,
  NotificacaoTedDevolvidaCedenteField,
  NotificacaoTedDevolvidaSacadoField,
  PermiteTaxaParticularField,
  PnlBookField,
  PnlStrategyField,
  PortfolioIdField,
  PrazoLimiteOperacaoField,
  RebateAlongaPrazoField,
  RebateRebarbaSacadoField,
  RebateToleranceField,
  ReporteDiarioSacadoField,
  ReporteNotasNaoAntecipadasCedenteField,
  SacadoDefineContaDesembolsoField,
  SendErrorCallbackField,
  TipoOnboardingField,
  TipoTaxaParticularField,
  VisibilidadePrazoVencimentoCedenteField,
  VisualizaRebateSacadoField,
  GracePeriodField,
  FilesForOperationField,
  FilesForReceivablesField,
  NeedsFilesForOperationField,
  NeedsFilesForReceivablesField,
  NeedsRegistrationInfoField,
} from "../fields";
import { ToCreditField } from "../fields/to-credit-field.component";

interface RiscoSacadoFormProps {
  form: UseFormReturn<EstrategiaForm>;
  shouldReset?: boolean;
}

export const RiscoSacadoForm = ({
  form,
  shouldReset,
}: RiscoSacadoFormProps) => {
  const { getValues, reset, watch } = form;

  useEffect(() => {
    if (shouldReset)
      reset({
        ...addEstrategiaFormDefaultValues,
        tipoProduto: getValues("tipoProduto"),
        exigeAprovacaoBanco: "yes",
        toCredit: "yes",
        horarioLimiteOperacao: "17:00",
        prazoLimiteOperacao: 5,
        modeloDeTaxa: "configuracao",
      });
  }, [getValues, reset, shouldReset]);

  const rebateAlongaPrazoWatch = watch("rebateAlongaPrazo");
  const collateralRequiredWatch = watch("collateralRequired");
  const reporteDiarioSacadoWatch = watch("reporteDiarioSacado");
  const reporteNotasNaoAntecipadasCedenteWatch = watch(
    "reporteNotasNaoAntecipadasCedente",
  );
  const needsFilesForOperationWatch = watch("needsFilesForOperation");
  const needsFilesForReceivablesWatch = watch("needsFilesForReceivables");

  return (
    <>
      <OTypography size="lg">Geral</OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <NomeField />
        <ModeloDeTaxaField />
        <HorarioLimiteOperacaoField />
        <ExigeAprovacaoBancoField />
        <ExigeIndicacaoHedgeField />
        <TipoOnboardingField type="DEFAULT" required />
        <PrazoLimiteOperacaoField />
        <GracePeriodField />
        <PnlBookField />
        <PnlStrategyField />
        <InstrumentCodeIdField />
        <MarketplaceIdField />
        <PortfolioIdField />
        <BiometryRequiredField />
        <ToCreditField />
      </ORow>

      <OTypography size="lg" className="mt-5">
        Risco sacado
      </OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <HabilitaPagForField />
        <ComandoOperacaoField required />
        <AprovacaoAposAntecipacaoSacadoField />
        <PermiteTaxaParticularField />
        <TipoTaxaParticularField />
        <ExigeAprovacaoSacadoField required />
        <SacadoDefineContaDesembolsoField />
        <CancelaNaoAntecipadasD0Field />
        <VisualizaRebateSacadoField />
        <RebateRebarbaSacadoField />
        <RebateAlongaPrazoField />
        <VisibilidadePrazoVencimentoCedenteField
          disabled={rebateAlongaPrazoWatch !== "yes"}
        />
        <RebateToleranceField />
        <SendErrorCallbackField />
        <CollateralRequiredField />
        {collateralRequiredWatch === "yes" && <CollateralIdentificationField />}
      </ORow>

      <OTypography size="lg" className="mt-5">
        Validações de entrada
      </OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <NeedsFilesForReceivablesField />
        <FilesForReceivablesField
          disabled={needsFilesForReceivablesWatch !== "yes"}
        />
        <NeedsFilesForOperationField />
        <FilesForOperationField
          disabled={needsFilesForOperationWatch !== "yes"}
        />
        <NeedsRegistrationInfoField />
      </ORow>

      <OTypography size="lg" className="mt-5">
        Notificações (sacado)
      </OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <NotificacaoOnboardingSacadoField />
        <ReporteDiarioSacadoField />
        {reporteDiarioSacadoWatch === "yes" && (
          <DiasParaVencimentoSacadoField required />
        )}
        <NotificacaoTedDevolvidaSacadoField />
        <EmailsSacadoNotificationField />
      </ORow>

      <OTypography size="lg" className="mt-5">
        Notificações (cedente)
      </OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <NotaDisponivelCedenteField />
        <ReporteNotasNaoAntecipadasCedenteField />
        {reporteNotasNaoAntecipadasCedenteWatch === "yes" && (
          <DiasCampanhaNotasNaoAntecipadasCedenteField />
        )}
        <NotificacaoRecebiveisAntecipadosField />
        <NotificacaoTedDevolvidaCedenteField />
      </ORow>

      <OTypography size="lg" className="mt-5">
        Avançado
      </OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <ApprovalGroupsField />
        <FccIdSacadoField />
      </ORow>
    </>
  );
};
