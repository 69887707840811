import { env } from "utils/environments";

export const onboardingEndpoints = {
  admin: {
    btgProducts: `${env.API_ONBOARDING}/api/admin/btg-products`,
    btgServices: `${env.API_ONBOARDING}/api/admin/btg-services`,
    company: {
      endpoint: `${env.API_ONBOARDING}/api/admin/company`,
    },
    companyGroup: {
      getCompaniesFromGroup: {
        byTaxId: (taxId: string) =>
          `${env.API_ONBOARDING}/api/admin/companyGroup/get-companies-from-group/${taxId}`,
        endpoint: `${env.API_ONBOARDING}/api/admin/companyGroup/get-companies-from-group`,
      },
      registerGroupAndAssociations: `${env.API_ONBOARDING}/api/admin/companyGroup/register-group-and-associations`,
      getGroupAssociations: `${env.API_ONBOARDING}/api/admin/companyGroup/get-companies-from-group-configs`,
    },
    contracts: {
      batch: {
        create: `${env.API_ONBOARDING}/api/admin/contracts/batch/create`,
      },
      byExternalReference: {
        sendContractSignedToLess: (externalReference: string) =>
          `${env.API_ONBOARDING}/api/admin/contracts/${externalReference}/send-contract-signed-to-less`,
      },
    },
    desks: {
      endpoint: `${env.API_ONBOARDING}/api/admin/desks`,
      byDeskCode: (deskCode: string | number) =>
        `${env.API_ONBOARDING}/api/admin/desks/${deskCode}`,
      onboarding: {
        endpoint: `${env.API_ONBOARDING}/api/admin/desks/onboarding`,
        configs: `${env.API_ONBOARDING}/api/admin/desks/onboarding/configs`,
        enums: `${env.API_ONBOARDING}/api/admin/desks/onboarding/enums`,
        configsByConfigIdentifier: (configIdentifier: string) =>
          `${env.API_ONBOARDING}/api/admin/desks/onboarding/configs/${configIdentifier}`,
        register: `${env.API_ONBOARDING}/api/admin/desks/onboarding/register`,
        update: `${env.API_ONBOARDING}/api/admin/desks/onboarding/update`,
      },
      registerEntityFromDesk: `${env.API_ONBOARDING}/api/admin/desks/onboarding/register-entity-from-desk`,
      deskConfigRelationship: {
        endpoint: `${env.API_ONBOARDING}/api/admin/desks/desk-config-relationship`,
        byUuid: (uuid: string) =>
          `${env.API_ONBOARDING}/api/admin/desks/desk-config-relationship/${uuid}`,
      },
      documentConfig: {
        endpoint: `${env.API_ONBOARDING}/api/admin/desks/document-config`,
        enums: `${env.API_ONBOARDING}/api/admin/desks/document-config/enums`,
        relationships: `${env.API_ONBOARDING}/api/admin/desks/document-config/relationships`,
        relationshipsByUuid: (uuid: string) =>
          `${env.API_ONBOARDING}/api/admin/desks/document-config/relationships/${uuid}`,
        documentRegister: `${env.API_ONBOARDING}/api/admin/desks/document-config/document-register`,
        relationshipRegister: `${env.API_ONBOARDING}/api/admin/desks/document-config/relationship-register`,
      },
      accountInfo: {
        endpoint: `${env.API_ONBOARDING}/api/admin/desks/account/info`,
        enums: `${env.API_ONBOARDING}/api/admin/desks/account/info/enums`,
        byUuid: (uuid: string) =>
          `${env.API_ONBOARDING}/api/admin/desks/account/info/${uuid}`,
        register: `${env.API_ONBOARDING}/api/admin/desks/account/info/register`,
        update: `${env.API_ONBOARDING}/api/admin/desks/account/info/update`,
      },
      onboardingAccountConfig: {
        endpoint: `${env.API_ONBOARDING}/api/admin/desks/onboarding-account-config`,
        enums: `${env.API_ONBOARDING}/api/admin/desks/onboarding-account-config/enums`,
        byUuidRelationships: (uuid: string) =>
          `${env.API_ONBOARDING}/api/admin/desks/onboarding-account-config/${uuid}/relationships`,
        byUuidRelationshipsByRelationshipUuid: (
          uuid: string,
          relationshipUuid: string,
        ) =>
          `${env.API_ONBOARDING}/api/admin/desks/onboarding-account-config/${uuid}/relationships/${relationshipUuid}`,
        relationships: `${env.API_ONBOARDING}/api/admin/desks/onboarding-account-config/relationships`,
        byUuid: (uuid: string) =>
          `${env.API_ONBOARDING}/api/admin/desks/onboarding-account-config/${uuid}`,
        register: `${env.API_ONBOARDING}/api/admin/desks/onboarding-account-config/register`,
        update: `${env.API_ONBOARDING}/api/admin/desks/onboarding-account-config/update`,
        byUuidProductsServices: (uuid: string) =>
          `${env.API_ONBOARDING}/api/admin/desks/onboarding-account-config/${uuid}/products-services`,
        upsertProductsServices: `${env.API_ONBOARDING}/api/admin/desks/onboarding-account-config/upsert-products-services`,
      },
      onboardingPartnerConfig: {
        endpoint: `${env.API_ONBOARDING}/api/admin/desks/onboarding-partner-config`,
        enums: `${env.API_ONBOARDING}/api/admin/desks/onboarding-partner-config/enums`,
        byUuid: (uuid: string) =>
          `${env.API_ONBOARDING}/api/admin/desks/onboarding-partner-config/${uuid}`,
      },
      onboardingConfigOfficer: {
        endpoint: `${env.API_ONBOARDING}/api/admin/desks/onboarding-config-officer`,
        byUuid: (uuid: string) =>
          `${env.API_ONBOARDING}/api/admin/desks/onboarding-config-officer/${uuid}`,
        officers: `${env.API_ONBOARDING}/api/admin/desks/onboarding-config-officer/officers`,
      },
      notification: {
        endpoint: `${env.API_ONBOARDING}/api/admin/desks/notification`,
        byUuid: (uuid: string) =>
          `${env.API_ONBOARDING}/api/admin/desks/notification/${uuid}`,
        register: `${env.API_ONBOARDING}/api/admin/desks/notification/register`,
        update: `${env.API_ONBOARDING}/api/admin/desks/notification/update`,
        registerVariables: `${env.API_ONBOARDING}/api/admin/desks/notification/register-variables`,
        removeVariablesByUuid: (uuid: string) =>
          `${env.API_ONBOARDING}/api/admin/desks/notification/remove-variables/${uuid}`,
      },
    },
    contractFileConfiguration: {
      endpoint: `${env.API_ONBOARDING}/api/admin/contract-file-configuration`,
      enums: `${env.API_ONBOARDING}/api/admin/contract-file-configuration/enums`,
      byId: (id: string) =>
        `${env.API_ONBOARDING}/api/admin/contract-file-configuration/${id}`,
      register: `${env.API_ONBOARDING}/api/admin/contract-file-configuration/register`,
      updateById: (id: string) =>
        `${env.API_ONBOARDING}/api/admin/contract-file-configuration/update/${id}`,
    },
    defaultContractFileConfiguration: {
      endpoint: `${env.API_ONBOARDING}/api/admin/default-contract-file-configuration`,
      enums: `${env.API_ONBOARDING}/api/admin/default-contract-file-configuration/enums`,
      byId: (id: string) =>
        `${env.API_ONBOARDING}/api/admin/default-contract-file-configuration/${id}`,
      register: `${env.API_ONBOARDING}/api/admin/default-contract-file-configuration/register`,
      updateById: (id: string) =>
        `${env.API_ONBOARDING}/api/admin/default-contract-file-configuration/update/${id}`,
    },
    officers: `${env.API_ONBOARDING}/api/admin/officers`,
    officersById: (id: string | number) =>
      `${env.API_ONBOARDING}/api/admin/officers/${id}`,
    getSemaphore: `${env.API_ONBOARDING}/api/admin/get-semaphore`,
    sendSemaphoreBatch: `${env.API_ONBOARDING}/api/admin/get-semaphore/batch`,
    onboard: {
      batch: {
        endpoint: `${env.API_ONBOARDING}/api/admin/onboard/batch`,
        verify: `${env.API_ONBOARDING}/api/admin/onboard/batch/verify`,
      },
      status: `${env.API_ONBOARDING}/api/admin/onboard/status`,
      user: {
        welcomeEmail: `${env.API_ONBOARDING}/api/admin/onboard/user/welcome-email`,
      },
    },
    onboardingLevels: {
      download: {
        byKey: (key: string) =>
          `${env.API_ONBOARDING}/api/admin/onboarding-levels/download/${key}`,
      },
      occurences: `${env.API_ONBOARDING}/api/admin/onboarding-levels/occurrences`,
    },
    user: {
      endpoint: `${env.API_ONBOARDING}/api/admin/user`,
      createAdministrator: `${env.API_ONBOARDING}/api/admin/user/create-administrator`,
      getProfiles: `${env.API_ONBOARDING}/api/admin/user/get-profiles`,
    },
    segmentGroup: {
      endpoint: `${env.API_ONBOARDING}/api/admin/segment-group`,
    },
    semaphoreConfiguration: {
      template: {
        findBy: (uuid: string) =>
          `${env.API_ONBOARDING}/api/admin/semaphore-configuration/template/${uuid}`,
        remove: (uuid: string) =>
          `${env.API_ONBOARDING}/api/admin/semaphore-configuration/template/${uuid}/remove`,
        list: `${env.API_ONBOARDING}/api/admin/semaphore-configuration/template/list`,
        register: `${env.API_ONBOARDING}/api/admin/semaphore-configuration/template/register`,
        addStep: `${env.API_ONBOARDING}/api/admin/semaphore-configuration/template/add-step`,
        removeStep: `${env.API_ONBOARDING}/api/admin/semaphore-configuration/template/remove-step`,
        updateStepOrder: `${env.API_ONBOARDING}/api/admin/semaphore-configuration/template/update-step-order`,
        updateStep: `${env.API_ONBOARDING}/api/admin/semaphore-configuration/template/update-step`,
      },
      step: {
        findBy: (uuid: string) =>
          `${env.API_ONBOARDING}/api/admin/semaphore-configuration/step/${uuid}`,
        update: (uuid: string) =>
          `${env.API_ONBOARDING}/api/admin/semaphore-configuration/step/${uuid}/update`,
        remove: (uuid: string) =>
          `${env.API_ONBOARDING}/api/admin/semaphore-configuration/step/${uuid}/remove`,
        list: `${env.API_ONBOARDING}/api/admin/semaphore-configuration/step/list`,
        add: `${env.API_ONBOARDING}/api/admin/semaphore-configuration/step/register`,
      },
    },
    entitySemaphore: {
      list: (taxId: string) =>
        `${env.API_ONBOARDING}/api/admin/semaphore/${taxId}/list`,
      find: (uuid: string) =>
        `${env.API_ONBOARDING}/api/admin/semaphore/${uuid}/find`,
      listAll: `${env.API_ONBOARDING}/api/admin/semaphore/odata/list`,
    },
    pipelineConfiguration: {
      list: `${env.API_ONBOARDING}/api/admin/pipeline-configuration/list`,
    },
  },
  address: {
    byZipCode: `${env.API_ONBOARDING}/api/address/by-zip-code`,
  },
  administrativeUnit: {
    cities: `${env.API_ONBOARDING}/api/AdministrativeUnit/cities`,
    city: `${env.API_ONBOARDING}/api/AdministrativeUnit/city`,
    countries: `${env.API_ONBOARDING}/api/AdministrativeUnit/countries`,
  },
  contingency: {
    files: {
      upload: `${env.API_ONBOARDING}/api/contingency/files/upload`,
    },
    documents: {
      load: `${env.API_ONBOARDING}/api/contingency/documents/load`,
      occurrences: `${env.API_ONBOARDING}/api/contingency/documents/occurrences`,
    },
    legalEntity: {
      reset: `${env.API_ONBOARDING}/api/contingency/legal-entity/reset`,
      updateDataLess: `${env.API_ONBOARDING}/api/contingency/legal-entity/update-data-less`,
    },
    onboarding: {
      approve: `${env.API_ONBOARDING}/api/contingency/onboarding/approve`,
      forceReopen: `${env.API_ONBOARDING}/api/contingency/onboarding/force-reopen`,
      reopen: `${env.API_ONBOARDING}/api/contingency/onboarding/reopen`,
      resendToLess: `${env.API_ONBOARDING}/api/contingency/onboarding/resend-to-less`,
      forceReopenAllSegments: `${env.API_ONBOARDING}/api/contingency/onboarding/force-reopen/all-segments`,
      reopenAllSegments: `${env.API_ONBOARDING}/api/contingency/onboarding/reopen/all-segments`,
      resendToLessAllSegments: `${env.API_ONBOARDING}/api/contingency/onboarding/resend-to-less/all-segments`,
    },
    reopenDataReview: `${env.API_ONBOARDING}/api/contingency/reopen-data-review`,
    reopenDataReviewForce: `${env.API_ONBOARDING}/api/contingency/reopen-data-review-force`,
    resendDataReviewToLess: `${env.API_ONBOARDING}/api/contingency/resend-data-review-to-less`,
    syncAccounts: `${env.API_ONBOARDING}/api/contingency/sync-accounts`,
    workflow: {
      terminateRelationship: `${env.API_ONBOARDING}/api/contingency/workflow/terminate-relationship`,
      approve: `${env.API_ONBOARDING}/api/contingency/workflow/approve`,
      update: `${env.API_ONBOARDING}/api/contingency/workflow/update`,
      sync: `${env.API_ONBOARDING}/api/contingency/workflow/sync`,
      byUuid: (uuid: string) =>
        `${env.API_ONBOARDING}/api/contingency/workflow/${uuid}`,
      byUuidLogs: (uuid: string) =>
        `${env.API_ONBOARDING}/api/contingency/workflow/${uuid}/logs`,
    },
    workflowMigration: {
      approve: `${env.API_ONBOARDING}/api/contingency/workflow-migration/approve`,
      byMigrationId: {
        details: (migrationId: string) =>
          `${env.API_ONBOARDING}/api/contingency/workflow-migration/${migrationId}/details`,
      },
      create: `${env.API_ONBOARDING}/api/contingency/workflow-migration/create`,
      list: `${env.API_ONBOARDING}/api/contingency/workflow-migration/list`,
      reprove: `${env.API_ONBOARDING}/api/contingency/workflow-migration/reprove`,
    },
    updateDataReviewByUuid: (uuid: string) =>
      `${env.API_ONBOARDING}/api/contingency/update-data-review-by-uuid/${uuid}`,
    syncDataReview: `${env.API_ONBOARDING}/api/contingency/sync-data-review`,
    dataReviewByUuidLogs: (uuid: string) =>
      `${env.API_ONBOARDING}/api/contingency/data-review/${uuid}/logs`,
    updateDataUpdateById: (id: number | string) =>
      `${env.API_ONBOARDING}/api/contingency/update-data-update-by-id/${id}`,
    syncDataUpdate: `${env.API_ONBOARDING}/api/contingency/sync-data-update`,
    dataUpdateByUuidLogs: (uuid: string) =>
      `${env.API_ONBOARDING}/api/contingency/data-update/${uuid}/logs`,
    dataUpdateGetChanges: `${env.API_ONBOARDING}/api/contingency/data-update/get-changes`,
  },
  customerInvite: {
    energy: `${env.API_ONBOARDING}/api/CustomerInvite/energy`,
    openAccount: `${env.API_ONBOARDING}/api/CustomerInvite/open-account`,
  },
  customerService: {
    contracts: {
      createAccountOpening: `${env.API_ONBOARDING}/api/customer-service/contracts/create-account-opening`,
    },
    invites: {
      createAccountOpening: `${env.API_ONBOARDING}/api/customer-service/invites/create-account-opening`,
      loadAccountOpening: `${env.API_ONBOARDING}/api/customer-service/invites/load-account-opening`,
    },
  },
  odata: {
    amlResponse: {
      monitor: `${env.API_ONBOARDING}/odata/aml-response/monitor`,
    },
    onboarding: {
      details: `${env.API_ONBOARDING}/odata/onboard/details`,
    },
  },
  onboardingLevels: {
    getSemaphoreFirstAccess: `${env.API_ONBOARDING}/api/onboarding-levels/get-semaphore-first-access`,
    registerLegalEntity: `${env.API_ONBOARDING}/api/onboarding-levels/register-legal-entity`,
  },
  searchLegalEntity: {
    details: `${env.API_ONBOARDING}/api/SearchLegalEntity/details`,
    endpoint: `${env.API_ONBOARDING}/api/SearchLegalEntity`,
  },
  tools: {
    cache: `${env.API_ONBOARDING}/api/tools/cache`,
    common: {
      endpoint: `${env.API_ONBOARDING}/api/tools/common`,
      list: `${env.API_ONBOARDING}/api/tools/common/list`,
      byDomainConfigGroup: {
        byDomainConfigKey: (
          domainConfigGroup: string,
          domainConfigKey: string,
        ) =>
          `${env.API_ONBOARDING}/api/tools/common/${domainConfigGroup}/${domainConfigKey}`,
      },
    },
  },
  workflowLevels: {
    loadEntityWorkflows: `${env.API_ONBOARDING}/api/workflow-levels/load-entity-workflows`,
    enums: `${env.API_ONBOARDING}/api/workflow-levels/enums`,
  },
  workflowDataReview: {
    loadEntityWorkflows: `${env.API_ONBOARDING}/api/workflow-data-review/load-entity-workflows`,
    byUuid: (uuid: string) =>
      `${env.API_ONBOARDING}/api/workflow-data-review/${uuid}`,
    enums: `${env.API_ONBOARDING}/api/workflow-data-review/enums`,
  },
  workflowDataUpdate: {
    loadEntityWorkflows: `${env.API_ONBOARDING}/api/workflow-data/load-entity-workflows`,
    byUuid: (uuid: string) => `${env.API_ONBOARDING}/api/workflow-data/${uuid}`,
    enums: `${env.API_ONBOARDING}/api/workflow-data/enums`,
  },
};
