import { ViewParseType } from "./operations-installments.types";

export const parseInstallmentStatusType: ViewParseType = {
  Cancelada: "Cancelada",
  Vencida: "Vencida",
  APagar: "A Vencer",
  ParcialmentePaga: "Parcialmente Paga",
  Paga: "Quitada",
};

export const installmentsFromSharkStatus = [
  "Desembolsado",
  "Atrasado",
  "Liquidado",
];
