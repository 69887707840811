import { OToastManager, modalManager } from "@maestro/core";
import { OConfirmationModal, OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import { IconButton } from "components/icon-button";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { datagridModalFix } from "utils/datagrid-modal-fix";
import { MleByTradeIdRouteParams } from "../../../../../../../routes/energia.route-params";
import { getValidationMessages } from "../../../../../../../utils";

interface GenerateMigrationDocumentAgainButtonProps {
  possibleDocument: HubEnergy.PossibleDocumentResponse;
}

export const GenerateMigrationDocumentAgainButton = ({
  possibleDocument,
}: GenerateMigrationDocumentAgainButtonProps) => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.generateMigrationDocuments,
  );

  const { tradeId } = useParams<MleByTradeIdRouteParams>();

  const documentType = possibleDocument.possibleDocument.type.name;
  const consumerUnitId = possibleDocument.consumerUnit.relatedEntityId;
  const generate = useCallback(async () => {
    if (!tradeId) return OToastManager.danger("No tradeId");

    const { success, response, error } = await callService({
      tradeId: Number(tradeId),
      consumerUnitIds: [consumerUnitId],
      documentTypes: [documentType],
    });

    if (success && response.data.success)
      OToastManager.success(
        `A ${documentType} foi gerado novamente e enviado para a assinatura do cliente`,
      );
    else
      OToastManager.danger(
        getValidationMessages(error)?.[0].ErrorMessage ??
          `Erro ao gerar a ${documentType}`,
      );
  }, [callService, consumerUnitId, documentType, tradeId]);

  const modalId = `generate-${documentType}-again-confirmation-modal-${consumerUnitId}`;

  return (
    <>
      <IconButton
        icon={{ category: "orq", icon: "orq-swap-circle" }}
        loading={loading}
        type="danger"
        onClick={() => modalManager.show(modalId)}
      >
        Gerar novamente
      </IconButton>
      <OConfirmationModal
        {...datagridModalFix}
        modalId={modalId}
        title={`Gerar nova ${documentType}`}
        onConfirm={() => {
          generate();
          modalManager.hide(modalId);
        }}
        divider={false}
        className="position-absolute"
        confirmLabel="Confirmar"
        closeLabel="Cancelar"
      >
        <OTypography style={{ whiteSpace: "normal" }}>
          Certeza que deseja gerar novamente a {documentType} para a unidade{" "}
          {possibleDocument.consumerUnit.officialName} -{" "}
          {masks.cpfCnpj(possibleDocument.consumerUnit.taxId)} n° de instalação{" "}
          {possibleDocument.consumerUnit.installationNumber}? Após a
          confirmação, o documento será gerado e o cliente será notificado para
          realizar a assinatura.
        </OTypography>
      </OConfirmationModal>
    </>
  );
};
