import {
  CentralBankEnabledSystem,
  FinancialInstitution,
} from "hooks/financial-institution/financial-institution.hook";
import { AccountUtils } from "./account";

const FINANCIAL_INSTITUTION_NOT_FOUND = {
  bankCode: "S/N",
  name: "Banco não identificado",
};

const BTG_ISPB = "30306294";

/** Função responsável por retornar o nome da instituição financeira no formato '{bankCode} - {Nome}' */
const assembleInstitutionResume = (
  /** Instituição financeira */
  {
    bankCode = FINANCIAL_INSTITUTION_NOT_FOUND.bankCode,
    shortName,
    fullName,
  }: FinancialInstitution,
  /** Define se será utilizado o shortName ou fullName */
  showFullName?: boolean,
) => {
  return `${bankCode} - ${showFullName ? fullName : shortName}`;
};

/** Função responsável por buscar uma instituição financeira a partir do ISPB ou BankCode */
const findFinancialInstitution = (
  /** Lista de Instituições financeiras */
  financialInstitutions: FinancialInstitution[],
  /** Referência de valor para a busca da Instituição financeira (pode ser preenchido com o bankCode e/ou ispb) */
  reference: string | [string | undefined, string | undefined],
) => {
  if (typeof reference === "string") {
    return financialInstitutions.find(({ ispb, bankCode }) =>
      [bankCode, ispb].includes(reference),
    );
  }

  return financialInstitutions.find(
    ({ ispb, bankCode }) =>
      (!!bankCode && reference?.includes(bankCode)) ||
      (!!ispb && reference?.includes(ispb)),
  );
};

/** Função responsável por retornar os dados da conta no formato '{bankCode} - {Nome} | Agência 00XX - Conta XXXXXXXXX-X' */
const assembleAccountResume = (
  /** Dados da conta */
  account: { agency: string; number: string },
  /** Lista de Instituições financeiras */
  financialInstitutions: FinancialInstitution[],
  /** Referência de valor para a busca da Instituição financeira (pode ser preenchido com o bankCode e/ou ispb) */
  reference: string | [string | undefined, string | undefined],
  /** Divisor entre as informações da Instituição financeira e da conta */
  divisor = "|",
) => {
  const financialInstitution = findFinancialInstitution(
    financialInstitutions,
    reference,
  );

  if (!financialInstitution) return "";

  const financialInstitutionResume =
    assembleInstitutionResume(financialInstitution);

  const accountResume = AccountUtils.assembleResume(
    account.agency,
    account.number,
    "-",
  );

  const resume = `${financialInstitutionResume} ${divisor} ${accountResume}`;

  return resume.replace(/\s+/g, " ");
};

/** Função responsável por buscar o nome instituição financeira a partir do ISPB ou BankCode */
const loadFinancialInstitutionName = (
  /** Lista de Instituições financeiras */
  financialInstitutions: FinancialInstitution[],
  /** Referência de valor para a busca da Instituição financeira (pode ser preenchido com o bankCode e/ou ispb) */
  reference: string | [string | undefined, string | undefined],
  /** Formato do nome (shortName/fullName) */
  nameType: "shortName" | "fullName" = "shortName",
  /** Define se será retornado o nome da instituição financeira no formato '{bankCode} - {Nome}' */
  showBankCode?: boolean,
) => {
  const data = findFinancialInstitution(financialInstitutions, reference);

  if (!data) return FINANCIAL_INSTITUTION_NOT_FOUND.name;

  const institutionName = showBankCode
    ? assembleInstitutionResume(data)
    : data[nameType];

  return institutionName ?? FINANCIAL_INSTITUTION_NOT_FOUND.name;
};

/** Função responsável por validar se a Instituição financeira permite a operação com base no valores de CentralBankEnabledSystems */
const validateFinancialInstitutionCentralBankEnabledSystem = (
  /** Lista de Instituições financeiras */
  financialInstitutions: FinancialInstitution[],
  /** Referência de valor para a busca da Instituição financeira (pode ser preenchido com o bankCode e/ou ispb) */
  reference: string | [string | undefined, string | undefined],
  /** Valor/Operação a ser validada com base no valores de CentralBankEnabledSystems */
  target: CentralBankEnabledSystem,
) => {
  const data = findFinancialInstitution(financialInstitutions, reference);
  if (!data) return false;
  const { centralBankEnabledSystems } = data;
  return centralBankEnabledSystems.includes(target);
};

export const FinancialInstitutionUtils = {
  BTG_ISPB,
  assembleInstitutionResume,
  assembleAccountResume,
  findFinancialInstitution,
  loadFinancialInstitutionName,
  validateFinancialInstitutionCentralBankEnabledSystem,
};
