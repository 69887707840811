import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { exchangeProductCurrenciesGrid } from "./exchange-product-currencies.grid";

export const ExchangeProductCurrenciesPage = () => {
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Moedas" />}
      actions={
        <RouterButton
          dataAction="cambio_moedas:botao:adicionar_moeda"
          dataLabel="adicionar_moeda"
          href={
            corporateRouter.routes.cambio.product.contingency.currencies.form
              .path
          }
          role={roles.cambio.product.contingencyCurrenciesFormPage.role}
        >
          Adicionar moeda
        </RouterButton>
      }
      gridRef={gridRef}
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={exchangeProductCurrenciesGrid}
      />
    </GridTemplate>
  );
};
