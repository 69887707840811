import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import {
  LoanPartnerForm,
  LoanPartnersFormValues,
} from "../../../../components/loan-partner/loan-partner-form.component";
import { loanPartnerValidationFormSchema } from "../../../../components/loan-partner/loan-partner-form.schemas";

export const AddPartnerPage = () => {
  const [loading, setLoading] = useState(false);

  const form = useForm<LoanPartnersFormValues>({
    defaultValues: {
      webHookAuthType: "Default",
    },
    resolver: yupResolver(loanPartnerValidationFormSchema),
  });

  const { handleSubmit } = form;

  const addPartner = useCallback(async (values: LoanPartnersFormValues) => {
    try {
      setLoading(true);

      await service.hubLoan.addPartner(values);

      OToastManager.success("Novo parceiro cadastrado com sucesso.");
    } catch {
      OToastManager.danger(
        "Ocorreu um erro ao tentar cadastrar o novo parceiro.",
      );
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Cadastrar parceiro" />}
      actions={
        <LoadingButton
          loading={loading}
          dataAction="emprestimos_cadastrar_parceiros:botao:cadastrar"
          dataLabel="cadastrar"
          onClick={handleSubmit(addPartner)}
        >
          Cadastrar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <LoanPartnerForm />
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
