import { OToastManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { gridStorage } from "utils/storage";
import { getValidationMessages } from "../../../../utils";
import { GridActions } from "./_compose";

export const dataSource =
  dataSourceCustomStoreGenerator<HubEnergy.DiligenceStepConfigBaseResponse>(
    () =>
      service.hubEnergy
        .getDiligenceStepConfig()
        .then(({ data }) => data.response ?? [])
        .catch((error) => {
          const validator =
            getValidationMessages(error)?.[0]?.ErrorMessage ??
            "Erro ao buscar as configurações de step";

          OToastManager.danger(validator);
          throw new Error(validator);
        }),
  );

export const stepConfigsGrid: ODataGridGeneratorConfig<HubEnergy.DiligenceStepConfigBaseResponse> =
  {
    datagrid: {
      noDataText: "Nenhuma configuração de step",
      filterRow: { visible: true },
      headerFilter: { visible: true },
      pager: { showPageSizeSelector: true },
      stateStoring: gridStorage("stepConfigsGrid"),
      columnResizingMode: "nextColumn",
    },
    columns: [
      {
        role: roles.energia.product.diligenceConfigUpdateDocument.role,
        cellRender: ({ data }) => (
          <DetailsLink
            href={corporateRouter.routes.energia.product.diligenceConfig.stepConfigs.update.path(
              {
                stepConfigId: data.id,
              },
            )}
          />
        ),
        width: "auto",
        allowExporting: false,
      },
      {
        dataField: "id",
        caption: "Id",
        width: "auto",
      },
      {
        dataField: "name",
        caption: "Nome",
        sortOrder: "asc",
      },
      {
        dataField: "label",
        caption: "Label",
      },
      {
        dataField: "type.name",
        caption: "Tipo do step",
      },
      {
        caption: "Ações",
        width: "auto",
        cellRender: ({ data, component }) => (
          <GridActions
            stepConfig={data}
            reload={() => component.getDataSource().reload()}
          />
        ),
        allowExporting: false,
      },
    ],
  };
