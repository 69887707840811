import { client } from "../../client";
import { ODataQueryRequest } from "../../service.types";
import { accEndpoints } from "../acc.endpoints";
import { GetAdminAccCounterpartiesODataResponse } from "./models/responses/get-admin-acc-counterparties-o-data.response";
import { AdminAccProposalsODataResponse } from "./models/responses/get-admin-acc-proposals-o-data.response";
import { AdminAccTradesODataResponse } from "./models/responses/get-admin-acc-trades-o-data.response";

export const accODataService = {
  getAccCounterparties(params: ODataQueryRequest) {
    return client.get<GetAdminAccCounterpartiesODataResponse>(
      accEndpoints.odata.adminACCCounterpartiesOData,
      { params },
    );
  },
  getAccProposals(params: ODataQueryRequest) {
    return client.get<AdminAccProposalsODataResponse>(
      accEndpoints.odata.adminACCProposalsOData,
      { params },
    );
  },
  getAccTrades(params: ODataQueryRequest) {
    return client.get<AdminAccTradesODataResponse>(
      accEndpoints.odata.adminACCTradesOData,
      { params },
    );
  },
};
