import {
  OCheckbox,
  OCheckboxGroup,
  OFilterItem,
  OLabel,
  OTypography,
} from "@maestro/react";

export const BadCreditStatusFilterItem = () => {
  return (
    <OFilterItem>
      <OTypography slot="title">Status da Negativação</OTypography>
      <OCheckboxGroup
        dataAction="contas_a_receber_inicio_filtros_do_sidebar:select:status_da_negativacao"
        dataLabel="selecionar_status_da_negativacao"
        className="d-flex flex-column align-items-center w-100"
        data-filterparam="badCreditStatus"
      >
        <div className="d-flex align-items-center justify-content-between py-3 w-95 border-bottom">
          <OLabel htmlFor="status_processing">Em negativação</OLabel>
          <OCheckbox
            id="status_processing"
            value="STARTING,PROCESSING,WARNING_PERIOD,FAILED_TO_SEND"
            size="sm"
          />
        </div>

        <div className="d-flex align-items-center justify-content-between py-3 w-95 border-bottom">
          <OLabel htmlFor="status_confirmed">Negativado</OLabel>
          <OCheckbox id="status_confirmed" value="CONFIRMED" size="sm" />
        </div>

        <div className="d-flex align-items-center justify-content-between py-3 w-95 border-bottom">
          <OLabel htmlFor="status_canceled">Negativação cancelada</OLabel>
          <OCheckbox id="status_canceled" value="CANCELED" size="sm" />
        </div>
      </OCheckboxGroup>
    </OFilterItem>
  );
};
