import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { CreditConciliation } from "./credit-conciliation.types";
import { creditConciliationGrid } from "./credit-conciliation.grid";

const dataSource = dataSourceCustomStoreGenerator<CreditConciliation>(() =>
  service.fidc
    .getCreditConciliation()
    .then(({ data }) => ({
      data: data.orders,
      totalCount: data.totalItems,
    }))
    .catch(() => {
      const errorMessage = "Erro ao buscar os dados";
      OToastManager.danger(errorMessage);
      throw new Error(errorMessage);
    }),
);

export const CreditConciliationPage = () => {
  const gridRef = useGridRef();
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Liquidação por Repasse" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={creditConciliationGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
