import { env } from "utils/environments";

export const hubPartnersEndpoints = {
  admin: {
    economicGroup: {
      endpoint: `${env.API_PARTNERS}/api/v1/admin/economic-groups`,
      originators: {
        byOriginatorIdentification: {
          endpoint: `${env.API_PARTNERS}/api/v1/admin/economic-groups/originators/by-originator-identification`,
        },
      },
    },
    partnershipType: {
      endpoint: `${env.API_PARTNERS}/api/v1/admin/partnership-type`,
    },
    originatorSubtype: {
      endpoint: `${env.API_PARTNERS}/api/v1/admin/originator-subtype`,
    },
    books: {
      endpoint: `${env.API_PARTNERS}/api/v1/admin/book`,
    },
    products: {
      endpoint: `${env.API_PARTNERS}/api/v1/admin/product`,
    },
    catalogs: {
      endpoint: `${env.API_PARTNERS}/api/v1/admin/catalog`,
    },
    productLink: {
      endpoint: `${env.API_PARTNERS}/api/v1/admin/product/link`,
    },
    originators: {
      byTaxId: {
        detail: (uuid: string) =>
          `${env.API_PARTNERS}/api/v1/admin/originators/${uuid}/details`,
      },
      byUuid: {
        endpoint: (uuid: string) =>
          `${env.API_PARTNERS}/api/v1/admin/originators/${uuid}`,
        indicator: (uuid: string) =>
          `${env.API_PARTNERS}/api/v1/admin/originators/${uuid}/indicator`,
        permission: (uuid: string) =>
          `${env.API_PARTNERS}/api/v1/admin/originators/${uuid}/permission`,
        operators: (uuid: string) =>
          `${env.API_PARTNERS}/api/v1/admin/originators/${uuid}/operators`,
        remuneration: (uuid: string) =>
          `${env.API_PARTNERS}/api/v1/admin/originators/${uuid}/remuneration`,
      },
      endpoint: `${env.API_PARTNERS}/api/v1/admin/originators`,
      main: `${env.API_PARTNERS}/api/v1/admin/originators/main`,
      search: `${env.API_PARTNERS}/api/v1/admin/originators/search`,
    },

    odata: {
      adminGetODataAccountRelationships: `${env.API_PARTNERS}/api/odata/AdminGetODataAccountRelationships`,
      adminGetODataEconomicGroups: `${env.API_PARTNERS}/api/odata/AdminGetODataEconomicGroups`,
      adminGetODataEntityRelationships: `${env.API_PARTNERS}/api/odata/AdminGetODataEntityRelationships`,
      adminGetODataOperators: `${env.API_PARTNERS}/api/odata/AdminGetODataOperatorProfiles`,
      adminGetODataOriginators: `${env.API_PARTNERS}/api/odata/AdminGetODataOriginators`,
    },
  },
  operatorProfiles: {
    byIdentifier: {
      details: `${env.API_PARTNERS}/api/v1/admin/operator-profiles/details`,
    },
    byOperatorProfileUUID: {
      hierarchies: (operatorProfileUUID: string) =>
        `${env.API_PARTNERS}/api/v1/operators/${operatorProfileUUID}/hierarchies`,
      unlink: (operatorProfileUUID: string) =>
        `${env.API_PARTNERS}/api/v1/operators/${operatorProfileUUID}/unlink`,
    },
    endpoint: `${env.API_PARTNERS}/api/v1/operators`,
    search: `${env.API_PARTNERS}/api/v1/operators/search`,
  },
};
