import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { service } from "services";
import { gridStorage } from "utils/storage";
import { MappedStatusBadge } from "../../../../components/mapped-status-badge";
import {
  energyGuarantorStatusMap,
  getValidationMessages,
} from "../../../../utils";
import { Guarantor } from "./trade-guarantors-grid.types";
import {
  addErrorsToGuarantors,
  maritalStatusTypeMap,
  weddingRegimeTypeMap,
} from "./trade-guarantors-grid.utils";

export const buildDataSource = (tradeId: string | number) =>
  dataSourceCustomStoreGenerator(
    () => {
      return service.hubEnergy
        .getTradeGuarantors(tradeId)
        .then(({ data: { response } }) => {
          if (!response?.lead.customerEntityTaxId)
            throw new Error("Cliente não encontrado");

          if (!response.leadGuarantors?.length) return Promise.resolve([]);

          return service.onboardingRelationships.b2c
            .loadGuarantors(
              {
                requiredGuarantors:
                  response.leadGuarantors.map(
                    (guarantor) => guarantor.entity.taxId,
                  ) ?? [],
                type: "SIMPLES",
              },
              response.lead.customerEntityTaxId,
            )
            .then(() => response.leadGuarantors)
            .catch((err) => {
              return addErrorsToGuarantors(err, response.leadGuarantors);
            });
        })
        .catch((err) => {
          const errorMessage =
            getValidationMessages(err)?.[0]?.ErrorMessage ??
            "Erro ao buscar os avalistas";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        });
    },
    {
      customStoreOptions: {
        update: async (original, updated) => {
          try {
            const payload = {
              guarantorId: original.id,
              maritalStatusType: original.maritalStatusType,
              weddingRegimeType: original.weddingRegimeType,
            };

            Object.assign(payload, updated);

            const {
              data: { response },
            } = await service.hubEnergy.updateGuarantorMaritalStatus(payload);

            if (response) {
              OToastManager.success("Estado civil atualizado com sucesso");

              return response;
            }
          } catch (err) {
            const errorMessage =
              getValidationMessages(err)?.[0]?.ErrorMessage ??
              "Erro ao atualizar o estado civil";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }
        },
      },
    },
  );

export const tradeGuarantorsGrid = (allowUpdating = false) =>
  ({
    datagrid: {
      noDataText: "Nenhum avalista",
      editing: {
        allowUpdating,
      },
      stateStoring: gridStorage("tradeGuarantorsGrid"),
      onEditorPreparing: (e) => {
        e.editorOptions = {
          ...e.editorOptions,
          dropDownOptions: {
            ...e.editorOptions?.dropDownOptions,
            minWidth: 400,
          },
          itemTemplate: (itemData, _, itemElement) => {
            (itemElement as HTMLElement).setAttribute("title", itemData.label);
            return itemData.label;
          },
        };
      },
      columnResizingMode: "nextColumn",
    },
    columns: [
      {
        allowEditing: false,
        dataField: "entity.name",
        caption: "Nome",
      },
      {
        allowEditing: false,
        dataField: "entity.taxId",
        caption: "CPF",
        customizeText: ({ value }) => masks.cpf(value),
      },
      {
        allowEditing: false,
        dataField: "status",
        caption: "Status",
        alignment: "center",
        cellRender: ({ data }) => (
          <MappedStatusBadge
            map={energyGuarantorStatusMap}
            status={data.status}
          />
        ),
      },
      {
        dataField: "maritalStatusType",
        caption: "Estado civil",
        lookup: {
          dataSource: Object.entries(maritalStatusTypeMap).map(([k, v]) => ({
            value: k,
            label: v,
          })),
          displayExpr: "label",
          valueExpr: "value",
        },
      },
      {
        dataField: "weddingRegimeType",
        caption: "Regime de comunhão de bens",
        lookup: {
          dataSource: Object.entries(weddingRegimeTypeMap).map(([k, v]) => ({
            value: k,
            label: v,
          })),
          displayExpr: "label",
          valueExpr: "value",
          allowClearing: true,
        },
      },
      {
        allowEditing: false,
        dataField: "errorMessages",
        caption: "Erros",
        calculateCellValue: ({ errorMessages }) => errorMessages?.join(" "),
        cellRender: ({ data: { errorMessages } }) =>
          errorMessages?.map((error) => <p key={error}>{error}</p>),
      },
    ],
  } satisfies ODataGridGeneratorConfig<Guarantor>);
