import { OToastManager } from "@maestro/core";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { service } from "services";
import { CollectionFilterFields } from "./_compose/filters-modal/filters-modal.type";
import { BankslipGridColumn } from "./bankslip.type";
import { assembleFilterParams } from "./bankslip.utils";

interface BankslipContextProps {
  collectionsList: BankingHub.Collection[];
  bankslipToCancel: BankingHub.Collection | undefined;
  setBankslipToCancel: Dispatch<
    SetStateAction<BankingHub.Collection | undefined>
  >;
  bankslipToArchive: BankingHub.Collection | undefined;
  setBankslipToArchive: Dispatch<
    SetStateAction<BankingHub.Collection | undefined>
  >;
  bankslipToSeeEvents?: BankingHub.Collection;
  setBankslipToSeeEvents: Dispatch<
    SetStateAction<BankingHub.Collection | undefined>
  >;
  detailedBankslip: BankingHub.Collection | undefined;
  setDetailedBankslip: Dispatch<
    SetStateAction<BankingHub.Collection | undefined>
  >;
  pageSize: number;
  pageNumber: number;
  handleSubmitFilter: (filter: CollectionFilterFields) => void;
  handlePageSizeChange: (value: number) => void;
  handlePageNumberChange: (value: number) => void;
  refreshGrid: () => void;
  isLoading: boolean;
  hasNextPage: boolean;
  visibleColumns: BankslipGridColumn[];
  updateVisibleColumns: (newColumns: BankslipGridColumn[]) => void;
}

const BankslipContext = createContext({} as BankslipContextProps);

interface BankslipProviderProps {
  children: React.ReactNode;
}

export const BankslipProvider = ({ children }: BankslipProviderProps) => {
  const [collectionsList, setCollectionsList] = useState<
    BankingHub.Collection[]
  >([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [cursorList, setCursorList] = useState([""]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [filters, setFilters] = useState<CollectionFilterFields>({});
  const [isLoading, setIsLoading] = useState(false);

  const [bankslipToCancel, setBankslipToCancel] =
    useState<BankingHub.Collection>();
  const [bankslipToArchive, setBankslipToArchive] =
    useState<BankingHub.Collection>();
  const [bankslipToSeeEvents, setBankslipToSeeEvents] =
    useState<BankingHub.Collection>();
  const [detailedBankslip, setDetailedBankslip] =
    useState<BankingHub.Collection>();

  const [visibleColumns, setVisibleColumns] = useState<BankslipGridColumn[]>(
    () => {
      const currentColumns = localStorage.getItem("bankslipGridColumns");
      return currentColumns
        ? (currentColumns.split(",") as BankslipGridColumn[])
        : Object.values(BankslipGridColumn);
    },
  );

  const getCollectionsList = useCallback(
    async (
      index: number,
      size: number,
      filterParams: CollectionFilterFields,
    ) => {
      try {
        setIsLoading(true);
        setHasNextPage(false);
        const cursor = cursorList[index - 1];

        const params = cursor
          ? { cursor }
          : {
              pageSize: size,
              pageNumber: index,
              ...assembleFilterParams(filterParams),
            };

        const { data } = await service.adminBankinghub.getAdminCollections(
          params,
        );

        setCollectionsList(data.data);
        const hasNext = data._links.next;
        setHasNextPage(!!hasNext);

        const updatedCursorList = index === 1 ? [""] : [...cursorList];

        const shouldAddCursor = hasNext && index === updatedCursorList.length;

        if (shouldAddCursor) {
          const newCursor = hasNext.href;
          updatedCursorList.push(newCursor.slice(newCursor.indexOf("=") + 1));
        }

        setCursorList(updatedCursorList);
      } catch (err) {
        OToastManager.danger(
          "Ocorreu um erro ao listar os boletos registrados nesta conta.",
        );
      } finally {
        setIsLoading(false);
      }
    },
    [cursorList],
  );

  useEffect(() => {
    getCollectionsList(pageNumber, pageSize, filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageSizeChange = useCallback(
    async (value: number) => {
      if (value === pageSize) return;
      setPageSize(value);
      setPageNumber(1);
      getCollectionsList(1, value, filters);
    },
    [filters, getCollectionsList, pageSize],
  );

  const handlePageNumberChange = useCallback(
    async (value: number) => {
      setPageNumber(value);
      getCollectionsList(value, pageSize, filters);
    },
    [filters, getCollectionsList, pageSize],
  );

  const handleSubmitFilter = useCallback(
    (filter: CollectionFilterFields) => {
      setPageNumber(1);
      setFilters(filter);
      getCollectionsList(1, pageSize, filter);
    },
    [getCollectionsList, pageSize],
  );

  const refreshGrid = useCallback(() => {
    getCollectionsList(pageNumber, pageSize, filters);
  }, [filters, getCollectionsList, pageNumber, pageSize]);

  const updateVisibleColumns = useCallback(
    (newColumns: BankslipGridColumn[]) => {
      localStorage.setItem("bankslipGridColumns", newColumns.join());
      setVisibleColumns(newColumns);
    },
    [],
  );

  const value = useMemo(
    () => ({
      handlePageNumberChange,
      handlePageSizeChange,
      handleSubmitFilter,
      collectionsList,
      isLoading,
      pageSize,
      pageNumber,
      refreshGrid,
      hasNextPage,
      bankslipToCancel,
      setBankslipToCancel,
      bankslipToArchive,
      setBankslipToArchive,
      bankslipToSeeEvents,
      setBankslipToSeeEvents,
      detailedBankslip,
      setDetailedBankslip,
      visibleColumns,
      updateVisibleColumns,
    }),
    [
      handlePageNumberChange,
      handlePageSizeChange,
      handleSubmitFilter,
      collectionsList,
      isLoading,
      pageSize,
      pageNumber,
      refreshGrid,
      hasNextPage,
      bankslipToCancel,
      bankslipToArchive,
      bankslipToSeeEvents,
      detailedBankslip,
      visibleColumns,
      updateVisibleColumns,
    ],
  );

  return (
    <BankslipContext.Provider value={value}>
      {children}
    </BankslipContext.Provider>
  );
};

export const useBankslipContext = () => useContext(BankslipContext);
