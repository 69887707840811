import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { FormTemplate } from "templates/form-template";
import { getHubfxErrorMessage } from "../../../../../../components/error/error-code.utils";
import { ExchangeDocumentConfigsForm } from "../../../../../../components/nature/document-configs/document-configs-form.component";
import {
  ExchangeDocumentConfigsFormValues,
  exchangeDocumentConfigsFormValidationFormSchema,
} from "../../../../../../components/nature/document-configs/document-configs-form.schemas";

export const ExchangeProductCreateDocumentConfig = () => {
  const form = useForm<ExchangeDocumentConfigsFormValues>({
    resolver: yupResolver(exchangeDocumentConfigsFormValidationFormSchema),
  });

  const navigate = useNavigate();

  const { handleSubmit } = form;

  const { callService: createNatureDocumentConfigs, loading } = useServiceCall(
    service.hubFx.nature.createNatureDocumentConfigs,
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await createNatureDocumentConfigs({
          ...values,
          isFileAllowed: !!values.isFileAllowed.length,
        });
        if (!success) return OToastManager.danger(getHubfxErrorMessage(error));

        OToastManager.success(
          "Configuração de documento adicionado com sucesso",
        );

        navigate(
          corporateRouter.routes.cambio.product.contingency.natures
            .documentConfigs.path,
        );
      }),
    [createNatureDocumentConfigs, handleSubmit, navigate],
  );

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar Configuração de Documentos" />}
      actions={
        <LoadingButton
          loading={loading}
          onClick={submit}
          dataAction="adicionar"
          dataLabel="adicionar_configuracao_documento:botao:adicionar"
        >
          Adicionar
        </LoadingButton>
      }
    >
      <FormProvider {...form}>
        <ExchangeDocumentConfigsForm />
      </FormProvider>
    </FormTemplate>
  );
};
