export const contractTypeMap = {
  AlienacaoFiduciariaImovel: "Alienação fiduciária de imóvel",
  AprovacaoTransacional: "Aprovação transacional",
  AUTHENTICATE: "Autorização",
  B2BRemuneracao: "Remuneração B2B",
  BANKSLIP: "Boleto",
  CartaDenunciaMigracaoEnergia:
    "Carta-denúncia de migração para o Mercado Livre de Energia",
  CedulaDeProdutoRural: "Cédula de produto rural",
  CONSUMPTION_BILL: "Boleto de consumo",
  ContratacaoDuplicatas: "Contrato de desconto de duplicatas",
  ContratoAberturaDeConta: "Abertura de Conta Corrente",
  ContratoAberturaDeContaTransacional: "Abertura de Conta Corrente",
  ContratoAprovacao: "Aprovação",
  ContratoCambio: "Contrato de câmbio",
  ContratoCancelamento: "Cancelamento",
  ContratoCCB: "CCB",
  ContratoCessaoFutura: "Cessão Futura",
  ContratoContraGarantia: "Contrato Contra Garantia",
  ContratoDeParceria: "Contrato de Parceria",
  ContratoFopa: "Contratação de folha de pagamento",
  ContratoKitPMEContaTransacional: "Contrato Conta Transacional",
  ContratoMigracaoEnergia: "Contrato de compra de energia",
  ContratoOperacao: "Cessão de recebíveis",
  ContratoOperacaoFIDC: "Operação FIDC",
  CREDITCARDINVOICE: "Fatura do cartão",
  DARF: "DARF",
  "empresas.btgpactual.com/approvals/batch": "Arquivo em lote",
  "empresas.btgpactual.com/approvals/ciba": "CIBA",
  "empresas.btgpactual.com/bank-limit/bank-limit-operation":
    "Solicitação de ajuste de limite transacional",
  "empresas.btgpactual.com/bank-limit/downgrade-limit":
    "Solicitação de redução de limite",
  "empresas.btgpactual.com/bank-limit/temporary-limit":
    "Solicitação de ajuste temporário de limite",
  "empresas.btgpactual.com/bank-limit/upgrade-limit":
    "Solicitação de aumento de limite",
  "empresas.btgpactual.com/batches/payments": "Pagamento em lote",
  "empresas.btgpactual.com/card/activate": "Autorização de ativação de cartão",
  "empresas.btgpactual.com/card/add-credit-card":
    "Criação de cartão de crédito",
  "empresas.btgpactual.com/card/change-hired-limit":
    "Alteração de limite do cartão",
  "empresas.btgpactual.com/card/delivery-address-update":
    "Alteração do endereço de entrega do cartão",
  "empresas.btgpactual.com/card/hire-debit": "Contratação - Cartão de débito",
  "empresas.btgpactual.com/card/temporary-blocks":
    "Bloqueio temporario de cartão",
  "empresas.btgpactual.com/cards/transactions/approval":
    "Transação do cartão de crédito",
  "empresas.btgpactual.com/cards/wallets/approval":
    "Solicitação de Adição de Cartão a Carteira Digital",
  "empresas.btgpactual.com/credit/prepayment": "Antecipação de parcela",
  "empresas.btgpactual.com/free-energy/diligence":
    "Documento de migração de energia",
  "empresas.btgpactual.com/openfinance/recurring-consent/sweeping":
    "Solicitação de Consentimento Open Finance",
  "empresas.btgpactual.com/payments/fopa": "Folha de pagamento",
  "empresas.btgpactual.com/transactions/pix/reversal": "Devolução de PIX",
  "empresas.btgpactual.com/transactions/temporized": "Operação em análise",
  "empresas.btgpactual.com/user/letter-of-attorney/update":
    "Atualização de procuração",
  "empresas.btgpactual.com/user/representatives/update":
    "Atualização de representantes",
  EncerramentoDeConta: "Contrato de encerramento de conta",
  InstrumentoCessaoFiduciaria: "Instrumento de Cessão Fiduciária",
  KitFichaCadastralFacta: "Contrato de revisão cadastral",
  KitPMEAberturaDeContaTransacionalPF: "Contrato Conta Transacional PF",
  Optin: "Termos de uso",
  OptinAberturaDeConta: "Termos de uso - Conta transacional e conta escrow",
  OptinCartaoDeCredito: "Termos e condições - Cartão de crédito",
  OptinContaTransacional: "Termos de uso - Conta Transacional",
  OverdraftHire: "Limite da conta",
  PAYMENT_CNAB: "CNAB de pagamentos",
  PIX: "PIX",
  PMEBILLING_BUNDLESUBSCRIPTION: "Adesão de plano",
  ProcuracaoEletronica: "Termo de delegação de poderes",
  ProcuracaoEnergia: "Procuração de migração para o Mercado Livre de Energia",
  PropostaAgroCompartilhamentoDados: "Proposta agro compartilhamento de dados",
  Renegociacao: "Renegociação",
  RevogacaoEletronica: "Termo de revogação de poderes",
  TED: "TED",
  TEF: "TEF",
  TermoAtualizacaoCadastral: "Contrato de atualização cadastral",
  TermoDeAberturaDeContaVinculada: "Contrato abertura de conta vinculada",
  TRANSFER_BATCH: "Transferência em lote",
};

export const statusMap = {
  Canceled: "Cancelado",
  Complete: "Aprovado",
  ContractPrepared: "Preparado",
  Expired: "Expirado",
  FullySigned: "Aprovado",
  InErrorNotificationError: "Erro",
  InErrorPreparationError: "Erro",
  InErrorSignatoryInfoNotFounded: "Erro",
  InErrorWithoutPower: "Erro",
  InErrorWithoutVigency: "Erro",
  InPreparation: "Em preparação",
  Refused: "Reprovado",
  WaitingApproval: "Em análise",
  WaitingSignatures: "Pendente",
};

export const statusDescriptionMap = {
  Canceled: "Documento cancelado, o cliente não poderá assiná-lo",
  Complete: "Contrato assinado e abonado na IRIS",
  FullySigned:
    "Status transitório que identifica quando o documento foi assinado por todos os signatários necessários",
  InErrorNotificationError: "Erro ao notificar os signatórios",
  InErrorPreparationError: "Erro ao gerar o PDF do documento",
  InErrorSignatoryInfoNotFounded:
    "Erro ao gerar o contrato devido não encontrar os signatários no LESS ou na base de usuários de PME. (Obs: pode indicar que o representante legal foi ou já estava cadastrado no LESS, mas o representante não foi cadastrado na base de usuários de PME devido não ter sido pendenciado via workflow, para que o cliente envie os dados cadastrais da pessoa",
  InErrorWithoutPower:
    "Erro ao gerar o contrato devido não encontrar os signatários com poderes na IRIS",
  InErrorWithoutVigency:
    "Erro ao gerar o contrato devido os poderes cadastrados na IRIS estarem fora do período de vigência",
  InPreparation:
    "Status transitório para identificar quando o documento PDF está sendo gerado",
  Refused: "Recusado pela IRIS",
  WaitingApproval:
    "Aguardando aprovação de abono manual. (Obs.: pode indicar que há uma task de double check no LESS)",
  WaitingSignatures: "Aguardando assinatura dos signatários",
};

export const responsibleMap = {
  InPreparation: "Automação PME",
  WaitingSignatures: "Cliente",
  FullySigned: "Automação PME",
  Refused: "IRIS",
  Canceled: "PME",
  WaitingApproval: "LESS/IRIS",
  Complete: "Cliente",
  InErrorSignatoryInfoNotFounded: "PME/LESS/IRIS",
  InErrorPreparationError: "PME",
  InErrorWithoutPower: "PME/IRIS",
};
