import { masks, validators } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { corporateRouter } from "routes/corporate-router.context";
import { endpoints } from "services/endpoints";
import { buildCustomerLink } from "utils/build-customer-link";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";

export const productOriginatorsODataGrid: ODataGridGeneratorConfig<HubPartners.Odata.Originator> =
  {
    datagrid: {
      noDataText: "Nenhum originador encontrado",
      filterRow: {
        visible: true,
      },
      onDataErrorOccurred: (e) => {
        if (e.error) {
          e.error.message = "Erro ao buscar originador.";
        }
      },
      dataSource: dataSourceODataStoreGenerator(
        endpoints.hubPartners.admin.odata.adminGetODataOriginators,
      ),
    },
    columns: [
      {
        caption: "Detalhes",
        cellRender: ({ data: { identification } }) => (
          <DetailsLink
            href={buildCustomerLink(
              identification,
              corporateRouter.routes.parceiros.customer.originator.path,
            )}
          />
        ),
        allowExporting: false,
      },
      {
        dataField: "officialName",
        caption: "Razão Social",
      },
      {
        dataField: "tradingName",
        caption: "Nome fantasia",
      },
      {
        dataField: "identification",
        caption: "CNPJ",
        width: 160,
        format: (value) =>
          validators.cnpj(value) ? masks.cpfCnpj(value) : value,
        calculateFilterExpression: calculateTaxIdFilterExpression,
      },
      {
        dataField: "partnershipTypes",
        caption: "Tipo de parceria",
      },
      {
        dataField: "originatorSubTypes",
        caption: "Subtipo de originador",
      },
      {
        dataField: "cge",
        caption: "CGE",
      },
      {
        dataField: "identifier",
        caption: "Identificador Externo",
      },
      {
        dataField: "createdDate",
        caption: "Data de criação",
      },
      {
        dataField: "createdBy",
        caption: "Criado por",
        width: 160,
        format: (value) => masks.cpfCnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
      },
      {
        dataField: "updatedDate",
        caption: "Data de atualização",
      },
      {
        dataField: "updatedBy",
        caption: "Atualizado por",
      },
    ],
  };
