const host = {
  REACT_APP_API_URL: process.env.REACT_APP_API_URL,
  REACT_APP_API_LOAN: process.env.REACT_APP_API_LOAN,
  REACT_APP_API_FX: process.env.REACT_APP_API_FX,
  REACT_APP_INTEGRATION_URL: process.env.REACT_APP_INTEGRATION_URL,
  REACT_APP_PAPERCLIPV2_URL: process.env.REACT_APP_PAPERCLIPV2_URL,
  REACT_APP_SENNA: process.env.REACT_APP_SENNA,
  REACT_APP_PORTAL_URL: process.env.REACT_APP_PORTAL_URL,
  REACT_APP_QUICKFIN_APP: process.env.REACT_APP_QUICKFIN_APP,
  REACT_APP_PORTAL_BTG_BUSINESS_URL:
    process.env.REACT_APP_PORTAL_BTG_BUSINESS_URL,
  REACT_APP_PORTAL_CORE_FRONT_URL: process.env.REACT_APP_PORTAL_CORE_FRONT_URL,
  REACT_APP_PAPERCLIP_URL: process.env.REACT_APP_PAPERCLIP_URL,
  REACT_APP_USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
  REACT_APP_USER_POOL_WEB_CLIENT_ID:
    process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_RECEBIVEIS_CARTAO_URL: process.env.REACT_APP_RECEBIVEIS_CARTAO_URL,
  REACT_APP_GATEKEEPER_URL: process.env.REACT_APP_GATEKEEPER_URL,
  REACT_APP_API_GATEKEEPER: process.env.REACT_APP_API_GATEKEEPER,
  REACT_APP_URL_WHITELABEL: process.env.REACT_APP_URL_WHITELABEL,
  REACT_APP_API_PARCEIROS: process.env.REACT_APP_API_PARCEIROS,
  REACT_APP_HUB_PARTNERS: process.env.REACT_APP_HUB_PARTNERS,
  REACT_APP_CORBAN_URL: process.env.REACT_APP_CORBAN_URL,
  REACT_APP_API_B2B: process.env.REACT_APP_API_B2B,
  REACT_APP_STATIC: process.env.REACT_APP_STATIC,
  REACT_APP_URL_BANKINGADMIN: process.env.REACT_APP_URL_BANKINGADMIN,
  REACT_APP_API_BANKINGHUB: process.env.REACT_APP_API_BANKINGHUB,
  REACT_APP_URL_BANKSLIPISSUE: process.env.REACT_APP_URL_BANKSLIPISSUE,
  REACT_APP_API_ONBOARDING: process.env.REACT_APP_API_ONBOARDING,
  REACT_APP_FIDC_URL: process.env.REACT_APP_FIDC_URL,
  REACT_APP_FIDC_BANKSLIP_MANAGER_URL:
    process.env.REACT_APP_FIDC_BANKSLIP_MANAGER_URL,
  REACT_APP_API_CLERK: process.env.REACT_APP_API_CLERK,
  REACT_APP_ENERGY_URL: process.env.REACT_APP_ENERGY_URL,
  REACT_APP_ENERGY_LEADS_URL: process.env.REACT_APP_ENERGY_LEADS_URL,
  REACT_APP_CALCULATOR_URL: process.env.REACT_APP_CALCULATOR_URL,
  REACT_APP_CALCULATOR_VERSION: process.env.REACT_APP_CALCULATOR_VERSION,
  REACT_APP_API_COLLECTION: process.env.REACT_APP_API_COLLECTION,
  REACT_APP_GATEKEEPERFRAUD_URL: process.env.REACT_APP_GATEKEEPERFRAUD_URL,
  REACT_APP_API_GATEHOLDER: process.env.REACT_APP_API_GATEHOLDER,
  REACT_APP_URL_DIEBOLD: process.env.REACT_APP_URL_DIEBOLD,
  REACT_APP_API_CUSTOMER: process.env.REACT_APP_API_CUSTOMER,
  REACT_APP_API_CREDIT_MANAGER: process.env.REACT_APP_API_CREDIT_MANAGER,
  REACT_APP_API_RAVEN: process.env.REACT_APP_API_RAVEN,
  REACT_APP_PROJECT_ENV: process.env.REACT_APP_PROJECT_ENV,
  REACT_APP_AMAZON_COGNITO_DOMAIN: process.env.REACT_APP_AMAZON_COGNITO_DOMAIN,
  REACT_APP_OAUTH_REDIRECT_SIGN: process.env.REACT_APP_OAUTH_REDIRECT_SIGN,
  REACT_APP_OAUTH_REDIRECT_SIGN_FALLBACK:
    process.env.REACT_APP_OAUTH_REDIRECT_SIGN_FALLBACK,
  REACT_APP_OAUTH_REDIRECT_SIGNOUT:
    process.env.REACT_APP_OAUTH_REDIRECT_SIGNOUT,
  REACT_APP_AMAZON_COGNITO_PROVIDER:
    process.env.REACT_APP_AMAZON_COGNITO_PROVIDER,
  REACT_APP_API_FSL: process.env.REACT_APP_API_FSL,
  REACT_APP_DD_ENV: process.env.REACT_APP_DD_ENV,
  REACT_APP_ASSETS: process.env.REACT_APP_ASSETS,
  REACT_APP_API_RELATIONSHIPS: process.env.REACT_APP_API_RELATIONSHIPS,
  REACT_APP_CARDS_OFFER_ENGINE_URL:
    process.env.REACT_APP_CARDS_OFFER_ENGINE_URL,
  REACT_APP_WEBSITE_WHITELABEL: process.env.REACT_APP_WEBSITE_WHITELABEL,
  REACT_APP_LIMIT_CONTROL_URL: process.env.REACT_APP_LIMIT_CONTROL_URL,
  REACT_APP_API_RENEGOTIATION: process.env.REACT_APP_API_RENEGOTIATION,
};

const env = {
  API_FSL: host.REACT_APP_API_FSL,
  OIDC_URL: process.env.OIDC_URL,
  DD_APPLICATION_ID: process.env.DD_APPLICATION_ID,
  DD_CLIENT_TOKEN: process.env.DD_CLIENT_TOKEN,
  DD_SERVICE: process.env.DD_SERVICE,
  DD_ENV: host.REACT_APP_DD_ENV,
  // env
  REACT_APP_PROJECT_ENV: host.REACT_APP_PROJECT_ENV,
  NODE_ENV: process.env.NODE_ENV,
  // Amplify
  USER_POOL_ID: host.REACT_APP_USER_POOL_ID,
  USER_POOL_WEB_CLIENT_ID: host.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  AMAZON_COGNITO_DOMAIN: host.REACT_APP_AMAZON_COGNITO_DOMAIN,
  OAUTH_REDIRECT_SIGN: host.REACT_APP_OAUTH_REDIRECT_SIGN,
  REACT_APP_OAUTH_REDIRECT_SIGN_FALLBACK:
    host.REACT_APP_OAUTH_REDIRECT_SIGN_FALLBACK,
  OAUTH_REDIRECT_SIGNOUT: host.REACT_APP_OAUTH_REDIRECT_SIGNOUT,
  AMAZON_COGNITO_PROVIDER: host.REACT_APP_AMAZON_COGNITO_PROVIDER,
  // APIs
  CLERK_API: host.REACT_APP_API_CLERK,
  GATEKEEPER_URL: host.REACT_APP_API_GATEKEEPER,
  GATEHOLDER_API: host.REACT_APP_API_GATEHOLDER,
  QUICKFIN_API: host.REACT_APP_API_URL,
  SENNA_API: host.REACT_APP_SENNA,
  // CoreFront
  COREFRONT_URL: host.REACT_APP_PORTAL_CORE_FRONT_URL,

  RENEGOTIATION_API: host.REACT_APP_API_RENEGOTIATION,
  API_LOAN: host.REACT_APP_API_LOAN,
  API_FX: host.REACT_APP_API_FX,
  INTEGRATION_URL: host.REACT_APP_INTEGRATION_URL,
  PAPERCLIPV2_URL: host.REACT_APP_PAPERCLIPV2_URL,
  PORTAL_URL: host.REACT_APP_PORTAL_URL,
  QUICKFIN_APP: host.REACT_APP_QUICKFIN_APP,
  PORTAL_BTG_BUSINESS_URL: host.REACT_APP_PORTAL_BTG_BUSINESS_URL,
  PORTAL_CORE_FRONT_URL: host.REACT_APP_PORTAL_CORE_FRONT_URL,
  PAPERCLIP_URL: host.REACT_APP_PAPERCLIP_URL,
  RECEBIVEIS_CARTAO_URL: host.REACT_APP_RECEBIVEIS_CARTAO_URL,
  URL_WHITELABEL: host.REACT_APP_URL_WHITELABEL,
  API_PARCEIROS: host.REACT_APP_API_PARCEIROS,
  HUB_PARTNERS: host.REACT_APP_HUB_PARTNERS,
  CORBAN_URL: host.REACT_APP_CORBAN_URL,
  API_B2B: host.REACT_APP_API_B2B,
  STATIC: host.REACT_APP_STATIC,
  URL_BANKINGADMIN: host.REACT_APP_URL_BANKINGADMIN,
  BANKING_API: host.REACT_APP_API_BANKINGHUB,
  URL_BANKSLIPISSUE: host.REACT_APP_URL_BANKSLIPISSUE,
  API_ONBOARDING: host.REACT_APP_API_ONBOARDING,
  FIDC_URL: host.REACT_APP_FIDC_URL,
  FIDC_BANKSLIP_MANAGER_URL: host.REACT_APP_FIDC_BANKSLIP_MANAGER_URL,
  ENERGY_URL: host.REACT_APP_ENERGY_URL,
  ENERGY_LEADS_URL: host.REACT_APP_ENERGY_LEADS_URL,
  CALCULATOR_URL: host.REACT_APP_CALCULATOR_URL,
  CALCULATOR_VERSION: host.REACT_APP_CALCULATOR_VERSION,
  API_COLLECTION: host.REACT_APP_API_COLLECTION,
  GATEKEEPERFRAUD_URL: host.REACT_APP_GATEKEEPERFRAUD_URL,
  URL_DIEBOLD: host.REACT_APP_URL_DIEBOLD,
  API_CUSTOMER: host.REACT_APP_API_CUSTOMER,
  API_CREDIT_MANAGER: host.REACT_APP_API_CREDIT_MANAGER,
  API_RAVEN: host.REACT_APP_API_RAVEN,
  ASSETS: host.REACT_APP_ASSETS,
  API_RELATIONSHIPS: host.REACT_APP_API_RELATIONSHIPS,
  CARDS_OFFER_ENGINE_URL: host.REACT_APP_CARDS_OFFER_ENGINE_URL,
  WEBSITE_WHITELABEL: host.REACT_APP_WEBSITE_WHITELABEL,
  LIMIT_CONTROL_URL: host.REACT_APP_LIMIT_CONTROL_URL,
};

enum LimitAnalysisStatus {
  Created = "Created",
  Approved = "Approved",
  Expired = "Expired",
  Canceled = "Canceled",
}

enum ProductType {
  RiscoSacado = "RiscoSacado",
  RiscoCedente = "RiscoCedente",
  Offshore = "Offshore",
  Cartao = "Cartao",
  FIDC = "FIDC",
  FundingProprio = "FundingProprio",
  CCC = "CCC",
}

export const endpoints = {
  assets: (fileKey: string) => `${env.ASSETS}/${fileKey}`,
  energyLeads: `${env.ENERGY_LEADS_URL}`,
  corban: {
    partner: {
      companiesFeeds: `${env.API_B2B}/api/partner/companies/feeds`,
    },
    salesforce: {
      getOperators: (taxId: string) =>
        `${env.API_B2B}/api/salesforce/operators?cnpj=${taxId}`,
      getOriginator: `${env.API_B2B}/api/salesforce/originator`,
      getCustomers: (taxId: string) =>
        `${env.API_B2B}/api/salesforce/customers?cnpj=${taxId}`,
    },
  },
  apiHubLoan: {
    salesforce: {
      getContract: (contractId: string) =>
        `${env.API_LOAN}/api/salesforce/contract/${contractId}/download`,
      getHistory: (identification: string) =>
        `${env.API_LOAN}/api/salesforce/company/${identification}/history`,
      getInstallments: (loanId: string) =>
        `${env.API_LOAN}/api/salesforce/loan/${loanId}`,
    },
    getDetails: (loanId: number | string) =>
      `${env.API_LOAN}/api/admin/loan/${loanId}/details`,
    downloadFile: (key: string) =>
      `${env.API_LOAN}/api/admin/company/download?key=${key}`,
    cancelLoan: (loanId: number | string) =>
      `${env.API_LOAN}/api/admin/loan/${loanId}/cancel`,
    republishTrade: `${env.API_LOAN}/api/admin/loan/republish`,
    updateStatus: `${env.API_LOAN}/api/admin/loan/status`,
    agreements: `${env.API_LOAN}/api/admin/agreement`,
    agreementContracts: {
      contract: `${env.API_LOAN}/api/admin/agreement-contract`,
      getContracts: (agreementId: number | string) =>
        `${env.API_LOAN}/api/admin/agreement-contract/${agreementId}`,
      deleteContract: (id: number | string) =>
        `${env.API_LOAN}/api/admin/agreement-contract/${id}`,
    },
    agreementsFund: `${env.API_LOAN}/api/admin/agreement/fund`,
    channel: `${env.API_LOAN}/api/admin/channel`,
    companyReadiness: (taxId: string) =>
      `${env.API_LOAN}/api/admin/company/${taxId}/readiness`,
    partner: `${env.API_LOAN}/api/admin/partner`,
    arrangement: `${env.API_LOAN}/api/admin/arrangement/`,
    arrangementsByPartner: (partnerName: string) =>
      `${env.API_LOAN}/api/admin/arrangement/${partnerName}`,
    arrangementById: (partnerChannelAgreementId: number | string) =>
      `${env.API_LOAN}/api/admin/arrangement/id/${partnerChannelAgreementId}`,
    failedRequest: (id: number | string) =>
      `${env.API_LOAN}/api/admin/failedrequest/${id}`,
    setArrangementToCompany: (taxId: string) =>
      `${env.API_LOAN}/api/admin/company/${taxId}/arrangements`,
    getDisbursements: (loanId: number | string) =>
      `${env.API_LOAN}/api/admin/loan/${loanId}/disbursements`,
    limitControl: {
      allPedingApproval: `${env.API_LOAN}/api/admin/limit-control/all-pending-approval`,
      avaliableLimit: (taxId: string, product: string) =>
        `${env.API_LOAN}/api/admin/limit-control/${taxId}/${product}/available`,
      limitApproval: (limitUpdatedId: string) =>
        `${env.API_LOAN}/api/admin/limit-control/${limitUpdatedId}/approve`,
      requestNewLimit: (taxId: string, product: string) =>
        `${env.API_LOAN}/api/admin/limit-control/${taxId}/${product}/request-new-limit`,
    },
    importFtsTrade: `${env.API_LOAN}/api/admin/trades/import`,
    contingency: {
      getContingency: `${env.API_LOAN}/api/admin/configuration-variable/all`,
      updateContingency: `${env.API_LOAN}/api/admin/configuration-variable`,
      loanContractsConfig: `${env.API_LOAN}/api/admin/loan-contracts-config`,
      getLoanContractsConfig: `${env.API_LOAN}/api/admin/loan-contracts-config/all`,
      getLoanContractsConfigDetails: (loanContractsId: string) =>
        `${env.API_LOAN}/api/admin/loan-contracts-config/${loanContractsId}`,
      contractTemplateConfig: `${env.API_LOAN}/api/admin/contract-template-config`,
      deleteContractTemplateConfig: (contractTemplateId: string) =>
        `${env.API_LOAN}/api/admin/contract-template-config/${contractTemplateId}`,
      downloadContractTemplateConfig: (key: string) =>
        `${env.API_LOAN}/api/admin/company/download?key=${key}`,
      uploadContractTemplate: `${env.API_LOAN}/api/admin/company/upload`,
    },
    summary: {
      summaryReport: `${env.API_LOAN}/api/reports/mainreport/summary`,
    },
    report: {
      downloadReport: `${env.API_LOAN}/api/reports/mainreport/download`,
    },
    disbursement: {
      updateValue: `${env.API_LOAN}/api/admin/disbursement/value`,
    },
    contract: {
      getContractsByLoanId: (loanId: number | string) =>
        `${env.API_LOAN}/api/admin/contract/${loanId}`,
    },
    guarantee: {
      getGuaranteesByContractId: (contractId: number | string) =>
        `${env.API_LOAN}/api/admin/guarantee/contract/${contractId}`,
      getGuaranteeByLoanId: (loanId: number | string) =>
        `${env.API_LOAN}/api/admin/guarantee/loan/${loanId}`,
    },
    pendency: {
      resolve: (pendencyId: number | string) =>
        `${env.API_LOAN}/api/admin/loan/pendency/${pendencyId}/resolve`,
      resolveAll: (loanId: number | string) =>
        `${env.API_LOAN}/api/admin/loan/${loanId}/pendency/resolve-all`,
    },
    odata: {
      getOperationsLoan: `${env.API_LOAN}/odata/LoanAdmin`,
      getAgroProposals: `${env.API_LOAN}/odata/AgricultureProposalsAdmin`,
      getInstallments: (query: string) =>
        `${env.API_LOAN}/odata/InstallmentsDueDatesAdmin${query}`,
      getFailedRequests: `${env.API_LOAN}/odata/FailedRequests`,
      getTradeImports: `${env.API_LOAN}/odata/TradeImportsAdmin`,
    },
  },
  hubcreditmanager: {
    salesforce: {
      companyCreditLines: `${env.API_CREDIT_MANAGER}/odata/LimitsSalesForce`,
      companyCreditLineDetails: `${env.API_CREDIT_MANAGER}/api/salesforce/limits/get-limit`,
    },
    limits: `${env.API_CREDIT_MANAGER}/api/admin/limits`,
    pendingRequests: `${env.API_CREDIT_MANAGER}/api/admin/limit-requests/get-all-pending`,
    sendApproval: `${env.API_CREDIT_MANAGER}/api/admin/limits/approval`,
    ODataLimits: `${env.API_CREDIT_MANAGER}/odata/LimitsAdmin`,
    transactionsByLimit: (transactionId: number | string) =>
      `${env.API_CREDIT_MANAGER}/api/admin/transaction/${transactionId}/get-all-by-limit`,
    createTransaction: `${env.API_CREDIT_MANAGER}/api/admin/transaction/create`,
    reverseTransaction: `${env.API_CREDIT_MANAGER}/api/admin/transaction/reverse`,
    createGuarantor: `${env.API_CREDIT_MANAGER}/api/guarantors/create-credit-line-guarantor`,
    admin: {
      limitRequests: {
        getLimitRequest: (limitRequestId: number | string) =>
          `${env.API_CREDIT_MANAGER}/api/admin/limit-requests/get-limit-request/${limitRequestId}`,
        updateStatus: `${env.API_CREDIT_MANAGER}/api/admin/limit-requests/update-status`,
        updateExpirationDate: `${env.API_CREDIT_MANAGER}/api/admin/limit-requests/update-expiration-date`,
        removeGuarantor: `${env.API_CREDIT_MANAGER}/api/admin/limit-requests/remove-guarantor`,
        updateQuotesAndIndexCode: `${env.API_CREDIT_MANAGER}/api/admin/limit-requests/update-quotes-indexcode`,
      },
      invoices: {
        getInvoicesByLimitRequestId: (limitRequestId: number | string) =>
          `${env.API_CREDIT_MANAGER}/api/admin/invoice/${limitRequestId}`,
        updateInvoice: `${env.API_CREDIT_MANAGER}/api/admin/invoice`,
      },
      additionalData: {
        agro: (limitRequestId: string) =>
          `${env.API_CREDIT_MANAGER}/api/admin/additional-data/agro/${limitRequestId}`,
      },
      collateral: {
        getCollateral: (collateralId: string) =>
          `${env.API_CREDIT_MANAGER}/api/admin/collateral/${collateralId}`,
        updateCollateral: `${env.API_CREDIT_MANAGER}/api/admin/collateral/update`,
      },
      collateralDocument: {
        create: `${env.API_CREDIT_MANAGER}/api/admin/limits/create-collateral-document`,
      },
      property: {
        update: `${env.API_CREDIT_MANAGER}/api/admin/property/update`,
      },
    },
    additionalFees: {
      getAdditionalFeeByLimitRequestId: (limitRequestId: number | string) =>
        `${env.API_CREDIT_MANAGER}/api/admin/additional-fee/get-by-limit-request-id/${limitRequestId}`,
      getAdditionalFeeById: (additionalFeeId: number | string) =>
        `${env.API_CREDIT_MANAGER}/api/admin/additional-fee/get-by-id/${additionalFeeId}`,
      delete: `${env.API_CREDIT_MANAGER}/api/admin/additional-fee/delete`,
      create: `${env.API_CREDIT_MANAGER}/api/admin/additional-fee/create`,
      update: `${env.API_CREDIT_MANAGER}/api/admin/additional-fee/update`,
    },
    disbursementInstructions: {
      getDisbursementInstructionsByLimitRequestId: (
        limitRequestId: number | string,
      ) =>
        `${env.API_CREDIT_MANAGER}/api/admin/disbursement-instructions/get-by-limit-request-id/${limitRequestId}`,
      getDisbursementInstructionsById: (
        disbursementInstructionsId: number | string,
      ) =>
        `${env.API_CREDIT_MANAGER}/api/admin/disbursement-instructions/get-by-id/${disbursementInstructionsId}`,
    },
    contractTemplate: {
      getContractTemplatesByLimitRequestId: (limitRequestId: number | string) =>
        `${env.API_CREDIT_MANAGER}/api/admin/contract-template/get-by-limit-request-id/${limitRequestId}`,
      create: `${env.API_CREDIT_MANAGER}/api/admin/contract-template/create`,
      update: `${env.API_CREDIT_MANAGER}/api/admin/contract-template/update`,
      delete: (contractTemplateId: number | string) =>
        `${env.API_CREDIT_MANAGER}/api/admin/contract-template/delete/${contractTemplateId}`,
    },
    foreignCurrencyIndex: {
      getForeignCurrencyIndexByLimitRequestId: (
        limitRequestId: number | string,
      ) =>
        `${env.API_CREDIT_MANAGER}/api/admin/foreign-currency-index/get-by-limit-request-id/${limitRequestId}`,
      create: `${env.API_CREDIT_MANAGER}/api/admin/foreign-currency-index/create`,
      update: `${env.API_CREDIT_MANAGER}/api/admin/foreign-currency-index/update`,
    },
    file: {
      download: `${env.API_CREDIT_MANAGER}/api/FileAdmin/download`,
      upload: `${env.API_CREDIT_MANAGER}/api/FileAdmin/upload`,
    },
  },
  hubfx: {
    salesforce: {
      getQuote: `${env.API_FX}/api/salesforce/quote`,
      getQuoteDetails: (quoteId: string) =>
        `${env.API_FX}/api/salesforce/quote/${quoteId}`,
      getOrder: `${env.API_FX}/api/salesforce/order`,
      getOrderDetails: (orderId: string) =>
        `${env.API_FX}/api/salesforce/order/${orderId}`,
      getPreTicket: `${env.API_FX}/api/salesforce/pre-ticket`,
      getPreTicketDetails: (preTicketId: string) =>
        `${env.API_FX}/api/salesforce/pre-ticket/${preTicketId}`,
      getLimit: `${env.API_FX}/api/salesforce/limit`,
      getLimitDetails: (limitId: string) =>
        `${env.API_FX}/api/salesforce/limit/${limitId}`,
    },
    getQuoteDetails: (quoteId: string) =>
      `${env.API_FX}/api/admin/quote/${quoteId}`,
    cancelOperation: (quoteId: string) =>
      `${env.API_FX}/api/admin/quote/${quoteId}/cancel`,
    getPreTicketDetails: (preTicketId: string) =>
      `${env.API_FX}/api/admin/pre-ticket/${preTicketId}`,
    updatePreTicket: `${env.API_FX}/api/admin/pre-ticket/status`,
    getOrderDetails: (orderId: string) =>
      `${env.API_FX}/api/admin/order/${orderId}`,
    getLimitDetails: (limitId: string) =>
      `${env.API_FX}/api/admin/limit/${limitId}`,
    getCounterpartyDetails: (counterpartyId: string) =>
      `${env.API_FX}/api/admin/counterparty/${counterpartyId}`,
    setOpenOperationLimits: `${env.API_FX}/api/admin/counterparty/open-operations-limit`,
    enablePreTicket: `${env.API_FX}/api/admin/counterparty/enable-pre-ticket`,
    downloadContract: (contractId: string) =>
      `${env.API_FX}/api/file/contract?contractId=${contractId}`,
    downloadReceipt: (contractId: string) =>
      `${env.API_FX}/api/file/receipt?contractId=${contractId}`,
    downloadFile: (key: string) => `${env.API_FX}/api/file/download?key=${key}`,
    getUpsertCommonDomainAdmin: `${env.API_FX}/api/admin/common`,
    upsertNatureRule: `${env.API_FX}/api/admin/common/nature-rules`,
    getNatureRules: `${env.API_FX}/api/admin/common/nature-rules`,
    getNatures: `${env.API_FX}/api/admin/common/natures`,
    upsertNatures: `${env.API_FX}/api/admin/common/natures`,
    deleteNature: (natureCode: string) =>
      `${env.API_FX}/api/admin/common/natures/${natureCode}`,
    odata: {
      getOperationsFx: `${env.API_FX}/odata/AdminOperationsOData`,
      getOrdersFx: `${env.API_FX}/odata/AdminOrdersOData`,
      getPreticketsFx: `${env.API_FX}/odata/AdminPreTicketsOData`,
      getCounterpartiesFx: `${env.API_FX}/odata/AdminCounterpartiesOData`,
      getLimitsFx: `${env.API_FX}/odata/AdminLimitsOData`,
    },
  },
  customerService: {
    operator: `${env.API_CUSTOMER}/api/v1/operator`,
  },
  raven: {
    adminOdata: `${env.API_RAVEN}/api/notifications/admin_odata`,
    template: `${env.API_RAVEN}/api/notification_config/odata`,
    templateDetails: (templateConfigName: string) =>
      `${env.API_RAVEN}/api/notification_config/${templateConfigName}`,
    templateRegister: `${env.API_RAVEN}/api/notification_config`,
    removeTemplate: (templateConfigName: string) =>
      `${env.API_RAVEN}/api/notification_config/${templateConfigName}`,
  },
  gatekeeper: {
    salesforce: {
      invalidate: `${env.GATEKEEPER_URL}/invalidate/salesforce/by_cpf`,
    },
    impersonate: `${env.GATEKEEPER_URL}/impersonate`,
    sessionToken: `${env.GATEKEEPER_URL}/session-token`,
    users: {
      self: `${env.GATEKEEPER_URL}/users/self`,
      getAll: `${env.GATEKEEPER_URL}/users/all`,
      getUser: (email: string) => `${env.GATEKEEPER_URL}/users/${email}`,
      getUserByEmail: `${env.GATEKEEPER_URL}/users/get_user_by_email`,
      updateUser: (email: string) => `${env.GATEKEEPER_URL}/users/${email}`,
      createUser: `${env.GATEKEEPER_URL}/users`,
      getCompanyUsers: (cnpj: string) =>
        `${env.GATEKEEPER_URL}/users/companies/${cnpj}`,
      getRelationProfiles: (email: string) =>
        `${env.GATEKEEPER_URL}/relationships/user/${email}`,
      deactivateUser: (email: string) =>
        `${env.GATEKEEPER_URL}/users/${email}/deactivate`,
      activateUser: (email: string) =>
        `${env.GATEKEEPER_URL}/users/${email}/activate`,
      userFromCpf: `${env.GATEKEEPER_URL}/users/get_from_cpf`,
    },
    systems: {
      getAll: `${env.GATEKEEPER_URL}/systems/all`,
      createSystem: `${env.GATEKEEPER_URL}/systems`,
      updateSystem: (name: string) => `${env.GATEKEEPER_URL}/systems/${name}`,
      getSystem: (name: string) => `${env.GATEKEEPER_URL}/systems/${name}`,
    },
    profiles: {
      getAll: `${env.GATEKEEPER_URL}/profiles/all`,
      createProfile: `${env.GATEKEEPER_URL}/profiles`,
      getProfile: (name: string) => `${env.GATEKEEPER_URL}/profiles/${name}`,
      updateProfile: (name: string) => `${env.GATEKEEPER_URL}/profiles/${name}`,
      updateSubProfiles: `${env.GATEKEEPER_URL}/profiles/update_sub_profiles`,
      getDetails: `${env.GATEKEEPER_URL}/profiles/get_details`,
      deleteDetail: `${env.GATEKEEPER_URL}/profiles/details/delete`,
      insertDetail: `${env.GATEKEEPER_URL}/profiles/details/create`,
      updateDetail: `${env.GATEKEEPER_URL}/profiles/details/update`,
      getActs: `${env.GATEKEEPER_URL}/profiles/get_acts`,
      insertAct: `${env.GATEKEEPER_URL}/profiles/add_acts`,
      deleteAct: `${env.GATEKEEPER_URL}/profiles/remove_acts`,
      removeRoles: `${env.GATEKEEPER_URL}/profiles/remove_roles`,
      addRoles: `${env.GATEKEEPER_URL}/profiles/add_roles`,
    },
    acts: {
      getActs: `${env.GATEKEEPER_URL}/get_acts`,
    },
    roles: {
      getAll: `${env.GATEKEEPER_URL}/roles`,
      createRole: `${env.GATEKEEPER_URL}/roles`,
      updateRole: (name: string) => `${env.GATEKEEPER_URL}/profiles/${name}`,
      getRole: (name: string) => `${env.GATEKEEPER_URL}/roles/${name}`,
      updateRoleById: `${env.GATEKEEPER_URL}/roles/update_by_id`,
    },
    invalidation: {},
    companies: {
      getAll: `${env.GATEKEEPER_URL}/companies`,
      createCompany: `${env.GATEKEEPER_URL}/companies`,
      updateCompany: (cnpj: string) =>
        `${env.GATEKEEPER_URL}/companies/${cnpj}`,
      getCompany: (cnpj: string) => `${env.GATEKEEPER_URL}/companies/${cnpj}`,
      addCompanyUsers: (cnpj: string) =>
        `${env.GATEKEEPER_URL}/companies/${cnpj}/users/profiles/add`,
      removeCompanyUsers: (cnpj: string) =>
        `${env.GATEKEEPER_URL}/companies/${cnpj}/users/profiles/remove`,
      getRelationProfiles: (cnpj: string) =>
        `${env.GATEKEEPER_URL}/relationships/company/${cnpj}`,
      removeRelationship: (cpf: string, cnpj: string) =>
        `${env.GATEKEEPER_URL}/relationships/${cpf}/${cnpj}`,
    },
    companiesMonitor: {
      odata: `${env.GATEKEEPER_URL}/odata/companies`,
    },
    usersMonitor: {
      odata: `${env.GATEKEEPER_URL}/odata/users`,
    },
    customerService: {
      generateToken: `${env.GATEKEEPER_URL}/internal_customer_service/generate`,
    },
    allowOperation: {
      getSignatureCombinations: `${env.GATEKEEPER_URL}/allow_operation/get_signature_combinations`,
    },
  },
  gateholder: {
    allowList: {
      index: `${env.GATEKEEPERFRAUD_URL}/api/allow_list/no_biometry`,
      create: `${env.GATEKEEPERFRAUD_URL}/api/allow_list/create`,
      delete: (taxId?: string | undefined) =>
        `${env.GATEKEEPERFRAUD_URL}/api/allow_list/no_biometry/${taxId}`,
    },
    odata: `${env.GATEHOLDER_API}/api/fraud_sys/grid`,
    details: (requestId: string) =>
      `${env.GATEHOLDER_API}/api/fraud_sys/analysis_details/${requestId}`,
    sendAnalysis: (requestId: string) =>
      `${env.GATEHOLDER_API}/api/fraud_sys/manual_analysis/${requestId}`,
    downloadFile: (fileKey: string) =>
      `${env.GATEHOLDER_API}/api/files/download/${fileKey}`,
  },
  recebiveiscartao: {
    dataGrids: {
      agendas: `${env.RECEBIVEIS_CARTAO_URL}/api/filter-table/agendas-without-operated-value`,
      agendasFullData: `${env.RECEBIVEIS_CARTAO_URL}/api/filter-table/agendas`,
      errors: `${env.RECEBIVEIS_CARTAO_URL}/api/filter-table/errors`,
      jobs: `${env.RECEBIVEIS_CARTAO_URL}/api/filter-table/jobs`,
      trades: `${env.RECEBIVEIS_CARTAO_URL}/api/filter-table/trades`,
      companyMappings: `${env.RECEBIVEIS_CARTAO_URL}/api/filter-table/agendas-company-map`,
      registroSolicitations: `${env.RECEBIVEIS_CARTAO_URL}/api/filter-table/registros-solicitations`,
      registros: `${env.RECEBIVEIS_CARTAO_URL}/api/filter-table/registros`,
    },
    registros: {
      download: (queryString: string) =>
        `${env.RECEBIVEIS_CARTAO_URL}/api/registros/download?${queryString}`,
      entubaInclusao: `${env.RECEBIVEIS_CARTAO_URL}/api/registros/entuba-inclusao`,
      entubaAlteracao: `${env.RECEBIVEIS_CARTAO_URL}/api/registros/entuba-alteracao`,
      entubaCancelamento: `${env.RECEBIVEIS_CARTAO_URL}/api/registros/entuba-cancelamento`,
    },
    agendas: {
      uploadFile: (type: "cip" | "cerc") =>
        `${env.RECEBIVEIS_CARTAO_URL}/api/agendas-${type}`,
      importDownload: (queryString: string) =>
        `${env.RECEBIVEIS_CARTAO_URL}/api/agendas/import?${queryString}`,
      alerts: `${env.RECEBIVEIS_CARTAO_URL}/api/agendas/alerts`,
    },
    companies: {
      optin: `${env.RECEBIVEIS_CARTAO_URL}/api/cip/request-agendas`,
      all: `${env.RECEBIVEIS_CARTAO_URL}/api/companies`,
      bandeiras: `${env.RECEBIVEIS_CARTAO_URL}/api/bandeiras/all`,
      notCredenciadoras: (params = "") =>
        `${env.RECEBIVEIS_CARTAO_URL}/api/companies/not-credenciadoras${params}`,
      credenciadoras: `${env.RECEBIVEIS_CARTAO_URL}/api/companies/credenciadoras`,
      map: {
        index: (tax_id: string | null = null) =>
          `${env.RECEBIVEIS_CARTAO_URL}/api/companies/map/${tax_id || ""}`,
        group: (tax_id: string | null = null) =>
          `${env.RECEBIVEIS_CARTAO_URL}/api/companies/map/group/${
            tax_id || ""
          }`,
      },
    },
    jobs: {
      crontab: `${env.RECEBIVEIS_CARTAO_URL}/api/jobs/crontab`,
      runWorker: `${env.RECEBIVEIS_CARTAO_URL}/api/jobs/run-worker`,
      runJob: `${env.RECEBIVEIS_CARTAO_URL}/api/jobs/run-job`,
      stopJob: `${env.RECEBIVEIS_CARTAO_URL}/api/jobs/stop-job`,
    },
    convenios: {
      all: `${env.RECEBIVEIS_CARTAO_URL}/api/convenios/grid`,
      create: `${env.RECEBIVEIS_CARTAO_URL}/api/convenios`,
      update: (id: number | string) =>
        `${env.RECEBIVEIS_CARTAO_URL}/api/convenios/${id}`,
    },
    whitelists: {
      all: `${env.RECEBIVEIS_CARTAO_URL}/api/whitelists/grid`,
      create: `${env.RECEBIVEIS_CARTAO_URL}/api/whitelists`,
      update: (id: number | string) =>
        `${env.RECEBIVEIS_CARTAO_URL}/api/whitelists/${id}`,
    },
    deflatores: {
      index: `${env.RECEBIVEIS_CARTAO_URL}/api/v2/deflatores`,
      create: `${env.RECEBIVEIS_CARTAO_URL}/api/deflatores`,
      update: (id: number | string) =>
        `${env.RECEBIVEIS_CARTAO_URL}/api/deflatores/${id}`,
      default: `${env.RECEBIVEIS_CARTAO_URL}/api/deflatores/default`,
    },
    trades: {
      children: (id: number | string) =>
        `${env.RECEBIVEIS_CARTAO_URL}/api/trades/${id}/children`,
    },
    emailAutomatico: {
      index: `${env.RECEBIVEIS_CARTAO_URL}/api/email_configs`,
      create: `${env.RECEBIVEIS_CARTAO_URL}/api/email_configs`,
      update: (id: number | string) =>
        `${env.RECEBIVEIS_CARTAO_URL}/api/email_configs/${id}`,
      delete: (id: number | string) =>
        `${env.RECEBIVEIS_CARTAO_URL}/api/email_configs/${id}`,
    },
    monitor: {
      recebiveisCartaoResumoAgendas: `${env.RECEBIVEIS_CARTAO_URL}/api/monitor/resumo-diario`,
      recebiveisCartaoHealthCheck: `${env.RECEBIVEIS_CARTAO_URL}/api/monitor/health-check`,
      recebiveisCartaoTradeHealth: `${env.RECEBIVEIS_CARTAO_URL}/api/monitor/trades`,
    },
  },
  apiParceiros: {
    partnersFeed: `${env.API_PARCEIROS}/odata/feedOdata`,
    adminOptionSign: `${env.API_PARCEIROS}/admin/optin/sign`,
  },
  adminBankinghub: {
    cnab: {
      cnabTracing: `${env.URL_BANKINGADMIN}/Cnab/cnabTracing`,
    },
    card: {
      getOfferList: (page: number, itemsPerPage: number) =>
        `${env.URL_BANKINGADMIN}/Card/offers?itemsPerPage=${itemsPerPage}&page=${page}`,
      getOfferSummary: `${env.URL_BANKINGADMIN}/Card/offers/summary`,
      getAccountSummary: `${env.URL_BANKINGADMIN}/Card/accounts/summary`,
      getCardSummary: `${env.URL_BANKINGADMIN}/Card/cards/summary`,
      getContestationSummary: `${env.URL_BANKINGADMIN}/Card/contestations/summary`,
      resendOfferAvailableNotification: `${env.URL_BANKINGADMIN}/Card/offers/notifications`,
    },
    getContactsByCompany: (identification: string) =>
      `${env.URL_BANKINGADMIN}/Contact/${identification}`,
    createAndUpdateAccount: (identification?: string) =>
      `${env.URL_BANKINGADMIN}/Contact/${identification ? identification : ""}`,
    deleteAccount: (identification: string, contactId: string) =>
      `${env.URL_BANKINGADMIN}/Contact/${identification}/${contactId}`,
    getBalance: (
      bankCode: number | string,
      accountNumber: number | string,
      agency: number | string,
    ) =>
      `${env.URL_BANKINGADMIN}/Balance/balance/${bankCode}/${accountNumber}/${agency}`,
    getBalanceYield: (identification: string) =>
      `${env.URL_BANKINGADMIN}/BalanceYield/balanceyield/${identification}`,
    getOverdraft: (identification: string) =>
      `${env.URL_BANKINGADMIN}/Overdraft/limit/${identification}`,
    bankingManager: {
      companies: `${env.URL_BANKINGADMIN}/BankingManager/companies`,
      company: `${env.URL_BANKINGADMIN}/BankingManager/company`,
    },
    bankslib: {
      getBillsByCnpj: `${env.URL_BANKINGADMIN}/Bankslip/issue`,
      cancelBillsByCnpj: `${env.URL_BANKINGADMIN}/Bankslip/issue/CancelInBatch`,
      validateBankslipCIP: (bankslipCode: string) =>
        `${env.URL_BANKINGADMIN}/Bankslip/issue/searchBankslipCIP/${bankslipCode}`,
      validateBankslipBillBo: (bankslipCode: string) =>
        `${env.URL_BANKINGADMIN}/Bankslip/issue/searchBankslipBillBo/${bankslipCode}`,
      changeBankslipStatus: (externalId: string) =>
        `${env.URL_BANKINGADMIN}/Bankslip/issue/update/${externalId}`,
    },
    account: {
      lessAccounts: (identification: string) =>
        `${env.URL_BANKINGADMIN}/Account/accounts?taxId=${identification}`,
      insertAccount: (id: string) =>
        `${env.URL_BANKINGADMIN}/Account/accounts/${id}`,
      removeAccount: (id: string) =>
        `${env.URL_BANKINGADMIN}/Account/accounts/${id}`,
    },
  },
  hubonboarding: {
    createUserAdmin: `${env.API_ONBOARDING}/api/admin/User/create-administrator`,
    sendWelcomeEmail: `${env.API_ONBOARDING}/api/admin/onboard/user/welcome-email`,
    getLegalEntityDetails: `${env.API_ONBOARDING}/api/SearchLegalEntity/details`,
    onboardPreRegister: `${env.API_ONBOARDING}/api/onboard/pre-register`,
    fileDownload: (key: string) =>
      `${env.API_ONBOARDING}/api/file/${key}/download`,
    admin: {
      contracts: {
        reprocess: `${env.API_ONBOARDING}/api/admin/contracts/reprocess`,
        batchCreate: `${env.API_ONBOARDING}/api/admin/contracts/batch/create`,
      },
      onboard: {
        get: `${env.API_ONBOARDING}/api/admin/Onboard/get-onboard-with-type`,
        updateStatus: `${env.API_ONBOARDING}/api/admin/Onboard/status`,
      },
      energy: `${env.API_ONBOARDING}/api/admin/energy`,
      energyUpdate: `${env.API_ONBOARDING}/api/admin/energy/update`,
      company: `${env.API_ONBOARDING}/api/admin/company`,
      deskOnboarding: `${env.API_ONBOARDING}/api/admin/desk/onboarding`,
      deskOnboardingRegisterLegalEntity: `${env.API_ONBOARDING}/api/admin/desk/onboarding/register-legal-entity`,
      getSemaphore: `${env.API_ONBOARDING}/api/admin/get-semaphore`,
      getLegalEntityByCpf: `${env.API_ONBOARDING}/api/admin/person`,
      getUserEmailUpdates: `${env.API_ONBOARDING}/api/admin/user/get-email-updates`,
      updateUserEmail: `${env.API_ONBOARDING}/api/admin/user`,
      updateOnboardingRiskStatus: `${env.API_ONBOARDING}/api/admin/OnboardingAnalysis`,
      getLastRiskAnalysis: `${env.API_ONBOARDING}/api/admin/OnboardingAnalysis/last-analysis`,
      deleteRelationship: `${env.API_ONBOARDING}/api/admin/user/related-entity`,
    },
    customerInvite: {
      openAccount: `${env.API_ONBOARDING}/api/CustomerInvite/open-account`,
    },
    searchLegalEntity: `${env.API_ONBOARDING}/api/SearchLegalEntity`,
    firstAccessSemaphore: `${env.API_ONBOARDING}/api/onboarding-levels/get-semaphore-first-access`,
    createCompany: `${env.API_ONBOARDING}/api/admin/onboarding-levels/register-legal-entity`,
    accountPfApproval: `${env.API_ONBOARDING}/api/contingency/account-pf/approval`,
    verifyCompany: `${env.API_ONBOARDING}/api/admin/company`,
    resendOnboardingLess: `${env.API_ONBOARDING}/api/contingency/onboarding/resend-to-less`,
    reopenOnboarding: `${env.API_ONBOARDING}/api/contingency/onboarding/reopen`,
    contingencyApprove: `${env.API_ONBOARDING}​/api/contingency/onboarding/approve`,
    documentsDevolutive: `${env.API_ONBOARDING}​/api/contingency/documents/occurrences`,
    generateTerm: `${env.API_ONBOARDING}​/api/customer-service/contracts/create-account-opening`,
    loadAccountOpening: `${env.API_ONBOARDING}​/api/customer-service/invites/load-account-opening`,
    createAccountOpening: `${env.API_ONBOARDING}/api/customer-service/invites/create-account-opening`,
    companyDocuments: `${env.API_ONBOARDING}​/api/contingency/documents/load`,
    documents: {
      download: (key: string) =>
        `${env.API_ONBOARDING}/api/admin/onboarding-levels/download/${key}`,
      uploadFile: (taxId: string, documentType: string) =>
        `${env.API_ONBOARDING}/api/GuarantorLevels/upload?guarantorTaxId=${taxId}&documentTypeLevels=${documentType}&filePageType=COMPLETO`,
    },
    getUserRecomendedProfiles: `${env.API_ONBOARDING}/api/admin/user/get-profiles`,
    finishAccess: `${env.API_ONBOARDING}/api/contingency/workflow/terminate-relationship`,
    companyGroups: {
      getGroup: (identification: string) =>
        `${env.API_ONBOARDING}/api/admin/companyGroup/get-companies-from-group/${identification}`,
      createGroup: `${env.API_ONBOARDING}/api/admin/companyGroup/register-group-and-associations`,
      verifyCompanies: `${env.API_ONBOARDING}/api/admin/companyGroup/register-group-and-associations/verify`,
    },
    findCep: `${env.API_ONBOARDING}/api/address/by-zip-code`,
    legalEntitySearch: `${env.API_ONBOARDING}/api/SearchLegalEntity`,
    administrativeUnit: {
      getCities: (stateCode: string) =>
        `${env.API_ONBOARDING}/api/AdministrativeUnit/cities?stateCode=${stateCode}&countryCode=BR`,
      getCityByExternalId: (externalId: string) =>
        `${env.API_ONBOARDING}/api/AdministrativeUnit/city?externalId=${externalId}`,
    },
  },
  fidc: {
    createFund: `${env.FIDC_URL}/api/fund`,
    getAssets: (batchId: string) =>
      `${env.FIDC_URL}/api/dashboard/assets?batchId=${batchId}`,
    getBatches: (fundTaxId: string, from: string, to: string) =>
      `${env.FIDC_URL}/api/dashboard/batches?fundTaxId=${fundTaxId}&from=${from}&to=${to}`,
    getSigners: (productName: string) =>
      `${env.FIDC_URL}/api/dashboard/signers/${productName}`,
    getNotifications: (productId: string) =>
      `${env.FIDC_URL}/api/notifications/${productId}`,
    getAmbevNotifications: `${env.FIDC_URL}/api/ambev/notifications/`,
    manageAmbevAssets: `${env.FIDC_URL}/api/manage/asset/ambev`,
    getContract: (batchId: string) =>
      `${env.FIDC_URL}/api/dashboard/contract/${batchId}`,
    getProcessedAssets: `${env.FIDC_URL}/api/validator/processedAssets`,
    getRules: `${env.FIDC_URL}/api/validator/rules`,
    getFunds: `${env.FIDC_URL}/api/fund/admin`,
    getInteractionsByFundTaxId: (fundTaxId: string) =>
      `${env.FIDC_URL}/api/snake/interaction/tracking?fundTaxId=${fundTaxId}`,
    resendInteraction: `${env.FIDC_URL}/api/snake/interaction/tracking`,
    createFinancial: `${env.FIDC_URL}/api/snake/financial`,
    getCompanyById: (id: string) => `${env.FIDC_URL}/api/snake/company/${id}`,
    updateFinancial: (financialId: string) =>
      `${env.FIDC_URL}/api/snake/financial/${financialId}`,
    deleteFinancial: (financialId: string) =>
      `${env.FIDC_URL}/api/snake/financial/${financialId}`,
    odata: {
      getInteractionsOData: (fundTaxId: string = "") =>
        `${env.FIDC_URL}/api/snake/odata/InteractionsOData?fundTaxId=${fundTaxId}`,
    },
    renewContract: (batchId: string) =>
      `${env.FIDC_URL}/api/renew/contract/${batchId}`,
    sendFundNotification: (fundTaxId: string) =>
      `${env.FIDC_URL}/api/send/notification/fund?cnpj=${fundTaxId}`,
    overallStatus: (params: any) =>
      `${env.FIDC_URL}/api/dashboard/overallStatus?${params}`,
    getCharges: (fundCge: string, clientCge: string) =>
      `${env.FIDC_URL}/api/dashboard/charges?fundCge=${fundCge}&clientCge=${clientCge}`,
    getProducts: `${env.FIDC_URL}/api/dashboard/products`,
    getConsolidatedContracts: (productId: string, params: string) =>
      `${env.FIDC_URL}/api/ContractGenerator/${productId}?${params}`,
    consolidatedContract: `${env.FIDC_URL}/api/ContractGenerator/`,
    generateAllContracts: (fundTaxId: string) =>
      `${env.FIDC_URL}/api/ambev/generate/all-contracts?cnpj=${fundTaxId}`,
    getBankslip: (slipId: string) =>
      `${env.FIDC_URL}/api/dashboard/bankSlip/${slipId}`,
    productAdmin: `${env.FIDC_URL}/api/product/admin`,
    guarantee: (taxId: string) => `${env.FIDC_URL}/api/guarantee/${taxId}`,
    batchStepStartSignatureProcess: `${env.FIDC_URL}/api/BatchStep/startSignatureProcess`,
    batch: {
      byBatchId: (batchId: string) => `${env.FIDC_URL}/api/batch/${batchId}`,
      duedate: `${env.FIDC_URL}/api/batch/duedate`,
      admin: `${env.FIDC_URL}/api/batch/admin`,
      terms: (id: string) => `${env.FIDC_URL}/api/batch/${id}/terms`,
      submitAdminById: (id: string) =>
        `${env.FIDC_URL}/api/batch/${id}/submit/admin`,
      submitAdmin: `${env.FIDC_URL}/api/batch/submit/admin`,
      guaranteeXLSXV2: (id: string) =>
        `${env.FIDC_URL}/api/batch/${id}/guaranteeXLSXV2`,
      getByCnpj: (taxId: string) =>
        `${env.FIDC_URL}/api/batch/getByCnpj?cnpj=${taxId}`,
    },
  },
  clerk: {
    salesforce: {
      getCompanyDetails: `${env.CLERK_API}/api/salesforce/get_company_details`,
      getSalesforceUser: `${env.CLERK_API}/api/salesforce/users`,
      getCompanyUsers: `${env.CLERK_API}/api/salesforce/get_company_users`,
    },
    users: {
      self: `${env.CLERK_API}/api/users/self`,
      getUser: `${env.CLERK_API}/api/users/get_by_email`,
      getUserByCpf: `${env.CLERK_API}/api/users/get_by_tax_id`,
      updateUser: `${env.CLERK_API}/api/users/update_by_email`,
      getDetailsByEmail: `${env.CLERK_API}/api/users/get_by_email`,
      updateUserEmail: `${env.CLERK_API}/api/users/update_email`,
      deleteUser: (cpf: string) => `${env.CLERK_API}/api/entities/${cpf}`,
      getByTaxId: `${env.CLERK_API}/api/users/get_by_tax_id`,
    },
    relations: {
      getAll: (cnpj: string) =>
        `${env.CLERK_API}/api/entities_relationships/from_user/${cnpj}`,
    },
    companies: {
      getAll: `${env.CLERK_API}/api/odata/admin/companies`,
      deleteUser: (identification: string) =>
        `${env.CLERK_API}/api/entities/${identification}`,
      getCompanyDetails: `${env.CLERK_API}/api/companies/get_by_tax_id`,
      getCompany: `${env.CLERK_API}/api/companies/get_by_tax_id`,
      updateCompany: `${env.CLERK_API}/api/companies/update_by_tax_id`,
      strategy: (identification: string, strategyUniqueId: string) =>
        `${env.CLERK_API}/api/strategies/${identification}/${strategyUniqueId}`,
      getBranches: (entityId: number | string) =>
        `${env.CLERK_API}/api/company_relationships/${entityId}/Matriz`,
    },
    customerPlans: {
      getFromCompany: `${env.CLERK_API}/api/customer_plans/get_from_company`,
    },
    groups: {
      getAll: `${env.CLERK_API}/api/groups`,
      updateEntities: `${env.CLERK_API}/api/groups/association`,
      getRelations: (id: string) =>
        `${env.CLERK_API}/api/groups/${id}/GrupoEconomico`,
      getGroup: (id: string) => `${env.CLERK_API}/api/groups/${id}`,
      deleteGroup: (id: string) => `${env.CLERK_API}/api/groups/${id}`,
    },
    systems: {
      createSystem: (name: string) => `${env.CLERK_API}/api/systems/${name}`,
      getAll: `${env.CLERK_API}/api/systems`,
      getStrategies: (id: string) =>
        `${env.CLERK_API}/api/strategies/entity/${id}`,
      updateStrategies: `${env.CLERK_API}/api/strategies`,
    },
    strategies: {
      getAll: `${env.CLERK_API}/api/strategies`,
      getByCompany: (identification: string) =>
        `${env.CLERK_API}/api/strategies/${identification}`,
    },
    bankAccounts: {
      getByTaxId: (taxId: string) =>
        `${env.CLERK_API}/api/bank_accounts/?company=${taxId}`,
      create: `${env.CLERK_API}/api/bank_accounts`,
      update: (id: number | string) =>
        `${env.CLERK_API}/api/bank_accounts/${id}`,
      delete: (id: number | string) =>
        `${env.CLERK_API}/api/bank_accounts/${id}`,
    },
    relationship: {
      update: (userIdentification: string, relatedIdentification: string) =>
        `${env.CLERK_API}/api/entities_relationships/${userIdentification}/${relatedIdentification}`,
      delete: (userIdentification: string, relatedIdentification: string) =>
        `${env.CLERK_API}/api/entities_relationships/${userIdentification}/${relatedIdentification}`,
    },
    adminOdata: {
      desksCompaniesSelf: `${env.CLERK_API}/api/admin/odata/desks/companies/self`,
      desksCompaniesAccountPFSelf: `${env.CLERK_API}/api/admin/odata/desks/companies/account_pf/self`,
      desksCompaniesAccountPJSelf: `${env.CLERK_API}/api/admin/odata/desks/companies/account_pj/self`,
      desksCompaniesAssignorSelf: `${env.CLERK_API}/api/admin/odata/desks/companies/assignor/self`,
      desksCompaniesEnergySelf: `${env.CLERK_API}/api/admin/odata/desks/companies/energy/self`,
      desksCompaniesNoOnboardingSelf: `${env.CLERK_API}/api/admin/odata/desks/companies/no_onboarding/self`,
      desksCompaniesPayerSelf: `${env.CLERK_API}/api/admin/odata/desks/companies/payer/self`,
      desksUsersSelf: `${env.CLERK_API}/api/admin/odata/desks/users/self`,
      allUsers: `${env.CLERK_API}/api/admin/odata/desks/all_users/self`,
      B2BUsers: `${env.CLERK_API}/api/admin/odata/desks/B2B_users/self`,
    },
    desks: {
      getAll: `${env.CLERK_API}/api/desks`,
      get: `${env.CLERK_API}/api/desks/`,
      getEntityDesks: `${env.CLERK_API}/api/desks/get_entity_desks`,
      upsertEntity: `${env.CLERK_API}/api/desks/upsert_entity`,
    },
    searchEngineEntity: `${env.CLERK_API}/api/search_engine/entity`,
    powerOfAttorney: {
      isEloaAvailable: `${env.CLERK_API}/api/power_of_attorney/is_eloa_available`,
    },
    reports: {
      getUsersAmount: `${env.CLERK_API}/api/reports/total_number_of_users`,
      getAccountsAmount: `${env.CLERK_API}/api/reports/total_number_of_clients_with_btg_business_account`,
      getClientsAmount: `${env.CLERK_API}/api/reports/total_number_of_clients`,
      getDailyClientsWithPendingOnboardingAmount: `${env.CLERK_API}/api/reports/daily_number_of_clients_with_pending_onboarding`,
      getDailyClientsWithFinishedOnboardingAmount: `${env.CLERK_API}/api/reports/daily_number_of_clients_with_finished_onboarding`,
      getDailyClientsWithReprovedOnboardingAmount: `${env.CLERK_API}/api/reports/daily_number_of_clients_with_reproved_onboarding`,
      getDailyAccountsByStatus: `${env.CLERK_API}/api/reports/daily_number_of_btg_accounts_by_status`,
      getElegibilityAmount: `${env.CLERK_API}/api/reports/total_number_of_clients_by_eligibility_status`,
      getDailyElegibilityAmount: `${env.CLERK_API}/api/reports/daily_number_of_clients_by_eligibility_status`,
      getProducts: `${env.CLERK_API}/api/products`,
      getDailyProducts: `${env.CLERK_API}/api/reports/daily_number_of_clients_by_products`,
      getDailyPendingUsers: `${env.CLERK_API}/api/reports/daily_number_of_users_creating_account`,
      getDailyFinishedUsers: `${env.CLERK_API}/api/reports/daily_number_of_users_with_active_account`,
      getDailyNotStartedBiometryUsers: `${env.CLERK_API}/api/reports/daily_number_of_users_with_not_started_biometry`,
      getDailyUnfinishedBiometryUsers: `${env.CLERK_API}/api/reports/daily_number_of_users_with_pending_biometry`,
      getDailyCompletedBiometryUsers: `${env.CLERK_API}/api/reports/daily_number_of_users_with_completed_biometry`,
    },
  },
  energy: {
    getConsumers: `${env.ENERGY_URL}/api/company/consumers`,
    getCompanies: `${env.ENERGY_URL}/api/company`,
    getDistributors: `${env.ENERGY_URL}/api/company/distributors`,
    putPostCompany: `${env.ENERGY_URL}/api/company`,
    getCompanyById: (id: string) => `${env.ENERGY_URL}/api/company/${id}`,
    getConsumerById: (id: string) =>
      `${env.ENERGY_URL}/api/company/consumer/${id}`,
    getConsumersByTaxId: (taxId: string) =>
      `${env.ENERGY_URL}/api/company/${taxId}/consumers`,
    getDistributorById: (id: string) =>
      `${env.ENERGY_URL}/api/company/distributor?id=${id}`,
    getCompanyByTaxId: (taxId: string) =>
      `${env.ENERGY_URL}/api/company/name?taxId=${taxId}`,
    getOnboardingCompanies: `${env.ENERGY_URL}/api/odata`,
    getDocument: (taxId?: string, documentTypeEnum?: number | string) =>
      `${env.ENERGY_URL}/api/admin-dashboard/${taxId}/document?documentType=${documentTypeEnum}`,
    getDownload: (taxId?: string, downloadTypeEnum?: number | string) =>
      `${env.ENERGY_URL}/api/admin-dashboard/${taxId}/download?downloadType=${downloadTypeEnum}`,
    getRequiredDocuments: (distributorId: string) =>
      `${env.ENERGY_URL}/api/company/requiredDistributorDocuments?id=${distributorId}`,
  },
  apiHubCollection: {
    odata: `${env.API_COLLECTION}/odata/CollectionOData`,
    resendNotification: `${env.API_COLLECTION}/api/notification/resend`,
    notification: (tradeId: string) =>
      `${env.API_COLLECTION}/api/notification/${tradeId}`,
    tempaltesCCB: `${env.API_COLLECTION}/api/template/ccb`,
  },
  hubonboardingrelationships: {
    irisPowers: `${env.API_RELATIONSHIPS}/api/customer-service/iris-powers`,
    guarantor: {
      loadGuarantors: `${env.API_RELATIONSHIPS}/api/guarantor/load-guarantors`,
      onboardingGuarantor: `${env.API_RELATIONSHIPS}/api/guarantor/onboarding-guarantor`,
    },
    admin: {
      guarantor: {
        getAll: `${env.API_RELATIONSHIPS}/api/admin/guarantors/get-all`,
        resend: `${env.API_RELATIONSHIPS}/api/admin/guarantors/resend`,
      },
      companyPowers: {
        get: `${env.API_RELATIONSHIPS}/api/admin/companyPowersMonitor/get`,
      },
      irisPowers: `${env.API_RELATIONSHIPS}/api/admin/get-iris-powers`,
    },
    company: {
      createUser: `${env.API_RELATIONSHIPS}/api/user/users/register`,
    },
    odata: {
      companiesPowers: {
        parametrizationStatus: `${env.API_RELATIONSHIPS}/odata/company-powers/parametrization-status`,
      },
    },
  },
  fidcBankslipManager: {
    getBankslip: (slipId: string) =>
      `${env.FIDC_BANKSLIP_MANAGER_URL}v1/bankslip/${slipId}`,
    putBankslip: `${env.FIDC_BANKSLIP_MANAGER_URL}v1/bankslip/`,
    getNextBusDay: (date: string, units: number | string) =>
      `${env.FIDC_BANKSLIP_MANAGER_URL}v1/NextBusDay?referenceDate=${date}&units=${units}`,
  },
  calculator: {
    getDistributors: `${env.CALCULATOR_URL}/api/v1/distribuidor/`,
    getCurrentVersions: `${env.CALCULATOR_URL}/api/v1/health/version`,
    postCotacao: `${env.CALCULATOR_URL}/api/v1/cotacao/`,
    getCotacaoById: (id: number | string) =>
      `${env.CALCULATOR_URL}/api/v1/cotacao/${id}`,
    getReport: `${env.CALCULATOR_URL}/api/v1/relatorio/`,
    deleteCotacao: (id: number | string) =>
      `${env.CALCULATOR_URL}/api/v1/cotacao/${id}`,
    getHistoricoCotacao: `${env.CALCULATOR_URL}/api/v1/cotacao/historico_agrupamento`,
    getConsolidado: `${env.CALCULATOR_URL}/api/v1/cotacao/consolidado`,
    postCurva: `${env.CALCULATOR_URL}/api/v1/curva/`,
    getCurvaByDate: (date: string) =>
      `${env.CALCULATOR_URL}/api/v1/curva/${date}`,
    postBill: `${env.CALCULATOR_URL}/api/v1/leitura_conta/upload_conta`,
    reprocessBillData: `${env.CALCULATOR_URL}/api/v1/leitura_conta/reprocessar_dados`,
    getBill: `${env.CALCULATOR_URL}/api/v1/leitura_conta/processar_conta`,
    postPSR: `${env.CALCULATOR_URL}/api/v1/distribuidor/psr`,
  },
  eligibilityengine: {
    eligibility: {
      result: {
        getCompanyResultsByProduct: (
          governmentId: string,
          productId: number | string,
        ) =>
          `${env.CARDS_OFFER_ENGINE_URL}/eligibility/result/product/${productId}/company/${governmentId}`,
        getCompanyResultsByOffer: (
          governmentId: string,
          offerId: number | string,
        ) =>
          `${env.CARDS_OFFER_ENGINE_URL}/eligibility/result/offer/${offerId}/company/${governmentId}`,
        getCompanyResultsByProductGroup: (
          governmentId: string,
          baseKind: string,
        ) =>
          `${env.CARDS_OFFER_ENGINE_URL}/eligibility/result/product-group/${baseKind}/company/${governmentId}`,
      },
      group: {
        create: `${env.CARDS_OFFER_ENGINE_URL}/eligibility/group`,
        update: (groupId: number | string) =>
          `${env.CARDS_OFFER_ENGINE_URL}/eligibility/group/${groupId}`,
        getByProductGroup: (baseKind: string) =>
          `${env.CARDS_OFFER_ENGINE_URL}/eligibility/group/product-group/${baseKind}`,
      },
      productGroup: {
        processAll: (baseKind: string) =>
          `${env.CARDS_OFFER_ENGINE_URL}/eligibility/product-group/${baseKind}`,
        processByCompany: (governmentId: string, baseKind: string) =>
          `${env.CARDS_OFFER_ENGINE_URL}/eligibility/product-group/${baseKind}/company/${governmentId}`,
      },
    },
    product: {
      getAll: `${env.CARDS_OFFER_ENGINE_URL}/product`,
    },
    criteria: {
      getTypes: `${env.CARDS_OFFER_ENGINE_URL}/criteria/types`,
    },
    offer: {
      getAll: `${env.CARDS_OFFER_ENGINE_URL}/offer`,
      changeIsEligibleStatus: (offerId: number | string) =>
        `${env.CARDS_OFFER_ENGINE_URL}/offer/${offerId}`,
    },
  },
  limitControl: {
    createLimitBag: () => `${env.LIMIT_CONTROL_URL}/api/v1/limits/`,
    createDependentLimitBag: (limitBagId: string) =>
      `${env.LIMIT_CONTROL_URL}/api/v1/limits/${limitBagId}/dependents`,
    getLimitBagWithDependents: (limitBagId: string) =>
      `${env.LIMIT_CONTROL_URL}/api/v1/limits/${limitBagId}/dependents`,
    getLimitBagDetails: (limitBagId: string) =>
      `${env.LIMIT_CONTROL_URL}/api/v1/limits/${limitBagId}/details`,
    getLimitBagChart: (limitBagId: string) =>
      `${env.LIMIT_CONTROL_URL}/api/v1/charts/limits/${limitBagId}`,
    getLimitBagsInfoByProduct: (productName: string) =>
      `${env.LIMIT_CONTROL_URL}/api/v1/products/${productName}/limits`,
    updateLimitBag: (limitBagId: string) =>
      `${env.LIMIT_CONTROL_URL}/api/v1/limits/${limitBagId}`,
  },
  paperclipv2: {
    signatureRule: {
      byCnpj: (taxId: string) => `/SignatureRule?cnpj=${taxId}`,
      createRuleAndGroup: `/SignatureRule`,
      createGroup: (signatureRuleId: number | string) =>
        `/SignatureRule/${signatureRuleId}/GroupOfSignatures`,
      updateGroup: (
        signatureRuleId: number | string,
        groupId: number | string,
      ) => `/SignatureRule/${signatureRuleId}/GroupOfSignatures/${groupId}`,
      deleteGroup: (
        signatureRuleId: number | string,
        groupId: number | string,
      ) => `/SignatureRule/${signatureRuleId}/GroupOfSignatures/${groupId}`,
      updateRule: (signatureRuleId: number | string) =>
        `/SignatureRule/${signatureRuleId}`,
    },
    contracts: {
      updateContract: (UUID: string) =>
        `${env.PAPERCLIPV2_URL}Contract/${UUID}/update-contract`,
      getContractFile: (UUID: string) =>
        `${env.PAPERCLIPV2_URL}Contract/${UUID}/file-binary.pdf`,
      getSignedContractFile: (UUID: string) =>
        `${env.PAPERCLIPV2_URL}Contract/${UUID}/file-signed-binary.pdf`,
      resendNotification: (UUID: string) =>
        `${env.PAPERCLIPV2_URL}Contract/${UUID}/send-notifications`,
      status: (UUID: string) => `${env.PAPERCLIPV2_URL}Contract/${UUID}/status`,
    },
    odata: {
      contractsByType: `${env.PAPERCLIPV2_URL}odata/ContractAdminOData`,
      contractsOfEntityByType: (cnpj: string, ContractType: string) =>
        `${env.PAPERCLIPV2_URL}odata/ContractAdminOData?$filter=contains(tolower(CompanyCNPJ)%2C%27${cnpj}%27)%20and%20ContractType%20eq%20%27${ContractType}%27`,
      customerFromQF: `odata/CedentesCompaniesView`,
      contractByUUID: (uuid: string) =>
        `${env.PAPERCLIPV2_URL}odata/ContractAdminOData?$apply=filter(UUID eq ${uuid})`,
    },
  },
  turtleRoad: {
    configTaxa: {
      getUniqueTaxes: (convenioUniqueId: string) =>
        `/taxas/configTaxa/${convenioUniqueId}`,
      createAndUpdateTaxaPrazo: (currencyId: number | string) =>
        `/taxas/taxaPrazo/${currencyId}`,
      createAndUpdateFullConfig: () => "/taxas/configTaxa/",
      deleteTaxaPrazo: (taxaPrazoId: number | string) =>
        `/taxas/taxaPrazo/${taxaPrazoId}`,
    },
  },
  quickfin: {
    salesforce: {
      getSacados: `${env.QUICKFIN_API}companies/cedente/sacados/salesforce?TipoProduto=RiscoSacado`,
      receivables: (sacadoId: number) =>
        `${env.QUICKFIN_API}odata/ReceivablesSalesforceOData?sacadoId=${sacadoId}`,
      operations: (page: number, itemsPerPage = 10) =>
        `${
          env.QUICKFIN_API
        }odata/OperationsAttendanceOData?$top=${itemsPerPage}&$skip=${
          (page - 1) * itemsPerPage
        }&$orderby=CreatedAt%20desc&$count=true&TipoProduto=Cartao&TipoProduto=RiscoSacado&TipoProduto=Offshore`,
      operationsAttendance: (operationId: number) =>
        `${env.QUICKFIN_API}operations/attendance/${operationId}`,
      listDocuments: (page?: number, rows = 10) =>
        `${env.QUICKFIN_API}salesforce/documentos?rows=${rows}&page=${page}&filename=`,
      downloadDocument: (documentId: string) =>
        `${env.QUICKFIN_API}salesforce/documentos/${documentId}/zip`,
    },
    onboarding: `${env.QUICKFIN_API}onboarding`,
    onboardingErrors: (taxId: string) =>
      `${env.QUICKFIN_API}onboarding/errors/${taxId}`,
    scheduling: {
      comprovante: (
        agendamentoAndReceivableId: number | string,
        isAgendamentoId: boolean,
      ) =>
        `${env.QUICKFIN_API}scheduling/${agendamentoAndReceivableId}/comprovante/${isAgendamentoId}`,
    },
    operationById: (companyId: number | string, operationId: number | string) =>
      `${env.QUICKFIN_API}admin/companies/${companyId}/operations/${operationId}`,
    estrategiaComercial: {
      getEstrategias: (id: number | string) => `/estrategia-comercial/${id}`,
      deleteEstrategia: (
        companyId: number | string,
        estrategiaId: number | string,
      ) =>
        `${env.QUICKFIN_API}estrategia-comercial/${companyId}/${estrategiaId}`,
      createEstrategia: (companyId: number | string) =>
        `${env.QUICKFIN_API}estrategia-comercial/${companyId}`,
      updateEstrategia: (companyId: number | string) =>
        `${env.QUICKFIN_API}estrategia-comercial/${companyId}`,

      setEstrategiaDefault: (
        companyId: number | string,
        estrategiaId: number | string,
      ) =>
        `${env.QUICKFIN_API}estrategia-comercial/${companyId}/${estrategiaId}/padrao`,
      getTemplatesEstrategia: `${env.QUICKFIN_API}template-estrategia-comercial`,
      createTemplateEstrategia: `${env.QUICKFIN_API}template-estrategia-comercial`,
      updateTemplateEstrategia: `${env.QUICKFIN_API}template-estrategia-comercial`,
    },
    portal: "/portal",
    verifyRiscoCedente: () => `/visao-cedente/verify`,
    superAdmin: {
      updateAgendamentoState: () => "super-admin/agendamento",
    },
    balances: {
      getBalances: () => "/admin/balances",
    },
    tiers: {
      getTiers: (cedenteId?: number | string) =>
        cedenteId ? `/admin/tiers?cedenteId=${cedenteId}` : "/admin/tiers",
      getTier: (id: number | string) => `/admin/tiers/${id}`,
      createTiers: () => "/admin/tiers",
      updateTier: (id: number | string) => `/admin/tiers/${id}`,
      destroyTier: (id: number | string) => `/admin/tiers/${id}`,
      getRelationships: (id?: string) =>
        "/admin/sacadocedentetiers/tiers/" + (id ? id : ""),
      destroyRelationship: (
        tierId: number | string,
        sacadoId: number | string,
        cedenteId: number | string,
      ) =>
        `/admin/sacadocedentetiers/tiers/${tierId}/sacados/${sacadoId}/cedentes/${cedenteId}`,
      createRelationship: (id: number | string) =>
        `/admin/sacadocedentetiers/tiers/${id}`,
      cedenteLimits: () => "/admin/limit/cedentes",
      cedenteSacadoLimits: (cedenteLimitId: number | string) =>
        `/admin/cedentelimits/${cedenteLimitId}/sacados`,
      editCedenteSacadoLimits: (
        cedenteLimitId: number | string,
        sacadoId: number | string,
      ) => `/admin/cedentelimits/${cedenteLimitId}/sacados/${sacadoId}`,
      editCedenteLimit: (id: number | string) => `/admin/limit/cedentes/${id}`,
      sacadoTierConfigs: () => "/admin/tiers/sacados",
      editSacadoTierConfig: (id: number | string) =>
        `/admin/tiers/sacados/${id}`,
    },
    companies: {
      withOnboarding: (id: number | string) =>
        `${env.QUICKFIN_API}companies/with-onboarding/${id}`,
      getContractTemplates: (companyId: number | string) =>
        `/companies/${companyId}/contract-templates`,
      adminGetAllCompanies: (query: string) =>
        `${env.QUICKFIN_API}companies/admin/get-all-companies?${query}`,
    },
    receivables: {
      approve: "/admin/receivables/approve",
      getAllReceivables: "/admin/receivables",
      receivablesSummary: `admin/receivables/summary`,
      exportAllReceivables: "/admin/receivables/export",
      getReceivablesDailyFIDC: `admin/receivables/fidc/dailySummary`,
      cancel: `${env.QUICKFIN_API}receivables/cancel`,
      getAllCancelableReceivables: `${env.QUICKFIN_API}admin/cancelable-receivables`,
    },
    odata: {
      getUsers: "odata/UsersView",
      getSacados: "odata/SacadosCompaniesView",
      getCedentes: "odata/CedentesCompaniesView",
      getAuditsLogs: "odata/AuditsView",
      getEventsLogs: "odata/LogEventosViews",
      getOperations: "odata/OperationsOData",
      getOperationsCreditCard: `odata/OperationsOData?tipoProduto=${ProductType.Cartao}`,
      getOperationsInvoice: `odata/OperationsOData?tipoProduto=${ProductType.RiscoSacado}`,
      getOperationsOffshore: `odata/OperationsOData?tipoProduto=${ProductType.RiscoSacado}&tipoProduto=${ProductType.Offshore}`,
      getOperationsFIDC: "odata/FIDCOperationsOData",
      getBorderos: "odata/BorderosOData",
      getBorderosFIDC: "odata/FIDCBorderosOData",
      getReceivablesState: "odata/ReceivableStateMachineODataView",
      getReceivablesStateFIDC: "odata/FIDCReceivableStateMachineODataView",
      getAgendamentosState: "odata/AgendamentoView",
      getAditamentos: "odata/AditamentoOData",
      getRelacionamentos: "odata/CompaniesRelationshipsOData",
      cedentesCompaniesView: `${env.QUICKFIN_API}odata/CedentesCompaniesView`,
      estrategiasComerciaisOData: `${env.QUICKFIN_API}odata/EstrategiasComerciaisOData`,
      onboardingsFornecedoresOData: `${env.QUICKFIN_API}odata/OnboardingsFornecedoresOData`,
      monitorDiario: `${env.QUICKFIN_API}odata/MonitorDiario`,
    },
    monitor: {
      resumoDiario: `${env.QUICKFIN_API}admin/monitor/resumo-diario`,
      tradesMaturing: `${env.QUICKFIN_API}admin/monitor/trades-maturing`,
    },
  },
  renegotiation: {
    getContracts: `${env.RENEGOTIATION_API}/api/salesforce/operation`,
    getAvailableDates: `${env.RENEGOTIATION_API}/api/salesforce/operation/contracts-available-dates`,
    getOffer: `${env.RENEGOTIATION_API}/api/salesforce/operation/offers`,
    agreements: `${env.RENEGOTIATION_API}/api/salesforce/agreements`,
    getAgreementDetails: (id: string) =>
      `${env.RENEGOTIATION_API}/api/salesforce/agreements/${id}/details`,
    cancelAgreement: (id: string) =>
      `${env.RENEGOTIATION_API}/api/salesforce/agreements/${id}`,
    resendPayment: (id: string) =>
      `${env.RENEGOTIATION_API}/api/salesforce/agreements/${id}/first-payment/send-bank-slip`,
  },
  bankinghub: {
    getPayments: (status: string) =>
      `${env.BANKING_API}/api/v1/Bankslip/payments?status=${status}`,
    getAccounts: (bankCode: number) =>
      `${env.BANKING_API}/api/v1/Statement/${bankCode}/accounts`,
    getBalance: (bankCode: number, accountNumber: string, agency: string) =>
      `${env.BANKING_API}/api/v1/Statement/${bankCode}/${accountNumber}/${agency}/balance`,
    getMovements: (
      bankCode: number,
      accountNumber: string,
      agency: string,
      startDate: string | undefined,
      endDate: string | undefined,
    ) =>
      `${env.BANKING_API}/api/v1/Statement/${bankCode}/${accountNumber}/${agency}/movement?startDate=${startDate}&endDate=${endDate}`,
    getBills: (startDate: string | undefined, endDate: string | undefined) =>
      `${env.BANKING_API}/api/v1/Bankslip/issue?InitialDate=${startDate?.substr(
        0,
        10,
      )}&FinalDate=${endDate?.substr(0, 10)}&pageSize=200`,
    getTransfers: (identification: string, status: string, quantity: number) =>
      `${env.BANKING_API}/api/v2/Payment?document=${identification}&quantity=${quantity}&status=${status}`,
    getDollarPrice: (referenceDate: string) =>
      `${env.BANKING_API}/api/v1/Cards/dollarPrice?referenceDate=${referenceDate}`,
    getOffers: `${env.BANKING_API}/api/v1/Cards/offers`,
    getCards: `${env.BANKING_API}/api/v1/Cards/accounts`,
    getTimelines: (
      accountId: string,
      itemsPerPage: number,
      page: number,
      rawEvents: boolean,
      account?: {
        branch: string;
        number: string;
      },
    ) =>
      `${
        env.BANKING_API
      }/api/v1/Cards/internal/accounts/${accountId}/timelines?itemsPerPage=${itemsPerPage}&page=${page}&rawEvents=${rawEvents}${
        account
          ? `&branch=${account.branch}&accountNumber=${account.number}`
          : ""
      }`,
    getTrackings: (cardId: string, trackingId: string) =>
      `${env.BANKING_API}/api/v1/Cards/${cardId}/trackings/${trackingId}`,
    getInvoices: (accountId: string) =>
      `${env.BANKING_API}/api/v1/Cards/accounts/${accountId}/invoices`,
    getInvoiceDetails: (accountId: string, invoiceId: number) =>
      `${env.BANKING_API}/api/v1/Cards/accounts/${accountId}/invoices/${invoiceId}`,
    getInvoiceReports: (accountId: string, invoiceId: number) =>
      `${env.BANKING_API}/api/v1/Cards/accounts/${accountId}/invoices/${invoiceId}/reports`,
    getInvoiceTransactions: (
      accountId: string,
      invoiceId: number,
      itemsPerPage: number,
      page: number,
    ) =>
      `${env.BANKING_API}/api/v1/Cards/accounts/${accountId}/invoices/${invoiceId}/transactions?itemsPerPage=${itemsPerPage}&page=${page}`,
    postInvoicePaymentMethods: (accountId: string, invoiceId: number) =>
      `${env.BANKING_API}/api/v1/Cards/accounts/${accountId}/invoices/${invoiceId}/paymentMethods`,
    postContestations: `${env.BANKING_API}/api/v1/Cards/contestations`,
    getContestations: (companyDocument: string, status: string) =>
      `${env.BANKING_API}/api/v1/Cards/contestations?companyDocument=${companyDocument}&status=${status}`,
    postDefinitiveBlock: (cardId: string) =>
      `${env.BANKING_API}/api/v1/Cards/${cardId}/definitiveBlocks`,
    postTemporaryBlock: (cardId: string) =>
      `${env.BANKING_API}/api/v1/Cards/internal/${cardId}/temporaryBlocks`,
    deleteTemporaryBlocks: (cardId: string) =>
      `${env.BANKING_API}/api/v1/Cards/${cardId}/temporaryBlocks`,
    postPreventiveBlock: (cardId: string) =>
      `${env.BANKING_API}/api/v1/Cards/internal/${cardId}/preventiveBlocks`,
    postResetWrongCardPasswordAttempts: (cardId: string) =>
      `${env.BANKING_API}/api/v1/Cards/internal/${cardId}/resetWrongPasswordAttempts`,
    patchContactless: (cardId: string) =>
      `${env.BANKING_API}/api/v1/Cards/${cardId}/contactless`,
    patchOnlineShopping: (cardId: string) =>
      `${env.BANKING_API}/api/v1/Cards/${cardId}/onlineShopping`,
    getOverdraftLimit: (document: string) =>
      `${env.BANKING_API}/api/v1/overdrafts/limits?document=${document}`,
    getOverdraftLimitAnalysisByStatus: (
      document: string,
      status: LimitAnalysisStatus,
    ) =>
      `${env.BANKING_API}/api/v1/overdrafts/analysis/${document}?status=${status}`,
    getOverdrafActiveInstallmentPlans: (ownerId: string) =>
      `${env.BANKING_API}/api/v1/overdrafts/owner/${ownerId}/installmentPlans`,
    fopa: {
      PayrollList: `${env.BANKING_API}/api/v1/Fopa/Payroll/List`,
      PayrollLines: `${env.BANKING_API}/api/v1/Fopa/Payroll/Lines`,
      getListSolicitations: `${env.BANKING_API}/api/v1/Fopa/Massify/List`,
      getEmployesList: `${env.BANKING_API}/api/v1/Fopa/Massify/Employees/List`,
      CheckCompanyExist: `${env.BANKING_API}/api/v1/Fopa/CheckCompanyExist`,
      getlistFilters: `${env.BANKING_API}/api/v1/Fopa/Massify/Employees/ListFilters`,
    },
  },
};
