import { TooltipIcon } from "components/tooltip-icon";

interface TextWithTooltipProps {
  text: string;
  tooltip: string;
}

export const TextWithTooltip = ({ text, tooltip }: TextWithTooltipProps) => {
  return (
    <span className="d-flex align-items-center gap-2">
      <TooltipIcon content={tooltip} />
      <span
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
        title={text}
      >
        {text}
      </span>
    </span>
  );
};
