import { SelectSearchFieldOption } from "components/select-search/field/select-search-field.types";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { fastCpfCnpjMask } from "utils/mask/fast-cpf-cnpj-mask";
import { AddPendencyForm } from "../../add-pendency-modal.form";

export const useConsumerUnitSelector = () => {
  const { callService, loading, hasError, value } = useServiceCall(
    service.hubEnergy.getConsumerUnitsMonitor,
  );

  const { clearErrors, setError } = useFormContext<AddPendencyForm>();

  const getConsumerUnits = useCallback(async () => {
    const { success } = await callService();
    if (success) clearErrors("_consumerUnitId");
    else
      setError("_consumerUnitId", {
        message:
          "Erro ao buscar as unidades consumidoras. Clique no botão ao lado para tentar novamente.",
      });
  }, [callService, clearErrors, setError]);

  useEffect(() => {
    getConsumerUnits();
  }, [getConsumerUnits]);

  const consumerUnits = useMemo(
    () =>
      value?.response
        .filter(({ diligenceStepsStatuses }) => diligenceStepsStatuses?.length)
        .map<SelectSearchFieldOption<number>>(
          ({ id, consumerUnit, installationNumber }) => ({
            label: `${consumerUnit.officialName} | ${fastCpfCnpjMask(
              consumerUnit.taxId,
            )} | nº inst: ${installationNumber}`,
            value: id,
          }),
        ) ?? [],
    [value?.response],
  );

  return {
    consumerUnits,
    getConsumerUnits,
    loading,
    hasFetchError: hasError,
  };
};
