import { OToastManager, modalManager } from "@maestro/core";
import { OConfirmationModal, OTypography } from "@maestro/react";
import { IconButton } from "components/icon-button";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { getValidationMessages } from "../../../../../../utils";

const modalId = "remove-import-confirmation-modal";

interface RemoveButtonProps {
  importData: HubEnergy.LegacyImport.ConsumerUnitsImportDataResponse;
}

export const RemoveButton = ({ importData }: RemoveButtonProps) => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.deleteConsumerUnitsImportData,
  );

  const navigate = useNavigate();

  const removeImportData = useCallback(async () => {
    const { success, error } = await callService(importData.id);

    if (success) {
      OToastManager.success("A importação foi removida com sucesso");
      navigate(corporateRouter.routes.energia.product.consumerUnitsImport.path);
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0].ErrorMessage ??
          "Erro ao remover a importação",
      );
    }
  }, [callService, importData.id, navigate]);

  return (
    <>
      <IconButton
        icon={{ category: "orq", icon: "orq-edit-trash" }}
        loading={loading}
        onClick={() => modalManager.show(modalId)}
        type="danger"
        disabled={
          importData.status === "IMPORTADO" ||
          importData.status === "PROCESSANDO"
        }
      >
        Remover importação
      </IconButton>
      <OConfirmationModal
        modalId={modalId}
        title="Remover importação"
        divider={false}
        onConfirm={() => {
          removeImportData();
          modalManager.hide(modalId);
        }}
        className="position-absolute"
      >
        <OTypography>Tem certeza que deseja remover a importação?</OTypography>
      </OConfirmationModal>
    </>
  );
};
