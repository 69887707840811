import { OToastManager } from "@maestro/react";
import { masks } from "@maestro/utils";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { PatchCompaniesByCustomerIdBillingProductsBody } from "services/api/models/requests";
import { service } from "services/service";
import { BillingProdutoDetalhesByTaxIdRouteParams } from "../../../../routes/billing.route-params";

export const useRateCustomization = () => {
  const { taxId } = useParams<BillingProdutoDetalhesByTaxIdRouteParams>();
  if (!taxId) {
    throw new Error("No id parameter");
  }

  const form = useForm();
  const { handleSubmit, setValue } = form;

  const {
    callService,
    hasError,
    loading: loadingBillingInfo,
    value: rateValues,
  } = useServiceCall(service.api.getCompanyBillingInfo);

  const { callService: callSubmit, loading: loadingSubmit } = useServiceCall(
    service.api.patchCompanyBillingProduct,
  );

  const getBillingInfo = useCallback(
    () => callService(taxId),
    [callService, taxId],
  );

  useEffect(() => {
    getBillingInfo();
  }, [getBillingInfo]);

  const customerName = useMemo(
    () =>
      rateValues
        ? `${rateValues.name} - ${masks.cpfCnpj(rateValues.customerId)}`
        : "",
    [rateValues],
  );

  useEffect(() => {
    if (rateValues) {
      rateValues.customerProduct.map((product) =>
        setValue(
          product.productSku,
          product?.adjustedAmount ?? product.defaultAmount,
        ),
      );
      rateValues.paymentSource.map((product) =>
        setValue(
          `${product.productSku}-${product.source}`,
          product?.adjustedAmount ?? product.amount,
        ),
      );
    }
  }, [rateValues, setValue]);

  const onSubmit = handleSubmit(async (values) => {
    if (rateValues) {
      const body: PatchCompaniesByCustomerIdBillingProductsBody = {
        CustomerProduct: Object.values(rateValues.customerProduct).map(
          (product) => ({
            productSku: product.productSku,
            AdjustedAmount: values[product.productSku],
          }),
        ),
        PaymentSource: Object.values(rateValues.paymentSource).map(
          (product) => ({
            productSku: product.productSku,
            AdjustedAmount: values[`${product.productSku}-${product.source}`],
            source: product.source,
          }),
        ),
      };

      const { success } = await callSubmit(taxId, body);

      if (success) {
        OToastManager.success("Tarifas atualizadas com sucesso!");
      } else {
        OToastManager.danger("Erro ao atualizar as tarifas");
      }
    }
  });

  return {
    customerName,
    getBillingInfo,
    form,
    hasError,
    loadingBillingInfo,
    loadingSubmit,
    onSubmit,
    rateValues,
  };
};
