import { OToastManager, OUploadCustomEvent } from "@maestro/core";
import { useReadFile } from "hooks/read-file";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { logger } from "utils/logger";
import { useUploadTiers } from "../../upload-tiers.context";
import { parseBatchTiersSpreadsheet } from "./file-upload.utils";

export const useFileUpload = () => {
  const [hasParseError, setHasParseError] = useState(false);

  const {
    callService: batchPreviewTiers,
    hasError: apiHasError,
    loading: apiLoading,
    value: apiResponse,
  } = useServiceCall(service.quickfin.batchPreviewTiers);

  const { nextStep, setParsedBatchTiersData, setPreviewData } =
    useUploadTiers();

  const {
    error: readerError,
    loading: readerLoading,
    reader,
    result,
    reset: readerReset,
  } = useReadFile();

  const form = useForm();

  const handleAddFile = useCallback(
    (evt: OUploadCustomEvent<File>) => {
      const file = evt.detail;

      if (file) {
        reader.readAsBinaryString(file);
      }
    },
    [reader],
  );

  useEffect(() => {
    if (result && !readerError) {
      try {
        const batchTiersData = parseBatchTiersSpreadsheet(result);

        if (batchTiersData.length > 0) {
          // validate
          setParsedBatchTiersData(batchTiersData);
          batchPreviewTiers(batchTiersData);
        }
      } catch (err) {
        logger.error(err);
        setHasParseError(true);
      }
    }
  }, [batchPreviewTiers, readerError, result, setParsedBatchTiersData]);

  useEffect(() => {
    if (apiResponse) {
      setPreviewData(apiResponse);
      nextStep();
    }
  }, [nextStep, apiResponse, setPreviewData]);

  useEffect(() => {
    if (apiHasError) {
      OToastManager.danger("Ocorreu um erro ao enviar o arquivo.");
      readerReset();
    }
  }, [apiHasError, readerReset]);

  return {
    apiHasError,
    apiLoading,
    form,
    handleAddFile,
    hasParseError,
    readerError,
    readerLoading,
    readerReset,
    setHasParseError,
  };
};
