import {
  OCheckbox,
  OCheckboxGroup,
  OFilterItem,
  OLabel,
  OTypography,
} from "@maestro/react";
import { CollectionStatus } from "../../../../bankslip.type";

export const StatusFilterItem = () => {
  return (
    <OFilterItem>
      <OTypography slot="title">Status</OTypography>
      <OCheckboxGroup
        dataAction=""
        dataLabel=""
        className="d-flex flex-column align-items-center w-100"
        data-filterparam="status"
      >
        <div className="d-flex align-items-center justify-content-between py-3 w-95 border-bottom">
          <OLabel htmlFor="status_created">Em aberto</OLabel>
          <OCheckbox
            id="status_created"
            value={CollectionStatus.Created}
            size="sm"
          />
        </div>

        <div className="d-flex align-items-center justify-content-between py-3 w-95 border-bottom">
          <OLabel htmlFor="status_paid">Pago</OLabel>
          <OCheckbox id="status_paid" value={CollectionStatus.Paid} size="sm" />
        </div>

        <div className="d-flex align-items-center justify-content-between py-3 w-95 border-bottom">
          <OLabel htmlFor="status_canceled">Cancelado</OLabel>
          <OCheckbox
            id="status_canceled"
            value={`${CollectionStatus.Canceled},${CollectionStatus.Expired}`}
            size="sm"
          />
        </div>

        <div className="d-flex align-items-center justify-content-between py-3 w-95 border-bottom">
          <OLabel htmlFor="status_overdue">Vencido</OLabel>
          <OCheckbox
            id="status_overdue"
            value={CollectionStatus.Overdue}
            size="sm"
          />
        </div>

        <div className="d-flex align-items-center justify-content-between py-3 w-95 border-bottom">
          <OLabel htmlFor="status_processing">Em processamento</OLabel>
          <OCheckbox
            id="status_processing"
            value={CollectionStatus.Processing}
            size="sm"
          />
        </div>

        <div className="d-flex align-items-center justify-content-between py-3 w-95 border-bottom">
          <OLabel htmlFor="status_failed">Falha</OLabel>
          <OCheckbox
            id="status_failed"
            value={CollectionStatus.Failed}
            size="sm"
          />
        </div>
      </OCheckboxGroup>
    </OFilterItem>
  );
};
