import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { getValidationMessages } from "../../../../../../utils";

export const useGridActions = (
  pendencyType: HubEnergy.DiligenceStepPendencyTypeResponse,
  reload: () => void,
) => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.deleteDiligenceStepPendencyTypeById,
  );

  const deletePendencyType = useCallback(async () => {
    const { success, error } = await callService(pendencyType.id);

    if (success) {
      OToastManager.success("Tipo de pendência excluído com sucesso");
      reload();
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0]?.ErrorMessage ??
          "Erro ao excluir o tipo de pendência",
      );
    }
  }, [callService, pendencyType, reload]);

  return { deletePendencyType, loading };
};
