import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { customer } from "contexts/customer";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services/service";
import {
  updateOriginatorInfoDefaultValues,
  updateOriginatorInfoValidationSchema,
} from "./update-originator-info.form";

export const useUpdateOriginatorInfo = () => {
  const [isCNPJ, setIsCNPJ] = useState(false);
  const [isCorban, setIsCorban] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [uuid, setUuid] = useState("");

  const form = useForm({
    resolver: yupResolver(updateOriginatorInfoValidationSchema),
    defaultValues: updateOriginatorInfoDefaultValues,
  });
  const { handleSubmit, setValue } = form;

  const onSubmit = handleSubmit(async (values) => {
    const payload = {
      officialName: values.officialName,
      tradingName: values.officialName,
      economicGroupUUID: values.economicGroupUUID,
      identifier: values.identifier,
      mainOriginatorUUID: values.mainOriginatorUUID,
      areaCode: values.areaCode,
      tagCode: values.tagCode,
    };

    setLoadingSubmit(true);
    try {
      await service.hubPartners.updateOriginatorDetails(uuid, payload);
      OToastManager.success("Originador atualizado com sucesso!");
    } catch {
      OToastManager.danger("Erro ao atualizar detalhes do originador");
    } finally {
      setLoadingSubmit(false);
    }
  });

  const getInfo = useCallback(async () => {
    const customerUuid = customer.value?.identification;

    if (!customerUuid) {
      return;
    }

    setLoading(true);
    try {
      const { data } = await service.hubPartners.originatorDetails(
        customerUuid,
      );
      const details = data.data;
      setValue("identification", details.identification);
      setValue("identificationType", details.identificationType);
      setValue("officialName", details.officialName);
      setValue("tradingName", details.tradingName);
      setValue("economicGroupUUID", details.economicGroup?.uuid);
      setValue("identifier", details.identifier);
      setValue("mainOriginatorUUID", details.mainOriginator?.uuid);
      setValue("tagCode", details.tagCode);

      setUuid(details.uuid);

      setIsCNPJ(
        details.identificationType === "CPF" ||
          details.identificationType === "CNPJ",
      );

      setIsCorban(
        details.partnershipTypes.some((pt) => pt.code !== "PES") &&
          details.partnershipTypes.some((pt) => pt.code !== "FDR"),
      );
    } catch {
      OToastManager.danger("Falha ao obter detalhes do originador");
    } finally {
      setLoading(false);
    }
  }, [setValue]);

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  return {
    form,
    isCNPJ,
    isCorban,
    loading,
    loadingSubmit,
    onSubmit,
  };
};
