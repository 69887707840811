import { env } from "utils/environments";

export const accEndpoints = {
  admin: {
    common: `${env.API_ACC}/api/admin/common`,
    counterparty: `${env.API_ACC}/api/admin/counterparty`,
    acc: {
      generateContract: `${env.API_ACC}/api/admin/acc/generate-contract`,
    },
    customer: {
      trades: `${env.API_ACC}/api/admin/customer/acc/trade`,
      simulate: `${env.API_ACC}/api/admin/customer/acc/simulate`,
      tradesById: (id: string) =>
        `${env.API_ACC}/api/admin/customer/acc/trade/${id}`,
      counterpartiesDetails: `${env.API_ACC}/api/admin/customer/acc/counterparty`,
      proposals: `${env.API_ACC}/api/admin/customer/acc/proposal`,
    },
  },
  odata: {
    adminACCCounterpartiesOData: `${env.API_ACC}/api/admin/odata/acc-counterparties`,
    adminACCProposalsOData: `${env.API_ACC}/api/admin/odata/acc-proposals`,
    adminACCTradesOData: `${env.API_ACC}/api/admin/odata/acc-trades`,
  },
};
