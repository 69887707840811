export const ORFieldInputs = [
  {
    label: "Nome",
    name: "description",
    id: "name",
    dataAction: "moedas:input:name",
    dataLabel: "name",
    required: true,
    placeholder: "Ex: USD",
  },
  {
    label: "Código",
    name: "code",
    id: "code",
    dataAction: "moedas:input:code",
    dataLabel: "code",
    required: true,
    mask: true,
    maskOption: {
      mask: "aaa",
      prepare: (value: string) =>
        value
          .toUpperCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, ""),
    },
    placeholder: "Ex: USD",
  },
  {
    label: "Símbolo",
    name: "symbol",
    id: "symbol",
    dataAction: "moedas:input:symbol",
    dataLabel: "symbol",
    placeholder: "Ex: $",
  },
  {
    label: "URL do ícone",
    name: "iconUrl",
    id: "iconUrl",
    dataAction: "moedas:input:iconUrl",
    dataLabel: "iconUrl",
  },
  {
    label: "Quantidade de casas decimais",
    name: "decimals",
    id: "decimals",
    dataAction: "moedas:input:decimals",
    dataLabel: "decimals",
    required: true,
    mask: true,
    maskOption: {
      mask: "0",
      prepare: (value: string) => {
        const num = parseInt(value, 10);
        if (!isNaN(num) && num >= 1 && num <= 8) {
          return value;
        }
        return "";
      },
    },
    placeholder: "Ex: 5",
  },
  {
    label: "openingHours",
    name: "openingHours",
    id: "openingHours",
    dataAction: "moedas:input:openingHours",
    dataLabel: "openingHours",
    required: true,
    placeholder: "Ex: 24h por dia, 7 dias da semana | 10h às 12h ",
  },
  {
    label: "incomingDisbursementHours",
    name: "incomingDisbursementHours",
    id: "incomingDisbursementHours",
    dataAction: "moedas:input:incomingDisbursementHours",
    dataLabel: "incomingDisbursementHours",
    required: true,
    placeholder: "Ex: D+0 das 12h às 16h | D+1 das 09h às 17h50",
  },
  {
    label: "outgoingDisbursementHours",
    name: "outgoingDisbursementHours",
    id: "outgoingDisbursementHours",
    dataAction: "moedas:input:outgoingDisbursementHours",
    dataLabel: "outgoingDisbursementHours",
    required: true,
    placeholder: "Ex: D+0 das 12h às 16h | D+1 das 09h às 17h50",
  },
  {
    label: "Limite padrão",
    name: "standardQuoteLimitAmount",
    id: "standardQuoteLimitAmount",
    dataAction: "moedas:input:standardQuoteLimitAmount",
    dataLabel: "standardQuoteLimitAmount",
    mask: true,
    required: true,
    maskOptionToParse: '{"mask": "00000000"}',
  },
  {
    label: "Limite pré-ticket",
    name: "preTicketQuoteLimitAmount",
    id: "preTicketQuoteLimitAmount",
    dataAction: "moedas:input:preTicketQuoteLimitAmount",
    dataLabel: "preTicketQuoteLimitAmount",
    mask: true,
    required: true,
    maskOptionToParse: '{"mask": "00000000"}',
  },
];

export const ORFieldRadioGroups = [
  {
    label: "AllowsNonStopTrading (24/7)",
    name: "allowsNonStopTrading",
    id: "allowsNonStopTrading",
    dataAction: "moedas:input:allowsNonStopTrading",
    dataLabel: "allowsNonStopTrading",
    required: true,
    disbursement: false,
  },
  {
    label: "AllowsSameDayDisbursement (D+0)",
    name: "allowsSameDayDisbursement",
    id: "allowsSameDayDisbursement",
    dataAction: "moedas:input:allowsSameDayDisbursement",
    dataLabel: "allowsSameDayDisbursement",
    required: true,
    disbursement: true,
  },
];

export const SameDayFields = [
  {
    label: "sameDayIncomingLimitTime (D+0)",
    name: "sameDayIncomingLimitTime",
    id: "sameDayIncomingLimitTime",
    dataAction: "moedas:input:sameDayIncomingLimitTime",
    dataLabel: "sameDayIncomingLimitTime",
    maskOptionToParse: '{"mask": "00:00:00"}',
    placeholder: "Ex: 24:00:00",
  },
  {
    label: "sameDayOutgoingLimitTime (D+0)",
    name: "sameDayOutgoingLimitTime",
    id: "sameDayOutgoingLimitTime",
    dataAction: "moedas:input:sameDayOutgoingLimitTime",
    dataLabel: "sameDayOutgoingLimitTime",
    maskOptionToParse: '{"mask": "00:00:00"}',
    placeholder: "Ex: 24:00:00",
  },
];

export const NonStopTradingFields = [
  {
    label: "incomingStartTime (24/7)",
    name: "incomingStartTime",
    id: "incomingStartTime",
    dataAction: "moedas:input:incomingStartTime",
    dataLabel: "incomingStartTime",
    radioGroup: false,
    maskOptionToParse: '{"mask": "00:00:00"}',
    placeholder: "Ex: 24:00:00",
  },
  {
    label: "incomingEndTime (24/7)",
    name: "incomingEndTime",
    id: "incomingEndTime",
    dataAction: "moedas:input:incomingEndTime",
    dataLabel: "incomingEndTime",
    radioGroup: false,
    maskOptionToParse: '{"mask": "00:00:00"}',
    placeholder: "Ex: 24:00:00",
  },
  {
    label: "outgoingStartTime (24/7)",
    name: "outgoingStartTime",
    id: "outgoingStartTime",
    dataAction: "moedas:input:outgoingStartTime",
    dataLabel: "outgoingStartTime",
    radioGroup: false,
    maskOptionToParse: '{"mask": "00:00:00"}',
    placeholder: "Ex: 24:00:00",
  },
  {
    label: "outgoingEndTime (24/7)",
    name: "outgoingEndTime",
    id: "outgoingEndTime",
    dataAction: "moedas:input:outgoingEndTime",
    dataLabel: "outgoingEndTime",
    radioGroup: false,
    maskOptionToParse: '{"mask": "00:00:00"}',
    placeholder: "Ex: 24:00:00",
  },
];

export function stringToTime(value: string | null | undefined) {
  return value?.replace(/^(\d{2})(\d{2})(\d{2}).*/, "$1:$2:$3");
}
