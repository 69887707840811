import { ODataGridGenerator } from "components/data-grid";
import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { RefreshGridButton } from "components/refresh-grid-button";
import {
  NextStepButton,
  PreviousStepButton,
  ResetStepButton,
} from "components/steps-builder";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { ErrorNotifications } from "../../../../../components";
import { buildOperationDetails } from "../../../../../utils";
import { useQuote } from "../../quote.context";
import { quoteGrid } from "./quote-step.grid";
import {
  mapQuoteDataOverrides,
  mapQuoteDataToOperationDetails,
} from "./quote-step.utils";

export const QuoteStep = () => {
  const { getQuote, quoteData, quoteFileData, quoteHasError, quoteLoading } =
    useQuote();

  const gridRef = useGridRef();

  const quotesWithError = quoteFileData.filter((quote) => quote.error);
  const operationDetailsReceivables = mapQuoteDataToOperationDetails(quoteData);
  const operationDetailsOverrides = mapQuoteDataOverrides(quoteData);

  return (
    <>
      <ErrorNotifications receivablesWithError={quotesWithError} />
      <div className="d-flex flex-column gap-4">
        <DetailsCard
          loading={quoteLoading}
          hasError={quoteHasError}
          value={operationDetailsReceivables}
          errorComponent={
            <ErrorComponent
              messageTitle="Não foi possível fazer a cotação"
              messageParagraph="Clique no botão para tentar novamente ou corrija a planilha e envie de novo."
            >
              <TryAgainButton onClick={() => getQuote(quoteFileData)} />
            </ErrorComponent>
          }
          fields={buildOperationDetails(
            operationDetailsOverrides,
            operationDetailsOverrides,
            {},
          )}
        />
        <GridTemplate
          actions={
            <RefreshGridButton onClick={() => getQuote(quoteFileData)} />
          }
          gridRef={gridRef}
          showClearFiltersButton
          showExportButton
        >
          <ODataGridGenerator
            gridRef={gridRef}
            grid={quoteGrid}
            dataSource={quoteData}
            loading={quoteLoading}
          />
        </GridTemplate>
        <div className="d-flex justify-content-end gap-3 mt-4 w-100">
          <ResetStepButton className="me-auto" />
          <PreviousStepButton />
          <NextStepButton disabled={quoteData.length < 1} />
        </div>
      </div>
    </>
  );
};
