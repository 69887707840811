import { ODataGridGeneratorConfig } from "components/data-grid";
import { service } from "services";

export const proposalPendenciesGrid = {
  datagrid: {
    noDataText: "Nenhuma pendência encontrada.",
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "pendencyId",
      caption: "Id",
      sortOrder: "desc",
    },
    {
      caption: "Tipo",
      dataField: "pendencyType",
    },
    {
      caption: "Orientação",
      dataField: "orientation",
    },
    {
      caption: "Descrição",
      dataField: "description",
    },
    {
      caption: "Origem",
      dataField: "origin",
    },
  ],
} satisfies ODataGridGeneratorConfig<
  Awaited<
    ReturnType<typeof service.b2bAgro.getProposalPendencies>
  >["data"]["data"][number]
>;
