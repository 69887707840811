import { isAxiosError } from "axios";

export const getValidationMessages = (err: unknown) => {
  if (isAxiosError<HubEnergy.ValidationErrorResponse>(err)) {
    const errorType = err.response?.data.Response?.ErrorType;

    if (
      errorType === "ValidationException" &&
      err.response?.data.Response?.Failures
    ) {
      return err.response.data.Response.Failures;
    }
  }

  return null;
};
