import { modalManager, OToastManager } from "@maestro/core";
import { OConfirmationModal, OTypography } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { getValidationMessages } from "../../../../../../utils";
import { useStepAnalysisContext } from "../../step-analysis.context";

const modalId = "submit-analysis-confirmation-modal";

export const SubmitAnalysisButton = () => {
  const { stepAnalysis, setStepAnalysis, refetch } = useStepAnalysisContext();

  const { callService, loading } = useServiceCall(
    service.hubEnergy.stepAnalysisSubmit,
  );

  const canSubmit = useCallback(() => {
    const allPendencies = [
      ...stepAnalysis.stepPendencies,
      ...stepAnalysis.propertiesWithPendencies.flatMap((p) => p.pendencies),
    ];

    if (
      (stepAnalysis.level === "BPO" &&
        allPendencies.some((p) => p.status === "PENDENTE_BPO")) ||
      (stepAnalysis.level === "OPS" &&
        allPendencies.some((p) => p.status === "PENDENTE_OPS"))
    ) {
      OToastManager.warning("Algumas pendências ainda não foram analisadas");
      return false;
    }

    return true;
  }, [
    stepAnalysis.level,
    stepAnalysis.propertiesWithPendencies,
    stepAnalysis.stepPendencies,
  ]);

  const concludePendency = useCallback(async () => {
    const { success, response, error } = await callService(stepAnalysis.id);

    if (success) {
      OToastManager.success("Análise enviada com sucesso");
      setStepAnalysis(response.data.response);
      refetch();
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0].ErrorMessage ??
          "Erro ao enviar a análise",
      );
    }
  }, [callService, refetch, setStepAnalysis, stepAnalysis.id]);

  return (
    <>
      <LoadingButton
        loading={loading}
        onClick={() => modalManager.show(modalId)}
      >
        Enviar
      </LoadingButton>
      <OConfirmationModal
        modalId={modalId}
        title="Enviar análise"
        divider={false}
        onConfirm={() => {
          if (canSubmit()) concludePendency();
          modalManager.hide(modalId);
        }}
        className="position-absolute"
      >
        <OTypography>Tem certeza que deseja enviar a análise?</OTypography>
      </OConfirmationModal>
    </>
  );
};
