import { client } from "../client";
import { hubEnergyEndpoints } from "./hubenergy.endpoints";

export const hubEnergyService = {
  getEntity(params?: { companyTaxId?: string }) {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.AdminEntityDetailsResponse>
    >(hubEnergyEndpoints.api.admin.entities, { params });
  },
  getCommonDomain() {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.AdminCommonDomainResponse[]>
    >(hubEnergyEndpoints.api.admin.common._);
  },
  postCommonDomain(body: HubEnergy.AdminUpsertCommonDomainCommand) {
    return client.post(hubEnergyEndpoints.api.admin.common._, body);
  },
  deleteCommonDomain(group: string, key: string) {
    return client.delete(
      hubEnergyEndpoints.api.admin.common.byGroup.byKey(group, key),
    );
  },
  getDistributors() {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.DistributorResponse[]>
    >(hubEnergyEndpoints.api.distributors._);
  },
  updateDistributors(body: HubEnergy.AdminRecommendToDistributorsCommand) {
    return client.put<
      HubEnergy.ApiBaseResponse<HubEnergy.DistributorSimpleResponse[]>
    >(
      hubEnergyEndpoints.api.admin.adequacyOptions.recommendToDistributors,
      body,
    );
  },
  getDistributorDetails(distributorId: number | string) {
    return client.get<HubEnergy.ApiBaseResponse<HubEnergy.DistributorResponse>>(
      hubEnergyEndpoints.api.distributors.byDistributorId(distributorId),
    );
  },
  createDistributor(body: HubEnergy.CreateDistributorCommand) {
    return client.post(hubEnergyEndpoints.api.distributors._, body);
  },
  createDistributors(body: FormData) {
    return client.post(hubEnergyEndpoints.api.distributors.batch, body);
  },
  updateDistributor(body: HubEnergy.UpdateDistributorCommand) {
    return client.patch(hubEnergyEndpoints.api.distributors._, body);
  },
  updateDistributorConfig(body: HubEnergy.AdminUpdateDistributorConfigCommand) {
    return client.put(hubEnergyEndpoints.api.admin.distributors.config, body);
  },
  getDistributorAdequacyOptions(byDistributorId: number | string) {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.AdequacyOptionResponse[]>
    >(
      hubEnergyEndpoints.api.admin.distributors.byDistributorId.adequacyOptions(
        byDistributorId,
      ),
    );
  },
  updateDistributorAdequacyOptions(
    body: HubEnergy.AdminUpdateDistributorAdequacyOptionCommand,
  ) {
    return client.patch(
      hubEnergyEndpoints.api.admin.distributors.adequacyOptions,
      body,
    );
  },
  getDistributorDiligenceConfig(distributorId: number | string) {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceConfigResponse>
    >(
      hubEnergyEndpoints.api.admin.distributors.byDistributorId.diligenceConfig(
        distributorId,
      ),
    );
  },
  updateDistributorDiligenceConfig(
    body: HubEnergy.AdminUpsertDistributorDiligenceConfigCommand,
  ) {
    return client.put(
      hubEnergyEndpoints.api.admin.distributors.diligenceConfig,
      body,
    );
  },
  getConsumerUnitsMonitor() {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.AdminConsumerUnitMonitor[]>
    >(hubEnergyEndpoints.api.admin.customers.consumerUnitsMonitor);
  },
  getConsumerUnitDetails(consumerUnitId: number | string) {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.ConsumerUnitDetailsResponse>
    >(
      hubEnergyEndpoints.api.admin.customers.consumerUnits.byConsumerUnitId(
        consumerUnitId,
      ),
    );
  },
  updateConsumerUnit(body: HubEnergy.AdminUpdateConsumerUnitCommand) {
    return client.put<
      HubEnergy.ApiBaseResponse<HubEnergy.ConsumerUnitResponse>
    >(hubEnergyEndpoints.api.admin.customers.consumerUnits._, body);
  },
  getCustomerContacts(customerId: string | number) {
    return client.get<HubEnergy.ApiBaseResponse<HubEnergy.ContactResponse[]>>(
      hubEnergyEndpoints.contacts.customer.byCustomerId(customerId),
    );
  },
  removeCustomerContact(
    contactId: string | number,
    customerId: string | number,
  ) {
    return client.delete(
      hubEnergyEndpoints.api.admin.contacts.byContactId.customer.byCustomerId(
        contactId,
        customerId,
      ),
    );
  },
  getTradeDetails(tradeId: string | number) {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.TradeDetailsResponse>
    >(hubEnergyEndpoints.api.admin.trades.byTradeId._(tradeId));
  },
  getTradeGuarantors(tradeId: string | number) {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.TradeGuarantorsResponse>
    >(hubEnergyEndpoints.api.admin.trades.byTradeId.guarantors(tradeId));
  },
  generateContract(body: HubEnergy.GenerateTradeContractCommand) {
    return client.post<HubEnergy.ApiBaseResponse>(
      hubEnergyEndpoints.api.admin.trades.generateContract,
      body,
    );
  },
  generateMigrationDocuments(
    body: HubEnergy.GenerateTradeConsumerUnitDocumentsCommand,
  ) {
    return client.post<HubEnergy.ApiBaseResponse>(
      hubEnergyEndpoints.api.admin.trades.generateMigrationDocuments,
      body,
    );
  },
  updateGuarantorMaritalStatus(body: HubEnergy.SetGuarantorMaritalDataCommand) {
    return client.patch<HubEnergy.ApiBaseResponse<HubEnergy.GuarantorResponse>>(
      hubEnergyEndpoints.api.admin.guarantors.maritalStatus,
      body,
    );
  },
  recreateTrade(body: HubEnergy.RecreateTradeCommand) {
    return client.post<HubEnergy.ApiBaseResponse>(
      hubEnergyEndpoints.api.admin.trades.recreate,
      body,
    );
  },
  getTradePossibleDocuments(tradeId: string | number) {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.PossibleDocumentResponse[]>
    >(hubEnergyEndpoints.api.admin.trades.byTradeId.possibleDocuments(tradeId));
  },
  createTradeDocuments(body: HubEnergy.CreateTradeDocumentsCommand) {
    return client.post<HubEnergy.ApiBaseResponse<boolean>>(
      hubEnergyEndpoints.api.admin.trades.createDocuments,
      body,
    );
  },
  previewContract(body: HubEnergy.PreviewTradeContractCommand) {
    return client.post<HubEnergy.ApiBaseResponse<string>>(
      hubEnergyEndpoints.api.admin.trades.previewContract,
      body,
    );
  },
  previewMigrationDocument(
    body: HubEnergy.PreviewTradeMigrationDocumentCommand,
  ) {
    return client.post<HubEnergy.ApiBaseResponse<string>>(
      hubEnergyEndpoints.api.admin.trades.previewMigrationDocument,
      body,
    );
  },
  reserveOperation(body: HubEnergy.ReserveOperationCommand) {
    return client.post(
      hubEnergyEndpoints.api.admin.trades.reserveOperation,
      body,
    );
  },
  createOperation(body: HubEnergy.CreateOperationCommand) {
    return client.post(
      hubEnergyEndpoints.api.admin.trades.createOperation,
      body,
    );
  },
  updateTradeStatus(body: HubEnergy.AdminUpdateTradeStatusCommand) {
    return client.put(hubEnergyEndpoints.api.admin.trades.status, body);
  },
  getDocumentTypes() {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.DocumentTypeResponse[]>
    >(hubEnergyEndpoints.api.admin.documents.documentTypes._);
  },
  postDocumentTypes(body: HubEnergy.AdminUpsertDocumentTypeCommand) {
    return client.post(
      hubEnergyEndpoints.api.admin.documents.documentTypes._,
      body,
    );
  },
  getDocumentTypesById(documentTypeId: number | string) {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.DocumentTypeResponse>
    >(
      hubEnergyEndpoints.api.admin.documents.documentTypes.byDocumentTypeId(
        documentTypeId,
      ),
    );
  },
  deleteDocumentTypesById(documentTypeId: number | string) {
    return client.delete<HubEnergy.ApiBaseResponse>(
      hubEnergyEndpoints.api.admin.documents.documentTypes.byDocumentTypeId(
        documentTypeId,
      ),
    );
  },
  getAdequacyOptions() {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.AdequacyOptionResponse[]>
    >(hubEnergyEndpoints.api.admin.adequacyOptions._);
  },
  updateAdequacyOptions(body: HubEnergy.AdminUpdateAdequacyOptionCommand) {
    return client.patch<
      HubEnergy.ApiBaseResponse<HubEnergy.AdequacyOptionResponse>
    >(hubEnergyEndpoints.api.admin.adequacyOptions._, body);
  },
  createAdequacyOptions(body: HubEnergy.AdminCreateAdequacyOptionCommand) {
    return client.post(hubEnergyEndpoints.api.admin.adequacyOptions._, body);
  },
  getAdequacyOptionsById(adequacyOptionId: number | string) {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.AdequacyOptionResponse>
    >(
      hubEnergyEndpoints.api.admin.adequacyOptions.byAdequacyOptionId._(
        adequacyOptionId,
      ),
    );
  },
  inactiveAdequacyOptionsById(id: number | string) {
    return client.delete(
      hubEnergyEndpoints.api.admin.adequacyOptions.byAdequacyOptionId._(id),
    );
  },
  getChannelDiligenceConsumerUnit(consumerUnitId: number | string) {
    return client.get<HubEnergy.ApiBaseResponse<HubEnergy.DiligenceResponse>>(
      hubEnergyEndpoints.api.byChannel.diligence.consumerUnit.byConsumerUnitId._(
        "ADMIN",
        consumerUnitId,
      ),
    );
  },
  getChannelDiligenceConsumerUnitByStepType(
    consumerUnitId: number | string,
    stepName: string,
  ) {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepResponse>
    >(
      hubEnergyEndpoints.api.byChannel.diligence.consumerUnit.byConsumerUnitId.byStepName._(
        "ADMIN",
        consumerUnitId,
        stepName,
      ),
    );
  },
  updateDiligenceProperties(
    body: HubEnergy.ChannelUpdateDiligenceStepPropertiesValuesCommand,
  ) {
    return client.patch<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepPropertyResponse[]>
    >(hubEnergyEndpoints.api.byChannel.diligence.properties("ADMIN"), body);
  },
  updateDiligenceMigrationDate(
    body: HubEnergy.AdminUpdateDiligenceMigrationDateCommand,
  ) {
    return client.patch(
      hubEnergyEndpoints.api.admin.diligence.updateMigrationDate,
      body,
    );
  },
  getDiligenceStepPropertyTypes() {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepPropertyTypeResponse[]>
    >(hubEnergyEndpoints.api.admin.diligence.configs.stepPropertyType._);
  },
  updateDiligenceStepPropertyType(
    body: HubEnergy.AdminUpdateDiligenceStepPropertyTypeCommand,
  ) {
    return client.put<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepPropertyTypeResponse>
    >(hubEnergyEndpoints.api.admin.diligence.configs.stepPropertyType._, body);
  },
  createDiligenceStepPropertyType(
    body: HubEnergy.AdminCreateDiligenceStepPropertyTypeCommand,
  ) {
    return client.post<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepPropertyTypeResponse>
    >(hubEnergyEndpoints.api.admin.diligence.configs.stepPropertyType._, body);
  },
  getDiligenceStepPropertyTypeById(stepPropertyTypeId: number | string) {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepPropertyTypeResponse>
    >(
      hubEnergyEndpoints.api.admin.diligence.configs.stepPropertyType.byStepPropertyTypeId(
        stepPropertyTypeId,
      ),
    );
  },
  deleteDiligenceStepPropertyTypeById(stepPropertyTypeId: number | string) {
    return client.delete<HubEnergy.ApiBaseResponse>(
      hubEnergyEndpoints.api.admin.diligence.configs.stepPropertyType.byStepPropertyTypeId(
        stepPropertyTypeId,
      ),
    );
  },
  getDiligenceStepPropertyTypeMasks() {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.EDiligenceStepPropertyMask[]>
    >(hubEnergyEndpoints.api.admin.diligence.configs.stepPropertyType.masks);
  },
  getDiligenceStepType() {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepTypeResponse[]>
    >(hubEnergyEndpoints.api.admin.diligence.configs.stepType._);
  },
  updateDiligenceStepType(body: HubEnergy.AdminUpdateDiligenceStepTypeCommand) {
    return client.put<HubEnergy.ApiBaseResponse>(
      hubEnergyEndpoints.api.admin.diligence.configs.stepType._,
      body,
    );
  },
  createDiligenceStepType(body: HubEnergy.AdminCreateDiligenceStepTypeCommand) {
    return client.post<HubEnergy.ApiBaseResponse>(
      hubEnergyEndpoints.api.admin.diligence.configs.stepType._,
      body,
    );
  },
  getDiligenceStepTypeById(stepTypeId: number | string) {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepTypeResponse>
    >(
      hubEnergyEndpoints.api.admin.diligence.configs.stepType.byStepTypeId(
        stepTypeId,
      ),
    );
  },
  deleteDiligenceStepTypeById(stepTypeId: number | string) {
    return client.delete<HubEnergy.ApiBaseResponse>(
      hubEnergyEndpoints.api.admin.diligence.configs.stepType.byStepTypeId(
        stepTypeId,
      ),
    );
  },
  getDiligenceStepConfig() {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepConfigBaseResponse[]>
    >(hubEnergyEndpoints.api.admin.diligence.configs.stepConfig._);
  },
  getDiligenceStepConfigBase(diligenceStepConfigId: number | string) {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepConfigBaseResponse>
    >(
      hubEnergyEndpoints.api.admin.diligence.configs.stepConfig.byDiligenceStepConfigId.base(
        diligenceStepConfigId,
      ),
    );
  },
  getDiligenceStepPropertyConfigs(diligenceStepConfigId: number | string) {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepPropertyConfigResponse[]>
    >(
      hubEnergyEndpoints.api.admin.diligence.configs.stepConfig.byDiligenceStepConfigId.properties(
        diligenceStepConfigId,
      ),
    );
  },
  getDiligenceStepPendencyConfigs(diligenceStepConfigId: number | string) {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepPendencyConfigResponse[]>
    >(
      hubEnergyEndpoints.api.admin.diligence.configs.stepConfig.byDiligenceStepConfigId.pendencies(
        diligenceStepConfigId,
      ),
    );
  },
  upsertDiligenceStepConfigBase(
    body: HubEnergy.AdminUpsertDiligenceStepConfigBaseCommand,
  ) {
    return client.put<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepConfigBaseResponse>
    >(hubEnergyEndpoints.api.admin.diligence.configs.stepConfig._, body);
  },
  upsertDiligenceStepPropertyConfig(
    body: HubEnergy.AdminUpsertDiligenceStepPropertyConfigCommand,
  ) {
    return client.put<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepPropertyConfigResponse>
    >(
      hubEnergyEndpoints.api.admin.diligence.configs.stepConfig.properties,
      body,
    );
  },
  upsertDiligenceStepPendencyConfig(
    body: HubEnergy.AdminUpsertDiligenceStepPendencyConfigCommand,
  ) {
    return client.put<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepPendencyConfigResponse>
    >(
      hubEnergyEndpoints.api.admin.diligence.configs.stepConfig.pendencies,
      body,
    );
  },
  deleteDiligenceStepConfigById(diligenceStepConfigId: number | string) {
    return client.delete<HubEnergy.ApiBaseResponse>(
      hubEnergyEndpoints.api.admin.diligence.configs.stepConfig.byDiligenceStepConfigId._(
        diligenceStepConfigId,
      ),
    );
  },
  deleteDiligenceStepPropertyConfigById(
    diligenceStepPropertyConfigId: number | string,
  ) {
    return client.delete<HubEnergy.ApiBaseResponse>(
      hubEnergyEndpoints.api.admin.diligence.configs.stepPropertyConfig.byDiligenceStepPropertyConfigId._(
        diligenceStepPropertyConfigId,
      ),
    );
  },
  deleteDiligenceStepPendencyConfigById(
    diligenceStepPendencyConfigId: number | string,
  ) {
    return client.delete<HubEnergy.ApiBaseResponse>(
      hubEnergyEndpoints.api.admin.diligence.configs.stepPendencyConfig.byDiligenceStepPendencyConfigId(
        diligenceStepPendencyConfigId,
      ),
    );
  },
  getDiligenceStepPendencyTypes() {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepPendencyTypeResponse[]>
    >(hubEnergyEndpoints.api.admin.diligence.configs.stepPendencyType._);
  },
  updateDiligenceStepPendencyType(
    body: HubEnergy.AdminUpdateDiligenceStepPendencyTypeCommand,
  ) {
    return client.put<HubEnergy.ApiBaseResponse>(
      hubEnergyEndpoints.api.admin.diligence.configs.stepPendencyType._,
      body,
    );
  },
  createDiligenceStepPendencyType(
    body: HubEnergy.AdminCreateDiligenceStepPendencyTypeCommand,
  ) {
    return client.post<HubEnergy.ApiBaseResponse>(
      hubEnergyEndpoints.api.admin.diligence.configs.stepPendencyType._,
      body,
    );
  },
  getDiligenceStepPendencyTypeById(stepPendencyTypeId: number | string) {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepPendencyTypeResponse>
    >(
      hubEnergyEndpoints.api.admin.diligence.configs.stepPendencyType.byStepPendencyTypeId(
        stepPendencyTypeId,
      ),
    );
  },
  deleteDiligenceStepPendencyTypeById(stepPendencyTypeId: number | string) {
    return client.delete<HubEnergy.ApiBaseResponse>(
      hubEnergyEndpoints.api.admin.diligence.configs.stepPendencyType.byStepPendencyTypeId(
        stepPendencyTypeId,
      ),
    );
  },
  updateDiligenceConsumerUnitByStepType(
    body: HubEnergy.AdminUpdateDiligenceStepCommand,
  ) {
    return client.patch<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepResponse>
    >(hubEnergyEndpoints.api.admin.diligence.step._, body);
  },
  addDiligenceStepProperties(
    body: HubEnergy.AdminAddDiligenceStepPropertyCommand,
  ) {
    return client.post(
      hubEnergyEndpoints.api.admin.diligence.step.properties,
      body,
    );
  },
  editStepProperty(body: HubEnergy.AdminUpdateDiligenceStepPropertyCommand) {
    return client.patch(
      hubEnergyEndpoints.api.admin.diligence.stepProperty._,
      body,
    );
  },
  moveStepProperty(body: HubEnergy.AdminMoveDiligenceStepPropertyCommand) {
    return client.put(
      hubEnergyEndpoints.api.admin.diligence.moveStepProperty,
      body,
    );
  },
  getDiligenceStepById(diligenceStepId: number | string) {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepSimpleResponse>
    >(
      hubEnergyEndpoints.api.admin.diligence.step.byDiligenceStepId._(
        diligenceStepId,
      ),
    );
  },
  getDiligenceStepByIdProperties(diligenceStepId: number | string) {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepPropertyResponse[]>
    >(
      hubEnergyEndpoints.api.admin.diligence.step.byDiligenceStepId.properties(
        diligenceStepId,
      ),
    );
  },
  getDiligenceStepByIdPendencies(diligenceStepId: number | string) {
    return client.get<
      HubEnergy.ApiBaseResponse<
        HubEnergy.DiligenceStepPendencyWithPropertyResponse[]
      >
    >(
      hubEnergyEndpoints.api.admin.diligence.step.byDiligenceStepId.pendencies(
        diligenceStepId,
      ),
    );
  },
  patchDiligencePropertyValue(
    body: HubEnergy.AdminUpdateDiligenceStepPropertyValueCommand,
  ) {
    return client.patch<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepPropertyResponse>
    >(hubEnergyEndpoints.api.admin.diligence.property.value, body);
  },
  addDiligenceStepPendency(
    body: HubEnergy.AdminCreateDiligenceStepPendencyCommand,
  ) {
    return client.post(
      hubEnergyEndpoints.api.admin.diligence.stepPendency._,
      body,
    );
  },
  updateDiligenceStepPendency(
    body: HubEnergy.AdminUpdateDiligenceStepPendencyCommand,
  ) {
    return client.patch(
      hubEnergyEndpoints.api.admin.diligence.stepPendency._,
      body,
    );
  },
  addPropertyDocumentTemplate(
    body: HubEnergy.AdminCreateDiligenceStepPropertyDocumentTemplateCommand,
  ) {
    return client.post<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepPropertyResponse>
    >(
      hubEnergyEndpoints.api.admin.diligence.stepProperty.documentTemplate,
      body,
    );
  },
  removePropertyDocumentTemplate(diligenceStepPropertyId: number | string) {
    return client.delete(
      hubEnergyEndpoints.api.admin.diligence.stepProperty.byDiligenceStepPropertyId.documentTemplate(
        diligenceStepPropertyId,
      ),
    );
  },
  addPropertyConfigDocumentTemplate(
    body: HubEnergy.AdminCreateDiligenceStepPropertyConfigDocumentTemplateCommand,
  ) {
    return client.post<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepPropertyConfigResponse>
    >(
      hubEnergyEndpoints.api.admin.diligence.configs.stepPropertyConfig
        .documentTemplate,
      body,
    );
  },
  removePropertyConfigDocumentTemplate(
    diligenceStepPropertyConfigId: number | string,
  ) {
    return client.delete(
      hubEnergyEndpoints.api.admin.diligence.configs.stepPropertyConfig.byDiligenceStepPropertyConfigId.documentTemplate(
        diligenceStepPropertyConfigId,
      ),
    );
  },
  getChannelFile(params: HubEnergy.ChannelDownloadFileCommand) {
    return client.get<HubEnergy.ApiBaseResponse<string>>(
      hubEnergyEndpoints.api.byChannel.file.download("ADMIN"),
      {
        params,
      },
    );
  },
  removeChannelFile(params: HubEnergy.ChannelRemoveFileCommand) {
    return client.delete<HubEnergy.ApiBaseResponse>(
      hubEnergyEndpoints.api.byChannel.file.remove("ADMIN"),
      {
        params,
      },
    );
  },
  uploadChannelFile(documentId: number | string, file: File) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("documentId", String(documentId));

    return client.post<
      HubEnergy.ApiBaseResponse<HubEnergy.DocumentFileResponse>
    >(hubEnergyEndpoints.api.byChannel.file.upload("ADMIN"), formData);
  },
  upsertPendencyNote(
    body: HubEnergy.AdminUpsertDiligenceStepPendencyNoteCommand,
  ) {
    return client.put<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepPendencyNoteSimpleResponse>
    >(hubEnergyEndpoints.api.admin.diligence.stepPendency.notes._, body);
  },
  removePendencyNote(noteId: string | number) {
    return client.delete(
      hubEnergyEndpoints.api.admin.diligence.stepPendency.notes.byNoteId(
        noteId,
      ),
    );
  },
  removeProperty(propertyId: number | string) {
    return client.delete(
      hubEnergyEndpoints.api.admin.diligence.stepProperty.byDiligenceStepPropertyId._(
        propertyId,
      ),
    );
  },
  removePendency(pendencyId: number | string) {
    return client.delete(
      hubEnergyEndpoints.api.admin.diligence.stepPendency.byDiligenceStepPendencyId(
        pendencyId,
      ),
    );
  },
  approveDiligenceStart(body: HubEnergy.AdminApproveDiligenceStartCommand) {
    return client.put(
      hubEnergyEndpoints.api.admin.diligence.approveStart,
      body,
    );
  },
  approveDiligenceConclusion(
    body: HubEnergy.AdminApproveDiligenceConclusionCommand,
  ) {
    return client.put(
      hubEnergyEndpoints.api.admin.diligence.approveConclusion,
      body,
    );
  },
  approveDiligenceStep(diligenceStepId: string | number) {
    return client.put(
      hubEnergyEndpoints.api.admin.diligence.step.byDiligenceStepId.approve(
        diligenceStepId,
      ),
    );
  },
  concludeDiligenceStep(diligenceStepId: string | number) {
    return client.put(
      hubEnergyEndpoints.api.admin.diligence.step.byDiligenceStepId.conclude(
        diligenceStepId,
      ),
    );
  },
  requestCorrectionDiligenceStep(diligenceStepId: string | number) {
    return client.put(
      hubEnergyEndpoints.api.admin.diligence.step.byDiligenceStepId.requestCorrection(
        diligenceStepId,
      ),
    );
  },
  listDiligenceStepStatuses() {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.EDiligenceStepStatus[]>
    >(hubEnergyEndpoints.api.admin.diligence.step.status);
  },
  forceRecreateDiligence(consumerUnitId: number | string) {
    return client.delete(
      hubEnergyEndpoints.api.admin.diligence.consumerUnits.byConsumerUnitId.forceRecreateDiligence(
        consumerUnitId,
      ),
    );
  },
  addAdequatorContact(body: HubEnergy.AdminCreateAdequacyOptionContactCommand) {
    return client.post<HubEnergy.ApiBaseResponse<HubEnergy.ContactResponse[]>>(
      hubEnergyEndpoints.api.admin.contacts.adequacyOption,
      body,
    );
  },
  updateAdequatorContact(
    body: HubEnergy.AdminUpdateAdequacyOptionContactCommand,
  ) {
    return client.patch<HubEnergy.ApiBaseResponse<HubEnergy.ContactResponse[]>>(
      hubEnergyEndpoints.api.admin.contacts.adequacyOption,
      body,
    );
  },
  addCustomerContact(body: HubEnergy.AdminCreateCustomerContactCommand) {
    return client.post<HubEnergy.ApiBaseResponse<HubEnergy.ContactResponse[]>>(
      hubEnergyEndpoints.api.admin.contacts.customer,
      body,
    );
  },
  updateCustomerContact(body: HubEnergy.AdminUpdateCustomerContactCommand) {
    return client.patch<HubEnergy.ApiBaseResponse<HubEnergy.ContactResponse[]>>(
      hubEnergyEndpoints.api.admin.contacts.customer,
      body,
    );
  },
  addDistributorContact(body: HubEnergy.AdminCreateDistributorContactCommand) {
    return client.post<HubEnergy.ApiBaseResponse<HubEnergy.ContactResponse[]>>(
      hubEnergyEndpoints.api.admin.contacts.distributor,
      body,
    );
  },
  updateDistributorContact(
    body: HubEnergy.AdminUpdateDistributorContactCommand,
  ) {
    return client.patch<HubEnergy.ApiBaseResponse<HubEnergy.ContactResponse[]>>(
      hubEnergyEndpoints.api.admin.contacts.distributor,
      body,
    );
  },
  removeAdequatorContact(
    contactId: string | number,
    adequatorId: string | number,
  ) {
    return client.delete(
      hubEnergyEndpoints.api.admin.contacts.byContactId.adequacyOption.byAdequacyOptionId(
        contactId,
        adequatorId,
      ),
    );
  },
  addStepToDiligence(body: HubEnergy.AdminAddDiligenceStepCommand) {
    return client.put<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepResponse>
    >(hubEnergyEndpoints.api.admin.diligence.addStep, body);
  },
  upsertBill(body: HubEnergy.AdminUpsertPropertyDiligenceBillCommand) {
    return client.put<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepPropertyResponse>
    >(hubEnergyEndpoints.api.admin.diligence.property.bills._, body);
  },
  deleteBill(diligenceBillId: string | number) {
    return client.delete<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepPropertyResponse>
    >(
      hubEnergyEndpoints.api.admin.diligence.property.bills.byDiligenceBillId(
        diligenceBillId,
      ),
    );
  },
  updatePendencyStatus(
    body: HubEnergy.AdminUpdateDiligenceStepPendencyStatusCommand,
  ) {
    return client.patch<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepPendencyResponse>
    >(hubEnergyEndpoints.api.admin.diligence.stepPendency.status, body);
  },
  getCurrentDistributors(adequacyOptionId: number | string) {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.DistributorSimpleResponse[]>
    >(
      hubEnergyEndpoints.api.admin.adequacyOptions.byAdequacyOptionId.distributors(
        adequacyOptionId,
      ),
    );
  },
  sendPendingSignatureNotification(
    body: HubEnergy.TradePendingSignatureSignatoriesNotificationCommand,
  ) {
    return client.post(
      hubEnergyEndpoints.api.admin.trades
        .tradePendingSignatureSignatoriesNotification,
      body,
    );
  },
  getDiligenceDocuments(params: {
    consumerUnitId?: number | string;
    diligenceId?: number | string;
  }) {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligencePropertyWithStepResponse[]>
    >(hubEnergyEndpoints.api.admin.diligence.documents, { params });
  },
  moveFile(body: HubEnergy.AdminMoveDocumentFileCommand) {
    return client.put(hubEnergyEndpoints.api.admin.documents.moveFile, body);
  },
  sendDocumentToPaperclip(body: HubEnergy.AdminSendToPaperclipCommand) {
    return client.put(
      hubEnergyEndpoints.api.admin.documents.sendToPaperclip,
      body,
    );
  },
  parseConsumerUnitsImportDataSpreadsheet(body: FormData) {
    return client.post<
      HubEnergy.ApiBaseResponse<HubEnergy.LegacyImport.ParseSpreadsheetResponse>
    >(hubEnergyEndpoints.api.admin.importConsumerUnits.parseSpreadsheet, body);
  },
  getConsumerUnitsImportDataList() {
    return client.get<
      HubEnergy.ApiBaseResponse<
        HubEnergy.LegacyImport.ConsumerUnitsImportDataListItem[]
      >
    >(hubEnergyEndpoints.api.admin.importConsumerUnits._);
  },
  getConsumerUnitsImportDataDetails(
    consumerUnitsImportDataId: string | number,
  ) {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.LegacyImport.ConsumerUnitsImportDataResponse>
    >(
      hubEnergyEndpoints.api.admin.importConsumerUnits.byConsumerUnitsImportDataId._(
        consumerUnitsImportDataId,
      ),
    );
  },
  updateConsumerUnitsImportData(
    body: HubEnergy.LegacyImport.UpdateConsumerUnitsImportDataCommand,
  ) {
    return client.put<
      HubEnergy.ApiBaseResponse<HubEnergy.LegacyImport.ConsumerUnitsImportDataResponse>
    >(hubEnergyEndpoints.api.admin.importConsumerUnits._, body);
  },
  reprocessConsumerUnitsImportData(consumerUnitsImportDataId: string | number) {
    return client.put(
      hubEnergyEndpoints.api.admin.importConsumerUnits.byConsumerUnitsImportDataId.reprocess(
        consumerUnitsImportDataId,
      ),
    );
  },
  deleteConsumerUnitsImportData(consumerUnitsImportDataId: string | number) {
    return client.delete(
      hubEnergyEndpoints.api.admin.importConsumerUnits.byConsumerUnitsImportDataId._(
        consumerUnitsImportDataId,
      ),
    );
  },
  executeConsumerUnitsImport(
    body: HubEnergy.LegacyImport.ExecuteImportCommand,
  ) {
    return client.post<HubEnergy.ApiBaseResponse>(
      hubEnergyEndpoints.api.admin.importConsumerUnits.executeImport,
      body,
    );
  },
  pressForResponseLetterOfComplaint(
    body: HubEnergy.AdminLetterOfComplaintPressForResponseCommand,
  ) {
    return client.put<HubEnergy.ApiBaseResponse>(
      hubEnergyEndpoints.api.admin.diligence.letterOfComplaint.pressForResponse,
      body,
    );
  },
  getDiligenceStepsMonitor() {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepMonitor[]>
    >(hubEnergyEndpoints.api.admin.diligence.stepMonitor);
  },
  updateDiligenceStepStatusBatch(
    body: HubEnergy.AdminUpdateDiligenceStepStatusBatchCommand,
  ) {
    return client.put<HubEnergy.ApiBaseResponse>(
      hubEnergyEndpoints.api.admin.diligence.step.batchStatus,
      body,
    );
  },
  getBillingReportById(billingReportId: number | string) {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.BillingReportResponse>
    >(
      hubEnergyEndpoints.api.byChannel.billingReport.byBillingReportId._(
        "ADMIN",
        billingReportId,
      ),
    );
  },
  getBillingReports() {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.AdminBillingReportResponse[]>
    >(hubEnergyEndpoints.api.admin.billingReports._);
  },
  getBillingReportsByTaxId(taxId: number | string) {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.AdminBillingReportResponse[]>
    >(hubEnergyEndpoints.api.admin.billingReports.byTaxId(taxId));
  },
  notifyInvoicedBillingReport(
    body: HubEnergy.AdminInvoicedBillingReportsNotificationCommand,
  ) {
    return client.post<HubEnergy.ApiBaseResponse>(
      hubEnergyEndpoints.api.admin.billingReports.notifyInvoiced,
      body,
    );
  },
  syncBillingReportWithNesById(billingReportId: number | string) {
    return client.put<HubEnergy.ApiBaseResponse>(
      hubEnergyEndpoints.api.admin.billingReports.syncWithNes.byBillingReportId(
        billingReportId,
      ),
    );
  },
  getInvoicesByBillingReportId(billingReportId: number | string) {
    return client.get<HubEnergy.ApiBaseResponse<HubEnergy.InvoiceResponse[]>>(
      hubEnergyEndpoints.api.byChannel.billingReport.byBillingReportId.invoices(
        "ADMIN",
        billingReportId,
      ),
    );
  },
  getDiligenceEventRules() {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceEventRuleResponse[]>
    >(hubEnergyEndpoints.api.admin.diligence.event.rule._);
  },
  getDiligenceEventRule(diligenceEventRuleId: string | number) {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceEventRuleResponse>
    >(
      hubEnergyEndpoints.api.admin.diligence.event.rule.byDiligenceEventRuleId(
        diligenceEventRuleId,
      ),
    );
  },
  createDiligenceEventRule(
    body: HubEnergy.AdminCreateDiligenceEventRuleCommand,
  ) {
    return client.post<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceEventRuleSimpleResponse>
    >(hubEnergyEndpoints.api.admin.diligence.event.rule._, body);
  },
  updateDiligenceEventRule(
    body: HubEnergy.AdminUpdateDiligenceEventRuleCommand,
  ) {
    return client.put<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceEventRuleSimpleResponse>
    >(hubEnergyEndpoints.api.admin.diligence.event.rule._, body);
  },
  deleteDiligenceEventRule(diligenceEventRuleId: string | number) {
    return client.delete<HubEnergy.ApiBaseResponse>(
      hubEnergyEndpoints.api.admin.diligence.event.rule.byDiligenceEventRuleId(
        diligenceEventRuleId,
      ),
    );
  },
  createDiligenceEventAction(
    body: HubEnergy.AdminCreateDiligenceEventActionCommand,
  ) {
    return client.post<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceEventActionSimpleResponse>
    >(hubEnergyEndpoints.api.admin.diligence.event.action._, body);
  },
  updateDiligenceEventAction(
    body: HubEnergy.AdminUpdateDiligenceEventActionCommand,
  ) {
    return client.put<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceEventActionSimpleResponse>
    >(hubEnergyEndpoints.api.admin.diligence.event.action._, body);
  },
  deleteDiligenceEventAction(diligenceEventActionId: string | number) {
    return client.delete<HubEnergy.ApiBaseResponse>(
      hubEnergyEndpoints.api.admin.diligence.event.action.byDiligenceEventActionId(
        diligenceEventActionId,
      ),
    );
  },
  executeAutomation(body: HubEnergy.AdminStepAnalysisExecuteAutomationCommand) {
    return client.post<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepAnalysisResponse>
    >(
      hubEnergyEndpoints.api.admin.diligenceStepAnalysis.executeAutomation,
      body,
    );
  },
  getStepAnalysisSummary() {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepAnalysisSummaryResponse>
    >(hubEnergyEndpoints.api.admin.diligenceStepAnalysis.summary);
  },
  getNextStepAnalysis() {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepAnalysisResponse>
    >(hubEnergyEndpoints.api.admin.diligenceStepAnalysis.next);
  },
  stepAnalysisConcludePendency(
    body: HubEnergy.AdminStepAnalysisConcludePendencyCommand,
  ) {
    return client.post<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepAnalysisResponse>
    >(
      hubEnergyEndpoints.api.admin.diligenceStepAnalysis.pendency.conclude,
      body,
    );
  },
  stepAnalysisWaitForDistributor(
    body: HubEnergy.AdminStepAnalysisWaitForDistributorCommand,
  ) {
    return client.post<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepAnalysisResponse>
    >(
      hubEnergyEndpoints.api.admin.diligenceStepAnalysis.pendency
        .waitForDistributor,
      body,
    );
  },
  stepAnalysisEscalatePendency(
    body: HubEnergy.AdminStepAnalysisEscalatePendencyCommand,
  ) {
    return client.post<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepAnalysisResponse>
    >(
      hubEnergyEndpoints.api.admin.diligenceStepAnalysis.pendency.escalate,
      body,
    );
  },
  stepAnalysisCreatePendency(
    body: HubEnergy.AdminStepAnalysisCreatePendencyCommand,
  ) {
    return client.post<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepAnalysisResponse>
    >(hubEnergyEndpoints.api.admin.diligenceStepAnalysis.pendency.add, body);
  },
  stepAnalysisSubmit(diligenceStepAnalysisId: number | string) {
    return client.post<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepAnalysisResponse>
    >(
      hubEnergyEndpoints.api.admin.diligenceStepAnalysis.submit.byDiligenceStepAnalysisId(
        diligenceStepAnalysisId,
      ),
    );
  },
  restartPendencyAnalysis(body: HubEnergy.AdminRestartPendencyAnalysisCommand) {
    return client.post<
      HubEnergy.ApiBaseResponse<HubEnergy.DiligenceStepAnalysisResponse>
    >(
      hubEnergyEndpoints.api.admin.diligenceStepAnalysis.pendency
        .restartAnalysis,
      body,
    );
  },
  getAgents() {
    return client.get<HubEnergy.ApiBaseResponse<HubEnergy.AgentResponse[]>>(
      hubEnergyEndpoints.api.admin.agent._,
    );
  },
  upsertAgent(body: HubEnergy.AdminUpsertAgentCommand) {
    return client.put<HubEnergy.ApiBaseResponse<HubEnergy.AgentResponse>>(
      hubEnergyEndpoints.api.admin.agent._,
      body,
    );
  },
  deleteAgent(agentId: number | string) {
    return client.delete<HubEnergy.ApiBaseResponse>(
      hubEnergyEndpoints.api.admin.agent.byAgentId(agentId),
    );
  },
  getConsumerUnitAgents() {
    return client.get<
      HubEnergy.ApiBaseResponse<HubEnergy.ConsumerUnitWithAgentsResponse[]>
    >(hubEnergyEndpoints.api.admin.agent.consumerUnits);
  },
  assignAgentBatch(body: HubEnergy.AdminBatchAssignConsumerUnitAgentCommand) {
    return client.put<
      HubEnergy.ApiBaseResponse<HubEnergy.ConsumerUnitWithAgentsResponse[]>
    >(hubEnergyEndpoints.api.admin.agent.assignBatch, body);
  },
  updateStepWithConfig(body: HubEnergy.UpdateDiligenceStepWithConfigCommand) {
    return client.post<
      HubEnergy.ApiBaseResponse<HubEnergy.UpdateDiligenceStepWithConfigResponse>
    >(hubEnergyEndpoints.api.admin.tools.updateStepWithConfig, body);
  },
};
