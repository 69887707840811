import { OLoader } from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { SelectSearchField } from "components/select-search";
import { useConsumerUnitSelector } from "./consumer-unit-selector.hook";

export const ConsumerUnitSelector = () => {
  const { consumerUnits, getConsumerUnits, hasFetchError, loading } =
    useConsumerUnitSelector();

  return (
    <div className="d-flex align-items-center flex-fill">
      <SelectSearchField
        options={consumerUnits}
        id="_consumerUnitId"
        name="_consumerUnitId"
        label="Unidade consumidora"
        placeholder="Selecionar"
        required
      />

      {loading && <OLoader size="sm" />}
      {hasFetchError && !loading && (
        <RefreshGridButton onClick={getConsumerUnits} />
      )}
    </div>
  );
};
