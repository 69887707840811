import { isAxiosError } from "axios";
import { getValueFromMap } from "utils/get-value-from-map";

const distributorErrorCodeMap = {
  EmptyName: "Necessário informar Nome",
  EmptyOfficialName: "Necessário informar Razão social",
  EmptyTaxId: "Necessário informar CNPJ",
  InvalidTaxId: "CNPJ inválido",
  DistributorWithSameTaxIdAlreadyExists:
    "Já existe uma distribuidora com mesmo CNPJ",
  MustHaveOneMainContact: "Necessáio ter um contato principal",
  EmptyContact: "Necessário informar contato",
  EmptyContactEmail: "Necessário informar email",
  EmptyCommunicationType: "Necessário informar comunicação",
  InvalidCommunicationType: "Comunicação inválida.",
  MustHaveContactWhenCommunicationTypeIsEmail:
    "Necessário informar contato principal para comunicação via email",
  MustHavePortalUrlWhenCommunicationTypeIsPortal:
    "Necessário informar url do portal para comunicação via protal",
  EmptyFile: "Não foi enviado arquivo",
  EmptyDistributorId: "Distribuidora não informada",
  DistributorNotFound: "Distribuidora não encontrada",
  CannotDeleteDistributor: "Distribuidora não pode ser removida",
};

const customerErrorCodeMap = {
  EmptyCustomerGroupName: "Nome do grupo não informado",
  EmptyCustomerList: "Necessário informar pelo menos um cliente",
  CustomerNotFound: "Cliente não encontrado",
  CustomerBelongsToAnotherGroup: "Cliente pertence a outro grupo",
  EmptyCustomerId: "Cliente não informado",
  CannotDeleteCustomer: "Cliente não pode ser removido",
  EmptyConsumerUnitId: "Unidade consumidora não informada",
  ConsumerUnitNotFound: "Unidade consumidora não encontrada",
  CannotRemoveMainConsumerUnit:
    "Não pode remover unidade consumidora principal",
  EmptyCustomerGroupId: "Grupo não informado",
  CustomerGroupNotFound: "Grupo não encontrado",
  ConsumerUnitBelongsToAnotherCustomer:
    "Unidade consumidora pertence a outro cliente",
  ThereMustBeOneMainUnit: "Deve possuir apenas uma unidade principal",
};

const tradeErrorCodeMap = {
  EmptyTradeId: "Proposta não informada",
  TradeNotFound: "Proposta não encontrada",
  InvalidTradeStatus: "Status da proposta inválido",
  ConsumerUnitDontBelongToThisTrade:
    "Unidade consumidora não pertence a essa proposta",
};

const commonDomainErrorCodeMap = {
  InvalidValue: "Valor inválido",
  CommonDomainNotFound: "Variável de configuração não encontrada",
};

/** @deprecated */
export const hubEnergyErrorCodeMap = {
  ...commonDomainErrorCodeMap,
  ...distributorErrorCodeMap,
  ...customerErrorCodeMap,
  ...tradeErrorCodeMap,
};

/** @deprecated */
export const getHubEnergyErrorMessage = (error: unknown) =>
  (isAxiosError<HubEnergy.ValidationErrorResponse>(error)
    ? error?.response?.data?.Response?.Failures?.map((failure) => {
        const message = getValueFromMap(
          hubEnergyErrorCodeMap,
          failure.ErrorCode,
        );
        return message ? [message] : [];
      }).join(", ")
    : null) || "Ocorreu um erro";
