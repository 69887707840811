import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { getValidationMessages } from "../../../../../utils";

export const useGridActions = (
  importData: HubEnergy.LegacyImport.ConsumerUnitsImportDataListItem,
  reload: () => void,
) => {
  const { callService: _deleteImportData, loading: deleteLoading } =
    useServiceCall(service.hubEnergy.deleteConsumerUnitsImportData);

  const { callService: _reprocessImportData, loading: reprocessLoading } =
    useServiceCall(service.hubEnergy.reprocessConsumerUnitsImportData);

  const { callService: _executeImport, loading: executeLoading } =
    useServiceCall(service.hubEnergy.executeConsumerUnitsImport);

  const deleteImportData = useCallback(async () => {
    const { success, error } = await _deleteImportData(importData.id);

    if (success) {
      OToastManager.success("Importação excluída com sucesso");
      reload();
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0]?.ErrorMessage ??
          "Erro ao excluir a importação",
      );
    }
  }, [_deleteImportData, importData.id, reload]);

  const reprocessImportData = useCallback(async () => {
    const { success, error } = await _reprocessImportData(importData.id);

    if (success) {
      OToastManager.success("Importação será reprocessada");
      reload();
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0]?.ErrorMessage ??
          "Erro ao reprocessar a importação",
      );
    }
  }, [_reprocessImportData, importData.id, reload]);

  const executeImport = useCallback(async () => {
    const { error, response, success } = await _executeImport({
      consumerUnitsImportDataId: importData.id,
    });

    if (success && response.data.success) {
      OToastManager.success("Importação foi executada");
      reload();
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0]?.ErrorMessage ??
          "Erro ao executar a importação",
      );
    }
  }, [_executeImport, importData.id, reload]);

  return {
    deleteImportData,
    executeImport,
    reprocessImportData,
    loading: deleteLoading || executeLoading || reprocessLoading,
  };
};
