import { dateOnly } from "../../../../../../utils";
import { checkboxValue, ConsumerUnitForm } from "./edit-consumer-unit.form";

export const mapResponseToForm = (
  consumerUnit: HubEnergy.ConsumerUnitDetailsResponse,
): ConsumerUnitForm =>
  ({
    address: {
      city: consumerUnit.address?.city,
      complement: consumerUnit.address?.complement,
      neighborhood: consumerUnit.address?.neighborhood,
      number: consumerUnit.address?.number,
      state: consumerUnit.address?.state,
      street: consumerUnit.address?.street,
      zipCode: consumerUnit.address?.zipCode,
      country: consumerUnit.address?.country,
    },
    externalId: consumerUnit.externalId,
    alias: consumerUnit.name,
    ccer: consumerUnit.ccer,
    clientNumber: consumerUnit.clientNumber,
    demand: consumerUnit.demand,
    distributorContractExpirationDate:
      consumerUnit.distributorContractExpirationDate,
    currentDistributorId: consumerUnit.currentDistributorId,
    fareGroup: consumerUnit.fareGroup,
    installationNumber: consumerUnit.installationNumber,
    invoiceAmount: consumerUnit.invoiceAmount,
    isMainUnit: consumerUnit.isMainUnit ? [checkboxValue] : [],
    migrationDate: consumerUnit.migrationDate,
    minimumPeakAllocation: consumerUnit.minimumPeakAllocation,
    modality: consumerUnit.modality,
    entityName: consumerUnit.entity?.name,
    entityOfficialName: consumerUnit.entity?.officialName,
    offPeakDemand: consumerUnit.offPeakDemand,
    peakDemand: consumerUnit.peakDemand,
    reportingDate: consumerUnit.reportingDate,
    supplyEndDate: consumerUnit.supplyEndDate,
    taxId: consumerUnit.entity?.taxId,
    volume: consumerUnit.volume,
    volumePerHour: consumerUnit.volumePerHour,
  } as ConsumerUnitForm);

export const mapFormToRequest = (
  values: ConsumerUnitForm,
): Omit<HubEnergy.AdminUpdateConsumerUnitCommand, "consumerUnitId"> => ({
  address: values.address && {
    city: values.address.city,
    complement: values.address.complement,
    country: "BRASIL",
    neighborhood: values.address.neighborhood,
    number: values.address.number,
    state: values.address.state,
    street: values.address.street,
    zipCode: values.address.zipCode,
  },
  externalId: values.externalId,
  ccer: values.ccer,
  clientNumber: values.clientNumber,
  demand: values.demand,
  distributorContractExpirationDate: dateOnly(
    values.distributorContractExpirationDate,
  ),
  fareGroup: values.fareGroup,
  installationNumber: values.installationNumber,
  isMainUnit: values.isMainUnit.includes(checkboxValue),
  migrationDate: dateOnly(values.migrationDate),
  minimumPeakAllocation: values.minimumPeakAllocation,
  modality: values.modality,
  offPeakDemand: values.offPeakDemand,
  peakDemand: values.peakDemand,
  reportingDate: dateOnly(values.reportingDate),
  supplyEndDate: dateOnly(values.supplyEndDate),
  volume: values.volume,
  volumePerHour: values.volumePerHour,
  name: values.alias,
  invoiceAmount: values.invoiceAmount,
  entityOfficialName: values.entityOfficialName,
  entityName: values.entityName,
  currentDistributorId: values.currentDistributorId,
});
