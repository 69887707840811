import { yupResolver } from "@hookform/resolvers/yup";
import {
  OCol,
  ORFieldInput,
  ORFieldRadioGroup,
  ORadio,
  OToastManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { PostCountry } from "services/hubfx/models/requests/post-admin-countries.request";
import { PostByIdCountry } from "services/hubfx/models/requests/post-by-id-admin-countries.request";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { countryFormValidationSchema } from "./exchange-product-countries.schemas";
import { ORFieldInputs } from "./exchange-product-countries.utils";

export const ExchangeProductCountriesFormPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { value: country } = useParseFromSearchParams<PostByIdCountry>("data");

  const form = useForm<PostCountry>({
    resolver: yupResolver(countryFormValidationSchema),
  });

  const { handleSubmit, reset } = form;

  useEffect(() => {
    country && reset(country);
  }, [country, reset]);

  const onAdd = useCallback(async (values: PostCountry) => {
    try {
      setLoading(true);
      const payload = { Countries: [values] };

      await service.hubFx.postCountry(payload);

      OToastManager.success("País adicionado com sucesso.");

      navigate(
        corporateRouter.routes.cambio.product.contingency.countries.path,
      );
    } catch {
      OToastManager.danger("Erro ao adicionar país.");
    } finally {
      setLoading(false);
    }
  }, []);

  const onEdit = useCallback(async (values: PostCountry & { id: number }) => {
    try {
      setLoading(true);
      const payload = { Countries: [values] };

      await service.hubFx.patchCountry(payload);

      OToastManager.success("País atualizado com sucesso.");

      navigate(
        corporateRouter.routes.cambio.product.contingency.countries.path,
      );
    } catch {
      OToastManager.danger("Erro ao atualizar país.");
    } finally {
      setLoading(false);
    }
  }, []);

  const onSubmit = useMemo(
    () =>
      handleSubmit((formValues) => {
        if (country?.id) {
          onEdit({ ...formValues, id: country.id });
        } else {
          onAdd(formValues);
        }
      }),
    [country, handleSubmit, onAdd, onEdit],
  );

  return (
    <FormTemplate
      pageTitle={
        <PageTitle title={country ? "Editar País" : "Adicionar País"} />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="paises:botao:adicionar_editar_pais,"
          dataLabel="adicionar_editar_pais,"
          onClick={onSubmit}
        >
          {country ? "Editar" : "Adicionar"}
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          {ORFieldInputs.map((item) => (
            <OCol>
              {item.radioGroup ? (
                <ORFieldRadioGroup
                  label={item.label}
                  name={item.name}
                  id={item.id}
                  dataAction={item.dataAction}
                  dataLabel={item.dataLabel}
                  required={item.required}
                >
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="chk1" value="true" />
                    Sim
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="chk2" value="false" />
                    Não
                  </div>
                </ORFieldRadioGroup>
              ) : (
                <ORFieldInput
                  tag="text"
                  label={item.label}
                  name={item.name}
                  id={item.id}
                  dataAction={item.dataAction}
                  dataLabel={item.dataLabel}
                  required={item.required}
                />
              )}
            </OCol>
          ))}
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
