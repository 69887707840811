import { ODataGridGeneratorConfig } from "components/data-grid";
import { IconButton } from "components/icon-button";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GetSnakeOdataInteractionsOdataResponse } from "services/fidc/models/responses";

const buildDetailsLink = (interactionId: string) => {
  const { routes } = corporateRouter;

  const baseUrl = routes.fidc.product.interactions.details.path;

  const searchParams = new URLSearchParams();
  searchParams.set("interactionId", interactionId);

  return baseUrl + "?" + searchParams.toString();
};

export const interactionsGrid = (
  resend: (interactionId: string) => Promise<void>,
): ODataGridGeneratorConfig<
  GetSnakeOdataInteractionsOdataResponse["value"][number]
> => ({
  datagrid: {
    noDataText: "Nenhuma interação disponível.",
    pager: {
      showInfo: true,
      infoText: "Página {0} de {1} ({2} interações)",
    },
    remoteOperations: true,
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      role: roles.fidc.product.interactionsDetailsPage.role,
      cellRender: ({ data }) => (
        <DetailsLink href={buildDetailsLink(data.companyId)} />
      ),
    },
    {
      dataField: "companyName",
      caption: "Empresa",
    },
    {
      dataField: "companyTaxId",
      caption: "CNPJ",
    },
    {
      dataField: "channel",
      caption: "Canal",
    },
    {
      dataField: "messageType",
      caption: "Tipo",
    },
    {
      dataField: "to",
      caption: "Destinatário",
    },
    {
      dataField: "dateCreated",
      caption: "Data de envio",
      dataType: "date",
      format: "dd/MM/yyyy HH:mm:ss",
    },
    {
      dataField: "content",
      caption: "Conteúdo",
    },
    {
      dataField: "open",
      caption: "Aberto",
      dataType: "date",
      format: "dd/MM/yyyy HH:mm:ss",
    },
    {
      dataField: "bounce",
      caption: "Rejeitado",
      dataType: "date",
      format: "dd/MM/yyyy HH:mm:ss",
    },
    {
      dataField: "sent",
      caption: "Confirmação de envio",
      dataType: "date",
      format: "dd/MM/yyyy HH:mm:ss",
    },
    {
      caption: "Ações",
      cellRender: ({ data }) => (
        <IconButton
          dataAction="interacoes_fidc:botao:reenviar"
          dataLabel="reenviar"
          icon={{
            category: "orq",
            icon: "orq-email",
          }}
          onClick={() => resend(data.id)}
          outline
        >
          Reenviar
        </IconButton>
      ),
    },
  ],
});
