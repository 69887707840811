import { OButton, OTypography, modalManager } from "@maestro/react";
import {
  MasterDetailComponentProps,
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { ContactContext } from "contexts/contact/contact.component";
import { useRoles } from "hooks/roles";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import {
  BankAccountsItem,
  PixItem,
  AddressItem,
  GetContactByContactHolderResponseItem,
} from "services/admin-bankinghub/models";
import { banksList } from "utils/bank-list";
import { ContactsFavoredDeleteModal } from "./_compose";

const contactsBanksDetailsGrid: ODataGridGeneratorConfig<BankAccountsItem> = {
  datagrid: {
    noDataText: "Nenhuma conta encontrada",
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      allowHeaderFiltering: true,
      dataField: "bank",
      caption: "Código de banco",
    },
    {
      allowHeaderFiltering: true,
      dataField: "bank",
      caption: "Banco",
      format: (value) => banksList.find((b) => b.id === value)?.title,
    },
    {
      allowHeaderFiltering: true,
      dataField: "account",
      caption: "Conta",
    },
    {
      allowHeaderFiltering: true,
      dataField: "account_digit",
      caption: "Dígito",
    },
    {
      allowHeaderFiltering: true,
      dataField: "branch",
      caption: "Agência",
    },
    {
      cellRender: ({ data }) => (
        <>
          <DataGridAction
            actions={[
              {
                label: "Excluir",
                icon: { category: "orq", icon: "orq-edit-trash" },
                onClick: () => {
                  modalManager.show("contacts-favored-delete-modal");
                },
              },
            ]}
          />
          <ContactsFavoredDeleteModal uuid={data.id} />
        </>
      ),
    },
  ],
};

const contactsPixDetailsGrid: ODataGridGeneratorConfig<PixItem> = {
  datagrid: {
    noDataText: "Nenhuma chave encontrada",
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      allowHeaderFiltering: true,
      dataField: "key_type",
      caption: "Tipo",
    },
    {
      allowHeaderFiltering: true,
      dataField: "pix_key",
      caption: "Chave",
    },
  ],
};

const contactsAddressDetailsGrid: ODataGridGeneratorConfig<AddressItem> = {
  datagrid: {
    noDataText: "Nenhum endereço encontrado",
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      allowHeaderFiltering: true,
      dataField: "address_street",
      caption: "Rua",
    },
    {
      allowHeaderFiltering: true,
      dataField: "address_number",
      caption: "Número",
    },
    {
      allowHeaderFiltering: true,
      dataField: "address_neighborhood",
      caption: "Bairro",
    },
    {
      allowHeaderFiltering: true,
      dataField: "address_city",
      caption: "Cidade",
    },
    {
      allowHeaderFiltering: true,
      dataField: "address_state_label",
      caption: "Estado",
    },
    {
      allowHeaderFiltering: true,
      dataField: "address_complement",
      caption: "Complemento",
    },
    {
      allowHeaderFiltering: true,
      dataField: "address_zipcode",
      caption: "CEP",
    },
  ],
};

type ContactsMasterDetailProps =
  MasterDetailComponentProps<GetContactByContactHolderResponseItem>;

export const ContactsMasterDetail = ({
  data: { data },
}: ContactsMasterDetailProps) => {
  const { routes } = corporateRouter;
  const { setContact } = useContext(ContactContext);
  const { hasRole } = useRoles();
  const navigate = useNavigate();
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-1">
        <OTypography size="lg">Contas Bancárias</OTypography>
        {hasRole(roles.banking.customer.contactsFavoredCreatePage.role) && (
          <OButton
            onClick={() => {
              navigate(routes.banking.customer.contacts.create.path);
              setContact(data);
            }}
          >
            Criar
          </OButton>
        )}
      </div>
      <ODataGridGenerator
        grid={contactsBanksDetailsGrid}
        dataSource={data.product.bankAccounts}
      />
      <OTypography size="lg" className="mb-1 mt-4">
        Pix
      </OTypography>
      <ODataGridGenerator
        grid={contactsPixDetailsGrid}
        dataSource={data.product.pix}
      />
      <OTypography size="lg" className="mb-1 mt-4">
        Endereços
      </OTypography>
      <ODataGridGenerator
        grid={contactsAddressDetailsGrid}
        dataSource={data.product.address}
      />
    </>
  );
};
