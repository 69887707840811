import { OLoader } from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { SelectSearchField } from "components/select-search";
import { usePendencyTypeSelector } from "./pendency-type-selector.hook";

export const PendencyTypeSelector = () => {
  const { getPendencyTypes, hasFetchError, loading, pendencyTypes } =
    usePendencyTypeSelector();

  return (
    <div className="d-flex align-items-center flex-fill">
      <SelectSearchField
        options={pendencyTypes}
        id="typeId"
        name="typeId"
        label="Tipo da pendência"
        placeholder="Selecionar"
        required
      />

      {loading && <OLoader size="sm" />}
      {hasFetchError && !loading && (
        <RefreshGridButton onClick={getPendencyTypes} />
      )}
    </div>
  );
};
