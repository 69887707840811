import * as yup from "yup";

export type UpdateOriginatorRemunerationFormFields = Pick<
  HubPartners.Admin.NewOriginator,
  "account" | "mirror"
>;

export const updateOriginatorRemunerationValidationSchema: yup.ObjectSchema<UpdateOriginatorRemunerationFormFields> =
  yup.object({
    account: yup.object({
      bank: yup.string().required("Este campo é obrigatório"),
      branch: yup.string().required("Este campo é obrigatório"),
      number: yup.string().required("Este campo é obrigatório"),
    }),
    mirror: yup.object({
      mirrorBook: yup.string(),
      mirrorStrategy: yup.string(),
    }),
  });

export const updateOriginatorRemunerationDefaultValues: UpdateOriginatorRemunerationFormFields =
  {
    account: {
      bank: "",
      branch: "",
      number: "",
    },
    mirror: {
      mirrorBook: undefined,
      mirrorStrategy: undefined,
    },
  };
