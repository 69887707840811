import { client } from "../client";
import { moanaEndpoints } from "./moana.endpoints";
import {
  GetOwnerReceivablesResponse,
  GetOwnerResponse,
  PutLimitChangeBody,
  PutLimitDisableBody,
  MoanaOwnerReceivable,
  GetOwnerTotalsResponse,
} from "./models";
import { CreateBorderoBody } from "./requests/create-bordero.request";
import { PortfolioManagementParams } from "./models/requests/portfolio-management.request";
import { PortfolioManagamentResponse } from "./models/responses/portfolio-management.response";
import { PayersFromOwnerParams } from "./models/requests/payers-from-owner.request";
import { PayerOwnerResponse } from "./models/responses/payer-owner.response";

export const moanaService = {
  getOwner(params: string) {
    return client.get<GetOwnerResponse>(
      `${moanaEndpoints.owner.endpoint}?${params}`,
    );
  },

  getTotals(params: string) {
    return client.get<GetOwnerTotalsResponse>(
      `${moanaEndpoints.owner.totals}?${params}`,
    );
  },

  getOwnerReceivables(params: string) {
    return client.get<GetOwnerReceivablesResponse>(
      `${moanaEndpoints.owner.receivables}?${params}`,
    );
  },

  exportOwnerReceivables(params: string) {
    return client.get<string>(
      `${moanaEndpoints.owner.receivablesExport}?${params}`,
    );
  },

  putLimitChange(body: PutLimitChangeBody) {
    return client.put(moanaEndpoints.limit.change, body);
  },

  putLimitDisable(body: PutLimitDisableBody) {
    return client.put(moanaEndpoints.limit.disable, body);
  },

  exportOwner(params: string) {
    return client.get<string>(`${moanaEndpoints.owner.export}?${params}`);
  },

  createBordero(body: CreateBorderoBody) {
    return client.post<boolean>(moanaEndpoints.bordero.byDocumentNumber, body);
  },

  portfolioManagement(params: PortfolioManagementParams) {
    return client.get<PortfolioManagamentResponse>(
      moanaEndpoints.owner.walletManagement,
      {
        params,
      },
    );
  },

  getPayersFromOwner(params: PayersFromOwnerParams) {
    return client.get<PayerOwnerResponse[]>(
      moanaEndpoints.owner.payersFromOwner,
      {
        params,
      },
    );
  },

  getAllOwners() {
    return client.get<PayerOwnerResponse[]>(moanaEndpoints.owner.getAll);
  },

  getDiscountedPortfolio(params: string) {
    return client.get<MoanaOwnerReceivable[]>(
      `${moanaEndpoints.owner.discountedWallet}?${params}`,
    );
  },

  getOwnWallet(params: string) {
    return client.get<MoanaOwnerReceivable[]>(
      `${moanaEndpoints.owner.ownWallet}?${params}`,
    );
  },

  exportDiscountedWallet(params: string) {
    return client.get<any>(
      `${moanaEndpoints.owner.exportDiscountedWallet}?${params}`,
    );
  },

  exportOwnWallet(params: string) {
    return client.get<any>(`${moanaEndpoints.owner.exportOwnWallet}?${params}`);
  },
};
