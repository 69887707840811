import { OButton, modalManager } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import {
  AddPendencyModal,
  BatchLetterOfComplaintButton,
  addPendencyModalId,
} from "./_compose";
import { dataSource, energyTasksGrid } from "./energy-tasks.grid";

export const EnergyTasksPage = () => {
  const gridRef = useGridRef();

  return (
    <>
      <GridTemplate
        pageTitle={<PageTitle title="Tarefas" />}
        gridRef={gridRef}
        actions={
          <>
            <BatchLetterOfComplaintButton
              gridRef={gridRef}
              reload={() => dataSource.reload()}
            />
            <OButton onClick={() => modalManager.show(addPendencyModalId)}>
              Adicionar tarefa
            </OButton>
          </>
        }
        showRefreshButton
        showClearFiltersButton
      >
        <ODataGridGenerator
          gridRef={gridRef}
          grid={energyTasksGrid}
          dataSource={dataSource}
        />
      </GridTemplate>
      <AddPendencyModal onSubmit={() => dataSource.reload()} />
    </>
  );
};
