import { masks } from "@maestro/utils";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { RouterButton } from "components/router-button";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";

export const partnersGrid = {
  datagrid: {
    noDataText: "Nenhum parceiro encontrado.",
    headerFilter: { visible: true, allowSearch: true },
    dataSource: dataSourceCustomStoreGenerator(() => {
      return service.hubLoan.getPartner().catch(() => {
        throw new Error("Erro ao buscar parceiros.");
      });
    }),
  },
  columns: [
    {
      type: "buttons",
      role: roles.emprestimos.product.partnersPartnerDetailsPage.role,
      cellRender: ({ data }) =>
        data.id && (
          <DetailsLink
            href={corporateRouter.routes.emprestimos.product.partners.partner.details.path(
              {
                partnerId: data.id,
                partnerName: data.name,
              },
            )}
          />
        ),
    },
    {
      dataField: "name",
      caption: "Nome",
    },
    {
      caption: "CNPJ/CPF",
      dataField: "taxId",
      format: (value) => masks.cpfCnpj(value),
    },
    {
      caption: "Motor de crédito",
      dataField: "creditEngineName",
    },
    {
      caption: "Webhook",
      dataField: "webHook",
    },
    {
      caption: "Parâmetros de autênticação do webhook",
      dataField: "webHookAuthParams",
    },
    {
      caption: "Tipo de autênticação do webhook",
      dataField: "webHookAuthType",
    },
    {
      caption: "Endereço",
      dataField: "address",
    },
    {
      caption: "E-mail",
      dataField: "email",
    },
    {
      caption: "Identificação do convênio",
      dataField: "agreementIdentification",
    },
    {
      caption: "Data de assinatura do convênio",
      dataField: "agreementSignatureDate",
      dataType: "date",
    },
    {
      caption: "Ações",
      role: roles.emprestimos.product.partnersEditPage.role,
      cellRender: ({ data }) => (
        <RouterButton
          dataAction="emprestimos_parceiros:botao:editar"
          dataLabel="editar"
          href={`${
            corporateRouter.routes.emprestimos.product.partners.edit.path
          }?data=${encodeURIComponent(JSON.stringify(data))}`}
          outline
        >
          Editar
        </RouterButton>
      ),
    },
  ],
} satisfies ODataGridGeneratorConfig<
  NonNullable<
    Awaited<ReturnType<typeof service.hubLoan.getPartner>>["data"]
  >[number]
>;
