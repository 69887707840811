import { OToastManager } from "@maestro/core";
import { SelectSearchOption } from "components/select-search/select-search.types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FieldError, useFormContext } from "react-hook-form";
import { service } from "services/service";
import { generateUuidV4 } from "../../../pages/produto/originadores/novo-originador/new-originator.utils";

export const useOriginatorInfoFields = (
  isEditing: boolean,
  isTechnology: boolean,
) => {
  const [economicGroups, setEconomicGroups] = useState<
    HubPartners.Admin.EconomicGroup[]
  >([]);
  const [loadingEconomicGroups, setLoadingEconomicGroups] = useState(false);
  const [mainOriginators, setMainOriginators] = useState<
    HubPartners.Admin.Originator[]
  >([]);
  const [loadingMainOrignators, setLoadingMainOriginators] = useState(false);
  const [foundEntityData, setFoundEntityData] = useState(false);
  const [isGuid, setIsGuid] = useState(false);

  const form = useFormContext();
  const {
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = form;
  const watchIdentification = watch("identification");
  const watchIdentificationType = watch("identificationType");

  const economicGroupValue = useMemo(() => {
    const foundEconomicGroup = economicGroups.find(
      (economicGroup) => economicGroup.uuid === getValues("economicGroupUUID"),
    );
    return foundEconomicGroup?.name ?? "";
  }, [economicGroups, getValues]);
  const mainOriginatorValue = useMemo(() => {
    const foundMainOriginator = mainOriginators.find(
      (mainOriginator) =>
        mainOriginator.uuid === getValues("mainOriginatorUUID"),
    );
    return foundMainOriginator?.name ?? "";
  }, [getValues, mainOriginators]);

  const economicGroupsError = (errors.economicGroupUUID ??
    null) as FieldError | null;
  const aminOriginatorsError = (errors.mainOriginatorUUID ??
    null) as FieldError | null;

  const fillByCpfCnpj = useCallback(async () => {
    if (isEditing) {
      return;
    }
    if (
      (watchIdentificationType === "CNPJ" &&
        watchIdentification.length === 14) ||
      (watchIdentificationType === "CPF" && watchIdentification.length === 11)
    ) {
      const body = {
        taxId: watchIdentification,
      };
      try {
        const { data } = await service.onboarding.searchLegalEntity(body);
        setValue("officialName", data.officialName);
        setValue("tradingName", data.name);
        setFoundEntityData(true);
      } catch {
        OToastManager.danger("Falha ao obter dados da empresa PJ");
        setFoundEntityData(false);
      }
    }
  }, [isEditing, setValue, watchIdentification, watchIdentificationType]);

  const gererateGuid = useCallback(() => {
    if (watchIdentificationType === "GUID") {
      const guid = generateUuidV4();
      setValue("identification", guid);
      setIsGuid(true);
    } else {
      setIsGuid(false);
    }
  }, [setValue, watchIdentificationType]);

  const economicGroupOptions = useMemo(
    () =>
      economicGroups?.map<SelectSearchOption<string>>((originator) => ({
        label: originator.name,
        value: originator.uuid,
        onSelect: (value) => setValue("economicGroupUUID", value),
      })) ?? [],
    [economicGroups, setValue],
  );

  const mainOriginatorOptions = useMemo(
    () =>
      mainOriginators?.map<SelectSearchOption<string>>((originator) => ({
        label: originator.name,
        value: originator.uuid,
        onSelect: (value) => setValue("mainOriginatorUUID", value),
      })) ?? [],
    [mainOriginators, setValue],
  );

  const getMainOriginators = useCallback(async () => {
    setLoadingMainOriginators(true);
    try {
      const { data } = await service.hubPartners.getMainOriginators();
      setMainOriginators(data.data);
    } catch {
      OToastManager.danger("Falha ao obter os tipos de parceria");
    } finally {
      setLoadingMainOriginators(false);
    }
  }, []);

  const getEconomicGroups = useCallback(async () => {
    setLoadingEconomicGroups(true);
    try {
      const { data } = await service.hubPartners.fetchAllEconomicGroups();
      setEconomicGroups(data.data);
    } catch {
      OToastManager.danger("Falha ao obter os tipos de parceria");
    } finally {
      setLoadingEconomicGroups(false);
    }
  }, []);

  useEffect(() => {
    if (watchIdentificationType && !isEditing) {
      setValue("identification", "");
      setValue("officialName", "");
      setValue("tradingName", "");
    }
  }, [isEditing, setValue, watchIdentificationType]);

  useEffect(() => {
    fillByCpfCnpj();
  }, [fillByCpfCnpj]);

  useEffect(() => {
    gererateGuid();
  }, [gererateGuid]);

  useEffect(() => {
    getMainOriginators();
  }, [getMainOriginators]);

  useEffect(() => {
    getEconomicGroups();
  }, [getEconomicGroups]);

  useEffect(() => {
    if (!isTechnology && !isEditing) {
      const btgMainOriginator = mainOriginators.find((mainOriginator) =>
        mainOriginator.name.includes("BTG PACTUAL"),
      );
      setValue("mainOriginatorUUID", btgMainOriginator?.uuid ?? "");
    }
  }, [isEditing, isTechnology, mainOriginators, setValue]);

  return {
    economicGroupOptions,
    economicGroupsError,
    economicGroupValue,
    foundEntityData,
    isGuid,
    loadingEconomicGroups,
    loadingMainOrignators,
    mainOriginatorOptions,
    aminOriginatorsError,
    mainOriginatorValue,
  };
};
