import { OTypography, OButton } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { DetailsCard } from "components/details-card";
import { NextStepButton, ResetStepButton } from "components/steps-builder";
import { useGridRef } from "hooks/grid-ref";
import { useMemo, useCallback } from "react";
import { GridTemplate } from "templates/grid-template";
import { OToastManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { service } from "services";
import { PostAdminReceivablesSearchBody } from "services/quickfin/models/requests/post-admin-receivables-search.request";
import dayjs from "dayjs";
import { ErrorNotifications } from "../../../../../components";
import {
  buildOperationDetails,
  uncapitalizeObjectKeys,
} from "../../../../../utils";
import { useIntubateOperations } from "../../intubate-operations.context";
import { quoteFileGrid } from "./quote-file.grid";

export const QuoteFileStep = () => {
  const { fileSearchReceivables, receivablesNotFound } =
    useIntubateOperations();

  const gridRef = useGridRef();

  const disableButton = !fileSearchReceivables.length;

  const downloadQuotationFile = useCallback(async () => {
    try {
      const formattedDate = dayjs().format("YYYYMMDD");

      const receivables: PostAdminReceivablesSearchBody = [];

      fileSearchReceivables.forEach((receivable) => {
        receivables.push({
          maturityDate: receivable.DataVencimento,
          overrideMaturityAmount: receivable.ValorSolicitado,
          overrideDisbursementAmount: receivable.ValorDesembolsoSolicitado!,
          brand: receivable.Bandeira,
          payerTaxIdentification: receivable.SacadoIdentification,
          payeeTaxIdentification: receivable.CedenteIdentification,
        });
      });

      const { data } = await service.quickfin.downloadSearchQuotationFile(
        receivables,
      );

      const filename = `Exportar_cotacao_${data.identification}_${formattedDate}.xlsx`;
      helpers.downloadBase64(data.quotation, filename);
    } catch {
      OToastManager.danger("Não foi possível fazer o download do arquivo");
    }
  }, [fileSearchReceivables]);

  const requestedAmounts = useMemo(
    () =>
      Object.fromEntries(
        fileSearchReceivables.map((receivable) => [
          receivable.ReceivableId,
          receivable.ValorSolicitado,
        ]),
      ),
    [fileSearchReceivables],
  );

  const requestedDisbursementAmounts = useMemo(
    () =>
      Object.fromEntries(
        fileSearchReceivables.map((receivable) => [
          receivable.ReceivableId,
          receivable.ValorDesembolsoSolicitado ?? receivable.ValorDesembolso,
        ]),
      ),
    [fileSearchReceivables],
  );

  return (
    <>
      <ErrorNotifications receivablesWithError={receivablesNotFound} />
      <div className="d-flex flex-column gap-4">
        <DetailsCard
          emptyStateComponent={<OTypography>Nenhum recebível</OTypography>}
          value={fileSearchReceivables.map(uncapitalizeObjectKeys)}
          fields={buildOperationDetails(
            requestedAmounts,
            {},
            requestedDisbursementAmounts,
          )}
        />
        <GridTemplate gridRef={gridRef} showClearFiltersButton>
          <ODataGridGenerator
            gridRef={gridRef}
            grid={quoteFileGrid}
            dataSource={fileSearchReceivables}
          />
        </GridTemplate>
        <div className="d-flex justify-content-end gap-3 mt-4 w-100">
          <OButton
            dataAction="entuba_summary:botao:exportar_cotacao"
            dataLabel="exportar_cotacao"
            onClick={downloadQuotationFile}
            disabled={disableButton}
          >
            Exportar Cotação
          </OButton>
          <ResetStepButton className="me-auto" />
          <NextStepButton disabled={fileSearchReceivables.length === 0} />
        </div>
      </div>
    </>
  );
};
