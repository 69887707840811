import * as yup from "yup";
import { LoanPartnersFormValues } from "./loan-partner-form.component";

export const loanPartnerValidationFormSchema: yup.ObjectSchema<LoanPartnersFormValues> =
  yup.object({
    name: yup.string().required("Este campo é obrigatório"),
    taxId: yup.string().optional(),
    creditEngineName: yup.string().optional(),
    webHook: yup.string().optional(),
    webHookAuthParams: yup.string().optional(),
    webHookAuthType: yup.string().required("Este campo é obrigatório"),
    address: yup.string().optional(),
    agreementIdentification: yup.string().optional(),
    agreementSignatureDate: yup
      .string()
      .transform((date) => {
        return date && new Date(date).toISOString();
      })
      .nullable()
      .optional(),
    email: yup.string().optional(),
  });
