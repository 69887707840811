import { masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { endpoints } from "services/endpoints";
import { buildCustomerLink } from "utils/build-customer-link";
import { parseQuoteFlowType } from "../../utils/quote.utils";

type Grid = ODataGridGeneratorConfig<
  NonNullable<
    Awaited<
      ReturnType<typeof service.hubFx.odata.getPreTickets>
    >["data"]["value"]
  >[number]
>;

export const exchangeOdataPreTicketsGrid = (
  mapSymbol: (currency?: string | null) => string,
  filterValue?: Grid["datagrid"]["filterValue"],
) =>
  ({
    datagrid: {
      noDataText: "Nenhum pré-ticket encontrado.",
      headerFilter: { visible: true, allowSearch: true },
      onDataErrorOccurred: (e) => {
        if (e.error) e.error.message = "Erro ao buscar pré-tickets.";
      },
      onRowPrepared: (e) => {
        if (e.data?.pendenciesCount && e.data.pendenciesCount > 0) {
          e.rowElement.style.backgroundColor = "var(--theme-warning-light)";
        }
      },
      dataSource: dataSourceODataStoreGenerator(
        endpoints.hubFx.channelOdata.channelPreTicketsOData,
        {
          dataSourceOptions: {
            filter: filterValue,
          },
        },
      ),
    },
    columns: [
      {
        allowExporting: false,
        type: "buttons",
        role: roles.cambio.product.preTicketsDetailsPage.role,
        cellRender: ({ data }) =>
          data.id && (
            <DetailsLink
              href={buildCustomerLink(
                `${data.counterpartyIdentification}` ?? "",
                corporateRouter.routes.cambio.customer.preTickets.details.path({
                  id: data.id,
                }),
              )}
            />
          ),
      },
      {
        sortOrder: "desc",
        dataField: "id",
        caption: "Id",
        dataType: "number",
      },
      {
        dataField: "fxId",
        caption: "FxId",
        dataType: "number",
      },
      {
        caption: "Data de criação",
        dataType: "date",
        format: "dd/MM/yyyy HH:mm:ss",
        dataField: "createdDate",
      },
      {
        caption: "Nome da empresa",
        dataField: "counterpartyName",
      },
      {
        caption: "CNPJ",
        dataField: "counterpartyIdentification",
        format: (value) => masks.cnpj(value),
      },
      {
        caption: "Tipo",
        cellRender: ({ data }) =>
          parseQuoteFlowType({
            buyCurrencyType: data.buyCurrencyType,
            flowType: data.flowType,
            sellCurrencyType: data.sellCurrencyType,
            beneficiaryName: data.beneficiaryName,
            orderPayer: data.orderPayer,
          })?.type,
      },
      {
        caption: "Status",
        dataField: "status",
      },
      {
        caption: "Beneficiário/Pagador",
        cellRender: ({ data }) =>
          parseQuoteFlowType({
            buyCurrencyType: data.buyCurrencyType,
            flowType: data.flowType,
            sellCurrencyType: data.sellCurrencyType,
            beneficiaryName: data.beneficiaryName,
            orderPayer: data.orderPayer,
          })?.externalPartyName,
      },
      {
        caption: "Moeda",
        cellRender: ({ data }) =>
          parseQuoteFlowType({
            buyCurrencyType: data.buyCurrencyType,
            flowType: data.flowType,
            sellCurrencyType: data.sellCurrencyType,
            beneficiaryName: data.beneficiaryName,
            orderPayer: data.orderPayer,
          })?.currency,
      },
      {
        caption: "País",
        dataField: "country",
      },
      {
        caption: "Enquadramento",
        dataField: "natureDescription",
      },
      {
        caption: "Valor disponível",
        cellRender: ({ data }) =>
          masks.currency(
            data.limitAvailableAmount,
            mapSymbol(
              parseQuoteFlowType({
                buyCurrencyType: data.buyCurrencyType,
                flowType: data.flowType,
                sellCurrencyType: data.sellCurrencyType,
                beneficiaryName: data.beneficiaryName,
                orderPayer: data.orderPayer,
              })?.currency,
            ),
            ".",
          ),
      },
      {
        caption: "Valor total",
        cellRender: ({ data }) =>
          masks.currency(
            data.limitTotalAmount,
            mapSymbol(
              parseQuoteFlowType({
                buyCurrencyType: data.buyCurrencyType,
                flowType: data.flowType,
                sellCurrencyType: data.sellCurrencyType,
                beneficiaryName: data.beneficiaryName,
                orderPayer: data.orderPayer,
              })?.currency,
            ),
            ".",
          ),
      },
    ],
  } satisfies Grid);
