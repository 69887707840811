import { ODataGridGeneratorConfig } from "components/data-grid";
import { StandardLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GetSnakeCompanyByIdResponse } from "services/fidc/models/responses";

const buildEditFinancialContactLink = (
  data: GetSnakeCompanyByIdResponse["financials"][number],
) => {
  const { routes } = corporateRouter;

  const baseUrl = routes.fidc.product.interactions.details.edit.path;

  const stringifyedData = JSON.stringify(data);
  const searchParams = new URLSearchParams();
  searchParams.set("contact", stringifyedData);

  return baseUrl + "?" + searchParams.toString();
};

export const interactionFinancialsGrid: ODataGridGeneratorConfig<
  GetSnakeCompanyByIdResponse["financials"][number]
> = {
  datagrid: {
    noDataText: "Nenhum contato financeiro encontrado.",
  },
  columns: [
    {
      role: roles.fidc.product.interactionsDetailsEditPage.role,
      cellRender: ({ data }) => (
        <StandardLink href={buildEditFinancialContactLink(data)}>
          Editar
        </StandardLink>
      ),
    },
    {
      dataField: "id",
      caption: "Id",
      visible: false,
      allowEditing: false,
    },
    {
      dataField: "name",
      caption: "Nome",
    },
    {
      dataField: "email",
      caption: "Email",
    },
    {
      dataField: "cellPhoneNumber",
      caption: "Celular",
    },
    {
      dataField: "isDisabled",
      caption: "Is disabled",
      dataType: "boolean",
    },
  ],
};
