import {
  OButton,
  OCol,
  OOptionMultiple,
  ORFieldInput,
  ORFieldSelectMultiple,
  ORow,
  ORFieldRadioGroup,
  OLabel,
  ORadio,
} from "@maestro/react";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useEmailCotacao } from "./email-cotacao.hook";
import { emailsOls } from "./email-cotacao.types";

export const EmailCotacao = () => {
  const { form, submit } = useEmailCotacao();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Envio email cotação"
          description="Reenviar emails de cotação automática pelo CNPJ (completo ou raiz) para as OLs"
        />
      }
      actions={
        <OButton
          dataAction="email_cotacao:botao:enviar"
          dataLabel="enviar"
          onClick={submit}
        >
          Enviar
        </OButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow>
            <OCol xs={12} md={6}>
              <ORFieldInput
                dataAction="email_cotacao:texto:cnpj"
                dataLabel="cnpj"
                tag="text"
                id="identification"
                name="identification"
                label="CNPJ raiz ou completo"
                placeholder="Preencher"
              />
            </OCol>
            <OCol xs={12} md={6} align="center" className="gap-2">
              <ORFieldSelectMultiple
                dataAction="email_cotacao:select:emails"
                dataLabel="emails"
                id="emails"
                name="emails"
                label="Emails"
                key={emailsOls.length}
              >
                {emailsOls.map((email) => (
                  <OOptionMultiple key={email.id} value={String(email.id)}>
                    {email.name}
                  </OOptionMultiple>
                ))}
              </ORFieldSelectMultiple>
            </OCol>
            <OCol xs={12} md={6}>
              <ORFieldRadioGroup
                id="isSacadoCnpj"
                name="isSacadoCnpj"
                dataLabel="e_cnpj_sacado"
                dataAction="relatorios_em_lote:select:e_cnpj_sacado"
                label="É CNPJ de sacado? "
              >
                <OCol xs={12} className="d-flex gap-3">
                  <div className="d-flex aligm-items-center gap-2">
                    <OLabel htmlFor="isSacadoCnpj">Sim</OLabel>
                    <ORadio id="isSacadoCnpj" value="true" />
                    <OLabel htmlFor="isSacadoCnpj">Não</OLabel>
                    <ORadio id="isSacadoCnpj" value="false" />
                  </div>
                </OCol>
              </ORFieldRadioGroup>
            </OCol>
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
