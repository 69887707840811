import { OTypography } from "@maestro/react";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import dayjs from "dayjs";
import { corporateRouter } from "routes/corporate-router.context";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { ValidationErrorComponent } from "../../../components/validation-error-component";
import { useStepAnalysisSummary } from "./step-analysis-summary.hook";
import { StepsBoard } from "./_compose";

export const StepAnalysisSummaryPage = () => {
  const { error, getSummary, hasError, loading, value } =
    useStepAnalysisSummary();

  const migrationDate =
    value?.response.migrationDate &&
    dayjs(value.response.migrationDate).format("DD/MM/YYYY");

  const showStartButton = value?.response.steps.some(
    (s) => s.consumerUnits.length,
  );

  return (
    <DetailsTemplate
      pageTitle={<PageTitle title="Análise de migração" />}
      actions={
        <>
          {migrationDate && (
            <OTypography>Data de migração: {migrationDate}</OTypography>
          )}
          {showStartButton && (
            <RouterButton
              href={
                corporateRouter.routes.energia.product.diligenceAnalysis.step
                  .path
              }
            >
              Iniciar
            </RouterButton>
          )}
        </>
      }
    >
      <ContentTemplate
        loading={loading}
        hasError={hasError}
        errorComponent={
          <ValidationErrorComponent
            error={error}
            callback={getSummary}
            title="Não foi possível buscar as migrações"
          />
        }
        value={value}
        render={(_value) => <StepsBoard summary={_value.response} />}
      />
    </DetailsTemplate>
  );
};
