import { OCard, OCardBody, OLoader } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { customer } from "contexts/customer";
import { FormProvider } from "react-hook-form";
import { FormTemplate } from "templates/form-template";
import { RemunerationFields } from "../../../../components/originator";
import { useUpdateOriginatorRemuneration } from "./update-originator-remuneration.hook";

export const UpdateOriginatorRemuneration = () => {
  const { form, loading, loadingSubmit, onSubmit } =
    useUpdateOriginatorRemuneration();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Remuneração"
          description="Selecione ou informe abaixo a conta bancária onde serão realizados os desembolsos deste parceiro."
        />
      }
      actions={
        <LoadingButton loading={loading || loadingSubmit} onClick={onSubmit}>
          Cadastrar
        </LoadingButton>
      }
    >
      <FormProvider {...form}>
        <OCard>
          <OCardBody>
            {loading ? (
              <OLoader />
            ) : (
              <RemunerationFields
                editingTaxId={customer.value?.identification}
              />
            )}
          </OCardBody>
        </OCard>
      </FormProvider>
    </FormTemplate>
  );
};
