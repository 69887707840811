import { client } from "../client";
import { hubCreditManagerEndpoints } from "./hubcreditmanager.endpoints";
import {
  GetApiAdminCustomerOriginatorParams,
  PatchAdminLimitRequestsRemoveGuarantorBody,
  PatchAdminTransactionReverseBody,
  PatchApiAdminWorkflowLeadExportRequestsReject,
  PostAdminAdditionaFeeCreateBody,
  PostAdminAdditionaFeeDeleteBody,
  PostAdminAdditionaFeeUpdateBody,
  PostAdminAddStrategiesToBookBody,
  PostAdminBatchFileTemplateBody,
  PostAdminBatchFileTemplateDownloadBody,
  PostAdminBigNumbersBody,
  PostAdminBookCreateBody,
  PostAdminBookDeleteBody,
  PostAdminBookStrategyDeleteBody,
  PostAdminCollateralCreateBody,
  PostAdminCollateralUpdateBody,
  PostAdminColumnsBody,
  PostAdminComponentsBody,
  PostAdminConfigurableStatusBody,
  PostAdminContractTemplateCreateBody,
  PostAdminContractTemplateUpdateBody,
  PostAdminCreditLineConfigurationCreateBody,
  PostAdminCustomerLimitsBody,
  PostAdminCustomerOriginatorBody,
  PostAdminDeleteWorkflowLeadBody,
  PostAdminEnergyWorkflowGetSelectedProposal,
  PostAdminFileDownloadBody,
  PostAdminForeignCurrencyIndexCreateBody,
  PostAdminForeignCurrencyIndexUpdateBody,
  PostAdminFormBody,
  PostAdminFormStepAddBody,
  PostAdminFormStepCardAddBody,
  PostAdminFormStepCardComponentAddBody,
  PostAdminFormStepCardComponentRemoveBody,
  PostAdminFormStepCardRemoveBody,
  PostAdminFormStepRemoveBody,
  PostAdminLimitRequestsUpdateAgroSacksBody,
  PostAdminLimitRequestsUpdateAmountBody,
  PostAdminLimitRequestsUpdateCreatingLimitStatusBody,
  PostAdminLimitRequestsUpdateExpirationDateBody,
  PostAdminLimitRequestsUpdateStatusBody,
  PostAdminLimitRequestSyncAgromaticBody,
  PostAdminLimitsApprovalBody,
  PostAdminLimitsBody,
  PostAdminLimitsGetAllLimitsByTaxIdBody,
  PostAdminLimitsGetLimitBody,
  PostAdminLinkToCprBody,
  PostAdminProcessorBody,
  PostAdminProcessorConfigConditionAddBody,
  PostAdminProcessorConfigConditionRemoveBody,
  PostAdminProcessorConfigIdsBody,
  PostAdminProcessorConfigPropertyUpdate,
  PostAdminProcessorConfigRelationshipCreateBody,
  PostAdminProcessorConfigRelationshipRemoveBody,
  PostAdminProcessorContextAddBody,
  PostAdminPropertyUpdateBody,
  PostAdminRepublishWorkflowCreditEngineBody,
  PostAdminRepublishWorkflowStatusBody,
  PostAdminSettlementInstructionUpdateBody,
  PostAdminStrategyCreateBody,
  PostAdminStrategyDeleteBody,
  PostAdminTransactionCreateBody,
  PostAdminUserActionRequestSendNotificationBody,
  PostAdminWorkflowConfigAddCanvasItemBody,
  PostAdminWorkflowConfigAddProcessorConfigBody,
  PostAdminWorkflowConfigExportCanvasBody,
  PostAdminWorkflowConfigImportCanvasBody,
  PostAdminWorkflowConfigRemoveCanvasItemBody,
  PostAdminWorkflowConfigUpdateCanvasItemBody,
  PostAdminWorkflowDashboardGetOriginatorsByLeadBody,
  PostAdminWorkflowDashboardGetSelectedOriginatorsBody,
  PostAdminWorkflowLeadsApprovalBody,
  PostApiAdminBatchFileTemplateColumnAddBody,
  PostApiAdminBatchFileTemplateColumnRemoveBody,
  PostApiAdminBatchFileTemplateConditionAddBody,
  PostApiAdminBatchFileTemplateConditionRemoveBody,
  PostApiAdminComponentPropertyRemoveBody,
  PostApiAdminComponentsConfigPropertyUpdateBody,
  PostApiAdminComponentsPropertyAddBody,
  PostApiAdminConditionCreateBody,
  PostApiAdminConditionExecuteBody,
  PostApiAdminFormStepCardComponentConditionAddBody,
  PostApiAdminFormStepCardComponentConditionRemoveBody,
  PostApiAdminFormStepConditionAddBody,
  PostApiAdminFormStepConditionRemoveBody,
  PostApiAdminLimitsCreateCollateralDocumentBody,
  PostApiAdminProcessorOutputAddBody,
  PostApiAdminProcessorOutputRemoveBody,
  PostApiAdminProcessorPropertyAddBody,
  PostApiAdminProcessorPropertyRemoveBody,
  PostApiAdminScoreBenefitCreateBody,
  PostApiAdminScoreLevelCreateBody,
  PostApiAdminScoreModuleCreateBody,
  PostApiAdminWorkflowConfigEditBody,
  PostApiAdminWorkflowConfigHierarchyEditBody,
  PostApiAdminWorkflowConfigViewBody,
  PostApiAdminWorkflowLeadsAddPendencyBody,
  PostApiAdminWorkflowLeadsDeletePendencyBody,
  PostGuarantorsCreateCreditLineGuarantorBody,
  PostWorkflowConfigCreateBody,
  PutAdminBatchTemplateBody,
  PutAdminBookUpdateBody,
  PutAdminColumnsBody,
  PutAdminComponentsBody,
  PutAdminConvasItemsPositionBody,
  PutAdminCreditLineConfigurationUpdateBody,
  PutAdminDeleteOfferBody,
  PutAdminFormBody,
  PutAdminFormStepBody,
  PutAdminFormStepCardBody,
  PutAdminFormStepCardComponentBody,
  PutAdminFormStepCardComponentUpdatePositionsBody,
  PutAdminFormStepCardUpdatePositionsBody,
  PutAdminFormStepUpdatePositionsBody,
  PutAdminInvoiceBody,
  PutAdminLimitRequestsUpdateQuotesIndexcodeBody,
  PutAdminProcessorConfigViewOutputConfigBigNumbersBody,
  PutAdminProcessorConfigViewOutputConfigStatusBody,
  PutAdminProcessorContextUpdateStatusBody,
  PutAdminStrategyUpdateBody,
  PutAdminUnitConsumerBody,
  PutAdminViewBigNumberUpdateGridBody,
  PutAdminWorkflowConfigViewUpdateViewBigNumbersBody,
  PutApiAdminComponentPropertyEditBody,
  PutApiAdminConditionUpdateBody,
  PutApiAdminGridBody,
  PutApiAdminGridColumnBody,
  PutApiAdminProcessorBody,
  PutApiAdminProcessorOutputEditBody,
  PutApiAdminProcessorPropertyUpdateBody,
  PutApiAdminScoreBenefitsUpdatePositionsBody,
  PutApiAdminScoreBenefitUpdateBody,
  PutApiAdminScoreModulesUpdatePositionsBody,
  PutApiAdminScoreModuleUpdateBody,
  PutApiAdminWorkflowConfigViewUpdateDefaultGridBody,
} from "./models/requests";
import { PostAdminCommonDomainDeleteBody } from "./models/requests/post-admin-common-domain-delete";
import { PostAdminLimitDeactivateBody } from "./models/requests/post-admin-limit-deactivate.request";
import { PostAdminLimitReevaluateBody } from "./models/requests/post-admin-limit-reevaluate.request";
import { PostAdminMinimumCriteriaDeleteBody } from "./models/requests/post-admin-minimum-criteria-delete";
import { PostApiAdminCommonDomainCreateBody } from "./models/requests/post-api-admin-common-domain-create.request";
import { PutAdminProcessorConfigBasicDataBody } from "./models/requests/put-admin-processor-config-basic-data.response";
import { PutAdminProcessorConfigChannelBody } from "./models/requests/put-admin-processor-config-channel.request";
import { PutAdminProcessorConfigSettingsBody } from "./models/requests/put-admin-processor-config-settings.response";
import { PutAdminProcessorConfigUserActionsBody } from "./models/requests/put-admin-processor-config-user-actions.response";
import { PutApiAdminCommonDomainBody } from "./models/requests/put-api-admin-common-domain.request";
import {
  GetAdminAllCprIdsResponse,
  GetAdminChannelResponse,
  GetAdminCreditLineConfigurationByIdResponse,
  GetAdminCreditLineConfigurationsResponse,
  GetAdminCreditLinesResponse,
  GetAdminCustomerLimitsOdataResponse,
  GetAdminGridByIdResponse,
  GetAdminLimitRequestByIdAdditionalFeesResponse,
  GetAdminLimitRequestMinifiedByIdResponse,
  GetAdminLimitRequestsByIdCollateralsResponse,
  GetAdminLimitRequestsByIdContractTemplatesResponse,
  GetAdminLimitRequestsByIdDisbursementInstructionsResponse,
  GetAdminLimitRequestsByIdExternalIdentificationsResponse,
  GetAdminLimitRequestsByIdFinancedAssetsResponse,
  GetAdminLimitRequestsByIdFinancialConditionsResponse,
  GetAdminLimitRequestsByIdGuarantorsResponse,
  GetAdminLimitRequestsByIdInvoicesResponse,
  GetAdminOfferByIdResponse,
  GetAdminPolicyByLimitRequestIdResponse,
  GetAdminProcessorByIdOutputsResponse,
  GetAdminProcessorConfigByIdResponse,
  GetAdminUnitConsumerByUnitIdResponse,
  GetAdminViewBigNumberByIdResponse,
  GetAdminWorkflowConfigViewByIdResponse,
  GetAdminWorkflowConfigViewsByWorkflowConfigIdResponse,
  GetAdminWorkflowLeadsBatchRequestsByIdFileResponse,
  GetAdminWorkflowLeadsByIdDocumentsResponse,
  GetAdminWorkflowLeadsByIdPendenciesResponse,
  GetAdminWorkflowLeadsByIdProcessorContextsResponse,
  GetAdminWorkflowLeadsByIdUserActionsResponse,
  GetApiAdminBatchConfigurableColumnsResponse,
  GetApiAdminBatchFileTemplateColumnsResponse,
  GetApiAdminBatchFileTemplateResponse,
  GetApiAdminBatchFileTemplatesByIdResponse,
  GetApiAdminBigNumbersResponse,
  GetApiAdminBooksResponse,
  GetApiAdminBookStrategiesResponse,
  GetApiAdminCollateralByIdResponse,
  GetApiAdminCommonDomainResponse,
  GetApiAdminComponentsByIdResponse,
  GetApiAdminComponentsResponse,
  GetApiAdminConditionResponse,
  GetApiAdminConfigurableGridColumnResponse,
  GetApiAdminConfigurableStatusResponse,
  GetApiAdminCustomerOriginatorResponse,
  GetApiAdminFinancedAssetByIdResponse,
  GetApiAdminFormByIdResponse,
  GetApiAdminFormResponse,
  GetApiAdminGridResponse,
  GetApiAdminLimitRequestsGetAllPendingResponse,
  GetApiAdminLimitRequestsGetLimitRequestByIdResponse,
  GetApiAdminMinimumCriteriaColumnResponse,
  GetApiAdminMinimumCriteriaResponse,
  GetApiAdminProcessorByIdResponse,
  GetApiAdminProcessorResponse,
  GetApiAdminScoreBenefitByIdResponse,
  GetApiAdminScoreBenefitsResponse,
  GetApiAdminScoreLevelsResponse,
  GetApiAdminScoreModuleByIdResponse,
  GetApiAdminScoreModulesResponse,
  GetApiAdminStrategiesResponse,
  GetApiAdminTransactionByIdGetAllByLimitResponse,
  GetApiAdminViewResponse,
  GetApiAdminWorkflowConfigByIdAllowedChildWorkflowConfigsResponse,
  GetApiAdminWorkflowConfigByIdCanvasDataResponse,
  GetApiAdminWorkflowConfigByIdChildProcessorConfigsResponse,
  GetApiAdminWorkflowConfigByIdChildWorkflowConfigsResponse,
  GetApiAdminWorkflowConfigByIdResponse,
  GetApiAdminWorkflowConfigHierarchyByIdResponse,
  GetApiAdminWorkflowConfigOffersByIdResponse,
  GetApiAdminWorkflowConfigResponse,
  GetApiAdminWorkflowLeadExportRequestsResponse,
  GetApiAdminWorkflowLeadsBatchRequestsByIdResponse,
  GetApiAdminWorkflowLeadsByIdCreditLinesByIdResponse,
  GetApiAdminWorkflowLeadsByIdEnergyConsumerUnitsResponse,
  GetApiAdminWorkflowLeadsByIdPendenciesResponse,
  GetApiAdminWorkflowLeadsByIdProcessorsExecutingResponse,
  GetApiAdminWorkflowLeadsByIdProposalGroupsByIdResponse,
  GetApiAdminWorkflowLeadsByIdResponse,
  GetApiAdminWorkflowLeadsByIdStateHistoryResponse,
  GetApiAdminWorkflowLeadsByIdSummaryResponse,
  GetApiAdminWorkflowLeadsProposedCreditLineByIdResponse,
  GetApiRmAdminMainOriginatorResponse,
  PatchApiAdminTransactionReverseResponse,
  PostAdminCustomerLimitsResponse,
  PostAdminForeignCurrencyIndexCreateResponse,
  PostAdminForeignCurrencyIndexUpdateResponse,
  PostAdminLimitsGetAllLimitsByTaxIdResponse,
  PostAdminLimitsGetLimitResponse,
  PostAdminProcessorConfigConditionAddResponse,
  PostAdminProcessorConfigRelationshipCreateResponse,
  PostAdminWorkflowConfigAddProcessorConfigResponse,
  PostAdminWorkflowConfigExportCanvasResponse,
  PostAdminWorkflowDashboardGetOriginatorsByLeadResponse,
  PostAdminWorkflowDashboardGetSelectedOriginatorsResponse,
  PostApiAdminConditionExecuteResponse,
  PostApiAdminContractTemplateCreateResponse,
  PostApiAdminContractTemplateUpdateResponse,
  PostApiAdminFileUploadResponse,
  PostApiAdminFormStepCardComponentConditionAddResponse,
  PostApiAdminFormStepConditionAddResponse,
  PostApiAdminLimitsCreateCollateralDocumentResponse,
  PostApiAdminLimitsResponse,
  PostApiAdminMinimumCriteriaCreateResponse,
  PostApiAdminProcessorOutputAddResponse,
  PostApiAdminProcessorPropertyAddResponse,
  PostApiAdminTransactionCreateResponse,
  PostApiAdminWorkflowLeadsBatchRequestByIdProcessResponse,
  PutAdminWorkflowLeadsBatchRequestStatus,
  PutApiAdminBigNumbersBody,
  PutApiAdminConditionUpdateResponse,
  PutApiAdminConfigurableStatusUpdateBody,
  PutApiAdminMinimumCriteriaBody,
  PutApiAdminProcessorPropertyUpdateResponse,
  PutApiAdminProcessorResponse,
} from "./models/responses";
import { GetAdminProcessorConfigBasicDataResponse } from "./models/responses/get-admin-processor-config-basic-data.response";
import { GetAdminProcessorConfigByWorflowIdResponse } from "./models/responses/get-admin-processor-config-by-workflow-id.response";
import { GetAdminProcessorConfigChannelResponse } from "./models/responses/get-admin-processor-config-channel.response";
import { ProcessorConfigViewResponse } from "./models/responses/get-admin-processor-config-monitoring.response";
import { GetAdminProcessorConfigPropertyResponse } from "./models/responses/get-admin-processor-config-properties.response";
import { GetAdminProcessorConfigSettingsResponse } from "./models/responses/get-admin-processor-config-settings.response";
import { GetAdminProcessorConfigUserActionsResponse } from "./models/responses/get-admin-processor-config-user-actions.response";
import { hubCreditManagerODataService } from "./odata/hubcreditmanager-odata.service";

export const hubCreditManagerService = {
  getCompanyCreditLines() {
    return client.get<GetAdminCustomerLimitsOdataResponse>(
      hubCreditManagerEndpoints.odata.adminCustomerLimits,
    );
  },
  getCompanyCreditLineDetails(body: PostAdminCustomerLimitsBody) {
    return client.post<PostAdminCustomerLimitsResponse>(
      hubCreditManagerEndpoints.adminCustomer.limits,
      body,
    );
  },
  getComponents() {
    return client.get<GetApiAdminComponentsResponse>(
      hubCreditManagerEndpoints.admin.components.endpoint,
    );
  },
  getComponentsById(id: string | number) {
    return client.get<GetApiAdminComponentsByIdResponse>(
      hubCreditManagerEndpoints.admin.components.byId(id),
    );
  },
  addComponents(body: PostAdminComponentsBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.components.endpoint,
      body,
    );
  },
  updateComponents(body: PutAdminComponentsBody) {
    return client.put(
      hubCreditManagerEndpoints.admin.components.endpoint,
      body,
    );
  },
  addComponentProperty(body: PostApiAdminComponentsPropertyAddBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.components.property.add,
      body,
    );
  },
  editComponentProperty(body: PutApiAdminComponentPropertyEditBody) {
    return client.put(
      hubCreditManagerEndpoints.admin.components.property.edit,
      body,
    );
  },
  removeComponentProperty(body: PostApiAdminComponentPropertyRemoveBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.components.property.remove,
      body,
    );
  },
  updateComponentConfigProperty(
    body: PostApiAdminComponentsConfigPropertyUpdateBody,
  ) {
    return client.post(
      hubCreditManagerEndpoints.admin.components.configProperty.update,
      body,
    );
  },
  getFormsList() {
    return client.get<GetApiAdminFormResponse>(
      hubCreditManagerEndpoints.admin.form.api,
    );
  },
  addForm(body: PostAdminFormBody) {
    return client.post(hubCreditManagerEndpoints.admin.form.api, body);
  },
  updateForm(body: PutAdminFormBody) {
    return client.put(hubCreditManagerEndpoints.admin.form.api, body);
  },
  updateFormStepPositions(body: PutAdminFormStepUpdatePositionsBody) {
    return client.put(
      hubCreditManagerEndpoints.admin.form.step.updatePositions,
      body,
    );
  },
  addFormStep(body: PostAdminFormStepAddBody) {
    return client.post(hubCreditManagerEndpoints.admin.form.step.add, body);
  },
  addFormStepCondition(body: PostApiAdminFormStepConditionAddBody) {
    return client.post<PostApiAdminFormStepConditionAddResponse>(
      hubCreditManagerEndpoints.admin.form.step.condition.add,
      body,
    );
  },
  removeFormStepCondition(body: PostApiAdminFormStepConditionRemoveBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.form.step.condition.remove,
      body,
    );
  },
  removeFormStep(body: PostAdminFormStepRemoveBody) {
    return client.post(hubCreditManagerEndpoints.admin.form.step.remove, body);
  },
  updateFormStep(body: PutAdminFormStepBody) {
    return client.put(hubCreditManagerEndpoints.admin.form.step.api, body);
  },
  updateFormStepCardPositions(body: PutAdminFormStepCardUpdatePositionsBody) {
    return client.put(
      hubCreditManagerEndpoints.admin.form.step.card.updatePositions,
      body,
    );
  },
  addFormStepCard(body: PostAdminFormStepCardAddBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.form.step.card.add,
      body,
    );
  },
  removeFormStepCard(body: PostAdminFormStepCardRemoveBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.form.step.card.remove,
      body,
    );
  },
  updateFormStepCard(body: PutAdminFormStepCardBody) {
    return client.put(hubCreditManagerEndpoints.admin.form.step.card.api, body);
  },
  updateFormStepCardComponentPositions(
    body: PutAdminFormStepCardComponentUpdatePositionsBody,
  ) {
    return client.put(
      hubCreditManagerEndpoints.admin.form.step.card.component.updatePositions,
      body,
    );
  },
  addFormStepCardComponent(body: PostAdminFormStepCardComponentAddBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.form.step.card.component.add,
      body,
    );
  },
  removeFormStepCardComponent(body: PostAdminFormStepCardComponentRemoveBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.form.step.card.component.remove,
      body,
    );
  },
  updateFormStepCardComponent(body: PutAdminFormStepCardComponentBody) {
    return client.put(
      hubCreditManagerEndpoints.admin.form.step.card.component.api,
      body,
    );
  },
  addFormStepCardComponentCondition(
    body: PostApiAdminFormStepCardComponentConditionAddBody,
  ) {
    return client.post<PostApiAdminFormStepCardComponentConditionAddResponse>(
      hubCreditManagerEndpoints.admin.form.step.card.component.condition.add,
      body,
    );
  },
  removeFormStepCardComponentCondition(
    body: PostApiAdminFormStepCardComponentConditionRemoveBody,
  ) {
    return client.post(
      hubCreditManagerEndpoints.admin.form.step.card.component.condition.remove,
      body,
    );
  },
  addBatchFileTemplateCondition(
    body: PostApiAdminBatchFileTemplateConditionAddBody,
  ) {
    return client.post<PostApiAdminFormStepCardComponentConditionAddResponse>(
      hubCreditManagerEndpoints.admin.batchFileTemplateConditions.api,
      body,
    );
  },
  removeBatchFileTemplateCondition(
    body: PostApiAdminBatchFileTemplateConditionRemoveBody,
  ) {
    return client.post(
      hubCreditManagerEndpoints.admin.batchFileTemplateConditions.remove,
      body,
    );
  },
  getConditions(orderParam?: string) {
    return client.get<GetApiAdminConditionResponse>(
      hubCreditManagerEndpoints.admin.condition.api(orderParam),
    );
  },
  getGrids() {
    return client.get<GetApiAdminGridResponse>(
      hubCreditManagerEndpoints.admin.grid.api,
    );
  },
  upsertGrid(body: PutApiAdminGridBody) {
    return client.put(hubCreditManagerEndpoints.admin.grid.api, body);
  },
  getGridById(id: string) {
    return client.get<GetAdminGridByIdResponse>(
      hubCreditManagerEndpoints.admin.grid.byId(id),
    );
  },
  updateGridColumn(body: PutApiAdminGridColumnBody) {
    return client.put(hubCreditManagerEndpoints.admin.grid.updateColumn, body);
  },
  getViews() {
    return client.get<GetApiAdminViewResponse>(
      hubCreditManagerEndpoints.admin.view.api,
    );
  },
  getBigNumbers() {
    return client.get<GetApiAdminBigNumbersResponse>(
      hubCreditManagerEndpoints.admin.bigNumbers,
    );
  },
  createBigNumbers(body: PostAdminBigNumbersBody) {
    return client.post(hubCreditManagerEndpoints.admin.bigNumbers, body);
  },
  updateBigNumbers(body: PutApiAdminBigNumbersBody) {
    return client.put(hubCreditManagerEndpoints.admin.bigNumbers, body);
  },
  getConfigurableStatus() {
    return client.get<GetApiAdminConfigurableStatusResponse>(
      hubCreditManagerEndpoints.admin.configurableStatus.api,
    );
  },
  createConfigurableStatus(body: PostAdminConfigurableStatusBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.configurableStatus.api,
      body,
    );
  },
  updateConfigurableStatus(body: PutApiAdminConfigurableStatusUpdateBody) {
    return client.put(
      hubCreditManagerEndpoints.admin.configurableStatus.api,
      body,
    );
  },
  createForeignCurrencyIndex(body: PostAdminForeignCurrencyIndexCreateBody) {
    return client.post<PostAdminForeignCurrencyIndexCreateResponse>(
      hubCreditManagerEndpoints.admin.foreignCurrencyIndex.create,
      body,
    );
  },
  updateForeignCurrencyIndex(body: PostAdminForeignCurrencyIndexUpdateBody) {
    return client.post<PostAdminForeignCurrencyIndexUpdateResponse>(
      hubCreditManagerEndpoints.admin.foreignCurrencyIndex.update,
      body,
    );
  },
  updateSettlementInstruction(body: PostAdminSettlementInstructionUpdateBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.settlementInstruction.update,
      body,
    );
  },
  limitRequestsUpdateExpirationDate(
    body: PostAdminLimitRequestsUpdateExpirationDateBody,
  ) {
    return client.post(
      hubCreditManagerEndpoints.admin.limitRequests.updateExpirationDate,
      body,
    );
  },
  limitRequestsUpdateStatus(body: PostAdminLimitRequestsUpdateStatusBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.limitRequests.updateStatus,
      body,
    );
  },
  limitRequestsInactivesUpdateCreatingLimitStatus(
    body: PostAdminLimitRequestsUpdateCreatingLimitStatusBody,
  ) {
    return client.put(
      hubCreditManagerEndpoints.admin.limitRequests.updateCreatingLimitStatus,
      body,
    );
  },
  getAllPendingLimitRequests() {
    return client.get<GetApiAdminLimitRequestsGetAllPendingResponse>(
      hubCreditManagerEndpoints.admin.limitRequests.getAllPending,
    );
  },
  getLimitRequest(id: string) {
    return client.get<GetApiAdminLimitRequestsGetLimitRequestByIdResponse>(
      hubCreditManagerEndpoints.admin.limitRequests.getLimitRequest.byId.endpoint(
        id,
      ),
    );
  },
  getLimitRequestMinified(id: string) {
    return client.get<GetAdminLimitRequestMinifiedByIdResponse>(
      hubCreditManagerEndpoints.admin.limitRequests.minifiedById(id),
    );
  },
  getAdditionalFees(id: string) {
    return client.get<GetAdminLimitRequestByIdAdditionalFeesResponse>(
      hubCreditManagerEndpoints.admin.limitRequests.byId.additionalFees(id),
    );
  },
  getFinancialConditions(id: string) {
    return client.get<GetAdminLimitRequestsByIdFinancialConditionsResponse>(
      hubCreditManagerEndpoints.admin.limitRequests.byId.financialConditions(
        id,
      ),
    );
  },
  getLimitGuarantors(id: string) {
    return client.get<GetAdminLimitRequestsByIdGuarantorsResponse>(
      hubCreditManagerEndpoints.admin.limitRequests.byId.guarantors(id),
    );
  },
  getCollaterals(id: string) {
    return client.get<GetAdminLimitRequestsByIdCollateralsResponse>(
      hubCreditManagerEndpoints.admin.limitRequests.byId.collaterals(id),
    );
  },
  getPolicies(id: string) {
    return client.get<GetAdminPolicyByLimitRequestIdResponse>(
      hubCreditManagerEndpoints.admin.limitRequests.getLimitRequest.byId.limit.customer.legalEntity.policies(
        id,
      ),
    );
  },
  getContractTemplates(id: string) {
    return client.get<GetAdminLimitRequestsByIdContractTemplatesResponse>(
      hubCreditManagerEndpoints.admin.limitRequests.byId.contractTemplates(id),
    );
  },
  getDisbursementInstructions(id: string) {
    return client.get<GetAdminLimitRequestsByIdDisbursementInstructionsResponse>(
      hubCreditManagerEndpoints.admin.limitRequests.byId.disbursementInstructions(
        id,
      ),
    );
  },
  getExternalIdentifications(id: string) {
    return client.get<GetAdminLimitRequestsByIdExternalIdentificationsResponse>(
      hubCreditManagerEndpoints.admin.limitRequests.byId.externalIdentifications(
        id,
      ),
    );
  },
  getFinancedAssets(id: string) {
    return client.get<GetAdminLimitRequestsByIdFinancedAssetsResponse>(
      hubCreditManagerEndpoints.admin.limitRequests.byId.financedAssets(id),
    );
  },
  getInvoices(id: string) {
    return client.get<GetAdminLimitRequestsByIdInvoicesResponse>(
      hubCreditManagerEndpoints.admin.limitRequests.byId.invoices(id),
    );
  },
  updateAgroSacks(body: PostAdminLimitRequestsUpdateAgroSacksBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.limitRequests.updateAgroSacks,
      body,
    );
  },
  updateLimitRequestAmount(body: PostAdminLimitRequestsUpdateAmountBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.limitRequests.updateAmount,
      body,
    );
  },
  updateQuotesIndexcode(body: PutAdminLimitRequestsUpdateQuotesIndexcodeBody) {
    return client.put(
      hubCreditManagerEndpoints.admin.limitRequests.updateQuotesIndexcode,
      body,
    );
  },
  addLimit(body: PostAdminLimitsBody) {
    return client.post<PostApiAdminLimitsResponse>(
      hubCreditManagerEndpoints.admin.limits.api,
      body,
    );
  },
  getLimit(body: PostAdminLimitsGetLimitBody) {
    return client.post<PostAdminLimitsGetLimitResponse>(
      hubCreditManagerEndpoints.admin.limits.getLimit,
      body,
    );
  },
  fetchAllLimitsByTaxId(body: PostAdminLimitsGetAllLimitsByTaxIdBody) {
    return client.post<PostAdminLimitsGetAllLimitsByTaxIdResponse>(
      hubCreditManagerEndpoints.admin.limits.getAllLimitsByTaxId,
      body,
    );
  },
  approvalLimits(body: PostAdminLimitsApprovalBody) {
    return client.post(hubCreditManagerEndpoints.admin.limits.approval, body);
  },
  createCollateralDocument(
    body: PostApiAdminLimitsCreateCollateralDocumentBody,
  ) {
    return client.post<PostApiAdminLimitsCreateCollateralDocumentResponse>(
      hubCreditManagerEndpoints.admin.limits.createCollateralDocument,
      body,
    );
  },
  deleteCollateralDocument(id: string) {
    return client.delete(
      hubCreditManagerEndpoints.admin.limits.deleteCollateralDocument(id),
    );
  },
  upload(body: FormData) {
    return client.post<PostApiAdminFileUploadResponse>(
      hubCreditManagerEndpoints.admin.file.upload,
      body,
    );
  },
  download(body: PostAdminFileDownloadBody) {
    return client.post<Blob>(
      hubCreditManagerEndpoints.admin.file.download,
      body,
      {
        responseType: "blob",
      },
    );
  },
  transactionsByLimit(id: number) {
    return client.get<GetApiAdminTransactionByIdGetAllByLimitResponse>(
      hubCreditManagerEndpoints.admin.transaction.byId.getAllByLimit(id),
    );
  },
  reverseTransaction(body: PatchAdminTransactionReverseBody) {
    return client.patch<PatchApiAdminTransactionReverseResponse>(
      hubCreditManagerEndpoints.admin.transaction.reverse,
      body,
    );
  },
  createTransaction(body: PostAdminTransactionCreateBody) {
    return client.post<PostApiAdminTransactionCreateResponse>(
      hubCreditManagerEndpoints.admin.transaction.create,
      body,
    );
  },
  getCollateralDetails(collateralId: string) {
    return client.get<GetApiAdminCollateralByIdResponse>(
      hubCreditManagerEndpoints.admin.collateral.byId(collateralId),
    );
  },
  createCollateral(body: PostAdminCollateralCreateBody) {
    return client.post(hubCreditManagerEndpoints.admin.collateral.create, body);
  },
  updateCollateral(body: PostAdminCollateralUpdateBody) {
    return client.post(hubCreditManagerEndpoints.admin.collateral.update, body);
  },
  updateProperty(body: PostAdminPropertyUpdateBody) {
    return client.post(hubCreditManagerEndpoints.admin.property.update, body);
  },
  createAdditionalFee(body: PostAdminAdditionaFeeCreateBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.additionalFee.create,
      body,
    );
  },
  updateAdditionalFee(body: PostAdminAdditionaFeeUpdateBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.additionalFee.update,
      body,
    );
  },
  deleteAdditionalFee(body: PostAdminAdditionaFeeDeleteBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.additionalFee.delete,
      body,
    );
  },
  createContractTemplate(body: PostAdminContractTemplateCreateBody) {
    return client.post<PostApiAdminContractTemplateCreateResponse>(
      hubCreditManagerEndpoints.admin.contractTemplate.create,
      body,
    );
  },
  updateContractTemplate(body: PostAdminContractTemplateUpdateBody) {
    return client.post<PostApiAdminContractTemplateUpdateResponse>(
      hubCreditManagerEndpoints.admin.contractTemplate.update,
      body,
    );
  },
  deleteContractTemplate(contractTemplateId: string | number) {
    return client.delete(
      hubCreditManagerEndpoints.admin.contractTemplate.delete.byId(
        contractTemplateId,
      ),
    );
  },
  updateInvoice(body: PutAdminInvoiceBody) {
    return client.put(hubCreditManagerEndpoints.admin.invoice, body);
  },
  createGuarantor(body: PostGuarantorsCreateCreditLineGuarantorBody) {
    return client.post(
      hubCreditManagerEndpoints.guarantors.createCreditLineGuarantor,
      body,
    );
  },
  removeGuarantor(body: PatchAdminLimitRequestsRemoveGuarantorBody) {
    return client.patch(
      hubCreditManagerEndpoints.admin.limitRequests.removeGuarantor,
      body,
    );
  },
  getFinancedAsset(financedAssetId: string) {
    return client.get<GetApiAdminFinancedAssetByIdResponse>(
      hubCreditManagerEndpoints.admin.financedAsset.byId(financedAssetId),
    );
  },
  syncAgromatic(body: PostAdminLimitRequestSyncAgromaticBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.limitRequests.syncAgromatic,
      body,
    );
  },
  getFormDetails(id: string | number) {
    return client.get<GetApiAdminFormByIdResponse>(
      hubCreditManagerEndpoints.admin.form.byId(id),
    );
  },
  getWorkflowConfig() {
    return client.get<GetApiAdminWorkflowConfigResponse>(
      hubCreditManagerEndpoints.admin.workflowConfig.api,
    );
  },
  getDetailsPageType() {
    return client.get<string[]>(
      hubCreditManagerEndpoints.admin.workflowConfig.detailsPageType,
    );
  },
  addProcessorConfig(body: PostAdminWorkflowConfigAddProcessorConfigBody) {
    return client.post<PostAdminWorkflowConfigAddProcessorConfigResponse>(
      hubCreditManagerEndpoints.admin.workflowConfig.addProcessorConfig,
      body,
    );
  },
  addCanvasItem(body: PostAdminWorkflowConfigAddCanvasItemBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.workflowConfig.addCanvasItem,
      body,
    );
  },
  updateCanvasItem(body: PostAdminWorkflowConfigUpdateCanvasItemBody) {
    return client.put(
      hubCreditManagerEndpoints.admin.workflowConfig.updateCanvasItem,
      body,
    );
  },
  updateConvasItemsPosition(body: PutAdminConvasItemsPositionBody) {
    return client.put(
      hubCreditManagerEndpoints.admin.workflowConfig.updateCanvasItemPosition,
      body,
    );
  },
  exportCanvas(body: PostAdminWorkflowConfigExportCanvasBody) {
    return client.post<PostAdminWorkflowConfigExportCanvasResponse>(
      hubCreditManagerEndpoints.admin.workflowConfig.exportCanvas,
      body,
    );
  },
  importCanvas(body: PostAdminWorkflowConfigImportCanvasBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.workflowConfig.importCanvas,
      body,
    );
  },
  removeCanvasItem(body: PostAdminWorkflowConfigRemoveCanvasItemBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.workflowConfig.removeCanvasItem,
      body,
    );
  },
  getCreditLines() {
    return client.get<GetAdminCreditLinesResponse>(
      hubCreditManagerEndpoints.admin.creditLine.endpoint,
    );
  },
  getCreditLineConfigurations() {
    return client.get<GetAdminCreditLineConfigurationsResponse>(
      hubCreditManagerEndpoints.admin.creditLineConfiguration.endpoint,
    );
  },
  getCreditLineConfigurationById(id: string | number) {
    return client.get<GetAdminCreditLineConfigurationByIdResponse>(
      hubCreditManagerEndpoints.admin.creditLineConfiguration.byId(id),
    );
  },
  addCreditLineConfiguration(body: PostAdminCreditLineConfigurationCreateBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.creditLineConfiguration.create,
      body,
    );
  },
  updateCreditLineLineConfiguration(
    body: PutAdminCreditLineConfigurationUpdateBody,
  ) {
    return client.put(
      hubCreditManagerEndpoints.admin.creditLineConfiguration.update,
      body,
    );
  },
  getBooks() {
    return client.get<GetApiAdminBooksResponse>(
      hubCreditManagerEndpoints.admin.book.endpoint,
    );
  },
  addBook(body: PostAdminBookCreateBody) {
    return client.post(hubCreditManagerEndpoints.admin.book.create, body);
  },
  updateBook(body: PutAdminBookUpdateBody) {
    return client.put(hubCreditManagerEndpoints.admin.book.update, body);
  },
  deleteBook(body: PostAdminBookDeleteBody) {
    return client.post(hubCreditManagerEndpoints.admin.book.delete, body);
  },
  getStrategies() {
    return client.get<GetApiAdminStrategiesResponse>(
      hubCreditManagerEndpoints.admin.strategy.endpoint,
    );
  },
  addStrategy(body: PostAdminStrategyCreateBody) {
    return client.post(hubCreditManagerEndpoints.admin.strategy.create, body);
  },
  updateStrategy(body: PutAdminStrategyUpdateBody) {
    return client.put(hubCreditManagerEndpoints.admin.strategy.update, body);
  },
  deleteStrategy(body: PostAdminStrategyDeleteBody) {
    return client.post(hubCreditManagerEndpoints.admin.strategy.delete, body);
  },
  getBookStrategies() {
    return client.get<GetApiAdminBookStrategiesResponse>(
      hubCreditManagerEndpoints.admin.bookStrategy.endpoint,
    );
  },
  addStrategiesToBook(body: PostAdminAddStrategiesToBookBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.bookStrategy.addStrategiesToBook,
      body,
    );
  },
  deleteBookStrategy(body: PostAdminBookStrategyDeleteBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.bookStrategy.delete,
      body,
    );
  },
  getMinimumCriteria() {
    return client.get<GetApiAdminMinimumCriteriaResponse>(
      hubCreditManagerEndpoints.admin.minimumCriteria.endpoint,
    );
  },
  createMinimumCriteria(body: PostApiAdminMinimumCriteriaCreateResponse) {
    return client.post(
      hubCreditManagerEndpoints.admin.minimumCriteria.create,
      body,
    );
  },
  updateMinimumCriteria(body: PutApiAdminMinimumCriteriaBody) {
    return client.put(
      hubCreditManagerEndpoints.admin.minimumCriteria.update,
      body,
    );
  },
  deleteMinimumCriteria(body: PostAdminMinimumCriteriaDeleteBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.minimumCriteria.delete,
      body,
    );
  },
  getCommonDomain() {
    return client.get<GetApiAdminCommonDomainResponse>(
      hubCreditManagerEndpoints.admin.commonDomain.api,
    );
  },
  createCommonDomain(body: PostApiAdminCommonDomainCreateBody) {
    return client.post(hubCreditManagerEndpoints.admin.commonDomain.api, body);
  },
  updateCommonDomain(body: PutApiAdminCommonDomainBody) {
    return client.put(hubCreditManagerEndpoints.admin.commonDomain.api, body);
  },
  deleteCommonDomain(body: PostAdminCommonDomainDeleteBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.commonDomain.delete,
      body,
    );
  },
  getAllWorkflowConfigs() {
    return client.get<GetApiAdminWorkflowConfigByIdAllowedChildWorkflowConfigsResponse>(
      hubCreditManagerEndpoints.admin.workflowConfig.api,
    );
  },
  getAllowedChildWorkflowConfigs(id: number | string) {
    return client.get<GetApiAdminWorkflowConfigByIdAllowedChildWorkflowConfigsResponse>(
      hubCreditManagerEndpoints.admin.workflowConfig.byIdAllowedChilWorkflowConfigs(
        id,
      ),
    );
  },
  getChildWorkflowConfigs(id: number | string) {
    return client.get<GetApiAdminWorkflowConfigByIdChildWorkflowConfigsResponse>(
      hubCreditManagerEndpoints.admin.workflowConfig.byIdChildWorkflowConfigs(
        id,
      ),
    );
  },
  getChildProcessorConfigs(id: number | string) {
    return client.get<GetApiAdminWorkflowConfigByIdChildProcessorConfigsResponse>(
      hubCreditManagerEndpoints.admin.workflowConfig.byIdChildProcessorConfigs(
        id,
      ),
    );
  },
  getWorkflowConfigDetails(id: number | string) {
    return client.get<GetApiAdminWorkflowConfigByIdResponse>(
      hubCreditManagerEndpoints.admin.workflowConfig.byId(id),
    );
  },
  getWorkflowConfigHierarchyDetails(id: number | string) {
    return client.get<GetApiAdminWorkflowConfigHierarchyByIdResponse>(
      hubCreditManagerEndpoints.admin.workflowConfig.byIdHierarchy(id),
    );
  },
  getConfigurableGridColumns() {
    return client.get<GetApiAdminConfigurableGridColumnResponse>(
      hubCreditManagerEndpoints.admin.configurableGridColumn,
    );
  },
  editWorkflowConfig(body: PostApiAdminWorkflowConfigEditBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.workflowConfig.edit,
      body,
    );
  },
  editWorkflowConfigHierarchy(
    body: PostApiAdminWorkflowConfigHierarchyEditBody,
  ) {
    return client.post(
      hubCreditManagerEndpoints.admin.workflowConfig.hierarchy,
      body,
    );
  },
  getWorkflowConfigViewsByWorkflowConfigId(id: string | number) {
    return client.get<GetAdminWorkflowConfigViewsByWorkflowConfigIdResponse>(
      hubCreditManagerEndpoints.admin.workflowConfig.viewsById(id),
    );
  },
  getWorkflowConfigOffers(id: string | number) {
    return client.get<GetApiAdminWorkflowConfigOffersByIdResponse>(
      hubCreditManagerEndpoints.admin.workflowConfig.offersById(id),
    );
  },
  getOfferById(id: string | number) {
    return client.get<GetAdminOfferByIdResponse>(
      hubCreditManagerEndpoints.admin.offer.byId(id),
    );
  },
  addOffer(body: FormData) {
    return client.post(
      hubCreditManagerEndpoints.admin.workflowConfig.createOffer,
      body,
    );
  },
  updateOffer(body: FormData) {
    return client.put(hubCreditManagerEndpoints.admin.offer.update, body);
  },
  deleteOffer(body: PutAdminDeleteOfferBody) {
    return client.put(hubCreditManagerEndpoints.admin.offer.delete, body);
  },
  createWorkflowConfig(body: PostWorkflowConfigCreateBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.workflowConfig.create,
      body,
    );
  },
  getWorkflowConfigCanvasData(id: string | number) {
    return client.get<GetApiAdminWorkflowConfigByIdCanvasDataResponse>(
      hubCreditManagerEndpoints.admin.workflowConfig.byIdCanvasData(id),
    );
  },
  getProcessor() {
    return client.get<GetApiAdminProcessorResponse>(
      hubCreditManagerEndpoints.admin.processor.api,
    );
  },
  createProcessor(body: PostAdminProcessorBody) {
    return client.post(hubCreditManagerEndpoints.admin.processor.api, body);
  },
  updateProcessor(body: PutApiAdminProcessorBody) {
    return client.put<PutApiAdminProcessorResponse>(
      hubCreditManagerEndpoints.admin.processor.api,
      body,
    );
  },
  getConsumerUnitDetails(unitId: string) {
    return client.get<GetAdminUnitConsumerByUnitIdResponse>(
      hubCreditManagerEndpoints.admin.unitConsumer.byUnitId(unitId),
    );
  },
  downloadBatchFileTemplate(body: PostAdminBatchFileTemplateDownloadBody) {
    return client.post<Blob>(
      hubCreditManagerEndpoints.admin.batchFileTemplates.downloadTemplateAdmin,
      body,
      {
        responseType: "blob",
      },
    );
  },
  getBatchFileTemplates() {
    return client.get<GetApiAdminBatchFileTemplateResponse>(
      hubCreditManagerEndpoints.admin.batchFileTemplates.api,
    );
  },
  createBatchFileTemplate(body: PostAdminBatchFileTemplateBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.batchFileTemplates.api,
      body,
    );
  },
  removeBatchFileTemplate(id: string | number) {
    return client.delete(
      hubCreditManagerEndpoints.admin.batchFileTemplates.byId.api(id),
    );
  },
  getBatchFileTemplateColumns(id: string | number) {
    return client.get<GetApiAdminBatchFileTemplateColumnsResponse>(
      hubCreditManagerEndpoints.admin.batchFileTemplates.byId.templateColumns(
        id,
      ),
    );
  },
  addBatchFileTemplateColumns(
    body: PostApiAdminBatchFileTemplateColumnAddBody,
  ) {
    return client.post(
      hubCreditManagerEndpoints.admin.batchFileTemplateColumns.api,
      body,
    );
  },
  removeBatchFileTemplateColumns(
    body: PostApiAdminBatchFileTemplateColumnRemoveBody,
  ) {
    return client.post(
      hubCreditManagerEndpoints.admin.batchFileTemplateColumns.remove,
      body,
    );
  },
  createbatchColumns(body: PostAdminColumnsBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.configurableBatchFileColumns.api,
      body,
    );
  },
  editConfigurableBatchColumns(body: PutAdminColumnsBody) {
    return client.put(
      hubCreditManagerEndpoints.admin.configurableBatchFileColumns.api,
      body,
    );
  },
  getBatchConfigurableColumns() {
    return client.get<GetApiAdminBatchConfigurableColumnsResponse>(
      hubCreditManagerEndpoints.admin.configurableBatchFileColumns.api,
    );
  },
  removeConfigurableBatchColumns(id: string | number) {
    return client.delete(
      hubCreditManagerEndpoints.admin.configurableBatchFileColumns.byId(id),
    );
  },
  getBatchFileTemplatesDetails(id: string | number) {
    return client.get<GetApiAdminBatchFileTemplatesByIdResponse>(
      hubCreditManagerEndpoints.admin.batchFileTemplates.byId.api(id),
    );
  },
  editBatchFileTemplate(body: PutAdminBatchTemplateBody) {
    return client.put(
      hubCreditManagerEndpoints.admin.batchFileTemplates.api,
      body,
    );
  },
  getWorkflowLeadsBatchRequestsDetails(id: string | number) {
    return client.get<GetApiAdminWorkflowLeadsBatchRequestsByIdResponse>(
      hubCreditManagerEndpoints.admin.workflowLeadsBatchRequests.byId.api(id),
    );
  },
  getWorkflowLeadsBatchRequestFile(id: string | number) {
    return client.get<GetAdminWorkflowLeadsBatchRequestsByIdFileResponse>(
      hubCreditManagerEndpoints.admin.workflowLeadsBatchRequests.byId.downloadFile(
        id,
      ),
    );
  },
  reprocessWorkflowLeadsBatchRequest(id: string | number) {
    return client.get<PostApiAdminWorkflowLeadsBatchRequestByIdProcessResponse>(
      hubCreditManagerEndpoints.admin.workflowLeadsBatchRequests.byId.reprocess(
        id,
      ),
    );
  },
  updateWorkflowLeadsBatchRequestStatus(
    body: PutAdminWorkflowLeadsBatchRequestStatus,
  ) {
    return client.put(
      hubCreditManagerEndpoints.admin.workflowLeadsBatchRequests.updateBatch,
      body,
    );
  },
  saveConsumerUnit(body: PutAdminUnitConsumerBody) {
    return client.put(
      hubCreditManagerEndpoints.admin.unitConsumer.endpoint,
      body,
    );
  },
  getWorkflowLeadsDetails(id: string | number) {
    return client.get<GetApiAdminWorkflowLeadsByIdResponse>(
      hubCreditManagerEndpoints.admin.workflowLeads.byId.api(id),
    );
  },
  getWorkflowLeadsSummary(id: string | number) {
    return client.get<GetApiAdminWorkflowLeadsByIdSummaryResponse>(
      hubCreditManagerEndpoints.admin.workflowLeads.byId.summary(id),
    );
  },
  getWorkflowLeadsPendencies(id: string | number) {
    return client.get<GetApiAdminWorkflowLeadsByIdPendenciesResponse>(
      hubCreditManagerEndpoints.admin.workflowLeads.byId.pendencies(id),
    );
  },
  getWorkflowLeadsEnergyConsumerUnits(id: string | number) {
    return client.get<GetApiAdminWorkflowLeadsByIdEnergyConsumerUnitsResponse>(
      hubCreditManagerEndpoints.admin.workflowLeads.byId.energyConsumerUnits(
        id,
      ),
    );
  },
  getWorkflowLeadsStateHistory(id: string | number) {
    return client.get<GetApiAdminWorkflowLeadsByIdStateHistoryResponse>(
      hubCreditManagerEndpoints.admin.workflowLeads.byId.stateHistory(id),
    );
  },
  getWorkflowLeadsProcessorsExecuting(id: string | number) {
    return client.get<GetApiAdminWorkflowLeadsByIdProcessorsExecutingResponse>(
      hubCreditManagerEndpoints.admin.workflowLeads.byId.processorsExecuting(
        id,
      ),
    );
  },
  getWorkflowLeadPendencies(id: string | number) {
    return client.get<GetAdminWorkflowLeadsByIdPendenciesResponse>(
      hubCreditManagerEndpoints.admin.workflowLeads.byId.pendencies(id),
    );
  },
  getWorkflowLeadDocuments(id: string | number) {
    return client.get<GetAdminWorkflowLeadsByIdDocumentsResponse>(
      hubCreditManagerEndpoints.admin.workflowLeads.byId.documents(id),
    );
  },
  getWorkflowLeadProcessorContexts(id: string | number) {
    return client.get<GetAdminWorkflowLeadsByIdProcessorContextsResponse>(
      hubCreditManagerEndpoints.admin.workflowLeads.byId.processorContexts(id),
    );
  },
  getWorkflowLeadProcessorUserActions(id: string | number) {
    return client.get<GetAdminWorkflowLeadsByIdUserActionsResponse>(
      hubCreditManagerEndpoints.admin.workflowLeads.byId.userActions(id),
    );
  },
  getWorkflowLeadProposalGroups(id: string | number) {
    return client.get<GetApiAdminWorkflowLeadsByIdProposalGroupsByIdResponse>(
      hubCreditManagerEndpoints.admin.workflowLeads.byId.proposalGroups(id),
    );
  },
  getWorkflowLeadCreditLines(id: string | number) {
    return client.get<GetApiAdminWorkflowLeadsByIdCreditLinesByIdResponse>(
      hubCreditManagerEndpoints.admin.workflowLeads.byId.creditLines(id),
    );
  },
  getWorkflowLeadProposedCreditLine(
    id: string | number,
    proposedCreditLineId: string | number,
  ) {
    return client.get<GetApiAdminWorkflowLeadsProposedCreditLineByIdResponse>(
      hubCreditManagerEndpoints.admin.workflowLeads.byId.proposedCreditLine.byId(
        id,
        proposedCreditLineId,
      ),
    );
  },
  republishWorkflowStatus(body: PostAdminRepublishWorkflowStatusBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.workflowLeads.republishLeadStatus,
      body,
    );
  },
  deleteWorkflowLead(body: PostAdminDeleteWorkflowLeadBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.workflowLeads.deleteWorkflowLead,
      body,
    );
  },
  republishLeadToCreditEngine(
    body: PostAdminRepublishWorkflowCreditEngineBody,
  ) {
    return client.post(
      hubCreditManagerEndpoints.admin.workflowLeads.republishLeadToCreditEngine,
      body,
    );
  },
  sendUserActionRequestNotification(
    body: PostAdminUserActionRequestSendNotificationBody,
  ) {
    return client.post(
      hubCreditManagerEndpoints.admin.userActionRequest.sendNotification,
      body,
    );
  },
  sendLeadApproval(body: PostAdminWorkflowLeadsApprovalBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.workflowLeads.approval,
      body,
    );
  },
  getSelectedProposal(body: PostAdminEnergyWorkflowGetSelectedProposal) {
    return client.post(
      hubCreditManagerEndpoints.admin.energyWorkflow.getSelectedProposal,
      body,
    );
  },
  getSelectedOriginators(
    body: PostAdminWorkflowDashboardGetSelectedOriginatorsBody,
  ) {
    return client.post<PostAdminWorkflowDashboardGetSelectedOriginatorsResponse>(
      hubCreditManagerEndpoints.admin.workflowDashboard.data,
      body,
    );
  },
  getOriginatorsByLead(
    body: PostAdminWorkflowDashboardGetOriginatorsByLeadBody,
  ) {
    return client.post<PostAdminWorkflowDashboardGetOriginatorsByLeadResponse>(
      hubCreditManagerEndpoints.admin.originators.producingLeads,
      body,
    );
  },
  addWorkflowLeadsPendency(body: PostApiAdminWorkflowLeadsAddPendencyBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.workflowLeads.addPendency,
      body,
    );
  },
  deleteWorkflowLeadPendency(
    body: PostApiAdminWorkflowLeadsDeletePendencyBody,
  ) {
    return client.post(
      hubCreditManagerEndpoints.admin.workflowLeads.deletePendency,
      body,
    );
  },
  getProcessorDetails(id: string | number) {
    return client.get<GetApiAdminProcessorByIdResponse>(
      hubCreditManagerEndpoints.admin.processor.byId.api(id),
    );
  },
  getProcessorOutputs(id: string | number) {
    return client.get<GetAdminProcessorByIdOutputsResponse>(
      hubCreditManagerEndpoints.admin.processor.byId.outputs(id),
    );
  },
  updateProcessorContextStatus(body: PutAdminProcessorContextUpdateStatusBody) {
    return client.put(
      hubCreditManagerEndpoints.admin.processorContext.updateStatus,
      body,
    );
  },
  addProcessorContext(body: PostAdminProcessorContextAddBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.processorContext.add,
      body,
    );
  },
  addProcessorProperty(body: PostApiAdminProcessorPropertyAddBody) {
    return client.post<PostApiAdminProcessorPropertyAddResponse>(
      hubCreditManagerEndpoints.admin.processor.property.add,
      body,
    );
  },
  editProcessorProperty(body: PutApiAdminProcessorPropertyUpdateBody) {
    return client.put<PutApiAdminProcessorPropertyUpdateResponse>(
      hubCreditManagerEndpoints.admin.processor.property.edit,
      body,
    );
  },
  editProcessorOutput(body: PutApiAdminProcessorOutputEditBody) {
    return client.put(
      hubCreditManagerEndpoints.admin.processor.output.edit,
      body,
    );
  },
  addProcessorOutput(body: PostApiAdminProcessorOutputAddBody) {
    return client.post<PostApiAdminProcessorOutputAddResponse>(
      hubCreditManagerEndpoints.admin.processor.output.add,
      body,
    );
  },
  removeProcessorProperty(body: PostApiAdminProcessorPropertyRemoveBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.processor.property.remove,
      body,
    );
  },
  removeProcessorOutput(body: PostApiAdminProcessorOutputRemoveBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.processor.output.remove,
      body,
    );
  },
  createCondition(body: PostApiAdminConditionCreateBody) {
    return client.post(hubCreditManagerEndpoints.admin.condition.create, body);
  },
  updateCondition(body: PutApiAdminConditionUpdateBody) {
    return client.put<PutApiAdminConditionUpdateResponse>(
      hubCreditManagerEndpoints.admin.condition.update,
      body,
    );
  },
  executeCondition(body: PostApiAdminConditionExecuteBody) {
    return client.post<PostApiAdminConditionExecuteResponse>(
      hubCreditManagerEndpoints.admin.condition.execute,
      body,
    );
  },
  getCustomerOriginator(params?: GetApiAdminCustomerOriginatorParams) {
    return client.get<GetApiAdminCustomerOriginatorResponse>(
      hubCreditManagerEndpoints.admin.customerOriginator,
      { params },
    );
  },
  createCustomerOriginator(body: PostAdminCustomerOriginatorBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.customerOriginator,
      body,
    );
  },
  addProcessorConfigCondition(body: PostAdminProcessorConfigConditionAddBody) {
    return client.post<PostAdminProcessorConfigConditionAddResponse>(
      hubCreditManagerEndpoints.admin.processorConfig.condition.add,
      body,
    );
  },
  getProcessorConfigDetails(id: string | number) {
    return client.get<GetAdminProcessorConfigByIdResponse>(
      hubCreditManagerEndpoints.admin.processorConfig.byId(id),
    );
  },
  getProcessorConfigByIds(body: PostAdminProcessorConfigIdsBody) {
    return client.post<GetAdminProcessorConfigByIdResponse[]>(
      hubCreditManagerEndpoints.admin.processorConfig.byIds,
      body,
    );
  },
  getProcessorConfigsByWorkflowLeadId(id: string | number) {
    return client.get<GetAdminProcessorConfigByIdResponse[]>(
      hubCreditManagerEndpoints.admin.processorConfig.byWorkflowLeadId(id),
    );
  },
  getProcessorConfigsByWorkflowConfigId(id: string | number) {
    return client.get<GetAdminProcessorConfigByWorflowIdResponse[]>(
      hubCreditManagerEndpoints.admin.processorConfig.byWorkflowConfigId(id),
    );
  },
  removeProcessorConfigCondition(
    body: PostAdminProcessorConfigConditionRemoveBody,
  ) {
    return client.post(
      hubCreditManagerEndpoints.admin.processorConfig.condition.remove,
      body,
    );
  },
  removeProcessorConfigRelationship(
    body: PostAdminProcessorConfigRelationshipRemoveBody,
  ) {
    return client.post(
      hubCreditManagerEndpoints.admin.processorConfig.relationship.remove,
      body,
    );
  },
  createProcessorConfigRelationship(
    body: PostAdminProcessorConfigRelationshipCreateBody,
  ) {
    return client.post<PostAdminProcessorConfigRelationshipCreateResponse>(
      hubCreditManagerEndpoints.admin.processorConfig.relationship.create,
      body,
    );
  },
  updateProcessorConfigProperty(body: PostAdminProcessorConfigPropertyUpdate) {
    return client.post(
      hubCreditManagerEndpoints.admin.processorConfig.property.update,
      body,
    );
  },
  getProcessorConfigDetailsBasicData(id: string | number) {
    return client.get<GetAdminProcessorConfigBasicDataResponse>(
      hubCreditManagerEndpoints.admin.processorConfig.basicDataById(id),
    );
  },
  getProcessorConfigDetailsSettings(id: string | number) {
    return client.get<GetAdminProcessorConfigSettingsResponse>(
      hubCreditManagerEndpoints.admin.processorConfig.seetingsById(id),
    );
  },
  getProcessorConfigDetailsUserActions(id: string | number) {
    return client.get<GetAdminProcessorConfigUserActionsResponse>(
      hubCreditManagerEndpoints.admin.processorConfig.userActionsById(id),
    );
  },
  getProcessorConfigDetailsChannel(id: string | number) {
    return client.get<GetAdminProcessorConfigChannelResponse>(
      hubCreditManagerEndpoints.admin.processorConfig.channelById(id),
    );
  },
  getProcessorConfigDetailsProperties(id: string | number) {
    return client.get<GetAdminProcessorConfigPropertyResponse[]>(
      hubCreditManagerEndpoints.admin.processorConfig.propertiesById(id),
    );
  },
  getProcessorConfigDetailsMonitoring(id: string | number) {
    return client.get<ProcessorConfigViewResponse[]>(
      hubCreditManagerEndpoints.admin.processorConfig.monitoringById(id),
    );
  },
  updateProcessorConfigDetailsBasicData(
    body: PutAdminProcessorConfigBasicDataBody,
  ) {
    return client.put(
      hubCreditManagerEndpoints.admin.processorConfig.basicData,
      body,
    );
  },
  updateProcessorConfigDetailsSettings(
    body: PutAdminProcessorConfigSettingsBody,
  ) {
    return client.put(
      hubCreditManagerEndpoints.admin.processorConfig.settings,
      body,
    );
  },
  updateProcessorConfigDetailsUserActions(
    body: PutAdminProcessorConfigUserActionsBody,
  ) {
    return client.put(
      hubCreditManagerEndpoints.admin.processorConfig.userActions,
      body,
    );
  },
  updateProcessorConfigDetailsChannel(
    body: PutAdminProcessorConfigChannelBody,
  ) {
    return client.put(
      hubCreditManagerEndpoints.admin.processorConfig.channel,
      body,
    );
  },
  getChannels() {
    return client.get<GetAdminChannelResponse>(
      hubCreditManagerEndpoints.admin.channel,
    );
  },
  removeCollateral(id: number) {
    return client.delete(hubCreditManagerEndpoints.admin.collateral.byId(id));
  },
  getWorkflowConfigViewById(id: string) {
    return client.get<GetAdminWorkflowConfigViewByIdResponse>(
      hubCreditManagerEndpoints.admin.workflowConfigView.byId(id),
    );
  },
  createWorkflowConfigView(body: PostApiAdminWorkflowConfigViewBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.workflowConfigView.api,
      body,
    );
  },
  updateWorkflowConfigViewDefaultGrid(
    body: PutApiAdminWorkflowConfigViewUpdateDefaultGridBody,
  ) {
    return client.put(
      hubCreditManagerEndpoints.admin.workflowConfigView.updateDefaultGrid,
      body,
    );
  },
  orderWorkflowConfigViewBigNumbers(
    body: PutAdminWorkflowConfigViewUpdateViewBigNumbersBody,
  ) {
    return client.put(
      hubCreditManagerEndpoints.admin.workflowConfigView.updateViewBigNumbers,
      body,
    );
  },
  getViewBigNumberById(id: string | number) {
    return client.get<GetAdminViewBigNumberByIdResponse>(
      hubCreditManagerEndpoints.admin.viewBigNumber.byId(id),
    );
  },
  updateViewBigNumberGrid(body: PutAdminViewBigNumberUpdateGridBody) {
    return client.put(
      hubCreditManagerEndpoints.admin.viewBigNumber.updateGrid,
      body,
    );
  },
  upsertOutputConfigStatus(
    body: PutAdminProcessorConfigViewOutputConfigStatusBody,
  ) {
    return client.put(
      hubCreditManagerEndpoints.admin.processorConfigView.outputConfigStatus,
      body,
    );
  },
  upsertOutputConfigBigNumbers(
    body: PutAdminProcessorConfigViewOutputConfigBigNumbersBody,
  ) {
    return client.put(
      hubCreditManagerEndpoints.admin.processorConfigView
        .outputConfigBigNumbers,
      body,
    );
  },
  reevaluateLimit(body: PostAdminLimitReevaluateBody) {
    return client.post(hubCreditManagerEndpoints.admin.limits.reevaluate, body);
  },
  deactivateLimit(body: PostAdminLimitDeactivateBody) {
    return client.post(hubCreditManagerEndpoints.admin.limits.deactivate, body);
  },
  getAllCprIds() {
    return client.get<GetAdminAllCprIdsResponse>(
      hubCreditManagerEndpoints.admin.limitRequests.getAllCprIds,
    );
  },
  linkToCpr(body: PostAdminLinkToCprBody) {
    return client.post(
      hubCreditManagerEndpoints.admin.limitRequests.linkToCpr,
      body,
    );
  },
  getMinimumCriteriaColumns() {
    return client.get<GetApiAdminMinimumCriteriaColumnResponse>(
      hubCreditManagerEndpoints.admin.configurableGridColumn,
    );
  },
  createMinimumCriteriaColumns(body: PostApiAdminConditionCreateBody) {
    return client.post(hubCreditManagerEndpoints.admin.condition.create, body);
  },
  updateMinimumCriteriaColumns(body: PutApiAdminConditionUpdateBody) {
    return client.put<PutApiAdminConditionUpdateResponse>(
      hubCreditManagerEndpoints.admin.condition.update,
      body,
    );
  },
  getRmAdminMainOriginator() {
    return client.get<GetApiRmAdminMainOriginatorResponse>(
      hubCreditManagerEndpoints.rmAdmin.originator.api,
    );
  },
  getWorkflowLeadExportRequests() {
    return client.get<GetApiAdminWorkflowLeadExportRequestsResponse>(
      hubCreditManagerEndpoints.admin.exportRequest.api,
    );
  },
  rejectWorkflowLeadExportRequest(
    body: PatchApiAdminWorkflowLeadExportRequestsReject,
  ) {
    return client.patch(
      hubCreditManagerEndpoints.admin.exportRequest.reject,
      body,
    );
  },
  getScoreModules() {
    return client.get<GetApiAdminScoreModulesResponse>(
      hubCreditManagerEndpoints.admin.scoreModules.api,
    );
  },
  getScoreModuleById(id: string | number) {
    return client.get<GetApiAdminScoreModuleByIdResponse>(
      hubCreditManagerEndpoints.admin.scoreModules.byId(id),
    );
  },
  getScoreBenefits() {
    return client.get<GetApiAdminScoreBenefitsResponse>(
      hubCreditManagerEndpoints.admin.scoreBenefits.api,
    );
  },
  getScoreBenefitById(id: string | number) {
    return client.get<GetApiAdminScoreBenefitByIdResponse>(
      hubCreditManagerEndpoints.admin.scoreBenefits.byId(id),
    );
  },
  getScoreLevels() {
    return client.get<GetApiAdminScoreLevelsResponse>(
      hubCreditManagerEndpoints.admin.scoreLevels.api,
    );
  },
  updateScoreModulesPositions(
    body: PutApiAdminScoreModulesUpdatePositionsBody,
  ) {
    return client.put(
      hubCreditManagerEndpoints.admin.scoreModules.updateOrders,
      body,
    );
  },
  updateScoreBenefitsPositions(
    body: PutApiAdminScoreBenefitsUpdatePositionsBody,
  ) {
    return client.put(
      hubCreditManagerEndpoints.admin.scoreBenefits.updateOrders,
      body,
    );
  },
  updateScoreBenefit(body: PutApiAdminScoreBenefitUpdateBody) {
    return client.put(hubCreditManagerEndpoints.admin.scoreBenefits.api, body);
  },
  updateScoreModule(body: PutApiAdminScoreModuleUpdateBody) {
    return client.put(hubCreditManagerEndpoints.admin.scoreModules.api, body);
  },
  createScoreBenefit(body: PostApiAdminScoreBenefitCreateBody) {
    return client.post(hubCreditManagerEndpoints.admin.scoreBenefits.api, body);
  },
  createScoreModule(body: PostApiAdminScoreModuleCreateBody) {
    return client.post(hubCreditManagerEndpoints.admin.scoreModules.api, body);
  },
  createScoreLevel(body: PostApiAdminScoreLevelCreateBody) {
    return client.post(hubCreditManagerEndpoints.admin.scoreLevels.api, body);
  },
  deleteScoreBenefit(id: string | number) {
    return client.delete(
      hubCreditManagerEndpoints.admin.scoreBenefits.byId(id),
    );
  },
  odata: hubCreditManagerODataService,
};
