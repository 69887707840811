import { OCard, OCardBody } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { DetailsWithoutCard } from "components/details-card";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useRoles } from "hooks/roles";
import { useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { DetailsTemplate } from "templates/details-template";
import { GridTemplate } from "templates/grid-template";
import { ButtonWithConfirmation } from "components/button-with-confirmation";
import {
  AddDiligenceStepButton,
  ApproveDiligenceConclusionButton,
  ApproveDiligenceStartButton,
} from "./_compose";
import { diligenceFields } from "./diligence.details";
import { diligenceStepsGrid } from "./diligence-steps.grid";
import { useDiligenceSteps } from "./diligence-steps.hook";
import { energiaRoles } from "../../../../../roles/energia.roles";
import { ValidationErrorComponent } from "../../../../../components/validation-error-component";

export const DiligenceStepsPage = () => {
  const gridRef = useGridRef();
  const { hasRole } = useRoles();

  const {
    consumerUnitId,
    dataSource,
    diligenceError,
    diligenceHasError,
    diligenceLoading,
    diligenceValue,
    form,
    submitLoading,
    submit,
  } = useDiligenceSteps();

  const grid = useMemo(
    () => diligenceStepsGrid(consumerUnitId),
    [consumerUnitId],
  );

  return (
    <div className="d-flex flex-column gap-4">
      <DetailsTemplate
        pageTitle={<PageTitle title="Migração" />}
        actions={
          <>
            {diligenceValue &&
              hasRole(energiaRoles.customer.diligenceStepsAdd.role) && (
                <AddDiligenceStepButton
                  diligenceId={diligenceValue.response.id}
                  reload={() => dataSource.reload()}
                />
              )}
            {diligenceValue?.response.status ===
              "PENDENTE_APROVACAO_DILIGENCIA" &&
              hasRole(energiaRoles.customer.diligenceStepsApprove.role) && (
                <ApproveDiligenceStartButton
                  diligenceId={diligenceValue.response.id}
                  reload={() => dataSource.reload()}
                />
              )}
            {diligenceValue?.response.status === "EM_ANALISE_OPS" &&
              hasRole(energiaRoles.customer.diligenceStepsApprove.role) && (
                <ApproveDiligenceConclusionButton
                  diligenceId={diligenceValue.response.id}
                  reload={() => dataSource.reload()}
                />
              )}
            {hasRole(energiaRoles.customer.migrationDateUpdate.role) && (
              <ButtonWithConfirmation
                confirmationModal={{
                  title: "Tem certeza que deseja alterar a data de migração?",
                  loading: submitLoading,
                }}
                onConfirm={submit}
                disabled={submitLoading || diligenceHasError}
              >
                Salvar Data de Migração
              </ButtonWithConfirmation>
            )}
          </>
        }
      >
        <FormProvider {...form}>
          <OCard>
            <OCardBody className="position-relative">
              <DetailsWithoutCard
                loading={diligenceLoading}
                hasError={diligenceHasError}
                errorComponent={
                  <OCard>
                    <OCardBody>
                      <ValidationErrorComponent
                        error={diligenceError}
                        title="Erro ao buscar a migração"
                        callback={() => dataSource.reload()}
                      />
                    </OCardBody>
                  </OCard>
                }
                value={diligenceValue?.response?.consumerUnit}
                fields={diligenceFields}
              />
            </OCardBody>
          </OCard>
        </FormProvider>
      </DetailsTemplate>
      <GridTemplate gridRef={gridRef} showRefreshButton showClearFiltersButton>
        <ODataGridGenerator
          gridRef={gridRef}
          grid={grid}
          dataSource={dataSource}
        />
      </GridTemplate>
    </div>
  );
};
