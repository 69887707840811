import * as yup from "yup";
import { InteractionFinancialAddForm } from "./interaction-financial-add.type";

export const interactionFinancialAddValidationSchema: yup.ObjectSchema<
  Omit<InteractionFinancialAddForm, "id">
> = yup.object({
  cellPhoneNumber: yup.array().required("Este campo é obrigatório"),
  email: yup.array().required("Este campo é obrigatório"),
  name: yup.string().required("Este campo é obrigatório"),
});

export const interactionFinancialAddDefaultValues: InteractionFinancialAddForm =
  {
    cellPhoneNumber: [""],
    email: [""],
    id: "",
    name: "",
  };
