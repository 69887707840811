import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { modalManagerPayload } from "utils/modal-manager-payload";
import { getValidationMessages } from "../../../../../../../../../../utils";
import {
  MoveStepPropertyForm,
  checkboxValue,
  moveStepPropertyFormDefaultValues,
  moveStepPropertyFormValidationSchema,
} from "./move-step-property-modal.form";
import { moveStepPropertyModalId } from "./move-step-property-modal.utils";

export const useMoveStepPropertyModal = (
  stepId: number,
  reload: () => void,
) => {
  const [property, setProperty] =
    useState<HubEnergy.DiligenceStepPropertyResponse>();

  const { callService, loading } = useServiceCall(
    service.hubEnergy.moveStepProperty,
  );

  const form = useForm<MoveStepPropertyForm>({
    defaultValues: moveStepPropertyFormDefaultValues,
    resolver: yupResolver(moveStepPropertyFormValidationSchema),
  });

  const { handleSubmit, reset } = form;

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        if (!property) return OToastManager.danger("Nenhuma propriedade");

        const { success, error } = await callService({
          destinationStepId: Number(values.destinationStepId),
          diligenceStepPropertyId: Number(property.id),
          isAutomation: values.isAutomation,
          isPublic: !!values.isPublic?.length,
          documentTypeId: values.documentTypeId,
          minimumAmount: values.minimumAmount,
          typeId: values.typeId,
        });

        if (success) {
          OToastManager.success("Propriedade movida com sucesso");
          reload();
          modalManagerPayload.hide(moveStepPropertyModalId);
        } else {
          OToastManager.success(
            getValidationMessages(error)?.[0]?.ErrorMessage ??
              "Erro ao mover a propriedade",
          );
        }
      }),
    [callService, property, handleSubmit, reload],
  );

  const initializeForm = useCallback(
    () =>
      reset(
        property
          ? {
              destinationStepId: stepId,
              documentTypeId: property.document?.type.id,
              isAutomation: property.isAutomation,
              isPublic: property.isPublic ? [checkboxValue] : [],
              minimumAmount: 1,
              typeId: property.type.id,
            }
          : moveStepPropertyFormDefaultValues,
      ),
    [property, reset, stepId],
  );

  useEffect(() => {
    initializeForm();
  }, [initializeForm]);

  useEffect(() => {
    const cleanup =
      modalManagerPayload.on<HubEnergy.DiligenceStepPropertyResponse>(
        moveStepPropertyModalId,
        "modalOpen",
        setProperty,
      );
    return cleanup;
  }, []);

  useEffect(() => {
    const cleanup = modalManagerPayload.on(
      moveStepPropertyModalId,
      "modalClose",
      () => setProperty(undefined),
    );
    return cleanup;
  }, []);

  return {
    form,
    loading,
    property,
    submit,
  };
};
