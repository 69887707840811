import dayjs from "dayjs";
import { ReceivableInfo } from "./operation-details.types";

const sum = (a: number, b: number) => a + b;

/** taxaMedia = SOMA(taxaTotal x 30 x valorBruto) / SOMA(prazo x valorBruto) */
export const calculateAvgMonthlyRate = (receivables: ReceivableInfo[]) => {
  if (receivables.length === 0) return 0;

  const numerator = receivables
    .map(
      ({ valorVencimento: valorBruto, taxaTotal }) =>
        30 * valorBruto * (taxaTotal ?? 0),
    )
    .reduce(sum, 0);

  const denominator = receivables
    .map(
      ({ valorVencimento: valorBruto, dataVencimento }) =>
        valorBruto * dayjs(dataVencimento).diff(dayjs().startOf("day"), "day"),
    )
    .reduce(sum, 0);

  return numerator / denominator;
};

/** prazoMedio = SOMA(prazo x valorBruto) / SOMA(valorBruto) */
export const calculateAvgTerm = (receivables: ReceivableInfo[]) => {
  if (receivables.length === 0) return 0;

  const numerator = receivables
    .map(
      ({ valorVencimento: valorBruto, dataVencimento }) =>
        valorBruto * dayjs(dataVencimento).diff(dayjs().startOf("day"), "day"),
    )
    .reduce(sum, 0);

  const denominator = receivables
    .map(({ valorVencimento: valorBruto }) => valorBruto)
    .reduce(sum, 0);

  return Math.ceil(numerator / denominator);
};
