import { OToastManager } from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { service } from "services";
import { useBankslipContext } from "../../bankslip.context";
import { BankslipCip } from "./bankslip-details-drawer.types";

const getParsedCip = (payload: string): BankslipCip | null => {
  if (!payload) {
    return null;
  }

  try {
    const parsedPayload = JSON.parse(payload);
    return parsedPayload;
  } catch {
    OToastManager.danger("Erro ao buscar dados na CIP.");
    return null;
  }
};

const isBankslipValidCipPayment = (payload: string) => {
  const parsedValue = getParsedCip(payload);
  if (!parsedValue) {
    return false;
  }

  return !!parsedValue.DataConsumer.ValidaPagamento.BoletoValidoPagamento;
};

export const useBankslipDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { detailedBankslip } = useBankslipContext();
  const [validOnCIP, setValidOnCIP] = useState(false);

  const getDetails = useCallback(async () => {
    try {
      setValidOnCIP(false);
      if (!detailedBankslip?.detail?.digitableLine) {
        return;
      }

      setIsLoading(true);

      const { data: cipValidation } =
        await service.adminBankinghub.getBankslipCIP(
          detailedBankslip.detail.digitableLine ?? "",
        );

      setValidOnCIP(isBankslipValidCipPayment(cipValidation.payload));
    } catch {
      OToastManager.danger("Ocorreu um erro ao consultar detalhes do boleto");
    } finally {
      setIsLoading(false);
    }
  }, [detailedBankslip]);

  useEffect(() => {
    getDetails();
  }, [detailedBankslip, getDetails]);

  return { isLoading, validOnCIP };
};
