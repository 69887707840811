import { OCol, OLoader, ORFieldInput, ORow } from "@maestro/react";
import { BankOption } from "../../bank-option";
import { useRemunerationFields } from "./remuneration-fields.hook";

interface RemunerationFieldsProps {
  editingTaxId?: string;
}

export const RemunerationFields = ({
  editingTaxId,
}: RemunerationFieldsProps) => {
  const {
    bankAccounts,
    loading,
    isOtherAccount,
    selectedOption,
    setSelectedOption,
  } = useRemunerationFields(editingTaxId);

  return (
    <div className="d-flex flex-column gap-3">
      {loading ? (
        <OLoader size="lg" />
      ) : (
        <ORow className="d-flex flex-row gap-2">
          <OCol xs={12} md={3} lg={2}>
            <BankOption
              uuid="custom"
              active={selectedOption === "custom"}
              onClick={() => setSelectedOption("custom")}
              title="Outra conta"
              description="Informe os dados bancários do originador nos campos abaixo."
            />
          </OCol>
          {bankAccounts?.map((account) => (
            <OCol
              xs={12}
              md={3}
              lg={2}
              key={`${account.bank}_${account.branch}_${account.number}`}
            >
              <BankOption
                uuid="custom"
                active={
                  selectedOption ===
                  `${account.bank}_${account.branch}_${account.number}`
                }
                onClick={() =>
                  setSelectedOption(
                    `${account.bank}_${account.branch}_${account.number}`,
                  )
                }
                bank={account.bank}
                agency={account.branch}
                account={account.number}
              />
            </OCol>
          ))}
        </ORow>
      )}
      <ORow>
        <OCol lg={4} xs={12}>
          <ORFieldInput
            tag="text"
            id="account.bank"
            name="account.bank"
            label="Código do banco"
            required
            disabled={!isOtherAccount || loading}
          />
        </OCol>
        <OCol lg={4} xs={12}>
          <ORFieldInput
            tag="text"
            id="account.branch"
            name="account.branch"
            label="Agência"
            required
            disabled={!isOtherAccount || loading}
          />
        </OCol>
        <OCol lg={4} xs={12}>
          <ORFieldInput
            tag="text"
            id="account.number"
            name="account.number"
            label="Conta com o dígito"
            required
            disabled={!isOtherAccount || loading}
          />
        </OCol>
        <OCol lg={6} xs={12}>
          <ORFieldInput
            tag="text"
            id="mirror.mirrorBook"
            name="mirror.mirrorBook"
            label="MIrror book"
          />
        </OCol>
        <OCol lg={6} xs={12}>
          <ORFieldInput
            tag="text"
            id="mirror.mirrorStrategy"
            name="mirror.mirrorStrategy"
            label="Mirror strategy"
          />
        </OCol>
      </ORow>
    </div>
  );
};
