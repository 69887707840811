import { client } from "../client";
import { fidcEndpoints } from "./fidc.endpoints";
import {
  GetBatchByBatchIdGuaranteeXlsxV2Params,
  GetBatchByBatchIdTermsParams,
  GetBatchByIdSubmitAdminParams,
  GetBatchGetByCnpjParams,
  GetContractGeneratorByProductIdParams,
  GetDashboardBankslipByBankslipIdParams,
  GetDashboardBatchesParams,
  GetDashboardChargesParams,
  GetDashboardOverallStatusParams,
  GetSnakeOdataInteractionsOdataParams,
  GetV1NextBusDayParams,
  PostAmbevGenerateAllNotificationsBody,
  PostBatchAdminBody,
  PostBatchDueDateParams,
  PostContractGeneratorBody,
  PostSendNotificationFundBody,
  PostSnakeFinancialBody,
  PostSnakeInteractionTrackingBody,
  PutSnakeFinancialByFinancialIdBody,
  PutV1BankslipBody,
} from "./models/requests";
import { PathDisverbationStatusBody } from "./models/requests/path-disverbation-status";
import { PostSendDisverbationBody } from "./models/requests/post-send-disverbation.request";
import {
  GetAmbevNotificationsResponse,
  GetBatchGetByCnpjResponse,
  GetContractGeneratorByProductIdResponse,
  GetDashboadChargesResponse,
  GetDashboardBatchesResponse,
  GetDashboardOverallStatusResponse,
  GetDashboardProductsResponse,
  GetDashboardBrokersByProductIdResponse,
  GetDashboardSignersAmbevNfeResponse,
  GetFundAdminResponse,
  GetGuaranteeByTaxIdResponse,
  GetProductAdminResponse,
  GetSnakeCompanyByIdResponse,
  GetSnakeOdataInteractionsOdataResponse,
  GetV1BankslipBySlipIdResponse,
  GetV1NextBusDayResponse,
  PostAmbevGenerateAllNotificationsResponse,
  PostBatchAdminResponse,
  PostBatchDueDateResponse,
  PostSendNotificationFundResponse,
  PostSnakeFinancialResponse,
  PutSnakeFinancialByFinancialIdResponse,
  PutV1BankslipResponse,
} from "./models/responses";
import { GetCreditConciliationDetailsResponse } from "./models/responses/get-credit-conciliation-details-response";
import { GetCreditConciliationResponse } from "./models/responses/get-credit-conciliation-response";
import { GetDisverbationApprovalsResponse } from "./models/responses/get-disverbation-approvals.response";
import { GetDisverbationFundsResponse } from "./models/responses/get-disverbation-funds.response";
import { PuthDisverbationApprovalsStatusResponse } from "./models/responses/puth-disverbation-approvals-status.response";

export const fidcService = {
  getFidcBatches(params: GetBatchGetByCnpjParams) {
    return client.get<GetBatchGetByCnpjResponse>(
      fidcEndpoints.batch.getByCnpj,
      {
        params,
      },
    );
  },

  getBankSlip(bankslipId: GetDashboardBankslipByBankslipIdParams) {
    return client.get(fidcEndpoints.dashboard.bankSlip.byBankSlip(bankslipId), {
      responseType: "blob",
    });
  },

  getBatchTerms(batchId: GetBatchByBatchIdTermsParams) {
    return client.get(fidcEndpoints.batch.byBatchId.terms(batchId), {
      responseType: "blob",
    });
  },

  getBatchGuarantee(batchId: GetBatchByBatchIdGuaranteeXlsxV2Params) {
    return client.get(fidcEndpoints.batch.byBatchId.guaranteeXLSXV2(batchId), {
      responseType: "blob",
    });
  },

  getBuyers(params: GetDashboardOverallStatusParams) {
    return client.get<GetDashboardOverallStatusResponse>(
      fidcEndpoints.dashboard.overallStatus,
      {
        params,
      },
    );
  },

  getConsolidatedContracts(
    productId: string,
    params: GetContractGeneratorByProductIdParams,
  ) {
    return client.get<GetContractGeneratorByProductIdResponse>(
      fidcEndpoints.contractGenerator.byProductId(productId),
      {
        params,
      },
    );
  },

  getBrokersByProductId(productId: string) {
    return client.get<GetDashboardBrokersByProductIdResponse[]>(
      fidcEndpoints.dashboard.brokers.byProductId(productId),
    );
  },

  getDonusNotification() {
    return client.get<GetAmbevNotificationsResponse>(
      fidcEndpoints.ambev.notifications,
    );
  },

  getDonusSigners() {
    return client.get<GetDashboardSignersAmbevNfeResponse>(
      fidcEndpoints.dashboard.signers.ambevNfe,
    );
  },

  getDueDate(params: PostBatchDueDateParams) {
    return client.post<PostBatchDueDateResponse>(
      fidcEndpoints.batch.dueDate,
      params,
    );
  },

  getFundBatches(params: GetDashboardBatchesParams) {
    return client.get<GetDashboardBatchesResponse>(
      fidcEndpoints.dashboard.batches,
      {
        params,
      },
    );
  },

  getFundClientCharges(params: GetDashboardChargesParams) {
    return client.get<GetDashboadChargesResponse>(
      fidcEndpoints.dashboard.charges,
      {
        params,
      },
    );
  },

  getFundInteractions(params: GetSnakeOdataInteractionsOdataParams) {
    return client.get<GetSnakeOdataInteractionsOdataResponse>(
      fidcEndpoints.snake.odata.InteractionsOdata,
      {
        params,
      },
    );
  },

  getFunds() {
    return client.get<GetFundAdminResponse>(fidcEndpoints.fund.admin);
  },

  getGuaranteeByTaxId(taxId: string) {
    return client.get<GetGuaranteeByTaxIdResponse>(
      fidcEndpoints.guarantee.byTaxId(taxId),
    );
  },

  getImportProducts() {
    return client.get<GetProductAdminResponse>(fidcEndpoints.product.admin);
  },

  getInteractionDetails(id: string) {
    return client.get<GetSnakeCompanyByIdResponse>(
      fidcEndpoints.snake.company.byId(id),
    );
  },

  getProducts() {
    return client.get<GetDashboardProductsResponse>(
      fidcEndpoints.dashboard.products,
    );
  },

  getSubmitBatch(batchId: GetBatchByIdSubmitAdminParams) {
    return client.get(fidcEndpoints.batch.byBatchId.submit.admin(batchId));
  },

  getV1Bankslip(slipId: string) {
    return client.get<GetV1BankslipBySlipIdResponse>(
      fidcEndpoints.v1.bankslip.bySlipId(slipId),
    );
  },

  getV1NextBusDay(params: GetV1NextBusDayParams) {
    return client.get<GetV1NextBusDayResponse>(fidcEndpoints.v1.NextBusDay, {
      params,
    });
  },

  getDisverbations(params: string) {
    return client.get<GetDisverbationApprovalsResponse>(
      `${fidcEndpoints.fidcBack.disverbation.approvals}?${params}`,
    );
  },

  getDisverbationFunds() {
    return client.get<GetDisverbationFundsResponse>(
      `${fidcEndpoints.fidcBack.disverbation.funds}`,
    );
  },

  getCreditConciliation() {
    return client.get<GetCreditConciliationResponse>(
      fidcEndpoints.credit.conciliation,
    );
  },

  getCreditConciliationDetails(orderId: string) {
    return client.get<GetCreditConciliationDetailsResponse>(
      fidcEndpoints.credit.details.byId(orderId),
    );
  },

  getGenerateURLFileCreditConciliation(orderId: string, params: string) {
    return client.get<string>(
      `${fidcEndpoints.credit.download.generate(orderId)}?${params}`,
    );
  },

  postContracts(body: PostContractGeneratorBody) {
    return client.post(fidcEndpoints.contractGenerator.endpoint, body);
  },

  postFinancial(body: PostSnakeFinancialBody) {
    return client.post<PostSnakeFinancialResponse>(
      fidcEndpoints.snake.financial.endpoint,
      body,
    );
  },

  postFundNotifications(body: PostSendNotificationFundBody) {
    return client.post<PostSendNotificationFundResponse>(
      fidcEndpoints.send.notifications.fund,
      body,
    );
  },

  postGenerateAllContracts(body: PostAmbevGenerateAllNotificationsBody) {
    return client.post<PostAmbevGenerateAllNotificationsResponse>(
      fidcEndpoints.ambev.generate.allContracts,
      body,
    );
  },

  postImportBatch(body: PostBatchAdminBody) {
    return client.post<PostBatchAdminResponse>(fidcEndpoints.batch.admin, body);
  },

  postInteractionTracking(body: PostSnakeInteractionTrackingBody) {
    return client.post(fidcEndpoints.snake.interaction.tracking, body);
  },

  postResendDisverbation(body: PostSendDisverbationBody) {
    return client.post(fidcEndpoints.fidcBack.disverbation.resends, body);
  },

  postConciliationFileById(body: FormData, orderId: string) {
    return client.post(fidcEndpoints.credit.file.byId(orderId), body);
  },

  postConciliationFile(body: FormData) {
    return client.post(fidcEndpoints.credit.file.file, body);
  },

  postConciliationRelease(orderId: string) {
    return client.post(fidcEndpoints.credit.confirmation.byId(orderId));
  },

  postConciliationCancel(orderId: string) {
    return client.post(fidcEndpoints.credit.cancel.byId(orderId));
  },

  putFinancial(body: PutSnakeFinancialByFinancialIdBody) {
    return client.put<PutSnakeFinancialByFinancialIdResponse>(
      fidcEndpoints.snake.financial.byFinancialId(body.id),
      body,
    );
  },

  putV1Bankslip(body: PutV1BankslipBody) {
    return client.put<PutV1BankslipResponse>(
      fidcEndpoints.v1.bankslip.endpoint,
      body,
    );
  },
  putConciliationChannelValidation(orderId: string) {
    return client.put(
      fidcEndpoints.credit.applyChannelValidation.channelValidation(orderId),
    );
  },
  // PATCH api/Disverbation/status
  // ids (query parameter) - one or multiple Ids of the Disverbation to change status
  // fundCge (query parameter)  - Cge of the fund to update status - This will update all Disverbations with PENDIN_APPROVAL status
  // username (query parameter) - Username that requested the change
  // status (query parameter) - The new status.
  pathDisverbations(body: PathDisverbationStatusBody) {
    return client.patch<PuthDisverbationApprovalsStatusResponse>(
      fidcEndpoints.fidcBack.disverbation.status,
      body,
    );
  },
};
