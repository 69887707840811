import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import {
  NameAndTaxIdFormatted,
  calculateNameAndTaxIdFilterExpression,
} from "components/name-and-tax-id-formatted";
import { roles } from "roles/roles";
import { service } from "services";
import { getValidationMessages } from "../../../../../utils";
import {
  DownloadSignedDocumentButton,
  GenerateContractAgainButton,
  GenerateMigrationDocumentAgainButton,
  GridHeaderTooltip,
  PreviewContractButton,
  PreviewMigrationDocumentButton,
} from "./_compose";

export const buildDataSource = (tradeId: string | number) =>
  dataSourceCustomStoreGenerator<HubEnergy.PossibleDocumentResponse>(() =>
    service.hubEnergy
      .getTradePossibleDocuments(tradeId)
      .then(({ data }) => data.response ?? [])
      .catch((err) => {
        const errorMessage =
          getValidationMessages(err)?.[0].ErrorMessage ??
          "Não foi possível buscar os documentos";

        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

export const tradeDocumentsGrid: ODataGridGeneratorConfig<HubEnergy.PossibleDocumentResponse> =
  {
    datagrid: {
      noDataText: "Nenhum documento",
      filterRow: { visible: true },
      headerFilter: { visible: true },
      pager: { showPageSizeSelector: true },
      columnResizingMode: "nextColumn",
    },
    columns: [
      {
        dataField: "possibleDocument.type.name",
        caption: "Tipo",
        width: "auto",
      },
      {
        caption: "Empresa relacionada",
        headerFilter: { allowSearch: true },
        calculateCellValue: ({ consumerUnit, customer }) =>
          consumerUnit
            ? `${consumerUnit.name} - ${masks.cpfCnpj(consumerUnit.taxId)} - ${
                consumerUnit.installationNumber
              }`
            : `${customer.name} - ${masks.cpfCnpj(customer.taxId)}`,
        calculateFilterExpression: calculateNameAndTaxIdFilterExpression(
          [
            "consumerUnit.name",
            "consumerUnit.officialName",
            "consumerUnit.installationNumber",
          ],
          "consumerUnit.taxId",
        ),
        cellRender: ({ data }) =>
          data.consumerUnit ? (
            <NameAndTaxIdFormatted
              name={data.consumerUnit.officialName}
              taxId={data.consumerUnit.taxId}
              subtexts={[
                `Nº instalação: ${data.consumerUnit.installationNumber}`,
              ]}
            />
          ) : (
            <NameAndTaxIdFormatted
              name={data.customer.officialName}
              taxId={data.customer.taxId}
            />
          ),
      },
      {
        dataField: "actualDocument.status",
        caption: "Status da assinatura",
        width: "auto",
      },
      {
        alignment: "left",
        caption: "Preview do documento",
        cellRender: ({ data }) =>
          data.possibleDocument.type.isMigrationDocument ? (
            <PreviewMigrationDocumentButton energyDocument={data} />
          ) : (
            <PreviewContractButton energyDocument={data} />
          ),
        headerCellRender: ({ column }) => (
          <GridHeaderTooltip
            caption={column.caption}
            tooltipContent="Gera um arquivo do Word com o documento desejado (Não envia para o usuário assinar)"
          />
        ),
        width: 160,
      },
      {
        alignment: "left",
        caption: "Contingência",
        cellRender: ({ data }) =>
          data.possibleDocument.type.isMigrationDocument ? (
            <GenerateMigrationDocumentAgainButton possibleDocument={data} />
          ) : (
            <GenerateContractAgainButton />
          ),
        headerCellRender: ({ column }) => (
          <GridHeaderTooltip
            caption={column.caption}
            tooltipContent="Gera o documento desejado e envia para a assinatura do usuário"
          />
        ),
        width: 210,
        role: roles.energia.product.tradesByIdDocumentsContingencyColumn.role,
      },
      {
        alignment: "left",
        caption: "Download documento assinado",
        cellRender: ({ data }) =>
          data.actualDocument?.status === "ASSINADO" &&
          data.actualDocument.files.find((f) => f.isActive && f.isValid)
            ?.externalId && (
            <DownloadSignedDocumentButton possibleDocument={data} />
          ),
        headerCellRender: ({ column }) => (
          <GridHeaderTooltip caption={column.caption} />
        ),
        width: 180,
      },
    ],
  };
