import { cases, masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { RouterButton } from "components/router-button";
import { GetAdminCustomerHistoryResponse } from "services/hubloan/admin-customer/models";
import { format } from "utils/date";
import { productTypes } from "./loan-operations.utils";

type HistoryResponseItem = GetAdminCustomerHistoryResponse[number];

export const grid = {
  datagrid: {
    noDataText: "Nenhuma operação encontrada.",
    pager: {
      showInfo: true,
      infoText: "Página {0} de {1} ({2} operações)",
    },
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "contract",
      caption: "Contrato",
      cellRender: ({ data }) => data.contract || "-",
    },
    {
      dataField: "createdDate",
      caption: "Data de contratação",
      cellRender: ({ data }) => format.dateTime(data.createdDate),
    },
    {
      dataField: "product",
      caption: "Produto",
      cellRender: ({ data }) =>
        productTypes[data.product || ""] || cases.title(data.product),
      calculateFilterExpression: (filterValue: string) => {
        const productKeys = Object.keys(productTypes).filter((p) =>
          productTypes[p]
            .toLocaleLowerCase()
            .includes(filterValue.toLocaleLowerCase()),
        );

        const expression: (string | string[])[] = [];

        productKeys.forEach((value, index) => {
          if (index > 0) expression.push("or");
          expression.push(["product", "contains", value]);
        });

        return expression;
      },
    },
    {
      dataField: "status",
      caption: "Status",
      cellRender: ({ data }) => cases.title(data.status),
    },
    {
      dataField: "curveValue",
      caption: "Saldo devedor atual",
      cellRender: ({ data }) => masks.currency(data.curveValue, "R$", "."),
      alignment: "left",
    },
    {
      dataField: "amount",
      caption: "Valor solicitado",
      cellRender: ({ data }) => masks.currency(data.amount, "R$", "."),
      alignment: "left",
    },
    {
      caption: "Ações",
      alignment: "center",
      cellRender: ({ data }) => (
        <RouterButton
          outline
          href={`/emprestimos/cliente/operacoes/${data.id}/detalhes`}
        >
          Detalhes
        </RouterButton>
      ),
    },
  ],
} satisfies ODataGridGeneratorConfig<HistoryResponseItem>;
