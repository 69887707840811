import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { service } from "services";

export const useStepAnalysisSummary = () => {
  const {
    callService: getSummary,
    error,
    hasError,
    loading,
    value,
  } = useServiceCall(service.hubEnergy.getStepAnalysisSummary);

  useEffect(() => {
    getSummary();
  }, [getSummary]);

  return {
    error,
    getSummary,
    hasError,
    loading,
    value,
  };
};
