import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { getValidationMessages } from "../../../../utils";
import {
  AddDistributorForm,
  addDistributorFormDefaultValues,
  addDistributorFormValidationSchema,
} from "./add-distributor.form";

export const useAddDistributor = () => {
  const { callService: createDistributor, loading } = useServiceCall(
    service.hubEnergy.createDistributor,
  );

  const navigate = useNavigate();

  const form = useForm<AddDistributorForm>({
    resolver: yupResolver(addDistributorFormValidationSchema),
    defaultValues: addDistributorFormDefaultValues,
  });

  const { handleSubmit } = form;

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await createDistributor({
          ...values,
          taxIdType: "CNPJ",
        });

        if (success) {
          OToastManager.success("Distribuidora cadastrada com sucesso");
          navigate(corporateRouter.routes.energia.product.distributor.path);
        } else
          OToastManager.danger(
            getValidationMessages(error)?.[0].ErrorMessage ??
              "Não foi possível cadastrar a distribuidora",
          );
      }),
    [handleSubmit, createDistributor, navigate],
  );

  return {
    form,
    loading,
    submit,
  };
};
