import { PostAdminCurrency } from "services/hubfx/models/requests/post-admin-currencies.request";
import * as yup from "yup";

export const currencyFormValidationSchema: yup.ObjectSchema<PostAdminCurrency> =
  yup.object({
    code: yup
      .string()
      .required("Este campo é obrigatório")
      .length(3, "O código da moeda deve ter 3 dígitos"),
    symbol: yup.string().notRequired(),
    decimals: yup.number().required("Este campo é obrigatório"),
    openingHours: yup.string().notRequired(),
    incomingDisbursementHours: yup.string().notRequired(),
    outgoingDisbursementHours: yup.string().notRequired(),
    description: yup.string().required("Este campo é obrigatório"),
    isApproved: yup.bool().required("Este campo é obrigatório"),
    standardQuoteLimit: yup.number().nullable().notRequired(),
    preTicketQuoteLimit: yup.number().nullable().notRequired(),
    allowsNonStopTrading: yup.bool().required("Este campo é obrigatório"),
    allowsSameDayDisbursement: yup.bool().required("Este campo é obrigatório"),
    sameDayIncomingLimitTime: yup.string().nullable().notRequired(),
    sameDayOutgoingLimitTime: yup.string().nullable().notRequired(),
    incomingStartTime: yup.string().nullable().notRequired(),
    incomingEndTime: yup.string().nullable().notRequired(),
    outgoingStartTime: yup.string().nullable().notRequired(),
    outgoingEndTime: yup.string().nullable().notRequired(),
    iconUrl: yup.string().nullable().notRequired(),
  });
