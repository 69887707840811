import { modalManager } from "@maestro/core";
import {
  OButton,
  OModal,
  OModalFooter,
  OModalHeader,
  OTypography,
} from "@maestro/react";

interface UpdateEstrategiasConfirmationModalProps {
  submit: (updateEstrategias: boolean) => () => void;
}

export const updateEstrategiasConfirmationModalId =
  "update-estrategias-confirmation-modal";

export const UpdateEstrategiasConfirmationModal = ({
  submit,
}: UpdateEstrategiasConfirmationModalProps) => {
  return (
    <OModal
      id={updateEstrategiasConfirmationModalId}
      position="center"
      backdrop
    >
      <OModalHeader>
        <OTypography size="lg">
          Deseja atualizar todas as estratégias vinculadas a este template?
        </OTypography>
      </OModalHeader>
      <OModalFooter>
        <div className="d-flex justify-content-end gap-3">
          <OButton
            dataAction="confirmacao_atualizar_estrategias:botao:cancelar"
            dataLabel="cancelar"
            type="dark"
            outline
            onClick={() =>
              modalManager.hide(updateEstrategiasConfirmationModalId)
            }
            className="me-auto"
          >
            Cancelar
          </OButton>
          <OButton
            dataAction="confirmacao_atualizar_estrategias:botao:nao"
            dataLabel="nao"
            type="dark"
            outline
            onClick={() => {
              submit(false)();
              modalManager.hide(updateEstrategiasConfirmationModalId);
            }}
          >
            Não
          </OButton>
          <OButton
            dataAction="confirmacao_atualizar_estrategias:botao:sim"
            dataLabel="sim"
            onClick={() => {
              submit(true)();
              modalManager.hide(updateEstrategiasConfirmationModalId);
            }}
          >
            Sim
          </OButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
