import { DeepNullable, YupObjectSchema } from "utils/types";
import * as yup from "yup";

export interface AddPendencyForm {
  stepId: number;
  typeId: number;
  propertyId: number;
  isAutomation: false;
  isPublic: boolean;
  hasBpoAction: boolean;
  message: string | null;
  status: string;
  _consumerUnitId: number | null;
}

export const addPendencyFormFields = {
  isPublic: {
    id: "isPublic",
    name: "isPublic",
    checkboxLabel: "Mostra cliente",
  },
  hasBpoAction: {
    id: "hasBpoAction",
    name: "hasBpoAction",
    checkboxLabel: "Tem ação de BPO",
  },
  message: {
    id: "message",
    name: "message",
    label: "Mensagem",
    tag: "textarea",
    adaptHeight: true,
  },
  status: {
    id: "status",
    name: "status",
    label: "Status",
    placeholder: "Selecionar",
    required: true,
  },
} as const;

export const addPendencyFormValidationSchema = yup.object<
  AddPendencyForm,
  YupObjectSchema<AddPendencyForm>
>({
  stepId: yup.number().required("Campo obrigatório"),
  typeId: yup.number().required("Campo obrigatório"),
  status: yup.string().required("Campo obrigatório"),
});

export const addPendencyFormDefaultValues = {
  stepId: null,
  typeId: null,
  propertyId: null,
  isAutomation: false,
  isPublic: false,
  hasBpoAction: false,
  message: null,
  status: null,
  _consumerUnitId: null,
} satisfies Partial<
  DeepNullable<AddPendencyForm>
> as unknown as AddPendencyForm;
