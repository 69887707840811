import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { percent4DigitsMask } from "utils/mask";
import { ReceivableInfo } from "./operation-details.types";
import {
  calculateAvgMonthlyRate,
  calculateAvgTerm,
} from "./operation-details.utils";

export const buildOperationDetails =
  (
    requestedAmounts: Record<number, number>,
    disbursementAmounts: Record<number, number>,
    requestedDisbursementAmounts: Record<number, number>,
  ) =>
  (receivables: ReceivableInfo[]): DetailsFields => {
    const totalMaturityValue = receivables.reduce(
      (acc, curr) => acc + curr.valorVencimento,
      0,
    );

    const totalRequestedAmount = receivables.reduce(
      (acc, curr) =>
        acc +
        (requestedAmounts[curr.receivableId] ?? curr.valorVencimento ?? 0),
      0,
    );

    const totalNetValue = receivables.reduce(
      (acc, curr) =>
        acc +
        (requestedDisbursementAmounts[curr.receivableId] ??
          disbursementAmounts[curr.receivableId] ??
          curr.valorDesembolso ??
          0),
      0,
    );

    const avgMonthlyRate = calculateAvgMonthlyRate(receivables);

    const avgTerm = calculateAvgTerm(receivables);

    return {
      details: {
        items: [
          {
            label: "Valor dos Vencimentos",
            value: masks.currency(totalMaturityValue, "R$"),
            grid: { sm: 6, md: 4, lg: 2 },
          },
          {
            label: "Valor Solicitado",
            value: masks.currency(totalRequestedAmount, "R$"),
            grid: { sm: 6, md: 4, lg: 2 },
          },
          {
            label: "Valor Líquido",
            value: masks.currency(totalNetValue, "R$"),
            grid: { sm: 6, md: 4, lg: 2 },
          },
          {
            label: "Número de Recebíveis",
            value: receivables.length,
            grid: { sm: 6, md: 4, lg: 2 },
          },
          {
            label: "Taxa Média Mensal",
            value: percent4DigitsMask(avgMonthlyRate),
            grid: { sm: 6, md: 4, lg: 2 },
          },
          {
            label: "Prazo Médio",
            value: `${avgTerm} dia(s)`,
            grid: { sm: 6, md: 4, lg: 2 },
          },
        ],
      },
    };
  };
