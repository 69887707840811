import {
  OBadge,
  OCol,
  OField,
  OIcon,
  OLoader,
  OTypography,
} from "@maestro/react";
import { SelectSearch } from "components/select-search";
import { UnstyledButton } from "components/unstyled-button";
import { remapHookFormRegister } from "../../../../../../../antecipacao-de-cartoes/components/generic-bank-data/generic-bank-data.utils";
import { useCompanySearchCanceling } from "./company-search-canceling.hook";

interface CompanySearchProps {
  mode: "Sacado" | "Cedente";
  name: string;
  label: string;
  placeholder: string;
}

export const CompanySearchCanceling = ({
  mode,
  label,
  name,
  placeholder,
}: CompanySearchProps) => {
  const {
    inputWatcher,
    localError,
    localForm,
    loading,
    options,
    selected,
    removeCompany,
  } = useCompanySearchCanceling(mode, name);

  return (
    <>
      <OCol xs={12} lg={6}>
        <OField
          label={label}
          htmlFor="input"
          error={!!localError}
          message={localError?.message as string}
          required={mode === "Cedente"}
        >
          <SelectSearch
            id="input"
            name="input"
            placeholder={placeholder}
            options={options}
            value={inputWatcher}
            className="w-100"
            maxOptions={50}
            eager={false}
            innerProps={{
              input: {
                ...remapHookFormRegister(localForm.register("input")),
              },
            }}
          />
        </OField>
        {loading && <OLoader className="d-block" size="sm" />}
      </OCol>
      <OCol xs={12} lg={6} className="d-flex flex-column gap-4">
        <OTypography tag="h4">Selecionados:</OTypography>
        {selected.map((company) => (
          <OBadge key={company.id} type="dark" size="sm" rounded outline>
            <div className="d-flex align-items-center gap-2">
              {company.label}
              <UnstyledButton onClick={() => removeCompany(company.id)}>
                <OIcon category="fal" icon="fa-times" />
              </UnstyledButton>
            </div>
          </OBadge>
        ))}
      </OCol>
    </>
  );
};
