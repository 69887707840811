import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { GridTemplate } from "templates/grid-template";
import { commonDomainGrid } from "./common-domain.grid";

export const CommonDomainColumnsPage = () => {
  const gridRef = useGridRef();
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Common Domain" />}
      gridRef={gridRef}
      showClearFiltersButton
      showExportButton
      showRefreshButton
      actions={
        <>
          <RouterButton
            role={
              roles.workflow.product.configurationsCreateCommonDomainPage.role
            }
            href={
              corporateRouter.routes.workflow.product.configurations
                .commonDomain.create.path
            }
          >
            Adicionar
          </RouterButton>
        </>
      }
    >
      <ODataGridGenerator gridRef={gridRef} grid={commonDomainGrid} />
    </GridTemplate>
  );
};