import dayjs from "dayjs";
import { CollectionFilterFields } from "./_compose/filters-modal/filters-modal.type";
import {
  BadCreditStatus,
  BankslipGridColumn,
  CollectionFilterParams,
  CollectionStatus,
  CollectionType,
} from "./bankslip.type";

export const MapperCollectionType: Record<CollectionType, string> = {
  [CollectionType.BANKSLIP]: "Boleto",
  [CollectionType.BANKSLIP_QRCODE]: "Bolepix",
};

export const MapperCollectionStatus: Record<
  CollectionStatus,
  {
    type: "info" | "success" | "warning" | "danger";
    label: string;
  }
> = {
  [CollectionStatus.Canceled]: {
    type: "danger",
    label: "Cancelado",
  },
  [CollectionStatus.CancelFailed]: {
    type: "danger",
    label: "Falha no cancelamento",
  },
  [CollectionStatus.Canceling]: {
    type: "info",
    label: "Cancelando",
  },
  [CollectionStatus.Created]: {
    type: "success",
    label: "Registrado",
  },
  [CollectionStatus.Expired]: {
    type: "danger",
    label: "Cancelado",
  },
  [CollectionStatus.Failed]: {
    type: "danger",
    label: "Falha",
  },
  [CollectionStatus.Overdue]: {
    type: "warning",
    label: "Vencido",
  },
  [CollectionStatus.Paid]: {
    type: "success",
    label: "Pago",
  },
  [CollectionStatus.Processing]: {
    type: "info",
    label: "Registrando",
  },
  [CollectionStatus.ProcessingPayment]: {
    type: "success",
    label: "Registrado",
  },
  [CollectionStatus.Updated]: {
    type: "success",
    label: "Atualizado",
  },
  [CollectionStatus.UpdateFailed]: {
    type: "danger",
    label: "Falha na atualização",
  },
  [CollectionStatus.Updating]: {
    type: "info",
    label: "Atualizando",
  },
};

export const MapperBadCreditStatus: Record<
  BadCreditStatus,
  {
    type: "info" | "success" | "warning" | "danger";
    label: string;
  }
> = {
  [BadCreditStatus.Canceled]: {
    type: "danger",
    label: "Cancelada",
  },
  [BadCreditStatus.Canceling]: {
    type: "info",
    label: "Cancelando",
  },
  [BadCreditStatus.Confirmed]: {
    type: "danger",
    label: "Negativado",
  },
  [BadCreditStatus.ConfirmedWithCancelPending]: {
    type: "info",
    label: "Cancelando",
  },
  [BadCreditStatus.Failed]: {
    type: "danger",
    label: "Erro",
  },
  [BadCreditStatus.FailedToCancel]: {
    type: "danger",
    label: "Erro",
  },
  [BadCreditStatus.FailedToSend]: {
    type: "info",
    label: "Em negativação",
  },
  [BadCreditStatus.Processing]: {
    type: "info",
    label: "Em negativação",
  },
  [BadCreditStatus.Starting]: {
    type: "info",
    label: "Em negativação",
  },
  [BadCreditStatus.WarningPeriod]: {
    type: "info",
    label: "Em negativação",
  },
};

export const MapperBankslipGridColumnWidth: Record<BankslipGridColumn, string> =
  {
    [BankslipGridColumn.CreatedAt]: "15%",
    [BankslipGridColumn.DocumentNumber]: "15%",
    [BankslipGridColumn.OurNumber]: "15%",
    [BankslipGridColumn.Payer]: "15%",
    [BankslipGridColumn.Status]: "12%",
    [BankslipGridColumn.BadCredit]: "12%",
    [BankslipGridColumn.DueDate]: "10%",
    [BankslipGridColumn.UpdatedAt]: "12%",
    [BankslipGridColumn.Amount]: "12%",
    [BankslipGridColumn.AmountPaid]: "12%",
    [BankslipGridColumn.CreationType]: "10%",
    [BankslipGridColumn.CollectionType]: "10%",
  };

export const MapperBankslipGridColumnCaption: Record<
  BankslipGridColumn,
  string
> = {
  [BankslipGridColumn.CreatedAt]: "Criação",
  [BankslipGridColumn.DocumentNumber]: "Nº Documento",
  [BankslipGridColumn.OurNumber]: "Nosso Número",
  [BankslipGridColumn.Payer]: "Pagador",
  [BankslipGridColumn.Status]: "Status Boleto",
  [BankslipGridColumn.BadCredit]: "Status Negativação",
  [BankslipGridColumn.DueDate]: "Vencimento",
  [BankslipGridColumn.UpdatedAt]: "Últ. Atualização",
  [BankslipGridColumn.Amount]: "Valor",
  [BankslipGridColumn.AmountPaid]: "Liquidado",
  [BankslipGridColumn.CreationType]: "Tipo de Emissão",
  [BankslipGridColumn.CollectionType]: "Tipo do Boleto",
};

export const isAbleToArchive = (collection: BankingHub.Collection) => {
  return (
    [
      CollectionStatus.Failed,
      CollectionStatus.Expired,
      CollectionStatus.Canceled,
    ].includes(collection.status) ||
    ([CollectionStatus.Processing].includes(collection.status) &&
      dayjs().isAfter(collection.dueDate, "day"))
  );
};

export const isAbleToCancel = (collection: BankingHub.Collection) => {
  return (
    [
      CollectionStatus.Created,
      CollectionStatus.Updated,
      CollectionStatus.Overdue,
      CollectionStatus.CancelFailed,
    ].includes(collection.status) && !collection.anticipation.isAnticipated
  );
};

export const assembleFilterParams = (
  params: CollectionFilterFields,
): CollectionFilterParams => {
  const { badCreditStatus, payerName, payerTaxId, ...rest } = params;
  const formattedFilters: CollectionFilterParams = { ...rest };

  if (badCreditStatus) formattedFilters["badCredit.status"] = badCreditStatus;
  if (payerName) formattedFilters["payer.name"] = payerName;
  if (payerTaxId) formattedFilters["payer.taxId"] = payerTaxId;

  return formattedFilters;
};
