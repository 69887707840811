import { OToastManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { gridStorage } from "utils/storage";
import { MappedStatusBadge } from "../../../components/mapped-status-badge";
import {
  consumerUnitsImportDataStatusMap,
  energyTradeStatusMap,
  getValidationMessages,
} from "../../../utils";
import { GridActions } from "./_compose";

export const dataSource =
  dataSourceCustomStoreGenerator<HubEnergy.LegacyImport.ConsumerUnitsImportDataListItem>(
    () =>
      service.hubEnergy
        .getConsumerUnitsImportDataList()
        .then(({ data }) => data.response)
        .catch((err) => {
          const errorMessage =
            getValidationMessages(err)?.[0]?.ErrorMessage ??
            "Erro ao buscar as importações";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }),
  );

export const importDataListGrid: ODataGridGeneratorConfig<HubEnergy.LegacyImport.ConsumerUnitsImportDataListItem> =
  {
    datagrid: {
      noDataText: "Nenhuma importação",
      filterRow: { visible: true },
      headerFilter: { visible: true },
      pager: { showPageSizeSelector: true },
      stateStoring: gridStorage("mle-importDataListGrid"),
      columnResizingMode: "nextColumn",
    },
    columns: [
      {
        cellRender: ({ data }) => (
          <DetailsLink
            href={corporateRouter.routes.energia.product.consumerUnitsImport.byConsumerUnitsImportDataId.path(
              { consumerUnitsImportDataId: data.id },
            )}
          />
        ),
        role: roles.energia.product
          .consumerUnitsImportByConsumerUnitsImportDataId.role,
        width: "auto",
      },
      {
        dataField: "id",
        caption: "Id",
        allowHeaderFiltering: false,
        width: "auto",
      },
      {
        dataField: "groupingId",
        caption: "Id agrupamento",
        allowHeaderFiltering: false,
        width: "auto",
      },
      {
        dataField: "nesOperationId",
        caption: "Id boleta NES",
        allowHeaderFiltering: false,
        width: "auto",
      },
      {
        dataField: "status",
        caption: "Status da importação",
        cellRender: ({ data }) => (
          <MappedStatusBadge
            map={consumerUnitsImportDataStatusMap}
            status={data.status}
          />
        ),
      },
      {
        dataField: "tradeStatus",
        caption: "Status da proposta",
        cellRender: ({ data }) =>
          data.tradeStatus && (
            <MappedStatusBadge
              map={energyTradeStatusMap}
              status={data.tradeStatus}
            />
          ),
      },
      {
        dataField: "createdDate",
        dataType: "date",
        format: "shortDateShortTime",
        caption: "Data de criação",
        width: "auto",
      },
      {
        dataField: "updatedDate",
        dataType: "date",
        format: "shortDateShortTime",
        caption: "Data de atualização",
        width: "auto",
      },
      {
        width: "auto",
        cellRender: ({ data, component }) => (
          <GridActions
            importData={data}
            reload={() => component?.getDataSource()?.reload?.()}
          />
        ),
      },
    ],
    selection: {
      allowSelectAll: true,
      mode: "multiple",
      selectAllMode: "allPages",
      showCheckBoxesMode: "always",
    },
  };
