import { OLoader } from "@maestro/react";
import { GetUserCommand, useUnsafeUser, userManager } from "contexts/user";
import React from "react";
import { ErrorComponent } from "../../empty-state";
import { EmptyState } from "../../empty-state/empty-state.component";
import { TryAgainButton } from "../../empty-state/try-again-button.component";

interface UserGuardProps {
  children: React.ReactNode;
}

export const UserGuard = ({ children }: UserGuardProps) => {
  const { user, state } = useUnsafeUser();

  if (window.location.pathname.startsWith('/acesso')) {
    return <>{children}</>
  }

  if (state === "LOADING") {
    return <OLoader absolute data-component="UserGuard" />;
  }

  if (state === "ERROR") {
    return (
      <div className="d-flex justify-content-center align-items-center p-5">
        <ErrorComponent
          messageTitle="Erro ao carregar o usuário"
          messageParagraph="Clique no botão para tentar novamente"
        >
          <TryAgainButton
            onClick={() => {
              userManager.execute(new GetUserCommand());
            }}
          />
        </ErrorComponent>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="d-flex justify-content-center align-items-center p-5">
        <EmptyState messageTitle="Parece que você ainda não fez login" />
      </div>
    );
  }

  return children as JSX.Element;
};
