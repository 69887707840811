import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { customer } from "contexts/customer";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services/service";
import {
  updateOriginatorCategorizationsDefaultValues,
  updateOriginatorCategorizationsSchema,
} from "./update-originator-categorizations.form";

export const useUpdateOriginatorCategorizations = () => {
  const [isCorban, setIsCorban] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [uuid, setUuid] = useState("");

  const form = useForm({
    resolver: yupResolver(updateOriginatorCategorizationsSchema),
    defaultValues: updateOriginatorCategorizationsDefaultValues,
  });
  const { handleSubmit, setValue } = form;

  const onSubmit = handleSubmit(async (values) => {
    setLoadingSubmit(true);
    try {
      await service.hubPartners.updateOriginatorPermission(uuid, values);
      OToastManager.success("Originador atualizado com sucesso!");
    } catch {
      OToastManager.danger("Erro ao atualizar permissões do originador");
    } finally {
      setLoadingSubmit(false);
    }
  });

  const getInfo = useCallback(async () => {
    const customerUuid = customer.value?.identification;

    if (!customerUuid) {
      return;
    }

    setLoading(true);
    try {
      const { data } = await service.hubPartners.originatorDetails(
        customerUuid,
      );
      const details = data.data;
      setValue("partnershipType", details.partnershipTypes[0].code);
      setValue(
        "originatorSubTypes",
        details.originatorSubTypes.map((subtype) => subtype.code),
      );
      setValue(
        "products",
        details.products.map((product) => product.code),
      );

      setUuid(details.uuid);

      setIsCorban(
        details.partnershipTypes.some((pt) => pt.code !== "PES") &&
          details.partnershipTypes.some((pt) => pt.code !== "FDR"),
      );
    } catch {
      OToastManager.danger("Falha ao obter detalhes do originador");
    } finally {
      setLoading(false);
    }
  }, [setValue]);

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  return {
    form,
    isCorban,
    loading,
    loadingSubmit,
    onSubmit,
  };
};
