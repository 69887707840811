import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { service } from "services";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { gridStorage } from "utils/storage";
import { MappedStatusBadge } from "../../../../components/mapped-status-badge";
import {
  energyTradeStatusMap,
  getValidationMessages,
  MappedStatus,
} from "../../../../utils";

interface ConsumerUnitWithAgent
  extends HubEnergy.ConsumerUnitWithAgentsResponse {
  bpo?: number;
  ops?: number;
}

export const dataSource = dataSourceCustomStoreGenerator<ConsumerUnitWithAgent>(
  () =>
    service.hubEnergy
      .getConsumerUnitAgents()
      .then(({ data }) => data.response)
      .catch((err) => {
        const errorMessage =
          getValidationMessages(err)?.[0]?.ErrorMessage ??
          "Erro ao buscar as unidades consumidoras";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  {
    customStoreOptions: {
      update: async (oldValues, newValues) => {
        try {
          const assignments: HubEnergy.AssignmentRequest[] = [];

          if (newValues.bpo) {
            assignments.push({
              agentId: newValues.bpo,
              consumerUnitId: oldValues.consumerUnitId,
              tradeId: oldValues.tradeId,
            });
          }
          if (newValues.ops) {
            assignments.push({
              agentId: newValues.ops,
              consumerUnitId: oldValues.consumerUnitId,
              tradeId: oldValues.tradeId,
            });
          }

          await service.hubEnergy.assignAgentBatch({
            assignments,
          });

          OToastManager.success("Agente atualizado");
        } catch (err) {
          const errorMessage =
            getValidationMessages(err)?.[0].ErrorMessage ??
            "Erro ao atualizar agente";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }
      },
    },
  },
);

const datagridSettings: ODataGridGeneratorConfig<ConsumerUnitWithAgent>["datagrid"] =
  {
    noDataText: "Nenhuma unidade consumidora",
    filterRow: { visible: true },
    headerFilter: { visible: true },
    pager: { showPageSizeSelector: true },
    stateStoring: gridStorage("energy-consumerUnitAssingment"),
    editing: {
      allowUpdating: true,
    },
    columnResizingMode: "nextColumn",
  };

export const consumerUnitAssingmentGrid = (
  allAgents: HubEnergy.AgentResponse[],
): ODataGridGeneratorConfig<ConsumerUnitWithAgent> => {
  const tradeStatusLookup = Object.entries(energyTradeStatusMap).map(
    (item: [string, MappedStatus]) => ({
      text: item[1].text,
      value: item[0],
    }),
  );

  const bpoLookup = allAgents
    .filter(({ level }) => level === "BPO")
    .map(({ id, name }) => ({
      text: name,
      value: id,
    }));

  const opsLookup = allAgents
    .filter(({ level }) => level === "OPS")
    .map(({ id, name }) => ({
      text: name,
      value: id,
    }));

  return {
    datagrid: datagridSettings,
    columns: [
      {
        dataField: "taxId",
        dataType: "string",
        caption: "CNPJ",
        format: (value) => masks.cpf(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
        allowEditing: false,
        width: "auto",
      },
      {
        dataField: "officialName",
        dataType: "string",
        allowEditing: false,
        caption: "Nome",
      },
      {
        dataField: "installationNumber",
        dataType: "string",
        caption: "Nº Instalação",
        allowEditing: false,
        width: "auto",
      },
      {
        dataField: "migrationDate",
        dataType: "date",
        caption: "Data de migração",
        format: "shortDate",
        allowEditing: false,
        width: "auto",
      },
      {
        dataField: "tradeStatus",
        dataType: "string",
        caption: "Status",
        cellRender: ({ data }) =>
          data.tradeStatus ? (
            <MappedStatusBadge
              map={energyTradeStatusMap}
              status={data.tradeStatus}
            />
          ) : (
            "-"
          ),
        headerFilter: {
          dataSource: tradeStatusLookup,
        },
        lookup: {
          dataSource: tradeStatusLookup,
          displayExpr: "text",
          valueExpr: "value",
        },
        allowEditing: false,
        width: "auto",
      },
      {
        dataField: "bpo",
        caption: "BPO",
        calculateCellValue: ({ agents }) =>
          agents?.find((a) => a.level === "BPO")?.id,
        headerFilter: {
          dataSource: bpoLookup,
        },
        lookup: {
          dataSource: bpoLookup,
          displayExpr: "text",
          valueExpr: "value",
        },
        width: "auto",
      },
      {
        dataField: "ops",
        caption: "Operador",
        calculateCellValue: ({ agents }) =>
          agents?.find((a) => a.level === "OPS")?.id,
        headerFilter: {
          dataSource: opsLookup,
        },
        lookup: {
          dataSource: opsLookup,
          displayExpr: "text",
          valueExpr: "value",
        },
        width: "auto",
      },
    ],
    selection: {
      allowSelectAll: true,
      mode: "multiple",
      selectAllMode: "allPages",
      showCheckBoxesMode: "always",
    },
  };
};
