import {
  OButton,
  OCheckbox,
  OLabel,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORFieldCheckboxGroup,
  OToastManager,
  OTypography,
  modalManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { SelectSearchField } from "components/select-search";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services/service";
import {
  energyTradeStatusMap,
  getValidationMessages,
} from "../../../../../../../utils";
import {
  ChangeTradeStatusForm,
  changeTradeStatusFormDefaultValues,
} from "./change-trade-status-button.form";

const modalId = "change-trade-status-modal";

export interface ChangeTradeStatusButtonProps {
  trade: HubEnergy.TradeDetailsResponse;
  reload: () => void;
}

export const ChangeTradeStatusButton = ({
  trade,
  reload,
}: ChangeTradeStatusButtonProps) => {
  const { callService: updateTradeStatus, loading } = useServiceCall(
    service.hubEnergy.updateTradeStatus,
  );

  const form = useForm<ChangeTradeStatusForm>({
    defaultValues: changeTradeStatusFormDefaultValues,
  });

  const { handleSubmit, reset } = form;

  const changeTradeStatus = handleSubmit(async (values) => {
    const { success, error } = await updateTradeStatus({
      tradeId: trade.id,
      shouldTriggerEvent:
        values.shouldTriggerEvent.includes("shouldTriggerEvent"),
      status: values.status,
    });

    if (success) {
      OToastManager.success("Status do trade atualizado com sucesso");
      reload();
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0].ErrorMessage ??
          "Erro ao atualizar o status do trade",
      );
    }
  });

  useEffect(() => {
    reset({ status: trade.status });
  }, [reset, trade]);

  return (
    <>
      <OModal
        id={modalId}
        position="center"
        size="sm"
        className="position-absolute"
      >
        <OModalHeader>
          <OTypography size="lg">Alterar status do trade</OTypography>
        </OModalHeader>
        <OModalBody className="p-2">
          <FormProvider {...form}>
            <ORFieldCheckboxGroup
              id="shouldTriggerEvent"
              name="shouldTriggerEvent"
            >
              <div className="d-flex flex-row gap-2 align-items-center">
                <OCheckbox
                  size="xs"
                  id="shouldTriggerEvent-checkbox"
                  value="shouldTriggerEvent"
                />
                <OLabel htmlFor="shouldTriggerEvent-checkbox">
                  Disparar evento de atualização de status
                </OLabel>
              </div>
            </ORFieldCheckboxGroup>
            <SelectSearchField
              id="status"
              name="status"
              options={Object.entries(energyTradeStatusMap).map(
                ([status, { text }]) => ({ label: text, value: status }),
              )}
            />
          </FormProvider>
        </OModalBody>
        <OModalFooter>
          <div className="d-flex justify-content-end gap-2">
            <OButton
              type="dark"
              outline
              onClick={() => modalManager.hide(modalId)}
            >
              Cancelar
            </OButton>
            <OButton
              onClick={() => {
                changeTradeStatus();
                modalManager.hide(modalId);
              }}
            >
              Salvar
            </OButton>
          </div>
        </OModalFooter>
      </OModal>
      <LoadingButton
        loading={loading}
        onClick={() => modalManager.show(modalId)}
      >
        Alterar status
      </LoadingButton>
    </>
  );
};
