import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import {
  buildDataSource,
  buildConsumerUnitsGrid,
} from "../../../components/consumer-units-grid";

const dataGrid = buildConsumerUnitsGrid(false);

export const ConsumerUnitsMonitor = () => {
  const gridRef = useGridRef();

  const dataSource = useMemo(() => buildDataSource(), []);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Unidades consumidoras" />}
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={dataGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
