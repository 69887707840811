import { OToastManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { service } from "services";
import { PostAdminCommonBody } from "services/hubfx/models/requests/post-admin-common.request";

export const exchangeProductCommonDomainGrid = {
  datagrid: {
    noDataText: "Nenhuma variável de configuração encontrada.",
    dataSource: dataSourceCustomStoreGenerator(
      () =>
        service.acc
          .getCommonDomain()
          .then(({ data }) => data.response ?? [])
          .catch(() => {
            throw new Error("Erro ao buscar variáveis de configuração");
          }),
      {
        customStoreOptions: {
          insert: async (values) => {
            try {
              await service.acc.postCommonDomain({
                groupKeyValues: [values],
              } as PostAdminCommonBody);

              OToastManager.success("Dado adicionado com sucesso.");

              return values;
            } catch {
              OToastManager.danger("Um erro ocorreu ao tentar adicionar.");
              throw new Error("Um erro ocorreu ao tentar adicionar.");
            }
          },
          update: async (originalData, updatedData) => {
            const data = { ...originalData, ...updatedData };
            try {
              await service.acc.postCommonDomain({
                groupKeyValues: [data],
              } as PostAdminCommonBody);

              OToastManager.success("Dados atualizados com sucesso.");
            } catch {
              OToastManager.danger(
                "Um erro ocorreu ao tentar salvar as alterações.",
              );
            }
          },
        },
      },
    ),
    paging: {
      pageSize: 20,
    },
    editing: {
      mode: "form",
      allowUpdating: true,
    },
    headerFilter: { visible: false, allowSearch: true },
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      sortIndex: 0,
      sortOrder: "asc",
      dataField: "group",
      caption: "Grupo",
      validationRules: [{ type: "required" }],
    },
    {
      sortIndex: 1,
      sortOrder: "asc",
      dataField: "key",
      caption: "Chave",
      validationRules: [{ type: "required" }],
    },
    {
      dataField: "value",
      caption: "Valor",
      width: 500,
    },
    {
      dataField: "isPublic",
      caption: "Público",
    },
    {
      dataField: "createdDate",
      caption: "Data de criação",
      dataType: "datetime",
      allowEditing: false,
    },
    {
      dataField: "updatedDate",
      caption: "Última atualização",
      dataType: "datetime",
      sortOrder: "desc",
      allowEditing: false,
    },
  ],
} satisfies ODataGridGeneratorConfig<
  Awaited<
    ReturnType<typeof service.acc.getCommonDomain>
  >["data"]["response"][number]
>;
