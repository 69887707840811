import { PostAdminReceivablesCotacaoResponse } from "services/quickfin/models";
import { QuoteIntubate } from "../../../types";
import { TableRow } from "./quote.types";

const isEmpty = (str: string | undefined) => !str || str.length === 0;

export const validatePayload = (payload: QuoteIntubate[]) =>
  payload.map((p) => {
    const newP = p;

    if (
      isEmpty(p.bandeira) ||
      isEmpty(p.dataVencimento) ||
      isEmpty(p.cedenteCnpj) ||
      isEmpty(p.sacadoCnpj) ||
      isEmpty(p.valorVencimento?.toString())
    ) {
      newP.error = "Por favor, preencha todos os campos!";
    }

    return newP;
  });

export const validateNoReturn = (
  payload: QuoteIntubate[],
  data: PostAdminReceivablesCotacaoResponse,
) =>
  payload.map((p) => {
    const newP = p;

    const found = data.find(
      (x) =>
        x.recebivel.bandeira === p.bandeira &&
        x.recebivel.cedenteCnpj === p.cedenteCnpj &&
        x.recebivel.sacadoCnpj === p.sacadoCnpj &&
        x.recebivel.dataVencimento.startsWith(p.dataVencimento),
    );

    if (!found) {
      newP.error = "Nenhum recebível encontrado!";
    }

    return newP;
  });

export const createSummary = (
  quoteResponse: PostAdminReceivablesCotacaoResponse,
): TableRow[] =>
  quoteResponse.map((item) => ({
    bandeira: item.recebivel.bandeira,
    sacado: item.recebivel.sacadoCnpj,
    sacadoIdentification: item.recebivel.sacadoCnpj,
    dataVencimento: item.recebivel.dataVencimento,
    cedente: item.recebivel.cedenteCnpj,
    cedenteIdentification: item.recebivel.cedenteCnpj,
    valorVencimento: item.recebivel.valorVencimento,
    valorDesembolso: item.cotacao,
  }));
