import { OLoader } from "@maestro/react";
import {
  clearCustomer,
  getCustomer,
  useUnsafeCustomer,
} from "contexts/customer";
import { CustomerState } from "contexts/customer/types/state";
import React, { useEffect } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { logger } from "utils/logger";
import { useCustomerRoute } from "./customer-route.hook";

interface CustomerRouteProps {
  children: React.ReactNode;
}

export const customerQueryParam = "select-customer";

export const CustomerRoute = ({ children }: CustomerRouteProps) => {
  const { customer, state } = useUnsafeCustomer();
  const [searchParams, setSearchParams] = useSearchParams();

  const customerToSelect = searchParams.get(customerQueryParam);

  const { getRedirectRoute } = useCustomerRoute();

  // Set customer from search parameters
  useEffect(() => {
    if (customerToSelect && customerToSelect !== customer?.identification) {
      logger.debug(
        `[CustomerRoute]get customer from search - identification: ${customerToSelect}`,
      );
      getCustomer(customerToSelect);
    }
  }, [customer?.identification, customerToSelect]);

  // Clear search params if the customer has already been loaded
  // or if an error occurred
  useEffect(() => {
    if (
      customer?.identification === customerToSelect ||
      state === CustomerState.ERROR
    ) {
      searchParams.delete(customerQueryParam);
      setSearchParams(searchParams, { replace: true });
    }
  }, [
    customer?.identification,
    customerToSelect,
    searchParams,
    setSearchParams,
    state,
  ]);

  useEffect(() => {
    if (state === CustomerState.ERROR) {
      logger.error("[CustomerRoute]error state, clearing customer...");

      clearCustomer();
    }
  }, [state]);

  // show loading if customer is loading or not initialized
  if (["LOADING", "INITIAL"].includes(state)) {
    logger.debug(`[CustomerRoute]loading - \`state\` is ${state}`);
    return <OLoader absolute data-component="CustomerRoute" />;
  }

  // error in getting customer, wait for clearCustomer() in useEffect
  if (state === CustomerState.ERROR) {
    return <OLoader absolute data-component="CustomerRoute" />;
  }

  // show loading if customer will be loaded from search parameters
  // in useEffect, after component renders
  if (customerToSelect) {
    return <OLoader absolute data-component="CustomerRoute" />;
  }

  // can't access a customer route if there is no customer selected
  if (!customer) {
    const redirectRoute = getRedirectRoute();
    logger.debug(`[CustomerRoute]no customer, redirecting to ${redirectRoute}`);
    return <Navigate to={redirectRoute} replace />;
  }

  logger.debug("[CustomerRoute]rendering children");

  return children as JSX.Element;
};
