import * as yup from "yup";
import { CompanySearchForm } from "./search-modal.types";

export const searchModalDefaulValues = {
  bandeira: null,
  cedentes: [],
  dataVencimentoInicial: null,
  sacados: [],
} satisfies CompanySearchForm;

export const searchModalValidationSchema = yup.object({
  cedentes: yup
    .array()
    .required("É necessário selecionar um cedente")
    .min(1, "É necessário selecionar um cedente"),
});
