import { DetailsFields } from "components/details-card";

export const OriginatorFields = (
  originator: HubPartners.Admin.OriginatorDetails,
): DetailsFields => ({
  info: {
    title: "Detalhes",
    items: [
      {
        label: "Tipo do identificador",
        value: originator.identificationType,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Identificador",
        value: originator.identification,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Razão social",
        value: originator.officialName,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Nome fantasia",
        value: originator.tradingName,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Grupo econômico",
        value: originator.economicGroup?.name,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Identificador externo",
        value: originator.identifier,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "TagCode",
        value: originator.tagCode,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Instituição respnsável",
        value: originator.mainOriginator?.name,
        grid: { sm: 6, md: 4, lg: 3 },
      },
    ],
  },
  categorization: {
    title: "Categorização",
    items: [
      {
        label: "Tipo de Parceria",
        value: originator.partnershipTypes.map((x) => x.code).join(", ") || "-",
        grid: { sm: 6, lg: 4 },
      },
      {
        label: "Subtipos do Originador",
        value:
          originator.originatorSubTypes.map((x) => x.code).join(", ") || "-",
        grid: { sm: 6, lg: 4 },
      },
      {
        label: "Produtos",
        value: originator.products?.map((x) => x.name).join(", ") || "-",
        grid: { sm: 6, lg: 4 },
      },
    ],
  },
  inication: {
    title: "Indicador",
    items: [
      {
        label: "Originador",
        value: originator.indicatorOperator?.officialName,
        grid: { xs: 6, xl: 4 },
      },
      {
        label: "Operador",
        value: originator.indicatorOriginator?.name,
        grid: { xs: 6, xl: 4 },
      },
    ],
  },
  remuneration: {
    title: "Remuneração",
    items: [
      {
        label: "Código do banco",
        value: originator.account?.bank,
        grid: { sm: 6, lg: 4 },
      },
      {
        label: "Agência",
        value: originator.account?.agency,
        grid: { sm: 6, lg: 4 },
      },
      {
        label: "Conta com o código",
        value: originator.account?.account,
        grid: { sm: 6, lg: 4 },
      },
      {
        label: "Mirror book",
        value: originator.mirror?.mirrorBook,
        grid: { xs: 6, lg: 4 },
      },
      {
        label: "Mirror strategy",
        value: originator.mirror?.mirrorStrategy,
        grid: { xs: 6, lg: 4 },
      },
    ],
  },
});
