export const cambioRouteParams = {
  id: "id",
  counterparty: "counterparty",
} as const;

type CambioParams = Record<keyof typeof cambioRouteParams, string>;

export type CambioOperacoesByIdRouteParams = Pick<CambioParams, "id">;

export type CambioOrdensByIdRouteParams = Pick<CambioParams, "id">;

export type CambioPreTicketsByIdRouteParams = Pick<
  CambioParams,
  "id" | "counterparty"
>;

export type CambioLimitesByIdRouteParams = Pick<CambioParams, "id">;

export type CambioNaturezasByIdRouteParams = Pick<CambioParams, "id">;

export interface CambioBankFormRouteQueryParam {
  Id: number;
  Name: string;
  Country: string;
  Swift: string;
  FedWire: string;
}
