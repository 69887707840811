import * as yup from "yup";

export interface BatchAssignModalForm {
  agent: number;
}

export const batchAssignModalFormValidationSchema = yup.object({
  agent: yup
    .number()
    .typeError("Necessário selecionar um agente")
    .required("Necessário selecionar um agente"),
});

export const batchAssignModalFormDefaultValues = {
  agent: null,
} as unknown as BatchAssignModalForm;
