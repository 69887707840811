import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services/service";
import { modalManagerPayload } from "utils/modal-manager-payload";
import { getValidationMessages } from "../../utils";
import {
  MoveFileForm,
  moveFileFormDefaultValues,
  moveFileFormValidationSchema,
} from "./move-file-modal.form";
import { moveFileModalId } from "./move-file-modal.utils";

export const useMoveFileModal = (
  consumerUnitId: number,
  reload: () => void,
) => {
  const [property, setProperty] =
    useState<HubEnergy.DiligenceStepPropertyResponse>();

  const { callService: moveFile, loading: submitLoading } = useServiceCall(
    service.hubEnergy.moveFile,
  );

  const {
    callService: getDocumentsCallService,
    loading: getDocumentsLoading,
    value: getDocumentsValue,
    hasError: getDocumentsHasError,
  } = useServiceCall(service.hubEnergy.getDiligenceDocuments);

  const form = useForm<MoveFileForm>({
    defaultValues: moveFileFormDefaultValues,
    resolver: yupResolver(moveFileFormValidationSchema),
  });

  const { handleSubmit, reset, watch } = form;

  const documentFileIdWatcher = watch("documentFileId");

  const removeFileFromList = useCallback(
    (fileId: number) =>
      setProperty(
        (_property) =>
          _property && {
            ..._property,
            document: _property.document && {
              ..._property.document,
              files:
                _property.document.files &&
                _property.document.files.filter(({ id }) => id !== fileId),
            },
            documentTemplate: _property.documentTemplate && {
              ..._property.documentTemplate,
              files:
                _property.documentTemplate.files &&
                _property.documentTemplate.files.filter(
                  ({ id }) => id !== fileId,
                ),
            },
          },
      ),
    [],
  );

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await moveFile({
          documentFileId: values.documentFileId,
          destinationDocumentId: values.destinationDocumentId,
        });

        if (success) {
          OToastManager.success("Arquivo movido com sucesso");
          removeFileFromList(values.documentFileId);
          getDocumentsCallService({ consumerUnitId });
        } else {
          OToastManager.danger(
            getValidationMessages(error)?.[0].ErrorMessage ??
              "Erro ao mover arquivo",
          );
        }
      }),
    [
      consumerUnitId,
      getDocumentsCallService,
      handleSubmit,
      moveFile,
      removeFileFromList,
    ],
  );

  useEffect(() => {
    reset(moveFileFormDefaultValues);
    // reset form when property changes
  }, [property, reset]);

  useEffect(() => {
    const cleanup =
      modalManagerPayload.on<HubEnergy.DiligenceStepPropertyResponse>(
        moveFileModalId,
        "modalOpen",
        setProperty,
      );
    return cleanup;
  }, []);

  useEffect(() => {
    const cleanup = modalManagerPayload.on(
      moveFileModalId,
      "modalClose",
      () => {
        setProperty(undefined);
        reload();
      },
    );
    return cleanup;
  }, [reload]);

  const files = useMemo(
    () => [
      ...(property?.document?.files ?? []),
      ...(property?.documentTemplate?.files ?? []),
    ],
    [property?.document?.files, property?.documentTemplate?.files],
  );

  const selectedFile = useMemo(
    () => files.find((file) => file.id === documentFileIdWatcher),
    [documentFileIdWatcher, files],
  );

  return {
    files,
    form,
    selectedFile,
    submit,
    submitLoading,
    // for useDiligenceDocumentSelector()
    getDocumentsCallService,
    getDocumentsLoading,
    getDocumentsValue,
    getDocumentsHasError,
  };
};
