import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { getValidationMessages } from "../../../../../../utils";

export const useGridActions = (
  documentType: HubEnergy.DocumentTypeResponse,
  reload: () => void,
) => {
  const { callService: deleteDocumentTypesById, loading } = useServiceCall(
    service.hubEnergy.deleteDocumentTypesById,
  );

  const deleteDocumentType = useCallback(async () => {
    const { success, error } = await deleteDocumentTypesById(documentType.id);

    if (success) {
      OToastManager.success("Tipo de documento excluído com sucesso");
      reload();
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0]?.ErrorMessage ??
          "Erro ao excluir o tipo de documento",
      );
    }
  }, [deleteDocumentTypesById, documentType, reload]);

  return { deleteDocumentType, loading };
};
