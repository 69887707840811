import { RedirectWithRoles } from "components/redirect-with-roles";
import { Routes } from "routes/routes.types";
import { CardsCustomerProvider } from "../contexts/cliente";
import { CardHiring } from "../pages/cliente/contratacao/contratar-cartao";
import { OfferGuarantors } from "../pages/cliente/contratacao/devedores-solidarios";
import { OfferGuarantorRegisterEmbedded } from "../pages/cliente/contratacao/devedores-solidarios/registro-embedado";
import { CardWallets } from "../pages/cliente/wallets";
import { CartoesAssistence } from "../pages/legacy/legacy-cards.page";
import { HiredOffers } from "../pages/produto/contratacoes";
import { OfferDetails } from "../pages/produto/contratacoes/detalhes";
import { CardsDashboard } from "../pages/produto/dashboard";
import { EligibilityTree } from "../pages/produto/motor-de-ofertas/arvores";
import { IntubateDebitCardOffers } from "../pages/produto/motor-de-ofertas/entuba-debito";
import { EligibilityOffers } from "../pages/produto/motor-de-ofertas/propostas-de-oferta";
import { EligibilityOfferDetails } from "../pages/produto/motor-de-ofertas/propostas-de-oferta/[offerId]/[taxId]/detalhes";
import { ReprocessEligibility } from "../pages/produto/motor-de-ofertas/reprocessar-elegibilidade";
import { CompaniesTransactionsSummary } from "../pages/produto/transacoes-por-empresas";
import { cartoesRoles } from "../roles/cartoes.roles";
import { cartoesRouteParams } from "./cartoes.route-params";

export const cartoesRoutes = {
  customer: {
    name: "",
    path: "/cartoes/cliente",
    element: <RedirectWithRoles />,
    contextProvider: CardsCustomerProvider,
    children: {
      assistance: {
        name: "Detalhes",
        path: "/cartoes/cliente/detalhes",
        role: cartoesRoles.customer.tab.role,
        element: <CartoesAssistence />,
      },
      wallets: {
        name: "Wallets",
        path: "/cartoes/cliente/wallets",
        element: <CardWallets />,
        role: cartoesRoles.customer.wallets.role,
      },
      hiring: {
        name: "Contratação",
        path: "/cartoes/cliente/contratacao",
        hasSubItems: true,
        children: {
          hireCard: {
            name: "Contratar Cartão",
            path: "/cartoes/cliente/contratacao/contratar-cartao",
            role: cartoesRoles.customer.hiringHireCard.role,
            element: <CardHiring />,
          },
          offerGuarantors: {
            name: "Devedores solidários",
            path: "/cartoes/cliente/contratacao/devedores-solidarios",
            element: <OfferGuarantors />,
            role: cartoesRoles.customer.hiringOfferGuarantors.role,
            children: {
              registerEmbedded: {
                name: "Registro - Embedado",
                path: `/cartoes/cliente/contratacao/devedores-solidarios/registro/embedado/:${cartoesRouteParams.offerGuarantorUuid}`,
                role: cartoesRoles.customer
                  .hiringOfferGuarantorsRegisterEmbedded.role,
                element: <OfferGuarantorRegisterEmbedded />,
              },
            },
          },
        },
      },
    },
  },
  product: {
    name: "Cartões",
    path: "/cartoes/produto",
    element: <RedirectWithRoles />,
    children: {
      dashboard: {
        name: "Dashboard",
        path: "/cartoes/produto/dashboard",
        role: cartoesRoles.product.dashboardPage.role,
        element: <CardsDashboard />,
      },
      hiredOffers: {
        name: "Contratações",
        path: "/cartoes/produto/contratacoes",
        role: cartoesRoles.product.hiredOffersPage.role,
        element: <HiredOffers />,
        children: {
          offerDetails: {
            name: "Detalhes da oferta",
            path: "/cartoes/produto/contratacoes/detalhes",
            role: cartoesRoles.product.hiredOffersOfferDetailsPage.role,
            element: <OfferDetails />,
          },
        },
      },
      companiesTransactionsSummary: {
        name: "Transações por Empresas",
        path: "/cartoes/produto/transacoes-por-empresas",
        role: cartoesRoles.product.companiesTransactionsSummary.role,
        element: <CompaniesTransactionsSummary />,
      },
      eligibility: {
        name: "Motor de ofertas",
        path: "/cartoes/produto/motor-de-ofertas",
        hasSubItems: true,
        children: {
          trees: {
            name: "Árvores de elegibilidade",
            path: "/cartoes/produto/motor-de-ofertas/arvores",
            hasSubItems: true,
            children: {
              credit: {
                name: "Crédito",
                role: cartoesRoles.product.eligibilityTreesCreditPage.role,
                path: `/cartoes/produto/motor-de-ofertas/arvores/credito`,
                element: <EligibilityTree productKind="CREDIT" />,
              },
              debit: {
                name: "Débito",
                role: cartoesRoles.product.eligibilityTreesDebitPage.role,
                path: `/cartoes/produto/motor-de-ofertas/arvores/debito`,
                element: <EligibilityTree productKind="DEBIT" />,
              },
            },
          },
          offers: {
            name: "Propostas de oferta",
            path: "/cartoes/produto/motor-de-ofertas/propostas-de-oferta",
            role: cartoesRoles.product.eligibilityOffersPage.role,
            element: <EligibilityOffers />,
            children: {
              details: {
                name: "Detalhes da oferta",
                role: cartoesRoles.product.eligibilityOffersDetailsPage.role,
                path: `/cartoes/produto/motor-de-ofertas/propostas-de-oferta/:${cartoesRouteParams.offerId}/:${cartoesRouteParams.taxId}/detalhes`,
                element: <EligibilityOfferDetails />,
              },
            },
          },
          reprocessEligibility: {
            name: "Reprocessar elegibilidade",
            path: "/cartoes/produto/motor-de-ofertas/reprocessar-elegibilidade",
            role: cartoesRoles.product.eligibilityReprocessEligibilityPage.role,
            element: <ReprocessEligibility />,
          },
          intubateDebitCardOffers: {
            name: "Entuba - Cartão de débito",
            path: "/cartoes/produto/motor-de-ofertas/entuba-debito",
            role: cartoesRoles.product.eligibilityIntubateDebitCardOffersPage
              .role,
            element: <IntubateDebitCardOffers />,
          },
        },
      },
    },
  },
} as const satisfies Routes;
