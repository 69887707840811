import { yupResolver } from "@hookform/resolvers/yup";
import { OCol, ORFieldInput, ORow, OToastManager } from "@maestro/react";
import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { useProfiles } from "../../../../../hooks";
import {
  EditPartnershipTypeFormFields,
  editPartnershipTypeSchema,
} from "./edit-partnership-type.form";

export const EditPartnershipType = () => {
  const { value: partnershipType } =
    useParseFromSearchParams<HubPartners.Admin.PartnershipType>("data");
  const [loading, setLoading] = useState(false);

  const { allProfiles, getProfiles, getProfilesHasError, getProfilesLoading } =
    useProfiles();

  const form = useForm<EditPartnershipTypeFormFields>({
    resolver: yupResolver(editPartnershipTypeSchema),
  });
  const { handleSubmit, reset } = form;

  const onSubmit = handleSubmit(async (values) => {
    try {
      setLoading(true);
      await service.hubPartners.putPartnershipType(values);

      OToastManager.success("Tipo de parceria atualizado com sucesso.");
    } catch {
      OToastManager.danger("Erro ao atualizar tipo de parceria.");
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    if (partnershipType) {
      reset({
        name: partnershipType.name,
        code: partnershipType.code,
        masterGatekeeperProfiles: partnershipType.masterGatekeeperProfiles,
        restrictedGatekeeperProfiles:
          partnershipType.restrictedGatekeeperProfiles,
        uuid: partnershipType.uuid,
        segmentFilterCode: partnershipType.segmentFilterCode,
      });
    }
  }, [partnershipType, reset]);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Editar tipo de parceria" />}
      actions={
        <LoadingButton loading={loading} onClick={onSubmit}>
          Editar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <ContentTemplate
          loading={getProfilesLoading}
          hasError={getProfilesHasError}
          value={!!allProfiles}
          errorComponent={
            <DefaultErrorComponent
              title="Não foi possível buscar os profiles"
              callback={getProfiles}
            />
          }
          render={() => (
            <FormProvider {...form}>
              <ORow>
                <OCol>
                  <ORFieldInput
                    tag="text"
                    label="Código"
                    name="code"
                    id="code"
                    required
                  />
                </OCol>
                <OCol>
                  <ORFieldInput
                    tag="text"
                    label="Nome"
                    name="name"
                    id="name"
                    required
                  />
                </OCol>
                <OCol>
                  <ORFieldInput
                    tag="text"
                    label="Código de segmento de filtro"
                    name="segmentFilterCode"
                    id="segmentFilterCode"
                    required
                  />
                </OCol>
              </ORow>
            </FormProvider>
          )}
        />
      </CardTemplate>
    </FormTemplate>
  );
};
