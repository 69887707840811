import { OToastManager } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { getValidationMessages } from "../../../../../../../utils";

interface ApproveDiligenceConclusionButtonProps {
  diligenceId: string | number;
  reload: () => void;
}

export const ApproveDiligenceConclusionButton = ({
  diligenceId,
  reload,
}: ApproveDiligenceConclusionButtonProps) => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.approveDiligenceConclusion,
  );

  const approveDiligenceConclusion = useCallback(async () => {
    const { success, error } = await callService({
      diligenceId: Number(diligenceId),
    });
    if (!success)
      OToastManager.danger(
        getValidationMessages(error)?.[0]?.ErrorMessage ??
          "Não foi possível concluir a migração",
      );
    else {
      OToastManager.success("Migração concluir com sucesso");
      reload();
    }
  }, [callService, diligenceId, reload]);

  return (
    <LoadingButton loading={loading} onClick={approveDiligenceConclusion}>
      Concluir migração
    </LoadingButton>
  );
};
