import { yupResolver } from "@hookform/resolvers/yup";
import { modalManager, OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { getValidationMessages } from "../../../../../utils";
import {
  AddPendencyForm,
  addPendencyFormDefaultValues,
  addPendencyFormValidationSchema,
} from "./add-pendency-modal.form";
import { addPendencyModalId } from "./add-pendency-modal.utils";

export const useAddPendencyModal = (onSubmit: () => void) => {
  const {
    callService: _getDiligence,
    loading: diligenceLoading,
    hasError: diligenceHasError,
    value: diligenceValue,
  } = useServiceCall(service.hubEnergy.getChannelDiligenceConsumerUnit);

  const { callService: _addPendency, loading: submitLoading } = useServiceCall(
    service.hubEnergy.addDiligenceStepPendency,
  );

  const form = useForm<AddPendencyForm>({
    defaultValues: addPendencyFormDefaultValues,
    resolver: yupResolver(addPendencyFormValidationSchema),
  });

  const { handleSubmit, watch, reset } = form;

  const consumerUnitWatch = watch("_consumerUnitId");

  const getDiligence = useCallback(() => {
    if (!consumerUnitWatch) {
      OToastManager.warning("Nenhuma unidade consumidora selecionada");
      return;
    }
    _getDiligence(consumerUnitWatch);
  }, [_getDiligence, consumerUnitWatch]);

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await _addPendency({
          stepId: values.stepId,
          typeId: values.typeId,
          isAutomation: values.isAutomation,
          isPublic: !!values.isPublic,
          hasBpoAction: !!values.hasBpoAction,
          message: values.message,
          propertyId: values.propertyId,
          status: values.status,
        });
        if (success) {
          OToastManager.success("Tarefa adicionada com sucesso");
          modalManager.hide(addPendencyModalId);
          onSubmit();
        } else
          OToastManager.danger(
            getValidationMessages(error)?.[0].ErrorMessage ??
              "Erro ao adicionar tarefa",
          );
      }),
    [_addPendency, handleSubmit, onSubmit],
  );

  useEffect(() => {
    if (consumerUnitWatch) getDiligence();
  }, [consumerUnitWatch, getDiligence]);

  useEffect(() => {
    const cleanup = modalManager.on(addPendencyModalId, "modalOpen", () => {
      reset(addPendencyFormDefaultValues);
    });
    return cleanup;
  }, [reset]);

  return {
    diligence: diligenceValue?.response,
    diligenceHasError,
    diligenceLoading,
    form,
    getDiligence,
    submit,
    submitLoading,
  };
};
