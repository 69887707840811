import {
  MasterDetailComponentProps,
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";

const grid: ODataGridGeneratorConfig<HubOnboardingRelationships.Guarantor.File> =
  {
    datagrid: {
      noDataText: "Nenhum arquivo",
      pager: {
        infoText: "Página {0} de {1} ({2} arquivos)",
      },
      filterRow: {
        visible: true,
      },
      headerFilter: {
        visible: true,
      },
      wordWrapEnabled: true,
    },
    columns: [
      { dataField: "id", caption: "ID", allowHeaderFiltering: false },
      { dataField: "name", caption: "Nome", allowHeaderFiltering: false },
      { dataField: "type", caption: "Tipo" },
      {
        dataField: "errorMessage",
        caption: "Ocorrência",
        cssClass: "dx-word-wrap",
        allowHeaderFiltering: false,
      },
    ],
  };

type DocumentsMasterDetailProps =
  MasterDetailComponentProps<HubOnboardingRelationships.Guarantor.Document>;

export const DocumentsMasterDetail = ({
  data: {
    data: { files },
  },
}: DocumentsMasterDetailProps) => {
  return <ODataGridGenerator grid={grid} dataSource={files} />;
};
