import { useStepsBuilder } from "components/steps-builder";
import { useServiceCall } from "hooks/service-call";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { service } from "services";
import {
  PostAdminOperationsAntecipacaoResponse,
  PostAdminReceivablesCotacaoBody,
  PostAdminReceivablesCotacaoResponse,
} from "services/quickfin/models";
import { BankAccountWithTaxId } from "../../../components/generic-bank-data/generic-bank-data.types";
import { QuoteIntubate } from "../../../types";
import { validateNoReturn } from "./quote.utils";

interface QuoteContext {
  anticipate: () => unknown;
  anticipateHasError: boolean;
  anticipateLoading: boolean;
  anticipateResponse: PostAdminOperationsAntecipacaoResponse | undefined;
  bankAccount: BankAccountWithTaxId | undefined;
  getQuote: (body: PostAdminReceivablesCotacaoBody) => unknown;
  quoteData: PostAdminReceivablesCotacaoResponse;
  quoteFileData: QuoteIntubate[];
  quoteHasError: boolean;
  quoteLoading: boolean;
  setBankAccount: (bankAccount: BankAccountWithTaxId | undefined) => void;
  setQuoteFileData: (quote: QuoteIntubate[]) => void;
}

const quoteContext = createContext({} as QuoteContext);

interface QuoteProviderProps {
  children: React.ReactNode;
}

export const QuoteProvider = ({ children }: QuoteProviderProps) => {
  const [bankAccount, setBankAccount] = useState<BankAccountWithTaxId>();
  const [quoteFileData, setQuoteFileData] = useState<QuoteIntubate[]>([]);
  const [quoteData, setQuoteData] =
    useState<PostAdminReceivablesCotacaoResponse>([]);

  const { stepNumber } = useStepsBuilder();

  const {
    callService: getQuote,
    hasError: quoteHasError,
    loading: quoteLoading,
    value: quoteResponse,
  } = useServiceCall(service.quickfin.getQuote);

  const {
    callService: callAnticipate,
    hasError: anticipateHasError,
    loading: anticipateLoading,
    value: anticipateResponse,
  } = useServiceCall(service.quickfin.anticipate);

  const anticipate = useCallback(
    () =>
      bankAccount &&
      callAnticipate({
        conta: {
          agencia: bankAccount.branch,
          codigoBanco: bankAccount.bank,
          digitoConta: bankAccount.account_digit,
          numeroConta: bankAccount.account,
        },
        recebiveis: quoteFileData,
      }),
    [bankAccount, callAnticipate, quoteFileData],
  );

  useEffect(() => {
    if (quoteResponse) {
      setQuoteFileData((_quoteFileData) =>
        validateNoReturn(_quoteFileData, quoteResponse),
      );
      setQuoteData(quoteResponse);
    }
  }, [quoteResponse]);

  useEffect(() => {
    if (stepNumber === 0) {
      setQuoteFileData([]);
      setQuoteData([]);
      setBankAccount(undefined);
    }
  }, [stepNumber]);

  const value = useMemo(
    () => ({
      anticipate,
      bankAccount,
      getQuote,
      quoteData,
      quoteFileData,
      quoteHasError,
      quoteLoading,
      setBankAccount,
      setQuoteFileData,
      anticipateHasError,
      anticipateLoading,
      anticipateResponse,
    }),
    [
      anticipate,
      anticipateHasError,
      anticipateLoading,
      anticipateResponse,
      bankAccount,
      getQuote,
      quoteData,
      quoteFileData,
      quoteHasError,
      quoteLoading,
    ],
  );

  return (
    <quoteContext.Provider value={value}>{children}</quoteContext.Provider>
  );
};

export const useQuote = () => useContext(quoteContext);
