import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { BankAccountsItem } from "services/admin-bankinghub/models";
import { GetContactByIdResponseItem } from "services/bankinghub/models";
import { ContactsFavoredEditByUuidRouteParams } from "../../../../../cadastro/routes/cadastro.route-params";
import { FormValuesType, validationSchema } from "../_compose";
import { handleValues } from "../contacts.utils";

export const useContactsFavoredEdit = () => {
  const { uuid } = useParams<ContactsFavoredEditByUuidRouteParams>();
  if (!uuid) throw new Error("No id in route parameters");

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState<
    GetContactByIdResponseItem | undefined
  >();

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, reset } = form;

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);
      if (!contact) return;

      const newValues = handleValues(values, uuid);
      const bankAccounts =
        contact.product?.bankAccounts?.filter((bank) => bank.id !== uuid) ?? [];
      bankAccounts.push(newValues);

      const payload = {
        contact_holder: contact.contact_holder,
        document_number: contact.document_number,
        id: contact.id,
        product: { ...contact.product, bankAccounts },
      };

      await service.adminBankinghub.updateContact(payload);

      OToastManager.success("Cadastrado com sucesso");

      navigate(corporateRouter.routes.banking.customer.contacts.path);
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  });

  const resetValues = useCallback(
    (values?: BankAccountsItem) => {
      if (!values) return;

      const isDisbursementAccount = values.is_disbursement_account ? "S" : "N";

      const newValues = {
        ...values,
        is_disbursement_account: isDisbursementAccount,
      };

      reset(newValues);
    },
    [reset],
  );

  const load = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await service.adminBankinghub.getContactByHolder();

      const getContact = data.find((c) => c.same_ownership);

      const values = getContact?.product.bankAccounts?.find(
        (bank) => bank.id === uuid,
      );

      setContact(getContact);
      resetValues(values);
    } catch (error) {
      OToastManager.danger("Erro para buscar os dados");
    } finally {
      setLoading(false);
    }
  }, [resetValues, uuid]);

  useEffect(() => {
    load();
  }, [load]);

  return { form, submit, loading };
};
