import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { useCurrencies } from "hooks/currency/currency.hook";
import { ReactNode } from "react";
import { PostPreticketByChannelGetResponseContent } from "services/hubfx/models/responses";
import { ExchangeLimitResponse } from "services/hubfx/models/types/limit/limit.model";
import { ExchangePreTicketResponse } from "services/hubfx/models/types/pre-ticket/pre-ticket.model";
import { format } from "utils/date";
import { parseQuoteFlowType } from "../../utils/quote.utils";

export const exchangePreTicketDetailsGenerator = (
  preTicket:
    | PostPreticketByChannelGetResponseContent
    | (ExchangePreTicketResponse & {
        limit: ExchangeLimitResponse | null;
      }),
  title: ReactNode = "Pré-Ticket",
) => {
  const { mapSymbol } = useCurrencies();

  const symbol = mapSymbol(parseQuoteFlowType(preTicket)?.currency);

  return {
    preTicket: {
      title,
      items: [
        {
          label: "Id",
          value: preTicket.id,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Dt. Criação",
          value: format.dateTime(preTicket.createdDate),
          grid: { md: 4, xl: 3 },
        },
        {
          label: parseQuoteFlowType(preTicket)?.externalParty,
          value: parseQuoteFlowType(preTicket)?.externalPartyName,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Status",
          value: preTicket.status,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "País",
          value: preTicket.country,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Moeda",
          value: parseQuoteFlowType(preTicket)?.currency,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Tipo",
          value: preTicket.flowType,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    value: {
      title: "Valor pré-ticket",
      items: [
        {
          label: "Valor solicitado",
          value: masks.currency(preTicket.amount, symbol, "."),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Valor disponível",
          value: masks.currency(preTicket.limit?.availableAmount, symbol, "."),
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    nature: {
      title: "Enquadramento pré-ticket",
      items: [
        {
          label: "Enquadramento",
          value: preTicket.natureDescription,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  } satisfies DetailsFields;
};
