import {
  OFilterItem,
  OInputCpfCnpj,
  OInputText,
  OOption,
  OSelect,
  OTypography,
} from "@maestro/react";
import { useState } from "react";

export const PayerFilterItem = () => {
  const [isFilterName, setIsFilterName] = useState(true);

  return (
    <OFilterItem>
      <OTypography slot="title">Nome do Cliente / Documento</OTypography>
      <div className="d-flex align-items-center justify-content-between py-2 px-2">
        <OSelect
          className="w-30 bg-transparent"
          aspect="flushed"
          iconSize="xl"
          placeholder="Nome"
          defaultValue="payer_name"
          dataAction=""
          dataLabel=""
        >
          <OOption
            className="bg-light"
            onClick={() => setIsFilterName(true)}
            value="payer_name"
          >
            Nome
          </OOption>
          <OOption
            className="bg-light"
            onClick={() => setIsFilterName(false)}
            value="payer_document"
          >
            CPF/CNPJ
          </OOption>
        </OSelect>

        {isFilterName ? (
          <OInputText
            className="w-60"
            dataAction=""
            dataLabel=""
            name="input_payer_name"
            id="payer_name"
            placeholder="Buscar..."
            aspect="flushed"
            data-filterparam="payerName"
          />
        ) : (
          <OInputCpfCnpj
            className="w-60"
            dataAction=""
            dataLabel=""
            name="input_payer_document"
            id="payer_cpf_cnpj"
            placeholder="Buscar..."
            aspect="flushed"
            data-filterparam="payerTaxId"
          />
        )}
      </div>
    </OFilterItem>
  );
};
