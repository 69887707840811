import { OToastManager, modalManager } from "@maestro/core";
import type { DataGrid } from "devextreme-react/data-grid";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { getValidationMessages } from "../../../../../utils";
import { BatchStepStatusForm } from "./batch-step-status-modal.types";
import { batchStepStatusModalId } from "./batch-step-status-modal.utils";

export const useBatchStepStatusModal = (
  selectedSteps: HubEnergy.DiligenceStepMonitor[],
  gridRef: React.RefObject<DataGrid>,
) => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.updateDiligenceStepStatusBatch,
  );

  const form = useForm<BatchStepStatusForm>();

  const { getValues } = form;

  const submit = useCallback(async () => {
    const status = getValues("status");
    if (!status)
      return OToastManager.warning("É necessário selecionar um status");
    if (!selectedSteps?.length)
      return OToastManager.warning("É necessário selecionar as steps");

    const { success, error } = await callService({
      diligenceStepIds: selectedSteps.map(
        ({ diligenceStepId }) => diligenceStepId,
      ),
      status,
    });

    if (success) {
      OToastManager.success("Status alterados com sucesso");
      gridRef.current?.instance?.getDataSource()?.reload?.();
      gridRef.current?.instance?.deselectAll?.();
      modalManager.hide(batchStepStatusModalId);
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0].ErrorMessage ??
          "Erro ao alterar os status",
      );
    }
  }, [callService, getValues, gridRef, selectedSteps]);

  return { form, loading, submit };
};
