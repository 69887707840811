import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { ExchangeLimitResponse } from "services/hubfx/models/types/limit/limit.model";
import { ExchangePreTicketResponse } from "services/hubfx/models/types/pre-ticket/pre-ticket.model";
import { parseQuoteFlowType } from "../../utils/quote.utils";

export const exchangePreTicketsGrid = (
  mapSymbol: (currency?: string | null) => string,
) => {
  return {
    datagrid: {
      noDataText: "Nenhum pré-ticket encontrado.",
      headerFilter: { visible: true },
      pager: {
        showInfo: true,
        visible: true,
        allowedPageSizes: [10, 20, 30],
        showPageSizeSelector: true,
      },
    },
    columns: [
      {
        type: "buttons",
        role: roles.cambio.customer.preTicketsDetailsPage.role,
        cellRender: ({ data }) =>
          !!data.id && (
            <DetailsLink
              href={corporateRouter.routes.cambio.customer.preTickets.details.path(
                {
                  id: data.id,
                },
              )}
            />
          ),
      },
      {
        sortOrder: "desc",
        dataField: "id",
        caption: "Id",
        dataType: "number",
      },
      {
        dataField: "createdDate",
        caption: "Data de criação",
        dataType: "datetime",
        format: "shortDateShortTime",
      },
      {
        caption: "Beneficiário/Pagador",
        cellRender: ({ data }) =>
          parseQuoteFlowType({
            buyCurrencyType: data.buyCurrencyType,
            flowType: data.flowType,
            sellCurrencyType: data.sellCurrencyType,
          })?.externalPartyName,
      },
      {
        caption: "Moeda",
        cellRender: ({ data }) =>
          parseQuoteFlowType({
            buyCurrencyType: data.buyCurrencyType,
            flowType: data.flowType,
            sellCurrencyType: data.sellCurrencyType,
          })?.currency,
      },
      {
        caption: "País",
        dataField: "country",
      },
      {
        caption: "Status",
        dataField: "status",
      },
      {
        caption: "Enquadramento",
        dataField: "natureDescription",
      },
      {
        caption: "Valor solicitado",
        cellRender: ({ data }) =>
          masks.currency(
            data.amount,
            mapSymbol(
              parseQuoteFlowType({
                buyCurrencyType: data.buyCurrencyType,
                flowType: data.flowType,
                sellCurrencyType: data.sellCurrencyType,
              })?.currency,
            ),
            ".",
          ),
      },
      {
        caption: "Valor disponível",
        cellRender: ({ data }) =>
          masks.currency(
            data.limit?.availableAmount,
            mapSymbol(
              parseQuoteFlowType({
                buyCurrencyType: data.buyCurrencyType,
                flowType: data.flowType,
                sellCurrencyType: data.sellCurrencyType,
              })?.currency,
            ),
            ".",
          ),
      },
      {
        caption: "Pendências",
        dataField: "pendenciesCount",
      },
    ],
  } satisfies ODataGridGeneratorConfig<
    ExchangePreTicketResponse & {
      limit: ExchangeLimitResponse | null;
      pendenciesCount: number | null;
    }
  >;
};
