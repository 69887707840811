import { OToastManager } from "@maestro/core";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { BankAccount } from "services/clerk";
import { client } from "services/client";
import { endpoints } from "services/endpoints";
import {
  SharkConfirmationResponse,
  SharkNextBusinessDays,
  SharkPaymentMethodType,
  SharkSettlementData,
  SharkSimulateResponse,
} from "services/shark/models/responses";
import { EmprestimosClienteOperacoesByIdAndContractNumber } from "../../../../../../../routes/emprestimos.route-params";

interface AnticipationContextProps {
  sharkSettlement?: SharkSettlementData;
  getSharkSettlement: () => Promise<void>;
  simulate: (
    installments: number[],
    position_date?: string,
  ) => Promise<SharkSimulateResponse | undefined>;
  confirmSimulation: (
    simulation_identification: string,
    settlement_method: SharkPaymentMethodType,
    bankAccount?: BankAccount,
  ) => Promise<SharkConfirmationResponse | undefined>;
  nextBusinessDays: string[];
  loading: boolean;
  error: boolean;
}

export const AnticipationContext = createContext<AnticipationContextProps>(
  {} as AnticipationContextProps,
);

export const AnticipationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [sharkSettlement, setSharkSettlement] = useState<SharkSettlementData>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [nextBusinessDays, setNextBusinessDays] = useState<string[]>([]);

  const [searchParams] = useSearchParams("currency");
  const navigate = useNavigate();

  const { contractNumber, id } =
    useParams<EmprestimosClienteOperacoesByIdAndContractNumber>();
  if (!contractNumber) throw new Error("No contract number");
  if (!id) throw new Error("No id");

  const currency = useMemo(
    () => searchParams.get("currency") ?? "BRL",
    [searchParams],
  );

  const getSharkSettlement = useCallback(async () => {
    try {
      const { data } = await client.get<SharkSettlementData>(
        endpoints.shark.v1.prepayments.getSettlement(contractNumber),
        {
          params: {
            contract_origin: "PME",
          },
        },
      );

      setSharkSettlement(data);
    } catch (e) {
      OToastManager.warning(
        "Um erro ocorreu ao tentarmos validar os dados do empréstimo. Por favor, tente novamente mais tarde.",
      );
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [contractNumber]);

  const getNextBusinessDays = useCallback(async (delta: number | string) => {
    try {
      const { data } = await client.get<SharkNextBusinessDays>(
        endpoints.shark.v1.calendar.nextBusinessDays(delta),
      );

      setNextBusinessDays(data.next_business_days.reverse());
    } catch (e) {
      OToastManager.warning(
        "Não foi possível buscar os próximos dias úteis possíveis para emitir do boleto bancário. Por favor, tente novamente mais tarde.",
      );
    }
  }, []);

  const simulate = useCallback(
    async (installments: number[], position_date?: string) => {
      const { data } = await client.post<SharkSimulateResponse>(
        endpoints.shark.v1.prepayments.simulateSettlement(contractNumber),
        {
          installments,
          contract_origin: "PME",
          position_date,
        },
      );

      return data;
    },
    [contractNumber],
  );

  const confirmSimulation = useCallback(
    async (
      simulation_identification: string,
      settlement_method: SharkPaymentMethodType,
      bankAccount?: BankAccount,
    ) => {
      if (
        settlement_method === "C/C" &&
        (!bankAccount?.accountNumber || !bankAccount?.agency)
      ) {
        OToastManager.danger(
          "É necessário ter uma conta ativa para poder antecipar as parcelas por débito em conta corrente.",
        );
        throw new Error("Dados da conta não encontrados.");
      }

      let checkingAccount: string | undefined;

      if (bankAccount) {
        checkingAccount = bankAccount.accountNumber;
        if (bankAccount.accountDigit)
          checkingAccount += bankAccount.accountDigit;
      }

      const { data } = await client.post<SharkConfirmationResponse>(
        endpoints.shark.v1.prepayments.confirmSettlement(contractNumber),
        {
          simulation_identification,
          settlement_method,
          checking_account: checkingAccount,
          agency: bankAccount?.agency,
        },
      );

      return data;
    },
    [contractNumber],
  );

  useEffect(() => {
    if (!!currency && currency !== "BRL") {
      OToastManager.warning(
        "No momento, não é possível antecipar empréstimos realizados em moeda estrangeira.",
      );

      navigate(
        corporateRouter.routes.emprestimos.customer.operations.quotes.path({
          id,
        }),
      );
    }
  }, [currency, id, navigate]);

  useEffect(() => {
    const paymentMethod = sharkSettlement?.agreement.payment_methods.find(
      (m) => m.short_name === "BOLETO",
    );

    if (paymentMethod) {
      getNextBusinessDays(paymentMethod.max_days_to_pay);
    }
  }, [sharkSettlement, getNextBusinessDays]);

  const anticipationContextProvider = useMemo(() => {
    return (
      <AnticipationContext.Provider
        value={{
          sharkSettlement,
          getSharkSettlement,
          simulate,
          confirmSimulation,
          nextBusinessDays,
          loading,
          error,
        }}
      >
        {children}
      </AnticipationContext.Provider>
    );
  }, [
    children,
    confirmSimulation,
    error,
    getSharkSettlement,
    loading,
    nextBusinessDays,
    sharkSettlement,
    simulate,
  ]);

  return anticipationContextProvider;
};

export const useAnticipation = () => useContext(AnticipationContext);
