import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { getValidationMessages } from "../../../../../utils";

export const useGridActions = () => {
  const { callService: _recreateTrade, loading: recreateTradeLoading } =
    useServiceCall(service.hubEnergy.recreateTrade);

  const navigate = useNavigate();

  const recreateTrade = useCallback(
    async (tradeId: number) => {
      const { success, error, response } = await _recreateTrade({
        tradeId,
      });

      if (success) {
        OToastManager.success("Trade recriado com sucesso");

        const newTradeId = response.data.response;
        if (newTradeId !== null)
          navigate(
            corporateRouter.routes.energia.product.trades.byId.details.path({
              tradeId: newTradeId,
            }),
          );
      } else {
        const title = "Não foi possível recriar o trade";
        const message = getValidationMessages(error)?.[0]?.ErrorMessage;

        OToastManager.danger(
          message
            ? {
                title,
                message,
              }
            : title,
        );
      }
    },
    [_recreateTrade, navigate],
  );

  return {
    loading: recreateTradeLoading,
    recreateTrade,
  };
};
