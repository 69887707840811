import { ODataGridGenerator } from "components/data-grid";
import { DetailsCard } from "components/details-card";
import { StandardLink } from "components/standard-link";
import { useRoles } from "hooks/roles";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { PostPreticketByChannelGetResponseContent } from "services/hubfx/models/responses";
import { exchangePreTicketDocumentsGrid } from "./documents/pre-ticket-documents.grid";
import { filteredPreTicketDocuments } from "./documents/pre-ticket-documents.utils";
import { exchangePreTicketPendenciesGrid } from "./pendencies/pre-ticket-pendencies.grid";
import { exchangePreTicketDetailsGenerator } from "./pre-ticket.details";

interface ExchangePreTicketDetailsProps {
  preTicketDetails: PostPreticketByChannelGetResponseContent | undefined;
  type: "customer" | "product";
}

export const ExchangePreTicketDetails = ({
  preTicketDetails,
  type,
}: ExchangePreTicketDetailsProps) => {
  const { hasRole } = useRoles();
  if (!preTicketDetails) return null;
  const { pendencies, documentGroups, orderId } = preTicketDetails;

  const documents = filteredPreTicketDocuments(documentGroups);

  return (
    <div className="d-flex flex-column gap-2">
      <DetailsCard
        value={preTicketDetails}
        fields={(value) => exchangePreTicketDetailsGenerator(value, undefined)}
      />
      {!!pendencies?.length && (
        <DetailsCard
          value={pendencies}
          fields={(value) => ({
            pendencies: {
              title: "Pendências",
              items: [
                {
                  value: (
                    <ODataGridGenerator
                      grid={exchangePreTicketPendenciesGrid}
                      dataSource={value}
                    />
                  ),
                },
              ],
            },
          })}
        />
      )}

      {!!documents?.length && (
        <DetailsCard
          value={documents}
          fields={(value) => ({
            documents: {
              title: "Documentos enviados",
              items: [
                {
                  value: (
                    <ODataGridGenerator
                      grid={exchangePreTicketDocumentsGrid}
                      dataSource={value}
                    />
                  ),
                },
              ],
            },
          })}
        />
      )}

      {orderId && (
        <DetailsCard
          value={preTicketDetails}
          fields={(preTicket) => {
            const { orderPayer, flowType, buyCurrencyType, sellCurrencyType } =
              preTicket;

            const currency =
              flowType === "INTERNACAO" ? sellCurrencyType : buyCurrencyType;

            return {
              order: {
                title: hasRole(roles.cambio[type].ordersDetailsPage.role) ? (
                  <StandardLink
                    href={corporateRouter.routes.cambio[
                      type
                    ].orders.details.path({ id: orderId })}
                    size="lg"
                    type="default"
                  >
                    Ordem
                  </StandardLink>
                ) : undefined,
                items: [
                  {
                    label: "Id",
                    value: orderId,
                    grid: { md: 4, xl: 3 },
                  },
                  {
                    label: "Moeda",
                    value: currency,
                    grid: { md: 4, xl: 3 },
                  },
                  {
                    label: "Pagador",
                    value: orderPayer,
                    grid: { md: 4, xl: 3 },
                  },
                ],
              },
            };
          }}
        />
      )}
    </div>
  );
};
