import { OCard, OCardBody, OLoader } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { FormTemplate } from "templates/form-template";
import { IndicationFields } from "../../../../components/originator";
import { useUpdateOriginatorIndication } from "./update-originator-indication.hook";

export const UpdateOriginatorIndication = () => {
  const { form, loading, loadingSubmit, onSubmit } =
    useUpdateOriginatorIndication();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Indicação"
          description="Informe caso o originador foi indicado por algum Parceiro Estratégico."
        />
      }
      actions={
        <LoadingButton loading={loading || loadingSubmit} onClick={onSubmit}>
          Cadastrar
        </LoadingButton>
      }
    >
      <FormProvider {...form}>
        <OCard>
          <OCardBody>
            {loading ? <OLoader /> : <IndicationFields isEditing />}
          </OCardBody>
        </OCard>
      </FormProvider>
    </FormTemplate>
  );
};
