import { useState, useEffect } from "react";
import { service } from "services";
import { logger } from "utils/logger";

export enum CentralBankEnabledSystem {
  STR = "STR",
  SPI = "SPI",
}

export interface FinancialInstitution {
  ispb: string;
  shortName: string;
  fullName: string;
  centralBankEnabledSystems: CentralBankEnabledSystem[];
  bankCode?: string;
}

export const useFinancialInstitution = (
  filterBy?: CentralBankEnabledSystem,
) => {
  const [financialInstitutionList, setFinancialInstitutionList] = useState<
    FinancialInstitution[]
  >([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await service.assets.getFinancialInstitutions();
        setFinancialInstitutionList(
          data.financialInstitutions
            .filter((fi: FinancialInstitution) => {
              if (filterBy)
                return fi.centralBankEnabledSystems.includes(filterBy);
              return true;
            })
            .sort((a: FinancialInstitution, b: FinancialInstitution) => {
              if (a.bankCode && b.bankCode)
                return a.bankCode.localeCompare(b.bankCode);
              return false;
            }),
        );
      } catch (error) {
        logger.error("Error fetching bank data:", error);
      }
    };

    fetchData();
  }, [filterBy]);

  return financialInstitutionList;
};
