import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import { useRoles } from "hooks/roles";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { roles } from "roles/roles";
import { service } from "services/service";
import { NewOriginatorFormSchema } from "./new-originator.form";

export const useNewOriginator = () => {
  const { hasRole } = useRoles();
  const [loading, setLoading] = useState(false);

  const form = useForm<HubPartners.Admin.NewOriginator>({
    resolver: yupResolver(NewOriginatorFormSchema),
  });
  const { handleSubmit } = form;

  const isTechnologyDetails = hasRole(
    roles.parceiros.product.originadoresInfoFields.role,
  );
  const isTechnologyPermissions = hasRole(
    roles.parceiros.product.originadoresPermissionFields.role,
  );

  const onSubmit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      await service.hubPartners.registerOriginator(values);

      OToastManager.success("Originador adicionado com sucesso!");
    } catch (err) {
      if (isAxiosError<HubPartners.Admin.ErrorResponse>(err)) {
        err.response?.data?.failures?.forEach((failure) => {
          failure.message && OToastManager.danger(failure.message);
        });
      } else {
        OToastManager.danger("Não foi possível cadastrar o originador.");
      }
    } finally {
      setLoading(false);
    }
  });

  return {
    form,
    isTechnologyDetails,
    isTechnologyPermissions,
    loading,
    onSubmit,
  };
};
