import {
  ODivider,
  OLoader,
  ORow,
  OTypography,
  modalManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { TipoProdutoField } from "../../../../../../components/estrategia-form/fields";
import {
  UpdateEstrategiasConfirmationModal,
  updateEstrategiasConfirmationModalId,
} from "../_compose";
import { useEstrategiaTemplateDetails } from "./estrategia-template-details.hook";

export const EstrategiaTemplateDetails = () => {
  const { Form, form, loading, submit, submitLoading } =
    useEstrategiaTemplateDetails();

  return (
    <>
      <FormTemplate
        pageTitle={<PageTitle title="Detalhes Estratégia" />}
        actions={
          <LoadingButton
            dataAction="formulario:botao:salvar"
            dataLabel="salvar"
            loading={submitLoading}
            disabled={loading}
            onClick={() =>
              modalManager.show(updateEstrategiasConfirmationModalId)
            }
          >
            Salvar
          </LoadingButton>
        }
      >
        <CardTemplate>
          {(loading || submitLoading) && <OLoader absolute backdrop />}
          <FormProvider {...form}>
            <OTypography size="lg">Tipo de produto</OTypography>
            <ODivider type="dark" size="xxs" className="mb-2" />
            <ORow columnGap={4} rowGap={5} className="ms-2">
              <TipoProdutoField type="template" disabled />
            </ORow>

            {Form && <Form form={form} />}
          </FormProvider>
        </CardTemplate>
      </FormTemplate>
      <UpdateEstrategiasConfirmationModal submit={submit} />
    </>
  );
};
