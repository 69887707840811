import { OToastManager, modalManager } from "@maestro/core";
import { dataSourceCustomStoreGenerator } from "components/data-grid";
import { useCallback, useEffect, useMemo } from "react";
import { service } from "services";
import { GetContactByContactHolderResponseItem } from "services/admin-bankinghub/models";
import { contactsGrid } from "./contacts.grid";

export const useContactsFavored = () => {
  const dataSource = useMemo(() => {
    return dataSourceCustomStoreGenerator<GetContactByContactHolderResponseItem>(
      () =>
        service.adminBankinghub
          .getContactByHolder()
          .then(({ data }) => data)
          .catch(() => {
            const errorMessage = "Erro ao buscar os contas";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }),
    );
  }, []);

  const grid = useMemo(() => contactsGrid(), []);

  const getClerkContacts = useCallback(() => {
    return service.clerk
      .getBankAccount()
      .catch(() => OToastManager.danger("Erro aos buscar contas"));
  }, []);

  useEffect(() => {
    getClerkContacts();
  }, [getClerkContacts]);

  useEffect(() => {
    const cleanUp = modalManager.on(
      "contacts-favored-delete-modal",
      "modalClose",
      () => {
        getClerkContacts();
        dataSource.reload();
      },
    );

    return cleanUp;
  }, [dataSource, getClerkContacts]);

  return { grid, dataSource, getClerkContacts };
};
