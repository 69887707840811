import { SelectSearchFieldOption } from "components/select-search/field/select-search-field.types";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { AddPendencyForm } from "../../add-pendency-modal.form";

export const usePendencyTypeSelector = () => {
  const { callService, loading, hasError, value } = useServiceCall(
    service.hubEnergy.getDiligenceStepPendencyTypes,
  );

  const { clearErrors, setError } = useFormContext<AddPendencyForm>();

  const getPendencyTypes = useCallback(async () => {
    const { success } = await callService();
    if (success) clearErrors("typeId");
    else
      setError("typeId", {
        message:
          "Erro ao buscar os tipos de pendência. Clique no botão ao lado para tentar novamente.",
      });
  }, [callService, clearErrors, setError]);

  useEffect(() => {
    getPendencyTypes();
  }, [getPendencyTypes]);

  const pendencyTypes = useMemo(
    () =>
      value?.response.map<SelectSearchFieldOption<number>>(
        ({ id, name, description }) => ({
          label: `${name} - ${description}`,
          value: id,
        }),
      ) ?? [],
    [value?.response],
  );

  return {
    pendencyTypes,
    getPendencyTypes,
    loading,
    hasFetchError: hasError,
  };
};
