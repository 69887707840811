import { OToastManager, modalManager } from "@maestro/core";
import { OConfirmationModal, OTypography } from "@maestro/react";
import { IconButton } from "components/icon-button";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { getValidationMessages } from "../../../../../../utils";

const modalId = "execute-import-confirmation-modal";

interface ExecuteButtonProps {
  importData: HubEnergy.LegacyImport.ConsumerUnitsImportDataResponse;
}

export const ExecuteButton = ({ importData }: ExecuteButtonProps) => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.executeConsumerUnitsImport,
  );

  const navigate = useNavigate();

  const executeImportData = useCallback(async () => {
    const { error, response, success } = await callService({
      consumerUnitsImportDataId: importData.id,
    });

    if (success && response.data.success) {
      OToastManager.success("A importação foi executada com sucesso");
      navigate(corporateRouter.routes.energia.product.consumerUnitsImport.path);
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0].ErrorMessage ??
          "Erro ao executar a importação",
      );
    }
  }, [callService, importData.id, navigate]);

  return (
    <>
      <IconButton
        icon={{ category: "orq", icon: "orq-play" }}
        loading={loading}
        onClick={() => modalManager.show(modalId)}
        disabled={importData.status !== "PENDENTE_CONFIRMACAO"}
      >
        Executar importação
      </IconButton>
      <OConfirmationModal
        modalId={modalId}
        title="Executar importação"
        divider={false}
        onConfirm={() => {
          executeImportData();
          modalManager.hide(modalId);
        }}
        className="position-absolute"
      >
        <OTypography>Tem certeza que deseja executar a importação?</OTypography>
      </OConfirmationModal>
    </>
  );
};
