import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { ExchangeOrderResponse } from "services/hubfx/models/types/order/order.model";

export const exchangeOrdersGrid = (
  mapSymbol: (currency?: string | null) => string,
) => {
  return {
    datagrid: {
      noDataText: "Nenhuma ordem encontrada.",
      headerFilter: { visible: true },
      pager: {
        showInfo: true,
        visible: true,
        allowedPageSizes: [10, 20, 30],
        showPageSizeSelector: true,
      },
    },
    columns: [
      {
        type: "buttons",
        role: roles.cambio.customer.ordersDetailsPage.role,
        cellRender: ({ data }) =>
          !!data.id && (
            <DetailsLink
              href={corporateRouter.routes.cambio.customer.orders.details.path({
                id: data.id,
              })}
            />
          ),
      },
      {
        sortOrder: "desc",
        dataField: "id",
        caption: "Id",
        dataType: "number",
      },
      {
        dataField: "createdDate",
        caption: "Data da operação",
        dataType: "datetime",
        format: "shortDateShortTime",
      },
      {
        dataField: "olderReceipt",
        caption: "Recibo mais antigo",
        dataType: "datetime",
        format: "shortDateShortTime",
      },
      { caption: "Pagador", dataField: "payer" },
      { caption: "Moeda", dataField: "currency" },
      {
        caption: "Quantidade",
        cellRender: ({ data }) =>
          masks.currency(data.amount, mapSymbol(data.currency), "."),
      },
      {
        caption: "Saldo",
        cellRender: ({ data }) =>
          masks.currency(data.balanceAmount, mapSymbol(data.currency), "."),
      },
      {
        caption: "Bloqueado",
        cellRender: ({ data }) =>
          masks.currency(data.blockedAmount, mapSymbol(data.currency), "."),
      },
      {
        caption: "Em trânsito",
        cellRender: ({ data }) =>
          masks.currency(data.inTransitAmount, mapSymbol(data.currency), "."),
      },
    ],
  } satisfies ODataGridGeneratorConfig<
    Omit<ExchangeOrderResponse, "conciliationId" | "lastUpdate">
  >;
};
