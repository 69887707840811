import { yupResolver } from "@hookform/resolvers/yup";
import {
  OCol,
  ORFieldInput,
  ORFieldRadioGroup,
  ORadio,
  OToastManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { useCallback, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { PostAdminCurrency } from "services/hubfx/models/requests/post-admin-currencies.request";
import { PostByIdAdminCurrency } from "services/hubfx/models/requests/post-by-id-admin-currencies.request";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { currencyFormValidationSchema } from "./exchange-product-currencies-form.schemas";
import {
  NonStopTradingFields,
  ORFieldInputs,
  ORFieldRadioGroups,
  SameDayFields,
  stringToTime,
} from "./exchange-product-currencies-form.utils";

export const ExchangeProductCurrenciesFormPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { value: currency } =
    useParseFromSearchParams<PostByIdAdminCurrency>("data");

  const form = useForm<PostAdminCurrency>({
    resolver: yupResolver(currencyFormValidationSchema),
    defaultValues: { ...currency },
  });

  const { handleSubmit, watch } = form;

  const watchSameDayDisbursement = watch("allowsSameDayDisbursement");
  const watchNonStopTrading = watch("allowsNonStopTrading");

  const onAdd = useCallback(async (values: PostAdminCurrency) => {
    try {
      setLoading(true);
      const payload = { Currencies: [values] };

      await service.hubFx.postCurrency(payload);

      OToastManager.success("Moeda adicionada com sucesso.");

      navigate(
        corporateRouter.routes.cambio.product.contingency.currencies.path,
      );
    } catch {
      OToastManager.danger("Erro ao adicionar moeda.");
    } finally {
      setLoading(false);
    }
  }, []);

  const onEdit = useCallback(
    async (values: PostAdminCurrency & { id: number }) => {
      try {
        setLoading(true);
        const payload = { Currencies: [values] };

        await service.hubFx.postByIdCurrency(payload);

        OToastManager.success("Moeda atualizada com sucesso.");

        navigate(
          corporateRouter.routes.cambio.product.contingency.currencies.path,
        );
      } catch {
        OToastManager.danger("Erro ao atualizar moeda.");
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const onSubmit = useMemo(
    () =>
      handleSubmit((formValues) => {
        formValues = {
          ...formValues,
          incomingStartTime: stringToTime(formValues.incomingStartTime),
          incomingEndTime: stringToTime(formValues.incomingEndTime),
          outgoingStartTime: stringToTime(formValues.outgoingStartTime),
          outgoingEndTime: stringToTime(formValues.outgoingEndTime),
          sameDayIncomingLimitTime: stringToTime(
            formValues.sameDayIncomingLimitTime,
          ),
          sameDayOutgoingLimitTime: stringToTime(
            formValues.sameDayOutgoingLimitTime,
          ),
        };
        if (currency?.id) {
          onEdit({ ...formValues, id: currency.id });
        } else {
          onAdd(formValues);
        }
      }),
    [currency, handleSubmit, onAdd, onEdit],
  );

  return (
    <FormTemplate
      pageTitle={
        <PageTitle title={currency ? "Editar Moeda" : "Adicionar Moeda"} />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="moedas:botao:adicionar_editar_moeda,"
          dataLabel="adicionar_editar_moeda,"
          onClick={onSubmit}
        >
          {currency ? "Editar" : "Adicionar"}
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          {ORFieldInputs.map((item) => (
            <OCol>
              <ORFieldInput tag={item.mask ? "mask" : "text"} {...item} />
            </OCol>
          ))}

          <ORFieldRadioGroup
            label="Aprovado"
            name="isApproved"
            id="IsApproved"
            dataAction="moedas:input:IsApproved"
            dataLabel="IsApproved"
            required={true}
          >
            {" "}
            <div className="d-flex align-items-center gap-2 mb-2">
              <ORadio id="sim" value="true" />
              Sim
            </div>
            <div className="d-flex align-items-center gap-2 mb-2">
              <ORadio id="nao" value="false" />
              Não
            </div>
          </ORFieldRadioGroup>

          {ORFieldRadioGroups.map((item) => (
            <ORFieldRadioGroup
              label={item.label}
              name={item.name}
              id={item.id}
              dataAction={item.dataAction}
              dataLabel={item.dataLabel}
              required={item.required}
            >
              <div className="d-flex align-items-center gap-2 mb-2">
                <ORadio id={`${item.id}_sim`} value="true" />
                Sim
              </div>
              <div className="d-flex align-items-center gap-2 mb-2">
                <ORadio id={`${item.id}_nao`} value="false" />
                Não
              </div>
            </ORFieldRadioGroup>
          ))}

          {String(watchSameDayDisbursement) === "true" && (
            <>
              {SameDayFields.map((item) => (
                <OCol key={`${item.name}`}>
                  <ORFieldInput tag="mask" {...item} />
                </OCol>
              ))}
            </>
          )}

          {String(watchNonStopTrading) === "false" && (
            <>
              {NonStopTradingFields.map((item) => (
                <OCol key={`${item.name}`}>
                  <ORFieldInput tag="mask" {...item} />
                </OCol>
              ))}
            </>
          )}
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
