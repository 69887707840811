import { yupResolver } from "@hookform/resolvers/yup";
import {
  OButton,
  OCol,
  OIcon,
  OLoader,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  OToastManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { CambioBankFormRouteQueryParam } from "../../../../../routes/cambio.route-params";
import {
  BeneficiaryBankFormValues,
  beneficiaryBankFormValidationSchema,
} from "./exchange-product-beneficiary-bank-form.schemas";

export const ExchangeProductBeneficiaryBankFormPage = () => {
  const [loading, setLoading] = useState(false);
  const { value: bank } =
    useParseFromSearchParams<CambioBankFormRouteQueryParam>("data");

  const {
    value: countries,
    callService: getCountries,
    hasError: hasErrorCountries,
    loading: loadingCountries,
  } = useServiceCall(service.hubFx.getCountries);

  const form = useForm<BeneficiaryBankFormValues>({
    resolver: yupResolver(beneficiaryBankFormValidationSchema),
  });

  const { handleSubmit, reset, watch } = form;

  const countryWatch = watch("Country");

  const onAdd = useCallback(async (formValues: BeneficiaryBankFormValues) => {
    try {
      setLoading(true);

      await service.hubFx.postBank(formValues);

      OToastManager.success("Banco adicionado com sucesso.");
    } catch {
      OToastManager.danger("Erro ao adicionar banco.");
    } finally {
      setLoading(false);
    }
  }, []);

  const onEdit = useCallback(
    async (values: BeneficiaryBankFormValues & { Id: number }) => {
      try {
        setLoading(true);
        await service.hubFx.patchBank(values);

        OToastManager.success("Banco atualizado com sucesso.");
      } catch {
        OToastManager.danger("Erro ao atualizar banco.");
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const onSubmit = useMemo(
    () =>
      handleSubmit((formValues) => {
        if (bank?.Id) {
          onEdit({ ...formValues, Id: bank.Id });
        } else {
          onAdd(formValues);
        }
      }),
    [bank, handleSubmit, onAdd, onEdit],
  );

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  useEffect(() => {
    bank && reset(bank);
  }, [bank, reset]);

  return (
    <FormTemplate
      pageTitle={
        <PageTitle title={bank ? "Editar Banco" : "Adicionar Banco"} />
      }
      actions={
        <LoadingButton
          loading={loading}
          dataAction="bancos_beneficiarios:botao:adicionar_editar_banco_beneficiario,"
          dataLabel="adicionar_editar_banco_beneficiario,"
          onClick={onSubmit}
        >
          {bank ? "Editar" : "Adicionar"}
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <OCol>
            <ORFieldInput
              tag="text"
              label="Nome"
              name="Name"
              id="name"
              dataAction="bancos_beneficiarios:input:nome"
              dataLabel="nome"
              required
            />
          </OCol>
          <OCol className="align-items-center">
            <ORFieldSelect
              label="País"
              name="Country"
              id="country"
              dataAction="bancos_beneficiarios:input:codigo_pais"
              dataLabel="codigo_pais"
              key={countries?.length}
            >
              {countries?.map((country) => (
                <OOption key={country.code} value={country.code ?? ""}>
                  {country.description}
                </OOption>
              ))}
            </ORFieldSelect>
            {loadingCountries && <OLoader />}
            {hasErrorCountries && (
              <OButton
                type="default"
                outline
                dataLabel="tentar_novamente_distribuidora_vinculada"
                dataAction="energia_formulario:botao:tentar_novamente_distribuidora_vinculada"
                onClick={() => getCountries()}
              >
                <OIcon
                  category="fa"
                  icon="fa-repeat"
                  size="sm"
                  type="default"
                />
              </OButton>
            )}
          </OCol>
          <OCol>
            <ORFieldInput
              tag="text"
              label="Swift"
              name="Swift"
              id="swift"
              dataAction="bancos_beneficiarios:input:swift"
              dataLabel="swift"
            />
          </OCol>
          <OCol>
            {countryWatch === "US" && (
              <ORFieldInput
                tag="text"
                label="FedWire"
                name="FedWire"
                id="fedWire"
                dataAction="bancos_beneficiarios:input:fedwire"
                dataLabel="fedwire"
              />
            )}
          </OCol>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
