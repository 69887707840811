import { validators } from "@maestro/utils";
import * as yup from "yup";

export interface ExchangeProductAddCounterpartyFormValues {
  counterpartyIdentification: string;
}

export const exchangeProductAddCounterpartyFormValidationFormSchema: yup.ObjectSchema<ExchangeProductAddCounterpartyFormValues> =
  yup.object({
    counterpartyIdentification: yup
      .string()
      .required("Este campo é obrigatório")
      .test("cpfCnpjValidator", "CNPJ/CPF inválido.", (value) =>
        validators.cpfCnpj(value),
      ),
  });
