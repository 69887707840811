import { Roles } from "roles/roles.types";

export const parceirosRoles = {
  customer: {
    tab: {
      role: "admin:B2BTabCustomer.View",
      description: "Visualização da tab 'B2B' na visão cliente",
    },
    partnersTab: {
      role: "admin:HubOnboardingPartnersTabCustomer.View",
      description: "Visualização da tab 'Parceiros' na visão cliente",
    },
    operatorsPage: {
      role: "admin:HubOnboardingPartnersOperatorsPageCustomer.View",
      description: "Visualização da página 'Operadores' na visão cliente",
    },
    economicGroupPage: {
      role: "admin:HubOnboardingPartnersEconomicGroupPageCustomer.View",
      description: "Visualização da página 'Grupo Econômico' na visão cliente",
    },
    operatorDetailsPage: {
      role: "admin:HubOnboardingPartnersOperatorDetailsPageCustomer.View",
      description:
        "Visualização da página 'Operadores - Detalhes' na visão cliente",
    },
    operatorViewingPermissionsPage: {
      role: "admin:HubOnboardingPartnersOperatorViewingPermissionsPageCustomer.View",
      description:
        "Visualização da página 'Operadores - Permissões de visualização' na visão cliente",
    },
    operatorUpdatePage: {
      role: "admin:HubOnboardingPartnersOperatorUpdatePageCustomer.View",
      description:
        "Visualização da página 'Operadores - Atualizar' na visão cliente",
    },
    originatorPage: {
      role: "admin:HubOnboardingPartnersOriginatorPageCustomer.View",
      description: "Visualização da página 'Originador' na visão cliente",
    },
    originatorEditInfo: {
      role: "admin:HubOnboardingPartnersOriginatorEditInfoCustomer.View",
      description:
        "Visualização da página 'Originador - Editar detalhes' na visão cliente",
    },
    originatorEditPermissions: {
      role: "admin:HubOnboardingPartnersOriginatorEditPermissionsCustomer.View",
      description:
        "Visualização da página 'Originador - Editar Permissões' na visão cliente",
    },
    originatorEditIndication: {
      role: "admin:HubOnboardingPartnersOriginatorEditIndicationCustomer.View",
      description:
        "Visualização da página 'Originador - Editar Indicação' na visão cliente",
    },
    originatorEditRemuneration: {
      role: "admin:HubOnboardingPartnersOriginatorEditRemunerationCustomer.View",
      description:
        "Visualização da página 'Originador - Editar Remuneração' na visão cliente",
    },
    parceirosCustomersPage: {
      role: "admin:B2BCustomersPageCustomer.View",
      description: "Visualização da página 'Clientes' na visão cliente",
    },
    parceirosCustomersCompanyUsersPage: {
      role: "admin:B2BCustomersCompanyUsersPageCustomer.View",
      description:
        "Visualização da página 'Clientes - Usuários da empresa' na visão cliente",
    },
    enableCurrentAccountPage: {
      role: "admin:B2BEnableCurrentAccountPageCustomer.View",
      description:
        "Visualização da página 'Abertura de Conta Corrente' na visão cliente",
    },
  },
  product: {
    tab: {
      role: "admin:B2BTabProduct.View",
      description: "Visualização da tab 'B2B' na visão produto",
    },
    partnersTab: {
      role: "admin:HubOnboardingPartnersTabProduct.View",
      description: "Visualização da tab 'Parceiros' na visão produto",
    },
    economicGroupsPage: {
      role: "admin:HubOnboardingPartnersEconomicGroups.View",
      description:
        "Visualização da página 'Grupos Econômicos' na visão produto",
    },
    economicGroupsFormPage: {
      role: "admin:HubOnboardingPartnersEconomicGroupsForm.View",
      description:
        "Visualização da página 'Grupos Econômicos' na visão produto",
    },
    monitoringPage: {
      role: "admin:B2BAgroProposals.View",
      description: "Visualização da página 'Monitoramento' na visão produto",
    },
    summaryPage: {
      role: "admin:B2BAgroProposalSummary.View",
      description:
        "Visualização da página 'Resumo da proposta' na visão produto",
    },
    documentsPage: {
      role: "admin:B2BAgroProposalDocuments.View",
      description:
        "Visualização da página 'Documentos da proposta' na visão produto",
    },
    pendenciesPage: {
      role: "admin:B2BAgroProposalPendencies.View",
      description:
        "Visualização da página 'Pendências da proposta' na visão produto",
    },
    candidatosLeadsPage: {
      role: "admin:B2BCandidatosLeadsPageProduct.View",
      description:
        "Visualização da página 'Portal B2B - Candidatos - Leads' na visão produto",
    },
    candidatosAtendentesPage: {
      role: "admin:B2BCandidatosAtendentesPageProduct.View",
      description:
        "Visualização da página 'Portal B2B - Candidatos - Atendentes' na visão produto",
    },
    candidatosOnboardingEscritoriosPage: {
      role: "admin:B2BCandidatosOnboardingEscritoriosPageProduct.View",
      description:
        "Visualização da página 'Portal B2B - Candidatos - Onboarding de Escritório' na visão produto",
    },
    originadoresPage: {
      role: "admin:B2BOriginadoresPageProduct.View",
      description:
        "Visualização da página 'Portal B2B - Originadores' na visão produto",
    },
    originadoresAdd: {
      role: "admin:B2BOriginadoresAddPageProduct.View",
      description:
        "Visualização da página 'Portal B2B - Originadores - Cadastro' na visão produto",
    },
    originadoresInfoFields: {
      role: "HubPartners:OriginatorDetailsFields.View",
      description:
        "Visualização de alguns campos de informação na página 'Portal B2B - Originadores - Cadastro' na visão produto",
    },
    originadoresPermissionFields: {
      role: "HubPartners:OriginatorPermissionsFields.View",
      description:
        "Visualização de alguns campos de permissões na página 'Portal B2B - Originadores - Cadastro' na visão produto",
    },
    operadoresPage: {
      role: "admin:B2BOperadoresPageProduct.View",
      description:
        "Visualização da página 'Portal B2B - Operadores' na visão produto",
    },
    consultoresPage: {
      role: "admin:B2BConsultoresPageProduct.View",
      description:
        "Visualização da página 'Portal B2B - Consultores' na visão produto",
    },
    catalogoPage: {
      role: "admin:B2BCreditoPageProduct.View",
      description:
        "Visualização da página 'Portal B2B - Catálogo de produtos' na visão produto",
    },
    remuneracaoPage: {
      role: "admin:B2BRemuneracaoPageProduct.View",
      description:
        "Visualização da página 'Portal B2B - Remuneração' na visão produto",
    },
    clientsPage: {
      role: "admin:HubOnboardingPartnersClientsPageProduct.View",
      description:
        "Visualização da página 'Portal B2B - Clientes' na visão produto",
    },
    operacoesPage: {
      role: "admin:B2BOperacoesPageProduct.View",
      description:
        "Visualização da página 'Portal B2B - Operações' na visão produto",
    },
    simuladorPage: {
      role: "admin:B2BSimuladoresPageProduct.View",
      description:
        "Visualização da página 'Portal B2B - Simuladores' na visão produto",
    },
    materiaisDeApoioPage: {
      role: "admin:B2BMateriaisDeApoioPageProduct.View",
      description:
        "Visualização da página 'Portal B2B - Materiais de Apoio' na visão produto",
    },
    entreEmContatoPage: {
      role: "admin:B2BAtendimentoPageProduct.View",
      description:
        "Visualização da página 'Portal B2B - Entre em contato' na visão produto",
    },
    customersRelationship: {
      role: "admin:HubOnboardingPartnersCustomersRelationshipPageProduct.View",
      description:
        "Visualização da página 'Vínculos de Clientes' na visão produto",
    },
    accountsRelationship: {
      role: "admin:HubOnboardingPartnersAccountsRelationshipPageProduct.View",
      description:
        "Visualização da página 'Vínculos de Contas' na visão produto",
    },
    originatorsPage: {
      role: "admin:HubOnboardingParnersOriginatorsPageProduct.View",
      description:
        "Visualização da página 'Parceiros - Originadores' na visão produto",
    },
    originatorsAdd: {
      role: "admin:HubOnboardingPartnersOriginatorsAddPageProduct.View",
      description:
        "Visualização da página 'Pareiros - Originadores - Cadastro' na visão produto",
    },
    addOperator: {
      role: "admin:HubOnboardingPartnersAddOperatorPageProduct.View",
      description:
        "Visualização da página 'Operadores - Novo Operador' na visão produto",
    },
    operatorDetails: {
      role: "admin:HubOnboardingPartnersOperatorDetailsPageProduct.View",
      description:
        "Visualização da página 'Operadores - Detalhes' na visão produto",
    },
    operatorRelatedEntities: {
      role: "admin:HubOnboardingPartnersOperatorRelatedEntitiesPageProduct.View",
      description:
        "Visualização da página 'Operadores - Entidades Relacionadas' na visão produto",
    },
    operatorByIdentificationViewingPermissions: {
      role: "admin:HubOnboardingPartnersOperatorByIdentificationViewingPermissionsPageProduct.View",
      description:
        "Visualização da página 'Operadores - Por Identification - Permissões de Visualização' na visão produto",
    },
    operatorByIdentificationUpdateRelationship: {
      role: "admin:HubOnboardingPartnersOperatorByIdentificationUpdateRelationshipPageProduct.View",
      description:
        "Visualização da página 'Operadores - Por Identification - Atualizar relacionamento' na visão produto",
    },
    operatorViewingPermissions: {
      role: "admin:HubOnboardingPartnersOperatorViewingPermissionsPageProduct.View",
      description:
        "Visualização da página 'Operadores - Permissões de Visualização' na visão produto",
    },
    operatorUpdateRelationship: {
      role: "admin:HubOnboardingPartnersOperatorUpdateRelationshipPageProduct.View",
      description:
        "Visualização da página 'Operadores - Atualizar relacionamento' na visão produto",
    },
    partnershipTypes: {
      role: "admin:HubOnboardingPartnersOriginatorTypesPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Tipos de Originador' na visão produto",
    },
    addPartnershipType: {
      role: "admin:HubOnboardingPartnersAddOriginatorTypePageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Adicionar Tipo de Originador' na visão produto",
    },
    editPartnershipType: {
      role: "admin:HubOnboardingPartnersEditOriginatorTypePageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Editar Tipo de Originador' na visão produto",
    },
    originatorSubtypes: {
      role: "admin:HubOnboardingPartnersOriginatorSubtypesPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Subtipos de Originador' na visão produto",
    },
    addOriginatorSubtype: {
      role: "admin:HubOnboardingPartnersAddOriginatorSubtypePageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Adicionar Subtipo de Originador' na visão produto",
    },
    editOriginatorSubtype: {
      role: "admin:HubOnboardingPartnersEditProductsPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Editar Subtipo de Originador' na visão produto",
    },
    products: {
      role: "admin:HubOnboardingPartnersProductsPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Produtos' na visão produto",
    },
    addProduct: {
      role: "admin:HubOnboardingPartnersAddProductPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Adicionar Produto' na visão produto",
    },
    editProduct: {
      role: "admin:HubOnboardingPartnersEditProductPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Editar Produto' na visão produto",
    },
    books: {
      role: "admin:HubOnboardingPartnersBooksPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Books' na visão produto",
    },
    addBook: {
      role: "admin:HubOnboardingPartnersAddBookPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Adicionar Book' na visão produto",
    },
    editBook: {
      role: "admin:HubOnboardingPartnersEditBookPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Editar Book' na visão produto",
    },
    catalogs: {
      role: "admin:HubOnboardingPartnersCatalogsPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Catálogos' na visão produto",
    },
    addCatalog: {
      role: "admin:HubOnboardingPartnersAddCatalogPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Adicionar Catálogo' na visão produto",
    },
    editCatalog: {
      role: "admin:HubOnboardingPartnersEditCatalogPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Editar Catálogo' na visão produto",
    },
    links: {
      role: "admin:HubOnboardingPartnersLinkssPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Vínculos' na visão produto",
    },
    addLink: {
      role: "admin:HubOnboardingPartnersAddLinkPageProduct.View",
      description:
        "Visualização da página 'Configuracoes - Adicionar Vínculo' na visão produto",
    },
  },
} satisfies Roles;
