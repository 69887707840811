import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { useCurrencies } from "hooks/currency/currency.hook";
import { ReactNode } from "react";
import { ExchangeOrderResponse } from "services/hubfx/models/types/order/order.model";
import { format } from "utils/date";

export const exchangeOrderDetailsGenerator = (
  order: Omit<ExchangeOrderResponse, "conciliationId" | "lastUpdate"> | null,
  title: ReactNode = "Ordem",
) => {
  if (!order) return {} as DetailsFields;

  const { mapSymbol } = useCurrencies();

  const symbol = mapSymbol(order.currency) ?? "";

  return {
    order: {
      title,
      items: [
        {
          label: "Id",
          value: order.id,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Dt. Criação",
          value: format.dateTime(order.createdDate),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Moeda",
          value: order.currency,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    payer: {
      title: "Pagador",
      items: [
        {
          label: "Pagador",
          value: order.payer,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    value: {
      title: "Valor",
      items: [
        {
          label: "Vr. Total",
          value: masks.currency(order.amount, symbol, "."),

          grid: { md: 4, xl: 3 },
        },
        {
          label: "Vr. Disponivel",
          value: masks.currency(order.balanceAmount, symbol, "."),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Vr. Trânsito",
          value: masks.currency(order.inTransitAmount, symbol, "."),
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  } satisfies DetailsFields;
};
