import { OButton, OCard, OCardBody, OCardFooter } from "@maestro/react";
import {
  EmptyState,
  ErrorComponent,
  TryAgainButton,
} from "components/empty-state";
import { PageTitle } from "components/page-title";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { EditEligibilityGroupModal, VariantSelector } from "./_compose";
import {
  EligibilityTreeProvider,
  useEligibilityTree,
} from "./eligibility-tree.context";
import { FlowContainer } from "../../../../components/flow-container";

const EligibilityTreeComponent = () => {
  const {
    chart,
    criteriaHasError,
    criteriaLoading,
    discardChanges,
    getCriteria,
    getEligibilityGroups,
    groupsHasError,
    groupsLoading,
    hasUnsavedChanges,
    saveChanges,
  } = useEligibilityTree();

  return (
    <>
      <DetailsTemplate
        pageTitle={<PageTitle title="Árvores de Elegibilidade" />}
      >
        <div className="d-flex flex-column gap-4">
          <OCard>
            <OCardBody>
              <VariantSelector />
            </OCardBody>
          </OCard>
          <OCard>
            <OCardBody className="position-relative">
              <ContentTemplate
                loading={groupsLoading || criteriaLoading}
                hasError={groupsHasError || criteriaHasError}
                value={chart}
                errorComponent={
                  <ErrorComponent
                    messageTitle="Não foi possível buscar os grupos de elegibilidade"
                    messageParagraph="Clique no botão para tentar novamente"
                  >
                    <TryAgainButton
                      onClick={() => {
                        getEligibilityGroups();
                        getCriteria();
                      }}
                    />
                  </ErrorComponent>
                }
                emptyStateComponent={
                  <EmptyState messageTitle="Nenhuma árvore selecionada" />
                }
                render={(_charts) => <FlowContainer chart={chart} />}
              />
            </OCardBody>
            <OCardFooter className="d-flex justify-content-end gap-3">
              <OButton
                dataAction="acoes:botao:desfazer_alteracoes"
                dataLabel="desfazer_alteracoes"
                type="dark"
                outline
                disabled={!hasUnsavedChanges || groupsLoading}
                onClick={() => discardChanges()}
              >
                Desfazer alterações
              </OButton>
              <OButton
                dataAction="acoes:botao:salvar_alteracoes"
                dataLabel="salvar_alteracoes"
                disabled={!hasUnsavedChanges || groupsLoading}
                onClick={() => saveChanges()}
              >
                Salvar alterações
              </OButton>
            </OCardFooter>
          </OCard>
        </div>
      </DetailsTemplate>
      <EditEligibilityGroupModal />
    </>
  );
};

interface EligibilityTreeProps {
  productKind: "CREDIT" | "DEBIT";
}

export const EligibilityTree = ({ productKind }: EligibilityTreeProps) => (
  <EligibilityTreeProvider productKind={productKind}>
    <EligibilityTreeComponent />
  </EligibilityTreeProvider>
);
