import { env } from "utils/environments";

export const eligibilityengineEndpoints = {
  contingency: {
    eligibility: `${env.API_ELIGIBILITYENGINE}/contingency/eligibility`,
  },
  criteria: {
    types: `${env.API_ELIGIBILITYENGINE}/criteria/types`,
  },
  eligibility: {
    group: {
      endpoint: `${env.API_ELIGIBILITYENGINE}/eligibility/group`,
      byGroupId: (groupId: string | number) =>
        `${env.API_ELIGIBILITYENGINE}/eligibility/group/${groupId}`,
      product: {
        byProductKind: (productKind: "CREDIT" | "DEBIT") =>
          `${env.API_ELIGIBILITYENGINE}/eligibility/group/product/${productKind}`,
      },
    },
    product: {
      debit: `${env.API_ELIGIBILITYENGINE}/eligibility/product/debit`,
    },
  },
  offer: {
    endpoint: `${env.API_ELIGIBILITYENGINE}/offer`,
    byId: (id: string | number) => `${env.API_ELIGIBILITYENGINE}/offer/${id}`,
    snapshot: {
      byId: (id: string | number) =>
        `${env.API_ELIGIBILITYENGINE}/offer/snapshot/${id}`,
    },
  },
  product: {
    byProductKind: {
      variant: (productKind: "CREDIT" | "DEBIT") =>
        `${env.API_ELIGIBILITYENGINE}/product/${productKind}/variant`,
    },
  },
};
