import { corporateRouter } from "routes/corporate-router.context";

export const liquidationSearchParam = "credit-conciliation-data";

export const buildDetailsLink = (
  creditConciliationOrderId: string,
  fundName: string,
) => {
  const baseUrl =
    corporateRouter.routes.fidc.product.CreditConciliation.management.details
      .path;
  const searchParams = new URLSearchParams();
  searchParams.set("orderId", creditConciliationOrderId);
  searchParams.set("fundName", fundName);

  return baseUrl + "?" + searchParams.toString();
};

export const creditConciliationValidationStatusMap = {
  INVALID: {
    label: "Inválido",
    type: "danger",
  },
  CANCELED: {
    label: "Cancelado",
    type: "danger",
  },
  EXPIRED: {
    label: "Expirado",
    type: "danger",
  },
  WAITING_CUSTODIAN_VALIDATION: {
    label: "Pendente de Envio",
    type: "warning",
  },
  WAITING_CUSTODIAN_RELEASE: {
    label: "Pendente de Aprovação",
    type: "warning",
  },
  FINISHED: {
    label: "Finalizado",
    type: "success",
  },
  WAITING_PARTNER_FILE_READING: {
    label: "Lendo o Arquivo do Parceiro",
    type: "info",
  },
  WAITING_CROS_CONTRACTS_DOWNLOADING: {
    label: "Buscando contratos no CROS",
    type: "info",
  },
  WAITING_CHANNEL_VALIDATION: {
    label: "Validando arquivo do Parceiro",
    type: "info",
  },
  WAITING_CHANNEL_FILE_WRITING: {
    label: "Gerando Arquivo do Canal",
    type: "info",
  },
  WAITING_CUSTODIAN_FILE_READING: {
    label: "Lendo o Arquivo de Custódia",
    type: "info",
  },
  WAITING_SETTLEMENT_GROUP_OPENING: {
    label: "Solicitando Abertura do Grupo de Liquidação",
    type: "info",
  },
  WAITING_CROS_VALIDATION: {
    label: "Aguardando Validação do CROS",
    type: "info",
  },
  WAITING_CROS_FILE_WRITING: {
    label: "Gerando Arquivo do CROS",
    type: "info",
  },
  WAITING_CROS_COMPLETION: {
    label: "Aguardando Finalização do CROS",
    type: "info",
  },
  WAITING_PARTNER_FILE_WRITING: {
    label: "Gerando Arquivo do Parceiro",
    type: "info",
  },
  WAITING_PARTNER_FILE_SENDING: {
    label: "Enviando Arquivo ao Parceiro",
    type: "info",
  },
  WAITING_CHANNEL_VALIDATIONS_CREATION: {
    label: "Gerando Validações",
    type: "info",
  },
  WAITING_SETTLEMENT_GROUP_CONTRACTS_DISPATCH: {
    label: "Enviando Contratos para o Grupo de Liquidação",
    type: "info",
  },
  QUEUED: {
    label: "Na Fila",
    type: "info",
  },
} as const;
