import { ODataGridGenerator } from "components/data-grid";
import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { DetailsTemplate } from "templates/details-template";
import { GridTemplate } from "templates/grid-template";
import { buildOperationDetails } from "./operation-details.details";
import { tradesGrid } from "./operation-details.grid";
import { useOperationDetails } from "./operation-details.hook";

export const OperationDetails = () => {
  const { getOperation, hasError, loading, operationDetails } =
    useOperationDetails();
  const gridRef = useGridRef();

  const gridSummary = useMemo(
    () => (
      <Summary texts={{ sum: "{0}" }}>
        <TotalItem
          column="price"
          summaryType="sum"
          valueFormat={{
            style: "currency",
            currency:
              operationDetails?.trades?.[0].receivable.currency || "BRL",
            precision: 3,
          }}
        />
        <TotalItem
          column="maturityAmount"
          summaryType="sum"
          valueFormat={{
            style: "currency",
            currency:
              operationDetails?.trades?.[0].receivable.currency || "BRL",
            precision: 3,
          }}
        />
      </Summary>
    ),
    [operationDetails?.trades],
  );

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Detalhes da operação" />}>
      <div className="d-flex flex-column gap-4">
        <DetailsCard
          loading={loading}
          hasError={hasError}
          errorComponent={
            <ErrorComponent
              messageTitle="Erro ao buscar os detalhes da operação"
              messageParagraph="Clique no botão para tentar novamente."
            >
              <TryAgainButton onClick={() => getOperation()} />
            </ErrorComponent>
          }
          value={operationDetails}
          fields={buildOperationDetails}
        />
        <GridTemplate
          actions={<RefreshGridButton onClick={() => getOperation()} />}
          gridRef={gridRef}
          showClearFiltersButton
          showExportButton
        >
          <ODataGridGenerator
            gridRef={gridRef}
            grid={tradesGrid}
            dataSource={operationDetails?.trades}
            loading={loading}
          >
            {gridSummary}
          </ODataGridGenerator>
        </GridTemplate>
      </div>
    </DetailsTemplate>
  );
};
