import { yupResolver } from "@hookform/resolvers/yup";
import { OCol, ORFieldInput, OToastManager } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import {
  ExchangeProductAddCounterpartyFormValues,
  exchangeProductAddCounterpartyFormValidationFormSchema,
} from "./exchange-product-add-counterparty-form.schemas";

export const ExchangeProductAddCounterpartyPage = () => {
  const [loading, setLoading] = useState(false);
  const form = useForm<ExchangeProductAddCounterpartyFormValues>({
    resolver: yupResolver(
      exchangeProductAddCounterpartyFormValidationFormSchema,
    ),
  });
  const { handleSubmit } = form;

  const onAddCounterparty = useMemo(
    () =>
      handleSubmit(async ({ counterpartyIdentification }) => {
        try {
          setLoading(true);

          const { data } = await service.acc.postCounterparty({
            counterpartyIdentification,
          });

          if (data.success) {
            OToastManager.success("Contraparte adicionada com sucesso!");
          } else {
            OToastManager.warning(
              data.errorMessage || "Não foi possível adicionar contraparte.",
            );
          }
        } catch {
          OToastManager.danger("Erro ao adicionar contraparte.");
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit],
  );

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar contraparte" />}
      actions={
        <LoadingButton
          loading={loading}
          dataAction="cambio_adicionar_contraparte:botao:adicionar_contraparte"
          dataLabel="adicionar_contraparte"
          onClick={onAddCounterparty}
        >
          Adicionar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <OCol md={6}>
            <ORFieldInput
              dataAction="cambio_adicionar_contraparte:input:cpf_cnpj_contraparte"
              dataLabel="cpf_cnpj_contraparte"
              tag="cpfcnpj"
              label="CNPJ/CPF da Contraparte"
              name="counterpartyIdentification"
              id="counterpartyIdentification"
            />
          </OCol>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
