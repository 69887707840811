import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { getValidationMessages } from "../../../../../../utils";

export const useGridActions = (
  propertyType: HubEnergy.DiligenceStepPropertyTypeResponse,
  reload: () => void,
) => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.deleteDiligenceStepPropertyTypeById,
  );

  const deletePropertyType = useCallback(async () => {
    const { success, error } = await callService(propertyType.id);

    if (success) {
      OToastManager.success("Tipo de propriedade excluído com sucesso");
      reload();
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0]?.ErrorMessage ??
          "Erro ao excluir o tipo de propriedade",
      );
    }
  }, [callService, propertyType, reload]);

  return { deletePropertyType, loading };
};
