import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { exchangeOdataQuotesGrid } from "../../../components/odata/exchange-odata-quotes.grid";
import { useCurrencies } from "hooks/currency/currency.hook";

export const ExchangeProductQuotesPage = () => {
  const gridRef = useGridRef();
  const { mapSymbol } = useCurrencies();

  const quotesGrid = useMemo(() => exchangeOdataQuotesGrid(mapSymbol), []);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Operações" />}
      actions={
        <RefreshGridButton
          onClick={() => {
            !quotesGrid.datagrid.dataSource.isLoading() &&
              quotesGrid.datagrid.dataSource.reload();
          }}
        />
      }
      gridRef={gridRef}
      showClearFiltersButton
      showExportButton
    >
      <ODataGridGenerator gridRef={gridRef} grid={quotesGrid} />
    </GridTemplate>
  );
};
