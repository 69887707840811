import { OCard, OCardBody, ORadio, OTypography } from "@maestro/react";

type BankOptionProps = {
  uuid: string;
  title?: string;
  description?: string;
  active: boolean;
  onClick: () => void;
  bank?: string;
  agency?: string;
  account?: string;
  disabled?: boolean;
};

export const BankOption = ({
  uuid,
  title,
  active,
  onClick,
  bank,
  agency,
  account,
  description,
  disabled,
}: BankOptionProps) => {
  return (
    <OCard
      style={{
        width: 220,
        height: 154,
        maxWidth: 220,
        maxHeight: 154,
        border: active ? "1px solid var(--theme-tertiary)" : "",
        cursor: disabled ? "default" : "pointer",
      }}
      onClick={() => {
        !disabled && onClick();
      }}
    >
      <div
        className="d-flex p-3 pb-0"
        style={{
          justifyContent: title ? "space-between" : "end",
        }}
      >
        {title && <OTypography>{title}</OTypography>}
        <ORadio
          id={uuid}
          name={uuid}
          value={uuid}
          checked={active}
          disabled={disabled}
          onClick={onClick}
        />
      </div>
      <OCardBody className="d-flex flex-column pt-0">
        {description ? (
          <OTypography className="pt-4" size="sm">
            {description}
          </OTypography>
        ) : (
          <>
            <div className="d-flex justify-content-between w-100 pt-2">
              <OTypography weight="bold">Banco:</OTypography>
              <OTypography>{bank}</OTypography>
            </div>
            <div className="d-flex justify-content-between w-100">
              <OTypography weight="bold">Agência:</OTypography>
              <OTypography>{agency}</OTypography>
            </div>
            <div className="d-flex justify-content-between w-100">
              <OTypography weight="bold">Conta:</OTypography>
              <OTypography>{account}</OTypography>
            </div>
          </>
        )}
      </OCardBody>
    </OCard>
  );
};
