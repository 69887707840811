import { OFilterItem, OInputDate, OTypography } from "@maestro/react";
import { useMemo } from "react";
import { MapperDateFilterTitle } from "./date-filter-item.utils";

interface DateFilterItemProps {
  dateType: "CreatedAt" | "DueDate" | "SettledAt";
}

export const DateFilterItem = ({ dateType }: DateFilterItemProps) => {
  const title = useMemo(() => MapperDateFilterTitle[dateType], [dateType]);

  return (
    <OFilterItem>
      <OTypography slot="title" key={title}>
        {title}
      </OTypography>

      <div className="d-flex gap-4 py-2 px-3">
        <OInputDate
          allowClear
          aspect="flushed"
          picker="date"
          placeholder="Data Inicial"
          position="bottomLeft"
          size="md"
          data-filterparam={`start${dateType}`}
          key={`start${dateType}`}
        />

        <OInputDate
          allowClear
          aspect="flushed"
          picker="date"
          placeholder="Data Final"
          position="bottomLeft"
          size="md"
          data-filterparam={`end${dateType}`}
          key={`end${dateType}`}
        />
      </div>
    </OFilterItem>
  );
};
