import { yupResolver } from "@hookform/resolvers/yup";
import { PageTitle } from "components/page-title";
import { FormProvider, useForm } from "react-hook-form";
import { FormTemplate } from "templates/form-template";
import { bigNumberFormDefaultValues, bigNumberFormValidationSchema } from "../big-number-form.schema";
import { BigNumbersForm } from "../big-number-form.component";


export const AddBigNumbersPage = () => {
  const form = useForm({
    resolver: yupResolver(bigNumberFormValidationSchema),
    defaultValues: bigNumberFormDefaultValues,
  });

  return (
    <FormTemplate pageTitle={<PageTitle title="Adicionar Big Number" />}>
      <FormProvider {...form}>
        <BigNumbersForm mode="add" />
      </FormProvider>
    </FormTemplate>
  );
};