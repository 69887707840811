import { OToastManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { IconButton } from "components/icon-button";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { GetApiFranchiseResponseItem } from "services/clerk";
import { copyToClipboard } from "utils/copy";
import { buildTaggedLink } from "../../../utils/build-link";
import { FranchiseMasterDetail } from "./franquias.master-detail";

export const dataSource =
  dataSourceCustomStoreGenerator<GetApiFranchiseResponseItem>(() =>
    service.clerk
      .getFranchises()
      .then(({ data }) => data ?? [])
      .catch(() => {
        const validator = "Erro ao buscar franquias.";
        OToastManager.danger(validator);
        throw new Error(validator);
      }),
  );

export const franquiasGrid: ODataGridGeneratorConfig<
  Awaited<ReturnType<typeof service.clerk.getFranchises>>["data"][number]
> = {
  datagrid: {
    noDataText: "Nenhuma franquia",
    filterRow: { visible: true },
    headerFilter: { visible: true },
  },
  columns: [
    {
      dataField: "name",
      caption: "Nome",
    },
    {
      dataField: "createdBy",
      caption: "Criado por",
    },
    {
      dataField: "tagCode",
      caption: "Código",
      cellRender: ({ data }) => (
        <div className="d-flex align-items-center gap-4">
          <span>{data.tagCode}</span>

          <IconButton
            icon={{ category: "orq", icon: "orq-link" }}
            type="dark"
            outline
            onClick={() => {
              copyToClipboard(buildTaggedLink(data.tagCode));
              OToastManager.success("Link tagueado copiado com sucesso");
            }}
          >
            Copiar link
          </IconButton>
        </div>
      ),
    },
    {
      caption: "Ações",
      alignment: "center",
      cellRender: ({ data, component }) => (
        <DataGridAction
          actions={[
            {
              icon: { category: "orq", icon: "orq-edit-pencil" },
              label: "Detalhes",
              role: roles.franquias.product.updateFranchise.role,
              route: `${corporateRouter.routes.franquias.product.franquias.details.path(
                {
                  id: data.id,
                },
              )}?data=${encodeURIComponent(
                JSON.stringify({ name: data.name }),
              )}`,
            },
            {
              icon: { category: "orq", icon: "orq-edit-trash" },
              label: "Remover",
              role: roles.franquias.product.deleteFranchise.role,
              onClick: async () => {
                try {
                  await service.clerk.deleteFranchise(data.id);
                  component.getDataSource().reload();
                  OToastManager.success("Franquia removida com sucesso!");
                } catch {
                  OToastManager.danger("Erro ao remover franquia.");
                }
              },
            },
          ]}
        />
      ),
    },
  ],
  masterDetail: {
    enabled: true,
    component: FranchiseMasterDetail,
  },
};
