import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { getValidationMessages } from "../../../../../../utils";
import {
  DocumentTypeForm,
  checkboxValue,
  documentTypeFormDefaultValues,
  documentTypeFormValidationSchema,
} from "./document-type-form.form";

export const useDocumentTypeForm = (documentTypeId: number | null) => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.postDocumentTypes,
  );

  const navigate = useNavigate();

  const form = useForm<DocumentTypeForm>({
    defaultValues: documentTypeFormDefaultValues,
    resolver: yupResolver(documentTypeFormValidationSchema),
  });

  const { handleSubmit } = form;

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await callService({
          documentTypeId,
          description: values.description,
          isMigrationDocument:
            !!values.isMigrationDocument?.includes(checkboxValue),
          label: values.label,
          name: values.name,
        });

        if (success) {
          OToastManager.success("Tipo de documento salvo");
          navigate(
            corporateRouter.routes.energia.product.diligenceConfig.documentTypes
              .path,
          );
        } else {
          OToastManager.danger(
            getValidationMessages(error)?.[0].ErrorMessage ??
              "Erro ao salvar tipo de documento",
          );
        }
      }),
    [callService, documentTypeId, handleSubmit, navigate],
  );

  return {
    form,
    loading,
    submit,
  };
};
