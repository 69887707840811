import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { service } from "services";
import { getValidationMessages } from "../../../../../../../../../../utils";

export const useGridActions = (pendencyId: number, reload: () => void) => {
  const {
    callService: _executeAutomation,
    loading: updatePendencyStatusLoading,
  } = useServiceCall(service.hubEnergy.executeAutomation);

  const { callService: _pressForResponse, loading: pressForResponseLoading } =
    useServiceCall(service.hubEnergy.pressForResponseLetterOfComplaint);

  const {
    callService: _restartPendencyAnalysis,
    loading: restartPendencyAnalysisLoading,
  } = useServiceCall(service.hubEnergy.restartPendencyAnalysis);

  const startAutomation = useCallback(async () => {
    const { success, error } = await _executeAutomation({
      diligenceStepPendencyId: pendencyId,
    });
    if (success) {
      OToastManager.success("Automação iniciada com sucesso");
      reload();
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0].ErrorMessage ??
          "Erro ao iniciar a automação",
      );
    }
  }, [_executeAutomation, pendencyId, reload]);

  const pressForResponse = useCallback(async () => {
    const { success, error } = await _pressForResponse({
      diligenceStepPendencyId: pendencyId,
    });
    if (success) {
      OToastManager.success("Cobrança de resposta enviada com sucesso");
      reload();
    } else {
      let errorMessage =
        getValidationMessages(error)?.[0].ErrorMessage ??
        "Erro ao enviar a cobrança de resposta";
      if (isAxiosError<HubEnergy.ApiBaseResponse>(error))
        errorMessage = error.response?.data.errorMessage ?? errorMessage;
      OToastManager.danger(errorMessage);
    }
  }, [_pressForResponse, pendencyId, reload]);

  const restartPendencyAnalysis = useCallback(async () => {
    const { success, error } = await _restartPendencyAnalysis({
      diligenceStepPendencyId: pendencyId,
    });
    if (success) {
      OToastManager.success("Pendência reenviada para análise");
      reload();
    } else {
      OToastManager.danger(
        getValidationMessages(error)?.[0].ErrorMessage ??
          "Erro ao reenviar para análise",
      );
    }
  }, [_restartPendencyAnalysis, pendencyId, reload]);

  return {
    startAutomation,
    pressForResponse,
    restartPendencyAnalysis,
    loading:
      updatePendencyStatusLoading ||
      pressForResponseLoading ||
      restartPendencyAnalysisLoading,
  };
};
