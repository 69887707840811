import { OCheckbox, ODivider, OInputSearch } from "@maestro/react";
import { FinancialInstitution } from "hooks/financial-institution/financial-institution.hook";
import { useCallback, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FinancialInstitutionUtils } from "utils/financial-institution";
import { OMultLabel } from "../../../../../o-mult-label";
import {
  SelectionContainer,
  SelectionOptionContainer,
  SelectionOptionInfoContainer,
} from "../../side-form-modal.styles";
import { FinancialInstitutionOption } from "./_compose";
import { MultFinancialInstitutionSelectionSectionFilter } from "../../../../side-filter-bar.types";
import { isAMultSelectionFieldValue } from "../../side-form-modal.utils";

const DEFAULT_BANK_LIST_SIZE = 16;

type MultFinancialInstitutionSelectionProps =
  MultFinancialInstitutionSelectionSectionFilter;

export const MultFinancialInstitutionSelection = ({
  data,
  formId,
}: MultFinancialInstitutionSelectionProps) => {
  const [searchString, setSearchString] = useState("");

  const { watch, setValue } = useFormContext();

  const fieldValueWatched: unknown[] = watch(formId) || [];

  const resumeOption = useCallback(
    (financialInstitution: FinancialInstitution) =>
      FinancialInstitutionUtils.assembleInstitutionResume(financialInstitution),
    [],
  );

  const filteredData = useMemo((): FinancialInstitution[] => {
    if (!searchString) return data.slice(0, DEFAULT_BANK_LIST_SIZE);

    return data?.filter((financialInstitution) =>
      resumeOption(financialInstitution)
        .toLowerCase()
        .includes(searchString.toLowerCase()),
    );
  }, [searchString, data, resumeOption]);

  const handleCheckboxOnClick = (value: unknown) => {
    if (!isAMultSelectionFieldValue(fieldValueWatched)) return;

    const checked = fieldValueWatched.includes(value);

    const newValue = !checked
      ? fieldValueWatched.concat(value)
      : fieldValueWatched.filter((item) => item !== value);

    setValue(formId, newValue);
  };

  return (
    <div className="d-flex flex-column gap-3">
      <div className="bg-light">
        <OInputSearch
          id="search"
          name={`side-filter-bar-input-search-${formId}`}
          placeholder="Digite aqui para buscar"
          aspect="outline"
          handleClear={() => setSearchString("")}
          onInput={({ currentTarget: { value = "" } }) => {
            setSearchString(value);
          }}
        />
      </div>
      <SelectionContainer>
        {filteredData.map(({ bankCode }, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>
              <OMultLabel
                htmlFor={`checkbox-${bankCode}`}
                className="d-grid w-100"
                size="lg"
              >
                <SelectionOptionContainer>
                  <SelectionOptionInfoContainer>
                    <FinancialInstitutionOption
                      title={resumeOption(filteredData[index])}
                      bankCode={filteredData[index].bankCode}
                      isHeader={false}
                    />
                  </SelectionOptionInfoContainer>
                  <OCheckbox
                    id={`checkbox-${bankCode}`}
                    name={`checkbox-${bankCode}`}
                    value={bankCode ?? ""}
                    checked={fieldValueWatched?.includes(bankCode)}
                    size="xs"
                    onClick={() => {
                      handleCheckboxOnClick(bankCode);
                    }}
                  />
                </SelectionOptionContainer>
              </OMultLabel>
              {index < filteredData.length - 1 && (
                <ODivider size="xxs" className="my-2" />
              )}
            </div>
          );
        })}
      </SelectionContainer>
    </div>
  );
};
