import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { CreditLineConfigurationForm } from "../_compose/credit-line-configuration-form.component";
import { useUpdateCreditLineConfiguration } from "./update-credit-line-configuration.hook";

export const UpdateCreditLineConfigurationPage = () => {
  const {
    bookStrategies,
    creditLines,
    form,
    getAllData,
    hasErrorBookStrategies,
    hasErrorCreditLineConfiguration,
    hasErrorCreditLines,
    loadingBookStrategies,
    loadingCreditLineConfiguration,
    loadingCreditLines,
    loadingUpdateCreditLineConfiguration,
    updateCreditLineConfiguration,
  } = useUpdateCreditLineConfiguration();

  const { handleSubmit } = form;

  return (
    <FormTemplate
      pageTitle={
        <PageTitle title="Atualizar configuração de linha de crédito" />
      }
      actions={
        <LoadingButton
          loading={loadingUpdateCreditLineConfiguration}
          onClick={handleSubmit(updateCreditLineConfiguration)}
          dataAction="atualizar-configuracao-de-linha-de-credito:botao:salvar"
          dataLabel="salvar"
          disabled={
            loadingBookStrategies ||
            loadingCreditLines ||
            loadingCreditLineConfiguration
          }
        >
          Salvar
        </LoadingButton>
      }
    >
      <ContentTemplate
        hasError={
          hasErrorBookStrategies ||
          hasErrorCreditLines ||
          hasErrorCreditLineConfiguration
        }
        loading={
          loadingBookStrategies ||
          loadingCreditLines ||
          loadingCreditLineConfiguration
        }
        noValue
        errorComponent={
          <ErrorComponent
            messageTitle="Não foi possível carregar os dados referentes às configurações das linhas de crédito."
            messageParagraph="Clique no botão para tentar novamente."
          >
            <TryAgainButton onClick={() => getAllData()} />
          </ErrorComponent>
        }
        render={() => (
          <CardTemplate>
            <FormProvider {...form}>
              <CreditLineConfigurationForm
                bookStrategies={bookStrategies}
                creditLines={creditLines}
                hasErrorBookStrategies={hasErrorBookStrategies}
                hasErrorCreditLines={hasErrorCreditLines}
                loadingBookStrategies={loadingBookStrategies}
                loadingCreditLines={loadingCreditLines}
              />
            </FormProvider>
          </CardTemplate>
        )}
      />
    </FormTemplate>
  );
};
