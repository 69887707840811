import { masks } from "@maestro/utils";
import { CopyButton } from "components/copy-button";
import { DetailsFields } from "components/details-card";
import { StandardLink } from "components/standard-link";
import dayjs from "dayjs";
import { corporateRouter } from "routes/corporate-router.context";
import { buildCustomerLink } from "utils/build-customer-link";

interface ConsumerUnitHeadeFieldsArgument {
  consumerUnit: HubEnergy.ConsumerUnitWithCustomerRelatedEntity;
  distributor: HubEnergy.RelatedEntityResponse;
}

export const consumerUnitHeadeFields = ({
  consumerUnit,
  distributor,
}: ConsumerUnitHeadeFieldsArgument): DetailsFields => ({
  consumerUnit: {
    items: [
      {
        label: "Razão social",
        value: (
          <StandardLink
            href={buildCustomerLink(
              consumerUnit.customerTaxId,
              corporateRouter.routes.energia.customer.consumerUnit.details.path(
                {
                  consumerUnitId: consumerUnit.relatedEntityId,
                },
              ),
            )}
          >
            {consumerUnit.officialName}
          </StandardLink>
        ),
        grid: { md: 6, lg: 4 },
      },
      {
        label: "CNPJ",
        value: (
          <div className="d-flex align-items-center gap-2">
            {masks.cpfCnpj(consumerUnit.taxId)}{" "}
            <CopyButton
              action="detalhes_do_cliente:botao:copiar-identificacao"
              label="copiar-identificacao"
              target={consumerUnit.taxId}
              toasterMessage="CPNJ copiado"
            />
          </div>
        ),
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Data de migração",
        grid: { md: 6, lg: 4 },
        value: dayjs(consumerUnit.migrationDate).format("DD/MM/YYYY"),
      },
      {
        label: "Distribuidora Vinculada",
        value: `${
          distributor?.name ?? distributor?.officialName
        } - ${masks.cpfCnpj(distributor?.taxId)}`,
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Número de instalação",
        value: consumerUnit.installationNumber,
        grid: { md: 6, lg: 4 },
      },
    ],
  },
});
