import { client } from "../../client";
import { adminBankinghubEndpoints } from "../admin-bankinghub.endpoints";
import { GetAgreementsResponse } from "./models";

export const adminBankinghubConfigHubService = {
  getAgreements() {
    return client.get<GetAgreementsResponse>(
      adminBankinghubEndpoints.configHub.agreements,
    );
  },
};
