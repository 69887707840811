import dayjs from "dayjs";
import { Line } from "react-chartjs-2";
import { Quote } from "services/hubfx/models/responses";
import { generateDecreasingHex } from "../../../../exchange-product-dashboard.utils";
import { InfoCardContent } from "../../../info-card";
import { InfoCard } from "../../../info-card/info-card.component";
import { useDashboardFluctuations } from "../../dashboard-fluctuations.hook";
import { options, periodTabMap } from "../../dashboard-fluctuations.utils";

interface FluctuationsChartProps {
  activeTab: number;
  flowType: "INTERNACAO" | "REMESSA";
}

export const FluctuationsChart = ({
  activeTab,
  flowType,
}: FluctuationsChartProps) => {
  const { dashboard, getDashboard } = useDashboardFluctuations({
    activeTab,
    flowType,
  });

  const currencies = dashboard.value?.flowType.currencies;

  const labels: string[] = [];
  const allLabels = currencies?.flatMap((item) =>
    item.quotes.map((q) => q.date),
  );

  allLabels &&
    allLabels
      .sort()
      .map((item) => dayjs(item).format(periodTabMap[activeTab].format))
      .forEach((value) => {
        if (!labels.includes(value)) labels.push(value);
      });

  const getListValues = (
    quotes: Quote[],
    dashboardLabels: string[],
    activeTab: number,
  ) => {
    const list = dashboardLabels.map(
      (item) =>
        quotes.find((q) => {
          const date = dayjs(q.date).format(periodTabMap[activeTab].format);

          return item === date;
        })?.price,
    );

    return list;
  };

  return (
    <InfoCard
      {...dashboard}
      callService={getDashboard}
      render={(value) => {
        const currencies = value.flowType.currencies;

        const colours = generateDecreasingHex(currencies?.length ?? 1);

        const datasets =
          currencies?.map((currency, index) => ({
            label: `${currency.quoteCurrency}`,
            borderColor: colours[index],
            backgroundColor: colours[index],
            data: getListValues(currency.quotes, labels, activeTab),
          })) || [];

        return (
          <InfoCardContent
            header={
              flowType === "INTERNACAO"
                ? "Cotações de recebimento"
                : "Cotações de envio"
            }
            body={
              <Line
                options={options}
                data={{
                  labels: labels,
                  datasets: datasets,
                }}
              />
            }
          />
        );
      }}
    />
  );
};
