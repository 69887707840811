import { env } from "utils/environments";

export const recebiveisCartaoEndpoints = {
  agendasCip: `${env.API_RECEBIVEIS_CARTAO}api/agendas-cip`,
  agendas: {
    import: `${env.API_RECEBIVEIS_CARTAO}api/agendas/import`,
    redisponibilizar: `${env.API_RECEBIVEIS_CARTAO}api/agendas/redisponibilizar`,
  },
  bandeiras: {
    all: `${env.API_RECEBIVEIS_CARTAO}api/bandeiras/all`,
  },
  cip: {
    requestAgendas: `${env.API_RECEBIVEIS_CARTAO}api/cip/request-agendas`,
  },
  companies: {
    endpoint: `${env.API_RECEBIVEIS_CARTAO}api/companies`,
    credenciadoras: `${env.API_RECEBIVEIS_CARTAO}api/companies/credenciadoras`,
    notCredenciadoras: `${env.API_RECEBIVEIS_CARTAO}api/companies/not-credenciadoras`,
    map: {
      group: {
        endpoint: `${env.API_RECEBIVEIS_CARTAO}api/companies/map/group`,
        byPrefix: (prefix: string | number) =>
          `${env.API_RECEBIVEIS_CARTAO}api/companies/map/group/${prefix}`,
      },
    },
  },
  convenios: {
    grid: `${env.API_RECEBIVEIS_CARTAO}api/convenios/grid`,
  },
  deflatores: {
    endpoint: `${env.API_RECEBIVEIS_CARTAO}api/deflatores`,
    default: `${env.API_RECEBIVEIS_CARTAO}api/deflatores/default`,
    byId: (id: string | number) =>
      `${env.API_RECEBIVEIS_CARTAO}api/deflatores/${id}`,
    cedente: `${env.API_RECEBIVEIS_CARTAO}api/deflatores/company`,
  },
  emailConfigs: {
    endpoint: `${env.API_RECEBIVEIS_CARTAO}api/email_configs`,
    byId: (id: string | number) =>
      `${env.API_RECEBIVEIS_CARTAO}api/email_configs/${id}`,
  },
  filterTable: {
    agendas: `${env.API_RECEBIVEIS_CARTAO}api/filter-table/agendas`,
    agendasCompanyMap: `${env.API_RECEBIVEIS_CARTAO}api/filter-table/agendas-company-map`,
    agendasWithoutOperatedValue: `${env.API_RECEBIVEIS_CARTAO}api/filter-table/agendas-without-operated-value`,
    autoRealocacoes: `${env.API_RECEBIVEIS_CARTAO}api/filter-table/auto-realocacoes`,
    errors: `${env.API_RECEBIVEIS_CARTAO}api/filter-table/errors`,
    jobs: `${env.API_RECEBIVEIS_CARTAO}api/filter-table/jobs`,
    registros: `${env.API_RECEBIVEIS_CARTAO}api/filter-table/registros`,
    registrosSolicitations: `${env.API_RECEBIVEIS_CARTAO}api/filter-table/registros-solicitations`,
    trades: `${env.API_RECEBIVEIS_CARTAO}api/filter-table/trades`,
  },
  jobs: {
    crontab: `${env.API_RECEBIVEIS_CARTAO}api/jobs/crontab`,
    runJob: `${env.API_RECEBIVEIS_CARTAO}api/jobs/run-job`,
    runWorker: `${env.API_RECEBIVEIS_CARTAO}api/jobs/run-worker`,
    stopJob: `${env.API_RECEBIVEIS_CARTAO}api/jobs/stop-job`,
  },
  monitor: {
    healthCheck: `${env.API_RECEBIVEIS_CARTAO}api/monitor/health-check`,
    resumoDiario: `${env.API_RECEBIVEIS_CARTAO}api/monitor/resumo-diario`,
    trades: `${env.API_RECEBIVEIS_CARTAO}api/monitor/trades`,
  },
  realocacao: {
    config: `${env.API_RECEBIVEIS_CARTAO}api/realocacao/config`,
    executar: `${env.API_RECEBIVEIS_CARTAO}api/realocacao/executar`,
    simular: `${env.API_RECEBIVEIS_CARTAO}api/realocacao/simular`,
  },
  registros: {
    download: `${env.API_RECEBIVEIS_CARTAO}api/registros/download`,
    entubaAlteracao: `${env.API_RECEBIVEIS_CARTAO}api/registros/entuba-alteracao`,
    entubaCancelamento: `${env.API_RECEBIVEIS_CARTAO}api/registros/entuba-cancelamento`,
    entubaInclusao: `${env.API_RECEBIVEIS_CARTAO}api/registros/entuba-inclusao`,
  },
  trades: {
    byParentId: {
      children: (parentId: string | number) =>
        `${env.API_RECEBIVEIS_CARTAO}api/trades/${parentId}/children`,
    },
    getAllVnp: `${env.API_RECEBIVEIS_CARTAO}api/trades?status[]=valor_nao_pago&group_by[]=status`,
    getVnpSummary: `${env.API_RECEBIVEIS_CARTAO}api/vnp-summary`,
  },
  v2: {
    deflatores: `${env.API_RECEBIVEIS_CARTAO}api/v2/deflatores`,
  },
  whitelists: {
    endpoint: `${env.API_RECEBIVEIS_CARTAO}api/whitelists`,
    byId: (id: number | string) =>
      `${env.API_RECEBIVEIS_CARTAO}api/whitelists/${id}`,
    grid: `${env.API_RECEBIVEIS_CARTAO}api/whitelists/grid`,
  },
  antecipacaoAutomatica: {
    getCurrentConfig: `${env.API_RECEBIVEIS_CARTAO}/api/antecipacao-automatica`,
  },
  slc: {
    getConciliacao: (startDate: string, endDate: string) =>
      `${env.API_RECEBIVEIS_CARTAO}api/slc/liquidacao-company?startDate=${startDate}&endDate=${endDate}`,
    getConciliacaoDetail: (maturityDate: string) =>
      `${env.API_RECEBIVEIS_CARTAO}api/slc/liquidacao-detalhada-company?maturityDate=${maturityDate}`,
  },
  optin: {
    requestOptins: `${env.API_RECEBIVEIS_CARTAO}api/optins`,
    getOptins: `${env.API_RECEBIVEIS_CARTAO}api/optins/grid`,
    rejectedOptins: `${env.API_RECEBIVEIS_CARTAO}api/rejected-optins/grouped-optins`,
    retryOptin: `${env.API_RECEBIVEIS_CARTAO}api/rejected-optins/retry-optin`,
  },
  optout: {
    requestOptout: `${env.API_RECEBIVEIS_CARTAO}api/optout`,
  },
  processDashboard: {
    endpoint: `${env.API_RECEBIVEIS_CARTAO}api/dashboard-processos`,
  },
  companyTraits: {
    endpoint: `${env.API_RECEBIVEIS_CARTAO}api/company-traits`,
  },
};
