import { client } from "../client";
import { hubLoanAdminCustomerService } from "./admin-customer/hubloan-admin-customer.service";
import { hubloanEndpoints } from "./hubloan.endpoints";
import {
  GetApiAdminCompnayDownloadParams,
  GetApiAdminContractTemplateConfigGetByProductAndTaxIdParams,
  GetApiAdminDashboardBody,
  GetApiAdminLoanIntegrationLogParams,
  GetApiAdminSimulationAutoReadinessParams,
  PostAdminLoanBusinessApproveBody,
  PostAdminSimulationAutoCreateBody,
  PostApiAdminAgreementBody,
  PostApiAdminArrangmentBody,
  PostApiAdminConfigurationVariableBody,
  PostApiAdminContractGeneratorBody,
  PostApiAdminContractTemplateConfigBody,
  PostApiAdminLoanContractsConfigBody,
  PostApiAdminLoanRepublishBody,
  PostApiAdminPartnerBody,
  PostApiAdminTradesImportBody,
  PostApiReportsMainreportSummaryBody,
  PutApiAdminAgreementBody,
  PutApiAdminContractStatusBody,
  PutApiAdminContractTemplateConfigBody,
  PutApiAdminDisbursementValueBody,
  PutApiAdminLoanContractsConfigBody,
  PutApiAdminLoanStatusBody,
  PutApiAdminPartnerBody,
} from "./models/requests";
import { PostAdminGenerateGuaranteeContractBody } from "./models/requests/post-admin-generate-guarantee-contract.request";
import { PostApiAdminAddConfigurationVariableBody } from "./models/requests/post-api-admin-add-configuration-variable.request";
import { ConfirmationBody } from "./models/requests/post-api-confirm-simulation.request";
import { SimulationBody } from "./models/requests/post-api-simulation.request";
import {
  GetApiAdminAgreementFundResponse,
  GetApiAdminAgreementResponse,
  GetApiAdminArrangementByPartnerNameResponse,
  GetApiAdminArrangementIdByIdResponse,
  GetApiAdminArrangementResponse,
  GetApiAdminCompanyByIdReadinessResponse,
  GetApiAdminConfigurationVariableAllResponse,
  GetApiAdminContractByIdResponse,
  GetApiAdminContractTemplateConfigGetByProductAndTaxIdResponse,
  GetApiAdminDashboardResponse,
  GetApiAdminFailedRequestByIdResponse,
  GetApiAdminLoanByIdDetailsResponse,
  GetApiAdminLoanByIdDisbursementsResponse,
  GetApiAdminLoanContractsConfigAllResponse,
  GetApiAdminLoanContractsConfigByIdResponse,
  GetApiAdminLoanIntegrationLogResponse,
  GetApiAdminPartnerResponse,
  GetApiAdminProductTypesResponse,
  GetApiAdminSimulationAutoReadinessResponse,
  PostApiAdminAgreementResponse,
  PostApiAdminArrangementResponse,
  PostApiAdminCompanyUploadContractTemplateResponse,
  PostApiAdminCompanyUploadResponse,
  PostApiAdminContractGeneratorResponse,
  PostApiAdminContractTemplateConfigResponse,
  PostApiAdminLoanContractsConfigResponse,
  PostApiAdminLoanRepublishResponse,
  PostApiAdminPartnerResponse,
  PostApiReportsMainreportSummaryResponse,
  PutApiAdminAgreementResponse,
  PutApiAdminContractTemplateConfigResponse,
  PutApiAdminDisbursementValueResponse,
  PutApiAdminLoanContractsConfigResponse,
  PutApiAdminLoanStatusResponse,
  PutApiAdminPartnerResponse,
} from "./models/responses";
import { SimulationSummaryResponse } from "./models/responses/get-api-simulation-summary.response";
import { GetCompanyProductsResponse } from "./models/responses/get-company-products.response";
import { PostApiAdminSimulationResultResponse } from "./models/responses/post-api-admin-simulation-result-response";
import { hubLoanODataService } from "./odata/hubloan-odata.service";

export const hubLoanService = {
  adminCustomer: hubLoanAdminCustomerService,
  odata: hubLoanODataService,
  getLog(id: number | string) {
    return client.get<GetApiAdminFailedRequestByIdResponse>(
      hubloanEndpoints.admin.failedRequestById(id),
    );
  },
  getAgreementFund() {
    return client.get<GetApiAdminAgreementFundResponse>(
      hubloanEndpoints.admin.agreement.fund,
    );
  },
  postReportsMainreportSummary(body: PostApiReportsMainreportSummaryBody) {
    return client.post<PostApiReportsMainreportSummaryResponse>(
      hubloanEndpoints.reportsMainreportSummary,
      body,
    );
  },
  getArrangement() {
    return client.get<GetApiAdminArrangementResponse>(
      hubloanEndpoints.admin.arrangement.api,
    );
  },
  getArrangementDetails(partnerName: string) {
    return client.get<GetApiAdminArrangementByPartnerNameResponse>(
      hubloanEndpoints.admin.arrangement.byPartnerName(partnerName),
    );
  },
  postArrangement(body: PostApiAdminArrangmentBody) {
    return client.post<PostApiAdminArrangementResponse>(
      hubloanEndpoints.admin.arrangement.api,
      body,
    );
  },
  getAgreement() {
    return client.get<GetApiAdminAgreementResponse>(
      hubloanEndpoints.admin.agreement.api,
    );
  },
  getAgreementRating() {
    return client.get<string[]>(hubloanEndpoints.admin.agreement.rating);
  },
  getAgreementSourceSystem() {
    return client.get<string[]>(hubloanEndpoints.admin.agreement.sourceSystem);
  },
  getAgreementTradeType() {
    return client.get<string[]>(hubloanEndpoints.admin.agreement.tradeType);
  },
  getAgreementWarrantyDocumentSystem() {
    return client.get<string[]>(
      hubloanEndpoints.admin.agreement.warrantyDocumentSystem,
    );
  },
  getAgreementAdditionalDisbursementAccountType() {
    return client.get<string[]>(
      hubloanEndpoints.admin.agreement.additionalDisbursementAccountType,
    );
  },
  getAgreementFinanceOrigin() {
    return client.get<string[]>(hubloanEndpoints.admin.agreement.financeOrigin);
  },
  createAgreement(body: PostApiAdminAgreementBody) {
    return client.post<PostApiAdminAgreementResponse>(
      hubloanEndpoints.admin.agreement.api,
      body,
    );
  },
  editAgreement(body: PutApiAdminAgreementBody) {
    return client.put<PutApiAdminAgreementResponse>(
      hubloanEndpoints.admin.agreement.api,
      body,
    );
  },
  getLoanContractsConfigAll() {
    return client.get<GetApiAdminLoanContractsConfigAllResponse>(
      hubloanEndpoints.admin.loanContractsConfig.all,
    );
  },
  getLoanContractsConfigDetails(id: string) {
    return client.get<GetApiAdminLoanContractsConfigByIdResponse>(
      hubloanEndpoints.admin.loanContractsConfig.byId(id),
    );
  },
  updateLoanContractsConfig(body: PutApiAdminLoanContractsConfigBody) {
    return client.put<PutApiAdminLoanContractsConfigResponse>(
      hubloanEndpoints.admin.loanContractsConfig.api,
      body,
    );
  },
  addLoanContractsConfig(body: PostApiAdminLoanContractsConfigBody) {
    return client.post<PostApiAdminLoanContractsConfigResponse>(
      hubloanEndpoints.admin.loanContractsConfig.api,
      body,
    );
  },
  getPartner() {
    return client.get<GetApiAdminPartnerResponse>(
      hubloanEndpoints.admin.partner,
    );
  },
  addPartner(body: PostApiAdminPartnerBody) {
    return client.post<PostApiAdminPartnerResponse>(
      hubloanEndpoints.admin.partner,
      body,
    );
  },
  updatePartner(body: PutApiAdminPartnerBody) {
    return client.put<PutApiAdminPartnerResponse>(
      hubloanEndpoints.admin.partner,
      body,
    );
  },
  getConfigurationVariableAll() {
    return client.get<GetApiAdminConfigurationVariableAllResponse>(
      hubloanEndpoints.admin.configurationVariable.all,
    );
  },
  updateConfigurationVariable(body: PostApiAdminConfigurationVariableBody) {
    return client.post(hubloanEndpoints.admin.configurationVariable.api, body);
  },
  addConfigurationVariable(body: PostApiAdminAddConfigurationVariableBody) {
    return client.post(
      hubloanEndpoints.admin.configurationVariable.create,
      body,
    );
  },
  addContractTemplateConfig(body: PostApiAdminContractTemplateConfigBody) {
    return client.post<PostApiAdminContractTemplateConfigResponse>(
      hubloanEndpoints.admin.contractTemplateConfig.api,
      body,
    );
  },
  updateContractTemplateConfig(body: PutApiAdminContractTemplateConfigBody) {
    return client.put<PutApiAdminContractTemplateConfigResponse>(
      hubloanEndpoints.admin.contractTemplateConfig.api,
      body,
    );
  },
  getContractTemplates(
    params: GetApiAdminContractTemplateConfigGetByProductAndTaxIdParams,
  ) {
    return client.get<GetApiAdminContractTemplateConfigGetByProductAndTaxIdResponse>(
      hubloanEndpoints.admin.contractTemplateConfig.getByProductAndTaxId,
      { params },
    );
  },
  deleteContractTemplateConfig(id: number) {
    return client.delete(
      hubloanEndpoints.admin.contractTemplateConfig.byId(id),
    );
  },
  uploadContractTemplate(body: FormData) {
    return client.post<PostApiAdminCompanyUploadContractTemplateResponse>(
      hubloanEndpoints.admin.company.uploadContractTemplate,
      body,
    );
  },
  upload(body: FormData) {
    return client.post<PostApiAdminCompanyUploadResponse>(
      hubloanEndpoints.admin.company.upload,
      body,
    );
  },
  download(params: GetApiAdminCompnayDownloadParams) {
    return client.get<Blob>(hubloanEndpoints.admin.company.download, {
      params,
      responseType: "blob",
    });
  },
  getReadinessStatus(id: string) {
    return client.get<GetApiAdminCompanyByIdReadinessResponse>(
      hubloanEndpoints.admin.company.byIdReadiness(id),
    );
  },
  generateContract(body: PostApiAdminContractGeneratorBody) {
    return client.post<PostApiAdminContractGeneratorResponse>(
      hubloanEndpoints.admin.contractGenerator.api,
      body,
    );
  },
  downloadMapping() {
    return client.get<Blob>(
      hubloanEndpoints.admin.contractGenerator.downloadMapping,
      {
        responseType: "blob",
      },
    );
  },
  createAutosimulation(body: PostAdminSimulationAutoCreateBody) {
    return client.post(hubloanEndpoints.admin.simulation.autoCreate, body);
  },
  getAutoSimulationReadiness(params: GetApiAdminSimulationAutoReadinessParams) {
    return client.get<GetApiAdminSimulationAutoReadinessResponse>(
      hubloanEndpoints.admin.simulation.autoReadiness,
      {
        params,
      },
    );
  },
  getContracts(id: string) {
    return client.get<GetApiAdminContractByIdResponse>(
      hubloanEndpoints.admin.contractById(id),
    );
  },
  getLoanDetails(id: string) {
    return client.get<GetApiAdminLoanByIdDetailsResponse>(
      hubloanEndpoints.admin.loan.byIdDetails(id),
    );
  },
  cancelLoan(id: string | number) {
    return client.put(hubloanEndpoints.admin.loan.byIdCancel(id));
  },
  getArrangementId(id: string | number) {
    return client.get<GetApiAdminArrangementIdByIdResponse>(
      hubloanEndpoints.admin.arrangement.idById(id),
    );
  },
  updateLoanStatus(body: PutApiAdminLoanStatusBody) {
    return client.put<PutApiAdminLoanStatusResponse>(
      hubloanEndpoints.admin.loan.status,
      body,
    );
  },
  updateContractStatus(body: PutApiAdminContractStatusBody) {
    return client.put(hubloanEndpoints.admin.updateContractStatus, body);
  },
  resolvePendency(pendencyId: string | number) {
    return client.post(
      hubloanEndpoints.admin.loan.pendencyByIdResolve(pendencyId),
    );
  },
  resolveAllPendencies(loanId: number | string) {
    return client.post(
      hubloanEndpoints.admin.loan.byIdPendencyResolveAll(loanId),
    );
  },
  getLoanDisbursements(id: string) {
    return client.get<GetApiAdminLoanByIdDisbursementsResponse>(
      hubloanEndpoints.admin.loan.byIdDisbursements(id),
    );
  },
  updateDisbursementValue(body: PutApiAdminDisbursementValueBody) {
    return client.put<PutApiAdminDisbursementValueResponse>(
      hubloanEndpoints.admin.disbursement.value,
      body,
    );
  },
  getLoanIntegrationLog(params?: GetApiAdminLoanIntegrationLogParams) {
    return client.get<GetApiAdminLoanIntegrationLogResponse>(
      hubloanEndpoints.admin.loan.integrationLog,
      { params },
    );
  },
  republishLoan(body: PostApiAdminLoanRepublishBody) {
    return client.post<PostApiAdminLoanRepublishResponse>(
      hubloanEndpoints.admin.loan.republish,
      body,
    );
  },
  approveDisbursement(body: PostAdminLoanBusinessApproveBody) {
    return client.post(hubloanEndpoints.admin.loan.businessApprove, body);
  },
  importTrades(body: PostApiAdminTradesImportBody) {
    return client.post(hubloanEndpoints.admin.trades.import, body);
  },
  getApiAdminDashboard(body: GetApiAdminDashboardBody) {
    return client.post<GetApiAdminDashboardResponse>(
      hubloanEndpoints.admin.dashboard,
      body,
    );
  },
  updateBalancePosition(id: string) {
    return client.post(hubloanEndpoints.admin.loan.byIdUpdatePosition(id));
  },
  generateGuaranteeContract(body: PostAdminGenerateGuaranteeContractBody) {
    return client.post<number>(
      hubloanEndpoints.admin.contractGenerator.guarantees,
      body,
    );
  },
  getSettlementTypes() {
    return client.get<string[]>(hubloanEndpoints.admin.agreement.types);
  },
  getAvailableProducts() {
    return client.get<GetCompanyProductsResponse[]>(
      hubloanEndpoints.companyProducts,
    );
  },
  frameTaxId(taxId: string, offerId?: string) {
    return client.post<string>(hubloanEndpoints.frameTaxId, {
      taxId,
      offerId,
    });
  },
  getSimulationSummary(product: string, id: string, offerId?: string) {
    return client.get<SimulationSummaryResponse>(
      hubloanEndpoints.simulationSummary(product, id, offerId),
    );
  },
  getLoanbyIdDetails(loanId: number | string) {
    return client.get(hubloanEndpoints.loanByIdDetails(loanId));
  },
  getPossibleStatusLoan(loanId: number | string) {
    return client.get<string[]>(
      hubloanEndpoints.admin.loan.possibleStatusByLoanId(loanId),
    );
  },
  postConfirmSimulation(body: ConfirmationBody) {
    return client.post(hubloanEndpoints.simulationAccept, body);
  },
  postSimulation(body: SimulationBody) {
    return client.post<PostApiAdminSimulationResultResponse[]>(
      hubloanEndpoints.simulation,
      body,
    );
  },
  getProductTypes() {
    return client.get<GetApiAdminProductTypesResponse[]>(
      hubloanEndpoints.admin.products,
    );
  },
};
