import { OToastManager } from "@maestro/react";
import { DetailsCard } from "components/details-card";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useRoles } from "hooks/roles";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { DetailsTemplate } from "templates/details-template";
import { emprestimosRoles } from "../../../../../roles/emprestimos.roles";
import { EmprestimosProdutoEmprestimosById } from "../../../../../routes/emprestimos.route-params";
import { OperationsDetailsGenerator } from "./operations-details.details";
import { ApproveDisbursement } from "./_compose/approve-disbursement.component";
import { CancelLoan } from "./_compose/cancel-loan.component";
import { RepublishTrade } from "./_compose/republish-trade.component";

export const OperationsDetailsPage = () => {
  const [loading, setLoading] = useState(false);

  const { id } = useParams<EmprestimosProdutoEmprestimosById>();

  const form = useForm<{ loanStatus: string }>();

  const { handleSubmit, reset } = form;

  if (!id) throw new Error("No id");

  const { hasRole } = useRoles();

  const {
    callService: getLoanData,
    value: loanData,
    hasError: hasErrorLoanData,
    loading: loadingLoanData,
  } = useServiceCall(service.hubLoan.adminCustomer.getLoanDetails);

  const {
    callService: updateBalancePosition,
    loading: loadingUpdateBalancePosition,
  } = useServiceCall(service.hubLoan.updateBalancePosition);

  const updateLoanStatus = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          setLoading(true);

          await service.hubLoan.updateLoanStatus({
            loanId: +id,
            loanStatus: values.loanStatus,
          });

          OToastManager.success("Status atualizado com sucesso");

          getLoanData(id);
        } catch {
          OToastManager.danger("Houve um erro ao tentar atualizar o status.");
        } finally {
          setLoading(false);
        }
      }),
    [getLoanData, handleSubmit, id],
  );

  const updatePosition = useCallback(async () => {
    const { success } = await updateBalancePosition(id);

    if (success) {
      OToastManager.success(
        "Atualização da posição do balance realizada com sucesso!",
      );

      getLoanData(id);
    } else
      OToastManager.danger(
        "Houve um erro ao tentar atualizar a posição do balance.",
      );
  }, [getLoanData, id, updateBalancePosition]);

  useEffect(() => {
    getLoanData(id);
  }, [getLoanData, id]);

  useEffect(() => {
    loanData &&
      reset({
        loanStatus: loanData.status,
      });
  }, [loanData, reset]);

  return (
    <DetailsTemplate
      pageTitle={
        <PageTitle
          title={
            loanData?.contract?.number
              ? `Detalhes #${loanData.contract.number}`
              : "Detalhes"
          }
        />
      }
      actions={
        <>
          {hasRole(
            emprestimosRoles.customer.operationsDetailsLoanCancel.role,
          ) && <CancelLoan loanData={loanData} getLoanData={getLoanData} />}

          {loanData?.status === "Desembolsado" &&
            hasRole(
              emprestimosRoles.customer.operationsDetailsUpdatePosition.role,
            ) && (
              <LoadingButton
                onClick={updatePosition}
                loading={loadingUpdateBalancePosition}
              >
                Atualizar posição
              </LoadingButton>
            )}

          {loanData &&
            hasRole(
              emprestimosRoles.customer.operationsDetailsRepublishTrade.role,
            ) && <RepublishTrade loanData={loanData} />}

          {loanData &&
            hasRole(
              emprestimosRoles.customer.operationsDetailsApproveDisbursement
                .role,
            ) && <ApproveDisbursement loanData={loanData} />}

          {hasRole(
            emprestimosRoles.customer.operationsDetailsConfirmStatus.role,
          ) && (
            <LoadingButton loading={loading} onClick={updateLoanStatus}>
              Confirmar status
            </LoadingButton>
          )}
        </>
      }
    >
      <div className="d-flex flex-column gap-2">
        <FormProvider {...form}>
          <DetailsCard
            loading={loadingLoanData}
            errorComponent={
              <ErrorComponent
                messageTitle="Não foi possível carregar os dados do emprestimos."
                messageParagraph="Tente novamente mais tarde."
              >
                <TryAgainButton onClick={() => getLoanData(id)} />
              </ErrorComponent>
            }
            hasError={hasErrorLoanData}
            value={loanData}
            fields={OperationsDetailsGenerator}
          />
        </FormProvider>
      </div>
    </DetailsTemplate>
  );
};
