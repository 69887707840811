import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { GetSnakeCompanyByIdResponse } from "services/fidc/models/responses";

export const interactionPartnersGrid: ODataGridGeneratorConfig<
  GetSnakeCompanyByIdResponse["partners"][number]
> = {
  datagrid: {
    noDataText: "Nenhum sócio encontrado.",
  },
  columns: [
    {
      dataField: "id",
      caption: "Id",
    },
    {
      dataField: "name",
      caption: "Nome",
    },
    {
      dataField: "email",
      caption: "Email",
    },
    {
      dataField: "cellPhoneNumber",
      caption: "Celular",
      cellRender: ({ data }) => masks.phone(data.cellPhoneNumber),
    },
    {
      dataField: "taxId",
      caption: "CPF/CNPJ",
      cellRender: ({ data }) => masks.cpfCnpj(data.taxId),
    },
  ],
};
