import {
  OButton,
  OFilter,
  OFilterBody,
  OFilterFooter,
  OFilterHeader,
  OFilterModal,
  OToastManager,
  OTypography,
} from "@maestro/react";
import { validators } from "@maestro/utils";
import { useRef } from "react";
import { useBankslipContext } from "../../bankslip.context";
import { BadCreditStatusFilterItem } from "./_compose/bad-credit-status-filter-item";
import { DateFilterItem } from "./_compose/date-filter-item";
import { IdentifierFilterItem } from "./_compose/identifier-filter-item";
import { PayerFilterItem } from "./_compose/payer-filter-item";
import { StatusFilterItem } from "./_compose/status-filter-item";
import { CollectionFilterFields } from "./filters-modal.type";
import { assembleFilterParams } from "./filters-modal.utils";

export const bankslipFilterModalId = "bankslip-filter-modal-id";

export const FiltersModal = () => {
  const filterModalRef = useRef<HTMLOFilterModalElement>(null);
  const { handleSubmitFilter } = useBankslipContext();

  const handleFilterItems = async () => {
    const params =
      (await filterModalRef.current?.filter()) as CollectionFilterFields;

    const { payerTaxId, badCreditStatus, status } = params;

    if (Array.isArray(status)) {
      params.status = status.join(",");
    }

    if (Array.isArray(badCreditStatus)) {
      params.badCreditStatus = badCreditStatus.join(",");
    }

    if (payerTaxId && !validators.cpfCnpj(payerTaxId)) {
      OToastManager.danger("CPF/CNPJ inválido");
      return;
    }

    handleSubmitFilter(assembleFilterParams(params));

    filterModalRef.current?.closeFilter();
  };

  const handleClearFilterItems = async () => {
    handleSubmitFilter({});
    await filterModalRef.current?.clearFilter();
    filterModalRef.current?.closeFilter();
  };

  return (
    <OFilterModal ref={filterModalRef} id={bankslipFilterModalId}>
      <OFilterHeader className="pt-1">
        <OTypography tag="h2">Filtros</OTypography>
      </OFilterHeader>

      <OFilterBody>
        <OFilter>
          <PayerFilterItem />
          <IdentifierFilterItem />
          <DateFilterItem dateType="CreatedAt" />
          <DateFilterItem dateType="DueDate" />
          <DateFilterItem dateType="SettledAt" />
          <StatusFilterItem />
          <BadCreditStatusFilterItem />
        </OFilter>
      </OFilterBody>

      <OFilterFooter>
        <div className="d-flex justify-content-end">
          <OButton
            type="primary"
            onClick={handleClearFilterItems}
            bordered={false}
            outline
          >
            Limpar
          </OButton>
          <OButton onClick={handleFilterItems}>Filtrar</OButton>
        </div>
      </OFilterFooter>
    </OFilterModal>
  );
};
