import { client } from "../../client";
import { ODataQueryRequest } from "../../service.types";
import { hubfxEndpoints } from "../hubfx.endpoints";
import { GetAdminBanksODataResponse } from "./models/responses/get-admin-banks-o-data.response";
import { GetAdminCounterpartiesODataResponse } from "./models/responses/get-admin-counterparties-o-data.response";
import { GetAdminCountriesODataResponse } from "./models/responses/get-admin-countries-o-data.response";
import { GetAdminCurrenciesODataResponse } from "./models/responses/get-admin-currencies-o-data.response";
import { GetAdminLimitsODataResponse } from "./models/responses/get-admin-limits-o-data.response";
import { ChannelOdataOperationsResponses } from "./models/responses/get-admin-operations-o-data.response";
import { ChannelOdataOrdersResponses } from "./models/responses/get-admin-orders-o-data.response";
import { ChannelOdataPreTicketsResponses } from "./models/responses/get-admin-pre-tickets-o-data.response";

export const hubFxODataService = {
  getAdminCountries(params: ODataQueryRequest) {
    return client.get<GetAdminCountriesODataResponse>(
      hubfxEndpoints.admin.countries,
      { params },
    );
  },
  getCurrencies(params: ODataQueryRequest) {
    return client.get<GetAdminCurrenciesODataResponse>(
      hubfxEndpoints.odata.adminCurrenciesOData,
      { params },
    );
  },
  getOperations(params: ODataQueryRequest) {
    return client.get<ChannelOdataOperationsResponses>(
      hubfxEndpoints.channelOdata.channelOperationsOData,
      { params },
    );
  },
  getOrders(params: ODataQueryRequest) {
    return client.get<ChannelOdataOrdersResponses>(
      hubfxEndpoints.channelOdata.channelOrdersOData,
      { params },
    );
  },
  getPreTickets(params: ODataQueryRequest) {
    return client.get<ChannelOdataPreTicketsResponses>(
      hubfxEndpoints.channelOdata.channelPreTicketsOData,
      { params },
    );
  },
  getCounterparties(params: ODataQueryRequest) {
    return client.get<GetAdminCounterpartiesODataResponse>(
      hubfxEndpoints.odata.adminCounterpartiesOData,
      { params },
    );
  },
  getLimits(params: ODataQueryRequest) {
    return client.get<GetAdminLimitsODataResponse>(
      hubfxEndpoints.odata.adminLimitsOData,
      { params },
    );
  },
  getBanks(params: ODataQueryRequest) {
    return client.get<GetAdminBanksODataResponse>(
      hubfxEndpoints.odata.adminBanksOData,
      { params },
    );
  },
};
