import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { customer } from "contexts/customer";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services/service";
import {
  updateOriginatorIndcationDefaultValues,
  updateOriginatorIndcationValidationSchema,
} from "./update-originator-indication.form";

export const useUpdateOriginatorIndication = () => {
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [uuid, setUuid] = useState("");

  const form = useForm({
    resolver: yupResolver(updateOriginatorIndcationValidationSchema),
    defaultValues: updateOriginatorIndcationDefaultValues,
  });
  const { handleSubmit, setValue } = form;

  const onSubmit = handleSubmit(async (values) => {
    const payload = {
      indicatorOriginatorUUID: values.indicatorOriginatorUUID,
      indicatorOperatorUUID: values.indicatorOperatorUUID,
    };

    setLoadingSubmit(true);
    try {
      await service.hubPartners.updateOriginatorIndicator(uuid, payload);
      OToastManager.success("Originador atualizado com sucesso!");
    } catch {
      OToastManager.danger("Erro ao atualizar indicação do originador");
    } finally {
      setLoadingSubmit(false);
    }
  });

  const getInfo = useCallback(async () => {
    const customerUuid = customer.value?.identification;

    if (!customerUuid) {
      return;
    }

    setLoading(true);
    try {
      const { data } = await service.hubPartners.originatorDetails(
        customerUuid,
      );
      const details = data.data;
      if (details.indicatorOriginator) {
        setValue("hasIndicator", ["check"]);
      }
      setValue("indicatorOriginatorUUID", details.indicatorOriginator?.uuid);
      setValue("indicatorOperatorUUID", details.indicatorOperator?.uuid);

      setUuid(details.uuid);
    } catch {
      OToastManager.danger("Falha ao obter detalhes do originador");
    } finally {
      setLoading(false);
    }
  }, [setValue]);

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  return {
    form,
    loading,
    loadingSubmit,
    onSubmit,
  };
};
