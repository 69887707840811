import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { GetApiAdminLoanByIdDetailsResponse } from "services/hubloan/models";
import { format } from "utils/date";
import { parseInstallmentStatusType } from "./operations-installments.utils";

type HubLoanInstallmentItem = NonNullable<
  NonNullable<GetApiAdminLoanByIdDetailsResponse["settlement"]>["installments"]
>[number];

export const OperationsInstallmentFromHubLoanGrid =
  (): ODataGridGeneratorConfig<HubLoanInstallmentItem> => {
    return {
      datagrid: {
        noDataText: "Nenhuma parcela encontrada.",
        pager: {
          showInfo: true,
          infoText: "Página {0} de {1} ({2} parcelas)",
        },
        filterRow: {
          visible: true,
        },
      },
      columns: [
        {
          dataField: "code",
          caption: "Parcela",
          width: "100",
          sortOrder: "asc",
        },
        {
          dataField: "maturityDate",
          caption: "Data de vencimento",
          alignment: "left",
          cellRender: ({ data }) => format.date(data.maturityDate),
        },
        {
          dataField: "amount",
          caption: "Valor no vencimento",
          alignment: "left",
          cellRender: ({ data }) => masks.currency(data.amount, "R$", "."),
        },
        {
          dataField: "status",
          caption: "Status",
          alignment: "left",
          cellRender: ({ data }) => parseInstallmentStatusType[data.status],
        },
      ],
    } satisfies ODataGridGeneratorConfig<HubLoanInstallmentItem>;
  };
