import { OCol, OField, ORow } from "@maestro/react";
import { SelectSearchMultiple } from "components/form";
import { SelectSearch } from "components/select-search";
import { usePermissionsFields } from "./permissions-fields.hook";

interface CategorizationsFieldsProps {
  isCorban?: boolean;
  isTechnology?: boolean;
}

export const CategorizationsFields = ({
  isCorban = false,
  isTechnology = true,
}: CategorizationsFieldsProps) => {
  const {
    loadingOriginatorSubtypes,
    loadingPartnershipTypes,
    loadingProducts,
    originatorSubtypeOptions,
    partnershipTypeError,
    partnershipTypeOptions,
    partnershipTypeValue,
    productOptions,
  } = usePermissionsFields(isTechnology);

  return (
    <ORow>
      <OCol lg={6} xs={12}>
        <OField
          htmlFor="partnershipType"
          label="Tipo de Parceria"
          error={!!partnershipTypeError}
          message={partnershipTypeError?.message ?? ""}
          disabled={isCorban || loadingPartnershipTypes}
        >
          <SelectSearch
            className="w-100"
            id="partnershipType"
            name="partnershipType"
            placeholder="Selecione"
            value={partnershipTypeValue}
            disabled={isCorban || loadingPartnershipTypes}
            options={partnershipTypeOptions}
          />
        </OField>
      </OCol>
      <OCol lg={6} xs={12}>
        <SelectSearchMultiple
          id="originatorSubTypes"
          name="originatorSubTypes"
          placeholder="Selecione"
          label="Subtipo do Originador"
          options={originatorSubtypeOptions}
          disabled={isCorban || loadingOriginatorSubtypes}
        />
      </OCol>
      <OCol lg={6} xs={12}>
        <SelectSearchMultiple
          id="products"
          name="products"
          placeholder="Selecione"
          label="Produtos"
          options={productOptions}
          disabled={isCorban || loadingProducts}
          required
        />
      </OCol>
    </ORow>
  );
};
