import { ODivider, ORow, OTypography } from "@maestro/react";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { addEstrategiaFormDefaultValues } from "../estrategia-form.form";
import { EstrategiaForm } from "../estrategia-form.types";
import {
  ApprovalGroupsField,
  AutomaticAnticipationField,
  BiometryRequiredField,
  ContractTemplateIdField,
  CreditorIdField,
  ExigeAprovacaoBancoField,
  ExigeIndicacaoHedgeField,
  FilesForOperationField,
  FilesForReceivablesField,
  HasGuarantorsField,
  HasPayerValidationField,
  HiringContractTemplateIdField,
  HiringContractTypeField,
  HorarioLimiteOperacaoField,
  InstrumentCodeIdField,
  InterestArrearsBaseField,
  InterestArrearsRateField,
  MarketplaceIdField,
  ModeloDeTaxaField,
  NeedsFilesForOperationField,
  NeedsFilesForReceivablesField,
  NomeField,
  OfertaCCCField,
  PenaltyFeeField,
  PnlBookField,
  PnlStrategyField,
  PortfolioIdField,
  PrazoLimiteOperacaoField,
  TipoContratoField,
  TipoOnboardingField,
  GracePeriodField,
  ToCreditField,
  NeedsRegistrationInfoField,
} from "../fields";

interface CCCFormProps {
  form: UseFormReturn<EstrategiaForm>;
  shouldReset?: boolean;
}

export const CCCForm = ({ form, shouldReset }: CCCFormProps) => {
  const { getValues, reset, watch } = form;

  useEffect(() => {
    if (shouldReset)
      reset({
        ...addEstrategiaFormDefaultValues,
        tipoProduto: getValues("tipoProduto"),
        prazoLimiteOperacao: 0,
        toCredit: "yes",
        modeloDeTaxa: "configuracao",
        exigeAprovacaoBanco: "no",
        tipoOnboarding: "SACADO_SIMPLES",
      });
  }, [getValues, reset, shouldReset]);

  const needsFilesForOperationWatch = watch("needsFilesForOperation");
  const needsFilesForReceivablesWatch = watch("needsFilesForReceivables");

  return (
    <>
      <OTypography size="lg">Geral</OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <NomeField />
        <ModeloDeTaxaField />
        <HorarioLimiteOperacaoField />
        <ExigeAprovacaoBancoField />
        <ExigeIndicacaoHedgeField />
        <TipoOnboardingField type="CCC" />
        <PrazoLimiteOperacaoField />
        <GracePeriodField />
        <PnlBookField />
        <PnlStrategyField />
        <InstrumentCodeIdField />
        <MarketplaceIdField />
        <PortfolioIdField />
        <CreditorIdField />
        <BiometryRequiredField />
        <ToCreditField />
      </ORow>

      <OTypography size="lg" className="mt-5">
        CCC
      </OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <HiringContractTypeField type="CCC" />
        <HiringContractTemplateIdField />
        <TipoContratoField type="CCC" required />
        <ContractTemplateIdField />
        <AutomaticAnticipationField />
        <HasGuarantorsField />
        <HasPayerValidationField />
      </ORow>

      <OTypography size="lg" className="mt-5">
        Validações de entrada
      </OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <NeedsFilesForReceivablesField />
        <FilesForReceivablesField
          disabled={needsFilesForReceivablesWatch !== "yes"}
        />
        <NeedsFilesForOperationField />
        <FilesForOperationField
          disabled={needsFilesForOperationWatch !== "yes"}
        />
        <NeedsRegistrationInfoField />
      </ORow>

      <OTypography size="lg" className="mt-5">
        Encargos de atraso
      </OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <PenaltyFeeField required />
        <InterestArrearsRateField required />
        <InterestArrearsBaseField required />
      </ORow>

      <OTypography size="lg" className="mt-5">
        Avançado
      </OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <ApprovalGroupsField />
      </ORow>

      <OTypography size="lg" className="mt-5">
        Notificações (cedente)
      </OTypography>
      <ODivider type="dark" size="xxs" className="mb-2" />
      <ORow columnGap={4} rowGap={5} className="ms-2">
        <OfertaCCCField />
      </ORow>
    </>
  );
};
