import {
  OCard,
  OCardBody,
  OCheckbox,
  OCol,
  OLabel,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORow,
} from "@maestro/react";
import { InputMultiple } from "../../../../recebiveis/components/input-multiple/input-multiple.component";
import { ExchangeNatureGenericFieldsForm } from "../nature-generic-fields-form.component";

export const ExchangeDocumentConfigsForm = () => {
  return (
    <div className="d-flex flex-column gap-4">
      <OCard>
        <OCardBody>
          <ORow>
            <OCol sm={12} md={6}>
              <ORFieldInput
                tag="text"
                id="typeName"
                name="typeName"
                dataAction="cambio_formulario_documento_configuracao:texto:nome"
                dataLabel="nome"
                label="Nome"
              />
            </OCol>
            <OCol sm={12} md={6}>
              <ORFieldInput
                tag="text"
                id="typeDescription"
                name="typeDescription"
                dataAction="cambio_formulario_documento_configuracao:texto:descricao"
                dataLabel="descricao"
                label="Descrição"
              />
            </OCol>
          </ORow>

          <ORow>
            <OCol sm={12} md={12} lg={4}>
              <ORFieldCheckboxGroup
                id="isFileAllowed"
                name="isFileAllowed"
                dataAction="cambio_formulario_documento_configuracao:checkbox:permitido_arquivo"
                dataLabel="permitido_arquivo"
              >
                <div className="d-flex align-items-center gap-2 mb-2">
                  <OCheckbox
                    size="xs"
                    id="isFileAllowed-checkbox"
                    value="true"
                  />
                  <OLabel htmlFor="isFileAllowed-checkbox">
                    Permitido arquivo?
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </OCol>
          </ORow>
        </OCardBody>
      </OCard>

      <OCard>
        <OCardBody>
          <ExchangeNatureGenericFieldsForm />
        </OCardBody>
      </OCard>
      <OCard>
        <OCardBody>
          <ExchangeNatureGenericFieldsForm name="fileGenericFields" />
        </OCardBody>
      </OCard>
      <OCard>
        <OCardBody>
          <OCol sm={12} md={6}>
            <InputMultiple
              id="fxPendencyCodes"
              name="fxPendencyCodes"
              dataAction="cambio_formulario_documento_configuracao:texto:codigos_pendencia_fx"
              dataLabel="codigos_pendencia_fx"
              label="Códigos de pendencia do FX"
            />
          </OCol>
        </OCardBody>
      </OCard>
    </div>
  );
};
