import { OToastManager } from "@maestro/core";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { dataSourceODataStoreGenerator } from "components/data-grid";
import { GetSnakeOdataInteractionsOdataResponse } from "services/fidc/models/responses";
import { endpoints } from "services/endpoints";
import { service } from "services/service";
import { useFidcFunds } from "../../../hooks";

const buildDataSource = (fundId: string) =>
  dataSourceODataStoreGenerator<
    GetSnakeOdataInteractionsOdataResponse["value"][number]
  >(endpoints.fidc.snake.odata.InteractionsOdata, {
    odataStoreOptions: {
      beforeSend: (config) => {
        // eslint-disable-next-line no-param-reassign
        config.params = {
          fundTaxId: fundId,
          ...config.params,
        };
        return config;
      },
    },
  });

export const useInteractions = () => {
  const [loading, setLoading] = useState(false);
  const { funds } = useFidcFunds();
  const form = useForm();
  const { watch } = form;
  const selectedFundWatch: string = watch("selectedFund");

  const resend = useCallback(async (interactionId: string) => {
    setLoading(true);

    try {
      await service.fidc.postInteractionTracking({ interactionId });
      OToastManager.success("Interação reenviada com sucesso!");
    } catch {
      OToastManager.danger("Erro ao reenviar interação para o destinatário");
    } finally {
      setLoading(false);
    }
  }, []);

  const dataSource = useMemo(() => {
    return buildDataSource(selectedFundWatch ?? "");
  }, [selectedFundWatch]);

  return {
    dataSource,
    form,
    funds,
    loading,
    resend,
    selectedFundWatch,
  };
};
