import { OCol, ORFieldInput, ORow } from "@maestro/react";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { useRateCustomization } from "./rate-customization.hook";

export const RateCustomization = () => {
  const {
    customerName,
    getBillingInfo,
    form,
    hasError,
    loadingBillingInfo,
    loadingSubmit,
    onSubmit,
    rateValues,
  } = useRateCustomization();

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title={`Cliente: ${customerName}`}
          description="Visualize e edite os dados do cliente selecionado"
        />
      }
      actions={
        !hasError && (
          <LoadingButton
            dataAction="configuracao_tarifas:botao:alterar"
            dataLabel="alterar"
            loading={loadingBillingInfo || loadingSubmit}
            onClick={() => onSubmit()}
          >
            Alterar
          </LoadingButton>
        )
      }
    >
      <CardTemplate>
        <ContentTemplate
          loading={loadingBillingInfo}
          hasError={hasError}
          value={rateValues}
          errorComponent={
            <ErrorComponent
              messageTitle="Não foi possível encontrar dados de billing desse cliente"
              messageParagraph="Clique no botão para tentar novamente"
            >
              <TryAgainButton onClick={() => getBillingInfo()} />
            </ErrorComponent>
          }
          render={(_rateValues) => (
            <FormProvider {...form}>
              <ORow>
                {_rateValues?.customerProduct.map((product) => (
                  <OCol key={product.productSku} xs={12} md={6}>
                    <ORFieldInput
                      tag="number"
                      id={product.productSku}
                      name={product.productSku}
                      dataAction={`configuracao_tarifas:texto:${product.name}`}
                      dataLabel={product.name}
                      label={
                        product.recurringChargePeriod
                          ? `${product.name} (Mensalidade)`
                          : product.name
                      }
                    />
                  </OCol>
                ))}
                {_rateValues?.paymentSource.map((product) => (
                  <OCol
                    key={`${product.productSku}-${product.source}`}
                    xs={12}
                    md={6}
                  >
                    <ORFieldInput
                      tag="number"
                      id={`${product.productSku}-${product.source}`}
                      name={`${product.productSku}-${product.source}`}
                      dataAction={`configuracao_tarifas:texto:${product.description}`}
                      dataLabel={product.description}
                      label={product.description}
                    />
                  </OCol>
                ))}
              </ORow>
            </FormProvider>
          )}
        />
      </CardTemplate>
    </FormTemplate>
  );
};
