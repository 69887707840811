import * as yup from "yup";
import { FormValuesType } from "./update-contact.types";

export const validationSchema: yup.ObjectSchema<FormValuesType> = yup.object({
  bank: yup.string().required("Este campo é obrigatório"),
  branch: yup.string().required("Este campo é obrigatório"),
  account: yup.string().required("Este campo é obrigatório"),
  account_digit: yup.string().required("Este campo é obrigatório"),
  account_type: yup.string().required("Este campo é obrigatório"),
  is_disbursement_account: yup.string().required("Este campo é obrigatório"),
});
