import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useIntubateOperations } from "../../intubate-operations.context";
import { CardReceivable } from "../../intubate-operations.types";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import { auth } from "contexts/auth";
import dayjs from "dayjs";
import { quickfinEndpoints } from "services/quickfin/quickfin.endpoints";
import { FilterPayload } from "./_compose/search-modal/search-modal.types";
import type { Options } from "devextreme/data/data_source";

interface QuoteListContext {
  dataSource: DataSource<CardReceivable> | undefined;
  disbursementAmounts: Record<number, number>;
  requestedAmounts: Record<number, number>;
  selectedRows: CardReceivable[];
  filterPayload: FilterPayload | undefined;
  setDisbursementAmounts: React.Dispatch<
    React.SetStateAction<Record<number, number>>
  >;
  setRequestedAmounts: React.Dispatch<
    React.SetStateAction<Record<number, number>>
  >;
  setSelectedRows: React.Dispatch<React.SetStateAction<CardReceivable[]>>;
  updateParentContext: () => void;
  setFilterPayload: React.Dispatch<React.SetStateAction<FilterPayload | undefined>>;
}

const quoteListContext = createContext({} as QuoteListContext);

interface QuoteListProps {
  children: React.ReactNode;
}

export const QuoteListProvider = ({ children }: QuoteListProps) => {
  const [selectedRows, setSelectedRows] = useState<CardReceivable[]>([]);
  const [filterPayload, setFilterPayload] = useState<FilterPayload>() ;
  const [requestedAmounts, setRequestedAmounts] = useState<
    Record<number, number>
  >({});
  const [disbursementAmounts, setDisbursementAmounts] = useState<
    Record<number, number>
  >({});
  const [requestedDisbursementAmounts, setRequestedDisbursementAmounts] =
    useState<Record<number, number>>({});

  const { setCardReceivables } = useIntubateOperations();

  const odataStore = new ODataStore<CardReceivable>({
    url: quickfinEndpoints.odata.receivableStateMachineODataView,
    version: 4,
    beforeSend: (config) => {
      // eslint-disable-next-line no-param-reassign
      config.headers = {
        Authorization: auth.value,
        ...config.headers,
      };
    },
  });

  const gridFilter: Options[] = [
    ["TipoProduto", "=", "Cartao"], 
    ["State", "=", "DisponivelParaCedente"],
    ["ValorDesembolso", "<>", null],
    ["CreatedAt", ">=", dayjs().startOf("day").toDate()],
  ];

  const dataSource = useMemo(
    () =>{
        if (filterPayload){

          gridFilter.push(["CedenteIdentification", "contains", filterPayload.cedente]);
          if (filterPayload?.bandeira != null) gridFilter.push(["Bandeira", "=", filterPayload?.bandeira]);
          if (filterPayload?.sacado != null) gridFilter.push(["SacadoIdentification", "contains", filterPayload?.sacado]);
          if (filterPayload?.dataVencimentoInicial != null) gridFilter.push(["DataVencimento", ">=", filterPayload?.dataVencimentoInicial]);
          
          return new DataSource<CardReceivable>({
            load: (loadOptions) => odataStore.load(loadOptions),
            filter: [
              gridFilter,
            ],
            select: [
              "Bandeira",
              "Sacado",
              "SacadoIdentification",
              "ReceivableId",
              "Cedente",
              "CedenteIdentification",
              "DataVencimento",
              "ValorVencimento",
              "ValorDesembolso",
              "Currency",
              "TaxaTotal",
            ],
          })
        }
    }, [filterPayload],
  );

  const updateParentContext = useCallback(() => {
    setCardReceivables(
      selectedRows.map((row) => ({
        ...row,
        ValorSolicitado:
          requestedAmounts[row.ReceivableId] ?? row.ValorVencimento,
        ValorDesembolso:
          disbursementAmounts[row.ReceivableId] ?? row.ValorDesembolso,
        ValorDesembolsoSolicitado:
          requestedDisbursementAmounts[row.ReceivableId] ?? row.ValorDesembolso,
      })),
    );
  }, [
    disbursementAmounts,
    requestedAmounts,
    requestedDisbursementAmounts,
    selectedRows,
    setCardReceivables,
  ]);

  const value = useMemo(
    () => ({
      dataSource,
      selectedRows,
      setSelectedRows,
      requestedAmounts,
      setRequestedAmounts,
      disbursementAmounts,
      setDisbursementAmounts,
      requestedDisbursementAmounts,
      setRequestedDisbursementAmounts,
      updateParentContext,
      filterPayload,
      setFilterPayload,
    }),
    [
      dataSource, 
      disbursementAmounts,
      requestedAmounts,
      requestedDisbursementAmounts,
      selectedRows,
      updateParentContext,
      filterPayload,
    ],
  );

  return (
    <quoteListContext.Provider value={value}>
      {children}
    </quoteListContext.Provider>
  );
};

export const useQuoteList = () => useContext(quoteListContext);
