import { RedirectWithRoles } from "components/redirect-with-roles";
import { Routes } from "routes/routes.types";
import {
  Anticipations,
  ConsolidatedContracts,
  EditBankSlip,
  FidcBatches,
  FidcOperations,
  FidcOperationsDetails,
  FidcReceivable,
  ImportFidc,
  InteractionDetails,
  InteractionFinancialAdd,
  InteractionFinancialEdit,
  Interactions,
  ViewAssets,
  ViewAssetsDetails,
} from "../pages";
import { fidcRoles } from "../roles/fidc.roles";
import { CreditConciliationDetails } from "../pages/produto/liquidacao-repasse/gestao/detalhes";
import { DisverbationApprovals } from "../pages/produto/desaverbacao/aprovacao/disverbation-approvals.page";
import { DisverbationHistorys } from "../pages/produto/desaverbacao/historico";
import { CreditConciliationPage } from "../pages/produto/liquidacao-repasse/gestao";
import { LoadFileConciliation } from "../pages/produto/liquidacao-repasse/upload/load-file-conciliation.component";

export const fidcRoutes = {
  product: {
    name: "",
    path: "/fidc/produto",
    element: <RedirectWithRoles />,
    children: {
      anticipations: {
        name: "Antecipações",
        path: "/fidc/produto/antecipacoes",
        element: <Anticipations />,
        role: fidcRoles.product.anticipationsPage.role,
      },
      consolidatedContracts: {
        name: "Contratos Consolidados",
        path: "/fidc/produto/contratos",
        element: <ConsolidatedContracts />,
        role: fidcRoles.product.consolidatedContractsPage.role,
      },
      bankslipEditing: {
        name: "Edição de boleto",
        path: "/fidc/produto/edicao-boleto",
        element: <EditBankSlip />,
        role: fidcRoles.product.bankslipEditingPage.role,
        children: {
          assets: {
            name: "Detalhes dos ativos",
            path: "/fidc/produto/edicao-boleto/ativos",
            element: <ViewAssets />,
            role: fidcRoles.product.bankslipEditingAssetsPage.role,
            children: {
              details: {
                name: "Detalhes do boleto",
                path: "/fidc/produto/edicao-boleto/ativos/detalhes",
                element: <ViewAssetsDetails />,
                role: fidcRoles.product.bankslipEditingAssetsDetailsPage.role,
              },
            },
          },
        },
      },
      import: {
        name: "Importação",
        path: "/fidc/produto/importacao",
        element: <ImportFidc />,
        role: fidcRoles.product.importPage.role,
      },
      interactions: {
        name: "Interações",
        path: "/fidc/produto/interacoes",
        element: <Interactions />,
        role: fidcRoles.product.interactionsPage.role,
        children: {
          details: {
            name: "Detalhes",
            path: "/fidc/produto/interacoes/detalhes",
            element: <InteractionDetails />,
            role: fidcRoles.product.interactionsDetailsPage.role,

            children: {
              add: {
                name: "Adição de contato",
                path: "/fidc/produto/interacoes/detalhes/adicionar",
                element: <InteractionFinancialAdd />,
                role: fidcRoles.product.interactionsDetailsAddPage.role,
              },
              edit: {
                name: "Edição de contato",
                path: "/fidc/produto/interacoes/detalhes/editar",
                element: <InteractionFinancialEdit />,
                role: fidcRoles.product.interactionsDetailsEditPage.role,
              },
            },
          },
        },
      },
      batches: {
        name: "Lotes",
        path: "/fidc/produto/lotes",
        element: <FidcBatches />,
        role: fidcRoles.product.batchesPage.role,
      },
      operations: {
        name: "Operações",
        path: "/fidc/produto/operacoes",
        element: <FidcOperations />,
        role: fidcRoles.product.operationsPage.role,
        children: {
          details: {
            name: "Detalhes operação",
            path: "/fidc/produto/operacoes/detalhes",
            element: <FidcOperationsDetails />,
            role: fidcRoles.product.operationsDetailsPage.role,
          },
        },
      },
      receivables: {
        name: "Recebíveis",
        path: "/fidc/produto/recebiveis",
        element: <FidcReceivable />,
        role: fidcRoles.product.bankslipEditingPage.role,
      },
      disverbation: {
        name: "Desaverbação",
        path: "/fidc/produto/desaverbacao",
        hasSubItems: true,
        children: {
          disverbationApprovals: {
            name: "Aprovação de Desaverbação",
            path: "/fidc/produto/desaverbacao/aprovacao",
            element: <DisverbationApprovals />,
            role: fidcRoles.product.disaverbationApprovalPage.role,
          },
          disverbationHistorys: {
            name: "Histórico de Desaverbação",
            path: "/fidc/produto/desaverbacao/historico",
            element: <DisverbationHistorys />,
            role: fidcRoles.product.disaverbationHistoricPage.role,
          },
        },
      },
      CreditConciliation: {
        name: "Liquidação por Repasse",
        path: "/fidc/produto/liquidacao-repasse",
        hasSubItems: true,
        children: {
          management: {
            name: "Gestão",
            path: "/fidc/produto/liquidacao-repasse/gestao",
            element: <CreditConciliationPage />,
            role: fidcRoles.product.batchesPage.role,
            children: {
              details: {
                name: "Detalhes",
                path: "/fidc/produto/liquidacao-repasse/gestao/detalhes",
                element: <CreditConciliationDetails />,
                role: fidcRoles.product.operationsDetailsPage.role,
              },
            },
          },
          uploadFile: {
            name: "Carregar arquivo",
            path: "/fidc/produto/liquidacao-repasse/upload",
            element: <LoadFileConciliation />,
            role: fidcRoles.product.disaverbationHistoricPage.role,
          },
        },
      },
    },
  },
} as const satisfies Routes;
