import { Roles } from "roles/roles.types";

export const fidcRoles = {
  product: {
    tab: {
      role: "admin:FIDCTabProduct.View",
      description: "Visualização da tab 'FIDC' na visão produto",
    },
    anticipationsPage: {
      role: "admin:FIDCAnticipationsPageProduct.View",
      description: "Visualização da página 'Antecipações' na visão produto",
    },
    consolidatedContractsPage: {
      role: "admin:FIDCConsolidatedContractsPageProduct.View",
      description:
        "Visualização da página 'Contratos Consolidados' na visão produto",
    },
    bankslipEditingPage: {
      role: "admin:FIDCBankslipEditingPageProduct.View",
      description: "Visualização da página 'Edição de boleto' na visão produto",
    },
    bankslipEditingAssetsPage: {
      role: "admin:FIDCBankslipEditingAssetsPageProduct.View",
      description:
        "Visualização da página 'Edição de boleto - Detalhes dos ativos' na visão produto",
    },
    bankslipEditingAssetsDetailsPage: {
      role: "admin:FIDCBankslipEditingAssetsDetailsPageProduct.View",
      description:
        "Visualização da página 'Edição de boleto - Detalhes dos ativos - Detalhes do boleto' na visão produto",
    },
    importPage: {
      role: "admin:FIDCImportPageProduct.View",
      description: "Visualização da página 'Importação' na visão produto",
    },
    interactionsPage: {
      role: "admin:FIDCInteractionsPageProduct.View",
      description: "Visualização da página 'Interações' na visão produto",
    },
    interactionsDetailsPage: {
      role: "admin:FIDCInteractionsDetailsPageProduct.View",
      description:
        "Visualização da página 'Interações - Detalhes' na visão produto",
    },
    interactionsDetailsAddPage: {
      role: "admin:FIDCInteractionsDetailsAddPageProduct.View",
      description:
        "Visualização da página 'Interações - Detalhes - Adição de contato' na visão produto",
    },
    interactionsDetailsEditPage: {
      role: "admin:FIDCInteractionsDetailsEditPageProduct.View",
      description:
        "Visualização da página 'Interações - Detalhes - Edição de contato' na visão produto",
    },
    batchesPage: {
      role: "admin:FIDCBatchesPageProduct.View",
      description: "Visualização da página 'Lotes' na visão produto",
    },
    operationsPage: {
      role: "admin:FIDCOperationsPageProduct.View",
      description: "Visualização da página 'Operações' na visão produto",
    },
    operationsDetailsPage: {
      role: "admin:FIDCOperationsDetailsPageProduct.View",
      description:
        "Visualização da página 'Operações - Detalhes operação' na visão produto",
    },
    receivablesPage: {
      role: "admin:FIDCReceivablesPageProduct.View",
      description: "Visualização da página 'Recebíveis' na visão produto",
    },
    creditConciliationDetailPage: {
      role: "admin:FIDCCreditConciliationDetailPageProduct.View",
      description: "Visualização da página 'Recebíveis' na visão produto",
    },
    disaverbationApprovalPage: {
      role: "admin:FIDCDisverbationApprovalPageProduct.View",
      description: "Visualização da página 'Aprovação de Desaverbação",
    },
    disaverbationHistoricPage: {
      role: "admin:FIDCDisverbationHistoricPageProduct.View",
      description: "Visualização da página 'Hitórico de Desaverbação",
    },
  },
} satisfies Roles;
