import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useCurrencies } from "hooks/currency/currency.hook";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { exchangeOdataPreTicketsGrid } from "../../../components/odata/exchange-odata-pre-tickets.grid";

export const ExchangeProductPreTicketsPage = () => {
  const gridRef = useGridRef();
  const { mapSymbol } = useCurrencies();

  const preTicketGrid = useMemo(
    () => exchangeOdataPreTicketsGrid(mapSymbol),
    [],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Pré-Tickets" />}
      actions={
        <RefreshGridButton
          onClick={() => {
            !preTicketGrid?.datagrid?.dataSource?.isLoading() &&
              preTicketGrid?.datagrid?.dataSource?.reload();
          }}
        />
      }
      gridRef={gridRef}
      showClearFiltersButton
      showExportButton
    >
      <ODataGridGenerator gridRef={gridRef} grid={preTicketGrid} />
    </GridTemplate>
  );
};
