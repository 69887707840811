import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import {
  OCheckbox,
  OLabel,
  ORFieldCheckboxGroup,
  ORFieldInput,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { WorkflowProvider } from "../../../../components/hooks/use-workflow.context";
import {
  addProcessorFormDefaultValues,
  addProcessorFormValidationSchema,
} from "./add-processor-form.schemas";

const AddProcessor = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const form = useForm({
    resolver: yupResolver(addProcessorFormValidationSchema),
    defaultValues: addProcessorFormDefaultValues,
  });
  const { handleSubmit, reset } = form;

  const submitProcessor = useMemo(
    () =>
      handleSubmit(async (values) => {
        try {
          setLoading(true);

          const payload = {
            ...values,
            canBeWfEntry: !!values.canBeWfEntry?.length,
            hasUserAction: true,
          };

          await service.hubCreditManager.createProcessor(payload);

          reset();

          OToastManager.success("Processador salvo com sucesso.");

          navigate(corporateRouter.routes.workflow.product.processors.path);
        } catch {
          OToastManager.danger("Não foi possível salvar o processador.");
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, navigate, reset],
  );

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar processador" />}
      actions={
        <LoadingButton
          loading={loading}
          dataAction="adicionar_processador:botao:enviar"
          dataLabel="enviar"
          onClick={() => submitProcessor()}
        >
          Adicionar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <form spellCheck="false">
            <div className="d-flex flex-column gap-2">
              <ORFieldInput
                id="type"
                name="type"
                tag="text"
                label="Tipo"
                tooltip="Ex.=NOME_PROCESSADOR"
                tooltipPosition="top-right"
                required
                labelSize="md"
                dataAction="adicionar_processador:texto:tipo"
                dataLabel="tipo"
              />
              <ORFieldInput
                id="description"
                name="description"
                tag="text"
                label="Descrição"
                required
                labelSize="md"
                dataAction="adicionar_processador:texto:descricao"
                dataLabel="descricao"
              />
              <ORFieldInput
                id="eventName"
                name="eventName"
                tooltip="Ex.=HubCreditManagerWebApp.Domain.Aggregates.WorkflowAggregate.Events.StartProcessorNotificationEvent"
                tooltipPosition="top-right"
                tag="text"
                label="Nome do evento"
                labelSize="md"
                dataAction="adicionar_processador:texto:nome_evento"
                dataLabel="nome_evento"
              />
            </div>
            <div className="d-flex flex-row gap-2">
              <ORFieldCheckboxGroup
                id="canBeWfEntry"
                name="canBeWfEntry"
                dataAction="adicionar_processador:radio:pode_ser_entrada"
                dataLabel="pode_ser_entrada"
              >
                <div className="d-flex align-items-center gap-2 mb-2">
                  <OCheckbox size="xs" id="wf-entry-checkbox" value="1" />
                  <OLabel htmlFor="wf-entry-checkbox">
                    Pode ser entrada de Workflow?
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
              <ORFieldCheckboxGroup
                id="hasUserAction"
                name="hasUserAction"
                dataAction="adicionar_processador:radio:possui_acao_usuario"
                dataLabel="possui_acao_usuario"
              >
                <div className="d-flex align-items-center gap-2 mb-2">
                  <OCheckbox size="xs" id="wf-action-checkbox" value="1" />
                  <OLabel htmlFor="wf-action-checkbox">
                    Possui ação de usuário?
                  </OLabel>
                </div>
              </ORFieldCheckboxGroup>
            </div>
          </form>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};

export const AddProcessorPage = () => {
  return (
    <WorkflowProvider>
      <AddProcessor />
    </WorkflowProvider>
  );
};
