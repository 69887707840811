import { RedirectWithRoles } from "components/redirect-with-roles";
import { Routes } from "routes/routes.types";
import { AdminCustomerACCContractsPage } from "../pages/cliente/contratos/admin-customer-acc-contracts.page";
import { AdminCustomerACCCounterpartiesDetailsPage } from "../pages/cliente/detalhes/admin-customer-acc-counterparties-details.page";
import { AdminCustomerACCTradesPage } from "../pages/cliente/operacoes";
import { AdminCustomerACCTradesDetailsPage } from "../pages/cliente/operacoes/[id]/admin-customer-acc-trades-details.page";
import { AdminCustomerACCProposalsDetailsPage } from "../pages/cliente/propostas/[id]/admin-customer-acc-proposals-details.page";
import { AdminCustomerACCProposalsPage } from "../pages/cliente/propostas/admin-client-acc-proposals.page";
import { ExchangeProductCommonDomainPage } from "../pages/produto/contingencia/variaveis-configuracao/exchange-product-common-domain.page";
import { AdminProductACCCounterpartiesPage } from "../pages/produto/contrapartes/admin-product-acc-counterparties.page";
import { AdminProductACCTradesPage } from "../pages/produto/operacoes/admin-product-acc-trades.page";
import { AdminProductACCProposalsPage } from "../pages/produto/propostas/admin-product-acc-proposals.page";
import { accRoles } from "../roles/acc.roles";
import { accRouteParams } from "./acc.route-params";
import { ExchangeProductAddCounterpartyPage } from "../pages/produto/contrapartes/adicionar/exchange-product-add-counterparty.page";

export const accRoutes = {
  customer: {
    name: "",
    path: "/acc/cliente",
    element: <RedirectWithRoles />,
    children: {
      proposals: {
        name: "Propostas",
        path: "/acc/cliente/propostas",
        element: <AdminCustomerACCProposalsPage />,
        role: accRoles.customer.proposalsPage.role,
        children: {
          details: {
            name: "Simular",
            path: `/acc/cliente/propostas/:${accRouteParams.id}`,
            element: <AdminCustomerACCProposalsDetailsPage />,
            role: accRoles.customer.proposalsDetailsPage.role,
          },
        },
      },
      details: {
        name: "Detalhes",
        path: "/acc/cliente/detalhes",
        element: <AdminCustomerACCCounterpartiesDetailsPage />,
        role: accRoles.customer.counterpartyDetailsPage.role,
      },
      Contracts: {
        name: "Contratos",
        path: "/acc/cliente/contratos",
        element: <AdminCustomerACCContractsPage />,
        role: accRoles.customer.contractsPage.role,
      },
      quotes: {
        name: "Operações",
        path: "/acc/cliente/operacoes",
        element: <AdminCustomerACCTradesPage />,
        role: accRoles.customer.tradesPage.role,
        children: {
          details: {
            name: "Detalhes",
            path: `/acc/cliente/operacoes/:${accRouteParams.id}`,
            element: <AdminCustomerACCTradesDetailsPage />,
            role: accRoles.customer.tradesDetailsPage.role,
          },
        },
      },
    },
  },
  product: {
    name: "",
    path: "/acc/produto",
    element: <RedirectWithRoles />,
    children: {
      proposals: {
        name: "Propostas",
        path: "/acc/produto/propostas",
        element: <AdminProductACCProposalsPage />,
        role: accRoles.product.proposalsPage.role,
      },
      quotes: {
        name: "Operações",
        path: "/acc/produto/operacoes",
        element: <AdminProductACCTradesPage />,
        role: accRoles.product.tradesPage.role,
      },
      counterparties: {
        name: "Contrapartes",
        path: "/acc/produto/contrapartes",
        element: <AdminProductACCCounterpartiesPage />,
        role: accRoles.product.counterpartiesPage.role,
        children: {
          add: {
            name: "Adicionar",
            path: "/acc/produto/contrapartes/adicionar",
            element: <ExchangeProductAddCounterpartyPage />,
            role: accRoles.product.counterpartiesAddPage.role,
          },
        },
      },
      contingency: {
        name: "Contingência",
        path: "/acc/produto/contingencia",
        hasSubItems: true,
        children: {
          commonDomain: {
            name: "Variáveis de Configuração",
            path: "/acc/produto/contingencia/variavies-configuracao",
            element: <ExchangeProductCommonDomainPage />,
            role: accRoles.product.contingencyCommonDomainPage.role,
          },
        },
      },
    },
  },
} as const satisfies Routes;
