import {
  modalManager,
  OButton,
  OCol,
  OModalBody,
  OModalFooter,
  OModalHeader,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { FormProvider } from "react-hook-form";
import { CheckboxField } from "../../../../../components/checkbox-field";
import { ModalWithSelectOverflow } from "../../../../../components/modal-with-select-overflow";
import { pendencyStatusMap } from "../../../../../utils";
import { addPendencyFormFields } from "./add-pendency-modal.form";
import { useAddPendencyModal } from "./add-pendency-modal.hook";
import { addPendencyModalId } from "./add-pendency-modal.utils";
import {
  ConsumerUnitSelector,
  DiligenceStepPropertySelector,
  DiligenceStepSelector,
  PendencyTypeSelector,
} from "./_compose";

interface AddPendencyModalProps {
  onSubmit: () => void;
}

export const AddPendencyModal = ({ onSubmit }: AddPendencyModalProps) => {
  const {
    diligence,
    diligenceHasError,
    diligenceLoading,
    form,
    getDiligence,
    submit,
    submitLoading,
  } = useAddPendencyModal(onSubmit);

  return (
    <ModalWithSelectOverflow id={addPendencyModalId} position="center">
      <OModalHeader>
        <OTypography size="lg">Adicionar tarefa</OTypography>
      </OModalHeader>
      <OModalBody>
        <FormProvider {...form}>
          <div>
            <ORow>
              <ConsumerUnitSelector />
            </ORow>
            <ORow>
              <DiligenceStepSelector
                diligenceHasError={diligenceHasError}
                diligenceLoading={diligenceLoading}
                diligence={diligence}
                getDiligence={getDiligence}
              />
            </ORow>
            <ORow>
              <DiligenceStepPropertySelector
                diligenceHasError={diligenceHasError}
                diligenceLoading={diligenceLoading}
                diligence={diligence}
              />
            </ORow>
            <ORow>
              <PendencyTypeSelector />
            </ORow>
            <ORow className="w-100">
              <ORFieldSelect {...addPendencyFormFields.status}>
                {Object.entries(pendencyStatusMap).map(([k, v]) => (
                  <OOption key={k} value={k}>
                    {v.text}
                  </OOption>
                ))}
              </ORFieldSelect>
            </ORow>
            <ORow>
              <OCol xs={6}>
                <CheckboxField {...addPendencyFormFields.isPublic} />
              </OCol>
              <OCol xs={6}>
                <CheckboxField {...addPendencyFormFields.hasBpoAction} />
              </OCol>
            </ORow>
            <ORow className="w-100">
              <ORFieldInput {...addPendencyFormFields.message} />
            </ORow>
          </div>
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex gap-3">
          <LoadingButton loading={submitLoading} onClick={submit}>
            Salvar
          </LoadingButton>
          <OButton
            type="dark"
            outline
            onClick={() => modalManager.hide(addPendencyModalId)}
          >
            Cancelar
          </OButton>
        </div>
      </OModalFooter>
    </ModalWithSelectOverflow>
  );
};
