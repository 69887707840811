import { OToastManager, modalManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { isAxiosError } from "axios";
import { dataSourceCustomStoreGenerator } from "components/data-grid";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  PostAdminReceivablesSummaryBody,
  ReceivablesItem,
} from "services/quickfin/models";
import { service } from "services/service";
import { logger } from "utils/logger";
import { newFilterDefaultValues } from "./note-filter.form";
import {
  bodyInitialValue,
  buildPayload,
  noteFilterModalId,
} from "./note-filter.utils";

export const useNoteFilter = () => {
  const [body, setBody] = useState<PostAdminReceivablesSummaryBody>();
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(1);
  const form = useForm({
    defaultValues: newFilterDefaultValues,
  });
  const { handleSubmit, reset } = form;

  const {
    callService: getSummary,
    value: summary,
    loading: loadingSummary,
  } = useServiceCall(service.quickfin.getReceivablesSummary);

  useEffect(() => {
    getSummary(bodyInitialValue);
  }, [getSummary]);

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<ReceivablesItem>(() => {
        return service.quickfin
          .getReceivablesTitles({
            ...(body ?? bodyInitialValue),
            page,
            rows,
          })
          .then(({ data }) => data.receivables)
          .catch((err) => {
            if (!isAxiosError(err)) logger.error(err);
            const errorMessage = "Erro ao buscar títulos";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          });
      }),
    [body, page, rows],
  );

  const resetFilter = () => {
    const parsedInitialBody = buildPayload(newFilterDefaultValues);
    reset(newFilterDefaultValues);

    setPage(1);
    setBody(parsedInitialBody);
    getSummary(parsedInitialBody);
    dataSource.reload();
    modalManager.hide(noteFilterModalId);
  };

  const onSubmit = handleSubmit(async (values) => {
    const payload = buildPayload(values);
    setPage(1);
    getSummary(payload);
    setBody(payload);
    modalManager.hide(noteFilterModalId);
  });

  const refetchData = () => {
    getSummary(body ?? buildPayload(newFilterDefaultValues));
    dataSource.reload();
  };

  const exportAllData = useCallback(async () => {
    try {
      const { data: blob } = await service.quickfin.exportAllNotes(
        body ?? buildPayload(newFilterDefaultValues),
      );

      helpers.downloadBlobFile("recebiveis_nota_a_nota.xlsx", blob);
    } catch {
      OToastManager.danger("Não foi possível baixar o arquivo.");
    }
  }, [body]);

  return {
    dataSource,
    exportAllData,
    form,
    loadingSummary,
    onSubmit,
    page,
    refetchData,
    resetFilter,
    rows,
    setPage,
    setRows,
    summary,
  };
};
