import { OToastManager, modalManager } from "@maestro/core";
import { helpers } from "@maestro/utils";
import { isAxiosError } from "axios";
import { useServiceCall } from "hooks/service-call";
import { useForm } from "react-hook-form";
import {
  PostAdminReceivablesSummaryBody,
  PostAdminReceivablesSummaryResponse,
  ReceivablesItem,
} from "services/quickfin/models";
import { service } from "services/service";
import { logger } from "utils/logger";
import { useCallback, useEffect, useState } from "react";
import {
  buildPayload,
  duplicatasNoteFilterModalId,
  newDuplicatasFilterDefaultValues,
} from "./duplicatas-note-filter.utils";

export const useDuplicatasNote = (documentNumber: string) => {
  const [body, setBody] = useState<PostAdminReceivablesSummaryBody>();
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(1);
  const [cedenteId, setCedenteId] = useState("");
  const [summary, setSummary] = useState<PostAdminReceivablesSummaryResponse>();
  const [dataSource, setDataSource] = useState<ReceivablesItem[]>();

  const form = useForm({
    defaultValues: newDuplicatasFilterDefaultValues,
  });
  const { handleSubmit, reset } = form;

  const { callService: getReceivablesSummary, loading: loadingSummary } =
    useServiceCall(service.quickfin.getReceivablesSummary);

  const { callService: getCompanyByTaxId } = useServiceCall(
    service.quickfin.getCompanyByTaxId,
  );

  const getCompany = useCallback(async () => {
    const data = await getCompanyByTaxId(documentNumber);
    return data.response;
  }, [documentNumber, getCompanyByTaxId]);

  const getSummary = useCallback(
    async (
      companyId: string,
      body?: PostAdminReceivablesSummaryBody | null,
    ) => {
      const data = await getReceivablesSummary(
        body ?? buildPayload(newDuplicatasFilterDefaultValues, companyId),
      );
      if (!data?.response?.data) return;
      setSummary(data.response.data);
    },
    [],
  );

  const getDataSource = useCallback(
    async (
      companyId: string,
      body?: PostAdminReceivablesSummaryBody | null,
    ) => {
      if (companyId === "") return;
      service.quickfin
        .getReceivablesTitles({
          ...(body ??
            buildPayload(newDuplicatasFilterDefaultValues, companyId)),
          page,
          rows,
        })
        .then(({ data }) => setDataSource(data.receivables))
        .catch((err) => {
          if (!isAxiosError(err)) logger.error(err);
          const errorMessage = "Erro ao buscar títulos";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        });
    },
    [body, page, rows],
  );

  const resetFilter = () => {
    const parsedInitialBody = buildPayload(
      newDuplicatasFilterDefaultValues,
      cedenteId,
    );
    reset(newDuplicatasFilterDefaultValues);

    setPage(1);
    setBody(parsedInitialBody);
    getSummary(cedenteId, parsedInitialBody);
    getDataSource(cedenteId, parsedInitialBody);
    modalManager.hide(duplicatasNoteFilterModalId);
  };

  const onSubmit = handleSubmit(async (values) => {
    const payload = buildPayload(values, cedenteId);
    setPage(1);
    setBody(payload);
    getSummary(cedenteId, payload);
    getDataSource(cedenteId, payload);
    modalManager.hide(duplicatasNoteFilterModalId);
  });

  const refetchData = () => {
    getSummary(cedenteId, body);
    getDataSource(cedenteId, body);
  };

  const exportAllData = useCallback(async () => {
    try {
      if (cedenteId) {
        const { data: blob } = await service.quickfin.exportAllNotes(
          body ?? buildPayload(newDuplicatasFilterDefaultValues, cedenteId),
        );
        helpers.downloadBlobFile("recebiveis_nota_a_nota.xlsx", blob);
      } else {
        const company = await getCompany();
        const { data: blob } = await service.quickfin.exportAllNotes(
          body ??
            buildPayload(
              newDuplicatasFilterDefaultValues,
              company?.data?.id?.toString() ?? "",
            ),
        );
        helpers.downloadBlobFile("recebiveis_nota_a_nota.xlsx", blob);
      }
    } catch {
      OToastManager.danger("Não foi possível baixar o arquivo.");
    }
  }, [body]);

  useEffect(() => {
    const fetchSummary = async () => {
      const company = await getCompany();
      if (!company?.data) return;
      const companyId = company.data.id.toString();
      setCedenteId(companyId);
      await getSummary(companyId);
      getDataSource(companyId);
    };

    fetchSummary();
  }, []);

  useEffect(() => {
    getDataSource(cedenteId, body);
  }, [rows, page]);

  return {
    dataSource,
    exportAllData,
    form,
    loadingSummary,
    onSubmit,
    page,
    refetchData,
    resetFilter,
    rows,
    setPage,
    setRows,
    summary,
  };
};
