import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { getValidationMessages } from "../../../../../utils";
import {
  PropertyTypeForm,
  propertyTypeFormDefaultValues,
  propertyTypeFormValidationSchema,
} from "../_compose";

export const useAddPropertyType = () => {
  const { callService, loading } = useServiceCall(
    service.hubEnergy.createDiligenceStepPropertyType,
  );

  const navigate = useNavigate();

  const form = useForm<PropertyTypeForm>({
    defaultValues: propertyTypeFormDefaultValues,
    resolver: yupResolver(propertyTypeFormValidationSchema),
  });

  const { handleSubmit } = form;

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, error } = await callService({
          label: values.label,
          mask: values.mask,
          name: values.name,
        });

        if (success) {
          OToastManager.success("Tipo de propriedade salvo");
          navigate(
            corporateRouter.routes.energia.product.diligenceConfig.propertyTypes
              .path,
          );
        } else {
          OToastManager.danger(
            getValidationMessages(error)?.[0].ErrorMessage ??
              "Erro ao salvar tipo de propriedade",
          );
        }
      }),
    [callService, handleSubmit, navigate],
  );

  return {
    form,
    loading,
    submit,
  };
};
