import { OToastManager, OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import {
  calculateNameAndTaxIdFilterExpression,
  NameAndTaxIdFormatted,
} from "components/name-and-tax-id-formatted";
import { auth } from "contexts/auth";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import { service } from "services";
import { hubEnergyEndpoints } from "services/hubenergy/hubenergy.endpoints";
import { getValueFromMap } from "utils/get-value-from-map";
import { fastCpfCnpjMask } from "utils/mask/fast-cpf-cnpj-mask";
import { clearSearchParam } from "utils/search-params/clear-search-param";
import { getSearchParams } from "utils/search-params/get-search-params";
import { gridStorage } from "utils/storage";
import { MappedStatusBadge } from "../../../components/mapped-status-badge";
import { PendencyNotesMasterDetail } from "../../../components/pendency-notes";
import { energiaRoles } from "../../../roles/energia.roles";
import {
  configureHeaderFilterODataGroupBy,
  disableHeaderFilterPagination,
  getValidationMessages,
  odataDateFilterWorkaround,
  pendencyAutomationStatusMap,
  pendencyStatusMap,
} from "../../../utils";
import { GridActions, TextWithTooltip } from "./_compose";

const buildOdataStore = (
  paramsCallback?: (params: Record<string, string | undefined>) => void,
) =>
  new ODataStore<HubEnergy.DiligenceTaskODataResponse>({
    url: hubEnergyEndpoints.odata.diligenceTasks,
    version: 4,
    beforeSend: (config) => {
      // eslint-disable-next-line no-param-reassign
      config.headers = {
        Authorization: auth.value,
        ...config.headers,
      };
      odataDateFilterWorkaround(config.params);
      paramsCallback?.(config.params);
      return config;
    },
  });

const buildDataSource = (
  paramsCallback?: (params: Record<string, string | undefined>) => void,
) => {
  const odataStore = buildOdataStore(paramsCallback);

  return new DataSource<HubEnergy.DiligenceTaskODataResponse>({
    store: new CustomStore<HubEnergy.DiligenceTaskODataResponse>({
      load: (options) => odataStore.load(options),
      byKey: (key) => odataStore.byKey(key),
      update: async (original, updated) => {
        try {
          const newData = { ...original, ...updated };

          await service.hubEnergy.updatePendencyStatus({
            diligenceStepPendencyId: newData.pendencyId,
            status: newData.pendencyStatus,
          });

          OToastManager.success("Pendência editada com sucesso.");

          return newData;
        } catch (error) {
          const errorMessage =
            getValidationMessages(error)?.[0]?.ErrorMessage ??
            "Erro ao editar a pendência";

          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }
      },
    }),
  });
};

export const dataSource = buildDataSource();

export const energyTasksGrid: ODataGridGeneratorConfig<HubEnergy.DiligenceTaskODataResponse> =
  {
    datagrid: {
      noDataText: "Nenhuma tarefa encontrada.",
      headerFilter: { visible: true },
      filterRow: { visible: true },
      pager: { showPageSizeSelector: true },
      stateStoring: gridStorage("energyTasksGrid"),
      // onRowPrepared: highlightNearExpirationDate,
      onEditorPreparing: (e) => {
        if (e.parentType === "dataRow")
          e.editorOptions = {
            ...e.editorOptions,
            dropDownOptions: {
              ...e.editorOptions?.dropDownOptions,
              minWidth: 400,
            },
            itemTemplate: (itemData, _, itemElement) => {
              (itemElement as HTMLElement).setAttribute(
                "title",
                itemData.label,
              );
              return itemData.label;
            },
          };
      },
      onContentReady: (e) => {
        const searchParams = getSearchParams<{ pendencyId: string }>();
        if (searchParams.pendencyId) {
          e.component.filter([
            "pendencyId",
            "=",
            Number(searchParams.pendencyId),
          ]);
          clearSearchParam("pendencyId");
        }
      },
      remoteOperations: true,
      columnAutoWidth: false,
      columnResizingMode: "nextColumn",
      syncLookupFilterValues: false,
    },
    columns: [
      {
        dataField: "consumerUnitOfficialName",
        dataType: "string",
        caption: "Unidade consumidora",
        calculateFilterExpression: calculateNameAndTaxIdFilterExpression(
          ["consumerUnitOfficialName", "consumerUnitInstallationNumber"],
          "consumerUnitTaxId",
        ),
        cellRender: ({ data }) => (
          <NameAndTaxIdFormatted
            name={data.consumerUnitOfficialName}
            subtexts={[
              masks.cpfCnpj(data.consumerUnitTaxId),
              `Nº instalação: ${data.consumerUnitInstallationNumber}`,
            ]}
          />
        ),
        headerFilter:
          configureHeaderFilterODataGroupBy<HubEnergy.DiligenceTaskODataResponse>()(
            [
              "consumerUnitOfficialName",
              "consumerUnitTaxId",
              "consumerUnitInstallationNumber",
            ],
            (adjustParams) => buildDataSource(adjustParams),
            (officialName, taxId, installationNumber) =>
              `${officialName} - ${fastCpfCnpjMask(
                taxId,
              )} - nº inst ${installationNumber}`,
          ),
        allowSorting: true,
        allowEditing: false,
        minWidth: 180,
      },
      {
        dataField: "stepTypeName",
        dataType: "string",
        caption: "Etapa",
        calculateFilterExpression: calculateNameAndTaxIdFilterExpression(
          ["stepTypeName", "stepTypeDescription"],
          [],
        ),
        cellRender: ({ data }) => (
          <TextWithTooltip
            text={data.stepTypeName}
            tooltip={data.stepTypeDescription}
          />
        ),
        headerFilter:
          configureHeaderFilterODataGroupBy<HubEnergy.DiligenceTaskODataResponse>()(
            ["stepTypeName"],
            (adjustParams) => buildDataSource(adjustParams),
          ),
        allowEditing: false,
        minWidth: 180,
      },
      {
        dataField: "pendencyTypeName",
        dataType: "string",
        caption: "Pendência",
        calculateFilterExpression: calculateNameAndTaxIdFilterExpression(
          ["pendencyTypeName", "pendencyTypeDescription", "pendencyMessage"],
          [],
        ),
        cellRender: ({ data }) => (
          <div>
            <TextWithTooltip
              text={data.pendencyTypeName}
              tooltip={data.pendencyTypeDescription}
            />
            {data.pendencyMessage && (
              <OTypography
                style={{ whiteSpace: "normal" }}
                size="sm"
                type="dark-80"
              >
                {data.pendencyMessage}
              </OTypography>
            )}
          </div>
        ),
        headerFilter:
          configureHeaderFilterODataGroupBy<HubEnergy.DiligenceTaskODataResponse>()(
            ["pendencyTypeName"],
            (adjustParams) => buildDataSource(adjustParams),
          ),
        allowEditing: false,
        minWidth: 180,
      },
      {
        dataField: "pendencyStatus",
        dataType: "string",
        caption: "Status",
        alignment: "center",
        cellRender: ({ data }) => (
          <MappedStatusBadge
            map={pendencyStatusMap}
            status={data.pendencyStatus}
          />
        ),
        headerFilter:
          configureHeaderFilterODataGroupBy<HubEnergy.DiligenceTaskODataResponse>()(
            ["pendencyStatus"],
            (adjustParams) => buildDataSource(adjustParams),
            (status) =>
              getValueFromMap(pendencyStatusMap, status)?.text ?? status,
          ),
        // lookup: {
        //   dataSource: Object.entries(pendencyStatusMap).map(([key, value]) => ({
        //     value: key,
        //     label: value.text,
        //   })),
        //   displayExpr: "label",
        //   valueExpr: "value",
        // },
        allowEditing: true,
        width: "auto",
      },
      {
        caption: "Automação",
        dataField: "pendencyAutomationStatus",
        dataType: "string",
        cellRender: ({ data }) =>
          data.pendencyAutomationStatus ? (
            <MappedStatusBadge
              map={pendencyAutomationStatusMap}
              status={data.pendencyAutomationStatus}
            />
          ) : (
            "-"
          ),
        headerFilter:
          configureHeaderFilterODataGroupBy<HubEnergy.DiligenceTaskODataResponse>()(
            ["pendencyAutomationStatus"],
            (adjustParams) => buildDataSource(adjustParams),
            (automationStatus) =>
              getValueFromMap(pendencyAutomationStatusMap, automationStatus)
                ?.text ??
              automationStatus ??
              "(Sem automação)",
          ),
        allowEditing: false,
        width: "auto",
      },
      {
        dataField: "stepExpirationDate",
        dataType: "date",
        format: "shortDate",
        caption: "Dt limite",
        sortOrder: "asc",
        headerFilter: { dataSource: disableHeaderFilterPagination },
        allowEditing: false,
        width: "auto",
      },
      {
        dataField: "consumerUnitMigrationDate",
        dataType: "date",
        format: "shortDate",
        caption: "Dt migração",
        headerFilter: { dataSource: disableHeaderFilterPagination },
        allowEditing: false,
        width: "auto",
      },
      {
        dataField: "pendencyId",
        role: energiaRoles.product.taskActions.role,
        cellRender: ({ data, component, rowIndex, row }) => (
          <GridActions
            task={data}
            component={component}
            rowIndex={rowIndex}
            isEditing={!!row.isEditing}
          />
        ),
        caption: "",
        allowFiltering: true,
        allowHeaderFiltering: false,
        allowSorting: true,
        allowEditing: false,
        width: "auto",
        alignment: "left",
      },
    ],
    masterDetail: {
      enabled: true,
      component: ({ data }) => (
        <PendencyNotesMasterDetail
          pendencyId={data.data.pendencyId}
          notes={data.data.notes}
        />
      ),
    },
    selection: {
      allowSelectAll: true,
      mode: "multiple",
      selectAllMode: "allPages",
      showCheckBoxesMode: "always",
    },
  };
