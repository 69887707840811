export const env = {
  NODE_ENV: process.env.NODE_ENV,
  PROJECT_ENV: process.env.REACT_APP_PROJECT_ENV,

  // apis
  API: process.env.REACT_APP_API,
  API_ASSETS: process.env.REACT_APP_ASSETS,
  API_B2B: process.env.REACT_APP_API_B2B,
  API_B2B_AGRO: process.env.REACT_APP_API_B2B_AGRO,
  API_B2B_CORE: process.env.REACT_APP_API_B2B_CORE,
  API_BANKINGHUB: process.env.REACT_APP_API_BANKINGHUB,
  API_BANKINGHUB_ADMIN: process.env.REACT_APP_URL_BANKINGADMIN,
  API_CLERK: process.env.REACT_APP_API_CLERK,
  API_COLLECTION: process.env.REACT_APP_API_HUBCOLLECTION,
  API_CREDIT_MANAGER: process.env.REACT_APP_API_CREDIT_MANAGER,
  API_CUSTOMER: process.env.REACT_APP_API_CUSTOMER,
  API_ELIGIBILITYENGINE: process.env.REACT_APP_CARDS_OFFER_ENGINE_URL,
  API_ENERGY: process.env.REACT_APP_ENERGY_URL,
  API_FIDC: process.env.REACT_APP_API_FIDC,
  API_FIDC_BANKSLIP_MANAGER: process.env.REACT_APP_FIDC_BANKSLIP_MANAGER_URL,
  API_FX: process.env.REACT_APP_API_FX,
  API_ACC: process.env.REACT_APP_API_TRADE_FINANCE,
  API_GATEHOLDER: process.env.REACT_APP_API_GATEHOLDER,
  API_GATEKEEPER: process.env.REACT_APP_API_GATEKEEPER,
  API_HUBENERGY: process.env.REACT_APP_API_HUBENERGY,
  API_LEGACYS: process.env.REACT_APP_API_LEGACYS,
  API_LIMIT_CONTROL: process.env.REACT_API_LIMIT_CONTROL,
  API_LOAN: process.env.REACT_APP_API_LOAN,
  API_MOANA: process.env.REACT_APP_API_MOANA,
  API_ONBOARDING: process.env.REACT_APP_API_ONBOARDING,
  API_ONBOARDING_CORE_ACCOUNT:
    process.env.REACT_APP_API_ONBOARDING_CORE_ACCOUNT,
  API_PAPERCLIPV2: process.env.REACT_APP_API_PAPERCLIPV2,
  API_PARTNERS: process.env.REACT_APP_API_HUBPARTNERS,
  API_QUICKFIN: process.env.REACT_APP_API_URL,
  API_RAVEN: process.env.REACT_APP_API_RAVEN,
  API_RECEBIVEIS_CARTAO: process.env.REACT_APP_RECEBIVEIS_CARTAO_URL,
  API_RECONCILIERO: process.env.REACT_APP_API_RECONCILIERO,
  API_RELATIONSHIPS: process.env.REACT_APP_API_RELATIONSHIPS,
  API_RENEGOTIATION: process.env.REACT_APP_API_RENEGOTIATION,
  API_ROADWALKER: process.env.REACT_APP_API_ROADWALKER,
  API_SCF_DEVELOPERS: process.env.REACT_APP_API_SCF_DEVELOPERS,
  API_SENNA: process.env.REACT_APP_SENNA,
  API_SHARK: process.env.REACT_APP_API_RENEGOTIATION,
  API_TURTLEFEE: process.env.REACT_APP_API_TURTLE_FEE,
  ENERGY_LEADS_URL: process.env.REACT_APP_ENERGY_LEADS_URL,
  API_DEVELOPERS: process.env.REACT_API_DEVELOPERS,

  // cognito
  AMAZON_COGNITO_DOMAIN: process.env.REACT_APP_AMAZON_COGNITO_DOMAIN,
  AMAZON_COGNITO_PROVIDER: process.env.REACT_APP_AMAZON_COGNITO_PROVIDER,
  OAUTH_REDIRECT_SIGN: process.env.REACT_APP_OAUTH_REDIRECT_SIGN_CORPORATE,
  OAUTH_REDIRECT_SIGNOUT:
    process.env.REACT_APP_OAUTH_REDIRECT_SIGNOUT_CORPORATE,
  USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
  USER_POOL_WEB_CLIENT_ID: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,

  // other
  EMBEDDED_URL: process.env.REACT_APP_PORTAL_EMBEDDED_URL,
  PORTAL_B2B: process.env.REACT_APP_PORTAL_B2B,
  PORTAL_CORE_FRONT_URL: process.env.REACT_APP_PORTAL_CORE_FRONT_URL,
  PORTAL_RMADMINE: process.env.REACT_APP_PORTAL_RMADMINE,
  LATEST_TAG: process.env.REACT_APP_LATEST_TAG,

  // links
  WIKI_LINK: process.env.REACT_APP_WIKI_LINK,
  LEGACY_ADMIN_LINK: process.env.REACT_APP_LEGACY_ADMIN_URL,

  // rmadmin sso
  OIDC_CLIENT_ID: process.env.REACT_APP_OIDC_CLIENT_ID,
  OIDC_AUTHORITY: process.env.REACT_APP_OIDC_AUTHORITY,
  OIDC_REDIRECT_URI: process.env.REACT_APP_OIDC_REDIRECT_URI,
};
