import * as yup from "yup";

export type AddPartnershipTypeFormFields = Omit<
  HubPartners.Admin.PartnershipType,
  "uuid"
>;

export const addPartnershipTypeSchema: yup.ObjectSchema<AddPartnershipTypeFormFields> =
  yup.object({
    name: yup.string().required("Este campo é obrigatório"),
    code: yup.string().required("Este campo é obrigatório"),
    masterGatekeeperProfiles: yup.array().default([]),
    restrictedGatekeeperProfiles: yup.array().default([]),
    segmentFilterCode: yup.string().required("Este campo é obrigatório"),
  });
