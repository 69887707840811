import moment from "moment";
import { NotesFilterForm } from "./note-filter.types";

export const newFilterDefaultValues: NotesFilterForm = {
  sacadoIdList: [],
  cedenteIdList: [],
  tipoProdutoList: [
    "RiscoSacado",
    "RiscoCedente",
    "Offshore",
    "FundingProprio",
    "CCC",
  ],
  currentStateList: [],
  receivableIdentification: [],
  externalIds: [],
  contractNumbers: [],
  strategyGuids: [],
  receivableId: [],
  tradeId: [],
  borderoId: [],
  operationId: [],
  creditorIds: [],
  marketplaceIds: [],
  portfolioIds: [],
  tradeDate: null,
  disbursementDate: null,
  maturityDate: null,
  createdAt: null,
  updatedAt: [moment(), moment()],
};
