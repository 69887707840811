import { OToastManager } from "@maestro/core";
import dayjs from "dayjs";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { client } from "services/client";
import { endpoints } from "services/endpoints";
import { GetApiAdminLoanByIdDetailsResponse } from "services/hubloan/models";
import {
  SharkAmortizationType,
  SharkSettlementData,
} from "services/shark/models/responses";
import { EmprestimosClienteOperacoesById } from "../../../../../routes/emprestimos.route-params";
import { installmentsFromSharkStatus } from "./operations-installments.utils";

export interface OperationsInstallmentsContextProps {
  getLoanDetails: () => Promise<void>;
  getSharkSettlement: (contractNumber: string) => Promise<void>;
  loanDetails?: GetApiAdminLoanByIdDetailsResponse;
  sharkSettlement?: SharkSettlementData;
  installmentsMap: Record<SharkAmortizationType, number[]>;
  checkedInstallmentsMap: Record<SharkAmortizationType, Set<number>>;
  refresh: () => Promise<void>;
  setCheckedInstallmentsMap: React.Dispatch<
    React.SetStateAction<Record<SharkAmortizationType, Set<number>>>
  >;
  loading: boolean;
  canAnticipate: boolean;
}

export const OperationsInstallmentsContext =
  createContext<OperationsInstallmentsContextProps>(
    {} as OperationsInstallmentsContextProps,
  );

export interface DefaultInstallmentsGridProviderProps {
  children?: React.ReactNode;
}

export const OperationsInstallmentsProvider = ({
  children,
}: DefaultInstallmentsGridProviderProps) => {
  const { id } = useParams<EmprestimosClienteOperacoesById>();
  if (!id) throw new Error("No id");

  const [loanDetails, setLoanDetails] =
    useState<GetApiAdminLoanByIdDetailsResponse>();
  const [sharkSettlement, setSharkSettlement] = useState<SharkSettlementData>();
  const [installmentsMap, setInstallmentsMap] = useState<
    Record<SharkAmortizationType, number[]>
  >({} as Record<SharkAmortizationType, number[]>);
  const [checkedInstallmentsMap, setCheckedInstallmentsMap] = useState<
    Record<SharkAmortizationType, Set<number>>
  >({} as Record<SharkAmortizationType, Set<number>>);
  const [loading, setLoading] = useState<boolean>(true);

  const getLoanDetails = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await client.get<GetApiAdminLoanByIdDetailsResponse>(
        endpoints.hubLoan.admin.loan.byIdDetails(id),
      );

      setLoanDetails(data);
    } catch (err) {
      OToastManager.danger("Não foi possível carregar os dados do empréstimo");
    } finally {
      setLoading(false);
    }
  }, [id]);

  const getSharkSettlement = useCallback(async (loanContractNumber: string) => {
    setLoading(true);

    try {
      const { data } = await client.get<SharkSettlementData>(
        endpoints.shark.v1.prepayments.getSettlement(loanContractNumber),
        {
          params: {
            contract_origin: "PME",
          },
        },
      );

      setSharkSettlement(data);
    } catch (e) {
      OToastManager.danger(
        "Um erro ocorreu ao tentarmos buscar os dados das parcelas.",
      );
    } finally {
      setLoading(false);
    }
  }, []);

  const canAnticipate = useMemo(() => {
    return (
      loanDetails?.status === "Desembolsado" &&
      loanDetails?.currency === "BRL" &&
      !!sharkSettlement?.installments.length &&
      dayjs(sharkSettlement.installments[0].maturity_date).diff(
        dayjs().startOf("day"),
        "days",
      ) > 0
    );
  }, [sharkSettlement, loanDetails]);

  const refresh = useCallback(() => {
    if (!loanDetails) {
      return getLoanDetails();
    }
    return getSharkSettlement(loanDetails?.contract.number);
  }, [getLoanDetails, getSharkSettlement, loanDetails]);

  useEffect(() => {
    if (id) {
      getLoanDetails();
    }
  }, [id, getLoanDetails]);

  useEffect(() => {
    if (
      !loanDetails?.contract ||
      !installmentsFromSharkStatus.includes(loanDetails?.status)
    )
      return;

    getSharkSettlement(loanDetails.contract.number);
  }, [getSharkSettlement, loanDetails]);

  useEffect(() => {
    if (!sharkSettlement) return;

    const installmentsMapAux = {} as Record<SharkAmortizationType, number[]>;
    const checkedInstallmentsMapAux = {} as Record<
      SharkAmortizationType,
      Set<number>
    >;

    sharkSettlement.agreement.agreement_prepayments_types.forEach(
      (agreementPrepaymentType) => {
        const amortizationFrequency =
          agreementPrepaymentType.amortization_frequency;

        checkedInstallmentsMapAux[amortizationFrequency] = new Set<number>();

        const installments = sharkSettlement.installments
          .filter(
            (installment) =>
              installment.amortization_frequency === amortizationFrequency,
          )
          .map((installment) => installment.installment_number);

        const ascendingOrder = ["ANY", "ASC"].includes(
          agreementPrepaymentType.prepayments_config.prepayment_allowed_order,
        );

        installmentsMapAux[amortizationFrequency] = installments.sort((a, b) =>
          ascendingOrder ? a - b : b - a,
        );
      },
    );

    setInstallmentsMap(installmentsMapAux);
    setCheckedInstallmentsMap(checkedInstallmentsMapAux);

    setLoading(false);
  }, [sharkSettlement]);

  const OperationsInstallmentsContextProvider = useMemo(() => {
    return (
      <OperationsInstallmentsContext.Provider
        value={{
          loading,
          loanDetails,
          getLoanDetails,
          getSharkSettlement,
          sharkSettlement,
          checkedInstallmentsMap,
          installmentsMap,
          refresh,
          setCheckedInstallmentsMap,
          canAnticipate,
        }}
      >
        {children}
      </OperationsInstallmentsContext.Provider>
    );
  }, [
    canAnticipate,
    checkedInstallmentsMap,
    children,
    getLoanDetails,
    getSharkSettlement,
    refresh,
    installmentsMap,
    loading,
    loanDetails,
    sharkSettlement,
  ]);

  return OperationsInstallmentsContextProvider;
};

export const useOperationsInstallments = () =>
  useContext(OperationsInstallmentsContext);
