import { yupResolver } from "@hookform/resolvers/yup";
import { modalManager, OToastManager } from "@maestro/core";
import {
  OButton,
  OCol,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORFieldInput,
  ORow,
  OTypography,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import * as yup from "yup";
import { getValidationMessages } from "../../../../../../../../../../utils";
import { useStepAnalysisContext } from "../../../../../../step-analysis.context";

export const escalateConfirmationModalId = (pendencyId: number) =>
  `escalate-confirmation-modal-${pendencyId}`;

interface EscalatePendencyForm {
  reason: string;
}

const escalatePendencyFormDefaultValues = {
  reason: null,
} as unknown as EscalatePendencyForm;

const escalatePendencyFormValidationSchema = yup.object({
  reason: yup
    .string()
    .typeError("É necessário informar um motivo")
    .required("É necessário informar um motivo"),
});

interface EscalateConfirmationModalProps {
  pendency: HubEnergy.DiligenceStepPendencySimpleResponse;
}

export const EscalateConfirmationModal = ({
  pendency,
}: EscalateConfirmationModalProps) => {
  const modalId = escalateConfirmationModalId(pendency.id);

  const { stepAnalysis, setStepAnalysis } = useStepAnalysisContext();

  const { callService, loading } = useServiceCall(
    service.hubEnergy.stepAnalysisEscalatePendency,
  );

  const form = useForm({
    defaultValues: escalatePendencyFormDefaultValues,
    resolver: yupResolver(escalatePendencyFormValidationSchema),
  });

  const { handleSubmit, reset } = form;

  const submit = useMemo(
    () =>
      handleSubmit(async (values) => {
        const { success, response, error } = await callService({
          diligenceStepAnalysisId: stepAnalysis.id,
          diligenceStepPendencyId: pendency.id,
          reason: values.reason,
        });

        if (success) {
          OToastManager.success("Pendência escalada com sucesso");
          setStepAnalysis(response.data.response);
          modalManager.hide(modalId);
        } else {
          OToastManager.danger(
            getValidationMessages(error)?.[0].ErrorMessage ??
              "Erro ao escalar a pendência",
          );
        }
      }),
    [
      callService,
      handleSubmit,
      modalId,
      pendency.id,
      setStepAnalysis,
      stepAnalysis.id,
    ],
  );

  useEffect(() => {
    const cleanup = modalManager.on(modalId, "modalClose", () => {
      reset(escalatePendencyFormDefaultValues);
    });
    return cleanup;
  }, [modalId, reset]);

  return (
    <OModal
      id={modalId}
      style={{ position: "absolute" }}
      position="center"
      backdrop={!loading}
    >
      <OModalHeader>
        <OTypography size="lg">Escalar pendência?</OTypography>
      </OModalHeader>
      <OModalBody>
        <OTypography className="mb-4">
          Certeza que deseja escalar a pendência <em>{pendency.type.label}</em>?
        </OTypography>
        <FormProvider {...form}>
          <ORow>
            <OCol xs={12} md={6}>
              <ORFieldInput
                tag="text"
                id="reason"
                name="reason"
                label="Motivo"
                placeholder="Preencher"
              />
            </OCol>
          </ORow>
        </FormProvider>
      </OModalBody>
      <OModalFooter>
        <div className="d-flex justify-content-end gap-3">
          <OButton
            type="dark"
            outline
            onClick={() => modalManager.hide(modalId)}
            disabled={loading}
          >
            Cancelar
          </OButton>
          <LoadingButton loading={loading} onClick={submit}>
            Enviar
          </LoadingButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
