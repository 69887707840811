import { cases, masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";

export const buildGuarantorDetailsFields = (
  guarantor: HubOnboardingRelationships.Guarantor.Guarantor,
): DetailsFields => ({
  info: {
    items: [
      {
        label: "Nome",
        value: cases.title(guarantor.name ?? ""),
        grid: { md: 4, xl: 3 },
      },
      {
        label: "CPF",
        value: masks.cpf(guarantor.taxId),
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Tipo",
        value: guarantor.type,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Status",
        value: guarantor.portalStatus,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Biometria",
        value: guarantor.biometryStatusType,
        grid: { md: 4, xl: 3 },
      },
    ],
  },
  contact: {
    title: "Contato",
    items: [
      {
        label: "Telefone",
        value: masks.phone(guarantor.phoneNumber),
        grid: { md: 4, xl: 3 },
      },
      {
        label: "E-mail",
        value: guarantor.email,
        grid: { md: 4, xl: 6 },
      },
    ],
  },
});
