import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import type { UseServiceCallReturn } from "hooks/service-call";
import { service } from "services";
import { gridStorage } from "utils/storage";
import {
  diligenceStepStatusMap,
  getValidationMessages,
} from "../../../../../../../utils";

export const buildDataSource = (
  diligenceStepConfigId: number,
  load: UseServiceCallReturn<
    typeof service.hubEnergy.getDiligenceStepPendencyConfigs
  >["callService"],
) =>
  dataSourceCustomStoreGenerator<HubEnergy.DiligenceStepPendencyConfigResponse>(
    () =>
      load(diligenceStepConfigId)
        .then(({ success, error, response }) => {
          if (success) return response.data.response;
          throw error;
        })
        .catch((err) => {
          const errorMessage =
            getValidationMessages(err)?.[0].ErrorMessage ??
            "Erro ao buscar as pendências";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }),
    {
      customStoreOptions: {
        insert: async (values) => {
          try {
            const { data } =
              await service.hubEnergy.upsertDiligenceStepPendencyConfig({
                diligenceStepConfigId,
                isAutomation: values.isAutomation,
                hasBpoAction: values.hasBpoAction,
                activateOnStepStatus: values.activateOnStepStatus,
                typeId: values.typeId,
                message: values.message,
                propertyConfigId: values.propertyConfigId,
                dependencyId: values.dependencyId,
              });
            OToastManager.success("Pendência adicionada");
            return data.response;
          } catch (err) {
            const errorMessage =
              getValidationMessages(err)?.[0].ErrorMessage ??
              "Erro ao adicionar pendência";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }
        },
        update: async (oldValues, newValues) => {
          try {
            const newData = { ...oldValues, ...newValues };

            const { data } =
              await service.hubEnergy.upsertDiligenceStepPendencyConfig({
                diligenceStepConfigId,
                diligenceStepPendencyConfigId: oldValues.id,
                isAutomation: newData.isAutomation,
                hasBpoAction: newData.hasBpoAction,
                activateOnStepStatus: newData.activateOnStepStatus,
                typeId: newData.typeId,
                message: newData.message,
                propertyConfigId: newData.propertyConfigId,
                dependencyId: newData.dependencyId,
              });
            OToastManager.success("Pendência atualizada");
            return data.response;
          } catch (err) {
            const errorMessage =
              getValidationMessages(err)?.[0].ErrorMessage ??
              "Erro ao atualizar pendência";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }
        },
        remove: async (values) => {
          try {
            await service.hubEnergy.deleteDiligenceStepPendencyConfigById(
              values.id,
            );
            OToastManager.success("Pendência excluída");
          } catch (err) {
            const errorMessage =
              getValidationMessages(err)?.[0].ErrorMessage ??
              "Erro ao excluir pendência";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }
        },
      },
    },
  );

const datagridSettings: ODataGridGeneratorConfig<HubEnergy.DiligenceStepPendencyConfigResponse>["datagrid"] =
  {
    noDataText: "Nenhuma pendência",
    stateStoring: gridStorage("pendencyConfigsGrid"),
    editing: {
      confirmDelete: true,
      allowDeleting: true,
      allowUpdating: true,
      mode: "form",
    },
    onInitNewRow: (e) => {
      e.data.isAutomation = false;
      e.data.hasBpoAction = false;
    },
    onEditorPreparing: (e) => {
      e.editorOptions = {
        ...e.editorOptions,
        dropDownOptions: {
          ...e.editorOptions?.dropDownOptions,
          minWidth: 400,
        },
        itemTemplate: (itemData, _, itemElement) => {
          (itemElement as HTMLElement).setAttribute("title", itemData.label);
          return itemData.label;
        },
      };
    },
    columnResizingMode: "nextColumn",
  };

export const pendencyConfigsGrid = (
  pendencyTypes: HubEnergy.DiligenceStepPendencyTypeResponse[],
  pendencyConfigs: HubEnergy.DiligenceStepPendencyConfigResponse[],
  propertyConfigLookup: {
    value: number;
    label: string;
  }[],
): ODataGridGeneratorConfig<HubEnergy.DiligenceStepPendencyConfigResponse> => {
  return {
    datagrid: datagridSettings,
    columns: [
      {
        dataField: "id",
        dataType: "number",
        caption: "Id",
        sortOrder: "asc",
        allowEditing: false,
        formItem: { visible: false },
      },
      {
        dataField: "typeId",
        dataType: "string",
        caption: "Tipo da pendência",
        validationRules: [{ type: "required" }],
        lookup: {
          dataSource: pendencyTypes.map(({ name, label, id }) => ({
            value: id,
            label: `${label} - [${name}]`,
          })),
          displayExpr: "label",
          valueExpr: "value",
        },
        cssClass: "dx-word-wrap",
        setCellValue(data, value: number, currentRowData) {
          this.defaultSetCellValue?.(data, value, currentRowData);
        },
      },
      {
        dataField: "typeId",
        dataType: "string",
        name: "description",
        caption: "Mensagem padrão",
        allowEditing: false,
        lookup: {
          dataSource: pendencyTypes.map(({ description, id }) => ({
            value: id,
            label: description,
          })),
          displayExpr: "label",
          valueExpr: "value",
        },
        cssClass: "dx-word-wrap",
      },
      {
        dataField: "propertyConfigId",
        dataType: "string",
        caption: "Propriedade",
        allowEditing: true,
        lookup: {
          dataSource: propertyConfigLookup,
          displayExpr: "label",
          valueExpr: "value",
          allowClearing: true,
        },
        cssClass: "dx-word-wrap",
      },
      {
        dataField: "message",
        dataType: "string",
        caption: "Mensagem",
        cssClass: "dx-word-wrap",
      },
      {
        dataField: "isAutomation",
        caption: "Automação",
        dataType: "boolean",
      },
      {
        dataField: "hasBpoAction",
        caption: "Ação do BPO",
        dataType: "boolean",
      },
      {
        dataField: "activateOnStepStatus",
        dataType: "string",
        caption: "Ativa quando step tiver status",
        lookup: {
          dataSource: Object.entries(diligenceStepStatusMap).map(
            ([status, { text }]) => ({
              value: status,
              label: text,
            }),
          ),
          displayExpr: "label",
          valueExpr: "value",
          allowClearing: true,
        },
        cssClass: "dx-word-wrap",
      },
      {
        dataField: "dependencyId",
        dataType: "number",
        caption: "Dependência",
        lookup: {
          dataSource: pendencyConfigs.map(({ id, typeId }) => {
            const type = pendencyTypes.find(
              (pendencyType) => pendencyType.id === typeId,
            );

            return {
              value: id,
              label: `[${id}] ${type?.label}`,
            };
          }),
          displayExpr: "label",
          valueExpr: "value",
          allowClearing: true,
        },
        cssClass: "dx-word-wrap",
      },
      {
        type: "buttons",
        buttons: ["edit", "delete"],
      },
    ],
  };
};
