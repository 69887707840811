import { RedirectWithRoles } from "components/redirect-with-roles";
import { Routes } from "routes/routes.types";
import { CompanyGroups } from "../pages/produto/agrupamento-cnpj";
import { ContractSignatories } from "../pages/produto/assinantes-contratos";
import { EditContractSignatories } from "../pages/produto/assinantes-contratos/[taxId]";
import { AddContractSignatories } from "../pages/produto/assinantes-contratos/adicionar";
import { Convenios } from "../pages/produto/convenios";
import { Quote } from "../pages/produto/cotacao";
import { Deflators } from "../pages/produto/deflatores";
import { GlobalDeflator } from "../pages/produto/deflatores/global";
import { NewDeflator } from "../pages/produto/deflatores/novo/new-deflator.page";
import { AutoEmails } from "../pages/produto/emails-automaticos";
import { NewAutoEmail } from "../pages/produto/emails-automaticos/novo";
import { CedenteLimits } from "../pages/produto/enquadramento-carteira/cedentes";
import { AddCedenteLimite } from "../pages/produto/enquadramento-carteira/cedentes/adicionar";
import { SacadoTier } from "../pages/produto/enquadramento-carteira/sacados";
import { AddSacadoTierConfig } from "../pages/produto/enquadramento-carteira/sacados/adicionar";
import { Tiers } from "../pages/produto/enquadramento-carteira/tiers";
import { AddRelationship } from "../pages/produto/enquadramento-carteira/tiers/[tierId]/adicionar-relacionamento";
import { UploadTiers } from "../pages/produto/enquadramento-carteira/upload";
import { IntubateOperationsFile } from "../pages/produto/entuba-operacoes/envio-arquivo";
import { IntubateOperationsList } from "../pages/produto/entuba-operacoes/listagem";
import { IntubateOperationsSuggest } from "../pages/produto/entuba-operacoes/sugestao";
import { IntubateOperationsWithoutRegistryFile } from "../pages/produto/entuba-operacoes-sem-registro/envio-arquivo";
import { Whitelist } from "../pages/produto/ferramentas/agendas-priorizadas";
import { Crontab } from "../pages/produto/ferramentas/crontab";
import { TradeHealth } from "../pages/produto/ferramentas/health-check/trade-health";
import { GeneralHealth } from "../pages/produto/ferramentas/health-check/visao-geral";
import { Jobs } from "../pages/produto/ferramentas/jobs";
import { Agendas } from "../pages/produto/monitor-cartoes/agendas";
import { GroupedAgendas } from "../pages/produto/monitor-cartoes/agendas-agrupadas";
import { ReceivableDetails } from "../pages/produto/monitor-cartoes/detalhes-recebiveis";
import { ImportAgendas } from "../pages/produto/monitor-cartoes/importacao";
import { IntubateGeneric } from "../pages/produto/monitor-registros/entuba";
import { Registers } from "../pages/produto/monitor-registros/registros";
import { RegisterSolicitations } from "../pages/produto/monitor-registros/solicitacoes";
import { Operations } from "../pages/produto/operacoes";
import { OperationDetails } from "../pages/produto/operacoes/empresa/[companyId]/operacao/[operationId]/detalhes";
import { Optin } from "../pages/produto/optins/envio";
import { AutoRelocation } from "../pages/produto/realocacao/automatica";
import { Relocate } from "../pages/produto/realocacao/executar";
import { Trades } from "../pages/produto/trades";
import { antecipacaoDeCartoesRoles } from "../roles/antecipacao-de-cartoes.roles";
import { antecipacaoDeCartoesRouteParams } from "./antecipacao-de-cartoes.route-params";
import { EmailCotacao } from "../pages/produto/email-cotacao";
import { RelatorioOperacoes } from "../pages/produto/relatorio-operacoes/solicitacao-de-relatorios";
import { CompanyDetails } from "../pages/cliente/detalhes";
import { ControladorLimiteCedente } from "../pages/produto/controlador-limite-cedente";
import { ProcessDashboard } from "../pages/produto/process-dashboard/process-dashboard.page";
import { IntubateOperationsSuggestClient } from "../pages/cliente/simular/sugestao";
import { ConciliacaoPage } from "../pages/cliente/extrato-liquidacao-posicao";
import { ConciliacaoDetailsPage } from "../pages/cliente/extrato-liquidacao-posicao/detalhes-dia/[maturityDate]/extrato-liquidacao-posicao-details.page";
import { ConsultaOptins } from "../pages/produto/optins/consulta-optins/consulta-optin.page";
import { CompanyTraits } from "../pages/produto/company-traits/company-traits.page";
import { OptinsRejeitados } from "../pages/produto/optins/optins-rejeitados/optins-rejeitados.page";

export const antecipacaoDeCartoesRoutes = {
  customer: {
    name: "",
    path: "/antecipacao-de-cartoes/cliente",
    element: <RedirectWithRoles />,
    children: {
      details: {
        name: "Detalhes",
        path: "/antecipacao-de-cartoes/cliente/detalhes",
        role: antecipacaoDeCartoesRoles.customer.detailsPage.role,
        element: <CompanyDetails />,
      },
      simulate: {
        name: "Simular",
        path: "/antecipacao-de-cartoes/cliente/simular-cotacao",
        role: antecipacaoDeCartoesRoles.customer.simulateQuotationPage.role,
        element: <IntubateOperationsSuggestClient />,
      },
      liquidationExtract: {
        name: "Extrato de liquidação",
        path: "/antecipacao-de-cartoes/cliente/extrato-de-liquidacao",
        role: antecipacaoDeCartoesRoles.customer.liquidationExtractPage.role,
        element: <ConciliacaoPage />,
        children: {
          details: {
            name: "Detalhes da antecipação",
            path: `/antecipacao-de-cartoes/cliente/extrato-de-liquidacao/detalhes-dia/:${antecipacaoDeCartoesRouteParams.maturityDate}`,
            role: antecipacaoDeCartoesRoles.product.operationsDetailsPage.role,
            element: <ConciliacaoDetailsPage />,
          },
        },
      },
    },
  },
  product: {
    name: "",
    path: "/antecipacao-de-cartoes/produto",
    element: <RedirectWithRoles />,
    children: {
      operations: {
        name: "Operações",
        path: "/antecipacao-de-cartoes/produto/operacoes",
        role: antecipacaoDeCartoesRoles.product.operationsPage.role,
        element: <Operations />,
        children: {
          details: {
            name: "Detalhes da operação",
            path: `/antecipacao-de-cartoes/produto/operacoes/empresa/:${antecipacaoDeCartoesRouteParams.companyId}/operacao/:${antecipacaoDeCartoesRouteParams.operationId}/detalhes`,
            role: antecipacaoDeCartoesRoles.product.operationsDetailsPage.role,
            element: <OperationDetails />,
          },
        },
      },
      intubateOperations: {
        name: "Entuba de operações",
        path: "/antecipacao-de-cartoes/produto/entuba-operacoes",
        hasSubItems: true,
        children: {
          file: {
            name: "Busca via submissão Excel",
            path: "/antecipacao-de-cartoes/produto/entuba-operacoes/envio-arquivo",
            role: antecipacaoDeCartoesRoles.product.intubateOperationsFilePage
              .role,
            element: <IntubateOperationsFile />,
          },
          list: {
            name: "Busca via listagem de recebíveis",
            path: "/antecipacao-de-cartoes/produto/entuba-operacoes/listagem",
            role: antecipacaoDeCartoesRoles.product.intubateOperationsListPage
              .role,
            element: <IntubateOperationsList />,
          },
          suggest: {
            name: "Busca via sugestão de recebíveis",
            path: "/antecipacao-de-cartoes/produto/entuba-operacoes/sugestao",
            role: antecipacaoDeCartoesRoles.product
              .intubateOperationsSuggestPage.role,
            element: <IntubateOperationsSuggest />,
          },
        },
      },
      intubateOperationsWithoutRegistry: {
        name: "Entuba de operações sem registro",
        path: "/antecipacao-de-cartoes/produto/entuba-operacoes-sem-registro",
        hasSubItems: true,
        children: {
          file: {
            name: "Submissão via Excel",
            path: "/antecipacao-de-cartoes/produto/entuba-operacoes-sem-registro/envio-arquivo",
            role: antecipacaoDeCartoesRoles.product
              .intubateOperationsWithoutRegistryFilePage.role,
            element: <IntubateOperationsWithoutRegistryFile />,
          },
        },
      },
      registersMonitor: {
        name: "Monitor de registros",
        path: "/antecipacao-de-cartoes/produto/monitor-registros",
        hasSubItems: true,
        children: {
          solicitations: {
            name: "Solicitações",
            path: "/antecipacao-de-cartoes/produto/monitor-registros/solicitacoes",
            role: antecipacaoDeCartoesRoles.product
              .registersMonitorSolicitationsPage.role,
            element: <RegisterSolicitations />,
          },
          registers: {
            name: "Registros",
            path: "/antecipacao-de-cartoes/produto/monitor-registros/registros",
            role: antecipacaoDeCartoesRoles.product
              .registersMonitorRegistersPage.role,
            element: <Registers />,
          },
          intubate: {
            name: "Entuba",
            path: "/antecipacao-de-cartoes/produto/monitor-registros/entuba",
            hasSubItems: true,
            children: {
              add: {
                name: "Inclusão",
                path: "/antecipacao-de-cartoes/produto/monitor-registros/entuba/inclusao",
                role: antecipacaoDeCartoesRoles.product
                  .registersMonitorIntubateAddPage.role,
                element: <IntubateGeneric type="add" />,
              },
              edit: {
                name: "Alteração",
                path: "/antecipacao-de-cartoes/produto/monitor-registros/entuba/alteracao",
                role: antecipacaoDeCartoesRoles.product
                  .registersMonitorIntubateEditPage.role,
                element: <IntubateGeneric type="edit" />,
              },
              cancel: {
                name: "Cancelamento",
                path: "/antecipacao-de-cartoes/produto/monitor-registros/entuba/cancelamento",
                role: antecipacaoDeCartoesRoles.product
                  .registersMonitorIntubateCancelPage.role,
                element: <IntubateGeneric type="cancel" />,
              },
            },
          },
        },
      },
      relocation: {
        name: "Realocação",
        path: "/antecipacao-de-cartoes/produto/realocacao",
        hasSubItems: true,
        children: {
          relocate: {
            name: "Simular e executar realocação",
            path: "/antecipacao-de-cartoes/produto/realocacao/executar",
            role: antecipacaoDeCartoesRoles.product.relocationRelocatePage.role,
            element: <Relocate />,
          },
          auto: {
            name: "Configurar realocação automática",
            path: "/antecipacao-de-cartoes/produto/realocacao/automatica",
            role: antecipacaoDeCartoesRoles.product.relocationAutoPage.role,
            element: <AutoRelocation />,
          },
        },
      },
      enquadramento: {
        name: "Enquadramento de Carteira",
        path: "/antecipacao-de-cartoes/produto/enquadramento-carteira",
        hasSubItems: true,
        children: {
          tiers: {
            name: "Tiers",
            path: "/antecipacao-de-cartoes/produto/enquadramento-carteira/tiers",
            role: antecipacaoDeCartoesRoles.product.enquadramentoTiersPage.role,
            element: <Tiers />,
            children: {
              addRelationship: {
                name: "Adicionar Relacionamento",
                path: `/antecipacao-de-cartoes/produto/enquadramento-carteira/tiers/:${antecipacaoDeCartoesRouteParams.tierId}/adicionar-relacionamento`,
                role: antecipacaoDeCartoesRoles.product
                  .enquadramentoTiersAddRelationshipPage.role,
                element: <AddRelationship />,
              },
            },
          },
          cedenteLimits: {
            name: "Limite de Cedente",
            path: "/antecipacao-de-cartoes/produto/enquadramento-carteira/cedentes",
            role: antecipacaoDeCartoesRoles.product
              .enquadramentoCedenteLimitsPage.role,
            element: <CedenteLimits />,
            children: {
              add: {
                name: "Adicionar",
                path: "/antecipacao-de-cartoes/produto/enquadramento-carteira/cedentes/adicionar",
                role: antecipacaoDeCartoesRoles.product
                  .enquadramentoCedenteLimitsAddPage.role,
                element: <AddCedenteLimite />,
              },
            },
          },
          sacadoTier: {
            name: "Configuração de Tier Mínimo do Sacado",
            path: "/antecipacao-de-cartoes/produto/enquadramento-carteira/sacados",
            role: antecipacaoDeCartoesRoles.product.enquadramentoSacadoTierPage
              .role,
            element: <SacadoTier />,
            children: {
              add: {
                name: "Adicionar",
                path: "/antecipacao-de-cartoes/produto/enquadramento-carteira/sacados/adicionar",
                role: antecipacaoDeCartoesRoles.product
                  .enquadramentoSacadoTierAddPage.role,
                element: <AddSacadoTierConfig />,
              },
            },
          },
          upload: {
            name: "Upload de Tiers",
            path: "/antecipacao-de-cartoes/produto/enquadramento-carteira/upload",
            role: antecipacaoDeCartoesRoles.product.enquadramentoUploadPage
              .role,
            element: <UploadTiers />,
          },
        },
      },
      quotation: {
        name: "Cotação",
        path: "/antecipacao-de-cartoes/produto/cotacao",
        role: antecipacaoDeCartoesRoles.product.quotationPage.role,
        element: <Quote />,
      },
      trades: {
        name: "Trades",
        path: "/antecipacao-de-cartoes/produto/trades",
        role: antecipacaoDeCartoesRoles.product.tradesPage.role,
        element: <Trades />,
      },
      agendas: {
        name: "Agendas",
        path: "/antecipacao-de-cartoes/produto/monitor-cartoes",
        hasSubItems: true,
        children: {
          agendas: {
            name: "Agendas",
            path: "/antecipacao-de-cartoes/produto/monitor-cartoes/agendas",
            role: antecipacaoDeCartoesRoles.product.agendasAgendasPage.role,
            element: <Agendas />,
          },
          groupedAgendas: {
            name: "Valores agrupados por cedente/sacado",
            path: "/antecipacao-de-cartoes/produto/monitor-cartoes/agendas-agrupadas",
            role: antecipacaoDeCartoesRoles.product.agendasGroupedAgendasPage
              .role,
            element: <GroupedAgendas />,
          },
          receivableDetails: {
            name: "Detalhes dos recebíveis no portal",
            path: "/antecipacao-de-cartoes/produto/monitor-cartoes/detalhes-recebiveis",
            role: antecipacaoDeCartoesRoles.product.agendasReceivableDetailsPage
              .role,
            element: <ReceivableDetails />,
          },
          importAgendas: {
            name: "Importação manual de agendas CIP",
            path: "/antecipacao-de-cartoes/produto/monitor-cartoes/importacao",
            role: antecipacaoDeCartoesRoles.product.agendasImportAgendasPage
              .role,
            element: <ImportAgendas />,
          },
        },
      },
      companyGroups: {
        name: "Agrupamento de CNPJs",
        path: "/antecipacao-de-cartoes/produto/agrupamento-cnpj",
        role: antecipacaoDeCartoesRoles.product.companyGroupsPage.role,
        element: <CompanyGroups />,
      },
      emailCotacao: {
        name: "Envio email cotação",
        path: "/antecipacao-de-cartoes/produto/email-cotacao",
        role: antecipacaoDeCartoesRoles.product.emailCotacaoPage.role,
        element: <EmailCotacao />,
      },
      operationReports: {
        name: "Relatório de operações",
        path: "/antecipacao-de-cartoes/produto/relatorio-operacoes",
        role: antecipacaoDeCartoesRoles.product.relatorioOperacoesPage.role,
        element: <RelatorioOperacoes />,
      },
      optins: {
        name: "Optins",
        path: "/antecipacao-de-cartoes/produto/optins",
        hasSubItems: true,
        children: {
          optin: {
            name: "Enviar",
            path: "/antecipacao-de-cartoes/produto/optins/novo",
            role: antecipacaoDeCartoesRoles.product.optinPage.role,
            element: <Optin />,
          },
          consultaOptins: {
            name: "Visualizar envios e solicitar opt-outs",
            path: "/antecipacao-de-cartoes/produto/optins/gerenciar",
            role: antecipacaoDeCartoesRoles.product.dashboardProcessoPage.role,
            element: <ConsultaOptins />,
          },
          optinsRejeitados: {
            name: "Optins rejeitados",
            path: "/antecipacao-de-cartoes/produto/optins/rejeitados",
            role: antecipacaoDeCartoesRoles.product.dashboardProcessoPage.role,
            element: <OptinsRejeitados />,
          },
        },
      },
      companyTraits: {
        name: "Propriedades das empresas",
        path: "/antecipacao-de-cartoes/produto/propriedade-empresas",
        role: antecipacaoDeCartoesRoles.product.companyTraitsPage.role,
        element: <CompanyTraits />,
      },
      deflators: {
        name: "Deflatores de Recebíveis",
        path: "/antecipacao-de-cartoes/produto/deflatores",
        role: antecipacaoDeCartoesRoles.product.deflatorsPage.role,
        element: <Deflators />,
        children: {
          newDeflator: {
            name: "Criar deflator",
            path: "/antecipacao-de-cartoes/produto/deflatores/novo",
            role: antecipacaoDeCartoesRoles.product.deflatorsNewDeflatorPage
              .role,
            element: <NewDeflator />,
          },
          globalDeflator: {
            name: "Deflator global",
            path: "/antecipacao-de-cartoes/produto/deflatores/global",
            role: antecipacaoDeCartoesRoles.product.deflatorsGlobalDeflatorPage
              .role,
            element: <GlobalDeflator />,
          },
        },
      },
      convenios: {
        name: "Convênios",
        path: "/antecipacao-de-cartoes/produto/convenios",
        role: antecipacaoDeCartoesRoles.product.conveniosPage.role,
        element: <Convenios />,
      },
      autoEmails: {
        name: "E-mails automáticos",
        path: "/antecipacao-de-cartoes/produto/emails-automaticos",
        role: antecipacaoDeCartoesRoles.product.autoEmailsPage.role,
        element: <AutoEmails />,
        children: {
          create: {
            name: "Criar e-mail automático",
            path: "/antecipacao-de-cartoes/produto/emails-automaticos/novo",
            role: antecipacaoDeCartoesRoles.product.autoEmailsCreatePage.role,
            element: <NewAutoEmail />,
          },
        },
      },
      contractSignatories: {
        name: "Assinantes de contratos",
        path: "/antecipacao-de-cartoes/produto/assinantes-contratos",
        role: antecipacaoDeCartoesRoles.product.contractSignatoriesPage.role,
        element: <ContractSignatories />,
        children: {
          add: {
            name: "Adicionar",
            path: `/antecipacao-de-cartoes/produto/assinantes-contratos/adicionar`,
            role: antecipacaoDeCartoesRoles.product.contractSignatoriesAddPage
              .role,
            element: <AddContractSignatories />,
          },
          edit: {
            name: "Editar",
            path: `/antecipacao-de-cartoes/produto/assinantes-contratos/:${antecipacaoDeCartoesRouteParams.taxId}`,
            role: antecipacaoDeCartoesRoles.product.contractSignatoriesEditPage
              .role,
            element: <EditContractSignatories />,
          },
        },
      },
      controladorLimite: {
        name: "Controle de Limite",
        path: "/antecipacao-de-cartoes/produto/controlador-limite-cedente",
        role: antecipacaoDeCartoesRoles.product.controladorLimiteCedente.role,
        hasSubItems: false,
        element: <ControladorLimiteCedente />,
      },
      tools: {
        name: "Ferramentas",
        path: "/antecipacao-de-cartoes/produto/ferramentas",
        hasSubItems: true,
        children: {
          whitelists: {
            name: "Agendas priorizadas",
            path: "/antecipacao-de-cartoes/produto/ferramentas/agendas-priorizadas",
            role: antecipacaoDeCartoesRoles.product.toolsWhitelistsPage.role,
            element: <Whitelist />,
          },
          jobs: {
            name: "Jobs",
            path: "/antecipacao-de-cartoes/produto/ferramentas/jobs",
            role: antecipacaoDeCartoesRoles.product.toolsJobsPage.role,
            element: <Jobs guarantee={false} />,
          },
          crontab: {
            name: "Crontab",
            path: "/antecipacao-de-cartoes/produto/ferramentas/crontab",
            role: antecipacaoDeCartoesRoles.product.toolsCrontabPage.role,
            element: <Crontab />,
          },
          guaranteeRelocation: {
            name: "Realocação de garantias",
            path: "/antecipacao-de-cartoes/produto/ferramentas/realocacao-garantias",
            role: antecipacaoDeCartoesRoles.product.toolsGuaranteeRelocationPage
              .role,
            element: <Jobs guarantee />,
          },
          healthCheck: {
            name: "Health check",
            path: "/antecipacao-de-cartoes/produto/ferramentas/health-check",
            hasSubItems: true,
            children: {
              generalHealth: {
                name: "Visão geral",
                path: "/antecipacao-de-cartoes/produto/ferramentas/health-check/visao-geral",
                role: antecipacaoDeCartoesRoles.product
                  .toolsHealthCheckGeneralHealthPage.role,
                element: <GeneralHealth />,
              },
              tradeHealth: {
                name: "Trade Health",
                path: "/antecipacao-de-cartoes/produto/ferramentas/health-check/trade-health",
                role: antecipacaoDeCartoesRoles.product
                  .toolsHealthCheckTradeHealthPage.role,
                element: <TradeHealth />,
              },
            },
          },
        },
      },
      dashboardProcessos: {
        name: "Dashboard rotina matinal",
        path: "/antecipacao-de-cartoes/produto/dashboard-rotina-matinal",
        role: antecipacaoDeCartoesRoles.product.dashboardProcessoPage.role,
        element: <ProcessDashboard />,
        children: {},
      },
    },
  },
} as const satisfies Routes;
