import { SelectSearchFieldOption } from "components/select-search/field/select-search-field.types";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { MleByConsumerUnitIdRouteParams } from "../../../../../../../../../../../../routes/energia.route-params";
import { MoveStepPropertyForm } from "../../move-step-property-modal.form";

export const useDestinationStepSelector = () => {
  const { consumerUnitId } = useParams<MleByConsumerUnitIdRouteParams>();
  if (!consumerUnitId) throw new Error("No consumerUnitId");

  const { callService, loading, value, hasError } = useServiceCall(
    service.hubEnergy.getChannelDiligenceConsumerUnit,
  );

  const { clearErrors, setError } = useFormContext<MoveStepPropertyForm>();

  const getSteps = useCallback(async () => {
    const { success } = await callService(consumerUnitId);
    if (success) clearErrors("destinationStepId");
    else
      setError("destinationStepId", {
        message:
          "Erro ao buscar os steps. Clique no botão ao lado para tentar novamente.",
      });
  }, [callService, clearErrors, consumerUnitId, setError]);

  useEffect(() => {
    getSteps();
  }, [getSteps]);

  const steps = useMemo(
    () =>
      value?.response?.steps.map<SelectSearchFieldOption<number>>(
        ({ type, id }) => ({
          label: type.name,
          value: id,
        }),
      ) ?? [],
    [value?.response],
  );

  return {
    steps,
    getSteps,
    loading,
    hasFetchError: hasError,
  };
};
