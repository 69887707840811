import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { service } from "services";

export const useStepAnalysis = () => {
  const {
    callService: getNextStepAnalysis,
    error,
    hasError,
    loading,
    value,
  } = useServiceCall(service.hubEnergy.getNextStepAnalysis);

  useEffect(() => {
    getNextStepAnalysis();
  }, [getNextStepAnalysis]);

  return {
    getNextStepAnalysis,
    error,
    hasError,
    loading,
    value,
  };
};
