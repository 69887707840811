import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { PostAdminCompaniesGetByTaxIdResponse } from "services/clerk";
import { format } from "utils/date";
import { getValueFromMap } from "utils/get-value-from-map";
import { OnboardingStatusTooltip } from "../../../../empresas/pages/produto/_compose";
import { onboardingTypeOverridesMap } from "./onboardings.utils";

type OnboardingsResponse = NonNullable<
  NonNullable<PostAdminCompaniesGetByTaxIdResponse["onboardings"]>[number]
>;

const buildButtonLink = (id: number) =>
  corporateRouter.routes.cadastro.customer.onboardings.onboarding.legacy.path({
    id,
  });

const buildDetailsButtonLink = (externalReference?: string) =>
  corporateRouter.routes.cadastro.customer.onboardings.onboardingDetails.path({
    externalReference: externalReference ?? "",
  });

export const buildGrid: ODataGridGeneratorConfig<OnboardingsResponse> = {
  datagrid: {
    noDataText: "Nenhum cadastro",
  },
  columns: [
    {
      role: roles.cadastro.customer.onboardingsViewDetailsPage.role,
      cellRender: ({ data }) => {
        return (
          data.type.includes("CONTA") &&
          data.status === "PENDENTE_DOCUMENTACAO" && (
            <DetailsLink
              href={
                corporateRouter.routes.cadastro.customer.onboardings.details
                  .path
              }
            />
          )
        );
      },
      allowExporting: false,
    },
    {
      dataField: "type",
      caption: "Tipo",
      customizeText: ({ value }) =>
        getValueFromMap(onboardingTypeOverridesMap, value) ?? value,
    },
    {
      dataField: "status",
      caption: "Status",
      cellRender: ({ data }) => (
        <OnboardingStatusTooltip onboardingStatus={data.status} />
      ),
    },
    {
      dataField: "rawStatus",
      caption: "Status Detalhado",
      role: roles.cadastro.customer.onboardingsOnboardingRawStatusGridColumn
        .role,
    },
    {
      dataField: "insertedAt",
      caption: "Data de Criação",
      customizeText: ({ valueText }) => format.date(valueText),
    },
    {
      dataField: "updatedAt",
      caption: "Última Atualização",
      customizeText: ({ valueText }) => format.date(valueText),
    },
    {
      dataField: "originChannel",
      caption: "Canal de Origem",
      role: roles.cadastro.customer.onboardingsOnboardingOriginChannelGridColumn
        .role,
    },
    {
      caption: "Ações",
      role: roles.cadastro.customer.onboardingsOnboardingEditPage.role,
      cellRender: ({ data, component, rowIndex }) => {
        return (
          <DataGridAction
            actions={[
              {
                icon: { category: "fas", icon: "fa-pen" },
                label: "Editar",
                visible:
                  !!data.id &&
                  ["CEDENTE_SIMPLES", "SACADO_SIMPLES"].includes(data.type),
                route: buildButtonLink(data.id),
              },
              {
                icon: { category: "fas", icon: "fa-pen" },
                label: "Editar",
                visible:
                  !!data.externalReference &&
                  !["CEDENTE_SIMPLES", "SACADO_SIMPLES"].includes(data.type),
                route:
                  corporateRouter.routes.cadastro.customer.onboardings.onboarding.edit.path(
                    {
                      id: data.externalReference ?? "",
                    },
                  ),
              },
              {
                icon: { category: "fas", icon: "fa-trash" },
                label: "Excluir",
                visible: !!data.externalReference,
                role: roles.cadastro.customer.onboardingsOnboardingDeleteButton
                  .role,
                onClick: () => {
                  component.option(
                    "editing.texts.confirmDeleteMessage",
                    `Tem certeza que deseja excluir o onboarding ${data?.externalReference}?`,
                  );
                  component.deleteRow(rowIndex);
                },
              },
              {
                icon: { category: "fas", icon: "fa-link" },
                label: "Detalhes",
                visible: !!data.externalReference,
                role: roles.cadastro.customer
                  .onboardingsOnboardingDetailsGridColumn.role,
                route: buildDetailsButtonLink(data?.externalReference),
              },
            ]}
          />
        );
      },
    },
  ],
};
