import {
  OButton,
  OCard,
  OCardBody,
  OCol,
  OIcon,
  OLabel,
  OLoader,
  OOption,
  ORFieldInput,
  ORFieldRadioGroup,
  ORFieldSelect,
  ORadio,
  ORow,
} from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { service } from "services";

interface DeskConfigFormProps {
  editOnly?: boolean;
}

export const DeskConfigForm = ({ editOnly }: DeskConfigFormProps) => {
  const {
    callService: getSegmentGroups,
    loading: loadingSegmntGroups,
    value: segmentGroups,
    hasError: hasErrorSegmentGroups,
  } = useServiceCall(service.onboarding.getSegmentGroups);
  const {
    callService: getDesksOnboardingEnums,
    loading: loadingEnums,
    value: enums,
    hasError: hasErrorEnums,
  } = useServiceCall(service.onboarding.getDesksOnboardingEnums);

  useEffect(() => {
    getSegmentGroups();
    getDesksOnboardingEnums();
  }, [getSegmentGroups, getDesksOnboardingEnums]);

  return (
    <div className="d-flex flex-column gap-4">
      <OCard>
        <OCardBody>
          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldInput
                disabled={editOnly}
                label="Identificaor"
                tag="text"
                id="configIdentifier"
                name="configIdentifier"
              />
            </OCol>
            <OCol xs={12} lg={6}>
              <ORFieldInput
                label="Nome"
                tag="text"
                id="configName"
                name="configName"
              />
            </OCol>
          </ORow>
          <ORow>
            <ORFieldInput
              label="Descrição"
              tag="text"
              id="configDescription"
              name="configDescription"
            />
          </ORow>
          <ORow>
            <OCol xs={12} lg={6} align="center" className="gap-2">
              <ORFieldSelect
                label="Relacionamento"
                id="relationship"
                name="relationship"
                placeholder="Selecione"
                disabled={loadingEnums}
                key={enums?.lessRelationships?.length}
              >
                {enums?.lessRelationships?.map((place) => (
                  <OOption key={place} value={place}>
                    {place}
                  </OOption>
                ))}
              </ORFieldSelect>
              {loadingEnums && <OLoader className="d-block" size="sm" />}
              {hasErrorEnums && (
                <OButton
                  type="default"
                  outline
                  onClick={() => getDesksOnboardingEnums()}
                >
                  <OIcon
                    category="fa"
                    icon="fa-repeat"
                    size="sm"
                    type="default"
                  />
                </OButton>
              )}
            </OCol>
            <OCol xs={12} lg={6} align="center" className="gap-2">
              <ORFieldSelect
                label="Integração"
                id="integration"
                name="integration"
                placeholder="Selecione"
                disabled={loadingEnums}
                key={enums?.integrationTypes?.length}
              >
                {enums?.integrationTypes?.map((integrationType) => (
                  <OOption key={integrationType} value={integrationType}>
                    {integrationType}
                  </OOption>
                ))}
              </ORFieldSelect>
              {loadingEnums && <OLoader className="d-block" size="sm" />}
              {hasErrorEnums && (
                <OButton
                  type="default"
                  outline
                  onClick={() => getDesksOnboardingEnums()}
                >
                  <OIcon
                    category="fa"
                    icon="fa-repeat"
                    size="sm"
                    type="default"
                  />
                </OButton>
              )}
            </OCol>
          </ORow>
          <ORow>
            <OCol xs={12} lg={6} align="center" className="gap-2">
              <ORFieldSelect
                id="segmentGroupId"
                name="segmentGroupId"
                label="Grupo de segmento"
                placeholder="Selecione"
                disabled={loadingSegmntGroups}
                key={segmentGroups?.length}
              >
                {segmentGroups?.map((segmentGroup) => (
                  <OOption key={segmentGroup.id} value={segmentGroup.id}>
                    {`${segmentGroup.id} - ${segmentGroup.segment.name} - ${segmentGroup.coSegment.name} (${segmentGroup.coSegment.code})`}
                  </OOption>
                ))}
              </ORFieldSelect>
              {loadingSegmntGroups && <OLoader className="d-block" size="sm" />}
              {hasErrorSegmentGroups && (
                <OButton
                  type="default"
                  outline
                  onClick={() => getSegmentGroups()}
                >
                  <OIcon
                    category="fa"
                    icon="fa-repeat"
                    size="sm"
                    type="default"
                  />
                </OButton>
              )}
            </OCol>
            <OCol xs={12} lg={6} align="center" className="gap-2">
              <ORFieldSelect
                id="whereShouldSignFirst"
                name="whereShouldSignFirst"
                label="Onde deve assinar primeiro?"
                placeholder="Selecione"
                disabled={loadingEnums}
                key={enums?.termsSignPlaces?.length}
              >
                {enums?.termsSignPlaces?.map((place) => (
                  <OOption key={place} value={place}>
                    {place}
                  </OOption>
                ))}
              </ORFieldSelect>
              {loadingEnums && <OLoader className="d-block" size="sm" />}
              {hasErrorEnums && (
                <OButton
                  type="default"
                  outline
                  onClick={() => getDesksOnboardingEnums()}
                >
                  <OIcon
                    category="fa"
                    icon="fa-repeat"
                    size="sm"
                    type="default"
                  />
                </OButton>
              )}
            </OCol>
          </ORow>
          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldInput
                label="Relacionamentos para sincronizar"
                tag="text"
                id="relationshipsToSync"
                name="relationshipsToSync"
                tooltip="Valores separados por virgula"
              />
            </OCol>

            <OCol xs={12} lg={6} align="center" className="gap-2">
              <ORFieldSelect
                label="Service to Sync"
                id="serviceToSync"
                name="serviceToSync"
                placeholder="Selecione"
                disabled={loadingEnums}
                key={enums?.serviceTypes?.length}
              >
                {enums?.serviceTypes?.map((serviceType) => (
                  <OOption key={serviceType} value={serviceType}>
                    {serviceType}
                  </OOption>
                ))}
              </ORFieldSelect>
              {loadingEnums && <OLoader className="d-block" size="sm" />}
              {hasErrorEnums && (
                <OButton
                  type="default"
                  outline
                  onClick={() => getDesksOnboardingEnums()}
                >
                  <OIcon
                    category="fa"
                    icon="fa-repeat"
                    size="sm"
                    type="default"
                  />
                </OButton>
              )}
            </OCol>
          </ORow>

          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldInput
                label="TagCode do originador"
                tag="text"
                id="originatorTagCode"
                name="originatorTagCode"
              />
            </OCol>
            <OCol xs={12} lg={6}>
              <ORFieldInput
                label="ChannelLess"
                tag="text"
                id="channelLess"
                name="channelLess"
              />
            </OCol>
          </ORow>
          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldInput
                label="BusinessType"
                tag="text"
                id="businessType"
                name="businessType"
              />
            </OCol>
            <OCol xs={12} lg={6}>
              <ORFieldInput
                label="BusinessSubType"
                tag="text"
                id="businessSubType"
                name="businessSubType"
              />
            </OCol>
          </ORow>
          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldInput
                label="Email de contato"
                tag="text"
                id="contactEmail"
                name="contactEmail"
              />
            </OCol>
            <OCol xs={12} lg={6}>
              <ORFieldInput
                label="FlowType"
                tag="text"
                id="flowType"
                name="flowType"
              />
            </OCol>
          </ORow>
          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldInput
                label="Grupo de notificação no Teams"
                tag="text"
                id="teamsNotificationGroup"
                name="teamsNotificationGroup"
              />
            </OCol>
            <OCol xs={12} lg={6}>
              <ORFieldInput
                label="SLA Análise de Onboarding"
                tag="number"
                id="onboardingAnalysisSLA"
                name="onboardingAnalysisSLA"
              />
            </OCol>
          </ORow>

          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldRadioGroup
                id="legalRepresentativeWithPowerShouldBeAnalyzed"
                name="legalRepresentativeWithPowerShouldBeAnalyzed"
                label="Representante legal com poder deve ser analizado?"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
            <OCol xs={12} lg={6}>
              <ORFieldRadioGroup
                id="shouldNotCreateUsersOnBtgEmpresas"
                name="shouldNotCreateUsersOnBtgEmpresas"
                label="Não deve criar usuário no Btg Empresas?"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
          </ORow>
          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldRadioGroup
                id="isAvailableOnApp"
                name="isAvailableOnApp"
                label="Está disponível no aplicativo?"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
            <OCol xs={12} lg={6}>
              <ORFieldRadioGroup
                id="shouldNotCreateOrUpdateActivations"
                name="shouldNotCreateOrUpdateActivations"
                label="Não deve criar/atualizar Activations?"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
          </ORow>

          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldRadioGroup
                id="containCustomProfiles"
                name="containCustomProfiles"
                label="Contém perfis personalizados?"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
            <OCol xs={12} lg={6}>
              <ORFieldRadioGroup
                id="allGenericOccurrenceAllowed"
                name="allGenericOccurrenceAllowed"
                label="Permitido todas as ocorrências genericas?"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
          </ORow>
          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldRadioGroup
                id="shouldNotifyOnlyOnHOB"
                name="shouldNotifyOnlyOnHOB"
                label="Deve notificar apenas no HOB?"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
            <OCol xs={12} lg={6}>
              <ORFieldRadioGroup
                id="shouldNotifyUpdatesOnTeams"
                name="shouldNotifyUpdatesOnTeams"
                label="Deve notificar atualizar no Teams?"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
          </ORow>
          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldRadioGroup
                id="shouldSyncDeskAutomaticaly"
                name="shouldSyncDeskAutomaticaly"
                label="Deve sincronizar as mesas automaticamente?"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
            <OCol xs={12} lg={6}>
              <ORFieldRadioGroup
                id="shouldSendWelcomeEmail"
                name="shouldSendWelcomeEmail"
                label="Deve enviar email de boas vindas?"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
          </ORow>
          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldRadioGroup
                id="mustBeAuthenticatedToSign"
                name="mustBeAuthenticatedToSign"
                label="Deve estar autenticado para assinar?"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
            <OCol xs={12} lg={6}>
              <ORFieldRadioGroup
                id="shouldNotifyByEvent"
                name="shouldNotifyByEvent"
                label="Deve notificar por evento?"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
          </ORow>
          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldRadioGroup
                id="shouldSyncOfficerAsAdvisor"
                name="shouldSyncOfficerAsAdvisor"
                label="ShouldSyncOfficerAsAdvisor?"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
            <OCol xs={12} lg={6}>
              <ORFieldRadioGroup
                id="shouldIgnoreEmailForExistingBtgEmpresasUsers"
                name="shouldIgnoreEmailForExistingBtgEmpresasUsers"
                label="Deve ignorar validação de email quando usuário já estiver cadatsrado no BTG Empresas?"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
          </ORow>

          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldInput
                adaptHeight
                label="Etapas iniciais"
                tag="textarea"
                id="initialStages"
                name="initialStages"
              />
            </OCol>
            <OCol xs={12} lg={6}>
              <ORFieldInput
                adaptHeight
                label="Perfis disponiveis"
                tag="textarea"
                id="profilesAvailable"
                name="profilesAvailable"
              />
            </OCol>
          </ORow>
        </OCardBody>
      </OCard>
    </div>
  );
};
