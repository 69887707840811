import { OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { endpoints } from "services/endpoints";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";

export const accontsRelationshipsODataGrid: ODataGridGeneratorConfig<HubPartners.Admin.Relationship> =
  {
    datagrid: {
      noDataText: "Nenhuma conta encontrada",
      filterRow: { visible: true },
      onDataErrorOccurred: (e) => {
        if (e.error) e.error.message = "Erro ao buscar contas.";
      },
      dataSource: dataSourceODataStoreGenerator(
        endpoints.hubPartners.admin.odata.adminGetODataAccountRelationships,
      ),
    },
    columns: [
      {
        dataField: "number",
        caption: "Conta",
      },
      {
        dataField: "branch",
        caption: "Agéncia",
      },
      {
        dataField: "officialName",
        caption: "Nome",
      },
      {
        dataField: "identification",
        caption: "CPF/CNPJ",
        width: 160,
        format: (value) => masks.cpfCnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
      },
      {
        dataField: "isMainLink",
        caption: "Vínculo principal",
      },
      {
        dataField: "originator.name",
        caption: "Originador",
        allowEditing: false,
        calculateFilterExpression: (filterValue) => [
          ["originator.name", "contains", filterValue],
          "or",
          [
            "originator.identification",
            "contains",
            filterValue.replace(/[^\w]/g, ""),
          ],
        ],
        cellRender: ({ data }) => (
          <div className="d-flex flex-column gap-2">
            <OTypography>{data.originator.name?.toUpperCase()}</OTypography>
            <OTypography type="default">
              {masks.cpfCnpj(data.originator.identification)}
            </OTypography>
          </div>
        ),
      },
      {
        dataField: "operator.name",
        caption: "Operador",
        allowEditing: false,
        calculateFilterExpression: (filterValue) => [
          ["operator.name", "contains", filterValue],
          "or",
          [
            "operator.identification",
            "contains",
            filterValue.replace(/[^\w]/g, ""),
          ],
        ],
        cellRender: ({ data }) => (
          <div className="d-flex flex-column gap-2">
            <OTypography>
              {data.operator?.name.toUpperCase() || "-"}
            </OTypography>
            <OTypography type="default">
              {masks.cpfCnpj(data.operator?.identification)}
            </OTypography>
          </div>
        ),
      },
      {
        dataField: "createdDate",
        caption: "Data do vínculo",
      },
      {
        dataField: "createdBy",
        caption: "Criado por",
        format: (value) => masks.cpfCnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
      },
      {
        dataField: "isActive",
        caption: "Vínculo ativo",
      },
      {
        dataField: "deactivatedAt",
        caption: "Data da desativação",
      },
    ],
  };
