import * as yup from "yup";

export const NewOriginatorFormSchema: yup.ObjectSchema<HubPartners.Admin.NewOriginator> =
  yup.object({
    identification: yup.string().required("Este campo é obrigatório"),
    identificationType: yup
      .mixed<HubPartners.Admin.IdentificationType>()
      .required("Este campo é obrigatório"),
    officialName: yup.string().required("Este campo é obrigatório"),
    tradingName: yup.string(),
    areaCode: yup
      .number()
      .required("Este campo é obrigatório")
      .transform((value) => (Number.isNaN(value) ? null : value)),
    partnershipType: yup.string().required("Este campo é obrigatório"),
    originatorSubTypes: yup
      .array()
      .of(yup.string().required("Este campo é obrigatório"))
      .default([]),
    products: yup
      .array()
      .of(yup.string().required("Este campo é obrigatório"))
      .default([])
      .min(1, "Selecione ao menos um produto"),
    hasIndicator: yup
      .array()
      .of(yup.string().required("Este campo é obrigatório"))
      .default([]),
    indicatorOriginatorUUID: yup
      .string()
      .test(
        "requiredWhenIndicated",
        "Selecionar originador que indicou",
        (value, context) =>
          (!!value && context.parent.hasIndicator.includes("check")) ||
          (!value && !context.parent.hasIndicator.includes("check")),
      ),
    indicatorOperatorUUID: yup
      .string()
      .test(
        "requiredWhenIndicated",
        "Selecionar operador que indicou",
        (value, context) =>
          (!!value && context.parent.hasIndicator.includes("check")) ||
          (!value && !context.parent.hasIndicator.includes("check")),
      ),
    economicGroupUUID: yup.string(),
    mainOriginatorUUID: yup.string(),
    account: yup.object({
      bank: yup.string().required("Este campo é obrigatório"),
      branch: yup.string().required("Este campo é obrigatório"),
      number: yup.string().required("Este campo é obrigatório"),
    }),
    identifier: yup.string(),
    mirror: yup.object({
      mirrorBook: yup.string(),
      mirrorStrategy: yup.string(),
    }),
  });
