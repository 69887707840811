export const productTypeMap: Record<string, string> = {
  Cartao: "Cartão",
  RiscoCedente: "Cessão de crédito",
  CCC: "Cessão com coobrigação",
  FIDC: "FIDC",
  FundingProprio: "Funding próprio",
  Offshore: "Offshore",
  RiscoSacado: "Risco Sacado",
};

export const onboardingStatusMap: Record<string, string[]> = {
  Autorizado: ["AUTORIZADO"],
  Concluido: ["CONCLUIDO"],
  PendenteCliente: [
    "PRE_CADASTRADO",
    "PENDENTE_ASSINATURA_TERMO",
    "PENDENTE_PRECADASTRO",
    "PENDENTE_CADASTRO",
    "PENDENTE_CADASTRO_MANUAL",
    "PENDENTE_BIOMETRIA",
    "PENDENTE_DOCUMENTACAO",
  ],
  PendenteBTG: [
    "DRAFT",
    "PENDENTE_ONBOARDING",
    "PENDENTE_VALIDACAO",
    "PENDENTE_VALIDACAO_MANUAL",
    "PENDENTE_REQUISICAO",
    "PENDENTE_VALIDACAO_CGE",
    "PENDENTE_ATUALIZACAO_ONBOARDING",
    "PENDENTE_VALIDACAO_ONBOARDING_MATRIZ",
    "PENDENTE_ANALISE",
    "PENDETE_SOLICITACAO_REPLICA",
    "PENDETE_RESPOSTA_CLERK",
  ],
  Reprovado: ["REPROVADO"],
  Inativo: ["PAUSADO", "DESATIVADO"],
};

export const contractStatusMap: Record<string, string> = {
  Signed: "Assinado",
  AutoSigned: "Assinado automaticamente",
  PartiallySigned: "Parcialmente assinado",
  WaitingApproval: "Aguardando aprovação",
  Pending: "Pendente",
  Requested: "Solicitado",
  ContractPrepared: "Contrato preparado",
  Rejected: "Rejeitado",
  Canceled: "Cancelado",
  Created: "Criado",
  OutOfLimit: "Limite excedido",
  WithoutPower: "Sem poder",
  WithoutVigency: "Sem vigência",
};

export const calculateOnboardingStatusFilter = (
  filterValue: string,
  selectedFilterOperation: string | null,
) => {
  const possibleValues: string[] = [];
  Object.keys(onboardingStatusMap).forEach((status) => {
    if (status.includes(filterValue)) {
      possibleValues.push(...onboardingStatusMap[status]);
    }
  });

  const result: (string[] | string)[] = [];

  possibleValues.forEach((filter, index) => {
    result.push(["OnboardingStatus", selectedFilterOperation ?? "=", filter]);
    if (index < possibleValues.length) {
      result.push("or");
    }
  });

  return result;
};
