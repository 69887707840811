import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { PatchTemplateEstrategiaComercialBody } from "services/quickfin/models";
import { getValueFromMap } from "utils/get-value-from-map";
import {
  EstrategiaForm,
  addEstrategiaFormDefaultValues,
  addEstrategiaFormValidationSchema,
  productFormMap,
} from "../../../../../../components/estrategia-form";
import { useEstrategiaTemplate } from "../../_compose";
import {
  buildPayload,
  toastErrors,
} from "../adicionar/add-estrategia-template.utils";
import { buildInitialValues } from "./estrategia-template-details.utils";

export const useEstrategiaTemplateDetails = () => {
  const { estrategia, loading } = useEstrategiaTemplate();

  const { callService, loading: submitLoading } = useServiceCall(
    service.quickfin.editEstrategia,
  );

  const navigate = useNavigate();

  const form = useForm<EstrategiaForm>({
    defaultValues: addEstrategiaFormDefaultValues,
    resolver: yupResolver(addEstrategiaFormValidationSchema),
  });

  const { handleSubmit, reset, watch } = form;

  const tipoProdutoWatch = watch("tipoProduto");

  const Form = getValueFromMap(productFormMap, tipoProdutoWatch);

  const submit = useMemo(
    () => (updateEstrategias: boolean) =>
      handleSubmit(async (values) => {
        const { success, error } = await callService(
          buildPayload(
            values,
            updateEstrategias,
            true,
          ) as PatchTemplateEstrategiaComercialBody,
        );
        if (success) {
          OToastManager.success("Estratégia editada com sucesso");
          navigate(
            corporateRouter.routes.recebiveis.product.estrategiaTemplates.path,
          );
        } else {
          toastErrors(error);
          OToastManager.danger("Não foi possível editar a estratégia");
        }
      }),
    [callService, handleSubmit, navigate],
  );

  useEffect(() => {
    if (estrategia) {
      reset(buildInitialValues(estrategia) as any);
    }
  }, [estrategia, reset]);

  return {
    Form,
    form,
    loading,
    submit,
    submitLoading,
  };
};
